import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import { FormattedMessage, injectIntl } from "react-intl";
import * as Utils from "../../commons/utils/Utils";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  Collapse,
  TextField,
  MenuItem,
  CircularProgress,
  TablePagination
} from "@material-ui/core";
import { StyledTableCell } from "../../commons/utils/TableStyles";
import Timezone from '../../commons/timezone/Timezone.js';
import $ from "jquery";
import Switch from "react-switch";
import { useIntl } from 'react-intl';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';

const PAGINATION_INIT = {
  page: 0,
  perPage: 10,
};

const QUERY_INIT = {
  categoria: '',
  categoriaSort: '',
  entidadMovil: '',
  entidadPersona: '',
  entidadSort: '',
};

const GastoExtraGrid = (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(null);
  const [gastos, setGastos] = useState(null);
  const [dataToExport, setDataToExport] = useState({
    tipo_entidad: true,
    entidad: true,
    categoria: true,
    importe: true,
    fecha: true,
    direccion: true,
    gps: true,
  });

  const intl = useIntl();
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [pagination, setPagination] = useState({ ...PAGINATION_INIT });
  const [paginationCount, setPaginationCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState({ ...QUERY_INIT });
  const [dropdownActive, setDropdownActive] = useState(false);

  useEffect(() => {
    setLoading(true);
    // Fetch gastos
    fetchGastos(pagination.page);
  }, [pagination, searchQuery]);

  const fetchGastos = (page) => {
    Utils.getData(`/gasto-extra/grid?page=${page + 1}${getQuery()}`)
      .then((res) => {
        console.log(res)
        setGastos(res.gastos);
        setLoading(false);
        setFiltersLoading(false);
        setPaginationCount(res.pagination.count);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setFiltersLoading(false);
      });
  };

  const deleteGasto = (id) => {
    Utils.deleteData("/gasto-extra/" + id)
      .then((res) => {
        fetchGastos();
      })
      .catch((err) => {
        console.log(err);
        swal(
          intl.formatMessage({
            id: 'GastoExtraController.errors',
            defaultMessage: 'Error al realizar la operacion.'
          }) + ' ' + err,
          '',
          'error',
        );
      });
  };

  const handleSubmitExport = () => {
    setLoading(true);
    fetch(Config.get("apiUrlBase") + "/gasto-extra/exportar-excel", {
      method: "POST",
      body: JSON.stringify({
        colsAExportar: dataToExport,
      }),
      headers: {
        "Authorization-Token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
      })
      .then((fileBlob) => {
        let fileUrl = URL.createObjectURL(fileBlob);
        $("<a />", {
          href: fileUrl,
          download: "fleet-gastos-extra.xlsx",
        })
          .appendTo("body")
          .get(0)
          .click();
      })
      .catch(() => {})
      .finally(() => {
        handleExportCancel();
        setLoading(false);
      });
  };

  const handleExportExcel = () => {
    $("#export_modal").modal("show");
  };

  const handleExportCancel = () => {
    $("#export_modal").modal("toggle");
  };

  const handleDataExport = (event, name) => {
    let dataToExportCopy = JSON.parse(JSON.stringify(dataToExport));
    dataToExportCopy[name] = !dataToExportCopy[name];
    setDataToExport(dataToExportCopy);
  };

  const changePage = (e, newPage) => {
    setLoading(true);
    setPagination({ ...pagination, page: newPage });
  };

  const filter = (name, value) => {
    console.log(name)
    console.log(value)
    setLoading(true);
    setFiltersLoading(true);

    value !== '' && setSearchActive(true);
    let query = { ...searchQuery };

    query[name] = value;
    if (name == 'entidadMovil' && value != '') {
      query['entidadPersona'] = ''
    }
    if (name == 'entidadPersona' && value != '') {
      query['entidadMovil'] = ''
    }
    setSearchQuery(query);
  };

  const getQuery = () => {
    let query = '';

    query += '&categoria=' + searchQuery.categoria;
    query += '&categoriaSort=' + searchQuery.categoriaSort;
    query += '&entidadMovil=' + searchQuery.entidadMovil;
    query += '&entidadPersona=' + searchQuery.entidadPersona;
    query += '&entidadSort=' + searchQuery.entidadSort;

    return query;
  };

  const handleFilterReset = () => {
    setPagination(PAGINATION_INIT);
    setSearchQuery(QUERY_INIT);
    setDropdownActive(false);
    setSearchActive(false);
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <div className="card">
        <div className="card-content">
          <div className="card-body">
            <div style={{ marginBottom: "-20px" }}>
              <Tooltip
                title={props.intl.formatMessage({
                  id: "clasesGrid.button.label_add",
                  defaultMessage: "Agregar",
                })}
                arrow
                placement="right"
              >
                <div
                  className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  onClick={() => setRedirectTo(props.match.url + "/add")}
                >
                  <i className="ft-plus"></i>
                </div>
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: 'llantaMarcasGrid.button.label_filtros',
                  defaultMessage: 'Filtros',
                })}
                arrow
                placement="right"
              >
                <div
                  className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  style={{ height: 52 }}
                  onClick={() => setDropdownActive(!dropdownActive)}
                >
                  {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                </div>
              </Tooltip>
              {searchActive && (
                <div className="btn-dt-main round-icon">
                  <div
                    className="text-center"
                    style={{
                      height: '25px',
                      borderRadius: '3px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: '8px',
                      backgroundColor: '#5AAC43',
                      color: 'white',
                    }}
                  >
                    <div
                      className="text-center"
                      style={{
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      {gastos.length} de {paginationCount} resultados de busqueda
                    </div>
                    <div className="resultados-busqueda-notif">
                      <i
                        className="las la-times la-xs cursor-pointer"
                        onClick={handleFilterReset}
                      ></i>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <Filtros
              dropdownActive={dropdownActive}
              filtersLoading={filtersLoading}
              filter={filter}
              searchActive={searchActive}
            />
            <TableContainer>
            <div className="col-2 float-right" id="buttons">
              <div className="dt-buttons btn-group">
                <button
                  onClick={handleExportExcel}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Exportar Excel"
                  className="btn btn-secondary buttons-collection"
                  tabIndex="0"
                  aria-controls="dataTable"
                  type="button"
                  aria-haspopup="true"
                >
                  <span>
                    <i className="ft-upload"></i>
                  </span>
                </button>
              </div>
            </div>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">CATEGORIA</StyledTableCell>
                    <StyledTableCell align="left">ENTIDAD</StyledTableCell>
                    <StyledTableCell align="left">FECHA</StyledTableCell>
                    <StyledTableCell align="left">IMPORTE</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <StyledTableCell colSpan={4} align="center">
                        Cargando
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    gastos &&
                    gastos.map((row) => (
                      <TableRow key={row.name} key={"clasesGridRow_" + row.id}>
                        <StyledTableCell className="text-secondary" align="left">{row.tipo_gasto.nombre}</StyledTableCell>
                        <StyledTableCell className="text-secondary" align="left">
                          <i className={row.entidad == 'M' ? "la la-car" : "la la-user"}></i>{' '}
                          {row.entidad == 'M' ? row.movil.dominio : row.persona.nombre+' '+row.persona.apellido}
                          </StyledTableCell>
                        <StyledTableCell className="text-secondary" align="left">{Timezone.getDateForClient(row.fecha, 'YYYY-MM-DD HH:mm:ss:tt', 'DD/MM/YYYY HH:mm')}</StyledTableCell>
                        <StyledTableCell className="text-secondary" align="left">$ {Math.round(row.importe)}</StyledTableCell>
                        <StyledTableCell align="right" style={{ maxWidth: 80 }}>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0 mx-1"
                              onClick={() => setRedirectTo(props.match.url + "/" + row.id)}
                            >
                              <Tooltip
                                title={props.intl.formatMessage({
                                  id: "clasesGrid.button.label_view",
                                  defaultMessage: "Ver",
                                })}
                                arrow
                              >
                                <i className="fa fa-search fa-xs"></i>
                              </Tooltip>
                            </button>
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0 mx-1"
                              onClick={() => setRedirectTo(props.match.url + "/" + row.id + "/edit")}
                            >
                              <Tooltip
                                title={props.intl.formatMessage({
                                  id: "clasesGrid.button.label_edit",
                                  defaultMessage: "Editar",
                                })}
                                arrow
                              >
                                <i className="fa fa-pencil fa-xs"></i>
                              </Tooltip>
                            </button>
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-danger p-0 mx-1"
                              onClick={() => deleteGasto(row.id)}
                            >
                              <Tooltip
                                title={props.intl.formatMessage({
                                  id: "clasesGrid.button.label_delete",
                                  defaultMessage: "Eliminar",
                                })}
                                arrow
                              >
                                <i className="fa fa-trash fa-xs"></i>
                              </Tooltip>
                            </button>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={paginationCount}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              onChangePage={changePage}
              labelRowsPerPage="Mostrar"
              labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
            />
          </div>
        </div>
      </div>

      {/* Modal Exportar */}
      <div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
        <div className="modal-xl modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-fleet">
              <h4 className="modal-title text-white" id="myModalLabel3">
                <i className="ft-download align-middle icon-modal-title"></i>
                <FormattedMessage
                  id="movilesGrid.render.export_modal.header_datos_generales"
                  defaultMessage=" Exportar Moviles"
                />
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body modal-grey modal-mh ovf-x-hidden">
              <div className="row">
                <div className="col-md-12">
                  <form className="form form-horizontal" onSubmit={handleSubmitExport}>
                    <div className="form-body">
                      <div className="card pull-up">
                        <div className="card-content">
                          <div className="card-body">
                            <h4 className="form-section">
                              <i className="la la-columns"></i>
                              <FormattedMessage
                                id="llantasGrid.render.export_modal.columns_to_export.header_columnas_a_exportar"
                                defaultMessage=" Columnas a exportar"
                              />
                            </h4>
                            <div className="row m-1">
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="categoria">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_categoria"
                                    defaultMessage="Categoria:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "categoria")}
                                  checked={dataToExport.categoria ? true : false}
                                  value={dataToExport.categoria}
                                  id="categoria"
                                  name="categoria"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="tipo_entidad">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_tipo_entidad"
                                    defaultMessage="Tipo Entidad:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "tipo_entidad")}
                                  checked={dataToExport.tipo_entidad ? true : false}
                                  value={dataToExport.tipo_entidad}
                                  id="tipo_entidad"
                                  name="tipo_entidad"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="entidad">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_entidad"
                                    defaultMessage="Entidad:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "entidad")}
                                  checked={dataToExport.entidad ? true : false}
                                  value={dataToExport.entidad}
                                  id="entidad"
                                  name="entidad"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="fecha">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_fecha"
                                    defaultMessage="Fecha:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "fecha")}
                                  checked={dataToExport.fecha ? true : false}
                                  value={dataToExport.fecha}
                                  id="fecha"
                                  name="fecha"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="importe">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_importe"
                                    defaultMessage="Importe:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "importe")}
                                  checked={dataToExport.importe ? true : false}
                                  value={dataToExport.importe}
                                  id="importe"
                                  name="importe"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="direccion">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_direccion"
                                    defaultMessage="Direccion:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "direccion")}
                                  checked={dataToExport.direccion ? true : false}
                                  value={dataToExport.direccion}
                                  id="direccion"
                                  name="direccion"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="gps">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_gps"
                                    defaultMessage="Direccion GPS:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "gps")}
                                  checked={dataToExport.gps ? true : false}
                                  value={dataToExport.gps}
                                  id="gps"
                                  name="gps"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div className="col-md-12 alert alert-info small" role="alert">
                      <FormattedMessage
                        id="llantasGrid.render.export_modal.esta_accion_puede_demorar_unos_minutos"
                        defaultMessage="Esta acción puede demorar unos minutos."
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer modal-grey">
              <button type="button" className="btn btn-default btn-fleet" onClick={handleSubmitExport}>
                <i className={loading ? "la la-spinner spinner" : "fa fa-download"}></i>
                <FormattedMessage
                  id="llantasGrid.render.export_modal.finish_button.exportar_a_excel"
                  defaultMessage=" Exportar a Excel"
                />
              </button>
              <button type="button" className="btn btn-danger" onClick={handleExportCancel}>
                <i className="fa fa-times-circle"></i>
                <FormattedMessage
                  id="llantasGrid.render.export_modal.finish_button.cancelar"
                  defaultMessage=" Cancelar"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function Filtros(props) {
  const intl = useIntl();
  const [categoria, setCategoria] = useState('');
  const [entidadMovil, setEntidadMovil] = useState('');
  const [entidadPersona, setEntidadPersona] = useState('');
  const [categorias, setCategorias] = useState([]);

  // Get categorias gastos
  useEffect(() => {
    getTipoGasto();
  }, []);

  useEffect(() => {
    !props.searchActive && resetAll();
  }, [props.searchActive]);

  const getTipoGasto = () => {
    return Utils.getData('/tipo-gasto-extra/select')
    .then((res) => {
      setCategorias(res);
    })
    .catch((e) => {
      console.log(e);
    });
  }

  const handleMaterialChange = (name, value) => {
    props.filter(name, value);
  };

  const handleOnEnterSerialChange = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleMaterialChange(name, value);
    }
  };

  const resetAll = () => {
    setCategoria('');
    setEntidadMovil('');
    setEntidadPersona('');
  };
  return (
    <>
      <Collapse in={props.dropdownActive}>
        <div className="row mb-1">
          <div className="col-md-10">
            <div className="row" style={{ width: '100%' }}>
              <div className="col-md-4">
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                  <TextField
                    id="standard-basic"
                    select
                    style={{ width: '100%' }}
                    label={intl.formatMessage({
                      id: 'llantaModelosGrid.render.filtros_categoria.label',
                      defaultMessage: 'Categoria',
                    })}
                    name="categoria"
                    value={categoria}
                    onChange={(e) => setCategoria(e.target.value)}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'categoria', categoria)}
                    onBlur={(e) => handleMaterialChange('categoria', categoria)}
                    disabled={props.filtersLoading}
                  >
                  {categorias.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  </TextField>
                </form>
              </div>
              <div className="col-md-4">
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                  <TextField
                    id="standard-basic"
                    style={{ width: '100%' }}
                    label={intl.formatMessage({
                      id: 'llantaModelosGrid.render.filtros_entidad_movil.label',
                      defaultMessage: 'Movil',
                    })}
                    name="entidadMovil"
                    value={entidadMovil}
                    onChange={(e) => setEntidadMovil(e.target.value)}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'entidadMovil', entidadMovil)}
                    onBlur={(e) => handleMaterialChange('entidadMovil', entidadMovil)}
                    disabled={props.filtersLoading}
                  />
                </form>
              </div>
              <div className="col-md-4">
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                  <TextField
                    id="standard-basic"
                    style={{ width: '100%' }}
                    label={intl.formatMessage({
                      id: 'llantaModelosGrid.render.filtros_entidad_persona.label',
                      defaultMessage: 'Persona',
                    })}
                    name="entidadPersona"
                    value={entidadPersona}
                    onChange={(e) => setEntidadPersona(e.target.value)}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'entidadPersona', entidadPersona)}
                    onBlur={(e) => handleMaterialChange('entidadPersona', entidadPersona)}
                    disabled={props.filtersLoading}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default injectIntl(GastoExtraGrid);
