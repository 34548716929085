import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  TableRow,
  TableCell,
  Collapse,
} from '@material-ui/core'
import { StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'
import moment from 'moment'
import 'moment/min/locales'
import * as Constants from '../atributoDinamico/constants.js'
import FormularioDetail from "./FormularioDetail";
import Timezone from '../../commons/timezone/Timezone.js';
import FormularioPdf from './FormularioPdf.js';

const FormulariosGridRow = (props) => {

  const formulario = props.formulario;
  const baseUrl = props.baseUrl;
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState(null)
  const [open, setOpen] = useState(false);
  const [isHidden, setIsHidden] = useState("hidden-personal");
  const estadosTooltip = ['', 'CERRADO', 'PENDIENTE', 'SIN COMPROBACION', 'EN PROCESO'];

  useEffect(() => {
    getEstado();
  }, [])

  const getEstado = (estado) => {
    switch (estado) {
      case Constants.ID_ESTADO_APROBACION_VALIDO:
        return "status-green";
        break;
      case Constants.ID_ESTADO_APROBACION_PENDIENTE:
        return "status-yellow";
        break;
      case Constants.ID_ESTADO_APROBACION_INVALIDO:
        return "status-red";
        break;
      case Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION:
        return "status-grey";
        break;

    }
  };

  const toggleCollapse = () => {
    setOpen(!open);
    if (isHidden === "hidden-personal") {
      setIsHidden("block");
    } else {
      setIsHidden("hidden-personal");
    }
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <TableRow>
        <StyledTableCell>
          <div className="d-flex">
            <div onClick={() => toggleCollapse()}>
              {open ? (
                <i className="fa fa-2x fa-angle-double-down personal-no-focus avatar-plus-detail pointer"></i>
              ) : (
                <i className="fa fa-angle-double-right fa-2x personal-no-focus avatar-plus-detail pointer"></i>
              )}
            </div>
            <div className="text-center" style={{ minWidth: "50px" }}>
              <i className={'fa-2x ' + formulario.tipo_formulario.icono} />
            </div>
          </div>
        </StyledTableCell>
        <StyledTooltip title={estadosTooltip[formulario.estado]} arrow placement="right">
          <td
            align="left"
            className={getEstado(formulario.estado) + " p-0 all custom-lineHeight br"}
            style={{ maxWidth: "3px" }}
          />
        </StyledTooltip>
        <StyledTableCell align="left">
          {formulario.id}
        </StyledTableCell>
        <StyledTableCell align="left">
          {formulario.tipo_formulario.nombre}
        </StyledTableCell>
        <StyledTableCell align="left">
            { formulario.created_at ? Timezone.getDateForClient(formulario.created_at, null, 'DD-MM-YYYY') :  ''}
        </StyledTableCell>
        <StyledTableCell align="left">
          {formulario.entidad}
        </StyledTableCell>
        <StyledTableCell align="left">
          {formulario.usuario_modificacion ? formulario.usuario_modificacion.label: ''}
        </StyledTableCell>
        <StyledTableCell align="left">
          {formulario.fallas}
        </StyledTableCell>
        <StyledTableCell align="left">
          {formulario.base ? formulario.base.descripcion : ''}
        </StyledTableCell>
        <StyledTableCell align="left">
          {formulario.chofer ? formulario.chofer.fullName : ''}
        </StyledTableCell>
        <StyledTableCell>
          <div className="d-inline-flex">
            <button
              className="action view btn btn-sm btn-icon btn-dt-grid text-success"
              data-title="Ver"
              data-togle="tooltip"
              data-placement="top" onClick={() => setRedirectTo(baseUrl + '/' + formulario.id)} >
              <i className="fa fa-search fa-xs" />
            </button>
            <button
              className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
              data-title="Editar"
              data-togle="tooltip"
              data-placement="top" onClick={() => setRedirectTo(baseUrl + '/' + formulario.id + '/edit')} >
              <i className="fa fa-pencil fa-xs" />
            </button>
            <FormularioPdf formulario_id={formulario.id} intl={intl}/>
          </div>
        </StyledTableCell>
      </TableRow>
      <TableRow className={isHidden}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: "rgb(244, 245, 250)" }} colSpan={19}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <FormularioDetail
              intl={intl}
              formulario_id={formulario.id}
              style={{ maxWidth: "85vw" }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default FormulariosGridRow
