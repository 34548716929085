import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Security from "../../commons/security/Security.js";
import $ from "jquery";
import "bootstrap4-duallistbox";
import "bootstrap4-duallistbox/dist/bootstrap-duallistbox.css";
import FormValidation from "../../commons/validation/FormValidation.js";
import Validator from "../../commons/validation/Validator.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import Loading from "../ui/Loading.js";
import Select from "react-select";
import "react-select/dist/react-select.css";
import moment from "moment";
import "moment/min/locales";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/min/dropzone.min.css";
import "../../assets/css/vec-dropzone.css";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";

class RopAbm extends Component {
  constructor(props) {
    super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");
    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
        movil: null,
        taller: null,
        clasificacion: null,
        observaciones: null
      },
      moviles: [],
      errors: [],
      loading: false,
    };

    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.handleClasificacionChange = this.handleClasificacionChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleObservacionesChange = this.handleObservacionesChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getBackendMoviles = this.getBackendMoviles.bind(this);


    this.formValidation = new FormValidation({
      component: this,
      validators: {
        "formData.movil": (value) => Validator.notEmpty(value),
        "formData.taller": (value) => Validator.notEmpty(value),
        "formData.clasificacion": (value) => Validator.notEmpty(value),
      },
    });
  }

	componentWillMount() {
    if (Security.hasPermission("ROPS_CREAR")) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: "/error",
      });
    }
  }


  initForm() {
    this.setState({ loading: true });
    let component = this;
    Promise.all([
      this.ajaxHandler.getJson("/moviles/select"),
      this.ajaxHandler.getJson('/talleres/gerenciador/' + ConfigBusiness.get("rop.gerenciador") + '/select'),
      this.ajaxHandler.getJson('/rops/classifications/correctivos'),
    ])
      .then((data) => {
        let moviles = data[0];
        let talleres = data[1];
        let clasificaciones = data[2]
        component.setState(
          {
            moviles: moviles,
            talleres: talleres,
            clasificaciones: clasificaciones
          },
          () => {
            if (this.props.match.params.movilId) {
              this.handleMovilChange({
                value: this.props.match.params.movilId,
                label: this.props.match.params.movilDominio,
              });
            }
            $("#movil").focus();
          }
        );
      })
      .catch(function (error) {
        component.ajaxHandler.handleError(error);
      })
      .finally(() => {
        component.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }
  
  handleMovilChange(object) {
    let component = this;
    if (object) {
      this.setState({ loading: true });
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.selectedMovil = object;
      this.setState(
        {
          formData: formDataCopy,
        },
        () => {
          this.ajaxHandler
            .getJson("/moviles/" + object.value)
            .then((data) => {
              let movil = data;
              let formDataCopy = JSON.parse(
                JSON.stringify(this.state.formData)
              );
              formDataCopy.movil = movil;
              this.setState({
                formData: formDataCopy,
              });
              this.setState({ loading: false });
            })
            .catch(function (error) {
              component.ajaxHandler.handleError(error);
              component.setState({ loading: false });
            });
        }
      ); 
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.movil = null;
      formDataCopy.gerenciador = null;
      this.setState({
        formData: formDataCopy,
      });
    }
  }

	handleTallerChange(object) {
    if(object){
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.taller = {id: object.value, razonSocial: object.label};
      this.setState({
        formData: formDataCopy
      });
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.taller = null;
      this.setState({
        formData: formDataCopy
      });
    }
	}

  handleClasificacionChange(object) {
    if(object){
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.clasificacion = {id: object.value, descripcion: object.label};
      this.setState({
        formData: formDataCopy
      });
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.clasificacion = null;
      this.setState({
        formData: formDataCopy
      });
    }
	}

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }
 
  handleObservacionesChange(object) {
    console.log(object);
    if(object){
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.observaciones = object.text;
      this.setState({
        formData: formDataCopy
      });
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.observaciones = null;
      this.setState({
        formData: formDataCopy
      });
    }
	}

  handleSubmit(event) {
    this.setState({ loading: true });
    let component = this
    const postData = {
      tallerId: component.state.formData.taller.id,
      movilId: component.state.formData.movil.id,
      clasificacion: component.state.formData.clasificacion.id,
      observacion: component.state.formData.observaciones,
    };

    this.ajaxHandler.fetch('/rops', {
      method: 'POST',
      body: JSON.stringify(postData),
    }).then(response => {
      if(response.status !== 400) {
        component.exit();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      component.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false });
    });
		event.preventDefault();
	}

  handleCancel(event) {
    window.history.back();
  }

  exit() {
    this.setState({
      redirectTo: "/rop",
    });
  }

  getBackendMoviles (searchTerm) {
    return this.ajaxHandler.getJson('/moviles/simple-search?search=' + searchTerm).then(res => {
      return {
        options: res,
        complete: true
      }
    });
  }

  render() {
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;
    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.errors.length === 0}
            >
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  {e}
                </li>
              ))}
            </div>
            <form
              className="form form-horizontal"
              ref="form"
              onSubmit={this.handleSubmit}
            >
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-info-circle"></i>
                        <FormattedMessage
                          id="ropAbm.datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                        <div
                          className="float-right"
                          style={{ fontSize: "14px" }}
                        >
                          <FormattedMessage
                            id="ropAbm.datos_generales.campos_requeridos"
                            defaultMessage="* campos requeridos"
                          />
                        </div>
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="base"
                            >
                              <FormattedMessage
                                id="ropAbm.datos_generales.label_equipo"
                                defaultMessage="Equipo *:"
                              />
                            </label>
                            <div className="col-md-8">
                              {this.props.action === "VIEW" ? (
                                <div className="form-control-static col-form-label form-value">
                                  {formData.movil
                                    ? formData.movil.dominio
                                    : ""}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                      name="storageTypeId"
                                      value={formData.selectedMovil}
                                      valueKey='value'
                                      labelKey='label'
                                      options={this.state.moviles}
                                      onChange={(e) =>
                                        this.handleMovilChange(e)
                                      }
                                      disabled={this.props.action === "VIEW"}
                                      placeholder={this.props.intl.formatMessage(
                                        {
                                          id:
                                            "ropAbm.datos_generales.placeholder_select_equipo",
                                          defaultMessage: "Dominio",
                                        }
                                      )}
                                    />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.movil
                                        .pristine ||
                                      validationState.formData.movil.valid
                                    }
                                  >
                                    {validationState.formData.movil.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                          {formData.movil && formData.movil.companiaOrigen ? (
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="cliente"
                            >
                              { formData.movil && formData.movil.companiaOrigen ? (
                                <FormattedMessage
                                  id="ropAbm.datos_generales.cliente"
                                  defaultMessage="Cliente:"/>
                              ):''}
                            </label>
                          ):''}
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.movil && formData.movil.companiaOrigen
                                  ? formData.movil.companiaOrigen
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* TALLER */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="taller">
                                <FormattedMessage id="ropAbm.datos_generales.label_taller" defaultMessage="Taller *:"/>
                            </label>
                            <div className="col-md-8">
                              <div>
                                  <Select
                                  id="taller"
                                  name="taller"
                                  placeholder={this.props.intl.formatMessage({ id: 'ropAbm.datos_generales.placeholder_select_taller', defaultMessage: 'Nombre' })}
                                  options={this.state.talleres}
                                  valueKey='value'
                                  labelKey='label'
                                  value={formData.taller ? {value: formData.taller.id, label: formData.taller.razonSocial} : null}
                                                                      onChange={(e) => this.handleTallerChange(e)}
                                                                      isLoading={this.state.talleresLoading}
                                  disabled={this.state.talleresDisabled}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* CLASIFICACION */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="clasificacion">
                                <FormattedMessage id="ropAbm.datos_generales.label_clasificacion" defaultMessage="Clasificación *:"/>
                            </label>
                            <div className="col-md-8">
                              <div>
                                  <Select
                                    id="clasificacion"
                                    name="clasificacion"
                                    placeholder={this.props.intl.formatMessage({ id: 'ropAbm.datos_generales.placeholder_select_clasificacion', defaultMessage: 'Descripción' })}
                                    options={this.state.clasificaciones}
                                    valueKey='value'
                                    labelKey='label'
                                    value={formData.clasificacion ? {value: formData.clasificacion.id, label: formData.clasificacion.descripcion} : null}
                                    onChange={(e) => this.handleClasificacionChange(e)}
                                    isLoading={this.state.clasificacionesLoading}
                                    disabled={this.state.clasificacionesDisabled}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* OBSERVACIONES */}
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label
                              className="col-md-2 label-control col-form-label"
                              htmlFor="observaciones"
                            >
                              <FormattedMessage
                                id="ropAbm.datos_generales.observaciones"
                                defaultMessage="Observaciones:"
                              />
                            </label>
                            <div className="col-md-10">
                              <div>
                                <textarea
                                  className="form-control"
                                  id="observaciones"
                                  name="observaciones"
                                  rows="3"
                                  maxLength="2000"
                                  placeholder={this.props.intl.formatMessage({
                                    id:
                                      "ropAbm.datos_generales.placeholder.observaciones",
                                    defaultMessage: "Observaciones",
                                  })}
                                  value={formData.observaciones ? formData.observaciones : ''} 
                                  onChange={this.handleInputFormChange}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*JSON.stringify(this.state.formData)*/}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== "VIEW" && (
                          <button
                            type="submit"
                            className="btn btn-primary mr-1"
                            disabled={this.state.loading || !validationState.form.valid}
                          >
                            <i className="fa fa-check-circle"></i>
                            <FormattedMessage
                              id="ropAbm.button.finish.guardar"
                              defaultMessage=" Guardar"
                            />
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.handleCancel.bind(this)}
                        >
                          <i className="fa fa-times-circle"></i>{" "}
                          {this.props.action === "VIEW"
                            ? this.props.intl.formatMessage({
                                id: "romAbm.button.finish.volver",
                                defaultMessage: "Volver",
                              })
                            : this.props.intl.formatMessage({
                                id: "ropAbm.button.finish.cancelar",
                                defaultMessage: "Cancelar",
                              })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(RopAbm);
