import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-responsive-bs4'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
//import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Portal } from 'react-overlays'
import Loading from '../ui/Loading.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Switch from "react-switch"
import { Transform } from 'stream';
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import llantasLogo from "../../assets/images/llantas/llanta-layout.svg"
import tireIcon from "../../assets/images/llantas/svg/car.svg"
import Timezone from '../../commons/timezone/Timezone.js';
import '../../assets/css/encuesta-satisfaccion-tickets.css';
import TicketGridFilters from './TicketGridFilters'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import swal from 'sweetalert2'

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CalendarContainer = ({children}) => {
  return (
    <Portal >
      {children}
    </Portal>
  )
}

class TicketsGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.columnsEnabled = {
			nivelAprobacionPresupuesto: ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.grilla') === 'true' ? true : false,
			region: ConfigBusiness.get('tickets.region.grilla') === 'true' ? true : false,
			centroCostos: ConfigBusiness.get('tickets.centroCostosGrilla.habilitado') === 'true' ? true : false,
			centroBeneficios: ConfigBusiness.get('tickets.centroBeneficiosGrilla.habilitado') === 'true' ? true : false,
			responsable1: ConfigBusiness.get('tickets.responsable1.habilitado') === 'true' ? true : false,
			encuestaSatisfaccion: ConfigBusiness.get('tickets.solicitarEncuestaSatisfaccion.grillaHabilitado') === 'true' ? true : false,
			fechaHoraTurno: ConfigBusiness.get('tickets.fechaHoraTurno.habilitado') === 'true' ? true : false,
			abono: ConfigBusiness.get('mantenimientos.correctivo.abono.habilitado') === 'true' ? true : false,
			tipoModelo: ConfigBusiness.get('tickets.movil.modelo') === 'true' ? true : false,
		}
		moment.locale('es');

		this.state = {
			redirectTo: null,
			now: new Date(),
			estadisticas: null,
			exportFechaDesde: moment().startOf('year'),
			exportFechaHasta: moment(),
			loading: false,
			abonoEnabled: false,
			tipoModeloEnabled: ConfigBusiness.get('tickets.movil.modelo') === 'true' ? true : false,
			regionEnabled: false,
			inmovilizadoEnabled: ConfigBusiness.get('tickets.inmovilizado.habilitado') === 'true' ? true : false,
			encuestaSatisfaccion: false,
			fechaHoraTurnoEnabled: false,
      nivelAprobacionPresupuesto: false,
			ticketGarantiaEnabled:ConfigBusiness.get('tickets.periodoGarantia.habilitado') === 'true' ? true : false,
      exportHistoricoFechaDesde: moment().subtract(3,'months').startOf('day'),
			exportHistoricoFechaHasta: moment(),
			dataToExport: {
				id: true,
				personaNombreApellido: true,
				movilDominio: true,
				serialLlanta: true,
				movilEstado: true,
				tipo: true,
				servicioNombre: true,
				estado: true,
				fechaHoraAlta: true,
				mttTotal: true,
				mttParcial: true,
				detalle: true,
				baseDescripcion: true,
				kmGenerado: true,
				KmRealizado: true,
				autogestion: true,
				forzado: true,
				abono: true,
				fechaHoraRealizado: true,
				gerenciadorRazonSocial: true,
				tallerRazonSocial: true,
				fechaHoraTurno: true,
				enTaller: true,
				observacionesTaller: true,
				presupuestoManoDeObra: true,
				presupuestoRepuestos: true,
				presupuestoImpuestos: true,
				presupuestoOtros: true,
				presupuestoTotal: true,
				presupuestoAdicional: true,
				presupuestoNombre: true,
				presupuestoFechaHora: true,
				factura: true,
				esSiniestro: true,
				numeroSiniestro: true,
				region: false,
				subRegion: false,
				inmovilizado: true,
        ordenTrabajo: true,
				responsable1NombreApellido : true,
				centroCostosNombre : true,
				centroBeneficiosNombre : false,
				movilFuncion: ConfigBusiness.get('moviles.funcion.habilitado') === 'true' ? true : false,
				movilCompaniaOrigen : true,
				tareaNombre : false,
				encuestaNivelSatisfaccion: ConfigBusiness.get('tickets.solicitarEncuestaSatisfaccion.grillaHabilitado') === 'true' ? true : false,
				encuestaComentarios: ConfigBusiness.get('tickets.solicitarEncuestaSatisfaccion.grillaHabilitado') === 'true' ? true : false,
				esReemplazoDeVehiculo: ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? true : false,
				fechaEntrega: ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? true : false,
				placaReemplazo: ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? true : false,
				otAsociada: ConfigBusiness.get('tickets.OTAsociada.habilitado') === 'true' ? true : false,
				fechaHoraTurnoEnabled: ConfigBusiness.get('tickets.fechaHoraTurno.habilitado') === 'true' ? true : false,
				nivelAprobacionPresupuesto: ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.grilla') === 'true' ? true : false,
				usuarioCreacionNombreApellido:true,
				tipoModelo:ConfigBusiness.get('tickets.movil.modelo') === 'true' ? true : false,
				ticketGarantia:ConfigBusiness.get('tickets.periodoGarantia.habilitado') === 'true' ? true : false,
			},
			exportarTodo: true,
			alertClick: null,
			movilDetail: null,
			dropdownActive: false,
			queryFilters: {
				ticketTipo: '',
  			id: '',
  			estado: '',
  			nivelAprobacionPresupuesto: '',
  			fechaHoraAlta: '',
  			detalle: '',
  			servicio: '',
  			tipoEntidadColumnGrid: '',
  			gerenciador: '',
  			region: '',
  			base: '',
  			centroCostos: '',
  			centroBeneficios: '',
  			responsable1: '',
  			enTaller: '',
  			mttTotal: '',
  			mttParcial: '',
  			encuestaNivelSatisfaccion: '',
  			abono: '',
  			fechaRealizado: '',
  			fechaHoraTurno: '',
  			tipoModelo: ''
			},
			limpiarFiltros: false,
			filtroKPI: null,
			grillaFiltrada: false
		}

		this.handleDataExport = this.handleDataExport.bind(this);
		this.handleAlertClick = this.handleAlertClick.bind(this);
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('TICKETS_LISTAR')) {
		  this.ajaxHandler.subscribe(this);
			if(this.props.location.state && this.props.location.state.filter){
				this.setState({ alertClick: this.props.location.state.filter});
				// localStorage.removeItem('DataTables_TicketsGrid');
			}
			if(this.props.location.state && this.props.location.state.filterMovilDetail){
				this.setState({ movilDetail: this.props.location.state.filterMovilDetail});
				// localStorage.removeItem('DataTables_TicketsGrid');
			}
				this.initGrid();
				if ( ConfigBusiness.get('tickets.region.habilitado') === 'true' ) {
					this.state.dataToExport.region = true;
					this.state.dataToExport.subRegion = true;
				}
				if ( ConfigBusiness.get('tickets.centroBeneficiosGrilla.habilitado') === 'true' ) {
					this.state.dataToExport.centroBeneficiosNombre = true;
				}
				if ( ConfigBusiness.get('tickets.solicitarEncuestaSatisfaccion.grillaHabilitado') === 'true' ) {
					this.setState({encuestaSatisfaccion: true});
				}
				if ( ConfigBusiness.get('tickets.fechaHoraTurno.habilitado') === 'true' ) {
					this.setState({fechaHoraTurnoEnabled: true});
				}
        if ( ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.grilla') === 'true' ) {
					this.setState({nivelAprobacionPresupuesto: true});
				}
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	handleDataExport(nombre){
		const miState = {...this.state};
		switch (nombre) {
			case "id":
				miState.dataToExport.id = !miState.dataToExport.id;
			break;
			case "personaNombreApellido":
				miState.dataToExport.personaNombreApellido = !miState.dataToExport.personaNombreApellido;
			break;
			case "movilDominio":
				miState.dataToExport.movilDominio = !miState.dataToExport.movilDominio;
			break;
			case "serialLlanta":
				miState.dataToExport.serialLlanta = !miState.dataToExport.serialLlanta;
			break;
      		case "movilEstado":
				miState.dataToExport.movilEstado = !miState.dataToExport.movilEstado;
			break;
			case "tipo":
				miState.dataToExport.tipo = !miState.dataToExport.tipo;
			break;
			case "servicioNombre":
				miState.dataToExport.servicioNombre = !miState.dataToExport.servicioNombre;
			break;
			case "estado":
				miState.dataToExport.estado = !miState.dataToExport.estado;
			break;
			case "fechaHoraAlta":
				miState.dataToExport.fechaHoraAlta = !miState.dataToExport.fechaHoraAlta;
			break;
			case "servicioNombre":
				miState.dataToExport.servicioNombre = !miState.dataToExport.servicioNombre;
			break;
			case "mttTotal":
				miState.dataToExport.mttTotal = !miState.dataToExport.mttTotal;
			break;
			case "mttParcial":
				miState.dataToExport.mttParcial = !miState.dataToExport.mttParcial;
			break;
			case "detalle":
				miState.dataToExport.detalle = !miState.dataToExport.detalle;
			break;
			case "baseDescripcion":
				miState.dataToExport.baseDescripcion = !miState.dataToExport.baseDescripcion;
			break;
			case "kmGenerado":
				miState.dataToExport.kmGenerado = !miState.dataToExport.kmGenerado;
			break;
			case "KmRealizado":
				miState.dataToExport.KmRealizado = !miState.dataToExport.KmRealizado;
			break;
			case "autogestion":
				miState.dataToExport.autogestion = !miState.dataToExport.autogestion;
			break;
			case "forzado":
				miState.dataToExport.forzado = !miState.dataToExport.forzado;
			break;
			case "abono":
				miState.dataToExport.abono = !miState.dataToExport.abono;
			break;
			case "fechaHoraRealizado":
				miState.dataToExport.fechaHoraRealizado = !miState.dataToExport.fechaHoraRealizado;
			break;
			case "gerenciadorRazonSocial":
				miState.dataToExport.gerenciadorRazonSocial = !miState.dataToExport.gerenciadorRazonSocial;
			break;
			case "tallerRazonSocial":
				miState.dataToExport.tallerRazonSocial = !miState.dataToExport.tallerRazonSocial;
			break;
			case "fechaHoraTurno":
				miState.dataToExport.fechaHoraTurno = !miState.dataToExport.fechaHoraTurno;
			break;
			case "enTaller":
				miState.dataToExport.enTaller = !miState.dataToExport.enTaller;
			break;
			case "observacionesTaller":
				miState.dataToExport.observacionesTaller = !miState.dataToExport.observacionesTaller;
			break;
			case "presupuestoManoDeObra":
				miState.dataToExport.presupuestoManoDeObra = !miState.dataToExport.presupuestoManoDeObra;
			break;
			case "presupuestoRepuestos":
				miState.dataToExport.presupuestoRepuestos = !miState.dataToExport.presupuestoRepuestos;
			break;
			case "presupuestoImpuestos":
				miState.dataToExport.presupuestoImpuestos = !miState.dataToExport.presupuestoImpuestos;
			break;
			case "presupuestoOtros":
				miState.dataToExport.presupuestoOtros = !miState.dataToExport.presupuestoOtros;
			break;
			case "presupuestoTotal":
				miState.dataToExport.presupuestoTotal = !miState.dataToExport.presupuestoTotal;
			break;
			case "presupuestoAdicional":
				miState.dataToExport.presupuestoAdicional = !miState.dataToExport.presupuestoAdicional;
			break;
			case "presupuestoNombre":
				miState.dataToExport.presupuestoNombre = !miState.dataToExport.presupuestoNombre;
			break;
			case "presupuestoFechaHora":
				miState.dataToExport.presupuestoFechaHora = !miState.dataToExport.presupuestoFechaHora;
			break;
			case "factura":
				miState.dataToExport.factura = !miState.dataToExport.factura;
			break;
			case "esSiniestro":
				miState.dataToExport.esSiniestro = !miState.dataToExport.esSiniestro;
			break;
			case "numeroSiniestro":
				miState.dataToExport.numeroSiniestro = !miState.dataToExport.numeroSiniestro;
      		break;
			case "region":
				miState.dataToExport.region = !miState.dataToExport.region;
     		 break;
			case "subRegion":
				miState.dataToExport.subRegion = !miState.dataToExport.subRegion;
			break;
			case "inmovilizado":
				miState.dataToExport.inmovilizado = !miState.dataToExport.inmovilizado;
			break;
      		case "ordenTrabajo":
				miState.dataToExport.ordenTrabajo = !miState.dataToExport.ordenTrabajo;
			break;
			case "centroCostosNombre":
				miState.dataToExport.centroCostosNombre = !miState.dataToExport.centroCostosNombre;
			break;
			case "centroBeneficiosNombre":
				miState.dataToExport.centroBeneficiosNombre = !miState.dataToExport.centroBeneficiosNombre;
			break;
			case "movilFuncion":
				miState.dataToExport.movilFuncion = !miState.dataToExport.movilFuncion;
			break;
			case "movilCompaniaOrigen":
				miState.dataToExport.movilCompaniaOrigen = !miState.dataToExport.movilCompaniaOrigen;
			break;
			case "tareaNombre":
				miState.dataToExport.tareaNombre = !miState.dataToExport.tareaNombre;
			break;
			case "responsable1NombreApellido":
				miState.dataToExport.responsable1NombreApellido = !miState.dataToExport.responsable1NombreApellido;
			break;
			case "encuestaSatisfaccion":
				miState.dataToExport.encuestaNivelSatisfaccion = !miState.dataToExport.encuestaNivelSatisfaccion;
				miState.dataToExport.encuestaComentarios = !miState.dataToExport.encuestaComentarios;
			break;
			case "nivelAprobacionPresupuesto":
				miState.dataToExport.nivelAprobacionPresupuesto = !miState.dataToExport.nivelAprobacionPresupuesto;
			break;
			case "usuarioCreacionNombreApellido":
				miState.dataToExport.usuarioCreacionNombreApellido = !miState.dataToExport.usuarioCreacionNombreApellido;
			break;
			case "esReemplazoDeVehiculo":
				miState.dataToExport.esReemplazoDeVehiculo = !miState.dataToExport.esReemplazoDeVehiculo;
				miState.dataToExport.fechaEntrega = !miState.dataToExport.fechaEntrega;
				miState.dataToExport.placaReemplazo = !miState.dataToExport.placaReemplazo;
			break;
			case "otAsociada":
				miState.dataToExport.otAsociada = !miState.dataToExport.otAsociada;
			break;
			case "tipoModelo":
				miState.dataToExport.tipoModelo = !miState.dataToExport.tipoModelo;
			break;
      case "ticketGarantia":
				miState.dataToExport.ticketGarantia = !miState.dataToExport.ticketGarantia;
			break;
		}

		this.setState(miState);
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}


	handleNew(event) {
		this.setState({
			redirectTo: '../correctivos/add'
		});
	}

	handleNewMenu(event) {
		if ($('#divNewMenu').find('i').hasClass('ft-plus')){
			$('#divNewMenu').find('i').addClass('ft-x');
			$('#divNewMenu').find('i').removeClass('ft-plus');
			$('#divNewCorrectivo').fadeToggle( "slow", "linear" );
			$('#divNewGestoria').fadeToggle( "slow", "linear" );
		} else {
			$('#divNewMenu').find('i').addClass('ft-plus');
			$('#divNewMenu').find('i').removeClass('ft-x');
			$('#divNewCorrectivo').fadeToggle( "slow", "linear" );
			$('#divNewGestoria').fadeToggle( "slow", "linear" );
		}
	}

	handleNewCorrectivo(event) {
		this.setState({
			redirectTo: '../correctivos/add'
		});
	}

	handleNewGestoria(event) {
		this.setState({
			redirectTo: '../ticketsgestoria/add'
		});
	}

	handleFilterPreventivos(event) {
		this.setState({alertClick: null, movilDetail: null, filtroKPI:'PREVENTIVO'});
	}

	handleFilterCorrectivos(event) {
		this.setState({alertClick: null, movilDetail: null, filtroKPI: 'CORRECTIVO'});
	}

	handleFilterGestoria(event) {
		this.setState({alertClick: null, movilDetail: null, filtroKPI: 'GESTORIA'});
	}

	handleFilterVencimientos(event) {
		this.setState({alertClick: null, movilDetail: null, filtroKPI: 'VENCIMIENTO'});
	}

	handleFilterEnTaller(event) {
		this.setState({alertClick: null, movilDetail: null, filtroKPI: 'EN_TALLER'});
	}

	handleExportCancel() {
		$('#export_modal').modal('toggle');
	}

	handleExportExcel() {
		let component = this;
		let nameExport = "";
		this.setState({ loading: true });
		component.ajaxHandler.fetch('/tickets/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
				fechaDesde: this.state.exportFechaDesde.format('YYYY-MM-DD'),
				fechaHasta: this.state.exportFechaHasta.format('YYYY-MM-DD'),
				colsAExportar: this.state.dataToExport,
				queryFilters: this.state.queryFilters
			})
		}).then(response => {
			if (response.status === 200) {
				let headerContentDisposition = response.headers.get('content-disposition').split(';');
				let filename = headerContentDisposition[1].split('=')
				nameExport = (filename[1]).replace(/["]+/g, '')
				return response.blob();
			}
		}).then(file => {
			let fileUrl = URL.createObjectURL(file);
			$("<a />", {
				href: fileUrl,
				download: `${nameExport}`
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportCancel();
			this.setState({ loading: false });
		});
	}

	handleAlertClick(filter){
		this.setState({alertClick: filter, movilDetail: null}, ()=>{ this.table.ajax.reload()});

	}

  handleExportHistoricoCancel() {
		$('#export_historico_modal').modal('toggle');
	}

	handleExportHistoricoExcel() {
		let component = this;
		this.setState({ loading: true });
		component.ajaxHandler.fetch('/ticket-historico/exportar-excel-multiple', {
			method: 'POST',
			body: JSON.stringify({
				fechaDesde: this.state.exportHistoricoFechaDesde.format('YYYY-MM-DD HH:mm:ss'),
				fechaHasta: this.state.exportHistoricoFechaHasta.format('YYYY-MM-DD HH:mm:ss'),
			})
		}).then(response => {
      if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-tickets-historico.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportHistoricoCancel();
			this.setState({ loading: false });
		});
	}

	async handleExportComentariosPDF(dt) {
		const mostrarComentarios = false;

		const tablaBody = [];

		const rowData = dt.data().toArray();
		// Obtener columnas desde Datatable
		const columnas = dt.settings().init().columns;
		const columnasSeleccionadas = Object.entries(columnas).filter(([i,v])=> dt.column(i).visible() && v.title);
		const tablaTitleProp = columnasSeleccionadas.map(([i,v])=>v.title);
		const tablaDataProp = columnasSeleccionadas.map(([i,v])=>v.data);

		let ticketComentarios = null;
		if(mostrarComentarios) {
			//Obtiene todos los comentarios de los ticketsId
			const rowsWithComments = rowData.filter((el) => el.comentario !== null).map((item)=>item.id);
			ticketComentarios = await Promise.all(
				rowsWithComments.map(async (_ticketId) => {
					let _res = await this.ajaxHandler.getJson(`/ticket-comentarios/ticket/${_ticketId}/grid`)
					return {
						id: _ticketId,
						comentarios: _res.map(v => v.comentario)
					}
				})
			);
		}

		tablaBody.push(
			tablaTitleProp.map((el)=>{
				return { text: el, style: 'tabla_header' }
			})
		);

		rowData.forEach((el) => {
			let fila = tablaDataProp.map(v=>'');
			Object.entries(el).forEach(([i,v])=>{
				let obj = { style: 'tabla_row', text: v };
				if(tablaDataProp.includes(i)) {
					switch (i) {
						case 'fechaHoraAlta':
								obj.text = Timezone.getDateForClient(v, 'YYYY-MM-DD HH:mm:ss', 'L');
							break;
						case 'servicio':
								obj.text = v.nombre;
							break;
						case 'tipoEntidadColumnGrid':
								obj.text = v.descripcion;
							break;
						case 'gerenciador':
								obj.text = (v===null) ? '' : v.razonSocial;
							break;
						case 'base':
								obj.text = v.descripcion;
							break;
						default:
								obj.text = (v===null) ? '' : v;
							break;
					}
					fila[tablaDataProp.indexOf(i)] = obj;
				}
			});
			tablaBody.push(fila);

			// Adjunta comentarios
			if(mostrarComentarios && ticketComentarios != null) {
				if(el.comentario) {
					const row_comentarios = ticketComentarios.filter((v) => v.id === el.id).map(v=>v.comentarios).shift();
					if(row_comentarios) {
						tablaBody.push([
							'',
							{style: 'comentario', colSpan: tablaTitleProp.length - 1, text: row_comentarios.join('\n')},
						]);
					}
				}
			}
		});

		const file = {
			pageSize: {
				width: 842,
				height: 595
			},
			pageMargins: [30, 30, 30, 30],
			content: [
				{
					columns: [
						{
							alignment: 'left',
							image: LogoCompany,
							fit: [100,100]
						},
						{
							alignment: 'right',
							image: LogoVecFleet,
							fit: [40,40]
						},
					]
				},
				{
					text: this.props.intl.formatMessage({ id: 'ticketsGrid.print_content.header_listado_de_perfiles', defaultMessage: 'Listado de Tickets' }),
					style: 'header'
				},
				{
					style: 'tabla',
					table: {
						widths: tablaDataProp.map(col=> (col==='detalle') ? '*' : 'auto' ),
						heights: 15,
						body: tablaBody
					},
					layout: 'noBorders'
				},
			],
			styles: {
				tabla: {
					margin: [0, 0, 0, 0]
				},
				tabla_header: {
					bold: true,
					color: 'white',
					fillColor: '#1D0D38'
				},
				tabla_row: {
					color: 'gray'
				},
				comentario: {
					bold: true,
				},
				header: {
					fontSize: 13,
					bold: true,
					margin: [0, 0, 0, 10]
				},
			},
			defaultStyle: {
				alignment: 'left',
				fontSize: 10
			}
		};

		pdfMake.createPdf(file).open();
	}

	handleQueryFilters(queryFilters){
		let grillaFiltrada = false;
		let queryFiltersState = [];
		let ticketTipoDefault = (ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' && queryFilters['ticketTipo'] !== 'DEFAULT') ? true : false;

		Object.entries(queryFilters).map(([key, value]) => {
			if (value && (ticketTipoDefault || (key === 'estado' && value !== 'PENDIENTES') )){
				queryFiltersState.push(key);
			}
		})

		if(queryFiltersState.length > 0 | this.state.filtroKPI !== null)
			grillaFiltrada = true

		this.setState({
			queryFilters: queryFilters,
			grillaFiltrada: grillaFiltrada
		})
	}

	handleDropdownActive(state){
		this.setState({
			dropdownActive: state
		})
	}

	handleLimpiarFiltros(){
		this.setState({
			limpiarFiltros: false,
			filtroKPI: null,
			grillaFiltrada: false
		})
	}

	render() {
		let dt = this.table;
		var viewCount = "";
		let columnsEnabled = this.columnsEnabled;
		{ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? (
			viewCount = "col-xl-2 col-lg-6 col-12 cards-5"
		) : (
			viewCount = "col-xl-3 col-lg-6 col-12 cards-4"
		)}
		return (
			<React.Fragment>
			{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
			{this.state.loading && <Loading />}
			<section id="minimal-gradient-statistics-bg" className="ticketWidget">
				<div className="row justify-content-around">
				<div className={viewCount} style={{cursor: 'pointer'}} onClick={this.handleFilterPreventivos.bind(this)}>
						<div className="card bg-gradient-directional-preventivo">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<i className="ft-check-circle text-white font-large-2 float-left"></i>
										</div>
										<div className="media-body text-white text-right">
											<h3 className="text-white">{this.state.estadisticas && this.state.estadisticas.cantidadPreventivos ? this.state.estadisticas.cantidadPreventivos : '0' }</h3>
											<span><FormattedMessage id="ticketsGrid.render.button_filter_preventivos" defaultMessage="Preventivos"/></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={viewCount} style={{cursor: 'pointer'}} onClick={this.handleFilterCorrectivos.bind(this)}>
						<div className="card bg-gradient-directional-correctivo">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<i className="icon-wrench text-white font-large-2 float-left"></i>
										</div>
										<div className="media-body text-white text-right">
											<h3 className="text-white">{this.state.estadisticas && this.state.estadisticas.cantidadCorrectivos ? this.state.estadisticas.cantidadCorrectivos : '0' }</h3>
											<span><FormattedMessage id="ticketsGrid.render.button_filter_correctivos" defaultMessage="Correctivos"/></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={viewCount} style={{cursor: 'pointer'}} onClick={this.handleFilterVencimientos.bind(this)}>
						<div className="card bg-gradient-directional-vencimiento">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<i className="icon-credit-card font-size-3-5rem text-white float-left"></i>
										</div>
										<div className="media-body text-white text-right">
											<h3 className="text-white">{this.state.estadisticas && this.state.estadisticas.cantidadVencimientos ? this.state.estadisticas.cantidadVencimientos : '0' }</h3>
											<span><FormattedMessage id="ticketsGrid.render.button_filter_vencimientos" defaultMessage="Vencimientos"/></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? (
						<div className={viewCount} style={{cursor: 'pointer'}} onClick={this.handleFilterGestoria.bind(this)}>
						<div className="card bg-gradient-directional-gestoria">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<i className="icon-briefcase font-size-3-5rem text-white float-left"></i>
										</div>
										<div className="media-body text-white text-right">
											<h3 className="text-white">{this.state.estadisticas && this.state.estadisticas.cantidadGestoria ? this.state.estadisticas.cantidadGestoria : '0' }</h3>
											<span><FormattedMessage id="ticketsGrid.render.button_filter_gestorias" defaultMessage="Gestorias"/></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
						) : (
							null
						)}
						<div className={viewCount} style={{cursor: 'pointer'}} onClick={this.handleFilterEnTaller.bind(this)}>
						<div className="card bg-gradient-directional-en-taller">
							<div className="card-content">
								<div className="card-body">
									<div className="media d-flex">
										<div className="align-self-center">
											<i className="fas fa-warehouse text-white font-large-2 float-left"></i>
										</div>
										<div className="media-body text-white text-right">
										<h3 className="text-white">{this.state.estadisticas && this.state.estadisticas.cantidadEnTaller ? this.state.estadisticas.cantidadEnTaller : '0' }</h3>
											<span><FormattedMessage id="ticketsGrid.render.button_filter_en_taller" defaultMessage="En Taller"/></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								<div className="container-fluid">
									<div className="row dt-icons">
                    <div className="col-6">
										{ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'false' ? (
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'ticketsGrid.render.add_tickets.button_title_nuevo', defaultMessage: 'Nuevo' })}>
													<i className="ft-plus"></i>
												</div>
										) : (
                        <>
                                                { Security.hasPermission('TICKETS_CREAR') ?
												<div id="divNewMenu" className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNewMenu.bind(this)}>
													<i className="ft-plus"></i>
												</div> : null }
                                                { Security.hasPermission('MANTENIMIENTOS_CORRECTIVOS_CREAR') ?
												<div id="divNewCorrectivo" style={{display:"none"}} className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNewCorrectivo.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'ticketsGrid.render.add_tickets.button_title_nuevo_ticket_correctvo', defaultMessage: 'Nuevo Ticket Correctvo' })}>
												<i className="la la-wrench" style={{fontSize:'1.8rem'}}></i>
												</div> : null }
                                                { Security.hasPermission('TICKETS_GESTORIA_CREAR') ?
												<div id="divNewGestoria" style={{display:"none"}}  className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNewGestoria.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'ticketsGrid.render.add_tickets.button_title_nuevo_ticket_de_gestoria', defaultMessage: 'Nuevo Ticket de Gestoría' })}>
													<i className="la la-briefcase" style={{fontSize:'1.8rem'}}></i>
												</div> : null }
                        </>
										)}
                        <div
                        className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                        style={{ height: 52 }}
                        onClick={() => this.setState({dropdownActive: !this.state.dropdownActive})}
                        >
                        {this.state.dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                        </div>
                    </div>
										<div className="col-6" id="buttons"></div>
									</div>
								</div>
								<div>
									<TicketGridFilters
										dtData={dt}
										columnsEnabled={this.columnsEnabled}
										dropdownActive={this.state.dropdownActive}
										callbackDropdownActive={this.handleDropdownActive.bind(this)}
										queryFilters={this.state.queryFilters}
										callbackQueryFilters={this.handleQueryFilters.bind(this)}
										limpiarFiltros={this.state.limpiarFiltros}
										callbackLimpiarFiltros={this.handleLimpiarFiltros.bind(this)}
										filtroKPI={this.state.filtroKPI}
									/>
								</div>
								<div className="table-responsive">
									<table id="dataTable" className="narrow table nowrap server-side table-hover" ref="grid" width="100%">
										<tfoot>
										<tr style={{backgroundColor: '#fff'}}>
											<th className=""></th>
											<th className=""></th>
											<th className="dt-search-header"></th>
											<th className="dt-search-header"></th>
											<th className={columnsEnabled.nivelAprobacionPresupuesto ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className="dt-search-header"></th>
											<th className="dt-search-header"></th>
											<th className="dt-search-header"></th>
											<th className="dt-search-header"></th>
											<th className="dt-search-header"></th>
											<th className={columnsEnabled.region ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className="dt-search-header"></th>
											<th className={columnsEnabled.centroCostos ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className={columnsEnabled.centroBeneficios ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className={columnsEnabled.responsable1 ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className="dt-search-header"></th>
											<th className="dt-search-header"></th>
											<th className="dt-search-header"></th>
											<th className={columnsEnabled.encuestaSatisfaccion ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className="dt-search-header"></th>
											<th className={columnsEnabled.fechaHoraTurno ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className={columnsEnabled.abono ? "dt-search-header" : "dt-search-header d-none"}></th>
											<th className=""></th>
											<th className=""></th>
											<th className=""></th>
										</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
				<div className="modal-xl modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="ticketsGrid.render.export_modal.header_exportar_tickets" defaultMessage=" Exportar Tickets"/></h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							<div className="row">
								<div className="col-md-12">
									{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
										{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
									</div>*/}
									<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
										<div className="form-body">
											<div className="card pull-up">
												<div className="card-content">
													<div className="card-body">
														<h4 className="form-section">
															<i className="la la-filter"></i><FormattedMessage id="ticketsGrid.render.export_modal.filter.header_filtros" defaultMessage=" Filtros"/>
															<div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="ticketsGrid.render.export_modal.filter.campos_requeridos" defaultMessage="campos requeridos"/></div>
														</h4>
														<div className="form-group row col-md-12">
															<label className="col-md-2 label-control col-form-label" htmlFor="manoDeObra">
																<FormattedMessage id="ticketsGrid.render.export_modal.filter.label_desde" defaultMessage="Desde "/>*:
															</label>
															<div className="col-md-2">
																<DatePicker
																	popperContainer={CalendarContainer}
																	id="exportFechaDesde"
																	name="exportFechaDesde"
																	className="form-control date-picker-placeholder"
																	placeholderText={this.props.intl.formatMessage({ id: 'ticketsGrid.render.export_modal.filter.placeholder_desde', defaultMessage: 'DD/MM/AAAA' })}
																	maxDate={this.state.exportFechaHasta}
																	openToDate={this.state.exportFechaDesde ? this.state.exportFechaDesde : moment()}
																	selected={this.state.exportFechaDesde ? this.state.exportFechaDesde : null }
																	onChange={(event) => {
																			let newState = {};
																			newState.exportFechaDesde = event ? moment(event.format()) : null;
																			if(event && this.state.exportFechaHasta && this.state.exportFechaHasta.clone().subtract(1, 'years').diff(moment(event.format()), 'days') >= 0) {
																				newState.exportFechaHasta = newState.exportFechaDesde.clone().add(1, 'years').subtract(1, 'days');
																			}
																			this.setState(newState);
																		}
																	}
																	showMonthDropdown
																	showYearDropdown
																	dropdownMode="select"
																	selectsStart
																	startDate={this.state.exportFechaDesde}
																	endDate={this.state.exportFechaHasta}
																/>
															</div>
															<label className="col-md-2 label-control col-form-label" htmlFor="manoDeObra">
																<FormattedMessage id="ticketsGrid.render.export_modal.filter.label_hasta" defaultMessage="Hasta "/>*:
															</label>
															<div className="col-md-2">
																<DatePicker
																	popperContainer={CalendarContainer}
																	id="exportFechaHasta"
																	name="exportFechaHasta"
																	className="form-control date-picker-placeholder"
																	placeholderText={this.props.intl.formatMessage({ id: 'ticketsGrid.render.export_modal.filter.placeholder_hasta', defaultMessage: 'DD/MM/AAAA' })}
																	minDate={this.state.exportFechaDesde}
																	maxDate={moment()}
																	openToDate={this.state.exportFechaHasta ? this.state.exportFechaHasta : moment()}
																	selected={this.state.exportFechaHasta ? this.state.exportFechaHasta : null}
																	onChange={(event) => {
																		let newState = {};
																		newState.exportFechaHasta = event ? moment(event.format()) : null;
																		if(event && this.state.exportFechaDesde && moment(event.format()).diff(this.state.exportFechaDesde.clone().add(1, 'years'), 'days') >= 0) {
																			newState.exportFechaDesde = newState.exportFechaHasta.clone().subtract(1, 'years').add(1, 'days');
																		}
																		this.setState(newState);
																	}}
																	showMonthDropdown
																	showYearDropdown
																	dropdownMode="select"
																	selectsEnd
																	startDate={this.state.exportFechaDesde}
																	endDate={this.state.exportFechaHasta}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="card pull-up">
												<div className="card-content">
													<div className="card-body">
														<h4 className="form-section">
															<i className="la la-columns"></i><FormattedMessage id="ticketsGrid.render.export_modal.columns_export.header_columnas_a_exportar" defaultMessage=" Columnas a exportar"/>
														</h4>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="idTicket">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_nro_ticket" defaultMessage="Nro. Ticket:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("id")}
																	checked = {this.state.dataToExport.id ? true : false}
																	value={this.state.dataToExport.id}
																	id = "id"
																	name = "id"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>

															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="personaNombreApellido">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_persona" defaultMessage="Persona:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("personaNombreApellido")}
																	checked = {this.state.dataToExport.personaNombreApellido ? true : false}
																	value={this.state.dataToExport.personaNombreApellido}
																	id = "personaNombreApellido"
																	name = "personaNombreApellido"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="movilDominio">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_movil:" defaultMessage="Móvil:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("movilDominio")}
																	checked = {this.state.dataToExport.movilDominio ? true : false}
																	value={this.state.dataToExport.movilDominio}
																	id = "movilDominio"
																	name = "movilDominio"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
                              </div>

                              <div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="movilEstado">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_movil_estado" defaultMessage="Movil Estado"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("movilEstado")}
																	checked = {this.state.dataToExport.movilEstado ? true : false}
																	value={this.state.dataToExport.movilEstado}
																	id = "movilEstado"
																	name = "movilEstado"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="tipo">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_tipo" defaultMessage="Tipo:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("tipo")}
																	checked = {this.state.dataToExport.tipo ? true : false}
																	value={this.state.dataToExport.tipo}
																	id = "tipo"
																	name = "tipo"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="servicioNombre">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_servicio" defaultMessage="Servicio:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("servicioNombre")}
																	checked = {this.state.dataToExport.servicioNombre ? true : false}
																	value={this.state.dataToExport.servicioNombre}
																	id = "servicioNombre"
																	name = "servicioNombre"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="estado">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_estado" defaultMessage="Estado:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="estado"
																	name="estado"
																	value={this.state.dataToExport.estado}
																	onChange={(event) => this.handleDataExport("estado")}
																	checked={this.state.dataToExport.estado ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="fechaHoraAlta">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_fecha_de_alta" defaultMessage="Fecha de Alta:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="fechaHoraAlta"
																	name="fechaHoraAlta"
																	value={this.state.dataToExport.fechaHoraAlta}
																	onChange={(event) => this.handleDataExport("fechaHoraAlta" )}
																	checked={this.state.dataToExport.fechaHoraAlta ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="mttTotal">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_mtt_total" defaultMessage="Mtt Total:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="mttTotal"
																	name="mttTotal"
																	value={this.state.dataToExport.mttTotal}
																	onChange={(event) => this.handleDataExport("mttTotal")}
																	checked={this.state.dataToExport.mttTotal ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="mttParcial">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_mtt_parcial" defaultMessage="Mtt Parcial:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="mttParcial"
																	name="mttParcial"
																	value={this.state.dataToExport.mttParcial}
																	onChange={(event) => this.handleDataExport("mttParcial")}
																	checked={this.state.dataToExport.mttParcial ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="detalle">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_detalle" defaultMessage="Detalle:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="detalle"
																	name="detalle"
																	value={this.state.dataToExport.detalle}
																	onChange={(event) => this.handleDataExport("detalle")}
																	checked={this.state.dataToExport.detalle ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false} />
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="baseDescripcion">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_base" defaultMessage="Base:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="baseDescripcion"
																	name="baseDescripcion"
																	value={this.state.dataToExport.baseDescripcion}
																	onChange={(event) => this.handleDataExport("baseDescripcion")}
																	checked={this.state.dataToExport.baseDescripcion ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																/>
															</div>
														</div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="kmGenerado">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_km_gen" defaultMessage="Km Gen:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="kmGenerado"
																	name="kmGenerado"
																	value={this.state.dataToExport.kmGenerado}
																	onChange={(event) => this.handleDataExport("kmGenerado")}
																	checked={this.state.dataToExport.kmGenerado ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="KmRealizado">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_km_realizado" defaultMessage="Km Realizado:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="KmRealizado"
																	name="KmRealizado"
																	value={this.state.dataToExport.KmRealizado}
																	onChange={(event) => this.handleDataExport("KmRealizado")}
																	checked={this.state.dataToExport.KmRealizado ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="autogestion">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_autogestion" defaultMessage="Autogestión:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="autogestion"
																	name="autogestion"
																	value={this.state.dataToExport.autogestion}
																	onChange={(event) => this.handleDataExport("autogestion")}
																	checked={this.state.dataToExport.autogestion ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="forzado">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_forzado" defaultMessage="Forzado:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="forzado"
																	name="forzado"
																	value={this.state.dataToExport.forzado}
																	onChange={(event) => this.handleDataExport("forzado")}
																	checked={this.state.dataToExport.forzado ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
														</div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="abono">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_abono" defaultMessage="Abono:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="abono"
																	name="abono"
																	value={this.state.dataToExport.abono}
																	onChange={(event) => this.handleDataExport("abono")}
																	checked={this.state.dataToExport.abono ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="fechaHoraRealizado">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_fec_realizado" defaultMessage="Fec Realizado:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="fechaHoraRealizado"
																	name="fechaHoraRealizado"
																	value={this.state.dataToExport.fechaHoraRealizado}
																	onChange={(event) => this.handleDataExport("fechaHoraRealizado")}
																	checked={this.state.dataToExport.fechaHoraRealizado ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="gerenciadorRazonSocial">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_gerenciador" defaultMessage="Gerenciador:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="gerenciadorRazonSocial"
																	name="gerenciadorRazonSocial"
																	value={this.state.dataToExport.gerenciadorRazonSocial}
																	onChange={(event) => this.handleDataExport("gerenciadorRazonSocial")}
																	checked={this.state.dataToExport.gerenciadorRazonSocial ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="tallerRazonSocial">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_taller" defaultMessage="Taller:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="tallerRazonSocial"
																	name="tallerRazonSocial"
																	value={this.state.dataToExport.tallerRazonSocial}
																	onChange={(event) => this.handleDataExport("tallerRazonSocial")}
																	checked={this.state.dataToExport.tallerRazonSocial ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
														</div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="fechaHoraTurno">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_fecha_turno" defaultMessage="Fecha Turno:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="fechaHoraTurno"
																	name="fechaHoraTurno"
																	value={this.state.dataToExport.fechaHoraTurno}
																	onChange={(event) => this.handleDataExport("fechaHoraTurno")}
																	checked={this.state.dataToExport.fechaHoraTurno ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="enTaller">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_en_taller" defaultMessage="En Taller:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="enTaller"
																	name="enTaller"
																	value={this.state.dataToExport.enTaller}
																	onChange={(event) => this.handleDataExport("enTaller")}
																	checked={this.state.dataToExport.enTaller ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
                              { this.state.inmovilizadoEnabled ?
                                <div className=" col-md-3">
                                  <label className="col-md-6 label-control col-form-label" htmlFor="activo">
                                    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_inmovilizado" defaultMessage="Inmovilizado:"/>
                                  </label>
                                  <Switch
                                    onChange = {(event) => this.handleDataExport("inmovilizado")}
                                    checked = {this.state.dataToExport.inmovilizado ? true : false}
                                    value={this.state.dataToExport.inmovilizado}
                                    id = "inmovilizado"
                                    name = "inmovilizado"
                                    offColor = "#FF4961"
                                    onColor = "#28D094"
                                    disabled = {false}
                                    className =  "col-md-6 align-middle"
                                  />
                                </div> : ''}
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="observacionesTaller">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_observacion" defaultMessage="Observacion:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="observacionesTaller"
																	name="observacionesTaller"
																	value={this.state.dataToExport.observacionesTaller}
																	onChange={(event) => this.handleDataExport("observacionesTaller")}
																	checked={this.state.dataToExport.observacionesTaller ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
                                                        </div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoManoDeObra">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_mano_de_obra" defaultMessage="Mano de Obra:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoManoDeObra"
																	name="presupuestoManoDeObra"
																	value={this.state.dataToExport.presupuestoManoDeObra}
																	onChange={(event) => this.handleDataExport("presupuestoManoDeObra")}
																	checked={this.state.dataToExport.presupuestoManoDeObra ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoRepuestos">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_repuestos" defaultMessage="Repuestos:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoRepuestos"
																	name="presupuestoRepuestos"
																	value={this.state.dataToExport.presupuestoRepuestos}
																	onChange={(event) => this.handleDataExport("presupuestoRepuestos")}
																	checked={this.state.dataToExport.presupuestoRepuestos ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>

															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoImpuestos">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_impuestos" defaultMessage="Impuestos:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoImpuestos"
																	name="presupuestoImpuestos"
																	value={this.state.dataToExport.presupuestoImpuestos}
																	onChange={(event) => this.handleDataExport("presupuestoImpuestos")}
																	checked={this.state.dataToExport.presupuestoImpuestos ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>

															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoOtros">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_otros" defaultMessage="Otros:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoOtros"
																	name="presupuestoOtros"
																	value={this.state.dataToExport.presupuestoOtros}
																	onChange={(event) => this.handleDataExport("presupuestoOtros")}
																	checked={this.state.dataToExport.presupuestoOtros ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
														</div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoTotal">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_total" defaultMessage="Total:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoTotal"
																	name="presupuestoTotal"
																	value={this.state.dataToExport.presupuestoTotal}
																	onChange={(event) => this.handleDataExport("presupuestoTotal")}
																	checked={this.state.dataToExport.presupuestoTotal ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoAdicional">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_adicional" defaultMessage="Adicional:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoAdicional"
																	name="presupuestoAdicional"
																	value={this.state.dataToExport.presupuestoAdicional}
																	onChange={(event) => this.handleDataExport("presupuestoAdicional")}
																	checked={this.state.dataToExport.presupuestoAdicional ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>

															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoNombre">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_ppto_nombre" defaultMessage="Ppto Nombre:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoNombre"
																	name="presupuestoNombre"
																	value={this.state.dataToExport.presupuestoNombre}
																	onChange={(event) => this.handleDataExport("presupuestoNombre")}
																	checked={this.state.dataToExport.presupuestoNombre ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="presupuestoFechaHora">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_ppto_fecha" defaultMessage="Ppto Fecha:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="presupuestoFechaHora"
																	name="presupuestoFechaHora"
																	value={this.state.dataToExport.presupuestoFechaHora}
																	onChange={(event) => this.handleDataExport("presupuestoFechaHora")}
																	checked={this.state.dataToExport.presupuestoFechaHora ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
														</div>
														<div className="row m-1">
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="factura">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_factura" defaultMessage="Factura:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="factura"
																	name="factura"
																	value={this.state.dataToExport.factura}
																	onChange={(event) => this.handleDataExport("factura")}
																	checked={this.state.dataToExport.factura ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="esSiniestro">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_siniestro" defaultMessage="Siniestro:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="esSiniestro"
																	name="esSiniestro"
																	value={this.state.dataToExport.esSiniestro}
																	onChange={(event) => this.handleDataExport("esSiniestro")}
																	checked={this.state.dataToExport.esSiniestro ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>

															<div className="col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="numeroSiniestro">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_nro_siniestro" defaultMessage="Nro Siniestro:"/>
																</label>
																<Switch
																	className =  "col-md-6 align-middle"
																	id="numeroSiniestro"
																	name="numeroSiniestro"
																	value={this.state.dataToExport.numeroSiniestro}
																	onChange={(event) => this.handleDataExport("numeroSiniestro")}
																	checked={this.state.dataToExport.numeroSiniestro ? true : false}
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="ordenTrabajo">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_nro_oT" defaultMessage="Nro OT:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("ordenTrabajo")}
																	checked = {this.state.dataToExport.ordenTrabajo ? true : false}
																	value={this.state.dataToExport.ordenTrabajo}
																	id = "ordenTrabajo"
																	name = "ordenTrabajo"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																	/>
															</div>
														</div>
														<div className="row m-1">
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombre">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_centro_costos" defaultMessage="Centro Costos:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("centroCostosNombre")}
																	checked = {this.state.dataToExport.centroCostosNombre ? true : false}
																	value={this.state.dataToExport.centroCostosNombre}
																	id = "centroCostosNombre"
																	name = "centroCostosNombre"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="responsable1NombreApellido">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_responsable1" defaultMessage="Responsable:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("responsable1NombreApellido")}
																	checked = {this.state.dataToExport.responsable1NombreApellido ? true : false}
																	value={this.state.dataToExport.responsable1NombreApellido}
																	id = "responsable1NombreApellido"
																	name = "responsable1NombreApellido"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>

															{ConfigBusiness.get('tickets.centroBeneficiosGrilla.habilitado') === 'true' ? (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="centroBeneficiosNombre">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_centro_beneficios:" defaultMessage="Centro Beneficios:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("centroBeneficiosNombre")}
																	checked = {this.state.dataToExport.centroBeneficiosNombre ? true : false}
																	value={this.state.dataToExport.centroBeneficiosNombre}
																	id = "centroBeneficiosNombre"
																	name = "centroBeneficiosNombre"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
														) : ''}
														{this.state.regionEnabled ? (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="region">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_region" defaultMessage="Región:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("region")}
																	checked = {this.state.dataToExport.region ? true : false}
																	value={this.state.dataToExport.region}
																	id = "region"
																	name = "region"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
                              							</div>
														) : ''}
														{this.state.regionEnabled ? (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="subRegion">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_sub_region" defaultMessage="Sub Región:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("subRegion")}
																	checked = {this.state.dataToExport.subRegion ? true : false}
																	value={this.state.dataToExport.subRegion}
																	id = "subRegion"
																	name = "subRegion"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															) : ''}
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="llanta">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.serial_llanta" defaultMessage="Llanta:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("serialLlanta")}
																	checked = {this.state.dataToExport.serialLlanta ? true : false}
																	value={this.state.dataToExport.serialLlanta}
																	id = "serialLlanta"
																	name = "serialLlanta"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															{ConfigBusiness.get('moviles.funcion.habilitado') === 'true' ? (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="movilFuncion">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.serial_movil_funcion" defaultMessage="Funcion Movil:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("movilFuncion")}
																	checked = {this.state.dataToExport.movilFuncion ? true : false}
																	value={this.state.dataToExport.movilFuncion}
																	id = "movilFuncion"
																	name = "movilFuncion"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															) :''}
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="movilCompaniaOrigen">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.movil_compania_origen" defaultMessage="Compañía Origen:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("movilCompaniaOrigen")}
																	checked = {this.state.dataToExport.movilCompaniaOrigen ? true : false}
																	value={this.state.dataToExport.movilCompaniaOrigen}
																	id = "movilCompaniaOrigen"
																	name = "movilCompaniaOrigen"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
														      {(this.state.encuestaSatisfaccion) ? (
															<div className=" col-md-3">
															  <label className="col-md-6 label-control col-form-label" htmlFor="encuestaSatisfaccion">
															    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_encuesta_satisfaccion" defaultMessage="Encuesta Satisfaccion:"/>
															  </label>
															  <Switch
															    onChange = {(event) => this.handleDataExport("encuestaSatisfaccion")}
															    checked = {this.state.dataToExport.encuestaNivelSatisfaccion ? true : false}
															    value={this.state.dataToExport.encuestaNivelSatisfaccion}
															    id = "encuestaSatisfaccion"
															    name = "encuestaSatisfaccion"
															    offColor = "#FF4961"
															    onColor = "#28D094"
															    disabled = {false}
															    className =  "col-md-6 align-middle"
															  />
															</div>
														  	) : ''}
                              {(this.state.nivelAprobacionPresupuesto) ? (
															<div className=" col-md-3">
															  <label className="col-md-6 label-control col-form-label" htmlFor="nivelAprobacionPresupuesto">
															    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.nivel_aprobacion_presupuesto" defaultMessage="Nivel Ap. Presupuesto:"/>
															  </label>
															  <Switch
															    onChange = {(event) => this.handleDataExport("nivelAprobacionPresupuesto")}
															    checked = {this.state.dataToExport.nivelAprobacionPresupuesto ? true : false}
															    value={this.state.dataToExport.nivelAprobacionPresupuesto}
															    id = "nivelAprobacionPresupuesto"
															    name = "nivelAprobacionPresupuesto"
															    offColor = "#FF4961"
															    onColor = "#28D094"
															    disabled = {false}
															    className =  "col-md-6 align-middle"
															  />
															</div>
														  	) : ''}
                              <div className=" col-md-3">
															  <label className="col-md-6 label-control col-form-label" htmlFor="usuarioCreacionNombreApellido">
															    <FormattedMessage id="ticketsGrid.render.export_modal.columns_export.label_usuario_creacion" defaultMessage="Usuario Creación:"/>
															  </label>
															  <Switch
															    onChange = {(event) => this.handleDataExport("usuarioCreacionNombreApellido")}
															    checked = {this.state.dataToExport.usuarioCreacionNombreApellido ? true : false}
															    value={this.state.dataToExport.usuarioCreacionNombreApellido}
															    id = "usuarioCreacionNombreApellido"
															    name = "usuarioCreacionNombreApellido"
															    offColor = "#FF4961"
															    onColor = "#28D094"
															    disabled = {false}
															    className =  "col-md-6 align-middle"
															  />
															</div>
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="tareaNombre">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.incluir_desglose_de_tareas_por_ticket" defaultMessage="Incluir desglose de tareas por ticket:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("tareaNombre")}
																	checked = {this.state.dataToExport.tareaNombre ? true : false}
																	value={this.state.dataToExport.tareaNombre}
																	id = "tareaNombre"
																	name = "tareaNombre"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
															{ConfigBusiness.get('tickets.reemplazoVehiculo.habilitado') === 'true' ? (
																<div className=" col-md-3">
																	<label className="col-md-6 label-control col-form-label" htmlFor="esReemplazoDeVehiculo">
																		<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.reemplazo_vehiculo" defaultMessage="Vehículo de Reemplazo"/>:
																	</label>
																	<Switch
																		onChange = {(event) => this.handleDataExport("esReemplazoDeVehiculo")}
																		checked = {this.state.dataToExport.esReemplazoDeVehiculo ? true : false}
																		value={this.state.dataToExport.esReemplazoDeVehiculo}
																		id = "esReemplazoDeVehiculo"
																		name = "esReemplazoDeVehiculo"
																		offColor = "#FF4961"
																		onColor = "#28D094"
																		disabled = {false}
																		className =  "col-md-6 align-middle"
																	/>
																</div>
															) : ''}
															{ConfigBusiness.get('tickets.OTAsociada.habilitado') === 'true' ? (
																<div className=" col-md-3">
																	<label className="col-md-6 label-control col-form-label" htmlFor="otAsociada">
																		<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.reemplazo_vehiculo" defaultMessage="OT. Asociada"/>:
																	</label>
																	<Switch
																		onChange = {(event) => this.handleDataExport("otAsociada")}
																		checked = {this.state.dataToExport.otAsociada ? true : false}
																		value={this.state.dataToExport.otAsociada}
																		id = "otAsociada"
																		name = "otAsociada"
																		offColor = "#FF4961"
																		onColor = "#28D094"
																		disabled = {false}
																		className =  "col-md-6 align-middle"
																	/>
																</div>
															) : ''}
															{(this.state.tipoModeloEnabled) ? (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="tipoModelo">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.tipo_modelo" defaultMessage="Tipo de Movil:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("tipoModelo")}
																	checked = {this.state.dataToExport.tipoModelo ? true : false}
																	value={this.state.dataToExport.tipoModelo}
																	id = "tipoModelo"
																	name = "tipoModelo"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
														  	) : ''}
                              {(this.state.ticketGarantiaEnabled) ? (
															<div className=" col-md-3">
																<label className="col-md-6 label-control col-form-label" htmlFor="ticketGarantia">
																	<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.ticket_garantia" defaultMessage="Ticket Garantía:"/>
																</label>
																<Switch
																	onChange = {(event) => this.handleDataExport("ticketGarantia")}
																	checked = {this.state.dataToExport.ticketGarantia ? true : false}
																	value={this.state.dataToExport.ticketGarantia}
																	id = "ticketGarantia"
																	name = "ticketGarantia"
																	offColor = "#FF4961"
																	onColor = "#28D094"
																	disabled = {false}
																	className =  "col-md-6 align-middle"
																/>
															</div>
														  	) : ''}
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-12 alert alert-info small" role="alert">
												<FormattedMessage id="ticketsGrid.render.export_modal.columns_export.information_esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos."/>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="modal-footer modal-grey">
							<button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportExcel.bind(this)}><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel"/></button>
							<button type="button" className="btn btn-danger" onClick={this.handleExportCancel}><i className="fa fa-times-circle"></i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.cancelar" defaultMessage=" Cancelar"/></button>
						</div>
					</div>
				</div>
			</div>
{/* Historico */}
      <div className="modal fade" tabIndex="-1" id="export_historico_modal" role="dialog" aria-labelledby="myModalLabel4">
				<div className="modal-lg modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel4"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="ticketsGrid.render.export_historico_modal.header_exportar_tickets_historico" defaultMessage=" Exportar Histórico de Estados de Tickets"/></h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							<div className="row">
								<div className="col-md-12">
									<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
                    <div className="col-md-12 alert alert-info small" role="alert">
                      - <FormattedMessage id="ticketsGrid.render.export_historico_modal.aplica_filtro_fecha_alta_tickets" defaultMessage="El filtro se aplica sobre la Fecha de Alta de los Tickets."/> <br/>
                      - <FormattedMessage id="ticketsGrid.render.export_historico_modal.periodo_meses_maximo" defaultMessage="Se puede abarcar un período de 3 meses máximo por Export. Por defecto, se contempla el último trimestre."/>
                    </div>
                    <div className="form-body">
                        <div className="card pull-up">
                          <div className="card-content">
                            <div className="card-body">
                              <h4 className="form-section">
                                <i className="la la-filter"></i><FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.header_filtros" defaultMessage=" Filtros"/>
                                <div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.campos_requeridos" defaultMessage="campos requeridos"/></div>
                              </h4>
														  <div className="form-group row col-md-12">
                                <label className="col-md-2 label-control col-form-label" htmlFor="exportHistoricoFechaDesde">
                                  <FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.label_desde" defaultMessage="Desde "/>*:
                                </label>
                                <div className="col-md-4">
                                  <DatePicker
                                    dateFormat='DD/MM/YYYY'
                                    popperContainer={CalendarContainer}
                                    id="exportHistoricoFechaDesde"
                                    name="exportHistoricoFechaDesde"
                                    className="form-control date-picker-placeholder"
                                    placeholderText={this.props.intl.formatMessage({ id: 'ticketsGrid.render.export_historico_modal.filter.placeholder_desde', defaultMessage: 'DD/MM/AAAA' })}
                                    maxDate={this.state.exportHistoricoFechaHasta}
                                    openToDate={this.state.exportHistoricoFechaDesde ? this.state.exportHistoricoFechaDesde : moment()}
                                    selected={this.state.exportHistoricoFechaDesde ? this.state.exportHistoricoFechaDesde : null }
                                    onChange={(event) => {
                                        let newState = {};
                                        newState.exportHistoricoFechaDesde = event ? moment(event.format()).startOf('day') : null;
                                        if(event && this.state.exportHistoricoFechaHasta && this.state.exportHistoricoFechaHasta.clone().subtract(3, 'months').diff(moment(event.format()), 'days') >= 0) {
                                          newState.exportHistoricoFechaHasta = newState.exportHistoricoFechaDesde.clone().add(3, 'months').subtract(1, 'days').endOf('day');
                                        }
                                        this.setState(newState);
                                      }
                                    }
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selectsStart
                                    startDate={moment(this.state.exportHistoricoFechaHasta).subtract(3, 'months')}
                                    endDate={this.state.exportHistoricoFechaHasta}
                                  />
															  </div>
                                <label className="col-md-2 label-control col-form-label" htmlFor="exportHistoricoFechaHasta">
																  <FormattedMessage id="ticketsGrid.render.export_historico_modal.filter.label_hasta" defaultMessage="Hasta "/>*:
															  </label>
                                <div className="col-md-4">
                                  <DatePicker
                                    dateFormat='DD/MM/YYYY'
                                    popperContainer={CalendarContainer}
                                    id="exportHistoricoFechaHasta"
                                    name="exportHistoricoFechaHasta"
                                    className="form-control date-picker-placeholder"
                                    placeholderText={this.props.intl.formatMessage({ id: 'ticketsGrid.render.export_historico_modal.filter.placeholder_hasta', defaultMessage: 'DD/MM/AAAA' })}
                                    minDate={this.state.exportHistoricoFechaDesde}
                                    maxDate={moment()}
                                    openToDate={this.state.exportHistoricoFechaHasta ? this.state.exportHistoricoFechaHasta : moment()}
                                    selected={this.state.exportHistoricoFechaHasta ? this.state.exportHistoricoFechaHasta : null}
                                    onChange={(event) => {
                                      let newState = {};
                                      newState.exportHistoricoFechaHasta = event ? moment(event.format()).endOf('day') : null;
                                      if(event && this.state.exportHistoricoFechaDesde && moment(event.format()).diff(this.state.exportHistoricoFechaDesde.clone().add(3, 'months'), 'days') >= 0) {
                                        newState.exportHistoricoFechaDesde = newState.exportHistoricoFechaHasta.clone().subtract(3, 'months').add(1, 'days').startOf('day');
                                      }
                                      this.setState(newState);
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selectsEnd
                                    startDate={this.state.exportHistoricoFechaDesde}
                                    endDate={this.state.exportHistoricoFechaHasta}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 alert alert-info small" role="alert">
												<FormattedMessage id="ticketsGrid.render.export_historico_modal.esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos."/>
											</div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer modal-grey">
							<button type="button" {...(!this.state.exportHistoricoFechaDesde || !this.state.exportHistoricoFechaHasta) && {disabled:true}} className="btn btn-default btn-fleet" onClick={this.handleExportHistoricoExcel.bind(this)}><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel"/></button>
							<button type="button" className="btn btn-danger" onClick={this.handleExportHistoricoCancel}><i className="fa fa-times-circle"></i><FormattedMessage id="ticketsGrid.render.export_modal.finish_button.cancelar" defaultMessage=" Cancelar"/></button>
						</div>
          </div>
        </div>
      </div>

			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;
		let columnsEnabled = this.columnsEnabled;
		Promise.all([
			this.ajaxHandler.getJson('/tickets/estadisticas'),
			,
		]).then((data) => {
			let estadisticas = data[0] ? data[0] : [];
			let dataToExportCopy = JSON.parse(JSON.stringify(component.state.dataToExport));
			dataToExportCopy.inmovilizado = this.state.inmovilizadoEnabled;

			component.setState({
				estadisticas: estadisticas,
				dataToExport: dataToExportCopy
			});

			this.table = $(this.refs.grid).DataTable(
        Object.assign(
          {
            dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
            stateSave: true,
            stateSaveCallback: function (settings, data) {
              // localStorage.setItem("DataTables_TicketsGrid", JSON.stringify(data));
            },
            // stateLoadCallback: function (settings) {
            //   return JSON.parse(localStorage.getItem("DataTables_TicketsGrid"));
            // },
            processing: true,
            serverSide: true,
            order: [[1, "desc"]],
            searchCols: [{ search: "DEFAULT" }, null, null, { search: "PENDIENTES" }],
            ajax: {
              type: "GET",
              url: Config.get("apiUrlBase") + "/tickets/grid",
              headers: {
                "Authorization-Token": localStorage.getItem("token"),
              },
              dataSrc: function (response) {
                return response.data;
              },
              data: function (d) {
				d.extraFilter = component.state.alertClick;
				d.extraFilterMovilDetail = component.state.movilDetail;
              },
              error: function (xhr, error, thrown) {
                component.ajaxHandler.handleResponseErrorsIsValid(xhr);
              },
              cache: false,
            },
            initComplete: function (settings) {
              // let stateColumns = JSON.parse(localStorage.getItem("DataTables_TicketsGrid")).columns;

              // var sets = settings;
              // var index = 0;

              //Si carga tickets a partir del kpi del dashboard se refrescan los valores del filtro estado
              if (component.state.alertClick) {
                let estadoKPI;
                switch (component.state.alertClick.type_kpi) {
                  case "presupuestados":
                    estadoKPI = "PRESUPUESTADO";
                    break;
                  case "listo_para_retirar":
                    estadoKPI = "LISTO_PARA_RETIRAR";
                    break;
                  case "aprobados":
                    estadoKPI = "APROBADO";
                    break;

                  default:
                    estadoKPI = "PENDIENTES";
                    break;
                }

                component.table.column(3).search(estadoKPI, false, true);
                $("#filter_estado").val(estadoKPI);

              }
            },
            columns: [
              {
                name: "tipoTicket",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_type.label_tipo",
                  defaultMessage: "Tipo",
                }),
                data: "ticketTipo",
                className: "all cursor-pointer action edit",
                orderable: true,
                render: function (data, type, full, meta) {
                  if (type === "filter") {
                    return data;
                  } else {
                    return '<div id="avatar"></div>';
                  }
                },
                filterType: "none",
              },
              {
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_Tkt.label_tkt",
                  defaultMessage: "Tkt",
                }),
                name: "id",
                className: "all cursor-pointer action edit",
                data: "id",
                orderable: true,
                filterType: "none",
              },
              {
                name: "indicador",
                title: "",
                data: "semaforo",
                width: "10px",
                padding: 0,
                orderable: false,
                className: "all cursor-pointer action edit",
                render: function (data, type, full, meta) {
                  return "";
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  switch (true) {
                    case cellData === "VERDE":
                      $(td).addClass("status-green");
                      break;
                    case cellData === "AMARILLO":
                      $(td).addClass("status-yellow");
                      break;
                    case cellData === "ROJO":
                      $(td).addClass("status-red");
                      break;
                    default:
                      $(td).addClass("status-grey");
                      break;
                  }
                },
                filterType: "none",
              },
              {
                name: "estado",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_state.label_estado",
                  defaultMessage: "Estado",
                }),
                className: "all cursor-pointer action edit",
                data: "estado",
                filterType: "none",
              },
              {
                name: "nivelAprobacionPresupuesto",
                title: columnsEnabled.nivelAprobacionPresupuesto
                  ? component.props.intl.formatMessage({
                      id: "ticketsGrid.column_state.label_nivelAprobacionPresupuesto",
                      defaultMessage: "Nivel Aprobación",
                    })
                  : "",
                className: columnsEnabled.nivelAprobacionPresupuesto
                  ? "all cursor-pointer action edit"
                  : "d-none",

                data: "nivelAprobacionPresupuesto",
                filterType: "none",
              },
              {
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_create.label_creado",
                  defaultMessage: "Creado",
                }),
                name: "fechaHoraAlta",
                className: "all cursor-pointer action edit",
                data: "fechaHoraAlta",
                render: function (data, type, full, meta) {
                  return data ? Timezone.getDateForClient(data, 'YYYY-MM-DD HH:mm:ss', 'L') : "";
                },
                filterType: "none",
              },
              {
                name: "detalle",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_detail.label_detalle",
                  defaultMessage: "Detalle",
                }),
                className: "all cursor-pointer action edit",
                data: "detalle",
                render: function (data, type, full, meta) {
                  if (type === "filter") {
                    return data;
                  } else {
                    return data
                      ? '<div data-togle="tooltip" data-placement="top" title="' +
                          data +
                          '">' +
                          data.substring(0, 20) +
                          "</div>"
                      : "";
                  }
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).find("div").tooltip();
                },
                filterType: "none",
              },
              {
                name: "servicio",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_service.label_servicio",
                  defaultMessage: "Servicio",
                }),
                className: "all cursor-pointer action edit",
                data: "servicio",
                render: function (data, type, row) {
                  if (type === "filter") {
                    return data && data.id ? data.id : "";
                  } else {
                    return data && data.nombre ? data.nombre : "";
                  }
                },
                filterType: "none",
              },
              {
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_entity.label_entidad",
                  defaultMessage: "Entidad",
                }),
                name: "tipoEntidadColumnGrid",
                className: "all cursor-pointer action edit",
                data: "tipoEntidadColumnGrid",
                width: "60px",
                render: function (data, type, row) {
                	return data ?  (
                    `<button class="px-0 btn btn-sm btn-icon btn-dt-grid text-dark" title="` +
                    	component.props.intl.formatMessage({
                        id: "ticketsGrid.column_actions.button_title_trabajar_con_ticket",
                        defaultMessage: "Trabajar con ticket",
                      }) +
                      `" data-togle="tooltip" data-placement="top"> ` +
												data.icon + `
										</button>` + " " + data.descripcion
                  ) : '';
                },
                filterType: "none",
              },
              {
                name: "gerenciador",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_gerenciador.label_gerenciador",
                  defaultMessage: "Gerenciador",
                }),
                className: "all cursor-pointer action edit",
                data: "gerenciador",
                render: function (data, type, row) {
                  if (type === "filter") {
                    return data && data.id ? data.id : "";
                  } else {
                    return data && data.razonSocial ? data.razonSocial : "";
                  }
                },
                filterType: "none",
              },
              {
                name: "region",
                title: columnsEnabled.region
                  ? component.props.intl.formatMessage({
                      id: "ticketsGrid.column_region.label_region",
                      defaultMessage: "Región",
                    })
                  : "",
                className: columnsEnabled.region
                  ? "all cursor-pointer action edit"
                  : "d-none",
                data: "region",
                render: function (data, type, row) {
                  if (type === "filter") {
                    return data && data.id ? data.id : "";
                  } else {
                    return data && data.nombre ? data.nombre : "";
                  }
                },
                filterType: "none",
              },
              {
                name: "base",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_base.label_base",
                  defaultMessage: "Base",
                }),
                className: "all cursor-pointer action edit",
                data: "base",
                render: function (data, type, row) {
                  if (type === "filter") {
                    return data && data.id ? data.id : "";
                  } else {
                    return data && data.descripcion ? data.descripcion : "";
                  }
                },
                filterType: "none",
              },
              {
                name: "centroCostos",
                title: columnsEnabled.centroCostos
                  ? component.props.intl.formatMessage({
                      id: "ticketsGrid.column_cost_center.label_centro_de_costos",
                      defaultMessage: "Centro de Costos",
                    })
                  : "",
                className: columnsEnabled.centroCostos
                  ? "all cursor-pointer action edit"
                  : "d-none cursor-pointer action edit",
                data: "centroCostos",
                render: function (data, type, row) {
                  if (type === "filter") {
                    return data && data.id ? data.id : "";
                  } else {
                    return data && data.nombre ? data.nombre : "";
                  }
                },
                filterType: "none",
              },
              {
                name: "centroBeneficios",
                title: columnsEnabled.centroBeneficios
                  ? component.props.intl.formatMessage({
                      id: "ticketsGrid.column_benefits_center.label_centro_de_beneficios",
                      defaultMessage: "Centro de Beneficios",
                    })
                  : "",
                className: columnsEnabled.centroBeneficios
                  ? "all cursor-pointer action edit"
                  : "d-none cursor-pointer action edit",
                data: "centroBeneficios",
                render: function (data, type, row) {
                  if (type === "filter") {
                    return data && data.id ? data.id : "";
                  } else {
                    return data && data.nombre ? data.nombre : "";
                  }
                },
                filterType: "none",
              },
              {
                title: columnsEnabled.responsable1 ? "Responsable" : "",
                className: columnsEnabled.responsable1
                  ? "all cursor-pointer action edit"
                  : "d-none cursor-pointer action edit",
                data: "responsable1",
                filterType: "none",
              },
              {
                name: "enTaller",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_taller.label_base",
                  defaultMessage: "Taller",
                }),
                data: "enTaller",
                className: "all cursor-pointer action edit",
                render: function (data, type, full, meta) {
                  if (type === "filter") {
                    return data;
                  } else {
                    if (data === 1) {
                      return (
                        '<div class="text-center default dt-info-icon"><i class="ft-check" data-togle="tooltip" data-placement="top" title="' +
                        component.props.intl.formatMessage({
                          id: "ticketsGrid.column_taller.icon_default.label_taller",
                          defaultMessage: "En Taller",
                        }) +
                        '"></i></div>'
                      );
                    } else if (data === 2) {
                      return (
                        '<div class="text-center success dt-info-icon"><i class="ft-check" data-togle="tooltip" data-placement="top" title="' +
                        component.props.intl.formatMessage({
                          id: "ticketsGrid.column_taller.icon_success.label_taller",
                          defaultMessage: "En Taller",
                        }) +
                        '"></i></div>'
                      );
                    } else if (data === 3) {
                      return (
                        '<div class="text-center danger dt-info-icon"><i class="ft-check" data-togle="tooltip" data-placement="top" title="' +
                        component.props.intl.formatMessage({
                          id: "ticketsGrid.column_taller.icon_danger.label_taller",
                          defaultMessage: "En Taller",
                        }) +
                        '"></i></div>'
                      );
                    } else {
                      return "";
                    }
                  }
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).find("div > i").tooltip();
                },
                filterType: "none",
              },
              {
                name: "mttTotal",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_TT.label_TT",
                  defaultMessage: "TT",
                }),
                className: "all cursor-pointer action edit",
                data: "mttTotal",
                filterType: "none",
              },
              {
                name: "mttParcial",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_TP.label_TP",
                  defaultMessage: "TP",
                }),
                className: "all cursor-pointer action edit",
                data: "mttParcial",
                filterType: "none",
              },
              {
                name: "encuestaNivelSatisfaccion",
                title: columnsEnabled.encuestaSatisfaccion
                  ? component.props.intl.formatMessage({
                      id: "ticketsGrid.column_encuesta.label_encuesta",
                      defaultMessage: "Satisfacción",
                    })
                : "",
                className: columnsEnabled.encuestaSatisfaccion
                ? "all cursor-pointer action edit"
                : "d-none",
                data: "encuestaNivelSatisfaccion",
                render: function (data, type, full, meta) {
                  let colorNivel = ''
                  if(data && data.nivel < 7) colorNivel = 'red'
                  if(data && (data.nivel == 7 || data.nivel == 8)) colorNivel = 'yellow'
                  if(data && (data.nivel == 9 || data.nivel == 10)) colorNivel = 'green'
                  return data
                    ? '<div class="text-center data-togle="tooltip" data-placement="top" title="' +
                        data.comentarios +
                        '">' + `<span className="number-span-${colorNivel}">` + data.nivel +'</span>'+
                        "</div>"
                    : "";
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).find("div > i").tooltip();
                },
                filterType: "none",
              },
              {
                name: "fechaRealizado",
                title: component.props.intl.formatMessage({
                  id: "ticketsGrid.column_realized.label_realizado",
                  defaultMessage: "Realizado",
                }),
                className: "all cursor-pointer action edit",
                data: "fechaRealizado",
                render: function (data, type, full, meta) {
                  return data
                    ? '<div class="text-center success dt-info-icon"><i class="ft-check" data-togle="tooltip" data-placement="top" title="' +
                        moment(data).format('L') +
                        '"></i></div>'
                    : "";
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).find("div > i").tooltip();
                },
                filterType: "none",
              },
			  {
                name: "fechaHoraTurno",
                title: columnsEnabled.fechaHoraTurno? component.props.intl.formatMessage({
                  id: "ticketsGrid.column_state.label_fecha_hora_turno",
                  defaultMessage: "Turno",
                }): '',
                className: columnsEnabled.fechaHoraTurno? "all cursor-pointer action edit" : 'd-none',
                data: "fechaHoraTurno",
				render: function (data) {
					return data && columnsEnabled.fechaHoraTurno ? Timezone.getDateForClient(data, 'YYYY-MM-DD HH:mm:ss', 'L') : "";
				},
                filterType: "none",
              },
              {
                name: "abono",
                title: columnsEnabled.abono
                  ? component.props.intl.formatMessage({
                      id: "ticketsGrid.column_abono.label_abono",
                      defaultMessage: "Abono",
                    })
                  : "",
                className: columnsEnabled.abono
                  ? "all cursor-pointer action edit"
                  : "d-none",
                data: "abono",
                render: function (data, type, full, meta) {
                  return data && columnsEnabled.abono
                    ? '<div class="text-center success dt-info-icon"><i class="ft-check" data-togle="tooltip" data-placement="top" title="' +
                        component.props.intl.formatMessage({
                          id: "ticketsGrid.column_abono.title_con_abono",
                          defaultMessage: " Con Abono ",
                        }) +
                        '"></i></div>'
                    : "";
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  if (columnsEnabled.abono) $(td).find("div > i").tooltip();
                },
                filterType: "none",
              },
			  {
                name: "tipoModelo",
                title: columnsEnabled.tipoModelo ? component.props.intl.formatMessage({
                      id: "ticketsGrid.column_tipo_modelo.lable_tipo_modelo",
                      defaultMessage: "Tipo Modelo",
					}) : '',
				className: columnsEnabled.tipoModelo
                  ? "all cursor-pointer action edit"
                  : "d-none",
                data: "tipoModelo",
                orderable: true,
                filterType: "none",
              },
              {
                orderable: false,
                name: "comentario",
                title: "",
                data: "comentario",
                className: "all cursor-pointer action edit",
                render: function (data, type, full, meta) {
                  return data
                    ? '<div class="text-center success dt-info-icon"><i class="ft-message-square" data-togle="tooltip" data-placement="top" title="' +
                        data.comentario +
                        '"></i></div>'
                    : "";
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).find("div > i").tooltip();
                },
                filterType: "none",
              },
              {
                name: "acciones",
                orderable: false,
                data: null,
                className: "text-center all",
                width: "120px",
                render: function (data, type, full, meta) {
                  return (
                    `
								<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="` +
                    component.props.intl.formatMessage({
                      id: "ticketsGrid.column_actions.button_title_trabajar_con_ticket",
                      defaultMessage: "Trabajar con ticket",
                    }) +
                    `" data-togle="tooltip" data-placement="top">
									<i class="fa fa-pencil fa-xs"></i>
								</button>
							`
                  );
                },
                createdCell: function (td, cellData, rowData, row, col) {
                  $(td).find("button").tooltip();
              },
                filterType: "none",
              },
            ],
            drawCallback: function() {
  						$(this).find('.action').on('click', function() {
  							let data = component.table.row($(this).parents('tr')).data();
  							let redirectTo;
  							if ($(this).hasClass('edit') && data) redirectTo = component.props.match.url + '/' + data.id + '/edit';
  							component.setState({
  								redirectTo: redirectTo
  							});
  						});
  					},
            rowCallback: function (row, data) {
              $("#avatar", row).addClass("dt-user-avatar");
              switch (data.ticketTipo) {
                case "PREVENTIVO":
                  $("#avatar", row).addClass("paleta-preventivo");
                  break;
                case "VENCIMIENTO":
                  $("#avatar", row).addClass("paleta-vencimiento");
                  break;
                case "GESTORIA":
                  $("#avatar", row).addClass("paleta-gestoria");
                  break;
                case "CORRECTIVO":
                  $("#avatar", row).addClass("paleta-correctivo");
                  break;
                default:
                  break;
              }
              $("#avatar", row).html(data.ticketTipo.substr(0, 1).toUpperCase());
            },
          },
          datatablesConfig(this.props.intl)
        )
      );

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.column(3).search('PENDIENTES', false, true);
						$('#filter_estado').val('PENDIENTES');
            if (ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true') {
              dt.column(0).search('DEFAULT', false, true);
              $('#filter_tipoTicket').val('DEFAULT');
            }
						dt.table().order([ 1, 'desc' ]);
						component.setState({alertClick: null,movilDetail: null, limpiarFiltros: true},()=>{ dt.ajax.reload()});
					}
				}, {
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
          action: function(e, dt, button, config) {
						component.handleExportComentariosPDF(dt);
					},
					titleAttr: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
          name:'exportExcel',
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_export.select_title_exportar', defaultMessage: 'Exportar' }),
					buttons: [
            {
							exportOptions: {
								columns: ':visible'
							},
							attr:  {
								id: 'collection-export',
							},
							text: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_export.select_title_exportar', defaultMessage: 'Exportar Tickets' }),
              action: function (e, dt, node, config) {
                component.setState({
                  exportFechaDesde: moment().startOf('year'),
                  exportFechaHasta: moment()
                }, () => {
                  if(component.state.grillaFiltrada){
										swal.fire({
											title: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_export.filtros_aplicados', defaultMessage: 'La exportacion cuenta con flitros aplicados' }),
											text: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_export.desdea_continuar', defaultMessage: '¿Desea Continuar?' }),
											type: "warning",
											showCancelButton: true,
											cancelButtonText: 'Cancelar',
											confirmButtonText: 'Aceptar',
											reverseButtons: true
										}).then((res) => {
											if (res.value) {
												$('#export_modal').modal({backdrop: 'static'});
											}
										});
									} else {
										$('#export_modal').modal({backdrop: 'static'});
									}
                });
              }
						},
            {
							exportOptions: {
								columns: ':visible'
							},
							attr:  {
								id: 'collection-export-historico',
							},
              enabled: Security.hasPermission('TICKETS_EXPORTAR_HISTORICO') ? true : false,
              className: Security.hasPermission('TICKETS_EXPORTAR_HISTORICO') ? '' : 'cursorDefault',
							text: component.props.intl.formatMessage({ id: 'ticketsGrid.tools_export.title_export_historico', defaultMessage: 'Exportar Histórico de Estados de Tickets' }),
              action: function (e, dt, node, config) {
                component.setState({
                  exportHistoricoFechaDesde: moment().subtract(3,'months').startOf('day'),
                  exportHistoricoFechaHasta: moment()
                }, () => {
                  $('#export_historico_modal').modal({backdrop: 'static'});
                });
              }
						},
          ]
        }]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('exportExcel:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();


			$('.buttons-collection').on("click",function(e){ e.stopPropagation();
      $('.dt-button-collection>.dropdown-menu:has(a[id="collection-export-historico"])').css('transform','translate3d(-245px, 0px, 0px)');
      // $('#collection-export-historico').attr('disabled',true)
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	}
}

export default injectIntl(TicketsGrid);
