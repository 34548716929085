import React, { Component } from 'react'
import InstanciaBasicConAdjunto from './InstanciaBasicConAdjunto.js'


class InstanciaAdjunto extends InstanciaBasicConAdjunto {
	constructor(props) {
		super(props);
  }

	render() {
      return (
        <React.Fragment>
					<div className="form-control">
							<input
								disabled={this.props.disabled || this.props.previsualizacion}
								type="file"
								multiple
								onChange={this.handleInputChange}
							/>
							{this.state.adjuntoArray.length > 0 && this.state.adjuntoUrlArray.length > 0 && this.state.adjuntoUrlArray.map((url, i) => 
								<button
									key={i}
									className="action btn btn-icon text-fleet btn-sm btn-dt-grid"
									onClick={(e) => this.handleOpenAdjunto(e, url)}
									title="Ver Adjunto" >
										<i className="fa fa-paperclip fa-xs"></i>
										<p>{this.state.adjuntoArray[i].archivoNombre}</p>
								</button>)}
							{this.state.adjunto && (
								<>
									<hr/>
									
									{this.state.adjunto_url &&
										<button
										className="action btn btn-icon text-fleet btn-sm btn-dt-grid"
										onClick={(e) => this.handleOpenAdjunto(e)}
										title="Ver Adjunto" >
											<i className="fa fa-paperclip fa-xs"></i>
										</button>
									}
									<label className="btn-sm">{this.state.adjunto.archivoNombre}</label>
									{! this.props.disabled &&
										<button
											className="action btn btn-icon text-fleet text-danger btn-sm btn-dt-grid pull-right"
											onClick={(e) => this.handleChangeAndUpdateFather(null)}
											title="Eliminar" >
												<i className="fa fa-trash fa-xs"></i>
										</button>
									}
								</>
							)}
					</div>
        </React.Fragment>
      )
  }
}

export default InstanciaAdjunto;
