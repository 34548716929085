import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as Utils from "../../commons/utils/Utils";
import { Table, TableContainer, TableHead, TableRow, TableBody, Tooltip } from "@material-ui/core";
import { StyledTableCell } from "../../commons/utils/TableStyles";

const TipoGastoExtraGrid = (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(null);
  const [tipoGastos, setTipoGastos] = useState(null);

  useEffect(() => {
    setLoading(true);
    // Fetch Clases
    fetchTipoGastos();
  }, []);

  const fetchTipoGastos = () => {
    Utils.getData("/tipo-gasto-extra")
      .then((res) => {
        setTipoGastos(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteClase = (id) => {
    Utils.deleteData("/tipo-gasto-extra/" + id)
      .then((res) => {
        fetchTipoGastos();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <div className="card">
        <div className="card-content">
          <div className="card-body">
            <div style={{ marginBottom: "-20px" }}>
              <Tooltip
                title={props.intl.formatMessage({
                  id: "clasesGrid.button.label_add",
                  defaultMessage: "Agregar",
                })}
                arrow
                placement="right"
              >
                <div
                  className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  onClick={() => setRedirectTo(props.match.url + "/add")}
                >
                  <i className="ft-plus"></i>
                </div>
              </Tooltip>
            </div>
            <TableContainer>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left">NOMBRE</StyledTableCell>
                    <StyledTableCell align="left">DESCRIPCION</StyledTableCell>
                    <StyledTableCell align="left">ACTIVO</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <StyledTableCell colSpan={4} align="center">
                        Cargando
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    tipoGastos &&
                    tipoGastos.map((row) => (
                      <TableRow key={row.name} key={"clasesGridRow_" + row.id}>
                        <StyledTableCell align="left" style={{ maxWidth: 10 }}>
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.nombre}</StyledTableCell>
                        <StyledTableCell align="left">{row.descripcion}</StyledTableCell>
                        <StyledTableCell align="left">{row.activo == 1 ? 'Si' : 'No'}</StyledTableCell>
                        <StyledTableCell align="right" style={{ maxWidth: 50 }}>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0 mx-1"
                              onClick={() => setRedirectTo(props.match.url + "/" + row.id)}
                            >
                              <Tooltip
                                title={props.intl.formatMessage({
                                  id: "clasesGrid.button.label_view",
                                  defaultMessage: "Ver",
                                })}
                                arrow
                              >
                                <i className="fa fa-search fa-xs"></i>
                              </Tooltip>
                            </button>
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0 mx-1"
                              onClick={() => setRedirectTo(props.match.url + "/" + row.id + "/edit")}
                            >
                              <Tooltip
                                title={props.intl.formatMessage({
                                  id: "clasesGrid.button.label_edit",
                                  defaultMessage: "Editar",
                                })}
                                arrow
                              >
                                <i className="fa fa-pencil fa-xs"></i>
                              </Tooltip>
                            </button>
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-danger p-0 mx-1"
                              onClick={() => deleteClase(row.id)}
                            >
                              <Tooltip
                                title={props.intl.formatMessage({
                                  id: "clasesGrid.button.label_delete",
                                  defaultMessage: "Eliminar",
                                })}
                                arrow
                              >
                                <i className="fa fa-trash fa-xs"></i>
                              </Tooltip>
                            </button>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(TipoGastoExtraGrid);
