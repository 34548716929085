import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Switch from "react-switch"
import InfraccionesAdjuntos from './InfraccionesAdjuntos'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Timezone from '../../commons/timezone/Timezone.js';

const newstate ={
	redirectTo: null,
	props: {
		open: false,
		action: null,
		id: null
	},
	formData: {
		id: 0,
		movil: '',
		acta: null,
		direccion: '',
		conductores: null,
		descripcion: '',
		importe:0,
		importeAbonado:0,
		puntosDescontados:0,
		litros: null,
		adjuntos: []
	},
	errors: [],
	loading: false,
	conductoresAll: null,
	direccionForm: null,
	mostrarHora: true,
	checked: true
};

class InfraccionesPago extends Component {
	constructor(props) {
		super(props);
		this.props = props;

		moment.locale('es');

		this.state = {
			...newstate,
			props: {
				...newstate.props,
				...this.props
			}
		};

		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleSwitchChange = this.handleSwitchChange.bind(this);
		this.handleNumericChange = this.handleNumericChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.formValidation = new FormValidation({
			component: this,
			validators: {
				'formData.movil': (value) => Validator.notEmpty(value),
				'formData.acta': (value) => Validator.notEmpty(value),
				'formData.importe': (value) => Validator.notEmpty(value),
			}
		});
	}

	componentDidMount() {
		this.ajaxHandler = new AjaxHandler();
		if(this.props.onRef) this.props.onRef(this);
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		if(this.props.open !== prevProps.open || this.props.id !==prevProps.id) {
			this.setState({
				props: this.props
			});
			this.ajaxHandler.subscribe(this);
			if (this.props.open) {
				this.initForm();
			}
		}
	}

  	initForm() {
		this.setState({ loading: true });
		let component = this;

		Promise.all([
		this.ajaxHandler.getJson('/personas/select'),
		this.props.action === 'PAY' ? this.ajaxHandler.getJson('/infracciones/' + this.props.id) : null
			]).then((data) => {

			let conductoresAll = data[0];
			let formData = data[1];

			component.setState({
				conductoresAll: conductoresAll
			});

			if(formData){
				if(formData.fechaEmision){
					formData.fechaEmision = Timezone.getDateForClient(formData.fechaEmision, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss') ;
				}
				//guardo los datos para el componente de direccion
				let direccionParams = JSON.stringify({
				direccion: formData.direccion,
				numero: formData.numeroDireccionInformada,
				localidad: formData.localidadInformada,
				provincia: formData.provinciaInformada,
				pais: formData.provinciaInformada ? formData.provinciaInformada.pais : null,
				latitud: formData.latitud,
				longitud: formData.longitud
				});

				formData.importeAbonado = formData.importeAbonado ? formData.importeAbonado : formData.importe;

				formData.direccion = formData.direccion;
				formData.latitud = formData.latitud;
				formData.longitud = formData.longitud;
				formData.descripcionInfraccion = formData.infraccion;
				formData.conductor = conductoresAll.filter(e => e.value === formData.conductor)[0];
				formData.conductor && (formData.conductor.id = formData.conductor.value);
				formData.conductor && (formData.conductor.name = formData.conductor.label);
				component.setState({
				formData: formData,
				direccionForm: direccionParams
				});
			}
		}).catch(function(error) {
				console.log(error);
				component.exit();
			}).finally(() => {
				this.setState({ loading: false });
				this.ajaxHandler.unsubscribe();
				document.getElementsByClassName('pagarInfranccion_modal_content')[0].scrollTop = 0;
		});
	}

	handleInputFormChange(event) {

		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    	formDataCopy[name] = value !== '' ? value : null;

		this.setState({
			formData: formDataCopy
		});
	}


  handleAdjuntosChange(adjuntoNuevo) {
		this.state.formData.adjuntos.push(adjuntoNuevo)
    this.setState({
      formData: this.state.formData
    });
  }

	callbackAdjuntosRemove(archivoNombre){
		this.state.formData.adjuntos = this.state.formData.adjuntos.filter(adjunto => adjunto.archivoNombre != archivoNombre)
		this.setState({
      formData: this.state.formData
    });
	}

	handleSwitchChange(checked) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		this.setState({ checked });
		checked ?
			formDataCopy["importeAbonado"] = formDataCopy["importe"]
		:
			formDataCopy["importeAbonado"] = 0
		;

		this.setState({
			formData: formDataCopy
		});
	}

	handleSubmit(event) {
		//formatea la fecha antes de enviarla
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.fechaEmision = Timezone.getDateForServer(this.state.formData.fechaEmision).format('YYYY-MM-DD HH:mm:ss');
		if (this.state.formData.fechaVencimiento)
				formDataCopy.fechaVencimiento = Timezone.getDateForServer(this.state.formData.fechaVencimiento).format('YYYY-MM-DD 23:59:59');
		formDataCopy.fechaAbono = Timezone.getDateForServer(moment().format('YYYY-MM-DD HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss');

		this.setState({ loading: true });
		let component = this
		let data = this.state.formData;
		this.ajaxHandler.subscribe(this);
		this.ajaxHandler.fetch('/infracciones/informar-pago/' +  data.id, {
			method: 'PUT',
			body: JSON.stringify({
				...formDataCopy
			}),
		}).then(response => {
			if(response.status !== 400) {

				component.exit();
			} else {
				response.json()
				.then(data => {
					this.setState({
						errors: data.detalle
					});
				});
			}
			window.scrollTo(0,0);
		}).catch((error) => {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
		this.setState({ loading: false });
		});
      	event.preventDefault();
	}

	handleCancel() {
		this.ajaxHandler.unsubscribe();
		this.props.callbackCancel();
		this.setState({
			...newstate
		});
	}

	handleNumericChange(e) {
	  let name = e.target.name;
	  let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
	  formDataCopy[name] = (e.target.validity.valid) ? e.target.value : this.state.formData[name];
	  this.setState({
		formData: formDataCopy
	  });
	}

	exit() {
		this.ajaxHandler.unsubscribe();
		this.props.callbackSubmit();
		this.setState({
			...newstate
		});
	}

	render() {
    	this.formValidation.validate();
		let formData = this.state.formData;
		let validationState = this.formValidation.state;

		let state = this.state;

		let requiredSymbol = ' *';

    return (
		<React.Fragment>
        	{state.redirectTo && <Redirect push to={state.redirectTo} />}
			{state.loading && <Loading />}
			<div className="row">
          		<div className="col-md-12">
            		<div className="alert alert-danger" role="alert" hidden={state.errors.length===0}>
						{state.errors.map((e, i) => <li key={i}>{e}</li>)}
					</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="infraccionesPago.render.infraction_details.header_detalles_de_la_infraccion" defaultMessage=" Detalles de la Infracción "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="infraccionesPago.render.infraction_details.campos_requeridos" defaultMessage="* campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* MOVIL */}
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                              <FormattedMessage id="infraccionesPago.render.infraction_details.label_movil" defaultMessage="Movil"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-9">
								<div className="form-control-static col-form-label form-value">{formData.movil ? formData.movil.dominio : ''}</div>
                            </div>
                          </div>
                        </div>

                        {/* FECHA DE EMISION*/}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaEmision">
                              <FormattedMessage id="infraccionesPago.render.infraction_details.label_fecha_emision" defaultMessage="Fecha Emisión"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-6">
								<div className="form-control-static col-form-label form-value">{formData.fechaEmision === null || formData.fechaEmision === '0000-00-00' ? '' : moment(formData.fechaEmision).format('L') }</div>
                            </div>
                          </div>
                        </div>
                        {/* HORA DE EMISION */}
                        <div className="col-md-6" hidden={!state.mostrarHora}>
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="horaActual">
                              <FormattedMessage id="infraccionesPago.render.infraction_details.label_hora" defaultMessage="Hora"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-6">
                              <div className="form-control-static col-form-label form-value">{formData.hora_actual === null || formData.hora_actual === '00:00' ? '' : moment(formData.fechaEmision).format('LT') }</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* FECHA DE VENCIMIENTO*/}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="fechaVencimiento">
                              <FormattedMessage id="infraccionesPago.render.infraction_details.label_fecha_vencimiento" defaultMessage="Fecha Vencimiento"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-6">
								<div className="form-control-static col-form-label form-value">{formData.fechaVencimiento === null || formData.fechaVencimiento === '0000-00-00' ? '' : moment(formData.fechaVencimiento).format('L') }</div>                            </div>
                          </div>
                        </div>
                        {/* # Acta */}
                        <div className="col-md-6">
                          	<div className="form-group row">
								<label className="col-md-3 label-control col-form-label" htmlFor="acta">
									<FormattedMessage id="infraccionesPago.render.infraction_details.label_acta" defaultMessage="#Acta:"/>
								</label>
								<div className="col-md-9">
									<div className="form-control-static col-form-label form-value">{formData.acta ? formData.acta : ''}</div>
								</div>
							</div>
                        </div>
                      </div>

                      <div className="row">

                        {/* IMPORTE */}
                        <div className="col-md-6">
                    		<div className="form-group row">
								<label className="col-md-6 label-control col-form-label" htmlFor="importe">
									<FormattedMessage id="infraccionesPago.render.infraction_details.label_importe" defaultMessage="Importe *:"/>
								</label>
								<div className="col-md-6">
									<div className="form-control-static col-form-label form-value">{formData.importe ? formData.importe.toFixed(2) : ''}</div>
								</div>
                        	</div>
                        </div>
                        {/* # Puntos Descontados */}
                        <div className="col-md-6">
                          	<div className="form-group row">
								<label className="col-md-6 label-control col-form-label" htmlFor="puntosDescontados">
									<FormattedMessage id="infraccionesPago.render.infraction_details.label_puntos_descontados" defaultMessage="Puntos Descontados:"/>
								</label>
								<div className="col-md-6">
									<div className="form-control-static col-form-label form-value">{formData.puntosDescontados ? formData.puntosDescontados : ''}</div>
								</div>
							</div>
                        </div>
                      </div>

                    <div className="row">
                        {/* Código de Infracción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="codigoInfraccion">
                            	<FormattedMessage id="infraccionesPago.render.infraction_details.label_codigo_de_infracción" defaultMessage="Código de Infracción:"/>
                            </label>
                            <div className="col-md-6">
								<div className="form-control-static col-form-label form-value">{formData.codigoInfraccion ? formData.codigoInfraccion : ''}</div>                            </div>
                          	</div>
                        </div>

                        {/* Descripción de Infracción */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="descripcionInfraccion">
                            	<FormattedMessage id="infraccionesPago.render.infraction_details.label_descripcion_de_infraccion" defaultMessage="Descripción de Infraccion:"/>
                            </label>
                            <div className="col-md-6">
								<div className="form-control-static col-form-label form-value">{formData.descripcionInfraccion ? formData.descripcionInfraccion : ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

				{!this.state.loading && <InfraccionesAdjuntos comprobante={true} infraccion={this.state.formData} action={this.props.action}  callbackRemove={this.callbackAdjuntosRemove.bind(this)} callbackUpdate={this.handleAdjuntosChange.bind(this)}></InfraccionesAdjuntos>}

				<div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body" id="container-direccion">
						<h4 className="form-section mt-2">
							<i className="la la-info-circle"></i><FormattedMessage id="infraccionesPago.render.infraction_details_confirm.confirma_que_desea_informar_el_pago" defaultMessage=" ¿Confirma que desea Informar el Pago?"/>
						</h4>

						<div className="row">
							{/* Swith */}
							<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-6 label-control col-form-label text-nowrap" htmlFor="importeAbonado">
								<FormattedMessage id="infraccionesPago.render.infraction_details_confirm.informar_mismo_monto" defaultMessage="Informar mismo monto: "/>
								</label>
								<div className="col-md-6 col-form-label">
									<div>
										<Switch
											checked={this.state.checked}
											onChange={this.handleSwitchChange}
											height={20}
											width={36}
										/>
									</div>
								</div>
							</div>
							</div>

							{/* IMPORTE A ABONAR */}
							<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="importeAbonado">
									<FormattedMessage id="infraccionesPago.render.infraction_details_confirm.label_monto_de_pago" defaultMessage="Monto de Pago:"/>
								</label>
								<div className="col-md-8">
								<div>
									<input
										type="text"
										className="form-control"
										pattern="[0-9]*"
										id="importeAbonado"
										name="importeAbonado"
										placeholder={this.props.intl.formatMessage({ id: 'infraccionesPago.render.infraction_details_confirm.placeholder_monto_de_pago', defaultMessage: 'Sólo números' })}
										value={formData.importeAbonado === 0 ? '': formData.importeAbonado}
										onInput={this.handleNumericChange}
										disabled={this.state.checked}
									/>
								</div>
								</div>
							</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="text-cd text-right">
									<button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
									<i className="fa fa-check-circle"></i><FormattedMessage id="InfraccionesPago.finish_button.guardar" defaultMessage=" Guardar"/>
									</button>
									<button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)} data-dismiss="modal" data-backdrop="false">
									<i className="fa fa-times-circle"></i><FormattedMessage id="InfraccionesPago.finish_button.cancelar" defaultMessage=" Cancelar"/>
									</button>
								</div>
							</div>
						</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(InfraccionesPago);
