import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import { StyledTooltip } from '../../commons/utils/TableStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Collapse,
  Grid,
  Box,
  CircularProgress,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from "react-datepicker";
import moment from "moment";
import Swal from 'sweetalert2'
import * as Utils from '../../commons/utils/Utils'
import $ from 'jquery'

const TIPO_TICKETS = [{ value: 'PREVENTIVO', label: 'PREVENTIVO' }, { value: 'CORRECTIVO', label: 'CORRECTIVO' }, { value: 'VENCIMIENTO', label: 'VENCIMIENTO' }];

const CustomToolbar = ({ eventos, setDropdownActive, dropdownActive, searchActive, setSearchActive, filterQuery, setFilterQuery, limpiarFiltros, setLimpiarFiltros }) => (props) => {
  const intl = useIntl()
  let fecha = props.label.charAt(0).toUpperCase() + props.label.slice(1);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(filterQuery.fechaInicio || moment().startOf('month'));
  const [fechaFin, setFechaFin] = useState(filterQuery.fechaFin || moment().endOf('month'));
  const [tiposTicket, setTiposTicket] = useState(TIPO_TICKETS);
  const [modeloTipos, setModeloTipos] = useState([]);
  const [modeloTipo, setModeloTipo] = useState(filterQuery.modeloTipo);
  const [dominio, setDominio] = useState(filterQuery.dominio);
  const [bases, setBases] = useState([]);
  const [base, setBase] = useState(filterQuery.base);
  const [regiones, setRegiones] = useState([]);
  const [region, setRegion] = useState(filterQuery.region);

  useEffect(() => {
    setFiltersLoading(true);
    Promise.all([
      Utils.getData('/regiones/select'),
      Utils.getData('/bases/select-filtered-by-user/movil'),
      Utils.getData('/modelo-tipos/select')
    ]).then((data) => {
      setRegiones(data[0] ? data[0] : [])
      setBases(data[1] ? data[1] : [])
      setModeloTipos(data[2] ? data[2] : [])
      setFiltersLoading(false);
    }).catch((err) => {
      console.log(err);
      setFiltersLoading(false);
    })
  }, [])

  const handleChangeTipo = (value) => {
    let tipos = tiposTicket.filter((tipo) => tipo.label != value.label)
    setTiposTicket(tipos)
    setFilterQuery({ ...filterQuery, tipo: value })
  }

  const downloadPdf = () => {
    let start = filterQuery.fechaInicio || moment().startOf('month');
    let end = filterQuery.fechaFin || moment().endOf('month')

    //Obtengo de los eventos fitlrados solo los preventivos
    const preventivos = eventos.filter(ev => ev.tipo === 'PREVENTIVO' && ev.start >= start && ev.start <= end)
      .map(p => ({ id: p.mantenimiento_id, fecha: moment(p.start).format('DD/MM/YYYY') }));

    if (!preventivos.length) {
      Swal.fire({
        type: 'warning',
        title: intl.formatMessage({ id: `calendario.planificaion_preventivos_vacio`, defaultMessage: "No se encontraron preventivos" }),
        confirmButtonText: "Ok",
      })
    } else {
      let body = JSON.stringify({ preventivos: preventivos });

      Utils.sendDataBlob('/calendario/exportar-excel', 'POST', body)
        .then(data => {
          console.log("blob del excel");
          let file = new File([data], 'Calendario - Planificacion de Preventivos.xlsx')
          let fileUrl = URL.createObjectURL(file)
          $("<a />", {
            href: fileUrl,
            download: "Calendario - Planificacion de Preventivos.xlsx"
          }).appendTo("body").get(0).click();
        }
        ).catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <>
      <div id="fc-basic-views" className="fc fc-unthemed fc-ltr">
        <div className="fc-toolbar fc-header-toolbar">
          <div className="fc-left">
            <div className="fc-button-group">
              <button
                type="button"
                className="fc-prev-button fc-button fc-state-default fc-corner-left"
                aria-label="prev"
                onClick={() => props.onNavigate('PREV')}
              >
                <span className="fa fa-angle-left"></span>
              </button>
              <button
                type="button"
                className="fc-next-button fc-button fc-state-default fc-corner-right"
                aria-label="next"
                onClick={() => props.onNavigate('NEXT')}
              >
                <span className="fa fa-angle-right"></span>
              </button>
            </div>

            <button
              type="button"
              className="fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right"
              onClick={() => props.onNavigate('TODAY')}
            >
              <FormattedMessage id="calendarToolbar.today.button.hoy" defaultMessage="Hoy" />
            </button>
            <StyledTooltip
              title={intl.formatMessage({
                id: 'calendarios.button.label_filtros',
                defaultMessage: 'Filtros',
              })}
              arrow
              placement="right"
            >
              <div
                className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                style={{ position: 'unset' }}
                onClick={() => setDropdownActive(!dropdownActive)}
              >
                {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
              </div>
            </StyledTooltip>
            {(searchActive && dropdownActive) && (
              <div className="btn-dt-main round-icon" style={{ position: 'unset' }} >
                <button
                  className="text-center cursor-pointer"
                  style={{
                    height: '25px',
                    borderRadius: '3px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 8px 0 8px',
                    backgroundColor: '#5AAC43',
                    color: 'white',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                  onClick={() => { setLimpiarFiltros(!limpiarFiltros); props.onNavigate('TODAY') }}
                >
                  Limpiar Filtros
                </button>
              </div>
            )}
          </div>

          <div className="fc-center">
            <h2>{fecha}</h2>
          </div>

          <div className="fc-right">
            <div className="fc-button-group">
              <button
                type="button"
                className="fc-month-button fc-button fc-state-default fc-corner-left fc-state-active"
                onClick={() => props.onView('month')}
              >
                <FormattedMessage id="calendarToolbar.month.button.mes" defaultMessage="Mes" />
              </button>
              <button
                type="button"
                className="fc-basicWeek-button fc-button fc-state-default"
                onClick={() => props.onView('week')}
              >
                <FormattedMessage id="calendarToolbar.week.button.semana" defaultMessage="Semana" />
              </button>
              <button
                type="button"
                className="fc-basicDay-button fc-button fc-state-default fc-corner-right"
                onClick={() => props.onView('day')}
              >
                <FormattedMessage id="calendarToolbar.day.button.dia" defaultMessage="Día" />
              </button>
            </div>

            <button
              type="button"
              className="fc-basicAgenda-button fc-button fc-state-default fc-corner-left fc-corner-right"
              onClick={() => props.onView('agenda')}
            >
              <FormattedMessage id="calendarToolbar.schedule.button.agenda" defaultMessage="Agenda" />
            </button>

            <StyledTooltip
              title={intl.formatMessage({
                id: 'calendario.tools_export.title_exportar_planificacion_preventivos',
                defaultMessage: 'Exportar Planificación de Preventivos',
              })}
              arrow
              placement="bottom"
            >
              <button
                type="button"
                className="btn fc-button fc-state-default fc-corner-left fc-corner-right"
                onClick={downloadPdf}>
                <span>
                  <i className="ft-upload"></i>
                </span>
              </button>
            </StyledTooltip>
          </div>
        </div>

        <div className="fc-clear"></div>
      </div>
      <div style={{ padding: 10, marginBottom: 10, marginTop: 10 }}>

        <Collapse in={dropdownActive}>
          <Grid container spacing={3}>

            <Grid item sm={2} xs={12} style={{ display: 'block' }}>
              <label><FormattedMessage id="calendario.filter.label_desde" defaultMessage="Desde " />:</label>
              <DatePicker
                selected={fechaInicio}
                onChange={(date) => setFechaInicio(date)}
                selectsStart
                startDate={fechaInicio}
                endDate={fechaFin}
                dateFormat={"DD/MM/YYYY"}
                openToDate={fechaInicio}
                className="form-control date-picker-placeholder"
                onBlur={() => (setFilterQuery({ ...filterQuery, fechaInicio: fechaInicio }), props.onNavigate('DATE', fechaInicio))}
              />
            </Grid>

            <Grid item sm={2} xs={12}>
              <label><FormattedMessage id="calendario.filter.label_hasta" defaultMessage="Hasta " />:</label>
              <DatePicker
                selected={fechaFin}
                onChange={(date) => setFechaFin(date)}
                selectsEnd
                startDate={fechaInicio}
                endDate={fechaFin}
                dateFormat={"DD/MM/YYYY"}
                openToDate={fechaFin}
                minDate={fechaInicio}
                className="form-control date-picker-placeholder"
                onBlur={() => (setFilterQuery({ ...filterQuery, fechaFin: fechaFin }))}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  multiple={true}
                  size="small"
                  name="tipo"
                  disabled={filtersLoading}
                  options={tiposTicket}
                  value={filterQuery.tipo}
                  defaultValue={[]}
                  getOptionLabel={(option) => option ? option.label : ""}
                  onChange={(e, value) => handleChangeTipo(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: "calendario.render.filtros_tipo.label",
                        defaultMessage: "Tipo"
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {filtersLoading ? (
                              <CircularProgress color="inherit" size={21} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Grid>


            <Grid item sm={2} xs={12}>
              <form noValidate autoComplete="off">
                <TextField
                  label={intl.formatMessage({ id: "calendario.render.filtros_dominio.label", defaultMessage: "Dominio" })}
                  name="dominio"
                  value={dominio}
                  onChange={(e) => setDominio(e.target.value)}
                  onBlur={() => { setFilterQuery({ ...filterQuery, dominio: dominio }) }}
                  className="col-12"
                />
              </form>
            </Grid>

            <Grid item sm={2} xs={12}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  size="small"
                  name="modeloTipo"
                  disabled={filtersLoading}
                  options={modeloTipos}
                  value={filterQuery.modeloTipo}
                  getOptionLabel={(option) => option ? option.label : ""}
                  onChange={(e, value) => setModeloTipo(value)}
                  onBlur={() => { setFilterQuery({ ...filterQuery, modeloTipo: modeloTipo }) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: "calendario.render.modeloTipo.label",
                        defaultMessage: "Tipo Modelo"
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {filtersLoading ? (
                              <CircularProgress color="inherit" size={21} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid item sm={2} xs={12}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  size="small"
                  name="base"
                  disabled={filtersLoading}
                  options={bases}
                  value={filterQuery.base}
                  getOptionLabel={(option) => option ? option.label : ""}
                  onChange={(e, value) => setBase(value)}
                  onBlur={() => { setFilterQuery({ ...filterQuery, base: base }) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: "calendario.render.base.label",
                        defaultMessage: "Base"
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {filtersLoading ? (
                              <CircularProgress color="inherit" size={21} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Grid>
            <Grid item sm={2} xs={12}>
              <form noValidate autoComplete="off">
                <Autocomplete
                  size="small"
                  name="region"
                  disabled={filtersLoading}
                  options={regiones}
                  value={filterQuery.region}
                  getOptionLabel={(option) => option ? option.label : ""}
                  onChange={(e, value) => setRegion(value)}
                  onBlur={() => { setFilterQuery({ ...filterQuery, region: region }) }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={intl.formatMessage({
                        id: "calendario.render.region.label",
                        defaultMessage: "Región"
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {filtersLoading ? (
                              <CircularProgress color="inherit" size={21} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </Grid>
          </Grid>
        </Collapse>
      </div>
    </>
  )
}

export default CustomToolbar
