import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'

import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Security from '../../commons/security/Security.js'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import Switch from "react-switch"
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class ServiciosGrid extends Component {
	constructor(props) {
		super(props);
        this.dataService = '/servicios/grid';
		this.apiBaseUrl = process.env.REACT_APP_VEC_FLEET_API_BASE_URL;

		this.ajaxHandler = new AjaxHandler();

		this.state = {
			redirectTo: null,
			loading: false,

			dataToExport : {
				servicio:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'serviciosGrid.modal_export.columns_export.label_servicio', defaultMessage: 'Servicio' })
				},
				tipoTicket:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'serviciosGrid.modal_export.columns_export.label_tipo_de_ticket', defaultMessage: 'Tipo de Ticket' })
				},
				maxAprobacion:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'serviciosGrid.modal_export.columns_export.label_maxmio_aprobación', defaultMessage: 'Máxmio Aprobación' })
				},
				asignacionTareas:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'serviciosGrid.modal_export.columns_export.label_asignacion_de_tareas', defaultMessage: 'Asignación de tareas' })
				},
				activo:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'serviciosGrid.modal_export.columns_export.label_activo', defaultMessage: 'Activo' })
				},
				adjuntoObligatorio:{
					value: true,
					title: this.props.intl.formatMessage({ id: 'serviciosGrid.modal_export.columns_export.label_adjunto_obligatorio', defaultMessage: 'Adjunto Obligatorio' })
				}
			},
		}
	}

	componentDidMount(nextProps, nextState) {
        if(Security.hasPermission('SERVICIOS_LISTAR')) {
            this.ajaxHandler.subscribe(this);
            let component = this;
            let filters = null;
            this.table = $(this.refs.grid).DataTable(
                Object.assign({
                    dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
                    stateSave: true,
                    stateSaveCallback: function(settings,data) {
                        localStorage.setItem( 'DataTables_ServiciosGrid', JSON.stringify(data) );
                    },
                    stateLoadCallback: function(settings) {
                        return JSON.parse( localStorage.getItem( 'DataTables_ServiciosGrid' ) );
                    },
                    serverSide: true,
                    ajax: {
                        type: 'GET',
                        url: Config.get('apiUrlBase') + this.dataService,
                        // url: component.apiBaseUrl + "/bases/grid",
                        headers: {
                            'Authorization-Token': localStorage.getItem("token")
                        },
                        dataSrc: function (response) {
                            return response.data;
                        },
                        error: function (xhr, error, thrown) {
                        },
                        cache: false
                    },
                    initComplete: function (settings) {
                        let stateColumns = JSON.parse(localStorage.getItem('DataTables_ServiciosGrid')).columns;

                        var sets = settings;
                        var index = 0;

                        this.api().columns().every( function () {
                            var column = this;

                            if(sets.aoColumns[index].filterType){
                                if(sets.aoColumns[index].filterType === 'select') {
                                    var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'serviciosGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
                                        .appendTo( $(column.footer()).empty() )
                                        .on( 'change', function () {
                                            var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                            column
                                                .search( val ? val : '', true, false )
                                                .draw();
                                        });								
                                    if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
                                        filters[sets.aoColumns[index].name].map(e => {
                                            select.append('<option value="'+e.value+'">'+e.label+'</option>');
                                            return true;
                                        });
                                    } else {
                                        column.data().unique().sort().each( function ( d, j ) {
                                            select.append( '<option value="'+d+'">'+d+'</option>' );
                                        });
                                    }
                                    if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
                                }
                                if(sets.aoColumns[index].filterType === 'input') {
                                    var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'serviciosGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'" />');
                                    if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
                                    input.appendTo($(column.footer()).empty());
                                    input.on( 'keyup change', function () {
                                        if ( column.search() !== this.value ) {
                                            column
                                            .search( this.value )
                                            .draw()
                                            .ajax.reload(null, false);
                                        }
                                    });
                                }
                            }
                            index++;
                            return '';
                        });

					$('tfoot tr').appendTo('thead');
				},
				columns: [
					{
						orderable: false,
						title: '',
						data: 'nombre',
						
						render: function (data, type, row) {
							return '<div class="dt-user-avatar">'+row.nombre.substr(0,1)+'</div>';
						},
						filterType: 'none'
					},{
							
							title: '',
							data: 'activo',
							width: 3,
							padding: 0,
							orderable: false,
							className: 'p-0',
							render: function (data, type, full, meta) {
								return '';
							},
							createdCell: function (td, cellData, rowData, row, col) {
								$('div.tooltip[role="tooltip"]').tooltip('dispose');
								$('div.tooltip[role="tooltip"]').remove();

								$(td).attr('data-togle', 'tooltip');
								$(td).attr('data-placement', 'top');
								$(td).attr('title', cellData ? 'Activo' : 'Inactivo');
								$(td).tooltip();
								if (cellData) {
									$(td).addClass('status-green');
								} else {
									$(td).addClass('status-red');
								}
							},
							filterType: 'none'
					},{
							title: component.props.intl.formatMessage({ id: 'serviciosGrid.column_service.label_servicio', defaultMessage: 'Servicio' }),
							data: 'nombre',
							filterType: 'input'
					},{
							title: component.props.intl.formatMessage({ id: 'serviciosGrid.column_type.label_tipo', defaultMessage: 'Tipo' }),
							data: 'ticketTipo',
							filterType: 'input'
							
					},{
						title: component.props.intl.formatMessage({ id: 'serviciosGrid.column_service.label_max_aprobar', defaultMessage: 'Max. Aprobar' }),
						data: 'topeAprobacion',
						className: (ConfigBusiness.get('servicios.montoAutoAprobacion.habilitado') === 'false') ? 'd-none' : 'all',
						filterType: 'input'
					},{
							orderable: false,
							data: null,
							className: "text-center",
							width: '120px',
							render: function (data, type, full, meta) {
                                let buttons = 
                                    (Security.hasPermission('SERVICIOS_VISUALIZAR') ? 
                                    `<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'serviciosGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`">
                                        <i class="fa fa-search fa-xs"></i>
                                    </button>` : '') +
                                    (Security.hasPermission('SERVICIOS_MODIFICAR') ? 
                                    `<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'serviciosGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`">
                                        <i class="fa fa-pencil fa-xs"></i>
                                    </button>` : '');
                                return buttons;
                            }
					}],
					drawCallback: function () {
						$(this).find('.action').on('click', function () {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							component.setState({
								redirectTo: redirectTo
							});

                                if($(this).hasClass('delete')) {
                                    swal({
                                    title: component.props.intl.formatMessage({ id: 'serviciosGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
                                    text: null,
                                    icon: "warning",
                                    buttons: {
                                        confirm: {
                                            text: component.props.intl.formatMessage({ id: 'serviciosGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
                                            value: true,
                                            visible: true,
                                            className: "btn btn-primary",
                                            closeModal: false
                                        },
                                        cancel: {
                                            text: component.props.intl.formatMessage({ id: 'serviciosGrid.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
                                            value: null,
                                            visible: true,
                                            className: "btn btn-danger",
                                            closeModal: true,
                                        }
                                    }
                                })
                                .then((isConfirm) => {
                                    if (isConfirm) {
                                        component.ajaxHandler.fetch('/servicios/' + data.id, {
                                            method: 'DELETE',
                                        })
                                        .then(response => {
                                            if(component.ajaxHandler.handleResponseErrorsIsValid(response)) {
                                                if(response.status !== 400) {
                                                    component.table.ajax.reload(null, false);
                                                    swal(component.props.intl.formatMessage({ id: 'serviciosGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
                                                } else {
                                                    response.json()
                                                    .then(data => {
                                                        swal(component.props.intl.formatMessage({ id: data.detalle[0]||'serviciosGrid.modal_delete.information_error', defaultMessage: 'No se puede borrar el Servicio ya que esta asociado a otro elemento.' }), "", "error");
                                                    });
                                                }
                                            }
                                        }).catch((error) => {
                                            component.ajaxHandler.handleError(error);
                                        });
                                    }
                                });
                                }
                            });
                        },
                }, datatablesConfig(this.props.intl))
            );

            new $.fn.dataTable.Buttons( this.table, {
                buttons: [
                    {
            extend: 'print',
            text: '<i class="ft-printer"></i>',
                        title: '',
                        customize: function(win)
                        {
                            var css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');

                            style.type = 'text/css';
                            style.media = 'print';

                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            }	else {
                                style.appendChild(win.document.createTextNode(css));
                            }

                            head.appendChild(style);

                            $(win.document.body)
                                .css( 'font-size', '10pt' )
                                .prepend(`<div class="container">
                                                        <div class="row mb-2">
                                                            <div class="col-6">
                                                                <img style="height: 35px" src="`+LogoCompany+`" />
                                                            </div>
                                                            <div class="col-6	text-right">
                                                                <img style="height: 40px" src="`+LogoVecFleet+`" />
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-12" style="border-bottom: 1px solid #000;">
                                                                <h2>`+component.props.intl.formatMessage({ id: 'serviciosGrid.print_content.header_listado_de_servicios', defaultMessage: 'Listado de Servicios' })+`</h2>
                                                            </div>
                                                        </div>
                                                    </div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 3,4,5,6,7 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'serviciosGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'serviciosGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
			name: 'exportExcel',
			text: '<i class="ft-upload"></i>',
			titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.title_exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
			action: function (e, dt, node, config) {
				component.setState({
				}, () => {
					$('#export_modal').modal({backdrop: 'static'});
				});
			}
		},
		{
			text: '<i class="ft-download"></i>',
			titleAttr: component.props.intl.formatMessage({ id: 'serviciosGrid.tools_import.select_importar', defaultMessage: 'Importar' })
        },
			]
		});

            this.table.buttons( 0, null ).container().appendTo('#buttons');

            $('#dataTable tfoot th').each( function () {
                var title = $(this).text();
                if(title !== '') $(this).html( '<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'serviciosGrid.columns_searh_filter.dataTable_buscar', defaultMessage: 'Buscar ...' })+'" />' );
            });

            this.table.columns([2,3]).every( function () {
                var that = this;
                $( 'input', this.footer()).on( 'keyup change', function () {
                    if ( that.search() !== this.value ) {
                        that
                            .search( this.value )
                            .draw()
                            .ajax.reload(null, false);
                    }
                });
                return null;
            });

            $('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
                    if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
                        $('.dt-button-collection>.dropdown-menu').css('display','block');
                });
        } else {
            this.setState({
                redirectTo: '/error'
            });
        }
	}

	handleNewBase(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	handleDataExport(key){
		const miState = {...this.state};
		miState.dataToExport[key].value = !miState.dataToExport[key].value;
		this.setState(miState);
	}
	handleExportExcel() {
		let component = this;
		let dictDataToExport = {}
		Object.keys(this.state.dataToExport).forEach((key) =>{
			dictDataToExport[key] = this.state.dataToExport[key].value;
		});
		this.setState({loading:true});
		component.ajaxHandler.fetch('/servicios/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
				colsAExportar: dictDataToExport
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-servicios.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportCancel();
			this.setState({ loading: false });
		});
	}

	handleExportCancel() {
		$('#export_modal').modal('toggle');
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		if(this.table) this.table.destroy();
	}

	render() {
	    return (
	    	<React.Fragment>
	    		{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    			<div className="row mt-2">
					<div className="col-12">
			    		<div className="card">
							<div className="card-content collpase show">
			            		<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
                                            { Security.hasPermission('SERVICIOS_CREAR') ?
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNewBase.bind(this)}>
													<i className="ft-plus"></i>
												</div>
                                                :
                                                null
                                            }
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
			    					<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
										<tfoot>
											<tr style={{backgroundColor: '#fff'}}>
												
												<th className=""></th>
												<th className="p-0"></th>
												<th className="dt-search-header"><FormattedMessage id="serviciosGrid.render.table_column_servicio" defaultMessage="Servicio"/></th>
												<th className="dt-search-header"><FormattedMessage id="serviciosGrid.render.table_column_tipo" defaultMessage="Tipo"/></th>
												<th className="dt-search-header"><FormattedMessage id="serviciosGrid.render.table_column_max_aprobar" defaultMessage="Max. Aprobar"/></th>
												
												<th className=""></th>
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
					<div className="modal-xl modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="serviciosGrid.modal_export.header_exportar_servicios" defaultMessage=" Exportar Servicios"/></h4>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<div className="modal-body modal-grey modal-mh ovf-x-hidden">
								<div className="row">
									<div className="col-md-12">
										{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
											{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
										</div>*/}
										<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
											<div className="form-body">
												<div className="card pull-up">
													<div className="card-content">
														<div className="card-body">
															<h4 className="form-section">
																<i className="la la-columns"></i><FormattedMessage id="serviciosGrid.modal_export.columns_export.header_columnas_a_exportar" defaultMessage=" Columnas a exportar"/>
															</h4>


															<div className="row m-1">
																{Object.keys(this.state.dataToExport).map((key) =>{
																	let data = this.state.dataToExport[key];
																	return (<div className=" col-md-3" key = {key}>
																		<label className="col-md-6 label-control col-form-label" htmlFor={key}>
																			{data.title}:
																		</label>
																		<Switch
																			onChange = {(event) => this.handleDataExport(key)}
																			checked = {data.value ? true : false}
																			value={data.value}
																			id = {key}
																			name = {key}
																			offColor = "#FF4961"
																			onColor = "#28D094"
																			disabled = {false}
																			className =  "col-md-6 align-middle"
																		/>
																	</div>
																	);
																}
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-12 alert alert-info small" role="alert">
												<FormattedMessage id="serviciosGrid.modal_export.information_esta_accion_puede_demorar_unos_minutos" defaultMessage="Esta acción puede demorar unos minutos."/>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="modal-footer modal-grey">
								<button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportExcel.bind(this)} ><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="serviciosGrid.modal_export.finish_button.exportar_a_excel" defaultMessage=" Exportar a Excel"/></button>
								<button type="button" className="btn btn-danger" onClick={this.handleExportCancel} ><i className="fa fa-times-circle"></i><FormattedMessage id="serviciosGrid.modal_export.finish_button.cancelar" defaultMessage=" Cancelar"/></button>
							</div>
						</div>
					</div>
				</div>
	    	</React.Fragment>
	    );
  	}
}

export default injectIntl(ServiciosGrid);
