import React , { useRef } from "react";
import ReactToPrint from "react-to-print";
import FormularioViewPDF from './FormularioViewPDF.js';

class FormularioPdf extends React.Component {
  reactToPrintTrigger(){
    return (<button
              className="btn btn-sm btn-icon btn-dt-grid text-success"
              type="button"
            >
              <i className="ft-upload"></i>
            </button>);
  }
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={this.reactToPrintTrigger}
          content={() => this.componentRef}
        />
        <div style={{display: 'none'}}>
          <FormularioViewPDF ref={el => (this.componentRef = el) } {...this.props} action="VIEW"/>
        </div>
      </div>
    );
  }
}

export default FormularioPdf;