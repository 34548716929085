import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Config from '../../commons/config/Config.js';
import $ from 'jquery';
import 'bootstrap4-duallistbox';
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css';
import FormValidation from '../../commons/validation/FormValidation.js';
import Validator from '../../commons/validation/Validator.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';
import Loading from '../ui/Loading.js';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import 'moment/min/locales';
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';
import '../../assets/css/vec-dropzone.css';
import Security from '../../commons/security/Security.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import LlantaInspecciones from './LlantaInspecciones';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import ReactDOM from 'react-dom';
import LlantaInspeccionFirma from './LlantaInspeccionFirma.js';
import swal from 'sweetalert';
import CarLayout from './CarLayout';
import * as Utils from '../../commons/utils/Utils';

class LlantaInspeccionesAbm extends Component {
  constructor(props) {
    super(props);
    let component = this;
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    this.state = {
      redirectTo: null,
      props: this.props,
      movilSeleccionado: null,
      movil: null,
      formData: {
        movil: null,
        persona_id: null,
        activo: null,
        latitudForm: null,
        longitudForm: null,
        odometro: '',
        firma: '',
        llantaMediciones: null,
      },
      firmaOriginal: {},
      finalizacion: false,
      errors: [],
      loading: false,
      moviles: [],
      selectMovilDisabled: false,
      movilSelected: null,
      isFocus: false,
      odometroOriginal: null,
      llantasAuxilio: [],
      odometroNuevo: null,
      llantas: null,
      firstPunto: 0,
      mediciones: [],
      llantaMediciones: [],
      latitudForm: null,
      longitudForm: null,
      maxposicion: null,
      llantasThatNeedService: [],
      errorOdometro: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.setFirma = this.setFirma.bind(this);
    this.formValidation = new FormValidation({
      component: this,
      validators: {
        'formData.name': (value) => Validator.notEmpty(value),
      },
    });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  componentWillMount() {
    if (
      (Security.hasPermission('LLANTAS_INSPECCIONES_CREAR') && this.state.props.action === 'ADD') ||
      (Security.hasPermission('LLANTAS_INSPECCIONES_MODIFICAR') &&
        this.state.props.action === 'EDIT') ||
      (Security.hasPermission('LLANTAS_INSPECCIONES_VISUALIZAR') &&
        this.state.props.action === 'VIEW')
    ) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
      this.getLocation();
    } else {
      this.setState({
        redirectTo: '/error',
      });
    }
  }

  initForm() {
    this.setState({ loading: true });
    let component = this;
    if (this.state.props.action === 'VIEW' || this.state.props.action === 'EDIT') {
      this.loadFormData();
    } else {
      let movil_id = new URLSearchParams(this.props.location.search).get('movil_id');
      console.log(movil_id);
      if (movil_id) {
        this.setState({ selectMovilDisabled: true });
        Promise.all([
          Utils.getData('/moviles/' + movil_id)
        ]).then((data) => {
          let movil = { value: data[0].id, label: data[0].dominio };
          this.handleFormChangeSelectObject('movil', movil);
          this.setState({ loading: false });
        });
      } else {
        Promise.all([
          Utils.getData('/moviles/select')
        ]).then((data) => {
          this.setState({ moviles: data[0] });
          this.setState({ loading: false });
        });
      }
    }
    window.scrollTo(0, 0);
    $('#nombre').focus();
  }

  loadFormData() {
    let component = this;
    this.setState({ loading: true });
    this.ajaxHandler
      .getJson('/llantainspecciones/' + this.state.props.match.params.id)
      .then((data) => {
        this.getData('/moviles/' + data.movil.id).then((res) => {
          console.log(res);

          this.setState({
            movilSeleccionado: res,
          });
        });
        let formDataCopy = data;
        let llantaMediciones = [];
        let maxposicion = null;
        data.mediciones.map((medicion, index) => {
          let llantaMedicion = {};
          llantaMedicion.valoresMedidos = [];
          llantaMedicion.id = medicion.id;
          llantaMedicion.posicion = medicion.posicion;
          if (llantaMedicion.posicion > maxposicion) maxposicion = llantaMedicion.posicion;
          llantaMedicion.llanta_id = medicion.llanta_id;
          llantaMedicion.llanta = medicion.llanta;
          llantaMedicion.serial = medicion.llanta.serial;
          llantaMedicion.recapadas = medicion.llanta.recapadas;
          // llantaMedicion.mmInicio = medicion.llantaModelo.mmInicio;
          llantaMedicion.llanta_inspeccion_id = medicion.llanta_inspeccion_id;
          llantaMedicion.estado = medicion.estado;
          llantaMedicion.psi = medicion.psi;
          medicion.valor_medidos.map((punto, index) => {
            let medicion = {
              estado: punto.estado,
              id: punto.id,
              llanta_medicion_id: punto.llanta_medicion_id,
              nombre: punto.punto_dinamico ? punto.punto_dinamico.nombre : '',
              punto_dinamico_id: punto.id,
              mmWarning: punto.punto_dinamico ? punto.punto_dinamico.mmWarning : 0,
              mmDanger: punto.punto_dinamico ? punto.punto_dinamico.mmDanger : 0,
              valor: punto.value,
            };
            llantaMedicion.valoresMedidos.push(medicion);
          });
          llantaMediciones.push(llantaMedicion);
        });

        formDataCopy.llantaMediciones = llantaMediciones;
        this.setState({ formData: formDataCopy });
        this.setState({ maxposicion: maxposicion });
        this.setState({ loading: false });
      }).catch(error => {
        component.ajaxHandler.handleResponseErrorsIsValid(error);
        this.setState({ loading: false });
      });
  }

  getData(service) {
    let serviceURL = Config.get('apiUrlBase') + service;
    return fetch(serviceURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
    }).then((response) => {
      return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  handleSelectChange(event) {
    console.log(event);
  }

  handleInputFormChangeMediciones(event, index) {
    let llantasThatNeedServiceCopy = this.state.llantasThatNeedService;
    let puntoIndex = this.state.firstPunto;
    let medicionIndex = index;
    let maxValue = this.getInputMaxValue(this.state.formData.llantaMediciones[this.state.firstPunto].llanta);
    maxValue = parseFloat(maxValue)
    let numberEventValue = parseFloat(Number(event.target.value).toFixed(2))

    let value = numberEventValue > maxValue ? maxValue : event.target.value;
    let llanta = this.state.formData.llantaMediciones[this.state.firstPunto];
    let estado = this.calcLoss(index, value);
    this.state.formData.llantaMediciones[puntoIndex].valoresMedidos[medicionIndex].valor = value;
    this.setState({ formData: this.state.formData });
  }

  getInputMaxValue(llanta) {
    let maxValue = null;
    if(llanta.ultima_medicion){
      llanta.ultima_medicion.valor_medidos.forEach(function(element) {
        if(!maxValue)
          maxValue = element.value;
        else{
          if(maxValue < element.value)
            maxValue = element.value
        }
      })
      if(!maxValue)
        maxValue = llanta.llanta_modelo.mmInicio;
    }else
      maxValue = llanta.llanta_modelo.mmInicio
    return Number(maxValue)
  }

  handleInputFormChange(event, index) {
    let value = event.target.value;
    let name = event.target.name;

    let formDataCopy = this.state.formData;
    formDataCopy[name] = value;
    this.setState({ formData: formDataCopy });
  }

  handleOdometro() {
    if (this.state.formData.odometro != '') {
      if (this.state.formData.odometro < this.state.odometroOriginal) {
        this.setState({ errorOdometro: 'error.odometro_mayor_al_actual' });
        return;
      }
      if (Math.abs(this.state.formData.odometro - this.state.odometroOriginal) > 1000) {
        swal({
          title:
            this.props.intl.formatMessage({
              id: 'llantaInspeccionesAbm.modal_updateKm.confirm_diferencia_de_km_mayor_a',
              defaultMessage: 'Diferencia de Km mayor a ',
            }) +
            1000 +
            this.props.intl.formatMessage({
              id: 'llantaInspeccionesAbm.modal_updateKm.confirm_km_esta_seguro_que_desea_actualizar?',
              defaultMessage: ' Km. Esta seguro que desea actualizar?',
            }),
          text: '',
          icon: 'warning',
          showCancelButton: true,
          buttons: {
            confirm: {
              text: this.props.intl.formatMessage({
                id: 'llantaInspeccionesAbm.modal_updateKm.confirm_aceptar',
                defaultMessage: 'Aceptar',
              }),
              value: true,
              visible: true,
              className: 'btn btn-success',
              closeModal: false,
            },
            cancel: {
              text: this.props.intl.formatMessage({
                id: 'llantaInspeccionesAbm.modal_updateKm.confirm_cancelar',
                defaultMessage: 'Cancelar',
              }),
              value: null,
              visible: true,
              className: 'btn btn-danger',
              closeModal: false,
            },
          },
        }).then((isConfirm) => {
          if (isConfirm) {
            this.setState({
              errorOdometro: null
            });
            swal(
              this.props.intl.formatMessage({
                id: 'llantaInspeccionesAbm.modal_updateKm.information_se_actualiza_al_aceptar_formulario',
                defaultMessage: 'Cambio aprobado. Se actualizara al finalizar.',
              }),
              '',
              'success',
            );
          } else {
            let formDataCopy = this.state.formData;
            formDataCopy.odometro = '';
            this.setState({
              formData: formDataCopy,
              errorOdometro: null
            });
            swal(
              this.props.intl.formatMessage({
                id: 'llantaInspeccionesAbm.modal_updateKm.information_actualizacion_cancelada!',
                defaultMessage: 'Actualización Cancelada!',
              }),
              '',
              'error',
            );
          }
        });
      }
    } else {
      console.log('no input');
    }
  }

  calcLoss(index, value) {
    let llantaMediciones = this.state.formData.llantaMediciones;
    let medicionesIndex = this.state.firstPunto;
    let mmInicio = llantaMediciones[medicionesIndex].mmInicio;

    let loss = mmInicio - value;
    if (loss === llantaMediciones[medicionesIndex].valoresMedidos[index].mmDanger) {
      // console.log('DANGER BRO');
      return 0;
    } else {
      return 1;
    }
  }

  etapaFinal(event) {
    this.setState({ finalizacion: true });
    this.setState({ loading: false });
    event.preventDefault();
  }

  handleFormChangeSelectObject(name, e) {
    this.setState({ errors: [] });
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.movil = e;
    if (e) {
      //Fetch PuntosMedicion from MOVIL ID
      Promise.all([
        Utils.getData('/llantas/llantasdemovil/' + e.value),
        Utils.getData('/moviles/' + e.value),
        Utils.getData('/llantas/llantasdemovil/' + e.value + '/auxilio'),
      ]).then((data) => {
        this.setState({ llantas: data[0] });
        let llantas = data[0].filter((e) => e.posicion);
        let llantasAuxilio = data[2];
        let odometroOriginal = data[1].kmActual;
        let llantaMediciones = [];
        let maxposicion = null;
        llantas.map((llanta, index) => {
          let llantaMedicion = {};
          llantaMedicion.serial = llanta.serial;
          llantaMedicion.recapadas = llanta.recapadas;
          llantaMedicion.llanta_id = llanta.id;
          llantaMedicion.llanta = llanta;
          llantaMedicion.posicion = llanta.posicion;
          llantaMedicion.valoresMedidos = [];
          llantaMedicion.psi = llanta.ultima_medicion ? llanta.ultima_medicion.psi : '';
          if (llanta.posicion > maxposicion) maxposicion = llanta.posicion;
          llanta.modelo.puntos_medicion.map((punto, indexValoresMedidos) => {
            let valoresMedidos = llanta.ultima_medicion
              ? llanta.ultima_medicion.valor_medidos
              : null;
            let valor = null;
            if (valoresMedidos) {
              valoresMedidos.map((valoreMedido, indexValor) => {
                if (valoreMedido.punto_dinamico_id === punto.id) valor = valoreMedido.value;
              });
            }
            let medicion = {
              nombre: punto.nombre,
              estado: 0,
              valor: valor ? valor : '',
              llanta_medicion_id: null,
              mmWarning: punto.mmWarning,
              mmDanger: punto.mmDanger,
              punto_dinamico_id: punto.id,
            };
            llantaMedicion.valoresMedidos.push(medicion);
          });
          llantaMediciones.push(llantaMedicion);
        });
        formDataCopy.llantaMediciones = llantaMediciones;
        this.setState({
          maxposicion: maxposicion,
          formData: formDataCopy,
          odometroOriginal: odometroOriginal,
          movilSeleccionado: data[1],
          llantasAuxilio: llantasAuxilio,
        });
      });
    } else {
      formDataCopy.llantaMediciones = [];
      this.setState({
        formData: formDataCopy,
        maxposicion: 0,
        llantas: [],
        firstPunto: 0,
        finalizacion: false,
      });
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((event) => this.showPosition(event));
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  showPosition(position) {
    this.setState({
      latitudForm: position.coords.latitude,
      longitudForm: position.coords.longitude,
    });
  }

  setFirma(firma) {
    if (firma === null) this.state.formData.firma = null;
    else this.state.formData.firma = JSON.stringify(firma);
  }

  submitInspeccion() {
    console.log('INSPECCION SUBMITTED');
  }

  handleSubmit(event) {
    event.preventDefault();
    let errors = [];
    if (this.state.formData.firma == '' || this.state.formData.firma == null) {
      errors.push('llantaInspeccionesAbm.errors.firma_requerida');
    }
    if (!this.state.formData.odometro) {
      errors.push('llantaInspeccionesAbm.errors.odometro_es_requerido');
    }

    if (!this.state.formData.firma || !this.state.formData.odometro) {
      this.setState({ errors: errors });
      window.scrollTo(0, 0);
      return event.preventDefault();
    }
    this.setState({ loading: true });
    let component = this;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.activo = 1;
    formDataCopy.persona_id = JSON.parse(localStorage.getItem('persona')).id;
    formDataCopy.latitudForm = this.state.latitudForm;
    formDataCopy.longitudForm = this.state.longitudForm;

    //POST Inspection
    this.ajaxHandler
      .fetch(
        '/llantainspecciones' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id),
        {
          method: this.props.action === 'ADD' ? 'POST' : 'PUT',
          body: JSON.stringify({
            ...formDataCopy,
          }),
        },
      )
      .then((response) => {
        if (response.status !== 400) {
          component.exit();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
      })
      .catch((error) => {
        component.ajaxHandler.handleResponseErrorsIsValid(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  endInspeccion() {
    console.log('HEY THERE MY FRIEND');
  }

  handleCancel(event) {
    this.exit();
  }

  exit() {
    this.setState({
      redirectTo: '/llantainspecciones',
    });
  }

  

  renderPunto() {
    // CHECK IF LLANTA NEEDS SERVICE
    let checking = true;
    if (checking) {
      let llantasThatNeedServiceCopy = this.state.llantasThatNeedService;
      let llanta = this.state.formData.llantaMediciones[this.state.firstPunto];
      llanta.valoresMedidos.map((medicion) => {
        if (medicion.valor <= medicion.mmDanger) {
          llantasThatNeedServiceCopy[this.state.firstPunto] = llanta;
        }
      });
      checking = false;
    }
    if (!checking) {
      this.setState({ firstPunto: this.state.firstPunto + 1 });
      if (!this.state.formData.llantaMediciones[this.state.firstPunto]) {
      }
    }
  }

  handleChangePSI(event) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    let psi = event.target.value;
    let llanta_index = this.state.firstPunto;
    formDataCopy.llantaMediciones[llanta_index].psi = psi;
    this.setState({ formData: formDataCopy });
  }

  render() {
    let formData = this.state.formData;

    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.errors.length === 0}
            >
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage
                    {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}
                  />
                </li>
              ))}
            </div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i>
                        <FormattedMessage
                          id="llantaInspeccionesAbm.render.general_data.header_datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                        <div className="float-right" style={{ fontSize: '14px' }}>
                          <FormattedMessage
                            id="llantaInspeccionesAbm.render.general_data.campos_requeridos"
                            defaultMessage="* campos requeridos"
                          />
                        </div>
                      </h4>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label text-nowrap"
                              htmlFor="observaciones"
                            >
                              <FormattedMessage
                                id="llantaInspeccionesAbm.render.general_data.select_movil"
                                defaultMessage="Placa"
                              />{' '}
                              *:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.formData.movil
                                    ? this.state.formData.movil.dominio
                                    : ''}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                    id="movil"
                                    name="movil"
                                    placeholder={this.props.intl.formatMessage({
                                      id: 'llantaInspeccionesAbm.render.general_data.select_placeholder_movil',
                                      defaultMessage: 'Seleccione',
                                    })}
                                    options={this.state.moviles}
                                    valueKey="value"
                                    labelKey="label"
                                    disabled={this.state.selectMovilDisabled}
                                    value={
                                      this.state.formData.movil ? this.state.formData.movil : ''
                                    }
                                    onChange={(e) => this.handleFormChangeSelectObject('movil', e)}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6"></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*PuntosMedicion*/}
                {this.state.formData.llantaMediciones ? (
                  this.state.formData.llantaMediciones[this.state.firstPunto] ? (
                    <div className="card pull-up">
                      <div className="card-content">
                        <div className="card-body">
                          <h4 className="form-section">
                            {!this.state.finalizacion ? (
                              <div>
                                <i className="las la-file"></i>
                                <FormattedMessage
                                  id="llantaInspeccionesAbm.render.header.punto_medicion.label_posicion"
                                  defaultMessage="Posicion"
                                />{' '}
                                {this.state.formData.llantaMediciones[this.state.firstPunto]
                                  ? this.state.formData.llantaMediciones[this.state.firstPunto]
                                      .posicion
                                  : ''}{' '}
                                <FormattedMessage
                                  id="llantaInspeccionesAbm.render.header.punto_medicion.label_posicion_de"
                                  defaultMessage="de"
                                />{' '}
                                {this.state.maxposicion}
                                <div className="float-right" style={{ fontSize: '14px' }}>
                                  <FormattedMessage
                                    id="llantaInspeccionesAbm.render.data_llanta_posicion.campos_requeridos"
                                    defaultMessage="* campos requeridos"
                                  />
                                </div>
                              </div>
                            ) : (
                              <i className="las la-file"></i>
                            )}
                          </h4>
                          {!this.state.finalizacion ? (
                            <div className="form-group row">
                              <div className="col-md-6">
                                {/*ROW para cada PuntoDinamico*/}
                                {this.state.formData.llantaMediciones[this.state.firstPunto] ? (
                                  <div>
                                    <div className="row">
                                      <label
                                        className="col-md-4 label-control col-form-label text-nowrap"
                                        htmlFor="observaciones"
                                      >
                                        <FormattedMessage
                                          id="llantaInspeccionesAbm.render.data_llanta.label_cautin"
                                          defaultMessage="Cautin"
                                        />{' '}
                                        :
                                      </label>
                                      <div className="col-md-8">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="descripcion"
                                          name="descripcion"
                                          placeholder={
                                            this.state.formData.llantaMediciones[
                                              this.state.firstPunto
                                            ].serial +
                                            ' - ' +
                                            this.props.intl.formatMessage({
                                              id: 'llantaInspeccionesAbm.render.data_llanta.cautin',
                                              defaultMessage: 'Recapados',
                                            }) +
                                            ': ' +
                                            +this.state.formData.llantaMediciones[
                                              this.state.firstPunto
                                            ].recapadas
                                          }
                                          value={formData.descripcion}
                                          onChange={this.handleInputFormChangeMediciones}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                    {this.state.formData.llantaMediciones[
                                      this.state.firstPunto
                                    ].valoresMedidos.map((punto, index) => {
                                      return (
                                        <div key={index} className="row mt-1">
                                          <label
                                            className="col-md-4 label-control col-form-label text-nowrap"
                                            htmlFor="observaciones"
                                          >
                                            {punto ? punto.nombre + ' *:' : ''}
                                          </label>
                                          <div className="col-md-8 row no-gutters">
                                            <input
                                              type="number"
                                              className="form-control col-md-4 px-1"
                                              value={
                                                this.state.formData.llantaMediciones[
                                                  this.state.firstPunto
                                                ].valoresMedidos[index]
                                                  ? this.state.formData.llantaMediciones[
                                                      this.state.firstPunto
                                                    ].valoresMedidos[index].valor
                                                  : 0
                                              }
                                              min="0"
                                              max={this.state.llantas ? this.getInputMaxValue(this.state.formData.llantaMediciones[this.state.firstPunto].llanta) : 12}
                                              // value={this.state.mediciones[this.state.firstPunto] ? (this.state.mediciones[this.state.firstPunto][index] ? this.state.mediciones[this.state.firstPunto][index].valor : '') : ''}
                                              onChange={(e) =>
                                                this.handleInputFormChangeMediciones(e, index)
                                              }
                                              disabled={this.props.action === 'VIEW'}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}

                                    <div className="row my-2">
                                      <label
                                        className="col-md-4 label-control col-form-label text-nowrap"
                                        htmlFor="observaciones"
                                      >
                                        <FormattedMessage
                                          id="llantaInspeccionesAbm.render.puntos_medicion.label_psi"
                                          defaultMessage="PSI"
                                        />
                                        {' * '} :
                                      </label>
                                      <div className="col-md-8">
                                        <input
                                          type="number"
                                          className="form-control col-md-4 px-1"
                                          id="psi"
                                          name="psi"
                                          placeholder={this.props.intl.formatMessage({
                                            id: 'llantaInspeccionesAbm.render.puntos_medicion.placeholder_psi',
                                            defaultMessage: '100',
                                          })}
                                          value={
                                            this.state.formData.llantaMediciones[
                                              this.state.firstPunto
                                            ].psi
                                          }
                                          onChange={(e) => this.handleChangePSI(e)}
                                          disabled={this.props.action === 'VIEW'}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <div>
                                  <p className="card-title text-center h3 display-3">
                                    <FormattedMessage
                                      id="llantaInspeccionesAbm.render.puntos_medicion.posicion_llanta_actual"
                                      defaultMessage="Llanta Actual"
                                    />
                                  </p>
                                  {this.state.movilSeleccionado && (
                                    <CarLayout
                                      llantaSeleccionada={
                                        this.state.formData.llantaMediciones[this.state.firstPunto]
                                      }
                                      movil={this.state.movilSeleccionado}
                                      llantas={this.state.llantas}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="form-group row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <label className="col-md-4 label-control col-form-label text-nowrap">
                                      <FormattedMessage
                                        id="llantaInspeccionesAbm.render.odometro_label"
                                        defaultMessage="Odometro *:"
                                      />
                                      <br />
                                      
                                    </label>
                                    <div className="col-md-6">
                                      <input
                                        type="number"
                                        min={this.state.odometroOriginal}
                                        className="form-control odometro-input"
                                        id="odometro"
                                        name="odometro"
                                        onBlur={() => this.handleOdometro()}
                                        placeholder={
                                          this.state.odometroOriginal !== null
                                            ? this.state.odometroOriginal
                                            : this.props.intl.formatMessage({
                                                id: 'llantaInspeccionesAbm.render.odometro_placeholder',
                                                defaultMessage: '500',
                                              })
                                        }
                                        value={formData.odometro}
                                        onChange={(e) => this.handleInputFormChange(e)}
                                        disabled={this.props.action === 'VIEW'}
                                      />
                                      {this.state.errorOdometro ? (
                                        <span className="text-danger" style={{ fontSize: '11px' }}>
                                          <i>
                                            <FormattedMessage {...(backendStrings[this.state.errorOdometro] ? backendStrings[this.state.errorOdometro] : backendStrings['errors.default'])}/>
                                            : {this.state.odometroOriginal}
                                          </i>
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group row">
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label className="col-md-4 label-control col-form-label text-nowrap">
                                      <span className="col-form-label">
                                        <FormattedMessage
                                          id="llantaInspeccionesAbm.render.firma_label"
                                          defaultMessage="Firma *:"
                                        />
                                      </span>
                                      <br />
                                      {this.state.errors['firma'] ? (
                                        <span className="text-danger" style={{ fontSize: '11px' }}>
                                          <i>[{this.state.errors['firma']}]</i>
                                        </span>
                                      ) : null}
                                    </label>
                                    <div className="col-md-8">
                                      <LlantaInspeccionFirma
                                        intl={this.props.intl}
                                        setFirma={this.setFirma}
                                        action={this.state.props.action}
                                        firma={this.state.formData.firma}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : this.state.formData.movil ? (
                    <div className="card pull-up">
                      <div className="card-content">
                        <div className="card-body">
                          <h4 className="form-section">
                            <i className="las la-file"></i>
                            <FormattedMessage
                              id="llantaInspeccionesAbm.render.header.no_punto_medicion.label_no_punto"
                              defaultMessage="Movil no tiene puntos"
                            />
                          </h4>
                          <div className="form-group row">
                            <div
                              style={{
                                width: '100%',
                                minHeight: '200px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <h1>
                                <FormattedMessage
                                  id="llantaInspeccionesAbm.render.no_punto_medicion.label_no_punto"
                                  defaultMessage="Este modelo no cuenta con puntos de medición designados"
                                />
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                ) : null}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.state.formData.llantaMediciones &&
                        this.state.formData.llantaMediciones.length > 0 ? (
                          this.state.firstPunto <
                          this.state.formData.llantaMediciones.length - 1 ? (
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={(e) => this.renderPunto()}
                            >
                              <i className="fa fa-check-circle"></i>{' '}
                              <FormattedMessage
                                id="llantaInspeccionesAbm.posicion_button.label_posicion"
                                defaultMessage="Posicion"
                              />{' '}
                              {this.state.formData.llantaMediciones[this.state.firstPunto + 1]
                                ? this.state.formData.llantaMediciones[this.state.firstPunto + 1]
                                    .posicion
                                : null}
                            </button>
                          ) : !this.state.finalizacion ? (
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={(e) => this.etapaFinal(e)}
                            >
                              <i className="fa fa-check-circle"></i>
                              <FormattedMessage
                                id="llantaInspeccionesAbm.button_siguiente"
                                defaultMessage=" Seguir >"
                              />
                            </button>
                          ) : this.props.action !== 'VIEW' ? (
                            <button type="submit" className="btn btn-primary mr-1">
                              <i className="fa fa-check-circle"></i>
                              <FormattedMessage
                                id="llantaInspeccionesAbm.finish_button.finalizar"
                                defaultMessage=" Finalizar"
                              />
                            </button>
                          ) : null
                        ) : null}
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.handleCancel.bind(this)}
                        >
                          <i className="fa fa-times-circle"></i>{' '}
                          {this.props.action === 'VIEW'
                            ? this.props.intl.formatMessage({
                                id: 'llantaInspeccionesAbm.finish_button.volver',
                                defaultMessage: 'Volver',
                              })
                            : this.props.intl.formatMessage({
                                id: 'llantaInspeccionesAbm.finish_button.cancelar',
                                defaultMessage: 'Cancelar',
                              })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(LlantaInspeccionesAbm);
