import axios from 'axios';
import Config from '../../commons/config/Config.js';
import swal from 'sweetalert2';
// import { useIntl, FormattedMessage } from 'react-intl'

// Se pasa un service o modulo. E.G.: "/moviles" "/moviles/2"
export const getData = (service) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: Config.get('apiUrlBase') + service,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        handleError(err)
          reject(err);
      });
  });
};

// Se pasa un service o modulo como String. E.G.: "/moviles" "/moviles/2"
// Se suma un metodo como String. E.G.: "POST" "PUT"
export const sendData = (service, method, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: Config.get('apiUrlBase') + service,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
      data: data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};

export const getdDataBlob = (service, method, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: Config.get('apiUrlBase') + service,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
      data: data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};

export const sendDataBlob = (service, method, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: Config.get('apiUrlBase') + service,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
      data: data,
    })
    .then((res) => resolve(res.data))
    .catch((err) => {
        handleError(err);
        reject(err);
      });
  });
};

// Se pasa un service o modulo como String. E.G.: "/moviles" "/moviles/2"
export const deleteData = (service) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: Config.get('apiUrlBase') + service,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => (err.response.data.codigo === 4000 ? reject(err) : handleError(err)));
  });
};

// Maneja los errores con un modal de sweet-alert;
const handleError = (err) => {
  console.log(err.response);
  return new Promise((resolve, reject) =>{
    let codigo = err.response.data.codigo
    let message = ""
    let detail = ""
    
    if(codigo === 4011 || codigo === 4010 || err.response.status === 401) {
      message = 'La sesión expiró.';
      detail = 'Por favor, ingrese nuevamente.';
    } else if(codigo === 4030) {
      message = 'No tiene permisos para realizar esta acción.';
      detail = 'Contacte al administrador.';
    } else if(codigo >= 5000) {
      message = 'Error interno del sistema.';
      detail = 'Contacte al administrador.';
    }
  
    if(codigo === 4011 || codigo === 4010 || err.response.status === 401) {
      swal({
        title: message,
        text: detail,
        icon: "error",
        buttons: {
          confirm: {
            text: 'Aceptar',
            value: true,
            visible: true,
            className: "btn btn-primary",
            closeModal: true
          }
        }
      }).then((isConfirmed) => {
        if (isConfirmed) {
          window.location.reload();
          resolve(message)
        }
      });
    }
    else{
      swal.fire(message, detail, 'error');
      resolve(message)
    }
  })

};
