import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Security from '../../commons/security/Security.js'

import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class TipoFormularioGrid extends Component {
	constructor(props) {
		super(props);
        this.dataService = '/tipoformulario/grid';
		this.apiBaseUrl = process.env.REACT_APP_VEC_FLEET_API_BASE_URL;
		this.ajaxHandler = new AjaxHandler();

		this.state = {
			redirectTo: null,
			estados: ['Inactivo', 'Activo']
		}
	}

	componentDidMount(nextProps, nextState) {
        if(Security.hasPermission('CHECKLIST_LISTAR_TIPO_FORMULARIOS')) {
            let component = this;
            // filtros para los selectfilter y menufilter
            let filters = {
                'activo': [
                    {
                        value: 1,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_state_filter.select_activo', defaultMessage: 'Activo' }),
                    },
                    {
                        value: 0,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_state_filter.select_inactivo', defaultMessage: 'Inactivo' })
                    }
                ],
                "con_persona": [
                    {
                        value: 1,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_person_filter.select_si', defaultMessage: 'Sí' }),
                    },
                    {
                        value: 0,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_person_filter.select_no', defaultMessage: 'No' })
                    }
                ],
                "con_movil": [
                    {
                        value: 1,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_movil_filter.select_si', defaultMessage: 'Sí' }),
                    },
                    {
                        value: 0,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_movil_filter.select_no', defaultMessage: 'No' })
                    }
                ],
                "con_medidor": [
                    {
                        value: 1,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_medidor_filter.select_si', defaultMessage: 'Sí' }),
                    },
                    {
                        value: 0,
                        label: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_medidor_filter.select_no', defaultMessage: 'No' })
                    }
                ],
                'icono': [
                        {icon: "&#xf207", value:"fa fa-bus" },
                        {icon: "&#xf21c", value:"fa fa-motorcycle" },
                        {icon: "&#xf1b9", value:"fa fa-car" },
                        {icon: "&#xf0d1", value:"fa fa-truck" },
                        {icon: "&#xf007", value:"fa fa-user" },
                        {icon: "&#xf0c0", value:"fa fa-users" },
                        {icon: "&#xf0ad", value:"fa fa-wrench" },
                        {icon: "&#xf013", value:"fa fa-cog" },
                        {icon: "&#xf041", value:"fa fa-map-marker" }
                ]
            };

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					order: [[0,'desc']],
					stateSave: true,
					stateSaveCallback: function(settings,data) {
						localStorage.setItem( 'DataTables_TipoFormularioGrid' , JSON.stringify(data) );
					},
					stateLoadCallback: function(settings) {
						return JSON.parse( localStorage.getItem( 'DataTables_TipoFormularioGrid' ) );
					},
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + this.dataService,

						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function (response) {
							return response.data;
						},
						error: function (xhr, error, thrown) {
						},
						cache: false
					},
					initComplete: function (settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_TipoFormularioGrid' )).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every( function () {
							var column = this;

							if(sets.aoColumns[index].filterType){
							// Filtro select

							if (sets.aoColumns[index].filterType === 'select') {
								var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'tipoFormularioGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
									.appendTo($(column.footer()).empty())
									.on('change', function() {
										var val = $.fn.dataTable.util.escapeRegex($(this).val());
										column
											.search(val ? val : '', true, false)
											.draw();
									});
								if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
									filters[sets.aoColumns[index].name].map(e => {
										select.append('<option value="' + e.value + '">' + e.label + '</option>');
										return true;
									});
								} else {
									column.data().unique().sort().each(function(d, j) {
										select.append('<option value="' + d + '">' + d + '</option>');
									});
								}
								if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
							}

							if (sets.aoColumns[index].filterType === 'select-icons') {

								let optionSelect = '';
								var select = $(`<div class="btn-group">
								<button type="button" id="btnDrop" class="btn btn-sm dropdown-toggle btn-filter-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								  <i class=""></i>
								</button>
								<div class="dropdown-menu" id="iconosDrop" style="max-width: 70vw;overflow-x:scroll">
									<a data-icon="" class="dropdown-item" href="#"><i>...</i></a>
								</div>
							  </div>`)
									.appendTo($(column.footer()).empty())

								if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
									filters[sets.aoColumns[index].name].map(e => {
										optionSelect = `<a data-icon="`+ e.value +`" class="dropdown-item" href="#"><i class="`+ e.value +` fa-2x"></i></a>`;

										$(optionSelect).appendTo($("#iconosDrop"))

										return true;
									});

									$("#iconosDrop").on("click", function(e){
										if(e.target.localName == 'i')
											var val = e.target.parentElement.dataset.icon;
										else
											var val = e.target.dataset.icon;

										$("#btnDrop i").removeClass();
										$("#btnDrop i").addClass(val);

										column
											.search(val ? val : '', false, false)
											.draw();
									});
								}
								if (stateColumns && stateColumns[index].search.search) {
									$("#btnDrop i").removeClass();
									$("#btnDrop i").addClass(stateColumns[index].search.search)
									select.val(stateColumns[index].search.search);
								}

							}

							// Filtro input
								if(sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'tipoFormularioGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on( 'keyup change', function () {
										if ( column.search() !== this.value ) {
											column
											.search( this.value )
											.draw()
											.ajax.reload(null, false);
										}
									});
								}



							// Filtro menu-estado
							if (sets.aoColumns[index].filterType === 'menuEstado') {

								var menuEstado = $('<div class="btn-group dropright"><button type="button" class="dropdown-toggle button-embudo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ft-filter"></i></button><div class="dropdown-menu" id="menuEstadoDrop"><h6 class="dropdown-header">'+component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_state_filter.label_estado', defaultMessage: 'Estado' })+'</h6></div></div>')
										.appendTo($(column.footer()).empty());

										filters[sets.aoColumns[index].name].map(e => {
											$('#menuEstadoDrop').append('<button class="dropdown-item" value="' + e.value + '">' + e.label + '</button>');
											return true;
										});
										$('#menuEstadoDrop').on('click', function(e) {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
										column
												.search(e.target.value ? e.target.value : '', true, false)
											.draw();
										});
								if (stateColumns && stateColumns[index].search.search) menuEstado.val(stateColumns[index].search.search);

							}

						}

							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [

						{
								title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_id', defaultMessage: 'Id' }),
								name: 'id',
								className: 'hidden',
								filterType: 'input',
						},
						{
							title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_tipo', defaultMessage: 'Tipo' }),
							name:'icono',
							width: 5,
							padding: 0,
							orderable: false,
							orderable: false,
							filterType: 'select-icons',
							className: 'all text-center',
							render: function(data, type, full, meta) {
								return '<i class="fa-2x ' + data + ' "/>';
							},
						},
						{
							title: '',
							name: 'activo',
							width: 3,
							padding: 0,
							orderable: false,
							className: 'p-0 all embudo',
							render: function(data, type, full, meta) {
								return '';
							},
							createdCell: function(td, cellData, rowData, row, col) {
								$('div.tooltip[role="tooltip"]').tooltip('dispose');
								$('div.tooltip[role="tooltip"]').remove();
								$(td).attr('data-togle', 'tooltip');
								$(td).attr('data-placement', 'top');
								$(td).attr('title', component.state.estados[cellData]);
								$(td).tooltip();
								if (cellData == 1)
									$(td).addClass('status-green');
							  else
									$(td).addClass('status-red');

							},
							filterType: 'menuEstado'
						},
						{
								title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_nombre', defaultMessage: 'Nombre' }),
								name: 'nombre',
								className: 'all',
								filterType: 'input',
								render: function (data, type, full, meta) {
									if (type === "filter") {
									  return data;
									} else {
									  return data
										? '<div data-togle="tooltip" data-placement="top" title="' +
											data +
											'">' +
											data.substring(0, 20) +
											"</div>"
										: "";
									}
								  },
								  createdCell: function (td, cellData, rowData, row, col) {
									$(td).find("div").tooltip();
							  },
						},
						{
								title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_descripcion', defaultMessage: 'Descripcion' }),
								className: 'all',
								name: 'descripcion',
								filterType: 'input',
								render: function (data, type, full, meta) {
									if (type === "filter") {
									  return data;
									} else {
									  return data
										? '<div data-togle="tooltip" data-placement="top" title="' +
											data +
											'">' +
											data.substring(0, 20) +
											"</div>"
										: "";
									}
								  },
								  createdCell: function (td, cellData, rowData, row, col) {
									$(td).find("div").tooltip();
							  },
						},
						{
								title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_persona', defaultMessage: 'Persona' }),
								className: 'all',
								name: 'con_persona',
								filterType: 'select',
								render: function(data, type, row) {
										return data ? 'Sí': 'No'
								},
						},
						{
								title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_movil', defaultMessage: 'Movil' }),
								name: 'con_movil',
								className: 'all',
								filterType: 'select',
								render: function(data, type, row) {
										return data ? 'Sí': 'No'
								},
						},
						{
								title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_medidor', defaultMessage: 'Medidor' }),
								className: 'all',
								name: 'con_medidor',
								filterType: 'select',
								render: function(data, type, row) {
										return data ? 'Sí': 'No'
								},
						},
						{
								title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.select_atributos', defaultMessage: 'Atributos' }),
								className: 'all cursor-pointer',
								name: 'nombresAtributos',
								filterType: 'none',
								orderable: false,
								render: function (data, type, full, meta) {
									if (type === "filter") {
									  return data;
									} else {
									  return data
										? '<div data-togle="tooltip" data-placement="top" title="' +
											data +
											'">' +
											data.substring(0, 20) +
											"</div>"
										: "";
									}
								  },
								  createdCell: function (td, cellData, rowData, row, col) {
									$(td).find("div").tooltip();
							  },
						},
						{
								orderable: false,
								data: null,
								searchable: false,
								name: 'actions',
								className: "text-center all",
								width: '120px',
								render: function (data, type, full, meta) {
                                    let botones = '';
                                    if (Security.hasPermission('CHECKLIST_VISUALIZAR_TIPO_FORMULARIOS'))
                                        botones += `							
                                            <button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`">
                                                <i class="fa fa-search fa-xs"></i>
                                            </button>`;
                                    if (Security.hasPermission('CHECKLIST_EDITAR_TIPO_FORMULARIOS'))
										botones += `<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'tipoFormularioGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`">
											<i class="fa fa-pencil fa-xs"></i>
										</button>`;
                                        
                                    return botones;
								}
						}],
						drawCallback: function () {
							$(this).find('.action').on('click', function () {
								let data = component.table.row($(this).parents('tr')).data();
								let redirectTo;
								if($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data[0];
								if($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data[0] + '/edit';
								component.setState({
									redirectTo: redirectTo
								});

								if($(this).hasClass('delete')) {
									swal({
									title: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
								})
								.then((isConfirm) => {
									if (isConfirm) {
										fetch(Config.get('apiUrlBase') + '/tipoformulario/' + data[0], {
											method: 'DELETE',
											headers: {
												'Accept': 'application/json',
												'Content-Type': 'application/json',
												'Authorization-Token': localStorage.getItem("token")
											}
										})
										.then(function(response) {
											component.table.ajax.reload(null, false);
											swal(component.props.intl.formatMessage({ id: 'tipoFormularioGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
										})
										.catch(function(error) {
											swal(component.props.intl.formatMessage({ id: 'tipoFormularioGrid.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
										});
									}
								});
								}
							});
						},
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons( this.table, {
				buttons: [
					{
			extend: 'print',
			text: '<i class="ft-printer"></i>',
						title: '',
						customize: function(win)
						{
							var css = '@page { size: landscape; }',
								head = win.document.head || win.document.getElementsByTagName('head')[0],
								style = win.document.createElement('style');

							style.type = 'text/css';
							style.media = 'print';

							if (style.styleSheet) {
								style.styleSheet.cssText = css;
							}	else {
								style.appendChild(win.document.createTextNode(css));
							}

							head.appendChild(style);

							$(win.document.body)
								.css( 'font-size', '10pt' )
								.prepend(`<div class="container">
														<div class="row mb-2">
															<div class="col-6">
																<img style="height: 35px" src="`+LogoCompany+`" />
															</div>
															<div class="col-6	text-right">
																<img style="height: 40px" src="`+LogoVecFleet+`" />
															</div>
														</div>
														<div class="row mb-2">
															<div class="col-12" style="border-bottom: 1px solid #000;">
																<h2>`+component.props.intl.formatMessage({ id: 'tipoFormularioGrid.print_content.label_listado_de_regiones', defaultMessage: 'Listado de Regiones' })+`</h2>
															</div>
														</div>
													</div>`);

							$(win.document.body).find( 'table' )
								.addClass( 'compact' )
								.css( 'font-size', 'inherit' );
						},
			exportOptions: {
				columns: [ 3,4 ]
			},
						titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
			},
			{
			extend: 'colvis',
						text: '<i class="la la-columns"></i>',
						titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
			},
			{
			extend: 'collection',
						text: '<i class="ft-upload"></i>',
						titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_export.title_exportar', defaultMessage: 'Exportar' }),
			buttons: [
			{
				extend: 'copyHtml5',
				exportOptions: {
				columns: ':visible'
				},
							text: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_export.select_Copiar', defaultMessage: 'Copiar' }),
							titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_export.select_title_copiar', defaultMessage: 'Copiar' })
			},
			{
				extend: 'excelHtml5',
				exportOptions: {
				columns: ':visible'
							},
							titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_export.select_exportar_title_excel', defaultMessage: 'Exportar a Excel' })
			},
			{
				extend: 'csvHtml5',
				exportOptions: {
				columns: ':visible'
							},
							titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_export.select_exportar_formato_title_csv', defaultMessage: 'Exportar en formato .csv' })
			},
			{
				extend: 'pdfHtml5',
				exportOptions: {
				columns: ':visible'
							},
							titleAttr: component.props.intl.formatMessage({ id: 'tipoFormularioGrid.tools_export.select_exportar_title_PDF', defaultMessage: 'Exportar a PDF' })
						}
						]
					}
				]
			});

			this.table.buttons( 0, null ).container().appendTo('#buttons');

			/*$('#dataTable tfoot th').each( function () {
				var title = $(this).text();
				if(title !== '') $(this).html( '<input type="text" class="btn-dt-filter" placeholder="Buscar ..." />' );
			});*/

			this.table.columns([3]).every( function () {
				var that = this;
				$( 'input', this.footer()).on( 'keyup change', function () {
					if ( that.search() !== this.value ) {
						that
							.search( this.value )
							.draw()
							.ajax.reload(null, false);
					}
				});
				return null;
			});
			component.setState({
				loading: false
			});

			$('.buttons-collection').on("click",function(e){ e.stopPropagation();
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
        } else {
            this.setState({
		        redirectTo: '/error'
	      	});
        }
	}

	handleNewBase(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	componentWillUnmount() {
		$('div.tooltip[role="tooltip"]').tooltip('dispose');
		if(this.table) this.table.destroy();
	}

	render() {
	    return (
	    	<React.Fragment>
	    		{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    			<div className="row mt-2">
						<div className="col-12">
			    		<div className="card">
								<div className="card-content collpase show">
			            <div className="card-body card-dashboard">
										<div className="container-fluid">
											<div className="row dt-icons">
												<div className="col-6">
                                                { Security.hasPermission('CHECKLIST_CREAR_TIPO_FORMULARIOS') ?
													<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNewBase.bind(this)}>
														<i className="ft-plus"></i>
													</div>
                                                     :
                                                     null
                                                }
												</div>
												<div className="col-6" id="buttons"></div>
											</div>
										</div>
			    					<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>

													<th className="p-0"></th>
													<th className="p-0"></th>
													<th className="">Icono</th>
													<th className="dt-search-header"><FormattedMessage id="tipoFormularioGrid.render.table_column_nombre" defaultMessage="Nombre"/></th>
													<th className="dt-search-header"><FormattedMessage id="tipoFormularioGrid.render.table_column_persona" defaultMessage="Persona"/></th>
													<th className="dt-search-header"><FormattedMessage id="tipoFormularioGrid.render.table_column_movil" defaultMessage="Movil"/></th>
													<th className="dt-search-header"><FormattedMessage id="tipoFormularioGrid.render.table_column_medidor" defaultMessage="Medidor"/></th>
													<th className="dt-search-header"><FormattedMessage id="tipoFormularioGrid.render.table_column_descripcion" defaultMessage="Descripcion"/></th>
													<th className="dt-search-header"><FormattedMessage id="tipoFormularioGrid.render.table_column_atributos" defaultMessage="Atributos"/></th>
													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
	    	</React.Fragment>
	    );
	}

	getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}

}

export default injectIntl(TipoFormularioGrid);
