import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import 'moment/min/locales'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
//import LogoCompany from '../../assets/images/logo-company-login-tc-print.png'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import swal2 from 'sweetalert2'
import swal from 'sweetalert'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'
import PreventivosImportar from './PreventivosImportar';
import PreventivosImportarReporte from './PreventivosImportarReporte';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class PreventivosGrid extends Component {
	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.columnsToPrint = [1,2,3,4,5];
		moment.locale('es');

		this.state = {
			redirectTo: null,
			disableGenerar: false,
			reporteImportacion: null,
			mensajeImportacion: ""
		}
		this.handleCloseModal = this.handleCloseModal.bind(this);
		this.handleCancelModal = this.handleCancelModal.bind(this);
		this.handleReportCloseModal = this.handleReportCloseModal.bind(this);
	}

	componentDidMount(nextProps, nextState) {
		if(Security.hasPermission('PREVENTIVOS_LISTAR')) {
	      this.ajaxHandler.subscribe(this);
				this.initGrid();
	    } else {
		    	this.setState({
		        redirectTo: '/error'
	      	});
	    }
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleNew(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

	handleGenerarTickets(){
		this.setState({
			loading: true,
			disableGenerar: true
    }, () => {
      let component = this;

      this.ajaxHandler.fetch('/preventivos/generar-desde-controles', {
        method: 'POST',
      }).then(response => {
        if(response.status === 400) {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        } else {
					component.table.ajax.reload(null, false);
					swal2(component.props.intl.formatMessage({ id: 'preventivosGrid.modal_generate_tickets.information_proceso_finalizado_con_exito', defaultMessage: 'PROCESO FINALIZADO CON ÉXITO!' }), "", "success");
				}
        window.scrollTo(0,0);
      }).catch((error) => {
        component.ajaxHandler.handleError(error);
      }).finally(() => {
        component.setState({
					loading: false,
					disableGenerar: false
				});
      });
    });
	}

	handleCancelModal(message, subtitle, error) {
	$("#import_modal").modal('toggle');
	}

	handleCloseModal(message, faltantes, error) {
		$("#import_modal").modal('toggle');
		if (message !== "" && error) {
			if (error.codigo === 4010) {
				swal(this.props.intl.formatMessage({ id: 'preventivosGrid.alert.sesion_expiro', defaultMessage: 'La sesión expiró' }), this.props.intl.formatMessage({ id: 'preventivosGrid.alert.ingrese_nuevamente', defaultMessage: 'Por favor, ingrese nuevamente.' }), "error").then(()=>{
					window.location.reload();
				});
			} else if (error.codigo) {
				swal(this.props.intl.formatMessage({ id: 'preventivosGrid.alert.error', defaultMessage: 'Error' }), message, "error");
			} else {
				swal(message, error, "error");
			}
		} else {
			if(faltantes['filas_fallaron'].length === 0){
				
				swal(message, "", "success").then((data) => {
					this.table.ajax.reload();
				});
			} else{
				this.setState({reporteImportacion: faltantes, mensajeImportacion:message}, ()=>{
					$('#import_report_modal').modal({ backdrop: 'static' });
				});
			}
		}
	}

	handleReportCloseModal(){
		$("#import_report_modal").modal('toggle');
		this.table.ajax.reload();
	}

	render() {
		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons">
											<div className="col-6">
												{Security.renderIfHasPermission('PREVENTIVOS_CREAR', (
												<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNew.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'preventivosGrid.render.button_add.title_nuevo', defaultMessage: 'Nuevo' })}>
													<i className="ft-plus"></i>
												</div>
												))}
												{Security.renderIfHasPermission('PREVENTIVOS_GENERAR', (
												<div style={{display: (this.state.disableGenerar || !Security.isInternal()) ? 'none' : ''}} className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleGenerarTickets.bind(this)} data-toggle="tooltip" data-placement="right" title={this.props.intl.formatMessage({ id: 'preventivosGrid.render.button_generate_ticket.title_generar_tickets', defaultMessage: 'Generar Tickets' })}>
													<i className="ft-play"></i>
												</div>
												))}
											</div>
											<div className="col-6" id="buttons"></div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>
													<th className="p-0"></th>
                          <th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="p-0"></th>
                          <th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className=""></th>
													<th className=""></th>
													<th className="dt-search-header"></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade col-8" tabIndex="-1" id="import_modal" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
				<div className="modal-xl modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="myModalLabel1">
								<FormattedMessage id="preventivosGrid.tools.import.button.importar" defaultMessage=" Importar"/>
							</h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-mh modal-grey">
							<form className="form">
								{<PreventivosImportar callbackClose={this.handleCloseModal} callbackCancel={this.handleCancelModal}/>}
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className="modal fade col-12" tabIndex="-1" id="import_report_modal" role="dialog" aria-labelledby="importReportLabel" style={{ margin: "auto" }}>
				<div className="modal-lg modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header bg-fleet">
							<h4 className="modal-title text-white" id="importReportLabel">Reporte de la Importación</h4>
							<button type="button" className="close" onClick={this.handleReportCloseModal} aria-label="Close"><span aria-hidden="true">&times;</span></button>
						</div>
						<div className="modal-body modal-grey modal-mh ovf-x-hidden">
							{this.state.reporteImportacion ?
							<PreventivosImportarReporte data={this.state.reporteImportacion} callbackReportClose={this.handleReportCloseModal}></PreventivosImportarReporte	>
							: null}
						</div>
						<div className="modal-footer modal-grey">
							<button type="button" className="btn btn-default btn-fleet" onClick={this.handleReportCloseModal}>Ok</button>
						</div>
					</div>
				</div>
			</div>
			</React.Fragment>
		);
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		Promise.all([
			this.getData('/servicios/tipo-ticket/PREVENTIVO/select'),
			this.getData('/bases/select-filtered-by-user/movil'),
		]).then((data) => {
			let filters = {
				servicio: data[0] ? data[0] : [],
				base: data[1] ? data[1] : [],
				unidadMedidor: Object.keys(UNIDAD_MEDIDOR).map((unidadMedidorId, i) => ({value: UNIDAD_MEDIDOR[unidadMedidorId]['id'], label: UNIDAD_MEDIDOR[unidadMedidorId]['label'] }))
			};

			this.table = $(this.refs.grid).DataTable(
				Object.assign({
					dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
					stateSave: true,
					stateSaveCallback: function(settings, data) {
						localStorage.setItem('DataTables_PreventivosGrid', JSON.stringify(data));
					},
					stateLoadCallback: function(settings) {
						return JSON.parse(localStorage.getItem('DataTables_PreventivosGrid'));
					},
					processing: true,
					serverSide: true,
					ajax: {
						type: 'GET',
						url: Config.get('apiUrlBase') + '/preventivos/grid',
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						},
						dataSrc: function(response) {
							return response.data;
						},
						error: function(xhr, error, thrown) {
							component.ajaxHandler.handleResponseErrorsIsValid(xhr);
						},
						cache: false
					},
					initComplete: function(settings) {
						let stateColumns = JSON.parse(localStorage.getItem('DataTables_PreventivosGrid')).columns;

						var sets = settings;
						var index = 0;

						this.api().columns().every(function() {
							var column = this;

							if (sets.aoColumns[index].filterType) {
								if (sets.aoColumns[index].filterType === 'select') {
									var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'preventivosGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
										.appendTo($(column.footer()).empty())
										.on('change', function() {
											var val = $.fn.dataTable.util.escapeRegex($(this).val());
											column
												.search(val ? val : '', true, false)
												.draw();
										});
									if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
										filters[sets.aoColumns[index].name].map(e => {
											select.append('<option value="' + e.value + '">' + e.label + '</option>');
											return true;
										});
									} else {
										column.data().unique().sort().each(function(d, j) {
											select.append('<option value="' + d + '">' + d + '</option>');
										});
									}
									if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
								}
								if (sets.aoColumns[index].filterType === 'input') {
									var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'preventivosGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
									if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
									input.appendTo($(column.footer()).empty());
									input.on('keyup change', function() {
										if (column.search() !== this.value) {
											column
												.search(this.value)
												.draw()
												.ajax.reload(null, false);
										}
									});
								}
							}
							index++;
							return '';
						});

						$('tfoot tr').appendTo('thead');
					},
					columns: [
						{
							title: '',
							className: 'd-none',
							filterType: 'none',
							name: 'id',
							data: 'id'
						},
						{
						name: 'base',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_base.label_base', defaultMessage: 'Base' }),
						className: 'all',
						data: 'base',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.descripcion ? data.descripcion : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'movil',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_movil.label_movil', defaultMessage: 'Móvil' }),
						className: 'all',
						data: 'movil',
						render: function(data, type, row) {
							return data && data.dominio ? data.dominio : '';
						},
						filterType: 'input'
					}, {
						name: 'activo',
						title: '',
						data: 'activo',
						width: 3,
						padding: 0,
						orderable: false,
						className: 'p-0 all',
						render: function(data, type, full, meta) {
							return '';
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$('div.tooltip[role="tooltip"]').tooltip('dispose');
							$('div.tooltip[role="tooltip"]').remove();

							$(td).attr('data-togle', 'tooltip');
							$(td).attr('data-placement', 'top');
              $(td).tooltip();
              switch (cellData) {
                case true:
                  $(td).addClass('status-green');
                  $(td).attr('title', component.props.intl.formatMessage({ id: 'preventivosGrid.column_state.title_activo', defaultMessage: 'ACTIVO' }));
                  break;
                case false:
                  $(td).addClass('status-red');
                  $(td).attr('title', component.props.intl.formatMessage({ id: 'preventivosGrid.column_state.title_inactivo', defaultMessage: 'INACTIVO' }));
                  break;
                default:
                  $(td).addClass('status-grey');
                  $(td).attr('title', '');
                  break;
							}
						},
						filterType: 'none'
					}, {
						name: 'servicio',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_service.label_servicio', defaultMessage: 'Servicio' }),
						className: 'all',
						data: 'servicio',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data && data.id ? data.id : '';
							} else {
								return data && data.nombre ? data.nombre : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'realizarALos',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_realize.label_realizar_en', defaultMessage: 'Realizar en' }),
						className: 'all',
						data: 'realizarALos',
						render: function(data, type, row) {
							return data && data ? Math.floor(data) : '';
						},
						filterType: 'input'
					}, {
						name: 'preventivoCadaKms',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_repeat.label_base', defaultMessage: 'Repetir' }),
						className: 'all',
						data: 'servicio',
						render: function(data, type, row) {
							return data && data.preventivoCadaKms ? Math.floor(data.preventivoCadaKms) : '';
						},
						filterType: 'input'
					}, {
						name: 'preventivoAlertarKmsPrevios',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_alert.label_base', defaultMessage: 'Alertar' }),
						className: 'all',
						data: 'servicio',
						render: function(data, type, row) {
							return data && data.preventivoAlertarKmsPrevios ? Math.floor(data.preventivoAlertarKmsPrevios) : '';
						},
						filterType: 'input'
					}, {
						name: 'kmActual',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_km_actual.label_medidor_actual', defaultMessage: 'Medidor Actual' }),
						className: 'all',
						data: 'movil',
						render: function(data, type, row) {
							return  Math.floor(data.kmActual);
						},
						filterType: 'input'
					},{
						name: 'unidadMedidor',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_km_actual.label_medidor_tipo', defaultMessage: 'Tipo Medidor' }),
						className: 'all',
						orderable: false,
						data: 'movil',
						render: function(data, type, row) {
							if (type === 'filter') {
								return data &&  data.unidadMedidorId ? data.unidadMedidorId : '';
							} else {
								return data && UNIDAD_MEDIDOR ? UNIDAD_MEDIDOR[data.unidadMedidorId]['unidades'] : '';
							}
						},
						filterType: 'select'
					}, {
						name: 'realizarEl',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_realize.label_realizar_el', defaultMessage: 'Realizar el' }),
						className: 'all',
						orderable: false,
						data: 'realizarEl',
						render: function(data, type, row) {
							return data ? moment(data).format('L') : '';
						},
					}, {
						name: 'ticket',
						title: component.props.intl.formatMessage({ id: 'preventivosGrid.column_ticket.label_ticket', defaultMessage: 'Ticket' }),
						className: 'all',
						data: 'ticket',
						render: function(data, type, row) {
							return data ? Math.floor(data) : '';
						},
						filterType: 'input'
					}, {
						orderable: false,
						data: null,
						className: "text-center all",
						width: '120px',
						render: function(data, type, full, meta) {
							let html =
								`<span>` +
								(Security.hasPermission('PREVENTIVOS_FORZAR') ? `
								<button class="action forzar btn btn-sm btn-icon btn-dt-grid text-success" ` + (full.displayForzado ? `` : ` style="visibility: hidden;"` ) +  ` data-title="`+component.props.intl.formatMessage({ id: 'perfilesGrid.column_actions.button_title_forzar', defaultMessage: 'Forzar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-crosshairs fa-2x"></i>
								</button>` : '') +
								`</span>
								 <span>` +
								(Security.hasPermission('PREVENTIVOS_VISUALIZAR') ? `
								<button class="action view btn btn-sm btn-icon btn-dt-grid text-success" data-title="`+component.props.intl.formatMessage({ id: 'preventivosGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-search fa-xs"></i>
								</button>` : '') +
								`</span>
								 <span>` +
								((Security.hasPermission('PREVENTIVOS_MODIFICAR') && data.ticket == 0) ? `
								<button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" data-title="`+component.props.intl.formatMessage({ id: 'preventivosGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`" data-togle="tooltip" data-placement="top">
									<i class="fa fa-pencil fa-xs"></i>
								</button>` : '') +
								`</span>
								 <span>` +
								((Security.hasPermission('PREVENTIVOS_ELIMINAR') && data.ticket == 0) ? `
								<button class="action delete btn btn-sm btn-icon btn-dt-grid text-danger" data-title="`+component.props.intl.formatMessage({ id: 'preventivosGrid.column_actions.button_title_eliminar', defaultMessage: 'Eliminar' })+`" data-togle="tooltip" data-placement="top">
									<i class="ft ft-trash"></i>
								</button>` : '') +
								`</span>`;
							return html;
						},
						createdCell: function(td, cellData, rowData, row, col) {
							$(td).find('button').tooltip();
						},
						filterType: 'none'

					}],
					drawCallback: function() {
						$(this).find('.action').on('click', function() {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if ($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if ($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							if ($(this).hasClass('forzar')) {
								swal2({
									input: 'textarea',
									inputPlaceholder: component.props.intl.formatMessage({ id: 'preventivosGrid.modal_force.confirm_detalle_acerca_de_la_razon_del_forzado', defaultMessage: 'Por favor, ingrese un detalle acerca de la razón del forzado' }),
									showCancelButton: true,
									cancelButtonText: component.props.intl.formatMessage({ id: 'preventivosGrid.modal_force.confirm_detalle_acerca_de_la_razon_del_forzado.cancelar', defaultMessage: 'Cancelar' }),
									confirmButtonText: component.props.intl.formatMessage({ id: 'preventivosGrid.modal_force.confirm_detalle_acerca_de_la_razon_del_forzado.ok', defaultMessage: 'Ok' }),
									inputValidator: function(value) {
										return !value && 'Campo requerido';
									}
								}).then((result) =>{
									if(result.value) {
										component.ajaxHandler.fetch('/preventivos/forzar/' + data.id , {
											method: 'POST',
											body: JSON.stringify({
												detalle: result.value
											}),
										}).then(response => {
											if(response.status === 204) {
												component.table.ajax.reload(null, false);
												swal2(component.props.intl.formatMessage({ id: 'preventivosGrid.modal_force.information_forzado_exitoso', defaultMessage: 'Forzado exitoso!' }), "", "success");
											} else {
												response.json()
												.then(data => {
													component.setState({
														errors: data.detalle
													});
												});
											}
											window.scrollTo(0,0);
										}).catch((error) => {
											component.ajaxHandler.handleError(error);
										});
									}
								});
							}
							component.setState({
								redirectTo: redirectTo
							});

							if ($(this).hasClass('delete')) {
								swal({
									title: component.props.intl.formatMessage({ id: 'preventivosGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
									text: null,
									icon: "warning",
									buttons: {
										confirm: {
											text: component.props.intl.formatMessage({ id: 'preventivosGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
											value: true,
											visible: true,
											className: "btn btn-primary",
											closeModal: false
										},
										cancel: {
											text: component.props.intl.formatMessage({ id: 'preventivosGrid.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
											value: null,
											visible: true,
											className: "btn btn-danger",
											closeModal: true,
										}
									}
									})
									.then((isConfirm) => {
										if (isConfirm) {
											fetch(Config.get('apiUrlBase') + '/preventivos/' + data.id, {
													method: 'DELETE',
													headers: {
														'Accept': 'application/json',
														'Content-Type': 'application/json',
														'Authorization-Token': localStorage.getItem("token")
													}
												})
												.then(function(response) {
													component.table.ajax.reload(null, false);
													swal(component.props.intl.formatMessage({ id: 'preventivosGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
												})
												.catch(function(error) {
													swal(component.props.intl.formatMessage({ id: 'preventivosGrid.modal_delete.information_error', defaultMessage: 'Error' }), "", "error");
												});
										}
									});
							}
						});
					}
				}, datatablesConfig(this.props.intl))
			);

			new $.fn.dataTable.Buttons(this.table, {
				buttons: [{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="'+LimpiarFiltros+'"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'preventivosGrid.tools_restore_filters.title_restaurar_filtros_y_ordenamiento', defaultMessage: 'Restaurar filtros y ordenamiento' }),
					action: function (e, dt, node, config) {
						var index = 0;
						component.table.columns().every(function() {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});
						dt.table().order([ 0, 'asc' ]);
						dt.ajax.reload();
					}
				}, {
					extend: 'print',
					name: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 40px" src="` + LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="` + LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>`+component.props.intl.formatMessage({ id: 'preventivosGrid.print_content.header_listado_de_vencimientos', defaultMessage: 'Listado de Vencimientos' })+`</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},
					exportOptions: {
						columns: component.columnsToPrint
					},
					titleAttr: component.props.intl.formatMessage({ id: 'preventivosGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'preventivosGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.title_exportar_a_excel', defaultMessage: 'Exportar a Excel' }),
					action: function(e, dt, node, config){
						swal2({
							title: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.modal_swal.se_estan_exportando_los_datos', defaultMessage: 'Se están exportando los datos.' }),
							text: component.props.intl.formatMessage({ id: 'perfilesGrid.tools_export.modal_swal.esta_accion_puede_demorar', defaultMessage: 'Esta acción puede demorar' }),
							buttons: {}
						});

						component.ajaxHandler.fetch('/preventivos/exportar-excel',{
							method: 'GET'
						}).then(response => {
							if(response.status === 200){
								return response.blob();
							}
						}).then(fileBlob => {
							let fileUrl = URL.createObjectURL(fileBlob);
							$("<a />", {
								href: fileUrl,
								download: "fleet-preventivos.xlsx"
							}).appendTo("body").get(0).click();
						}).catch(() => {
						}).finally(() => {
							swal2.close();
						});
					}
				},{
				text: '<i class="ft-download"></i>',
				titleAttr: 'Importar',
				className: Security.hasPermission('PREVENTIVOS_IMPORTAR') ? 'all': 'd-none',
				action: function (e, dt, node, config) {
					$('#import_modal').modal({ backdrop: 'static' });
				}
				}]
			});

			this.table.buttons(0, null).container().appendTo('#buttons');

			this.table.button('filterReset:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('print:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('columns:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			this.table.button('export:name')
				.nodes()
				.attr('data-toggle', 'tooltip')
				.attr('data-position', 'top');
			$('[data-toggle="tooltip"]').tooltip();

			$('.buttons-collection').on("click",function(e){ e.stopPropagation();
				if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
					$('.dt-button-collection>.dropdown-menu').css('display','block');
			});
		}).catch(function(error) {
			component.ajaxHandler.handleError(error);
		}).finally(() => {
			component.setState({
				loading: false
			});
		});
	}

	getData(service) {
		let component = this;
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return component.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}
}

export default injectIntl(PreventivosGrid);
