import React, { useState, useEffect} from "react";
import { Redirect } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
  TableRow,
  TableCell,
  Collapse,
  Input,
} from '@material-ui/core'
import { StyledTableCellProps as StyledTableCell, StyledTooltip } from '../../commons/utils/TableStyles'
import swal from 'sweetalert'
import moment from 'moment'
import 'moment/min/locales'
import PreventivoVerde from '../../assets/images/tareas/preventivo_verde.svg'
import PreventivoRojo from '../../assets/images/tareas/preventivo_rojo.svg'
import PreventivoAmarillo from '../../assets/images/tareas/preventivo_amarillo.svg'
import PreventivoTicketRojo from '../../assets/images/tareas/preventivostickets-06-rojo.svg'
import VencimientoVerde from '../../assets/images/tareas/vencimiento_verde.svg'
import VencimientoRojo from '../../assets/images/tareas/vencimiento_rojo.svg'
import VencimientoAmarillo from '../../assets/images/tareas/vencimiento_amarillo.svg'
import VencimientoTicketRojo from '../../assets/images/tareas/vencimientostickets-07-rojo.svg'
import CalendarioImg from '../../assets/images/tareas/calendario.svg'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'
import MovilesDetail from "./MovilesDetail";
import Timezone from '../../commons/timezone/Timezone.js';


const MovilesGridRow = (props) => {
  const movil = props.movil;
  const columnsEnabled = props.columnsEnabled;
  const permissions = props.permissions;
  const baseUrl = props.baseUrl;
  const intl = useIntl();
  const proxPreventivo = movil.proximasTareasPreventivo;
  const proxVencimiento = movil.proximasTareasVencimiento;

  const maxDifferenceKM = ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? ConfigBusiness.get('moviles.editaKm.maximo') : 0;
  const [inputKm, setInputKm] = useState('');
  const [controles, setControles] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null)
  const [open, setOpen] = useState(false);
  const [isHidden, setIsHidden] = useState("hidden-personal");
  const [estadosActivos, setEstadosActivos] = useState([]);
  const [estadosInactivos, setEstadosInactivos] = useState([]);
  const [estadosResaltados, setEstadosResaltados] = useState([]);


  useEffect(()=>{
    getControles(movil.id)
    getEstados()
  },[]);

  const getEstado = (estado) => {
    if (estadosActivos.includes(estado)) {
      return "status-green";
    } else if  (estadosResaltados.includes(estado)){
      return "status-orange";
    } else if  (estadosInactivos.includes(estado)){
      return "status-red";
    } else {
      return "status-grey";
    }
  };

  const getControles = (id) => {
    Utils.getData('/moviles/' + id + '/detail/controles/null/null')
    .then((data) => {
      if (data.combustibles === 2 ||
        data.documentacionChofer === 2 ||
        data.documentacionMovil === 2 ||
        data.dtc === "rojo" ||
        data.enTaller === 3 ||
        data.enTaller === 1 ||
        data.gps === "rojo" ||
        data.infracciones === 2)
      {
        setControles('danger');
      } else {
        setControles('success');
      }
    });
  };

  const getEstados = () => {
    Promise.all([
			ConfigBusiness.get('moviles.estadosActivos'),
			ConfigBusiness.get('moviles.estadosInactivos'),
			ConfigBusiness.get('moviles.estadosResaltados')
    ]).then((data) => {
      setEstadosActivos(data[0] ? data[0].split(',') : null)
      setEstadosInactivos(data[1] ? data[1].split(',') : null)
      setEstadosResaltados(data[2] ? data[2].split(',') : null)
    })
  };

  const HandleUpdateKm = (e) => {
    const km = e.target.value;
    if (km) { updateKm(km); }
  };

  const handleOnEnter = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      HandleUpdateKm(e);
    }
  };

  const toggleCollapse = () => {
    setOpen(!open);
    if (isHidden === "hidden-personal") {
      setIsHidden("block");
    } else {
      setIsHidden("hidden-personal");
    }
  };

  const updateKm = (km) => {
    const service = '/moviles/'+movil.id+'/km';
    const data = {'km' : km};
    const title = "Diferencia de "+ UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades']+
    " mayor a " + maxDifferenceKM +" "+ UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades']+
    " Desea forzar este cambio?";

    if(Math.abs(km - movil.km_actual) > maxDifferenceKM) {
      swal({
        title: title,
        text: "",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          confirm: {
            text: 'Aceptar',
            value: true,
            visible: true,
            className: "btn btn-success",
            closeModal: false
          },
          cancel: {
            text: 'Cancelar',
            value: null,
            visible: true,
            className: "btn btn-danger",
            closeModal: false,
          }
        }
      }).then(isConfirm => {
        if (isConfirm) {
          Utils.sendData(service, 'PUT', data).then(() => {
            movil.km_actual = km;
            setInputKm('');
            swal('Se actualizó con Éxito!', " ", "success");
          });
        } else {
          swal('Actualización Cancelada!', "", "error");
          setInputKm('');
        }
      });
    } else {
      Utils.sendData(service, 'PUT', data).then(() => {
        movil.km_actual = km;
        setInputKm('');
        swal('Se actualizó con Éxito!', " ", "success");
      });
    }
  }

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <TableRow>
          <StyledTableCell align="left" className="d-none">{movil.id}</StyledTableCell>
          <StyledTableCell align="left" className="all avatar-plus-detail">
            <div className="d-flex">
              <div onClick={()=>toggleCollapse()}>
                {open
                  ? ( <i className="fa fa-angle-double-down moviles-show-detail fa-2x align-self-center"></i>)
                  : ( <i className="fa fa-angle-double-right moviles-show-detail fa-2x align-self-center"></i>)
                }

              </div>
              <Logo
              codigo={movil.modelo_eloquent.marca_eloquent.codigo}
              nombre={movil.modelo_eloquent.marca_eloquent.nombre}
              marca={movil.modelo_eloquent.marca}
              />
            </div>
          </StyledTableCell>
          <StyledTooltip title={movil.estado} arrow placement="right">
            <td
              align="left"
              className={getEstado(movil.estado) + " p-0 all custom-lineHeight br"}
              style={{ maxWidth: "3px"}}
            />
          </StyledTooltip>
          <StyledTableCell show={columnsEnabled.unidadEnabled} align="left">
            {movil.unidad}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.chasisEnabled} align="left">
            {movil.chasis_nro}
          </StyledTableCell>
          <StyledTableCell align="left">
            <div className="d-flex">
            <div>
              {movil.dominio}
            </div>
            <i id="dominio" className={"fas fa-circle datatables-status-icon-fa-fas-circle "+controles}></i>
            </div>
          </StyledTableCell>
          <StyledTableCell align="left">
            {movil.modelo_eloquent && movil.modelo_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell align="left">
            {proxPreventivo ? <ProximosPreventivos data={proxPreventivo} icono={UNIDAD_MEDIDOR[movil.unidadMedidorId]['icono_preventivos']} baseUrl={baseUrl}/> : ''}
            {proxVencimiento ? <ProximosVencimientos data={proxVencimiento}  baseUrl={baseUrl}/> : ''}
          </StyledTableCell>
          <StyledTableCell align="left">
            {movil.modelo_eloquent.modelo_tipo.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.paisEnabled} align="left">
            {movil.base_eloquent.pais && movil.base_eloquent.pais.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.regionEnabled} align="left">
            {movil.base_eloquent.region_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.subRegionEnabled} align="left">
            {movil.base_eloquent.sub_region_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.baseEnabled} align="left">
            {movil.base_eloquent.descripcion}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.titularEnabled} align="left">
            {movil.titular_eloquent && movil.titular_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.companiaOrigenEnabled} align="left">
            {movil.compania_origen}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.centroCostosEnabled} align="left">
            {movil.cecos && movil.cecos.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosDireccionEnabled} align="left">
            {(movil.cecos && movil.cecos.direccion_eloquent) && movil.cecos.direccion_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosMercadoEnabled} align="left">
          {(movil.cecos && movil.cecos.mercado_eloquent) && movil.cecos.mercado_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosGerenciaEnabled} align="left">
          {(movil.cecos && movil.cecos.gerencia_eloquent) && movil.cecos.gerencia_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.cecosAreaEnabled} align="left">
          {(movil.cecos && movil.cecos.area_eloquent) && movil.cecos.area_eloquent.nombre}
          </StyledTableCell>
          <StyledTableCell show={columnsEnabled.responsable1Enabled} align="left">
            {movil.responsable1NombreApellido}
          </StyledTableCell>
          <StyledTableCell align="left">
            {
              movil.usuario_km_modificacion ? (
                <div className="text-center success dt-info-icon">
                  <StyledTooltip
                    title={ <div> Ult. actualización Medidor: <br/>
                      {Timezone.getDateForClient(movil.fecha_hora_km_modificacion, null, 'DD/MM/YYYY HH:mm')} <br/>
                      Usuario:
                      {(movil.usuario_km_modificacion.label ? movil.usuario_km_modificacion.label  : "Sistema")}
                    </div>}
                    arrow
                    placement="top"
                  >
                    <i className="la la-clock-o"></i>
                  </StyledTooltip>
                </div>
              ) : ''
            }
          </StyledTableCell>
          <StyledTableCell align="left">
            {Math.floor(movil.km_actual).toLocaleString('de-DE') + ' ' + UNIDAD_MEDIDOR[movil.unidad_medidor_id]['unidades']}
          </StyledTableCell>
          <StyledTableCell show={(columnsEnabled.editKmEnabled && permissions.canModifyKM) ? true : false}>
            <Input
              align="left"
              className="dt-input-grid input-km dt-input-holder input-no-arrows"
              disableUnderline={true}
              type="number"
              style={{border:"none"}}
              id={"dt_input_"+movil.id}
              data-id={movil.id}
              name={"dt_input_"+movil.id}
              size="10"
              value={inputKm}
              onChange={e => setInputKm(e.target.value)}
              onBlur={HandleUpdateKm}
              onKeyDown={(e) => handleOnEnter(e)}
              />
            </StyledTableCell>
          <StyledTableCell>
            <div className="d-inline-flex">
              {permissions.movilesVisualizar &&
                <button
                  className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Ver"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={() => setRedirectTo(baseUrl + '/' + movil.id)}
                >
                  <i className="fa fa-search fa-xs"/>
                </button>
              }
              {permissions.movilesModificar &&
                <button
                  className="action edit btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Trabajar con el móvil"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo(baseUrl +'/'+ movil.id +'/edit')}
                  >
                    <i className="fa fa-pencil fa-xs"/>
                </button>
              }
              {(permissions.ticketsListar || permissions.vencimientosListar || permissions.preventivosListar) &&
                <button
                  className="action mantYVenc btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Ver Mantenimientos, Vencimientos y Tickets"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo(baseUrl +'/'+ movil.id +'/tickets')}
                  >
                  <i className="ft ft-clipboard"/>
                </button>
              }
              {permissions.correctivosCrear &&
                <button
                  className="action correctivo btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Crear Nuevo Mantenimiento Correctivo"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo('/correctivos/add/movil/'+ movil.id +'/'+ movil.dominio)}
                  >
                    <i className="fa fa-wrench fa-xs"/>
                </button>
              }
              {permissions.vencimientosCrear &&
                <button
                  className="action vencimiento btn btn-sm btn-icon btn-dt-grid text-success"
                  title="Crear Nuevo Vencimiento"
                  data-togle="tooltip"
                  data-placement="top"
                  onClick={()=> setRedirectTo('/vencimientos/add')}
                  >
                    <i className="fa fa-calendar-plus-o fa-xs"/>
                </button>
              }
              </div>
            </StyledTableCell>
        </TableRow>
        <TableRow className={isHidden}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: "rgb(244, 245, 250)"}} colSpan={19}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <MovilesDetail
                intl={intl}
                movil={movil.id}
                style={{maxWidth: "85vw"}}
                />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
  )
}

function Logo(props) {
  const [error, setError] = useState(false);
  return (
    <>
      {!error ? (
        <StyledTooltip title={props.nombre} arrow placement="top">
          <img
            className={"dt-user-avatar-image dt-user-avatar-more avatar-"+props.marca}
            src={
              process.env.PUBLIC_URL + "/images/marcas/" + props.codigo + '.png'
            }
            onError={() => setError(true)}
          />
        </StyledTooltip>
      ) : (
        <StyledTooltip title={props.nombre} arrow placement="top">
          <div className={"dt-user-avatar dt-user-avatar-more avatar-"+props.marca}>
            {props.nombre.substr(0, 2).toUpperCase()}
          </div>
        </StyledTooltip>
      )}
    </>
  );
}

function ProximosPreventivos(props) {
  const data = props.data

  function Estados (){
    let estado
    switch (true) {
      case (data.ticket != null):
        estado = <a href={'/tickets/'+data.ticket+'/edit'}>
                    <StyledTooltip title={data.servicio} arrow placement="top">
                      <img className="proximasTareas" src={PreventivoTicketRojo} />
                    </StyledTooltip>
                  </a>
        break;
      case (data.estado == 'rojo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={PreventivoRojo} />
                </StyledTooltip>
        break;
      case (data.estado == 'amarillo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={PreventivoAmarillo} />
                </StyledTooltip>
        break;
      case (data.estado == 'verde'):
          estado = <StyledTooltip title={data.servicio} arrow placement="top">
                    <img className="proximasTareas" src={PreventivoVerde} />
                  </StyledTooltip>
          break;
    }
    return estado;
  }

  function Calendario(){
    if(data.calendario != null){
      return(
        <StyledTooltip title={data.calendario} arrow placement="top">
          <img className="proximasTareas" src={CalendarioImg}/>
        </StyledTooltip>)
    }else return false
  }

  function Ruta(){
    if(data.ruta){
      return(
        <StyledTooltip title={data.ruta} arrow placement="top">
          <img className="proximasTareas" src={props.icono}/>
        </StyledTooltip>)
    }
    else return false
  }
  return (
    <div className='row'>
      <Estados/>
      <Calendario/>
      <Ruta/>
    </div>
  );
}

function ProximosVencimientos(props) {
  let data = props.data;
  function Estados (){
    let estado
    switch (true) {
      case (data.ticket != null):
        estado = <a href={'/tickets/'+data.ticket+'/edit'}>
                    <StyledTooltip title={data.servicio} arrow placement="top">
                      <img className="proximasTareas" src={VencimientoTicketRojo} />
                    </StyledTooltip>
                  </a>
        break;
      case (data.estado == 'rojo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={VencimientoRojo} />
                </StyledTooltip>
        break;
      case (data.estado == 'amarillo'):
        estado = <StyledTooltip title={data.servicio} arrow placement="top">
                  <img className="proximasTareas" src={VencimientoAmarillo} />
                </StyledTooltip>
        break;
      case (data.estado == 'verde'):
          estado = <StyledTooltip title={data.servicio} arrow placement="top">
                    <img className="proximasTareas" src={VencimientoVerde} />
                  </StyledTooltip>
          break;
    }
    return estado;
  }
  return (
    <>
       <div className="row">
         <Estados/>
         <StyledTooltip title={data.calendario} arrow placement="top">
          <img className="proximasTareas" src={CalendarioImg}/>
        </StyledTooltip>
       </div>
    </>
  );
}

export default MovilesGridRow
