import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import 'react-select/dist/react-select.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import GravatarOptionMoviles from './GravatarOptionMoviles.js'
import ControlRojoImage from "../../assets/images/moviles/control-rojo.svg"
import ControlVerdeImage from "../../assets/images/moviles/control-verde.svg"
import ControlGrisImage from "../../assets/images/moviles/control-gris.svg"
import SurtidorRojoImage from "../../assets/images/moviles/surtidor-rojo.svg"
import SurtidorVerdeImage from "../../assets/images/moviles/surtidor-verde.svg"
import SurtidorGrisImage from "../../assets/images/moviles/surtidor-gris.svg"
import DocChoferRojoImage from "../../assets/images/moviles/detalle-rojo.svg"
import DocChoferVerdeImage from "../../assets/images/moviles/detalle-verde.svg"
import DocChoferGrisImage from "../../assets/images/moviles/detalle-gris.svg"
import EnTallerRojoImage from "../../assets/images/moviles/herramienta-rojo.svg"
import EnTallerVerdeImage from "../../assets/images/moviles/herramienta-verde.svg"
import EnTallerNaranjaImage from "../../assets/images/moviles/herramienta-naranja.svg"
import EnTallerGrisImage from "../../assets/images/moviles/herramienta-gris.svg"
import DtcRojoImage from "../../assets/images/moviles/manguera-rojo.svg"
import DtcVerdeImage from "../../assets/images/moviles/manguera-verde.svg"
import DtcGrisImage from "../../assets/images/moviles/manguera-gris.svg"
import GpsRojoImage from "../../assets/images/moviles/gps-rojo.svg"
import GpsVerdeImage from "../../assets/images/moviles/gps-verde.svg"
import GpsGrisImage from "../../assets/images/moviles/gps-gris.svg"
import DocMovilRojoImage from "../../assets/images/moviles/cargas-rojo.svg"
import DocMovilVerdeImage from "../../assets/images/moviles/cargas-verde.svg"
import DocMovilGrisImage from "../../assets/images/moviles/cargas-gris.svg"
import {FormattedMessage, injectIntl} from 'react-intl';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR, ID__UM_ODOMETRO, ID__UM_HOROMETRO } from '../unidadMedidor/constants.js'


class MovilesDetail extends Component {
	constructor(props) {
		super(props);

        this.ajaxHandler = new AjaxHandler();

        moment.locale('es');

            this.state = {
                redirectTo: null,
                props: this.props,
                formData: {
                },
                noHayDatos: false,
                periodos: [
                    {id:1, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_hoy', defaultMessage: 'Hoy' })},
                    {id:2, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_ayer', defaultMessage: 'Ayer' })},
                    {id:7, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_ultimos_siete_dias', defaultMessage: 'Últimos 7 días' })},
                    {id:14, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_ultimos_catorce_dias', defaultMessage: 'Últimos 14 días' })},
                    {id:20, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_ultimos_treinta_dias', defaultMessage: 'Últimos 30 días' })},
                    {id:30, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_este_mes', defaultMessage: 'Este mes' })},
                    {id:60, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_mes_pasado', defaultMessage: 'Mes Pasado' })},
                    {id:365, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_este_anio', defaultMessage: 'Este año' })},
                    {id:400, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_anio_pasado', defaultMessage: 'Año Pasado' })},
                    {id:0, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_todo', defaultMessage: 'Todo' })},
                    {id:12, label:""},
                    {id:3, label: this.props.intl.formatMessage({ id: 'movilesDetail.filter_period.select_solo_con_factura', defaultMessage: 'Solo con Factura' }), soloFactura:"false", sinFacturaChange:this.handleSoloFactura.bind(this)}
                ],
                unidad: 'day',
                periodo: null,
                periodoSelect: {id:0, label: this.props.intl.formatMessage({ id: 'movilesDetail.periodoSelect.label_todo', defaultMessage: 'Todo' })},
                filtroPeriodoInicio: null,
                filtroPeriodoFin: null,
                controlesResultado: null,
                costo: 0,
                km_por_dia: 0,
                distancia_total:0,
                controlGps : ControlGrisImage,
                controlDtc : DtcGrisImage,
                controlEnTaller : EnTallerGrisImage,
                controlDocMovil : DocMovilGrisImage,
                controlDocChofer : DocChoferGrisImage,
                controlInfracciones : ControlGrisImage,
                controlCombustibles : SurtidorGrisImage,
                soloFactura: false,
                unidadMedidorId: 1
                
        };
        this.chart = null;
        
    }

    componentDidMount() {

      this.ajaxHandler.subscribe(this);

      this.actualizarDatos();
     
     // $('#moviles-detalle-iconos-card').tooltip();
      
    }

  componentWillUnmount() {
    
    $('.moviles-detalle-iconos-card.tooltip[role="tooltip"]').remove();
    if(this.chart)
      this.chart.dispose();
    this.ajaxHandler.unsubscribe(this);
  }

  handleSelectFormChange(name, object) {

    //this.setState({loading: true});
    // const value = object === null ? null : object.id;
    if(name === "periodo"){
     this.setState({
           periodoSelect: object,	
         }, ()=>{
        
        let inicio = "";
      let fin = "";
    if(object && object.id !== 12){
      
      switch (object.id) {
        case 0:
            inicio = null;
            fin = null;
            break;
        case 1:
           inicio = moment().format('YYYY-MM-DD');
           fin = moment().format('YYYY-MM-DD');
          break;
       case 2:
         inicio = moment().subtract(1, 'days').format('YYYY-MM-DD');
         fin = inicio;
         break;
       case 7:
         inicio = moment().subtract(7, 'days').format('YYYY-MM-DD');
         fin = moment().format('YYYY-MM-DD');
         break;
       case 14:
         inicio = moment().subtract(14, 'days').format('YYYY-MM-DD');
         fin = moment().format('YYYY-MM-DD');
         break;
         case 20:
         inicio = moment().subtract(30, 'days').format('YYYY-MM-DD');
         fin = moment().format('YYYY-MM-DD');
         break;
       case 30:
         inicio = moment().startOf('month').format('YYYY-MM-DD');
         fin = moment().format('YYYY-MM-DD');
         break;
       case 60:
         inicio = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
         fin = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
         break;
       case 400:
         inicio = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
         fin = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
         break;
         default:
         inicio = moment().startOf('year').format('YYYY-MM-DD');
         fin = moment().format('YYYY-MM-DD');
        }
    
    }else if(object.inicio){
        inicio = moment(object.inicio).format('YYYY-MM-DD');
        fin = moment(object.fin).format('YYYY-MM-DD');
    }
        this.setState({filtroPeriodoInicio: inicio, filtroPeriodoFin: fin}, ()=>{
            this.actualizarDatos();
            //this.setState({loading:false});
            
            
        });
        
        });
       // this.props.actualizarPeriodo(object.inicio,object.fin);
    }
   
    }

    actualizarDatos(){
      this.setState({loading:true});

      let movilId = this.props.movil;
      let component = this;
      
      Promise.all([
        component.ajaxHandler.getJson('/moviles/'+movilId+'/detail/fuel/'+component.state.filtroPeriodoInicio+'/'+component.state.filtroPeriodoFin+'/'+this.state.soloFactura),
        component.ajaxHandler.getJson('/moviles/'+movilId+'/detail/km/'+component.state.filtroPeriodoInicio+'/'+component.state.filtroPeriodoFin),
        component.ajaxHandler.getJson('/moviles/'+movilId+'/detail/controles/'+component.state.filtroPeriodoInicio+'/'+component.state.filtroPeriodoFin)
        
     ]).then( (data) => {

      let costo = data[1].distancia_total === 0 || data[1].distancia_total === null || data[0].sumaTotal=== null ? 0 :  parseFloat((data[0].sumaTotal / data[1].distancia_total).toFixed(2));
      let km_por_dia = data[1].promedio_km_por_dia === null ? 0 : parseFloat(data[1].promedio_km_por_dia.toFixed(2));
      let distancia_total = data[1].distancia_total === null ? 0: parseFloat(data[1].distancia_total.toFixed(2));

      console.log('data 0', data[0])
      console.log('data 1', data[1])
      console.log('data 2', data[2])
      this.componentInit(data[0]);

      let rdo = data[2];

      let controlEnTaller;
      if(rdo.enTaller === null){
        controlEnTaller = EnTallerGrisImage;
      } else if(rdo.enTaller === 1 || rdo.enTaller === 3){
        controlEnTaller = EnTallerRojoImage;
      } else if(rdo.enTaller === 0){
        controlEnTaller = EnTallerVerdeImage;
      } else {
        controlEnTaller = EnTallerNaranjaImage;
      }

      let controlDocMovil;
      if(rdo.documentacionMovil === 0 || rdo.documentacionMovil === null){
        controlDocMovil = DocMovilGrisImage;
      } else if(rdo.documentacionMovil === 1){
        controlDocMovil = DocMovilVerdeImage;
      } else{
        controlDocMovil = DocMovilRojoImage;
      }

      let controlDocChofer;
      if(rdo.documentacionChofer === 0 || rdo.documentacionChofer === null){
        controlDocChofer = DocChoferGrisImage;
      } else if(rdo.documentacionChofer === 1){
        controlDocChofer = DocChoferVerdeImage;
      } else{
        controlDocChofer = DocChoferRojoImage;
      }

      let controlInfracciones;
      if(rdo.infracciones === 0 || rdo.infracciones === null){
        controlInfracciones = ControlGrisImage;
      } else if(rdo.infracciones === 1){
        controlInfracciones = ControlVerdeImage;
      } else{
        controlInfracciones = ControlRojoImage;
      }
      
      let controlCombustibles;
      if(rdo.combustibles === 0 || rdo.combustibles === null){
        controlCombustibles = SurtidorGrisImage;
      } else if(data[2].combustiblesInvalidas.total > 0){
        controlCombustibles = SurtidorRojoImage;
      } else{
        controlCombustibles = SurtidorVerdeImage;
      }


      //distritos para el caso de infracciones
      
      let fuentes = ConfigBusiness.get('infracciones.fuentes');
      
      this.setState({
        controlesResultado: data[2], 
        costo: costo, 
        km_por_dia:km_por_dia, 
        distancia_total:distancia_total,
        controlEnTaller: controlEnTaller,
        controlDocMovil: controlDocMovil,
        controlDocChofer: controlDocChofer,
        controlInfracciones: controlInfracciones,
        controlCombustibles: controlCombustibles,
        fuentesInfracciones: fuentes ? fuentes : null,
        noHayDatos: data[0].datos.length == 0 ? true : false,
        unidadMedidorId: data[2].unidadMedidorId 
      });
      
    }).catch(function(error) {
        component.ajaxHandler.handleError(error);
    }).finally(()=>{
          component.setState({loading:false});
    });
    }

  /*componentWillReceiveProps(nextProps){
  
    if(nextProps.datos != this.state.datos){
      if(nextProps.datos === null){
        this.setState({datos: null, noHayDatos: true})
      } else{
        if(this.state.cargaInicial){
          this.setState({cargaInicial: false, datos: nextProps.datos, noHayDatos: false});
          this.componentInit(nextProps.datos);
        }
        else{
          this.chart.data = nextProps.datos;
          this.setState({datos: nextProps.datos, noHayDatos: false});
        }
      }
     
    }
  }*/

  handleSoloFactura(event){

    //en el elemento de periodos que tiene el switch de solo factura, actualizo su valor
    let copiaPeriodos = this.state.periodos.map(x=>{
      if(x.id === 3){
        x.soloFactura = ""+event;
      }
      return x;
    });
    this.setState({periodos: copiaPeriodos, soloFactura: event}, ()=>{this.actualizarDatos(); });
    
    return ;
  }

  handleFilterOLD(url,filter,movil){    
    this.props.onClickDetailControl(url,filter,movil);
  }

  handleFilter(url,filter,movil){
		this.setState({
		  redirectTo: '/'+url,
		  redirect_params:{filterMovilDetail:{filter:filter, movil:movil}}
		});
	}
  componentInit(datos){
   
    let paleta = ['#4dc4bc', '#67b7dc', '#fe9657', '#f7c361', '#dc6967', '#616c6f', '#D7DBDD', '#58D68D', '#3498DB', '#E74C3C' ];
    // Create chart instance
    this.chart = am4core.create("chartdiv"+this.props.movil, am4charts.PieChart);

    // Add data
    this.chart.data = datos.datos;

    // Set inner radius
    this.chart.innerRadius = am4core.percent(50);
    this.chart.numberFormatter.numberFormat = "#.###,##";

    // Add and configure Series
    var pieSeries = this.chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "suma";
    pieSeries.dataFields.category = "gasto";

    pieSeries.colors.list = [];
    // for(let dato in datos.datos){
    //     pieSeries.colors.list.push(am4core.color(paleta[datos.datos[dato]['gasto']]));
    // }
    datos.datos.forEach((dato, i) => {
      pieSeries.colors.list.push(am4core.color(paleta[i]));
    });
    
     //pieSeries.slices.template.stroke = am4core.color("#fff");
    //pieSeries.slices.template.strokeWidth = 2;
    //pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.00')}% (${value.formatNumber('#.')})";
    //ser.columns.template.tooltipText = "";
    //pieSeries.legendSettings.valueText = "[bold {color}]";
   
    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    let label = pieSeries.createChild(am4core.Label);
    label.text = "${values.value.sum.formatNumber('#.')}";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 30;

    //Add a legend
    this.chart.legend = new am4charts.Legend();
    /* chart.legend.markers.template.disabled = true;
     chart.colors.step = 2;
-    chart.legend.labels.template.text = "[bold {color}]{name}";
-    //chart.legend.padding(10, 15, 10, 15);*/
    this.chart.legend.contentAlign = "center";
    this.chart.legend.fontSize = 10;
  }

	render() {

    $('#tallerTooltip'+this.props.movil).tooltip();
    $('#dtcTooltip'+this.props.movil).tooltip();
    $('#infraccionesTooltip'+this.props.movil).tooltip();
    $('#combustiblesTooltip'+this.props.movil).tooltip();
    $('#gpsTooltip'+this.props.movil).tooltip();
    $('#docMovilTooltip'+this.props.movil).tooltip();
    $('#docChoferTooltip'+this.props.movil).tooltip();
    
    let costo = this.state.costo < 0 ? 0 : this.state.costo.toLocaleString('de-DE');
    let km_por_dia = this.state.km_por_dia < 0 ? 0 : this.state.km_por_dia.toLocaleString('de-DE');
    let distancia_total = this.state.distancia_total < 0 ? 0 : this.state.distancia_total.toLocaleString('de-DE');
    let unidadMedidorId = this.state.unidadMedidorId;
        
        return (

			<React.Fragment>
				      {this.state.redirectTo && <Redirect push to={{pathname:this.state.redirectTo,  state: this.state.redirect_params }}  />}
               {this.state.loading && <Loading />}  
                <div className="card col-md-5 moviles-detalle-card-gasto" hidden={!this.state.controlesResultado}>
                    <div className="card-body">
                        <div style={{display:"flex"}}>
                            <div className="from-group col-6">
                                <h5 className="card-title card-title-costo-total">{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.header_costo_total', defaultMessage: 'Costo Total' })}</h5>
                            </div>                            
                            <div className="from-group col-6" style={{display:"flex"}}>
                                <label>{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_periodo', defaultMessage: 'Período' })}: </label>
                                <Select 
                                id="periodo" 
                                name="periodo" 
                                clearable={false}	
                                options={this.state.periodos} 
                                optionComponent={GravatarOptionMoviles} 
                                valueKey="id" 
                                labelKey="label" 
                                className="col-10 select-periodo" 
                                value={this.state.periodoSelect ? this.state.periodoSelect : null} 
                                onChange={(e) => this.handleSelectFormChange("periodo",e)} 
                                /> 
                            </div> 
                        </div>
                        <div id={"chartdiv"+this.props.movil} style={{ width: "100%", height: "350px" }} hidden={!this.state.controlesResultado || this.state.noHayDatos === true}></div>
                        <div hidden={this.state.noHayDatos === false} style={{height:"260px"}}>
                            <div className="alert alert-secondary" role="alert" style={{top:'33%',textAlign: 'center'}}><span style={{fontWeight:'bold'}}>Sin Datos Disponibles</span></div>
                        </div>
                    </div>
                </div>
                <div className="card col-md-7 moviles-detalle-controles-card" style={{display: 'inline-block', background:'#f4f5fa'}} hidden={!this.state.controlesResultado}>
                { ID__UM_ODOMETRO === unidadMedidorId ? (
                    <div className="card-body" style={{display: 'flex'}}>
                        <div className="col-4">
                            <div className="card-body">
                                <h3 className="" style={{fontSize: '2.2rem'}}>${ costo }</h3>
                                <span className="" style={{color: '#a0a5ab', fontSize:"18px"}}>{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_costo/MedidorKm', defaultMessage: 'Costo/km' })}</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card-body">
                                <h3 className="" style={{fontSize: '2.2rem'}}>{km_por_dia} {this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_medidor_por_dia_km', defaultMessage: 'Km' })}</h3>
                                <span className="" style={{color: '#a0a5ab', fontSize:"18px"}}>{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_medidor_por_dia_promedio_medidor_km/dia', defaultMessage: 'Promedio Km/Día' })}</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card-body">
                                <h3 className="" style={{fontSize: '2.2rem'}}>{ distancia_total} {this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_distancia_total_km', defaultMessage: 'km' })}</h3>
                                <span className="" style={{color: '#a0a5ab', fontSize:"18px"}}>{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_distancia_total_promedio_recorrido_km', defaultMessage: 'Recorrido Km' })}</span>
                            </div>
                        </div>
                    </div>
                ):''}
                { ID__UM_HOROMETRO  === unidadMedidorId ? (
                  <div className="card-body" style={{display: 'flex'}}>
                    <div className="col-4">
                            <div className="card-body">
                                <h3 className="" style={{fontSize: '2.2rem'}}>${ costo }</h3>
                                <span className="" style={{color: '#a0a5ab', fontSize:"18px"}}>{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_costo/MedidorHora', defaultMessage: 'Costo/hora' })}</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card-body">
                                <h3 className="" style={{fontSize: '2.2rem'}}>{km_por_dia} {this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_medidor_por_dia_horas', defaultMessage: 'Horas' })}</h3>
                                <span className="" style={{color: '#a0a5ab', fontSize:"18px"}}>{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_medidor_por_dia_promedio_medidor_horas/dia', defaultMessage: 'Promedio Horas/Día' })}</span>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card-body">
                                <h3 className="" style={{fontSize: '2.2rem'}}>{ distancia_total} {this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_horometro_total_horas', defaultMessage: 'Horas' })}</h3>
                                <span className="" style={{color: '#a0a5ab', fontSize:"18px"}}>{this.props.intl.formatMessage({ id: 'movilesDetail.render.total_cost.label_horometro_total_promedio_horas', defaultMessage: 'Transcurrido Horas' })}</span>
                            </div>
                        </div>
                
                  </div>
                ) :''}
                <div className="moviles-detalle-controles-title">
                        <h3 className="title-controles">{this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.header_controles', defaultMessage: 'Controles' })}</h3>
                </div>
                {this.state.controlesResultado ?
                <div style={{display: 'flex'}}>
                    {this.state.controlesResultado.gps === 'gris' || this.state.controlesResultado.gps === null ?
                    <div className="card moviles-detalle-iconos-card" id={"gpsTooltip"+this.props.movil} data-html="true" data-placement="top" data-toggle="tooltip" title={this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_sin_dispositivo', defaultMessage: 'Sin Dispositivo' })}>
                      <img className='moviles-detalle-iconos' src={GpsGrisImage}/> 
                    </div>
                        : <div className="card moviles-detalle-iconos-card" id={"gpsTooltip"+this.props.movil}  data-html="true" data-placement="top" data-toggle="tooltip" title={this.state.controlesResultado.gps === 'verde' ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_reportando', defaultMessage: 'Reportando' }) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_no_reporta', defaultMessage: 'No Reporta' })+" >72hs"}>
                            <img className='moviles-detalle-iconos' src={this.state.controlesResultado.gps === 'verde' ? GpsVerdeImage : GpsRojoImage}/> 
                          </div>
                      }
                    <div className="card moviles-detalle-iconos-card" id={"dtcTooltip"+this.props.movil} data-html="true" data-placement="top" data-toggle="tooltip" title={this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_sin_datos', defaultMessage: 'Sin Datos' })}>
                        <img className='moviles-detalle-iconos' src={this.state.controlDtc} />
                    </div>
                    <div className="card moviles-detalle-iconos-card" id={"tallerTooltip"+this.props.movil} data-html="true" data-placement="top" data-toggle="tooltip" 
                      title={this.state.controlesResultado.enTaller === 0 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_vehiculo_fuera_de_taller', defaultMessage: 'Vehículo fuera de taller' }) : (this.state.controlesResultado.enTaller === 1 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_en_taller_sin_dato_GPS', defaultMessage: 'En Taller sin dato GPS' }) : (this.state.controlesResultado.enTaller === 2 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_en_taller_verificado_GPS', defaultMessage: 'En Taller Verificado GPS' }) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_en_taller_fuera_de_rango_GPS', defaultMessage: 'En Taller fuera de rango GPS' })))}>
                      <img className={this.state.controlesResultado.enTaller !== 0 ? 'moviles-detalle-iconos redirect' : 'moviles-detalle-iconos'} src={this.state.controlEnTaller} onClick={this.state.controlesResultado.enTaller !== 0 ? this.handleFilter.bind(this,'tickets','taller',this.props.movil): null} />
                    </div>
                    <div className="card moviles-detalle-iconos-card" id={"docMovilTooltip"+this.props.movil} data-html="true" data-placement="top" data-toggle="tooltip" 
                      title={this.state.controlesResultado.documentacionMovil === 0 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_sin_control_de_documentacion_del_movil', defaultMessage: 'Sin control de documentación del Móvil' }) : (this.state.controlesResultado.documentacionMovil === 1 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_documentacion_del_movil_Ok', defaultMessage: 'Documentación del Móvil Ok' }) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_en_taller_documentacion_del_movil_vencida', defaultMessage: 'Documentación del Móvil Vencida' }))}>
                        <img className={this.state.controlesResultado.documentacionMovil === 2 ? 'moviles-detalle-iconos redirect' : 'moviles-detalle-iconos'} src={this.state.controlDocMovil} onClick={this.state.controlesResultado.documentacionMovil === 2 ? this.handleFilter.bind(this,'tickets','documentacionMovil',this.props.movil): null} />
                    </div>
                    
                    <div className="card moviles-detalle-iconos-card" id={"docChoferTooltip"+this.props.movil} data-html="true" data-placement="top" data-toggle="tooltip" 
                      title={this.state.controlesResultado.documentacionChofer === 0 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_sin_control_de_documentacion_de_chofer', defaultMessage: 'Sin control de documentación de Chofer' }) : (this.state.controlesResultado.documentacionChofer === 1 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_documentacion_de_chofer_Ok', defaultMessage: 'Documentación de Chofer Ok' }) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.title_documentacion_de_chofer_vencida', defaultMessage: 'Documentación de Chofer Vencida' }))}>
                        <img className={this.state.controlesResultado.documentacionChofer === 2 ? 'moviles-detalle-iconos redirect' : 'moviles-detalle-iconos'} src={this.state.controlDocChofer} onClick={this.state.controlesResultado.documentacionChofer === 2 ? this.handleFilter.bind(this,'tickets','documentacionChofer',this.props.movil): null} />
                    </div>
                      
                    <div className="card moviles-detalle-iconos-card" id={"infraccionesTooltip"+this.props.movil}  data-html="true" data-placement="top" data-toggle="tooltip" 
                   title= {this.state.controlesResultado.infracciones === 2 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_posee', defaultMessage: 'Posee' })+' '+(this.state.controlesResultado.totalInfracciones.total ? (this.state.controlesResultado.totalInfracciones.total == 1 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_una_infraccion', defaultMessage: '1 infracción' }) : (this.state.controlesResultado.totalInfracciones.total+' '+this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_infracciones', defaultMessage: 'infracciones' }))) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_cero_infracciones', defaultMessage: '0 infracciones' }))+" ($ " +(this.state.controlesResultado.totalInfracciones.importe ? this.state.controlesResultado.totalInfracciones.importe : 0)+") <br>"+(this.state.controlesResultado.totalInfracciones.total ? (this.state.controlesResultado.totalInfracciones.totalDistritos == 1 ? (this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_en_el_distrito', defaultMessage: 'En el distrito' })+": "+this.state.controlesResultado.totalInfracciones.distritos) : (this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_en_los_distritos', defaultMessage: 'En los distritos' })+": "+this.state.controlesResultado.totalInfracciones.distritos)) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_en_los_distritos', defaultMessage: 'En los distritos' })+": "+this.state.fuentesInfracciones) : 
                   this.state.controlesResultado.infracciones === 0 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_no_posee_el_modulo_de_infracciones', defaultMessage: 'No posee el módulo de infracciones' }) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.infraccionesTooltip.title_no_posee_infracciones_en_los_distritos', defaultMessage: 'No posee infracciones en los distritos' })+" "+this.state.fuentesInfracciones}>
                      <img className={this.state.controlesResultado.infracciones === 2 ? 'moviles-detalle-iconos redirect' : 'moviles-detalle-iconos'} src={this.state.controlInfracciones} onClick={this.state.controlesResultado.infracciones === 2 ? this.handleFilter.bind(this,'moduloInfracciones','infracciones',this.props.movil): null} />
                    </div>  
                      
                    <div className="card moviles-detalle-iconos-card" id={"combustiblesTooltip"+this.props.movil} data-html="true" data-placement="top" data-toggle="tooltip" 
                      title={this.state.controlesResultado.combustibles !== 0 && this.state.controlesResultado.combustiblesInvalidas.total > 0 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.combustiblesTooltip.title_posee', defaultMessage: 'Posee' })+" "+(this.state.controlesResultado.combustiblesInvalidas.total)+" "+this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.combustiblesTooltip.title_cargas_invalidas', defaultMessage: 'cargas inválidas' })+" "+"<br> ($"+(this.state.controlesResultado.combustiblesInvalidas.importe)+" / "+(this.state.controlesResultado.combustiblesInvalidas.litros)+this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.combustiblesTooltip.title_litros', defaultMessage: 'litros' })+")" : 
                      this.state.controlesResultado.combustibles === 0 ? this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.combustiblesTooltip.title_no_posee_el_modulo_de_combustible', defaultMessage: 'No posee el módulo de combustible' }) : this.props.intl.formatMessage({ id: 'movilesDetail.render.controls.combustiblesTooltip.title_no_posee_cargas_invalidas', defaultMessage: 'No posee cargas inválidas' })}>
                      <img className={this.state.controlesResultado.combustiblesInvalidas.total > 0 ? 'moviles-detalle-iconos redirect' : 'moviles-detalle-iconos'} src={this.state.controlCombustibles} onClick={this.state.controlesResultado.combustiblesInvalidas.total > 0  ? this.handleFilter.bind(this,'combustibles','combustibles',this.props.movil): null} />
                    </div>
                    
                </div>
                : ''}
              </div>
        
			</React.Fragment>
		);
	}
}

export default MovilesDetail;
