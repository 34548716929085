import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import Security from "../../commons/security/Security.js";
import Loading from "../ui/Loading.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import { FormattedMessage, injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import Input from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import RopGridRow from './RopGridRow.js';
import RopGridHeaders from "./RopGridHeaders.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import moment from 'moment';
import 'moment/min/locales';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const useStylesPersonal = makeStyles((theme) => ({
	root: {
		"& > *": {
			width: "100%",
		},
	}
}));

const RopsGrid = (props) => {
	const classes = useStylesPersonal();
	const intl = props.intl;
	
	const estados = ['CREADO', 'RECIBIDO', 'CANCELADO', 'CERRADO'];
	const filterEstadoDefault = ['CREADO', 'RECIBIDO'];

	const [loading, setLoading] = useState(false);
	const [redirectTo, setRedirectTo] = useState(null);
	const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
	const [rops, setRops] = useState([]);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filtersActive, setFiltersActive] = useState(null);
	const [fechaDesde, setFechaDesde] = useState();
	const [fechaHasta, setFechaHasta] = useState();
	const [numero, setNumero] = useState();
	const [estado, setEstado] = useState(filterEstadoDefault);
	const [talleres, setTalleres] = useState([]);
	const [clasificaciones, setClasificaciones] = useState([]);
	const [equipo, setEquipo] = useState();
	const [filtersOpen, setFiltersOpen] = React.useState(false);
	const [filterFechaDesde, setFilterFechaDesde] = useState();
	const [filterFechaHasta, setFilterFechaHasta] = useState();
	const [filterNumero, setFilterNumero] = useState();
	const [filterEstado, setFilterEstado] = useState(filterEstadoDefault);
	const [filterEquipo, setFilterEquipo] = useState();
	const [filterTaller, setFilterTaller] = useState();
	const [filterClasificacion, setFilterClasificacion] = useState();

	useEffect(() => {
		if (Security.hasPermission("ROPS_LISTAR")) {
			ajaxHandler.subscribe(this);
			initGrid();
		} else {
			setRedirectTo("/error");
		}
	}, []);

	const initGrid = async () => {
		setLoading(true);
		window.scrollTo(0, 0);

		try {
			const [ropsData, talleres, clasificaciones] = await Promise.all([
				await getData(`/rops${dataTableQuery()}`),
				await getData('/talleres/gerenciador/' + ConfigBusiness.get("rop.gerenciador") + '/select'),
				await getData('/rops/classifications/select')
			]);

			setRops(ropsData.rops);
			setTalleres(talleres);
			setClasificaciones(clasificaciones);
			setCount(ropsData.pagination.count);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setFiltersActive(filterNumero || filterEstado.length > 0 || filterEquipo || filterTaller, filterClasificacion || filterFechaDesde || filterFechaHasta);
		dataTableUpdate();
	}, [page, rowsPerPage, filterNumero, filterEstado, filterEquipo, filterTaller, filterClasificacion, filterFechaDesde, filterFechaHasta]);

	const dataTableQuery = () => {
		let myQuery = '?page=' + page;
		myQuery += '&perPage=' + rowsPerPage;
		myQuery += filterFechaDesde ? '&fechaDesde=' + moment(filterFechaDesde).utcOffset(0).startOf('day').toISOString() : '';
		myQuery += filterFechaHasta ? '&fechaHasta=' + moment(filterFechaHasta).utcOffset(0).endOf('day').toISOString() : '';
		myQuery += filterNumero ? '&numero=' + filterNumero : '';
		myQuery += filterEstado ? '&estado=' + filterEstado : '';
		myQuery += filterEquipo ? '&equipo=' + filterEquipo : '';
		myQuery += filterTaller ? '&taller=' + filterTaller.value : '';
		myQuery += filterClasificacion ? '&clasificacion=' + filterClasificacion.value : '';
		return myQuery;
	};

	const dataTableUpdate = async () => {
		setLoading(true);
		try {
			const [ropsData] = await Promise.all([
				await getData(`/rops${dataTableQuery()}`)
			]);

			setRops(ropsData.rops);
			setCount(ropsData.pagination.count);
		} finally {
			setLoading(false);
		}
	};

	const handleNew = (event) => {
		setRedirectTo("/rop/add");
	};

	const getData = (service) => {
		let serviceURL = Config.get("apiUrlBase") + service;
		return fetch(serviceURL, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"Authorization-Token": localStorage.getItem("token"),
			},
		}).then((response) => {
			return ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	};

	const redirectToView = (id) => {
		setRedirectTo("/rop/" + id);
	};

	const handleFilterReset = () => {
		setFechaDesde(null);
		setFechaHasta(null);
		setNumero(null);
		setEstado([]);
		setEquipo(null);
		setFilterFechaDesde(null);
		setFilterFechaHasta(null);
		setFilterNumero(null);
		setFilterEstado([]);
		setFilterEquipo(null);
		setFiltersActive(null);
		setFilterClasificacion(null);
	};

	const gridActions = (
		<div className="container-fluid">
			<div className="row dt-icons">
				<div className="col-10">
					<div className="row">
						{Security.renderIfHasPermission("ROPS_CREAR",
							<div>
								<div
									className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
									style={{ height: "55px" }}
									onClick={handleNew}
									data-toggle="tooltip"
									data-placement="right"
									title={intl.formatMessage({
										id: "ropsGrid.render.button_add.title_nuevo",
										defaultMessage: "Nuevo",
									})}
								>
									<i className="ft-plus"></i>
								</div>
							</div>
						)}
						<div
							style={{ maxHeight: "52px" }}
							className="btn btn-grey-blue btn-round box-shadow-2 btn-dt-main round-icon"
							onClick={() => setFiltersOpen(!filtersOpen)}
							data-toggle="tooltip"
							data-placement="right"
							title={intl.formatMessage({
								id: "ropsGrid.render.button_add.title_filtros",
								defaultMessage: "Filtros",
							})}
						>
							{filtersOpen ? <UpIcon /> : <ExpandMoreIcon />}
						</div>
						{filtersActive && (
							<div className="btn-dt-main round-icon">
								<div
									className="text-center"
									style={{
										height: "25px",
										borderRadius: "3px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										paddingLeft: "8px",
										backgroundColor: "#5AAC43",
										color: "white",
									}}
								>
									<div
										className="text-center"
										style={{
											width: "100%",
											fontSize: "12px",
											fontWeight: "500",
										}}
									>
										{rops.length} de {count} resultados de busqueda
									</div>
									<div className="resultados-busqueda-notif">
										<i class="las la-times la-xs cursor-pointer" onClick={handleFilterReset}></i>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);

	const filters = (
		<Collapse in={filtersOpen}>
			<div className="row mb-2">
				<div className="col-md-12">
					<div className="row" style={{ width: "100%"}}>
						<div className="col-md-3">
							<DatePicker 
								id="fechaDesde"
								name="fechaDesde"
								className="date-picker-placeholder"
								selected = { fechaDesde }
								placeholderText = { intl.formatMessage({ id: 'ropsGrid.render.filtros_fecha_desde.placeholder', defaultMessage: 'DD/MM/AAAA' }) }
								dateFormat="DD/MM/yyyy"
								onChange={date => setFechaDesde(date)}
								shouldCloseOnSelect={false}
								onBlur={() => {setFilterFechaDesde(fechaDesde)}}
								customInput={
									<Input
										id="standard-basic"
										label={intl.formatMessage({
											id: "ropsGrid.render.filtros_fecha_desde.label",
											defaultMessage: "Fecha desde",
										})}
										disabled={loading}
									/>
								}
							/>
						</div>
						<div className="col-md-3">
							<DatePicker 
								id="fechaHasta"
								name="fechaHasta"
								className="date-picker-placeholder"
								selected = { fechaHasta }
								placeholderText = { intl.formatMessage({ id: 'ropsGrid.render.filtros_fecha_hasta.placeholder', defaultMessage: 'DD/MM/AAAA' }) }
								dateFormat="DD/MM/yyyy"
								onChange={date => setFechaHasta(date)}
								shouldCloseOnSelect={false}
								onBlur={() => {setFilterFechaHasta(fechaHasta)}}
								customInput={
									<Input
										id="standard-basic"
										label={intl.formatMessage({
											id: "ropsGrid.render.filtros_fecha_hasta.label",
											defaultMessage: "Fecha hasta",
										})}
										disabled={loading}
									/>
								}
							/>
						</div>
					</div>
					<div className="row" style={{ width: "100%"}}>
						<div className="col-md-3">
							<form className={classes.root} noValidate autoComplete="off">
								<Input
									id="standard-basic"
									label={intl.formatMessage({
										id: "ropsGrid.render.filtros_numero.label",
										defaultMessage: "Número",
									})}
									name="numero"
									value={numero ? numero : ''}
									onChange={e => setNumero(e.target.value)}
									onBlur={e => setFilterNumero(numero)}
									onKeyDown={e => {
										if (e.keyCode === 13) {
											e.preventDefault();
											setFilterNumero(numero);
										}
									}}
									disabled={loading}
								/>
							</form>
						</div>
						<div className="col-md-3">
							<form className={classes.root} noValidate autoComplete="off">
								<Input
									id="standard-basic"
									label={intl.formatMessage({
										id: "ropsGrid.render.filtros_equipo.label",
										defaultMessage: "Equipo",
									})}
									name="equipo"
									value={equipo ? equipo : ''}
									onChange={e => setEquipo(e.target.value)}
									onBlur={e => setFilterEquipo(equipo)}
									onKeyDown={e => {
										if (e.keyCode === 13) {
											e.preventDefault();
											setFilterEquipo(equipo);
										}
									}}
									disabled={loading}
								/>
							</form>
						</div>
						<div className="col-md-3">
							<Autocomplete
								size="small"
								name="Taller"
								options={talleres}
								value={filterTaller ? filterTaller : null}
								getOptionLabel={option => option.label}
								onChange={(e, v) => setFilterTaller(v)}
								disabled={loading}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({
											id: "llantasGrid.render.filtros_taller.label",
											defaultMessage: "Taller",
										})}
										placeholder={intl.formatMessage({
											id: "llantasGrid.render.filtros_taller.placeholder",
											defaultMessage: "Taller",
										})}
									/>
								)}
							/>
						</div>
						<div className="col-md-3">
							<Autocomplete
								size="small"
								name="Clasificacion"
								options={clasificaciones}
								value={filterClasificacion ? filterClasificacion : null}
								getOptionLabel={option => option.label}
								onChange={(e, v) => setFilterClasificacion(v)}
								disabled={loading}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({
											id: "llantasGrid.render.filtros_clasificacion.label",
											defaultMessage: "Clasificación",
										})}
										placeholder={intl.formatMessage({
											id: "llantasGrid.render.filtros_clasificacion.placeholder",
											defaultMessage: "Clasificación",
										})}
									/>
								)}
							/>
						</div>
					</div>
					<div className="row" style={{ width: "100%" }}>
						<div className="col-md-6">
							<Autocomplete
								size="small"
								name="estado"
								options={estados}
								value={estado ? estado : []}
								multiple={true}
								getOptionLabel={option => option}
								onChange={(e, v) => setEstado(v)}
								onBlur={(e, v) => setFilterEstado(estado)}
								disabled={loading}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										label={intl.formatMessage({
											id: "ropsGrid.render.filtros_estados.label",
											defaultMessage: "Estados",
										})}
										placeholder={intl.formatMessage({
											id: "ropsGrid.render.filtros_estados.placeholder",
											defaultMessage: "Estados",
										})}
									/>
								)}
							/>
						</div>
					</div>
				</div>
			</div>
		</Collapse>
	);

	const grid = (
		<>
			<div className="table-responsive">
				<table
					id="dataTable"
					className="dataTable table nowrap server-side table-hover dataTableMovil"
					width="100%"
				>
					<thead>
						<RopGridHeaders />
					</thead>
					<tbody>
						{rops.length ? (
							rops.map((rop) => (
								<RopGridRow
									key={rop.id}
									rop={rop}
									redirectToView={redirectToView}
								/>
							))
						) : (
							<tr>
								<td align={"center"} colSpan={14}>
									{loading ? (
										<CircularProgress />
									) : (
										<FormattedMessage
											id="ropsGrid.render.sin_datos_en_tabla"
											defaultMessage="No hay datos disponibles."
										/>
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 50, 100]}
				component="div"
				count={count}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(e, page) => setPage(page)}
				onRowsPerPageChange={e => setRowsPerPage(e.target.value)}
				labelRowsPerPage="Mostrar"
				labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
			/>
		</>
	);

	return (
		<React.Fragment>
			{redirectTo && <Redirect push to={redirectTo} />}
			{loading && <Loading />}

			<div className="row mt-2">
				<div className="col-12">
					<div className="card">
						<div className="card-content collpase show">
							<div className="card-body card-dashboard">
								{gridActions}

								{filters}

								{grid}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default injectIntl(RopsGrid);