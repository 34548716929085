import React, { useState, useEffect, useLayoutEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import $ from 'jquery';

import Select from 'react-select';
import GravatarOptionMoviles from '../moviles/GravatarOptionMoviles';
import llantaDesgastePrematuroGreen from '../../assets/images/llantas/svg/llanta-desgaste-prematuro-green.svg';
import llantaDesgastePrematuroRed from '../../assets/images/llantas/svg/llanta-desgaste-prematuro-red.svg';
import llantaPresionGreen from '../../assets/images/llantas/svg/llanta-presion-green.svg';
import llantaPresionRed from '../../assets/images/llantas/svg/llanta-presion-red.svg';
import llantaMedicionGreen from '../../assets/images/llantas/svg/llanta-medicion-green.svg';
import llantaMedicionRed from '../../assets/images/llantas/svg/llanta-medicion-red.svg';
import llantaMedicionYellow from '../../assets/images/llantas/svg/llanta-medicion-yellow.svg';
import llantaDesgasteDesparejoGreen from '../../assets/images/llantas/svg/llanta-desgaste-desparejo-green.svg';
import llantaDesgasteDesparejoRed from '../../assets/images/llantas/svg/llanta-desgaste-desparejo-red.svg';
import llantaInspeccionRed from '../../assets/images/llantas/svg/llanta-inspeccion-red.svg';
import llantaInspeccionGreen from '../../assets/images/llantas/svg/llanta-inspeccion-green.svg';
import moment from 'moment';
import Timezone from '../../commons/timezone/Timezone.js';
import LlantasDetailCostsChart from './LlantasDetailCostsChart';

function LlantasDetail(props) {
  const { llanta } = props;
  const desgastePrematuroCada = 1000;
  const psiTolerancia = 20;

  useLayoutEffect(() => {
    $('#desgastePrematuro_' + llanta.id).tooltip();
    $('#tooltipPasaronDias_' + llanta.id).tooltip();
    $('#tooltipFallaMedicion_' + llanta.id).tooltip();
    $('#diferenciaMedicion_' + llanta.id).tooltip();
    $('#tooltipPsiTolerancia_' + llanta.id).tooltip();
  }, []);
  const controlDesgastePrematuro = (props) => {
    let tooltip = props.intl.formatMessage({
      id: 'llantasDetail.controles_desgaste_prematuro_inspeccion.sin_datos',
      defaultMessage: 'Sin datos',
    });
    let icon = llantaDesgastePrematuroGreen;
    let desgasteCada = 0;
    if (llanta.recapadas > 0) {
      if (llanta.odometro_recapado === 0 || llanta.odometro_recapado === null) desgasteCada = 0;
      else {
        desgasteCada = llanta.desgaste / llanta.odometro_recapado;
      }
    } else {
      if (llanta.odometro === 0 || llanta.odometro === null) desgasteCada = 0;
      else desgasteCada = llanta.desgaste / llanta.odometro;
    }
    if (llanta.llanta_modelo.desgaste_prematuro) {
      tooltip = props.intl.formatMessage({
        id: 'llantasDetail.controles_desgaste_prematuro.desgaste_prematuro',
        defaultMessage: 'Desgaste Prematuro',
      });
      if (desgasteCada >= llanta.llanta_modelo.desgaste_prematuro / desgastePrematuroCada)
        icon = llantaDesgastePrematuroRed;
    }
    return (
      <div
        className="card moviles-detalle-iconos-card"
        id={'desgastePrematuro_' + llanta.id}
        data-placement="top"
        data-toggle="tooltip"
        title={tooltip}
      >
        <img
          className="moviles-detalle-iconos"
          src={icon}
          style={{ width: '75px', height: '75px' }}
        />
      </div>
    );
  };

  const ultimaInspeccionPasaronDias = (props) => {
    let tooltip = props.intl.formatMessage({
      id: 'llantasDetail.controles_pasaron_dias_ultima_inspeccion.sin_datos',
      defaultMessage: 'Sin datos',
    });
    let icon = llantaInspeccionGreen;
    if (llanta.ultima_medicion) {
      let fechaDeLlanta = Timezone.getDateForClient(llanta.ultima_medicion.created_at, null, null);
      let fechaActual = moment();
      let diasPasados = fechaActual.diff(fechaDeLlanta, 'days');
      if (props.ultimaMedicionDiasAlerta) {
        tooltip =
          props.intl.formatMessage({
            id: 'llantasDetail.controles_pasaron_dias_ultima_inspeccion.pasaron',
            defaultMessage: 'Pasaron',
          }) +
          ' ' +
          diasPasados +
          ' ' +
          props.intl.formatMessage({
            id: 'llantasDetail.controles_pasaron_dias_ultima_inspeccion.dias_de_ultima_inspeccion.',
            defaultMessage: 'días de última inspección.',
          });
        if (diasPasados > props.ultimaMedicionDiasAlerta) {
          icon = llantaInspeccionRed;
        }
      }
    }
    return (
      <div
        className="card moviles-detalle-iconos-card"
        id={'tooltipPasaronDias_' + llanta.id}
        data-placement="top"
        data-toggle="tooltip"
        title={tooltip}
      >
        <img
          className="moviles-detalle-iconos"
          src={icon}
          style={{ width: '75px', height: '75px' }}
        />
      </div>
    );
  };
  const fallaMedicion = () => {
    let tooltip = 'Sin datos';
    let icon = llantaMedicionGreen;
    if (llanta.ultima_medicion) {
      if (llanta.ultima_medicion.estado === 1) {
        tooltip = props.intl.formatMessage({
          id: 'llantasDetail.controles_falla_medicion.medicion_sin_fallas',
          defaultMessage: 'Medición sin fallas',
        });
        icon = llantaMedicionGreen;
      }
      if (llanta.ultima_medicion.estado === 2) {
        tooltip = props.intl.formatMessage({
          id: 'llantasDetail.controles_falla_medicion.medicion_con_fallas',
          defaultMessage: 'Medición con fallas',
        });
        icon = llantaMedicionRed;
      }
      if (llanta.ultima_medicion.estado === 3) {
        tooltip = props.intl.formatMessage({
          id: 'llantasDetail.controles_falla_medicion.medicion_requiere_recapado',
          defaultMessage: 'Requiere recapado',
        });
        icon = llantaMedicionYellow;
      }
    }
    return (
      <div
        className="card moviles-detalle-iconos-card"
        id={'tooltipFallaMedicion_' + llanta.id}
        data-html="true"
        data-placement="top"
        data-toggle="tooltip"
        title={tooltip}
      >
        <img
          className="moviles-detalle-iconos"
          src={icon}
          style={{ width: '75px', height: '75px' }}
        />
      </div>
    );
  };

  const controlDiferenciaDosMediciones = (props) => {
    let tooltip = props.intl.formatMessage({
      id: 'llantasDetail.controles_diferencia_dos_mediciones.sin_datos',
      defaultMessage: 'Sin datos',
    });
    let icon = llantaDesgasteDesparejoGreen;
    if (props.llanta.desgasteDesparejo === 1) {
      tooltip = props.intl.formatMessage({
        id: 'llantasDetail.controles_diferencia_dos_mediciones.desgaste_desparejo_valido',
        defaultMessage: 'Desgaste desparejo',
      });
    } else {
      tooltip = props.intl.formatMessage({
        id: 'llantasDetail.controles_diferencia_dos_mediciones.desgaste_desparejo_invalido',
        defaultMessage: 'Desgaste desparejo',
      });
      icon = llantaDesgasteDesparejoRed;
    }
    return (
      <div
        className="card moviles-detalle-iconos-card"
        id={'diferenciaMedicion_' + llanta.id}
        data-html="true"
        data-placement="top"
        data-toggle="tooltip"
        title={tooltip}
      >
        <img
          className="moviles-detalle-iconos"
          src={icon}
          style={{ width: '75px', height: '75px' }}
        />
      </div>
    );
  };

  const psiToleranciaCorrecta = (props) => {
    let tooltip = props.intl.formatMessage({
      id: 'llantasDetail.controles_psi_tolerancia.sin_datos',
      defaultMessage: 'Sin datos',
    });
    let icon = llantaPresionGreen;
    if (llanta.ultima_medicion) {
      if (llanta.llanta_modelo.psi > 0 && llanta.ultima_medicion.psi > 0) {
        let tolerancia = (llanta.llanta_modelo.psi * psiTolerancia) / 100;
        if (
          llanta.ultima_medicion.psi >= llanta.llanta_modelo.psi - tolerancia &&
          llanta.ultima_medicion.psi <= llanta.llanta_modelo.psi + tolerancia
        ) {
          tooltip = props.intl.formatMessage({
            id: 'llantasDetail.controles_psi_tolerancia.el_psi_tiene_valores_tolerables',
            defaultMessage: 'El psi tiene valores tolerables',
          });
        } else {
          tooltip = props.intl.formatMessage({
            id: 'llantasDetail.controles_psi_tolerancia.el_psi_tiene_valores_no_tolerables',
            defaultMessage: 'El psi tiene valores no tolerables',
          });
          icon = llantaPresionRed;
        }
      }
    }
    return (
      <div
        className="card moviles-detalle-iconos-card"
        id={'tooltipPsiTolerancia_' + llanta.id}
        data-html="true"
        data-placement="top"
        data-toggle="tooltip"
        title={tooltip}
      >
        <img
          className="moviles-detalle-iconos"
          src={icon}
          style={{ width: '75px', height: '75px' }}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div style={{ maxWidth: '75vw' }}>
        <div className="">
          <div className="">
            <div className="">
              <div className="row">
                <div className="card col-md-5" style={{ background: '#f4f5fa' }}>
                  <div className="card-body">
                    <div style={{ display: 'flex' }}>
                      <div className="from-group col-6">
                        <h5 className="card-title card-title-costo-total">
                          {props.intl.formatMessage({
                            id: 'movilesDetail.render.total_cost.header_costo_total',
                            defaultMessage: 'Costo Total',
                          })}
                        </h5>
                      </div>
                      <div className="from-group col-6" style={{ display: 'flex' }}>
                        {/* <label>
                          {props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_periodo',
                            defaultMessage: 'Período',
                          })}
                          :{' '}
                        </label>
                        <Select
                          id="periodo"
                          name="periodo"
                          clearable={false}
                          options={['Todo', 'Semana']}
                          optionComponent={GravatarOptionMoviles}
                          valueKey="id"
                          labelKey="label"
                          className="col-10 select-periodo"
                          value={null}
                        /> */}
                      </div>
                    </div>
                    <LlantasDetailCostsChart llanta={llanta} />
                  </div>
                </div>
                <div
                  className="card col-md-7"
                  style={{ display: 'inline-block', background: '#f4f5fa' }}
                >
                  <div className="card-body" style={{ display: 'flex' }}>
                    <div className="col-4">
                      <div className="card-body">
                        <h3 className="" style={{ fontSize: '2.2rem' }}>
                        ${llanta.costo ? llanta.costo.value.toFixed(2) + " " + llanta.costo.label : ""}
                        </h3>
                        <span className="" style={{ color: '#a0a5ab', fontSize: '18px' }}>
                          {llanta.unidad_medidor_id === 1 ? props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_costo/Km',
                            defaultMessage: 'Costo/Km',
                          }) : props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_costo/Hr',
                            defaultMessage: 'Costo/Hr',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="card-body">
                        <h3 className="" style={{ fontSize: '2.2rem' }}>
                          976{' '}
                          {props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_km_por_dia',
                            defaultMessage: 'km',
                          })}
                        </h3>
                        <span className="" style={{ color: '#a0a5ab', fontSize: '18px' }}>
                          {props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_km_por_dia_promedio_km/dia',
                            defaultMessage: 'Promedio Km/Día',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="card-body">
                        <h3 className="" style={{ fontSize: '2.2rem' }}>
                          {llanta.odometro} {llanta.unidad_medidor_id === 1 ? props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_distancia_total_km',
                            defaultMessage: 'km',
                          }) : props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_distancia_total_hs',
                            defaultMessage: 'hs',
                          })}
                        </h3>
                        <span className="" style={{ color: '#a0a5ab', fontSize: '18px' }}>
                          {props.intl.formatMessage({
                            id: 'llantasDetail.render.total_cost.label_distancia_total_promedio_recorrido_km',
                            defaultMessage: 'Recorrido Km',
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="moviles-detalle-controles-title">
                    <h3 className="title-controles">
                      {props.intl.formatMessage({
                        id: 'llantasDetail.render.controls.header_controles',
                        defaultMessage: 'Controles',
                      })}
                    </h3>
                  </div>

                  <div style={{ display: 'flex' }}>
                    {controlDesgastePrematuro(props)}
                    {psiToleranciaCorrecta(props)}
                    {fallaMedicion()}
                    {controlDiferenciaDosMediciones(props)}
                    {ultimaInspeccionPasaronDias(props)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default injectIntl(LlantasDetail);
