import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as Utils from '../../commons/utils/Utils';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  TablePagination,
  Collapse,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { StyledTableCell } from '../../commons/utils/TableStyles';
import swal from 'sweetalert';

const PAGINATION_INIT = {
  page: 0,
  perPage: 10,
};

const QUERY_INIT = {
  nombre: '',
  nombreSort: '',
  medida: '',
  medidaSort: '',
  marca: '',
  marcaSort: '',
  psi: '',
  psiSort: '',
};

const LlantaModelosGrid = (props) => {
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [pagination, setPagination] = useState({ ...PAGINATION_INIT });
  const [paginationCount, setPaginationCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState({ ...QUERY_INIT });
  const [dropdownActive, setDropdownActive] = useState(false);
  const [modelos, setModelos] = useState(null);

  useEffect(() => {
    setLoading(true);
    // Fetch Marcas when page changes
    fetchModelos(pagination.page);
  }, [pagination]);

  useEffect(() => {
    setLoading(true);
    // Fetch Marcas when page changes
    fetchModelos(pagination.page);
  }, [searchQuery]);

  const fetchModelos = (page) => {
    Utils.getData(`/llantamodelos/grid?page=${page + 1}${getQuery()}`)
      .then((res) => {
        setModelos(res.modelos);
        setLoading(false);
        setFiltersLoading(false);
        setPaginationCount(res.pagination.count);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setFiltersLoading(false);
      });
  };

  const changePage = (e, newPage) => {
    setLoading(true);
    setPagination({ ...pagination, page: newPage });
  };

  const filter = (name, value) => {
    setLoading(true);
    setFiltersLoading(true);
    value !== '' && setSearchActive(true);

    let query = { ...searchQuery };

    switch (name) {
      case 'medida':
      case 'marca':
        query[name] = value !== '' ? value.map((m) => m.id).join(',') : '';
        break;
      default:
        query[name] = value;
        break;
    }
    setSearchQuery(query);
  };

  const getQuery = () => {
    let query = '';

    query += '&nombre=' + searchQuery.nombre;
    query += '&nombreSort=' + searchQuery.nombreSort;
    query += '&medida=' + searchQuery.medida;
    query += '&medidaSort=' + searchQuery.medidaSort;
    query += '&marca=' + searchQuery.marca;
    query += '&marcaSort=' + searchQuery.marcaSort;
    query += '&psi=' + searchQuery.psi;
    query += '&psiSort=' + searchQuery.psiSort;

    return query;
  };

  const handleFilterReset = () => {
    setPagination(PAGINATION_INIT);
    setSearchQuery(QUERY_INIT);
    setDropdownActive(false);
    setSearchActive(false);
  };

  const deleteModelo = (id) => {
    Utils.deleteData('/llantamodelos/' + id)
      .then((res) => {
        fetchModelos(0);
      })
      .catch((err) => {
        if (
          err.response.data.detalle[0] === 'llantaModeloController.errors.existen_llantas_asociadas'
        ) {
          swal(
            intl.formatMessage({
              id: 'llantaModeloController.errors.existen_llantas_asociadas',
              defaultMessage: 'Operacion denegada, existen llantas asociadas a este modelo.',
            }),
            '',
            'error',
          );
        } else {
          swal(
            intl.formatMessage({
              id: 'llantasModelosGrid.modal_delete.information_error_permiso',
              defaultMessage: 'No tiene permisos para realizar esta acción.',
            }),
            '',
            'error',
          );
        }
      });
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <div className="card">
        <div className="card-content">
          <div className="card-body">
            <div className="row" style={{ marginBottom: '-20px' }}>
              <Tooltip
                title={intl.formatMessage({
                  id: 'llantaMarcasGrid.button.label_add',
                  defaultMessage: 'Agregar',
                })}
                arrow
                placement="right"
              >
                <div
                  className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  style={{ height: 55 }}
                  onClick={() => setRedirectTo(props.match.url + '/add')}
                >
                  <i className="ft-plus"></i>
                </div>
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: 'llantaMarcasGrid.button.label_filtros',
                  defaultMessage: 'Filtros',
                })}
                arrow
                placement="right"
              >
                <div
                  className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  style={{ height: 52 }}
                  onClick={() => setDropdownActive(!dropdownActive)}
                >
                  {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                </div>
              </Tooltip>
              {searchActive && (
                <div className="btn-dt-main round-icon">
                  <div
                    className="text-center"
                    style={{
                      height: '25px',
                      borderRadius: '3px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: '8px',
                      backgroundColor: '#5AAC43',
                      color: 'white',
                    }}
                  >
                    <div
                      className="text-center"
                      style={{
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      {modelos.length} de {paginationCount} resultados de busqueda
                    </div>
                    <div className="resultados-busqueda-notif">
                      <i
                        className="las la-times la-xs cursor-pointer"
                        onClick={handleFilterReset}
                      ></i>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Filtros
              dropdownActive={dropdownActive}
              filtersLoading={filtersLoading}
              filter={filter}
              searchActive={searchActive}
            />
            <TableContainer>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Diseño/Modelo</StyledTableCell>
                    <StyledTableCell align="left">Medida</StyledTableCell>
                    <StyledTableCell align="left">Marca</StyledTableCell>
                    <StyledTableCell align="left">PSI</StyledTableCell>
                    <StyledTableCell align="left">Atributos</StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <StyledTableCell colSpan={4} align="center">
                        Cargando
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    modelos &&
                    modelos.map((modelo) => (
                      <TableRow key={modelo.name} key={'llantaModelosGridRow_' + modelo.id}>
                        <StyledTableCell align="left">{modelo.nombre}</StyledTableCell>
                        <StyledTableCell align="left">
                          {modelo.llanta_medida.nombre}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Logo modelo={modelo} />
                        </StyledTableCell>
                        <StyledTableCell align="left">{modelo.psi}</StyledTableCell>
                        <StyledTableCell align="left">
                          <small>
                            {modelo.puntos_medicion.map((el, index) => (
                              <p
                                key={'puntoMedicionItem_' + modelo.id + '_' + index}
                                style={{ margin: 0 }}
                              >
                                {el.nombre}: {el.mmWarning} | {el.mmDanger}
                              </p>
                            ))}
                          </small>
                        </StyledTableCell>
                        <StyledTableCell align="right" style={{ maxWidth: 50 }}>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0"
                              onClick={() => setRedirectTo(props.match.url + '/' + modelo.id)}
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'llantaMarcasGrid.button.label_view',
                                  defaultMessage: 'Ver',
                                })}
                                arrow
                              >
                                <i className="fa fa-search fa-xs"></i>
                              </Tooltip>
                            </button>
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0 mx-1"
                              onClick={() =>
                                setRedirectTo(props.match.url + '/' + modelo.id + '/edit')
                              }
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'llantaMarcasGrid.button.label_edit',
                                  defaultMessage: 'Editar',
                                })}
                                arrow
                              >
                                <i className="fa fa-pencil fa-xs"></i>
                              </Tooltip>
                            </button>
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-danger p-0"
                              onClick={() => deleteModelo(modelo.id)}
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'clasesGrid.button.label_delete',
                                  defaultMessage: 'Eliminar',
                                })}
                                arrow
                              >
                                <i className="fa fa-trash fa-xs"></i>
                              </Tooltip>
                            </button>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={paginationCount}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              onChangePage={changePage}
              labelRowsPerPage="Mostrar"
              labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

function Logo(props) {
  const [error, setError] = useState(false);
  return (
    <>
      {!error ? (
        <img
          className="dt-user-avatar-image"
          src={
            process.env.PUBLIC_URL + '/images/llantas/marcas-logo/' + props.modelo.llanta_marca.logo
          }
          onError={() => setError(true)}
        />
      ) : (
        <div className="dt-user-avatar">
          {props.modelo.llanta_marca.nombre.substr(0, 2).toUpperCase()}
        </div>
      )}
    </>
  );
}

function Filtros(props) {
  const intl = useIntl();
  const [nombre, setNombre] = useState('');
  const [medida, setMedida] = useState([]);
  const [marca, setMarca] = useState([]);
  const [psi, setPsi] = useState('');

  const [medidas, setMedidas] = useState([]);
  const [marcas, setMarcas] = useState([]);

  useEffect(() => {
    // Get Medidas & Marcas
    fetchMedidas('');
    fetchMarcas('');
  }, []);

  useEffect(() => {
    !props.searchActive && resetAll();
  }, [props.searchActive]);

  const fetchMedidas = (query) => {
    Utils.getData('/llantamedidas/select?nombre=' + query)
      .then((res) => {
        setMedidas(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchMarcas = (query) => {
    Utils.getData('/llantamarcas/select?nombre=' + query)
      .then((res) => {
        setMarcas(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleMaterialChange = (name, value) => {
    props.filter(name, value);
  };

  const handleOnEnterSerialChange = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleMaterialChange(name, value);
    }
  };

  const resetAll = () => {
    setMedida([]);
    setNombre('');
    setMarca([]);
    setPsi('');
  };
  return (
    <>
      <Collapse in={props.dropdownActive}>
        <div className="row mb-1">
          <div className="col-md-10">
            <div className="row" style={{ width: '100%' }}>
              <div className="col-md-4">
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                  <TextField
                    id="standard-basic"
                    style={{ width: '100%' }}
                    label={intl.formatMessage({
                      id: 'llantaModelosGrid.render.filtros_nombre.label',
                      defaultMessage: 'Nombre',
                    })}
                    name="nombre"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'nombre', nombre)}
                    onBlur={(e) => handleMaterialChange('nombre', nombre)}
                    disabled={props.filtersLoading}
                  />
                </form>
              </div>
              <div className="col-md-4">
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    name="medida"
                    options={medidas}
                    value={medida}
                    ChipProps={{onDelete: false}}
                    defaultValue={medida}
                    multiple={true}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, v) => setMedida(v)}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'medida', medida)}
                    onInputChange={(e, v) => fetchMedidas(v)}
                    onBlur={(event) => handleMaterialChange('medida', medida)}
                    disabled={props.filtersLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={intl.formatMessage({
                          id: 'llantaModelosGrid.render.filtros_medida.label',
                          defaultMessage: 'Medida',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'llantaModelosGrid.render.filtros_medidas.placeholder',
                          defaultMessage: 'Medidas',
                        })}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {props.filtersLoading ? (
                                <CircularProgress color="inherit" size={21} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </form>
              </div>
              <div className="col-md-4">
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    name="marca"
                    options={marcas}
                    value={marca}
                    multiple={true}
                    ChipProps={{onDelete: false}}
                    defaultValue={marca}
                    getOptionLabel={(option) => option.nombre}
                    onChange={(e, v) => setMarca(v)}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'marca', marca)}
                    onInputChange={(e, v) => fetchMarcas(v)}
                    onBlur={(event) => handleMaterialChange('marca', marca)}
                    disabled={props.filtersLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={intl.formatMessage({
                          id: 'llantaModelosGrid.render.filtros_marca.label',
                          defaultMessage: 'Marca',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'llantaModelosGrid.render.filtros_marcas.placeholder',
                          defaultMessage: 'Marcas',
                        })}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {props.filtersLoading ? (
                                <CircularProgress color="inherit" size={21} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </form>
              </div>
              <div className="col-md-4">
                <form noValidate autoComplete="off" style={{ width: '100%' }}>
                  <TextField
                    id="standard-basic"
                    style={{ width: '100%' }}
                    label={intl.formatMessage({
                      id: 'llantaModelosGrid.render.filtros_psi.label',
                      defaultMessage: 'PSI',
                    })}
                    name="psi"
                    value={psi}
                    onChange={(e) => setPsi(e.target.value)}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'psi', psi)}
                    onBlur={(e) => handleMaterialChange('psi', psi)}
                    disabled={props.filtersLoading}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default LlantaModelosGrid;
