import React, { Component, useEffect, useState, useLayoutEffect } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'
import TanqueVerde from '../../assets/images/combustibles/tanque-verde.svg'
import TanqueRojo from '../../assets/images/combustibles/tanque-rojo.svg'
import TanqueGris from '../../assets/images/combustibles/tanque-gris.svg'
import MangueraVerde from '../../assets/images/combustibles/manguera-verde.svg'
import MangueraRoja from '../../assets/images/combustibles/manguera-roja.svg'
import MangueraGris from '../../assets/images/combustibles/manguera-gris.svg'
import SurtidorHojaVerde from '../../assets/images/combustibles/rendimiento esperado-verde.svg'
import SurtidorHojaRojo from '../../assets/images/combustibles/rendimiento esperado-rojo.svg'
import SurtidorHojaGris from '../../assets/images/combustibles/rendimiento esperado-gris.svg'
import Security from '../../commons/security/Security.js'
import moment from 'moment'
import Loading from '../ui/Loading.js'
import WidgetSparkline from '../dashboards/operativo2/WidgetSparkline';
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import GravatarOption from './GravatarOption'
import { random } from '@amcharts/amcharts4/.internal/core/utils/String';
import Switch from "react-switch"
import CombustiblesImportar from './CombustiblesImportar';
import CombustiblesImportarReporte from './CombustiblesImportarReporte';
import { FormattedMessage, injectIntl } from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
//import { Tooltip } from '@amcharts/amcharts4/core'
import Tooltip from "@material-ui/core/Tooltip";
import Popper from '@material-ui/core/Popper';
import Popover from 'react-simple-popover';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";

import Input from "@material-ui/core/TextField";
import Cancel from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Sort from "../../assets/images/llantas/table/sort.svg";
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import Timezone from '../../commons/timezone/Timezone.js';
import ControlDistanciaVerde from '../../assets/images/combustibles/control-distancia-verde.png'
import ControlDistanciaRojo from '../../assets/images/combustibles/control-distancia-rojo.png'
import ControlDistanciaGris from '../../assets/images/combustibles/control-distancia-gris.png'
import ControlChoferVerde from '../../assets/images/combustibles/control-chofer-verde.png'
import ControlChoferRojo from '../../assets/images/combustibles/control-chofer-rojo.png'
import ControlChoferGris from '../../assets/images/combustibles/control-chofer-gris.png'

/*$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;*/

const StyledTableCell = withStyles((theme) => ({
    root: {
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Quicksand", Georgia, "Times New Roman", Times, serif',
        
    },
    head: {
        backgroundColor: "#1a1036",
        color: theme.palette.common.white,
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Quicksand", Georgia, "Times New Roman", Times, serif'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const NewTableCell = withStyles((theme) => ({
    root: {
        justifyContent: "center",
    },
    body: {
        justifyContent: "center"
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

/*const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    cell: {
        justifyContent: "center"
    }
});*/
/**************** */
const useStyles = makeStyles((theme)=>({
   /* tableRow: {
      height: 30
    },
    /*tableCell: {
      padding: "0px 16px"
    }*/

    root: {
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        color: "#6B6F82",
        borderBottom: "1px solid #e3ebf3 !important"
    },
    rootTable:{
        display: "table",
        overflowX: "auto",
        whiteSpace: "nowrap",
        marginTop: '6px'
    },
    head: {
        backgroundColor: "#1a1036",
        color: theme.palette.common.white,
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
    },
    body: {
        fontSize: 14,
    },
    /*propiedades para popper con mapas */
    popper: {
        zIndex: 1,         
        },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        left: '-14px',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid', 
            borderWidth: '8px 8px 8px 0',
            borderColor: `transparent #00000033 transparent transparent`,
            right: '-3px',
            top: '-12px',
            position: 'absolute',
        },
        '&::after': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid', 
            borderWidth: '8px 8px 8px 0',
            borderColor: `transparent #fff transparent transparent`,
            top: '-12px',
            position: 'absolute',
            right: '-4px',
            zIndex: '2000'
        },

                
        },
}));

function Row(props) {
    const [ajaxHandler, setAjaxHandler] = useState(props.ajaxHandler);
    const { combustible, intl } = props;
    const columnTarjetaSaldo = props.tarjetaSaldo;
    const columnConductorInformado = props.columnConductorInformado
    const classes = useStyles();
    const Estado = (props) => {

        let title = props.columnData === 'SIN_COMPROBACION' ? props.intl.formatMessage({ id: 'combustiblesGrid.column.createdCell.sin_comprobacion', defaultMessage: 'Sin Comprobación' }) : props.columnData;
        if (props.motivo) {
            title += " | " + props.motivo;
        }

        let classColor = "";
        if (props.columnData == 'INVALIDA') {
            classColor = "status-red";
        } else if (props.columnData == 'VALIDA') {
            classColor = "status-green";
        } else {
            classColor = "status-yellow";
        }

        useLayoutEffect(() => {
            $(".row-tooltip").tooltip();
          }, []);

        return (
            <React.Fragment>
                <TableCell data-toggle="tooltip" data-placement="top" title={ title }  style={{ maxWidth: "3px" }} component="th" scope="row" align="center" size="small" className={classColor + ' embudo all custom-lineHeight row-tooltip'}  classes={{ root: classes.root }}>
                
                </TableCell>
            </React.Fragment>
        );
    }

    const Controles = (props) => {

        const porcentajeLitrosCargados = ConfigBusiness.get('combustibles.porcentajeLitrosCargados');
        const litrosControlEntreCargas = ConfigBusiness.get('combustibles.litrosControlEntreCargas');
        const toleranciaUbicacion = ConfigBusiness.get('combustibles.toleranciaUbicacion');
        const variabilidadRendimientoInferior = ConfigBusiness.get('combustibles.toleranciaRendimientoInferior');
        const variabilidadRendimientoSuperior = ConfigBusiness.get('combustibles.toleranciaRendimientoSuperior');
        const controlDistanciaEntreCargas = ConfigBusiness.get('combustibles.controlDistanciaEntreCargas.habilitado') == 'true' ? true : false;
        const kmControlDistancia = ConfigBusiness.get('combustibles.controlDistanciaEntreCargas.kilometros')
        const controlChofer = ConfigBusiness.get('combustibles.controlConductor.habilitado') == 'true' ? true : false;

        let map = null;
        let marker = null;
        let litrosCada100km = props.litrosCada100km;
        
        const { combustible } = props;
        
        let rendimientoUnidad;
        let signoRendimiento;
        if(litrosCada100km === 'true'){
            signoRendimiento = " + ";
            rendimientoUnidad = ' LT/100Km';
        } else{
            signoRendimiento = " - ";
            rendimientoUnidad = ' Kms/LT';
        }

        //variables para popper
        const [anchorEl, setAnchorEl] = useState(null);
        const openPopper = Boolean(anchorEl);
        const idPopper = openPopper ? 'simple-popper' : undefined;
        const [arrowRef, setArrowRef] = useState(null);
        
        /*POR AHORA NO SE VA A USAR ESTE CONTROL*/
        /* DIFERENCIA LITROS EN ESTACIÓN */
        /*INFO: este control queda para telemetría avanzada
        //INFO: si esto lo habilita hay que copiarlo en el rowCallback con los cambios corresponndientes
        if(full['diferencia_litros_tanque']){
            if(parseFloat(full['diferencia_litros_tanque'])*(1-parseFloat(component.state.porcentajeLitrosCargados)/100)<=parseFloat(full['litros']) && parseFloat(full['litros'])<=parseFloat(full['diferencia_litros_tanque'])*(1+parseFloat(component.state.porcentajeLitrosCargados)/100)){
                //cadena += '<div style="width:auto; line-height: 1.5rem;"><i className="ft-check combustibles-litros-icon combustibles-carga-verde dt-info-icon"></i>';
                devolver += '<div style="width:auto; line-height: 1.5rem;"><img className="div-diferencia-litros" style="height: 40px" src="'+TanqueVerde+'"/>'
            } else{
                    let porcentajeDesvio = (Math.abs(parseFloat(full['diferencia_litros_tanque']) - parseFloat(full['litros']))*100)/ parseFloat(full['litros']);
                    //	cadena += '<div style="width:auto; line-height: 1.5rem;"><i className="ft-x combustibles-litros-icon combustibles-carga-rojo dt-info-icon" data-togle="tooltip" data-placement="top" data-html="true" data-original-title="Litros cargados difieren de los litros informados"></i>';
                    devolver += '<div style="width:auto; line-height: 1.5rem;"><img className="div-diferencia-litros" style="height: 40px" src="'+TanqueRojo+'" data-togle="tooltip" data-placement="top" data-html="true" data-original-title="Litros Cargados '+full['diferencia_litros_tanque']+', difieren en un '+Math.floor(porcentajeDesvio)+'% de lo informado"></i>';
            }
        }	else{
            devolver += '<div style="width:auto; line-height: 1.5rem;"><img className="div-diferencia-litros" style="height: 40px" src="'+TanqueGris+'" data-togle="tooltip" data-placement="top" data-html="true" data-original-title="'+ (full['diferencia_litros_tanque'] === null ? 'Sin valor de litros en el tanque': 'No aumentaron los litros en el tanque')+'"></img>';
        }
        devolver += '</div>';*/

        const initMapPopover = ()=>{
            if (combustible.control_ubicacion == 'INVALIDA' && combustible.direccion_coincide !== null) {
                initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) }, { latitudDetectada: parseFloat(combustible.latitud_detectada_avl), longitudDetectada: parseFloat(combustible['longitud_detectada_avl']) });
            } else {
                initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) });
            }
        }

        function initMap(id, markerInformado, markerDetectado) {

            let lat = markerInformado['latitudInformada'];
            let long = markerInformado['longitudInformada'];
            let zoom = 14;
            let label = "";
            if (markerDetectado) {
                lat = (markerDetectado['latitudDetectada'] + lat) / 2;
                long = (markerDetectado['longitudDetectada'] + long) / 2;
                zoom = 8;
                label = "I";
            }
            var centro = { lat: lat, lng: long };
            map = new window.google.maps.Map(document.getElementById('map' + id), {
                zoom: zoom,
                center: centro
            });
            // Place a draggable marker on the map
            marker = new window.google.maps.Marker({
                position: { lat: markerInformado['latitudInformada'], lng: markerInformado['longitudInformada'] },
                map: map,
                title: props.intl.formatMessage({ id: 'combustiblesGrid.google_maps.direccion_informada', defaultMessage: 'Dirección Informada' }),
                label: label
            });
    
            if (markerDetectado) {
    
                marker = new window.google.maps.Marker({
                    map: map,
                    title: props.intl.formatMessage({ id: 'combustiblesGrid.google_maps.direccion_detectada', defaultMessage: 'Direccion Detectada' }),
                    position: { lat: markerDetectado['latitudDetectada'], lng: markerDetectado['longitudDetectada'] },
                    label: "D"
                });
            }

        }
    
        function placeMarkerAndPanTo(event) {
            geocodeLatLng(event.latLng.lat(), event.latLng.lng());
        }
    
        function geocodeLatLng(lat, lng) {
            var geocoder = new window.google.maps.Geocoder;
            var latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
            let component;
            geocoder.geocode({ 'location': latlng }, geocodeLocation);
        }
    
        function geocodeLocation(results, status) {
    
            if (status === 'OK') {
                if (results[0]) {
    
                    //this.fillInAddress(results[0], false).then(()=>{this.actualizarUbicacionInput();});
                    //this.actualizarUbicacionInput();
                }
            }
        }

        useLayoutEffect(() => {
            $(".row-tooltip").tooltip();
          }, []);

        let metros = parseFloat(combustible.direccion_coincide);
        let litrosInformados = parseFloat(combustible['litros_despues_carga_anterior']) - parseFloat(combustible['dif_litros_avl']) + parseFloat(combustible['litros']);
        let dif = Math.abs(litrosInformados - parseFloat(combustible['litros_despues_carga']));

        const handleOpenMap = (event) => {
            
            setAnchorEl(anchorEl ? null : event.currentTarget);
            
        };

        const handleCloseMap = () =>{
            setAnchorEl(null);
        }

        useEffect((component) => {
            if(openPopper)
            {
                if (combustible.control_ubicacion == 'INVALIDA' && combustible.direccion_coincide !== null) {
                    initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) }, { latitudDetectada: parseFloat(combustible.latitud_detectada_avl), longitudDetectada: parseFloat(combustible.longitud_detectada_avl) });
                } else {
                    initMap(combustible.id, { latitudInformada: parseFloat(combustible.latitud_informada), longitudInformada: parseFloat(combustible.longitud_informada) });
                }
            }
        },[openPopper]);

        let titlePopper = "";
        if(combustible.control_ubicacion == 'INVALIDA' && combustible.direccion_coincide !== null){
            titlePopper = props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.direccion_informada', defaultMessage: 'Dirección informada a ' }) + (metros > 1000 ? (metros / 1000).toFixed(2) + ' kilómetros' : metros.toFixed(2) + ' metros') + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.posicion_obtenida_avl', defaultMessage: ' de la posición obtenida en el AVL.' });
        } else{
            titlePopper = (combustible.bandera ? combustible.bandera : '') + 'Dir: ' + combustible.direccion_carga;
        }

        let popperComponent = 
                            <Popper 
                                id={idPopper} 
                                open={openPopper} 
                                anchorEl={anchorEl} 
                                className={classes.popper}
                                keepMounted={true} 
                                placement={'right'}
                                modifiers={{
                                    flip: {
                                      enabled: true,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: 'scrollParent',
                                    },
                                    arrow: {
                                      enabled: true,
                                      element: arrowRef,
                                    },
                                  }}
                                 >
                                    <span  
                                        className={classes.arrow}
                                        ref={setArrowRef} />
                                    <div className="card popover" style={{border: "1px solid #00000033", maxWidth:"300px", height:"275px",left:"10px", top:"-136px"}}>
                                        <div className="card-header" style={{padding: "0.5rem 0.75rem",
                                                                            marginBottom: "0",
                                                                            fontSize: "1rem",
                                                                            color: "#464855",
                                                                            backgroundColor: "#f7f7f7"}}>
                                        { titlePopper } 
                                        </div>
                                        <div id={"map" + combustible.id } className="map-popover"
                                    style={{
                                        margin: "10px",
                                        padding: "0.5rem 0.75rem",
                                        backgroundColor: "#000",
                                      }}></div>
                                      </div>
                                      
                                </Popper>

        return (
            <React.Fragment>
                <div className="text-center success dt-info-icon row" /*onMouseLeave={() => handleCloseMap()}*/ style={{padding: "16px",width:'max-content'}}>
                    {/* POSITION */}
                    {(combustible.control_ubicacion && combustible.control_ubicacion != 'SIN_COMPROBACION') ?
                        (combustible.control_ubicacion == 'VALIDA' ) ?
                            <ClickAwayListener onClickAway={()=>handleCloseMap()}>
                                <div>
                                    <i id={arrowRef} className="fa fa-map-marker fa-xs combustibles-carga-verde combustibles-ubicacion" data-toggle="popover" data-html="true" data-placement="right" 
                                        onClick={handleOpenMap}
                                        aria-describedby={idPopper} style={{ cursor: "pointer" }}>
                                    </i>
                                    
                                    {popperComponent}
                                    
                                </div>
                            </ClickAwayListener>
                            :
                            (combustible.direccion_coincide === null) ?
                                <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_momento_carga', defaultMessage: 'GPS sin posición en el momento de la carga' })}
                                    data-placement="top" data-html="true" data-togle="tooltip" className="div-position row-tooltip">
                                    <i className="fa fa-map-marker fa-xs combustibles-tooltip-amarillo"></i>
                                </div>
                                :
                                <ClickAwayListener onClickAway={()=>handleCloseMap()}>
                                    <div>
                                        <i id={arrowRef} className="fa fa-map-marker fa-xs combustibles-carga-rojo " data-toggle="popover" data-html="true" data-placement="right"
                                            onClick={handleOpenMap} aria-describedby={idPopper} style={{ cursor: "pointer" }}>
                                        </i>
                                        {popperComponent}
                                    </div>
                                </ClickAwayListener>
                        :
                        <div data-original-title={(!combustible.latitud_informada ? props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_direccion_informada', defaultMessage: 'Sin coordenadas de dirección informada' }) : props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_satelital', defaultMessage: 'Sin Posición de Disp. Satelital' }))}
                            className="div-position row-tooltip" data-html="true" data-togle="tooltip" data-placement="top">
                            <i className="fa fa-map-marker fa-xs combustibles-tooltip-gris"></i>
                        </div>
                    }

                    {/* DIFERENCIA CAPACIDAD DE TANQUE */}
                    {(combustible.control_tanque && combustible.control_tanque != 'SIN_COMPROBACION') ?
                        (combustible.control_tanque == 'VALIDA') ?
                            <div style={{width:"auto", lineHeight: "1.5rem"}}>
                                <img className="div-diferencia-tanque" style={{height: "40px"}} src={TanqueVerde} /></div>
                            :
                            (<div style={{width:"auto", lineHeight: "1.5rem"}}>
                                    <div data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.litros_cargados_mayor_capacidad_tanque', defaultMessage: 'Litros Cargados mayor a la capacidad del tanque' })}
                                    className="row-tooltip">
                                        <img className="div-diferencia-tanque" style={{height: "40px"}} src={TanqueRojo}>
                                        </img>   
                                    </div>
                                
                            </div>)
                        :
                        <div style={{width:"auto", lineHeight: "1.5rem"}}>
                                <div data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.no_datos_capacidad_tanque', defaultMessage: 'No hay datos de la capacidad del tanque' })}
                                className="row-tooltip">
                                    <img className="div-diferencia-tanque" style={{height: "40px"}} src={TanqueGris} >
                                    </img>
                                </div>
                            
                        </div>
                    }

                    {/* RENDIMIENTO */}
                    {(!combustible.control_rendimiento) ? (
                        <div
                            className="row-tooltip"
                            data-placement="top"
                            data-togle="tooltip"
                            data-html="true"
                        >
                            <div style={{width:"auto", lineHeight: "1.5rem"}}>
                                <img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaGris} />
                            </div>
                        </div>
                    ) : null}
                    {(combustible.control_rendimiento === 'SIN_COMPROBACION') ? (
                        <div
                            className="row-tooltip"
                            data-placement="top"
                            data-togle="tooltip"
                            data-html="true"
                            data-original-title={
                                props.intl.formatMessage(backendStrings[combustible.control_rendimiento_msg] ? 
                                    backendStrings[combustible.control_rendimiento_msg] 
                                    : backendStrings['combustiblesGrid.column.controles.rendimiento_esperado_init']
                                )}
                        >
                            <div style={{width:"auto", lineHeight: "1.5rem"}}>
                                <img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaGris} />
                            </div>
                        </div>
                    ) : null}
                    {(combustible.control_rendimiento === 'INVALIDA') ? ( 
                        <div
                            className="row-tooltip"
                            data-placement="top"
                            data-togle="tooltip"
                            data-html="true"
                            data-original-title={
                                props.intl.formatMessage(backendStrings[combustible.control_rendimiento_msg] ? 
                                    backendStrings[combustible.control_rendimiento_msg] 
                                    : backendStrings['combustiblesGrid.column.controles.rendimiento_esperado_init'])
                                + (combustible.control_rendimiento_msg == 'combustiblesGrid.column.controles.rendimiento_esperado_carga_sin_recorrido' ? '' :  
                                combustible.rendimiento_esperado
                                +' '+rendimientoUnidad
                                +' ('+signoRendimiento
                                +(combustible.control_rendimiento_msg == 'combustiblesGrid.column.controles.rendimiento_esperado_superior' ? variabilidadRendimientoSuperior : variabilidadRendimientoInferior)
                                +'%)'
                                )}
                        >
                            <div style={{width:"auto", lineHeight: "1.5rem"}}>
                                <img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaRojo} />
                            </div>
                        </div>
                    ) : null}
                    {(combustible.control_rendimiento === 'VALIDA') ? (
                        <div
                            className="row-tooltip"
                            data-placement="top"
                            data-togle="tooltip"
                            data-html="true"
                            data-original-title={
                                props.intl.formatMessage(backendStrings[combustible.control_rendimiento_msg] ? 
                                    backendStrings[combustible.control_rendimiento_msg] 
                                    : backendStrings['combustiblesGrid.column.controles.rendimiento_esperado_init']
                                ) + combustible.rendimiento_esperado+' '+rendimientoUnidad+' ('+signoRendimiento+variabilidadRendimientoInferior +'%)'}
                        >
                            <div style={{width:"auto", lineHeight: "1.5rem"}}>
                                <img className="div-rendimiento" style={{height: "40px"}} src={SurtidorHojaVerde} />
                            </div>
                        </div>
                    ) : null}


                    {/*	 devolver += '<div data-html="true" data-placement="top" data-toggle="tooltip" title="Servicio: ">';
                //devolver += '<img style="height: 40px" src="'+surtidores[Math.floor(Math.random() * surtidores.length)]+'"/></div>';*/}
                    {/*(combustible['dif_litros_avl'] && combustible['litros_despues_carga'] && combustible['litros_despues_carga_anterior'] && combustible['litros']) ?

                        ((dif <= parseFloat(litrosControlEntreCargas)) ?
                            <div data-placement="top" data-togle="tooltip" data-html="true" data-original-title="" className="div-carga" className="row-tooltip">
                                <img style={{height: "40px"}} src={MangueraVerde} />
                            </div>
                            :
                            <div className="div-carga row-tooltip" data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.faltante_de', defaultMessage: 'Faltante de ' }) + Math.floor(dif) + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.de_litros', defaultMessage: ' litros' })}>
                                <img style={{height: "40px"}} src={MangueraRoja} />
                            </div>
                        )
                        :
                        <div className="div-carga row-tooltip" data-placement="top" data-togle="tooltip" data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.datos_insuficientes', defaultMessage: 'Datos Insuficientes' })}>
                            <img className="" style={{height: "40px"}} src={MangueraGris} />
                        </div>
                */}

                {/* CONTROL TARJETA */}
                {(combustible.control_tarjeta) ?
                        (combustible.control_tarjeta == 'INVALIDA') ?
                        <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.invalida', defaultMessage: 'La tarjeta de combustible de la carga y la asignada al vehículo no coinciden' })}
                            data-placement="top" data-html="true" data-togle="tooltip" className="div-position row-tooltip">
                            <i className="fa fa-credit-card fa-xs combustibles-carga-rojo"></i>
                        </div>

                        :
                        (combustible.control_tarjeta == 'SIN_COMPROBACION') ?
                            <div data-original-title={(!combustible.tarjeta ? props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.carga_sin_tarjeta_combustible', defaultMessage: 'La carga se realizó sin tarjeta de combustible' }) : props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.movil_sin_tarjeta_combustible', defaultMessage: 'El vehículo no tiene tarjeta asignada' }))}
                                className="div-position row-tooltip" data-html="true" data-togle="tooltip" data-placement="top">
                                <i className="fa fa-credit-card fa-xs combustibles-tooltip-gris"></i>
                            </div>
                            :
                            <div>
                                <i className="fa fa-credit-card fa-xs combustibles-carga-verde"></i>
                            </div>

                    :
                    <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.tarjeta.sin_control_tarjeta', defaultMessage: 'No se realizó el control de tarjeta' })}
                        data-placement="top" data-html="true" data-togle="tooltip" className="div-position row-tooltip">
                        <i className="fa fa-credit-card fa-xs combustibles-tooltip-gris"></i>
                    </div>
                    }

                  {/* CONTROL DISTANCIA */}
                  {(controlDistanciaEntreCargas) ?
                        (combustible.control_distancia == 'INVALIDA') 
                        ?
                        <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.distancia.invalida', defaultMessage: 'La distancia entre cargas es menor a '}) + kmControlDistancia + 'Km'}
                            data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
                            <img style={{height: "40px"}} src={ControlDistanciaRojo} />
                        </div>

                        :
                        (combustible.control_distancia == 'SIN_COMPROBACION' || combustible.control_distancia == null) ?
                        <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.distancia.sin_comprobacion', defaultMessage: 'No hay información suficiente de la carga anterior'})}
                          data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
                         <img style={{height: "40px"}} src={ControlDistanciaGris} />
                        </div>

                        :
                        <div style={{marginLeft:'5px'}}>
                          <img style={{height: "40px"}} src={ControlDistanciaVerde} />
                        </div>
                      :
                      ''
                    }

                    {/* CONTROL CHOFER */}
                    {(controlChofer) ?
                          (combustible.control_chofer == 'INVALIDA') 
                          ?
                          <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.informado', defaultMessage: 'El chofer informado'}) +' "'+ combustible.conductor_informado + '"' + 
                            props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.nomatch', defaultMessage: ' por el proveedor no coincide con ningún/o de los registrados en Sistema: '}) + "<br/><br/>" 
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_supervisor', defaultMessage: ' Supervisor'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.supervisor_persona) ? combustible.control_movil_chofer.supervisor_persona.fullName : '') + '<br/>'
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable1', defaultMessage: ' Responsable 1'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable1_persona) ? combustible.control_movil_chofer.responsable1_persona.fullName : '') + '<br/>'
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable2', defaultMessage: ' Responsable 2'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable2_persona) ? combustible.control_movil_chofer.responsable2_persona.fullName : '') + '<br/>'
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_temporal', defaultMessage: ' Temporal'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.temporal_persona) ? combustible.control_movil_chofer.temporal_persona.fullName : '')                            
                            }
                            data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
                            <img style={{height: "40px"}} src={ControlChoferRojo} />
                          </div>

                          // <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.informado', defaultMessage: 'El chofer informado: '}) + combustible.conductor_informado + ' no coincide con el registrado: ' + combustible.conductor_nombre_apellido}
                          //     data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
                          //     <img style={{height: "40px"}} src={ControlChoferRojo} />
                          // </div>

                          :
                          (combustible.control_chofer == 'SIN_COMPROBACION' || combustible.control_chofer == null) ?
                          <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.informado', defaultMessage: 'No hay información para la comprobación'})}
                            data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
                            <img style={{height: "40px"}} src={ControlChoferGris} />
                          </div>

                          :
                          <div data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.informado', defaultMessage: 'El chofer informado'}) +' "'+ combustible.conductor_informado + '"' + 
                            props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.chofer.match', defaultMessage: ' por el proveedor coincide con algún/os de los registrados en Sistema: '}) + "<br/><br/>" 
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_supervisor', defaultMessage: ' Supervisor'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.supervisor_persona) ? combustible.control_movil_chofer.supervisor_persona.fullName : '') + '<br/>'
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable1', defaultMessage: ' Responsable 1'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable1_persona) ? combustible.control_movil_chofer.responsable1_persona.fullName : '') + '<br/>'
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_responsable2', defaultMessage: ' Responsable 2'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.responsable2_persona) ? combustible.control_movil_chofer.responsable2_persona.fullName : '') + '<br/>'
                            + props.intl.formatMessage({ id: 'movilesAbm.render.choferes.label_temporal', defaultMessage: ' Temporal'}) + ': ' + ((combustible.control_movil_chofer && combustible.control_movil_chofer.temporal_persona) ? combustible.control_movil_chofer.temporal_persona.fullName : '')
                            }
                            data-placement="top" data-html="true" data-togle="tooltip" className="row-tooltip" style={{marginLeft:'5px'}}>
                            <img style={{height: "40px"}} src={ControlChoferVerde} />
                          </div>
                        :
                        ''
                      }
                </div>


            </React.Fragment>
        );

    }

    const handleView = (combId) => {
        props.setRedirectTo("/combustibles/" + combId);
    };

    const handleEdit = (combId) => {
        props.setRedirectTo("/combustibles/" + combId + "/edit");
    };
    
    const handleApprove = (combId) => {

        let titleObs = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.observaciones.placeholder', defaultMessage: 'Observaciones' });
        let titleMotive = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla', defaultMessage: 'Motivo para validarla..' });
        let titleRequired = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla_obligatorio', defaultMessage: 'El motivo es obligatorio' });

        let div = document.createElement("div");

        var motivosValidezSelect = document.createElement('select');
        motivosValidezSelect.className = "form-group col-12 Select-control swal-validar-combustibles";
        motivosValidezSelect.id = "confirm-motivoCambioEstado";
        motivosValidezSelect.onchange = function () {
            $(".error-motivo").attr('hidden', true);
        };

        let opcionMotivo = document.createElement('option');
            //opcionMotivo.className = "";
            opcionMotivo.text = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla', defaultMessage: 'Motivo para validarla..' });
            opcionMotivo.value = "";
            opcionMotivo.selected = "selected";
            opcionMotivo.disabled = "disabled";
            opcionMotivo.className = "swal-validar-combustibles";
            motivosValidezSelect.appendChild(opcionMotivo);

            props.motivosValidez.forEach((element)=>{
            let opcionMotivo = document.createElement('option');
            //opcionMotivo.className = "";
            opcionMotivo.value = element.id;
            opcionMotivo.text = element.nombre;
            opcionMotivo.className = "swal-validar-combustibles";
            motivosValidezSelect.appendChild(opcionMotivo);
        });

        var errorMotivo = document.createElement("div");
        errorMotivo.className = "help-block text-danger field-message error-motivo mb-1";
        errorMotivo.innerText = props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.motivo_para_validarla_obligatorio', defaultMessage: 'El motivo es obligatorio' });
        errorMotivo.hidden = true;

        div.append(motivosValidezSelect);
        div.append(errorMotivo);

        var observaciones = document.createElement('textarea');
        observaciones.placeholder= props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.observaciones.placeholder', defaultMessage: 'Observaciones' });
        observaciones.className = "swal-content__textarea";
        observaciones.id = "confirm-observacionesCambioEstado";

        div.append(observaciones);
 
        
        function recursiveCall(index) {
            return new Promise((resolve)=>{
                swal({
                    title: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.information.pasar_valida', defaultMessage: 'Pasar a Válida' }),
                    text: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.confirma_validar_carga', defaultMessage: '¿Confirma que desea validar la carga?' }),
                    icon: "warning",
                    content: div,
                    buttons: {
                        confirm: {
                            text: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.confirma_validar_carga.si', defaultMessage: 'Si' }),
                            value: true,
                            visible: true,
                            className: "btn btn-primary",
                            closeModal: false
                        },
                        cancel: {
                            text: props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.confirma_validar_carga.no', defaultMessage: 'No' }),
                            value: null,
                            visible: true,
                            className: "btn btn-danger",
                            closeModal: true,
                        }
                    }
                }).then((isConfirm) => {
                    if (isConfirm) {
                        if (!$('#confirm-motivoCambioEstado').val()) {
                            swal.stopLoading();
                            $(".error-motivo").attr('hidden', false);

                            return resolve(recursiveCall(++index));
                        } else {
                            ajaxHandler.fetch('/combustibles/'+ combId +'/validar', {
                                method: 'POST',
                                body: JSON.stringify({
                                    observaciones: $('#confirm-observacionesCambioEstado').val(),
                                    motivo_validez_id: $('#confirm-motivoCambioEstado').val()
                                }),
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization-Token': localStorage.getItem("token")
                                }
                            }).then((response) => {
                                if (response.status === 200) {
                                    //se cambia el valor de changeQuery para que se dispare el efecto de actualización de la tabla
                                    props.changeTable();
                                    swal(props.intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.carga_validada', defaultMessage: 'Carga validada.' }), "", "success");
                                }
                            }).catch((error) => {
                                ajaxHandler.handleError(error);
                            });
                        }
                    }
                });//then
            });//promise
        }//recursive function
        recursiveCall(0);
        
    };

    return (
        <React.Fragment>
            <TableRow key={combustible.id}>
                <TableCell classes={{ root: classes.root }}>
                    <Dominio combustible={combustible} intl={intl}></Dominio>
                </TableCell>
                
                <Estado columnData={combustible.estado} motivo={combustible.motivo_validez_forzado} intl={intl}/>
                
                <TableCell align="center" classes={{ root: classes.root }} style={{ minWidth: "165px", padding: "0" }}>
                    <Controles combustible={combustible} intl={intl} litrosCada100km={props.litrosCada100km}/>
                </TableCell>

                <TableCell align="center" classes={{ root: classes.root }}>
                    {(combustible.tarjeta) ?
                        <div>
                            { Timezone.getDateForClient(combustible.fecha_hora_carga, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm') +" "}
                            <div style={{ position: "relative", width: "auto",display: "inline-block" }} data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.carga.con.tarjeta.combustible', defaultMessage: 'Carga realizada con tarjeta de combustible' })} data-placement="top" data-togle="tooltip"
                            className="row-tooltip">
                            <i className="fa fa-credit-card" ></i>
                            </div>
                        </div>
                        :
                        <div>
                            { Timezone.getDateForClient(combustible.fecha_hora_carga, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm') }
                        </div>
                    }

                </TableCell>

                {(columnTarjetaSaldo) && 
                <TableCell align="center" classes={{ root: classes.root }}>
                    {combustible.tarjeta_saldo ? '$ '+parseFloat(combustible.tarjeta_saldo).toFixed(2) : ''}
                </TableCell>
                }

                <TableCell align="center" className="utilizacion" classes={{ root: classes.root }}>
                    <Utilizacion combustible={combustible} intl={intl}></Utilizacion>{/*TODO: Revisar que no le estoy pasando combustible para probar si es necesario*/}
                </TableCell>
                <TableCell align="center" classes={{ root: classes.root }}>
                    <Litros combustible={combustible} intl={intl}></Litros>
                </TableCell>
                <TableCell align="center" classes={{ root: classes.root }}>
                    {(combustible.total) ?
                        "$ " + combustible.total
                        : ""}
                </TableCell>

                <TableCell align="center" classes={{ root: classes.root }} style={{lineHeight:"35px"}}>
                    {(combustible.diferencia_precio_litro <= 0) ?
                        <div className="row" style={{ margin: "auto", flexWrap: "nowrap" }}>
                            $ {combustible.precio_litro}
                            <i className="fa fa-caret-down fa-xs combustibles-precio-alta"></i>
                        </div>
                        :
                        <div className="row" style={{ margin: "auto", flexWrap: "nowrap" }}>
                            $ {combustible.precio_litro} <i className="fa fa-caret-up fa-xs combustibles-precio-baja"></i>
                        </div>
                    }
                </TableCell>
                <TableCell align="center" classes={{ root: classes.root }}>
                    <Direccion combustible={combustible} intl={intl}></Direccion>
                </TableCell>
                <TableCell align="center" classes={{ root: classes.root }}>
                    {combustible.km_litro}
                </TableCell>
                <TableCell align="center" className="text-center costo_km" classes={{ root: classes.root }}>
                    {(combustible.costo_km) ?
                        "$ " + combustible.costo_km
                        : ""}
                </TableCell>
                {(columnConductorInformado) &&
                  <TableCell align="center" className="text-center" classes={{ root: classes.root }}>
                      {combustible.conductor_informado}
                  </TableCell>
                }

                <TableCell align="center" classes={{ root: classes.root }}>
                    <div style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                        {Security.hasPermission('COMBUSTIBLES_VISUALIZAR') ?
                            <button className="action view btn btn-sm btn-icon btn-dt-grid text-success" title={intl.formatMessage({ id: 'combustiblesGrid.column.action.ver.title', defaultMessage: 'Ver' })} data-togle="tooltip" data-placement="top" onClick={() => handleView(combustible.id)}>
                                <i className="fa fa-search fa-xs"></i>
                            </button> : ""}

                        {((combustible.estado === 'SIN_COMPROBACION' || combustible.estado === 'INVALIDA') && Security.hasPermission('COMBUSTIBLES_MODIFICAR')) ?
                            <button className="action edit btn btn-sm btn-icon btn-dt-grid text-success" title={intl.formatMessage({ id: 'combustiblesGrid.column.action.editar.title', defaultMessage: 'Editar' })} data-togle="tooltip" data-placement="top" onClick={() => handleEdit(combustible.id)}>
                                <i className="fa fa-pencil fa-xs"></i>
                            </button> : ''}

                        {((combustible.estado === 'SIN_COMPROBACION' || combustible.estado === 'INVALIDA') && Security.hasPermission('COMBUSTIBLES_VALIDAR')) ?
                            <button className="action approve btn btn-sm btn-icon btn-dt-grid text-success" title={intl.formatMessage({ id: 'combustiblesGrid.column.action.validar.title', defaultMessage: 'Validar' })} data-togle="tooltip" data-placement="top" onClick={() => handleApprove(combustible.id)}>
                                <i className="fa fa-thumbs-up fa-xs"></i>
                            </button> : ''}
                    </div>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
}

const Utilizacion = (props) => {
    const { combustible } = props;

    useLayoutEffect(() => {
        $(".row-tooltip").tooltip();
      }, []);

     let title = props.intl.formatMessage({ id: 'combustiblesGrid.column.utilizacion_full.title.km_recorridos_ultima_carga', defaultMessage: '-Km recorridos desde última carga ' }) + '<br>' + props.intl.formatMessage({ id: 'combustiblesGrid.column.utilizacion_full.title.kilometraje_móvil', defaultMessage: '-Kilometraje del móvil ' }) + parseFloat(combustible.utilizacion).toFixed(2) + ' Km';
    
        return (
            combustible.utilizacion ?
            <div data-original-title={title} data-html="true" data-placement="top" data-html="true" data-togle="tooltip" style={{ position: "relative" }} className="row-tooltip"> {parseFloat(combustible.utilizacion).toFixed(2)} Km
                {(combustible.odometro) ?
                    <div style={{fontSize:"0.8rem"}}> {parseFloat(combustible.odometro).toFixed(2)} Km
                    </div>
                    : null}
            </div>
            : null
        );
       
};

const Litros = (props) => {

    useLayoutEffect(() => {
        $(".row-tooltip").tooltip();
      }, []);

    return (
        <div style={{lineHeight: "16px"}}>
            <div style={{display: "inline-flex"}}>
                <div style={{ position: "relative", width: "auto" }} data-html="true" data-original-title={props.intl.formatMessage({ id: 'combustiblesGrid.litros.informados', defaultMessage: 'Litros Informados' })} data-placement="top" data-togle="tooltip"
                className="row-tooltip">
                    {props.combustible.litros}
                </div>
            </div>
            {props.combustible.combustible_tipo ?
                <div style={{fontSize:"0.8rem"/*, marginTop: "-1.6rem"*/}}>
                    {props.combustible.combustible_tipo}
                </div>
                : null}
        </div>
    );
}

const Direccion = (props) => {

    useLayoutEffect(() => {
        $(".row-tooltip").tooltip();
      }, []);

    let calle = props.combustible.direccion_carga.split(" - ");
    let tooltip = calle.length > 1;

    return (
        <div style={{lineHeight: "16px"}}>
            <div style={{display: "inline-flex"}}>
                {tooltip ?
                <div style={{ position: "relative", width: "auto" }} data-html="true" data-original-title={props.combustible.direccion_carga} data-placement="top" data-togle="tooltip"
                className="row-tooltip">
                    {calle[0]}
                </div>
                : calle[0]}
            </div>
        </div>
    );
}

const Dominio = (props) => {

    useLayoutEffect(() => {
        $(".row-tooltip").tooltip();
      }, []);

    const handleOpenMarcaModelo = (event) => {            
        setAnchorEl(event.currentTarget);        
    };
    
    const handleCloseMarcaModelo = (event) => {            
        setAnchorEl(null);        
    };

    useEffect((component) => {
        if(openPopper)
        {
        }
    },[openPopper]);

    const aHover = () => { 
        setLinkStyle({color: 'white',textDecoration: 'underline', fontSize: '0.875rem', fontWeight: '400'});
    }
    const aLeave = () => {
        setLinkStyle({color: 'white', fontSize: '0.875rem', fontWeight: '400'});
    }

    const [linkStyle, setLinkStyle] = useState({color: 'white', fontSize: '0.875rem', fontWeight: '400'});
    const [anchorEl, setAnchorEl] = useState(null);
    const openPopper = Boolean(anchorEl);
    const idPopper = openPopper ? 'simple-popper' : undefined;
    const [arrowRef, setArrowRef] = useState(null);

    let hrefMarcaModelo = "/modelos/"+props.combustible.modelo_id+"/edit";

    let popperComponent = <Popper 
        id={idPopper} 
        open={openPopper} 
        anchorEl={anchorEl} 
        keepMounted={true} 
        placement={'top-start'}
        >
        <div ref={setArrowRef} style={{padding: "0.4rem 0"}} onMouseLeave={handleCloseMarcaModelo} >
            <div style={{padding: "0.25rem 0.5rem", textAlign:"center", backgroundColor: "#000", borderRadius:"0.25rem"}}>
                <a style={linkStyle} onMouseOver={aHover} onMouseLeave={aLeave} href={hrefMarcaModelo}>{props.combustible.marca} - {props.combustible.modelo}</a>
            </div>
        <i className="fa fa-caret-down fa-xs" style={{fontSize:"1.2rem", marginLeft: "1.5rem", marginTop:"-0.4rem",position: "absolute", color: "#000"}}></i>
        </div>
    </Popper>
    return (
        <div style={{lineHeight: "16px"}} onMouseLeave={handleCloseMarcaModelo}>
            <div style={{display: "inline-flex"}}>
                <div id={arrowRef} data-toggle="popover" data-html="true" data-placement="top" onMouseEnter={handleOpenMarcaModelo} aria-describedby={idPopper}>
                    {props.combustible.dominio}
                </div>                
                {popperComponent}
            </div>
        </div>
    );
}
/*function getData(service, completo = false){
    const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
    let serviceURL = "";

    if (!completo)
        serviceURL = Config.get('apiUrlBase') + service;
    else
        serviceURL = service;

    return fetch(serviceURL, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization-Token": localStorage.getItem("token"),
        },
    }).then((response) => {
        return ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
    console.log("uso get data");
}*/

const CombustiblesGridNew = (props) => {

    const [apiBaseUrl, setApiBaseUrl] = useState(process.env.REACT_APP_VEC_FLEET_API_BASE_URL);
    //this.columnsToPrint = [0, 1, 2, 3, 4, 5, 6, 7];

    const [redirectTo, setRedirectTo] = useState(null);
    const [ajaxHandler, setAjaxHandler] = useState(props.ajaxHandler);
    const dataService = "/combustibles/grid";
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({page: 0, count: 1, perPage: 10});
    const [changePagination, setChangePagination] = useState(false);
    const [switchEstadoVehiculo,setSwitchEstadoVehiculo] = useState(false);
	  const [query, setQuery] = useState({
        page: 0, 
        marca: "", 
        modelo: "", 
        base: "", 
        centroCosto: "", 
        region: "",
        subRegion:"",
        dominio: "",      
        estado: "",
        estadoVehiculo: true,
        movilDetail: props.location.state && props.location.state.filterMovilDetail ? props.location.state.filterMovilDetail : null,
        lugarCarga: "",
        fechaHoraCargaSort: "",
        dominioSort: "",
        litrosSort: "",
        importeSort: "",
        precioLitroSort: "",
        utilizacionSort: "",
        direccionInformadaSort: "",
        litros100Sort: "",
        kmsLitroSort: "",
        costoKmSort: "",
        tarjetaSaldo:"",
        control:"",
        conductorInformadoSort:"",
    });
    const [changeQuery, setChangeQuery] = useState(false);

    moment.locale('es');

    const [combustibles, setCombustibles] = useState([]);
    const [filtroPeriodoInicio, setFiltroPeriodoInicio] = useState(null);//useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [filtroPeriodoFin, setFiltroPeriodoFin] = useState(null);// useState(moment().format('YYYY-MM-DD'));
    
    const periodos = [

        { id: 1, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.hoy.label', defaultMessage: 'Hoy' }) },
        { id: 2, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ayer.label', defaultMessage: 'Ayer' }) },
        { id: 7, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_siete_dias.label', defaultMessage: 'Últimos 7 días' }) },
        { id: 14, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_catorce_dias.label', defaultMessage: 'Últimos 14 días' }) },
        { id: 20, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_treinta_dias.label', defaultMessage: 'Últimos 30 días' }) },
        { id: 30, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimo_mes.label', defaultMessage: 'Este mes' }) },
        { id: 60, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.mes_pasados.label', defaultMessage: 'Mes Pasado' }) },
        { id: 365, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.este_anio.label', defaultMessage: 'Este año' }) },
        { id: 400, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.anio_pasado.label', defaultMessage: 'Año Pasado' }) },
        { id: 0, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.todo.label', defaultMessage: 'Todo' })  },
        { id: 12, label: "" }
    ];
    const [unidad, setUnidad] = useState('day');
    const [estadisticas, setEstadisticas] = useState([]);
    const [invalidas, setInvalidas] = useState([]);
    const [filtersActive, setFiltersActive] = useState(false);
    
    const [dataToExport, setDataToExport] = useState({
        fechaHoraCarga: true,
        movil: true,
        utilizacion: true,
        litros: true,
        importe: true,
        precioLitro: true,
        direccionInformada: true,
        kmLitros: true,
        costoKm: true,
        estado: true,
        base: true,
        centroCostosNombre: true,
        responsable1NombreApellido: true,
        responsable2NombreApellido: true,
        conductor: true,
        conductorInformado: ConfigBusiness.get('combustibles.controlConductor.grilla') === 'true' ? true : false,
        region:true,
        subRegion:true,
        combustibleTipos:true,
        odometro:true,
        rendimiento:true,
        tarjetaSaldo:true,
    });

    const [bases, setBases] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [subRegiones, setSubRegiones] = useState([]);
    const [modelos, setModelos] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [centroCostos, setCentroCostos] = useState([]);
    const estados = [{ value: '', label: 'Todos' }, { value: 'INVALIDA', label: 'Inválidas' }, { value: 'VALIDA', label: 'Válidas' }];
    const [controles, setcontroles] = useState([{ value: 'ubicacion_valida', label: 'Ubicación Válida' }, { value: 'ubicacion_sin_comprobacion', label: 'Ubicación Sin Comprobación' }, { value: 'ubicacion_invalida', label: 'Ubicación Inválida' }, { value: 'tanque_valida', label: 'Tamaño de Tanque Válida' }, { value: 'tanque_sin_comprobacion', label: 'Tamaño de Tanque Sin Comprobación' }, { value: 'tanque_invalida', label: 'Tamaño de Tanque Inválida' },{ value: 'rendimiento_valida', label: 'Rendimiento Válida' }, { value: 'rendimiento_sin_comprobacion', label: 'Rendimiento Sin Comprobación' }, { value: 'rendimiento_invalida', label: 'Rendimiento Inválida' }, { value: 'tarjeta_valida', label: 'Tarjeta Válida' }, { value: 'tarjeta_sin_comprobacion', label: 'Tarjeta Sin Comprobación' }, { value: 'tarjeta_invalida', label: 'Tarjeta Inválida' }]);
     
    const [base, setBase] = useState(null);
    const [centroCosto, setCentroCosto] = useState(null);
    const [region, setRegion] = useState(null);
    const [subRegion, setSubRegion] = useState(null);
    const [modelo, setModelo] = useState(null);
    const [dominio, setDominio] = useState([]);
    const [lugarCarga, setLugarCarga] = useState("");
    const [estado, setEstado] = useState(null);
    const [control, setControl] = useState(null);
    const [movilDetail, setMovilDetail] = useState(props.location.state && props.location.state.filterMovilDetail ?
        props.location.state.filterMovilDetail : null );
    const [estadoVehiculo,setEstadoVehiculo] = useState(true);
    const [columnConductorInformado,setColumnConductorInformado] = useState(false);

    //inicialización del filtro de período: por defecto es 0 - TODO
    var periodoInicial = periodos.find(element => element.id == 0)
    const [periodoSelect, setPeriodoSelect] = useState( periodoInicial );
    let periodo = periodoInicial;

    const [porcentajeLitrosCargados, setPorcentajeLitrosCargados] = useState(0);
    const [litrosControlEntreCargas, setLitrosControlEntreCargas] = useState(0);
    const [toleranciaUbicacion, setToleranciaUbicacion] = useState(0);
    const [modelosLoading, setModelosLoading] = useState(false);
    const [variabilidadRendimientoInferior, setVariabilidadRendimientoInferior] = useState(0);
    const [variabilidadRendimientoSuperior, setVariabilidadRendimientoSuperior] = useState(0);
    const [marca, setMarca] = useState(null);
    const [motivosValidez, setMotivosValidez] = useState([]);
    const [reporteImportacion, setReporteImportacion] = useState(null);
    const [mensajeImportacion, setMensajeImportacion] = useState("");
    const [columnTarjetaSaldo, setColumnTarjetaSaldo] = useState(true);
    const [typeImport, setTypeImport] = useState("");
    const litrosCada100km = ConfigBusiness.get('moviles.rendimientoLitrosCada100km');

    /*handleSelectFormChange = handleSelectFormChange.bind(this);
    handleCloseModal = handleCloseModal.bind(this);
    handleCancelModal = handleCancelModal.bind(this);
    handleMarcaChange = handleMarcaChange.bind(this);
    handleReportCloseModal = handleReportCloseModal.bind(this);
*/
    const textos = [];
    textos['widget_gasto_total'] = props.intl.formatMessage({ id: 'combustiblesGrid.card.widget_gasto_total', defaultMessage: 'Gasto total de combustible' });
    textos['widget_litros_consumidos'] = props.intl.formatMessage({ id: 'combustiblesGrid.card.widget_litros_consumidos', defaultMessage: 'Total de Litros Consumidos' });
    textos['widget_kms_litros'] = props.intl.formatMessage({ id: 'combustiblesGrid.periodos.card.widget_kms_litros', defaultMessage: 'Promedio de Kms por Litro' });
    textos['widget_promedio_costo_litros'] = props.intl.formatMessage({ id: 'combustiblesGrid.card.widget_promedio_costo_litros', defaultMessage: 'Promedio del Costo por Litro' });

    const map = null;
    let marker = null;

    let abortController = new AbortController();
    let signal = abortController.signal;
    //estructura que guarda las cargas que aun no se validaron con el AVL para actualizar su valor en la grilla, si es que se valida mientas se está en la pantalla
    let cargasAControlar = [];
    let stopControles = false;
    const classes = useStyles();

    const getData = (service, completo = false) => {
       
        let serviceURL = "";
        if (!completo){
            serviceURL = Config.get('apiUrlBase') + service;
        }else{
            serviceURL = service;
        }
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}

    useEffect(() => {
        
        //componentDidMount
        if (!Security.hasPermission('COMBUSTIBLES_LISTAR')) {
            setRedirectTo('/error');
        }
        window.scrollTo(0, 0);
        
        setLoading(true);
        
          
        Promise.all([
            getData('/bases/select'),
            getData('/centros-costos/select'),
            getData('/regiones/select'),
            getData('/subregiones/select-all'),
            ConfigBusiness.get('combustibles.porcentajeLitrosCargados'),
            ConfigBusiness.get('combustibles.litrosControlEntreCargas'),
            ConfigBusiness.get('combustibles.toleranciaUbicacion'),
            getData('/marcas/select'),
            ConfigBusiness.get('combustibles.toleranciaRendimientoInferior'),
            ConfigBusiness.get('combustibles.toleranciaRendimientoSuperior'),
            getData('/combustibles-motivos-validez/select'),
            ConfigBusiness.get('combustibles.tarjetaSaldo'),
            ConfigBusiness.get('combustibles.controlConductor.grilla'),            
        ]).then((data) => {

           /* let filters = {
                estado: [{ value: '', label: 'Todos' }, { value: 'INVALIDA', label: 'Inválidas' }, { value: 'VALIDA', label: 'Válidas' }]//, {value: 'SIN_COMPROBACION', label: 'Sin Comprobación'}
            };*/
            
            setBases(data[0] ? data[0] : []);
            setCentroCostos(data[1] ? data[1] : []);
            setRegiones(data[2] ? data[2] : []);
            setSubRegiones(data[3] ? data[3] : []);
            setPorcentajeLitrosCargados(data[4] ? data[4] : 0);
            setLitrosControlEntreCargas(data[5] ? data[5] : 0);
            setToleranciaUbicacion(data[6] ? data[6] : 0);
            setMarcas(data[7] ? data[7] : []);
            setVariabilidadRendimientoInferior(data[8] ? data[8] : 0);
            setVariabilidadRendimientoSuperior(data[9] ? data[9] : 0);
            setMotivosValidez(data[10] ? data[10] : []);
            setColumnTarjetaSaldo((data[11] && data[11] === 'true') ? true : false);
            setColumnConductorInformado((data[12] && data[12] === 'true') ? true : false);
                //si antes de irse a otro componente habia filtros extras se setean para que muestre los datos de caché correctamente
                
                /*TODO: por ahora lo desactivo let extraFilter = JSON.parse(localStorage.getItem('extraFilters' + 'CombustiblesGridNew'));

                if (extraFilter) {
                    if (extraFilter.marca) {
                        handleMarcaChange(extraFilter.marca);
                    }

                    setPeriodoSelect(extraFilter.periodo);
                    setMarca(extraFilter.marca);
                    setModelo(extraFilter.modelo);
                    setBase(extraFilter.base);
                    setCentroCosto(extraFilter.centroCosto);
                    setRegion(extraFilter.region);
                    setFiltroPeriodoInicio(extraFilter.filtroPeriodoInicio);
                    setFiltroPeriodoFin(extraFilter.filtroPeriodoFin);

                }*/
        }).catch(function (error) {
            ajaxHandler.handleError(error);
        }).finally(() => {
            setLoading(false);
        });

        //componentWillUnmount
        return () => {
            stopControles = true;

            abortController.signal.addEventListener('abort', (event) => {

                ajaxHandler.unsubscribe();
            });
            abortController.abort();
            abortController = null;
            cargasAControlar = [];
            signal = null;
            $('div.tooltip[role="tooltip"]').tooltip('dispose');
            $('div.tooltip[role="tooltip"]').remove();
            $('div.popover[role="tooltip"]').remove();
            //if (this.table) this.table.destroy();

            //se guardan los filtros extras seteados para poder recargarlos si se vuelve al componente
            /*TODO: por ahora lo desactivo localStorage.setItem('extraFilters' + 'CombustiblesGridNew', JSON.stringify({
                'periodo': periodoSelect,
                'marca': marca,
                'modelo': modelo,
                'base': base,
                'centroCosto': centroCosto,
                'region': region,
                'filtroPeriodoInicio': filtroPeriodoInicio,
                'filtroPeriodoFin': filtroPeriodoFin,
            }))*/;
        };
    }, []);//fin useEffect

    /**Efecto para carga y recarga de la grilla */
    useEffect(() => {
        setLoading(true);

        let urlService = Config.get('apiUrlBase') + dataService + '/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + dataTableQuery();
        getData(urlService,true).then((data)=>{
                setCombustibles(data.combustibles);
				setPagination(data.pagination);
                
        }).catch(function(error) {
			ajaxHandler.handleError(error);
		}).finally(() => {
			setLoading(false);
		});

    },[filtroPeriodoInicio,filtroPeriodoFin,changePagination,changeQuery]);

    /**Efecto para carga y recarga de widgets */
    useEffect(() => {
        setLoading(true);
            let unidad = calcularUnidadTiempoForWidgets().then((unidad) => {

                let url = new URL(Config.get('apiUrlBase') + '/combustibles/sparkline/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + '/' + unidad);
                let urlInvalidas = new URL(Config.get('apiUrlBase') + '/combustibles/cargas-invalidas/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + '/' + unidad);


                if (query.base) {
                    url.searchParams.append('bases[0][value]', query.base);
                    urlInvalidas.searchParams.append('bases[0][value]', query.base);
                } else {
                    url.searchParams.append('bases', '');
                    urlInvalidas.searchParams.append('bases', '');
                }

                if (query.region){
                    url.searchParams.append('regiones[0][value]', query.region);
                    urlInvalidas.searchParams.append('regiones[0][value]', query.region);
                }else {
                    url.searchParams.append('regiones', '');
                    urlInvalidas.searchParams.append('regiones', '');
                }

                if (query.subRegion){
                    url.searchParams.append('subRegiones[0][value]', query.subRegion);
                    urlInvalidas.searchParams.append('subRegiones[0][value]', query.subRegion);
                }else {
                    url.searchParams.append('subRegiones', '');
                    urlInvalidas.searchParams.append('subRegiones', '');
                }

                if (query.centroCosto){
                    url.searchParams.append('centroCostos[0][value]', query.centroCosto);
                    urlInvalidas.searchParams.append('centroCostos[0][value]', query.centroCosto);
                }else {
                    url.searchParams.append('centroCostos', '');
                    urlInvalidas.searchParams.append('centroCostos', '');
                }

                if(query.dominio){
                    url.searchParams.append('dominio', query.dominio);
                    urlInvalidas.searchParams.append('dominio', query.dominio);
                } else {
                    url.searchParams.append('dominio', '');
                    urlInvalidas.searchParams.append('dominio', '');
                }

                if(query.estado){
                    url.searchParams.append('estado', query.estado);
                    urlInvalidas.searchParams.append('estado', query.estado);
                } else {
                    url.searchParams.append('estado', '');
                    urlInvalidas.searchParams.append('estado', '');
                }

                if (query.control){
                    url.searchParams.append('controles[0][value]', query.control);
                    urlInvalidas.searchParams.append('controles[0][value]', query.control);
                }else {
                    url.searchParams.append('controles', '');
                    urlInvalidas.searchParams.append('controles', '');
                }

                if (query.modelo) {
                    url.searchParams.append('modelos[0][value]', query.modelo);
                    urlInvalidas.searchParams.append('modelos[0][value]', query.modelo);

                } else {
                    url.searchParams.append('modelos', '');
                    urlInvalidas.searchParams.append('modelos', '');
                }

                if (query.marca) {
                    let index = 0;
                    url.searchParams.append('marcas[value]', query.marca);
                    urlInvalidas.searchParams.append('marcas[value]', query.marca);
                } else {
                    url.searchParams.append('marcas', '');
                    urlInvalidas.searchParams.append('marcas', '');
                }

                if (query.estadoVehiculo !== null) {
                    url.searchParams.append('estadoVehiculo', query.estadoVehiculo);
                    urlInvalidas.searchParams.append('estadoVehiculo', query.estadoVehiculo);
                } else {
                    url.searchParams.append('estadoVehiculo', '');
                    urlInvalidas.searchParams.append('estadoVehiculo', '');
                }

                if (query.lugarCarga !== null) {
                    url.searchParams.append('lugarCarga', query.lugarCarga);
                    urlInvalidas.searchParams.append('lugarCarga', query.lugarCarga);
                } else {
                    url.searchParams.append('lugarCarga', '');
                    urlInvalidas.searchParams.append('lugarCarga', '');
                }

                if (query.movilDetail) {
                    let index = 0;
                    url.searchParams.append('extraFilterMovilDetail', query.movilDetail.movil);
                    urlInvalidas.searchParams.append('extraFilterMovilDetail', query.movilDetail.movil);
                } else {
                    url.searchParams.append('extraFilterMovilDetail', '');
                    urlInvalidas.searchParams.append('extraFilterMovilDetail', '');
                }

                Promise.all([
                    getData(url, true),
                    getData(urlInvalidas, true)

                ]).then((data) => {
                    setEstadisticas(data[0]);
                    setInvalidas(data[1]);
                    //resolve();//TODO: esto lo hacía en el then del setState
                }).catch(function(error) {
                    ajaxHandler.handleError(error);
                }).finally(() => {
                    setLoading(false);
                });

            });
        },[filtroPeriodoInicio,filtroPeriodoFin,changeQuery]);

	const dataTableQuery = () => {

		let myQuery = '?page=' + pagination.page;
		myQuery += '&perPage=' + pagination.perPage;

		myQuery += query.base ? '&bases[0][value]=' + query.base : '&bases=';
        myQuery += query.centroCosto ? '&centroCostos[0][value]=' + query.centroCosto : '&centroCostos=';
        myQuery += query.region ? '&regiones[0][value]=' + query.region : '&regiones=';
        myQuery += query.subRegion ? '&subRegiones[0][value]=' + query.subRegion : '&subRegiones=';
        myQuery += query.modelo ? '&modelos[0][value]=' + query.modelo : '&modelos=';
        myQuery += query.marca ? '&marcas[0][value]=' + query.marca : '&marcas=';
        myQuery += query.estadoVehiculo ? '&estadoVehiculo=' + query.estadoVehiculo : '&estadoVehiculo=';
        myQuery += query.dominio ? '&dominio=' + query.dominio : '&dominio=';
        myQuery += query.estado ? '&estado=' + query.estado : '&estado=';
        myQuery += query.control ? '&controles[0][value]=' + query.control : '&controles=';
        myQuery += query.movilDetail ? '&extraFilterMovilDetail=' + query.movilDetail.movil : '&extraFilterMovilDetail=';
        myQuery += query.lugarCarga ? '&lugarCarga=' + query.lugarCarga : '&lugarCarga=';
        myQuery += '&fechaHoraCargaSort=' + query.fechaHoraCargaSort;
        myQuery += '&dominioSort=' + query.dominioSort;
        myQuery += '&litrosSort=' + query.litrosSort;
        myQuery += '&importeSort=' + query.importeSort;
        myQuery += '&precioLitroSort=' + query.precioLitroSort;
        myQuery += '&utilizacionSort=' + query.utilizacionSort;
        myQuery += '&direccionInformadaSort=' + query.direccionInformadaSort; 
        myQuery += '&litros100Sort=' + query.litros100Sort;
        myQuery += '&kmsLitroSort=' + query.kmsLitroSort;
        myQuery += '&costoKmSort=' + query.costoKmSort;
        myQuery += '&tarjetaSaldo=' + query.tarjetaSaldo;
        myQuery += '&conductorInformadoSort=' + query.conductorInformadoSort;
        
        return myQuery;
        								
	}

	const dataTableUpdate = () => {
        
		let urlService = Config.get('apiUrlBase') + dataService + '/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + dataTableQuery();
		
		fetch(urlService, {
			method: 'GET',
			headers: {
				'Authorization-Token': localStorage.getItem("token")
			}
		}).then(
			response => response.json()
		).then(
			data => {
				let comb = data.combustibles;
				setCombustibles(comb);
				let pagination = data.pagination;
				setPagination(pagination);
			}
		);
    }
    
    const handleChangePage = (event, newPage) => {
        pagination.page = newPage;
        setChangePagination(!changePagination);
	};

	const handleChangeRowsPerPage = (event) => {
		pagination.perPage = parseInt(event.target.value, 10);
		pagination.page = 0;
        setChangePagination(!changePagination);
	};

    function calcularUnidadTiempoForWidgets() {

        return new Promise((resolve) => {
            let diferencia = (filtroPeriodoFin && filtroPeriodoInicio) ? moment(filtroPeriodoFin).diff(moment(filtroPeriodoInicio), "days") : 366; //se setea un valor para que entre en el último else
            let unidad = "";
            if (diferencia <= 14) {
                unidad = null;
            } else if (diferencia > 14 && diferencia <= 63) {
                unidad = "week";
            } else if (diferencia > 63 && diferencia <= 365) {
                unidad = "month";
            } else {
                unidad = "year";
            }

            setUnidad(unidad);//.then(()=>{ resolve(unidad); });
            resolve(unidad);
        });
    }

    function handleEstadoVehiculo (value){
      if(value){
        let data = {
          value : false,
        }
        handleSelectFormChange('estadoVehiculo',data)
      }
      else{
        let data = {
          value : true,
        }
        handleSelectFormChange('estadoVehiculo',data)
      }

      setSwitchEstadoVehiculo(value)
    }

    function handleSelectFormChange(name, object) {
        pagination.page = 0;
        setMovilDetail(null);
        query['movilDetail'] = "";
        // const value = object === null ? null : object.id;
        if (name === "periodo") {
            setPeriodoSelect(object);
            periodo = object;
            let inicio = "";
            let fin = "";
            if (object && object.id !== 12) {

                switch (object.id) {
                    case 1:
                        inicio = moment().format('YYYY-MM-DD');
                        fin = moment().format('YYYY-MM-DD');
                        break;
                    case 2:
                        inicio = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        fin = inicio;
                        break;
                    case 7:
                        inicio = moment().subtract(7, 'days').format('YYYY-MM-DD');
                        fin = moment().format('YYYY-MM-DD');
                        break;
                    case 14:
                        inicio = moment().subtract(14, 'days').format('YYYY-MM-DD');
                        fin = moment().format('YYYY-MM-DD');
                        break;
                    case 20:
                        inicio = moment().subtract(30, 'days').format('YYYY-MM-DD');
                        fin = moment().format('YYYY-MM-DD');
                        break;
                    case 30:
                        inicio = moment().startOf('month').format('YYYY-MM-DD');
                        fin = moment().format('YYYY-MM-DD');
                        break;
                    case 60:
                        inicio = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                        fin = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                        break;
                    case 400:
                        inicio = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
                        fin = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
                        break;
                    case 0:
                        inicio = null;
                        fin = null;
                        break;
                    default:
                        inicio = moment().startOf('year').format('YYYY-MM-DD');
                        fin = moment().format('YYYY-MM-DD');
                }

            } else if (object.inicio) {
                inicio = moment(object.inicio).format('YYYY-MM-DD');
                fin = moment(object.fin).format('YYYY-MM-DD');
            }
            setFiltroPeriodoInicio(inicio);
            setFiltroPeriodoFin(fin);

        } else {
            //ocurre cuando se aplica cualquier filtro distinto a periodo
            let valor;
           /* if (name == "modelo" || name == "marca") {
                valor = object ? [object] : null;
            } else {
                valor = object
            }*/
            valor = object ? object : null;
            
            switch (name) {
                case 'marca':
                    setMarca(valor); 
                    handleMarcaChange(valor);
                    break;
                case 'modelo':
                    setModelo(valor); break;
                case 'region': 
                    setRegion(valor);break;
                case 'subRegion': 
                    setSubRegion(valor);break;
                case 'centroCosto': 
                    setCentroCosto(valor); break;
                case 'base': 
                    setBase(valor); break;
                case 'estado':
                    setEstado(valor); break;
                case 'estadoVehiculo':
                    setEstadoVehiculo(valor.value);break;                
                case 'control': 
                    setControl(valor);break;
            }
            query[name] = [];

            if(name==='base' || name==='centroCosto' || name==='region' || name==='subRegion' || name==='control')
            {
              valor.length ? valor.forEach((val)=>query[name].push(val.value)) : query[name]=null
            }
            else
            {
              query[name]= valor ? valor.value : null;
            }
            
            setQuery(query);
            setChangeQuery(!changeQuery);
        }
        //se marca que hay filtros activos a menos que se haya seleccionado el período "TODO"
        checkFiltersActive();
        setPagination(pagination);

    }

    function handleInputFormChange(name, object){
        pagination.page = 0;        
        if(name === "dominio")
            setDominio(object.target.value);

        if(name === "lugarCarga")
            setLugarCarga(object.target.value);

        query[name] = [];
        query[name]=object.target.value || null;
        setQuery(query);
        setChangeQuery(!changeQuery);

        checkFiltersActive();
        setPagination(pagination);
        
    }

    function checkFiltersActive(){
        if(query.base || query.centroCosto || query.region || query.subRegion || query.modelo || query.marca || query.dominio || query.lugarCarga
            || query.estado || periodo.id != 0 || !query.estadoVehiculo || query.control ){
            setFiltersActive(true);
        } else {
            setFiltersActive(false);
        }
    }

    function handleMarcaChange(object) {

        return new Promise((resolve, reject) => {
            if (object) {

                setModelo(null);
                setModelos([]);

                //setModelosLoading(true);
                ajaxHandler.getJson('/modelos/marca/' + object.value + '/select')
                    .then((data) => {
                        setModelos(data);
                        resolve();
                    }).finally(() => {
                        //setModelosLoading(false);
                    });
        
            } else {
                setModelo(null);
                resolve();
            }
        });
    }

    function handleCancelModal(message, subtitle, error) {
        $("#import_modal").modal('toggle');
    }

    function handleCloseModal(message, filasFallaron, error) {
        $("#import_modal").modal('toggle');

        if (message !== "" && error) {
            if (error.codigo === 4010) {
                swal(props.intl.formatMessage({ id: 'combustiblesGrid.alert.sesion_expiro', defaultMessage: 'La sesión expiró' }), props.intl.formatMessage({ id: 'combustiblesGrid.alert.ingrese_nuevamente', defaultMessage: 'Por favor, ingrese nuevamente.' }), "error").then(() => {
                    window.location.reload();
                });
            } else if (error.codigo) {
                swal(props.intl.formatMessage({ id: 'combustiblesGrid.alert.error', defaultMessage: 'Error' }), message, "error");
            } else {
                swal(message, error, "error");
            }
        } else {
            //{"filas_fallaron":{"1":[{"id":"4","fila":"6","dominio":"AA008FU","motivo_falla":"Carga duplicada","created_at":"2020-08-25 16:54:43","updated_at":"2020-08-25 16:54:43","combustibles_archivo_importacion_id":"3"},{"id":"5","fila":"7","dominio":"AA008FU","motivo_falla":"Carga duplicada","created_at":"2020-08-25 16:54:43","updated_at":"2020-08-25 16:54:43","combustibles_archivo_importacion_id":"3"},{"id":"6","fila":"8","dominio":"AA008FR","motivo_falla":"No se encontr\u00f3 el m\u00f3vil","created_at":"2020-08-25 16:54:43","updated_at":"2020-08-25 16:54:43","combustibles_archivo_importacion_id":"3"}]}}

            if (filasFallaron.filas_fallaron.length == 0) {
                swal(message, "", "success").then((data) => {
                    reloadTable();
                });
            } else {
                setReporteImportacion(filasFallaron.filas_fallaron);
                
                setMensajeImportacion(message);
                $('#import_report_modal').modal({ backdrop: 'static' });
            }


        }
    }

    function handleReportCloseModal() {
        $("#import_report_modal").modal('toggle');
        reloadTable();
    }

    function handleInvalidas(estado) {
        setEstado({ value: 'INVALIDA', label: 'Inválidas' });
        query['estado'] = estado;
        setQuery(query);
        setChangeQuery(!changeQuery);
    }

    /*function cargarDatosFiltrados() {
        return new Promise((resolve, reject) => {
           /* console.log("ajax combustibles",Config.get('apiUrlBase') + dataService + '/' + filtroPeriodoInicio + '/' + filtroPeriodoFin);
            Promise.all([
            getData(Config.get('apiUrlBase') + dataService + '/' + filtroPeriodoInicio + '/' + filtroPeriodoFin,true)
            ]).then((data)=>{
                console.log("rta combustibles",data);
                setCombustibles(data[0] ? data[0] : []);
            })*/

            /*let unidad = calcularUnidadTiempoForWidgets().then((unidad) => {

                let url = new URL(Config.get('apiUrlBase') + '/combustibles/sparkline/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + '/' + unidad);
                let urlInvalidas = new URL(Config.get('apiUrlBase') + '/combustibles/cargas-invalidas/' + filtroPeriodoInicio + '/' + filtroPeriodoFin + '/' + unidad);


                if (query.base) {
                   /* base.forEach((element, index) => {
                        url.searchParams.append('bases[' + index + '][value]', element.value);
                       // url.searchParams.append('bases[' + index + '][label]', element.label);
                        urlInvalidas.searchParams.append('bases[' + index + '][value]', element.value);
                        //urlInvalidas.searchParams.append('bases[' + index + '][label]', element.label);
                    });*/
                   /* url.searchParams.append('bases[0][value]', query.base);
                    urlInvalidas.searchParams.append('bases[0][value]', query.base);
                } else {
                    url.searchParams.append('bases', '');
                    urlInvalidas.searchParams.append('bases', '');
                }

                if (query.region){
                    /*region.forEach((element, index) => {
                        url.searchParams.append('regiones[' + index + '][value]', element.value);
                        //url.searchParams.append('regiones[' + index + '][label]', element.label);
                        urlInvalidas.searchParams.append('regiones[' + index + '][value]', element.value);
                        //urlInvalidas.searchParams.append('regiones[' + index + '][label]', element.label);
                    });*/
                  /*  url.searchParams.append('regiones[0][value]', query.region);
                    urlInvalidas.searchParams.append('regiones[0][value]', query.region);
                }else {
                    url.searchParams.append('regiones', '');
                    urlInvalidas.searchParams.append('regiones', '');
                }

                if (query.centroCosto){
                   /* centroCosto.forEach((element, index) => {
                        url.searchParams.append('centroCostos[' + index + '][value]', element.value);
                        //url.searchParams.append('centroCostos[' + index + '][label]', element.label);
                        urlInvalidas.searchParams.append('centroCostos[' + index + '][value]', element.value);
                        //urlInvalidas.searchParams.append('centroCostos[' + index + '][label]', element.label);
                    });*/
                  /*  url.searchParams.append('centroCostos[0][value]', query.centroCosto);
                    urlInvalidas.searchParams.append('centroCostos[0][value]', query.centroCosto);
                }else {
                    url.searchParams.append('centroCostos', '');
                    urlInvalidas.searchParams.append('centroCostos', '');
                }

                if(query.dominio){
                    url.searchParams.append('dominio', query.dominio);
                    urlInvalidas.searchParams.append('dominio', query.dominio);
                } else {
                    url.searchParams.append('dominio', '');
                    urlInvalidas.searchParams.append('dominio', '');
                }

                if (query.modelo) {
                    /*let index = 0;
                    url.searchParams.append('modelos[' + index + '][value]', modelo[0].value);
                    //url.searchParams.append('modelos[' + index + '][label]', modelo[0].label);
                    urlInvalidas.searchParams.append('modelos[' + index + '][value]', modelo[0].value);
                    //urlInvalidas.searchParams.append('modelos[' + index + '][label]', modelo[0].label);
*/
                   /* url.searchParams.append('modelos[0][value]', query.modelo);
                    //url.searchParams.append('modelos[' + index + '][label]', modelo[0].label);
                    urlInvalidas.searchParams.append('modelos[0][value]', query.modelo);

                } else {
                    url.searchParams.append('modelos', '');
                    urlInvalidas.searchParams.append('modelos', '');
                }

                if (query.marca) {
                    let index = 0;
                    url.searchParams.append('marcas[value]', query.marca);
                    //url.searchParams.append('marcas[label]', marca.label);
                    urlInvalidas.searchParams.append('marcas[value]', query.marca);
                    //urlInvalidas.searchParams.append('marcas[label]', marca.label);
                } else {
                    url.searchParams.append('marcas', '');
                    urlInvalidas.searchParams.append('marcas', '');
                }
                //let params = {bases: base ? base.forEac : '', regiones: region ? region : '', centroCostos: centroCosto ? centroCosto : ''};
                //Object.keys(params).forEach(key => )

                Promise.all([
                    getData(url, true),
                    getData(urlInvalidas, true)

                ]).then((data) => {
                    setEstadisticas(data[0]);
                    setInvalidas(data[1]);
                    resolve();//TODO: esto lo hacía en el then del setState
                }).catch(function(error) {
                    ajaxHandler.handleError(error);
                }).finally(() => {
                    setLoading(false);
                });

            });
        });
    }*/

    const handleDataExport = (event, name) => {
        let dataToExportCopy = JSON.parse(JSON.stringify(dataToExport));
        dataToExportCopy[name] = !dataToExportCopy[name];
        setDataToExport(dataToExportCopy);
      };

    function handleExportExcel() {

        setLoading(true);
        ajaxHandler.fetch('/combustibles/exportar-excel', {
            method: 'POST',
            body: JSON.stringify({
                colsAExportar: dataToExport,
                fechaInicio: Timezone.getDateForServer(filtroPeriodoInicio),
                fechaFin: Timezone.getDateForServer(filtroPeriodoFin),
                regiones: query.region ? [{'value': query.region.toString()}] : null,
                subRegiones: query.subRegion ? [{'value': query.subRegion.toString()}] : null,
                centroCostos: query.centroCosto ? [{'value': query.centroCosto.toString()}] : null,
                bases: query.base ? [{'value': query.base.toString()}] : null,
                modelos: query.modelo ? [{'value': query.modelo}] : null,
                marcas: query.marca ? {'value': query.marca} : null,
                dominio: query.dominio ? query.dominio : null,
                estado: query.estado ? query.estado : null,
                lugarCarga: query.lugarCarga ? query.lugarCarga : null,
                estadoVehiculo: query.estadoVehiculo ? query.estadoVehiculo : null,
                controles: query.control ? [{'value': query.control.toString()}] : null
            })
        }).then(response => {
            if (response.status === 200) {
                return response.blob();
            }
        }).then(fileBlob => {
            let fileUrl = URL.createObjectURL(fileBlob);
            $("<a />", {
                href: fileUrl,
                download: "fleet-combustibles.xlsx"
            }).appendTo("body").get(0).click();
        }).catch(() => {
        }).finally(() => {
            handleExportCancel();
            setLoading(false);
        });
    }

    function handleExportCancel() {
        $('#export_modal').modal('toggle');
    }

   /*
        //cuando termina de recorrer el rowCallback se llama a la función que recorrerá las cargas pendientes de validación
        component.controlCargasPendientesAVL();
    },*/
    //TODO:
    /*rowCallback: function (row, data) {
        let navigator = window.navigator;
        let habilitarControles = false;
        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 || navigator.userAgent.indexOf("Chrome") != -1
            || navigator.userAgent.indexOf("Safari") != -1 || navigator.userAgent.indexOf("Firefox") != -1) {
            habilitarControles = true;
                //si el navegador no es IE y la fila de carga no está validada se apila para controlar si hubo cambios
            if(data.valido_direccion_avl == 0){
                component.cargasAControlar.push({"row":row, "data":data});
            }
        }
        //IE la función para abortar fetch no está soportada por internet explorer (navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )
    }
}, datatablesConfig)
);*/

    //TODO:
    /*new $.fn.dataTable.Buttons(this.table, {
        buttons: [
            {
                name: 'filterReset',
                text: '<img id="filterReset" className="filter-reset" src="' + LimpiarFiltros + '"></i>',
                titleAttr: 'Restaurar filtros y ordenamiento',
                action: function (e, dt, node, config) {
                    var index = 0;

                    component.setState({ loading: true });

                    component.setState({
                        centroCosto: null,
                        base: null,
                        filtroPeriodoInicio: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        filtroPeriodoFin: moment().format('YYYY-MM-DD'),
                        region: null,
                        periodoSelect: { id: 20, label: "Últimos 30 días" },
                        marca:null,
                        modelo:null
                    });
                    component.table.columns().every(function () {
                        var column = this;

                        if (dt.settings()[0].aoColumns[index].filterType) {
                            column.search('', true, false);
                        }
                        index++;
                        return null;
                    });
                    $(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
                        $(element).val('');
                    });

                    dt.table().order([3, 'desc']);
                    component.cargarDatosFiltrados().then(() => {
                        component.setState({ loading: false });
                    });
                }
            },
            {
                extend: 'print',
                text: '<i className="ft-printer"></i>',
                title: '',
                customize: function (win) {
                    var css = '@page { size: landscape; }',
                        head = win.document.head || win.document.getElementsByTagName('head')[0],
                        style = win.document.createElement('style');

                    style.type = 'text/css';
                    style.media = 'print';

                    if (style.styleSheet) {
                        style.styleSheet.cssText = css;
                    } else {
                        style.appendChild(win.document.createTextNode(css));
                    }

                    head.appendChild(style);

                    $(win.document.body)
                        .css('font-size', '10pt')
                        .prepend(`<div className="container">
                                            <div className="row mb-2">
                                                <div className="col-6">
                                                    <img style="height: 35px" src="`+ LogoCompany + `" />
                                                </div>
                                                <div className="col-6	text-right">
                                                    <img style="height: 40px" src="`+ LogoVecFleet + `" />
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-12" style="border-bottom: 1px solid #000;">
                                                    <h2>`+component.props.intl.formatMessage({ id: 'combustiblesGrid.tools.print.content.title.listado_de_cargas', defaultMessage: 'Listado de Cargas de Combustible' })+`</h2>
                                                </div>
                                            </div>
                                        </div>`);

                    $(win.document.body).find('table')
                        .addClass('compact')
                        .css('font-size', 'inherit');
                },
                exportOptions: {
                    columns: component.columnsToPrint
                },

                titleAttr: props.intl.formatMessage({ id: 'combustiblesGrid.tools.print.title.imprimir', defaultMessage: 'Imprimir' })
            }, {
                extend: 'colvis',
                name: 'columns',
                text: '<i className="la la-columns"></i>',
                titleAttr: props.intl.formatMessage({ id: 'combustiblesGrid.tools.filter.select_columns.seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
            }, {
                name: 'exportExcel',
                text: '<i className="ft-upload"></i>',
                titleAttr: props.intl.formatMessage({ id: 'combustiblesGrid.tools.filter.export_excel.exportar_excel', defaultMessage: 'Exportar a Excel' }),
                action: function (e, dt, node, config) {
                    component.setState({
                        exportFechaDesde: moment().startOf('year'),
                        exportFechaHasta: moment()
                    }, () => {
                        $('#export_modal').modal({ backdrop: 'static' });
                    });
                }
            },
            {
                text: '<i className="ft-download"></i>',
                titleAttr: 'Importar',
                action: function (e, dt, node, config) {
                    $('#import_modal').modal({ backdrop: 'static' });
                }
            }]
    });

    this.table.buttons(0, null).container().appendTo('#buttons');

    $('#dataTable tfoot th').each(function () {
        var title = $(this).text();
        if (title !== '') $(this).html('<input type="text" className="btn-dt-filter" placeholder="Buscar ..." />');
    });

    this.table.columns([3, 4, 5]).every(function () {
        var that = this;
        $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this.value) {
                that
                    .search(this.value)
                    .draw()
                    .ajax.reload(null, false);
            }
        });
        return null;
    });

    $('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
        if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
            $('.dt-button-collection>.dropdown-menu').css('display','block');
    });
}).catch(function (error) {
    component.ajaxHandler.handleError(error);
}).finally(() => {
    component.setState({
        loading: false
    });
});
});*/

    //TODO:
    /*	$('html').on('click', function (e) {
                if (typeof $(e.target).data('original-title') == 'undefined' &&
                    !$(e.target).parents().is('.popover')) {
                    $('[data-original-title]').popover('hide');
                }
        });
    }*/

    function handleNew(event) {
        setRedirectTo(props.match.url + '/add');
    }

    const controlCargasPendientesAVL = (async () => {

        while (this.cargasAControlar.length > 0 && !this.stopControles) {
            for (let element in this.cargasAControlar) {
                let row = this.cargasAControlar[element].row;
                let data = this.cargasAControlar[element].data;

                await this.ajaxHandler.getJsonOptions('/combustibles/controles-carga/' + data.id, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization-Token': localStorage.getItem("token")
                    },
                    signal: this.signal,

                })
                    .then((result, data) => {
                        if (result.valido_direccion_avl == 1) {

                            $(".km_litro", row).text(result['km_litro']);
                            if (result['costo_km']) {
                                $(".costo_km", row).text("$ " + result['costo_km']);
                            } else {
                                $(".costo_km", row).text("");
                            }

                            let ulilizacionText = this.getUtilizacion(result);
                            $(".utilizacion", row).html(ulilizacionText);
                            if (result['utilizacion']) {
                                let ulilizacionText = this.getUtilizacion(result);
                                $(".utilizacion", row).html(ulilizacionText);
                                $(".utilizacion>div", row).tooltip("enable");
                            }
                            else {
                                $(".utilizacion>div", row).tooltip("disable");
                                $(".utilizacion", row).text("");
                            }

                            let title = result.estado === 'SIN_COMPROBACION' ? props.intl.formatMessage({ id: 'combustiblesGrid.column.createdCell.sin_comprobacion', defaultMessage: 'Sin Comprobación' }) : result.estado;

                            if (result.motivo_validez_forzado) {
                                title += " | " + result.motivo_validez_forzado;
                            }

                            $(".embudo", row).attr('data-original-title', title);
                            $(".embudo", row).tooltip();

                            if (result.estado == 'INVALIDA') {
                                $(".embudo", row).addClass('status-red');
                                $(".embudo", row).removeClass('status-green');
                                $(".embudo", row).removeClass('status-yellow');
                            } else if (result.estado == 'VALIDA') {
                                $(".embudo", row).addClass('status-green');
                                $(".embudo", row).removeClass('status-red');
                                $(".embudo", row).removeClass('status-yellow');
                            } else {
                                $(".embudo", row).addClass('status-yellow');
                                $(".embudo", row).removeClass('status-green');
                                $(".embudo", row).removeClass('status-red');
                            }

                            /* POSITION */
                            if (result['direccion_coincide'] === null) {
                                $(".div-position", row).tooltip("enable");
                                $("div .fa-map-marker", row).css("cursor", "auto");

                                if (result['razon_invalidez'] == 'GPS sin posición en el momento de la carga') {
                                    $(".div-position", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_momento_carga', defaultMessage: 'GPS sin posición en el momento de la carga' }));
                                    $(".div-position i", row).removeClass("combustibles-carga-gris");
                                    $(".div-position i", row).addClass("combustibles-tooltip-amarillo");
                                }
                                else {
                                    $(".div-position", row).attr("data-original-title", !result['latitud_informada'] ? props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_direccion_informada', defaultMessage: 'Sin coordenadas de dirección informada' }) : props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.sin_posicion_satelital', defaultMessage: 'Sin Posición de Disp. Satelital' }));
                                    $(".div-position i", row).removeClass("combustibles-carga-amarillo");
                                    $(".div-position i", row).addClass("combustibles-tooltip-gris");
                                }
                                $(".div-position i", row).removeClass("combustibles-carga-rojo");
                                $(".div-position i", row).removeClass("combustibles-carga-verde");
                                $(".fa-map-marker[data-toggle='popover']", row).popover('dispose');

                            } else {
                                let metros = parseFloat(result['direccion_coincide']);
                                $("div .fa-map-marker", row).css("cursor", "pointer");
                                $(".div-position[data-toggle='tooltip']", row).tooltip("disable");

                                $(".fa-map-marker", row).attr("data-toggle", "popover");
                                $(".fa-map-marker", row).attr("data-html", true);
                                $(".fa-map-marker", row).attr("data-placement", "right");
                                if (metros > parseFloat(toleranciaUbicacion)) {
                                    $(".fa-map-marker", row).removeClass("combustibles-tooltip-gris");
                                    $(".fa-map-marker", row).removeClass("combustibles-tooltip-amarillo");
                                    $(".fa-map-marker", row).removeClass("combustibles-carga-verde");
                                    $(".fa-map-marker", row).addClass("combustibles-carga-rojo");

                                    $(".fa-map-marker", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.direccion_informada', defaultMessage: 'Dirección informada a ' }) + (metros > 1000 ? (metros / 1000).toFixed(2) + ' kilómetros' : metros.toFixed(2) + ' metros') + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.gps.posicion_obtenida_avl', defaultMessage: ' de la posición obtenida en el AVL.' }));

                                } else {

                                    $(".fa-map-marker", row).attr("data-original-title", (result['bandera'] ? result['bandera'] : '') + 'Dir: ' + result['direccion_carga']);
                                    $(".fa-map-marker", row).removeClass("combustibles-carga-rojo");
                                    $(".fa-map-marker", row).removeClass("combustibles-tooltip-gris");
                                    $(".fa-map-marker", row).removeClass("combustibles-tooltip-amarillo");
                                    $(".fa-map-marker", row).addClass("combustibles-carga-verde");
                                }
                                $(".fa-map-marker", row).on('inserted.bs.popover', function () {
                                    if (parseFloat(result['direccion_coincide']) > 100) {
                                        this.initMap(result['id'], { latitudInformada: parseFloat(result['latitud_informada']), longitudInformada: parseFloat(result['longitud_informada']) }, { latitudDetectada: parseFloat(result['latitud_detectada_avl']), longitudDetectada: parseFloat(result['longitud_detectada_avl']) });
                                    } else {
                                        this.initMap(result['id'], { latitudInformada: parseFloat(result['latitud_informada']), longitudInformada: parseFloat(result['longitud_informada']) });
                                    }
                                });
                                $(".fa-map-marker", row).popover({ content: '<div id="map' + result["id"] + '" className="map-popover"></div>' }).addClass('popover-combustibles');

                            }

                            /* DIFERENCIA CAPACIDAD DE TANQUE */
                            if (result['capacidad_tanque']) {
                                if (parseFloat(result['capacidad_tanque']) >= result['litros']) {
                                    $(".div-diferencia-tanque", row).attr("src", TanqueVerde);
                                    $(".div-diferencia-tanque", row).removeAttr("data-togle");
                                    $(".div-diferencia-tanque", row).removeAttr("data-placement");
                                    $(".div-diferencia-tanque", row).removeAttr("data-original-title");
                                    $(".div-diferencia-tanque[data-toggle='tooltip']", row).tooltip("disable");
                                } else {
                                    $(".div-diferencia-tanque", row).attr("src", TanqueRojo);
                                    $(".div-diferencia-tanque", row).attr("data-togle", "tooltip");
                                    $(".div-diferencia-tanque", row).attr("data-placement", "top");
                                    $(".div-diferencia-tanque", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.litros_cargados_mayor_capacidad_tanque', defaultMessage: 'Litros Cargados mayor a la capacidad del tanque' }));
                                    $(".div-diferencia-tanque", row).tooltip("enable");
                                }
                            } else {
                                $(".div-diferencia-tanque", row).attr("src", TanqueGris);
                                $(".div-diferencia-tanque", row).attr("data-togle", "tooltip");
                                $(".div-diferencia-tanque", row).attr("data-placement", "top");
                                $(".div-diferencia-tanque", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.no_datos_capacidad_tanque', defaultMessage: 'No hay datos de la capacidad del tanque' }));
                                $(".div-diferencia-tanque", row).tooltip("enable");
                            }

                            if (result['dif_litros_avl'] != null && result['litros_despues_carga'] != null && result['litros_despues_carga_anterior'] != null && result['litros'] != null) {

                                let litrosInformados = parseFloat(result['litros_despues_carga_anterior']) - parseFloat(result['dif_litros_avl']) + parseFloat(result['litros']);
                                let dif = Math.abs(litrosInformados - parseFloat(result['litros_despues_carga']));

                                if (dif <= parseFloat(litrosControlEntreCargas)) {
                                    $(".div-carga", row).attr("src", MangueraVerde);
                                    $(".div-carga", row).removeAttr("data-original-title");
                                } else {
                                    $(".div-carga", row).attr("src", MangueraRoja);
                                    $(".div-carga", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.faltante_de', defaultMessage: 'Faltante de ' }) + Math.floor(dif) + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.de_litros', defaultMessage: ' litros' }));
                                }
                            } else {
                                $(".div-carga", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.litros.datos_insuficientes', defaultMessage: 'Datos Insuficientes' }));
                                $(".div-carga", row).attr("src", MangueraGris);
                            }

                            /**RENDIMIENTO ESPERADO */
                            if (result['km_litro']) {
                                if (!result.rendimiento_esperado) {
                                    $(".div-rendimiento", row).attr("src", SurtidorHojaGris);
                                    $(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.no_datos_rendimiento_esperado', defaultMessage: 'No hay datos del rendimiento esperado' }));
                                } else if (parseFloat(result.km_litro) > 0 && parseFloat(result.km_litro) <= parseFloat(result.rendimiento_esperado * (1 + parseFloat(variabilidadRendimientoInferior) / 100))) {
                                    $(".div-rendimiento", row).attr("src", SurtidorHojaVerde);
                                    $(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.rendimiento_esperado', defaultMessage: 'Rendimiento Esperado: ' }) + result.rendimiento_esperado + " LT/100Km + " + variabilidadRendimientoInferior + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.tolerancia', defaultMessage: '% de tolerancia' }));
                                } else {
                                    $(".div-rendimiento", row).attr("src", SurtidorHojaRojo);
                                    $(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.else_rendimiento_esperado', defaultMessage: 'Rendimiento Esperado: ' }) + result.rendimiento_esperado + " LT/100Km + " + variabilidadRendimientoInferior + props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.full.title.else_tolerancia', defaultMessage: '% de tolerancia' }));
                                }
                            } else if (result.rendimiento_esperado && result.utilizacion === "0.000") {
                                $(".div-rendimiento", row).attr("src", SurtidorHojaRojo);
                                $(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.no_full.title.rendimiento_menor_esperado', defaultMessage: 'Rendimiento menor al esperado: realizó carga sin recorrer kilómetros' }));
                            }
                            else {
                                $(".div-rendimiento", row).attr("src", SurtidorHojaGris);
                                $(".div-rendimiento", row).attr("data-original-title", props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.rendimiento.no_hay_datos', defaultMessage: 'No hay datos de ' }) + "LT/100Km");
                            }

                            this.cargasAControlar.splice(element, 1);
                        }

                    })
                    .catch((result, data) => { });
            }

            await this.sleep(3000)//cada 3 segundos se vuelven a controlar las cargas
        }
    });

    function sleep(time) {
        return new Promise(r => setTimeout(r, time))
    }

    const [checkedFilter, setCheckedFilter] = React.useState(false);

    const handleChange = () => {
      setCheckedFilter((prev) => !prev);
    };
  
    const handleFilterReset = () => {
        query.page = 0;
        query.marca = "";
        query.modelo = "";
        query.base = "";
        query.centroCosto = "";
        query.region = "";
        query.subRegion = "";
        query.dominio = "";
        query.estado = "";
        query.estadoVehiculo = true;
        query.movilDetail = "";
        query.lugarCarga = "";
        query.fechaHoraCargaSort = "";
        query.dominioSort = "";
        query.litrosSort = "";
        query.importeSort = "";
        query.precioLitroSort = "";
        query.utilizacionSort = "";
        query.direccionInformadaSort = "";      //{ id: 20, label: props.intl.formatMessage({ id: 'combustiblesGrid.periodos.ultimos_treinta_dias.periodoSelect', defaultMessage: 'Últimos 30 días' }) }
        query.litros100Sort = "";
        query.kmsLitroSort = "";
        query.costoKmSort = "";
        query.tarjetaSaldo = "";
        query.control = "";
        query.conductorInformadoSort = "";
        setPeriodoSelect(periodoInicial);
        periodo = periodoInicial;
        setFiltroPeriodoInicio(null);
        setFiltroPeriodoFin(null);
        setMarca(null);
        setModelo(null);
        setBase(null);
        setCentroCosto(null);
        setRegion(null);
        setSubRegion(null);
        setDominio("");
        setEstado("");
        setEstadoVehiculo(true);
        setSwitchEstadoVehiculo(false);
        setMovilDetail("");
        setLugarCarga("");
        setControl(null);
        setQuery(query);
        setChangeQuery(!changeQuery);
        setFiltersActive(false);
    };

    const requestSort = (key) => {
        let aux = query[key];
        
        query["fechaHoraCargaSort"] = "";
        query["dominioSort"] = "";
        query["litrosSort"] = "";
        query["importeSort"] = "";
        query["precioLitroSort"] = "";
        query["direccionInformadaSort"] = ""; 
        query["utilizacionSort"] = "";
        query["litros100Sort"] = "";
        query["kmsLitroSort"] = "";
        query["costoKmSort"] = "";
        query["tarjetaSaldo"] = "";
        query["conductorInformadoSort"] = "";
        
        pagination["page"] = 0;
        
        if (!aux) query[key] = "asc";
        else if (aux === "desc") query[key] = "asc";
        else query[key] = "desc";
          
        setQuery(query);
        setChangeQuery(!changeQuery);
        setPagination(pagination);
    };

    const reloadTable = ()=>{
        setChangeQuery(!changeQuery);
    }

    return (
        <React.Fragment>
            {redirectTo && <Redirect push to={redirectTo} />}
            {loading && <Loading />}
            <section id="minimal-gradient-statistics-bg" className="combustiblesWidget">
                <div className="row">
                    {estadisticas.map((widget, index) => {
                        return (
                            <div key={index} className="col-xl-3 col-lg-6 col-12">
                                <WidgetSparkline id={index} text={textos[widget.text]} value={widget.value} description={widget.description} iconClass={widget.iconClass} mainColorClass={widget.mainColorClass} hasProgressBar={widget.hasProgressBar} progressBarValue={widget.progressBarValue} datos={widget.datos} entidad="combustibles" />
                            </div>
                        );
                    })}
                </div>
            </section>
            <div className="row">
                <div className="col-12">
                    <div className="card bg-gradient-x-danger combustibles-reporte-invalidas">
                        <div className="card-content">
                            <div className="row">
                                {invalidas.map((widget, index) => {
                                    if (invalidas.length - 1 == index) {//quito borde derecho del último card
                                        return (
                                            <div key={index} className="col-lg-4 col-md-6 col-sm-12" onClick={handleInvalidas.bind(this, widget.link)}>
                                                <div className="card-body card-body-comb-invalidas text-center">
                                                    <h1 className="text-white" style={{ fontSize: '3rem' }}> {widget.value}</h1>
                                                    <span className="text-white">{widget.description}</span>
                                                </div>
                                            </div>
                                        );
                                    }
                                    else {
                                        return (
                                            <div key={index} className={"col-lg-4 col-md-6 col-sm-12 border-right-danger border-right-lighten-3 "+ (index == 1 ? "combustibles-reporte-invalidas-centro" : '')} onClick={handleInvalidas.bind(this, widget.link)}>
                                                <div className="card-body card-body-comb-invalidas text-center">
                                                    <h1 className="text-white" style={{ fontSize: '3rem' }}> {widget.value}</h1>
                                                    <span className="text-white">{widget.description}</span>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <div className="card">
                        <div className="card-content collpase show">
                            <div className="card-body card-dashboard">
                                <div className="container-fluid">
                                    <div className="row buttons-grid dt-icons">
                                        <div className="col-10 d-flex">
                                            {Security.renderIfHasPermission("COMBUSTIBLES_CREAR",
                                            <div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={handleNew.bind(this)}>
                                                <i className="ft-plus"></i>
                                            </div>)}
                                            <div
                                                    style={{ maxHeight: "52px" }}
                                                    className="btn btn-grey-blue btn-round box-shadow-2 btn-dt-main round-icon"
                                                    onClick={handleChange}
                                                    data-toggle="tooltip"
                                                    data-placement="right"
                                                    title={props.intl.formatMessage({
                                                    id: "llantasGridNew.render.button_add.title_nuevo",
                                                    defaultMessage: "Filtros",
                                                    })}
                                                >
                                                    {checkedFilter ? <UpIcon /> : <ExpandMoreIcon />}
                                            </div>
                                            {filtersActive ? (
                                                <div className="btn-dt-main round-icon">
                                                    <div className="text-center combustibles-resultados-texto"
                                                            style={{
                                                            height: "25px",
                                                            borderRadius: "3px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            paddingLeft: "8px",
                                                            backgroundColor: "#5AAC43",
                                                            color: "white",
                                                            }}
                                                        >
                                                        <div className="text-center d-flex" style={{ width: "100%", fontSize: "12px", fontWeight: "500" }}>
                                                            {combustibles.length + ' ' + props.intl.formatMessage({ id: 'combustiblesGrid.filters.apply.separator', defaultMessage: 'de' }) + ' ' + pagination.count }<div className="combustibles-resultados">&nbsp; {props.intl.formatMessage({ id: 'combustiblesGrid.filters.apply.title', defaultMessage: 'resultados de búsqueda' })}</div>
                                                        </div>

                                                        <div className="resultados-busqueda-notif">
                                                            <i className="las la-times la-xs cursor-pointer" onClick={handleFilterReset}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : null}
                                        </div>
                                        <div className="col-2">
                                        
                                            <div className="dt-buttons btn-group">
                                                <button data-toggle="modal" data-target="#export_modal" title="Exportar Excel" className="btn btn-secondary buttons-collection" tabIndex="0" aria-controls="dataTable" type="button" aria-haspopup="true">
                                                    <span>
                                                        <i className="ft-upload"></i>
                                                    </span>
                                                </button>
                                                <button data-toggle="modal" data-target="#import_modal" title="Importar Excel" className="btn btn-secondary buttons-collection" tabIndex="0" aria-controls="dataTable" type="button" aria-haspopup="true">
                                                    <span>
                                                        <i className="ft-download"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <Collapse in={checkedFilter}>
                                        <div className="row mb-1">
                                            <div className="col-md-12">
                                                <div className="row mt-1" style={{ width: "100%" }}>
                                                    
                                                    {/*PERIODO */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Select
                                                            id="periodo"
                                                            name="periodo"
                                                            clearable={false}
                                                            options={periodos}
                                                            optionComponent={GravatarOption}
                                                            valueKey="id"
                                                            labelKey="label"
                                                            className="select-periodo-filter"
                                                            value={periodoSelect ? periodoSelect : null}
                                                            onChange={(e) => handleSelectFormChange("periodo", e)}
                                                        />
                                                    </div>

                                                    {/*BASE */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            multiple
                                                            size="small"
                                                            name="base"
                                                            loading={false}
                                                            options={bases}
                                                            value={base ? base : []}
                                                            defaultValue={[]}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("base", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_base.label", defaultMessage: "Base"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_base.placeholder", defaultMessage: "Base"})}                                                                
                                                            />
                                                            
                                                            )}
                                                        />
                                                    </div>
                                                    {/*CENTRO DE COSTOS */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            multiple
                                                            size="small"
                                                            name="centroCosto"
                                                            loading={false}
                                                            options={centroCostos}
                                                            value={centroCosto ? centroCosto : []}
                                                            defaultValue={[]}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("centroCosto", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_centro_costos.label", defaultMessage: "Centro de Costos"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_centro_costos.placeholder", defaultMessage: "Centro de Costos"})}
                                                            />
                                                            
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mt-1" style={{ width: "100%" }}>
                                                    {/*REGIÓN */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            multiple
                                                            size="small"
                                                            name="region"
                                                            loading={false}
                                                            options={regiones}
                                                            value={region ? region : []}
                                                            defaultValue={[]}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("region", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_region.label", defaultMessage: "Región"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_region.placeholder", defaultMessage: "Región"})}
                                                            />
                                                            )}
                                                        />
                                                    </div>

                                                    {/*SUB-REGIÓN*/}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            multiple
                                                            size="small"
                                                            name="subRegion"
                                                            loading={false}
                                                            options={subRegiones}
                                                            value={subRegion ? subRegion : []}
                                                            defaultValue={[]}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("subRegion", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_subregion.label", defaultMessage: "Sub-Región"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_subregion.placeholder", defaultMessage: "Sub-Región"})}
                                                            />
                                                            )}
                                                        />
                                                    </div>
                                                    
                                                    {/*DOMINIO */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <form className={classes.root} noValidate autoComplete="off">
                                                            <Input
                                                            id="standard-basic"
                                                            label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_dominio.label", defaultMessage: "Dominio"})} 
                                                            placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_dominio.placeholder", defaultMessage: "Dominio"})}
                                                            name="dominio"
                                                            value={dominio}
                                                            onChange={(e) => setDominio(e.target.value)}
                                                            onBlur={(e) => handleInputFormChange("dominio", e)}
                                                            className="col-12"
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                                
                                                <div className="row mt-1" style={{ width: "100%" }}>
                                                  {/*MARCA */}
                                                  <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            size="small"
                                                            name="marca"
                                                            loading={false}
                                                            options={marcas}
                                                            value={marca ? marca : ""}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("marca", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_marca.label", defaultMessage: "Marca"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_marca.placeholder", defaultMessage: "Marca"})}
                                                            />
                                                            )}
                                                        />
                                                    </div>

                                                    {/*MODELO */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            size="small"
                                                            name="modelo"
                                                            options={modelos}
                                                            value={modelo ? modelo : ""}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("modelo", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_modelos.label", defaultMessage: "Modelos"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_modelos.placeholder", defaultMessage: "Modelos"})}
                                                            />
                                                            )}
                                                        />
                                                    </div>

                                                    {/*ESTADO */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            size="small"
                                                            name="estado"
                                                            options={estados}
                                                            value={estado ? estado : ""}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("estado", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_estado.label", defaultMessage: "Estado"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_estado.placeholder", defaultMessage: "Estado"})} />
                                                            )}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mt-1" style={{ width: "100%" }}>

                                                  {/*LUGAR DE CARGA */}
                                                  <div className="col-md-4 col-sm-12">
                                                      <form className={classes.root} noValidate autoComplete="off">
                                                          <Input
                                                          id="lugar_carga"
                                                          label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_lugar_de_carga.label", defaultMessage: "Lugar de Carga"})} 
                                                          placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_lugar_de_carga.placeholder", defaultMessage: "Lugar de Carga"})}
                                                          name="lugarCarga"
                                                          value={lugarCarga}
                                                          onChange={(e) => handleInputFormChange("lugarCarga", e)}
                                                          className="col-12"
                                                          />
                                                      </form>
                                                  </div>

                                                  {/*ESTADO VEHICULO */}
                                                  <div className="col-md-4 col-sm-12 mt-2">
                                                      <div className="d-flex align-items-center">
                                                        <div className="mr-2">Ver Inactivos</div>
                                                          <Switch
                                                            onChange={(value) => handleEstadoVehiculo(value)}
                                                            checked={switchEstadoVehiculo}
                                                            disabled={loading}
                                                            offColor="#FF4961"
                                                            onColor="#28D094"
                                                          />
                                                      </div>
                                                  </div>

                                                    {/*CONTROLES */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <Autocomplete
                                                            multiple
                                                            size="small"
                                                            name="controles"
                                                            loading={false}
                                                            options={controles}
                                                            value={control ? control : []}
                                                            defaultValue={[]}
                                                            getOptionLabel={(option) => option ? option.label : ""}
                                                            onChange={(event, value) => handleSelectFormChange("control", value)}
                                                            renderInput={(params) => (
                                                            <TextField {...params} variant="standard" 
                                                                label={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_controles.label", defaultMessage: "Controles"})} 
                                                                placeholder={props.intl.formatMessage({id: "combustiblesGrid.render.filtros_controles.placeholder", defaultMessage: "Controles"})}
                                                            />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </Collapse>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table  aria-label="collapsible table" classes={{root: classes.rootTable}} width="100%">
                                        <TableHead >
                                            <TableRow>
                                                <StyledTableCell classes={{root: classes.head}}>
                                                    <div onClick={()=>requestSort("dominioSort")}>
                                                            {props.intl.formatMessage({ id: 'combustiblesGrid.column.dominio.title', defaultMessage: 'Dominio' })}
                                                            <img className="ml-1"
                                                                src={query.dominioSort === "" ? Sort : query.dominioSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center" style={{ maxWidth: "3px" }}><div></div></StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center"><div>{props.intl.formatMessage({ id: 'combustiblesGrid.column.controles.title', defaultMessage: 'Controles' })}</div></StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("fechaHoraCargaSort")}>
                                                    {props.intl.formatMessage({ id: 'combustiblesGrid.column.fecha_hora.title', defaultMessage: 'Fecha y Hora' })}
                                                        <img className="ml-1"
                                                                src={query.fechaHoraCargaSort === "" ? Sort : query.fechaHoraCargaSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div>
                                                </StyledTableCell>
                                                {(columnTarjetaSaldo) &&
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("tarjetaSaldo")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.tarjeta_saldo.title', defaultMessage: 'Saldo Tarjeta' })}
                                                        <img className="ml-1"
                                                                src={query.tarjetaSaldo === "" ? Sort : query.tarjetaSaldo === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div></StyledTableCell>
                                                }
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("utilizacionSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.utilizacion.title', defaultMessage: 'Utilización' })}
                                                        <img className="ml-1"
                                                                src={query.utilizacionSort === "" ? Sort : query.utilizacionSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div></StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("litrosSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.litros.title', defaultMessage: 'Litros' })}
                                                        <img className="ml-1"
                                                                src={query.litrosSort === "" ? Sort : query.litrosSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div></StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("importeSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.total.title', defaultMessage: 'Total' })}
                                                        <img className="ml-1"
                                                                src={query.importeSort === "" ? Sort : query.importeSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div></StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("precioLitroSort")}>
                                                        {props.intl.formatMessage({ id: 'combustiblesGrid.column.precio_por_litro.title', defaultMessage: 'Precio por Litro' })}
                                                        <img className="ml-1"
                                                                src={query.precioLitroSort === "" ? Sort : query.precioLitroSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div></StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("direccionInformadaSort")}>
                                                        {props.intl.formatMessage({ id: 'combustiblesGrid.column.lugar_carga', defaultMessage: 'Lugar de Carga' })}
                                                        <img className="ml-1"
                                                                src={query.direccionInformadaSort === "" ? Sort : query.direccionInformadaSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div></StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    { litrosCada100km === 'true' ? 
                                                    <div onClick={()=>requestSort("litros100Sort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.LT_100Km', defaultMessage: 'LT/100Km' })}
                                                        <img className="ml-1"
                                                                src={query.litros100Sort === "" ? Sort : query.litros100Sort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div>
                                                    :
                                                    <div onClick={()=>requestSort("kmsLitroSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.kms_litro', defaultMessage: 'Kms/LT' })}
                                                        <img className="ml-1"
                                                                src={query.kmsLitroSort === "" ? Sort : query.kmsLitroSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div>}
                                                </StyledTableCell>
                                                <StyledTableCell classes={{root: classes.head}} align="center">
                                                    <div onClick={()=>requestSort("costoKmSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.costo_Km', defaultMessage: 'Costo/Km' })}
                                                        <img className="ml-1"
                                                                src={query.costoKmSort === "" ? Sort : query.costoKmSort === "asc" ? SortAsc : SortDesc}
                                                                alt={"/"}
                                                                style={{ height: "12px", width: "22px" }}
                                                            />
                                                    </div>
                                                </StyledTableCell>
                                                {(columnConductorInformado) &&
                                                  <StyledTableCell classes={{root: classes.head}} align="center">
                                                      <div onClick={()=>requestSort("conductorInformadoSort")}>{props.intl.formatMessage({ id: 'combustiblesGrid.column.conductor_informado', defaultMessage: 'Conductor' })}
                                                          <img className="ml-1"
                                                                  src={query.conductorInformadoSort === "" ? Sort : query.conductorInformadoSort === "asc" ? SortAsc : SortDesc}
                                                                  alt={"/"}
                                                                  style={{ height: "12px", width: "22px" }}
                                                              />
                                                      </div>
                                                  </StyledTableCell>
                                                }
                                                <StyledTableCell classes={{root: classes.head}} align="center"></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {combustibles.map((comb) => (
                                                <Row
                                                    //redirectToCorrectivo={redirectToCorrectivo}
                                                    combustible={comb}
                                                    key={comb.id}
                                                    dataTableUpdate={dataTableUpdate}
                                                    intl={props.intl}
                                                    changeTable={reloadTable}
                                                    motivosValidez={motivosValidez}
                                                    ajaxHandler={props.ajaxHandler}
                                                    setRedirectTo={setRedirectTo}
                                                    litrosCada100km={litrosCada100km}
                                                    tarjetaSaldo={columnTarjetaSaldo}
                                                    columnConductorInformado={columnConductorInformado}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    component="div"
                                    count={pagination.count }
                                    rowsPerPage={pagination.perPage}
                                    page={pagination.page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    labelRowsPerPage="Mostrar"
                                    labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
                <div className="modal-xl modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-fleet">
                            <h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-download align-middle icon-modal-title"></i><FormattedMessage id="combustiblesGrid.tools.export.modal_title.exportar_combustibles" defaultMessage=" Exportar Combustibles" /></h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                            <div className="row">
                                <div className="col-md-12">
                                    {/*<div className="alert alert-danger" role="alert" hidden={errors.length===0}>
								{errors.map((e, i) => <li key={i}>{e}</li>)}
							</div>*/}
                                    <form className="form form-horizontal">
                                        <div className="form-body">
                                            <div className="card pull-up">
                                                <div className="card-content">
                                                    <div className="card-body">
                                                        <h4 className="form-section">
                                                            <i className="la la-columns"></i><FormattedMessage id="combustiblesGrid.tools.export.modal_content.columnas_a_exportar" defaultMessage=" Columnas a exportar" />
                                                        </h4>
                                                        <div className="row m-1">
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="fecha_hora_carga">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.fecha_hora" defaultMessage="Fecha y Hora:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "fechaHoraCarga")}
                                                                    checked={dataToExport.fechaHoraCarga ? true : false}
                                                                    value={dataToExport.fechaHoraCarga}
                                                                    id="fecha_hora_carga"
                                                                    name="fecha_hora_carga"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="dominio">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.dominio" defaultMessage="Dominio:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "movil")}
                                                                    checked={dataToExport.movil ? true : false}
                                                                    value={dataToExport.movil}
                                                                    id="dominio"
                                                                    name="dominio"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="utilizacion">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.utilizacion" defaultMessage="Utilizacion:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "utilizacion")}
                                                                    checked={dataToExport.utilizacion ? true : false}
                                                                    value={dataToExport.utilizacion}
                                                                    id="utilizacion"
                                                                    name="utilizacion"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="litros">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.litros" defaultMessage="Litros:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "litros")}
                                                                    checked={dataToExport.litros ? true : false}
                                                                    value={dataToExport.litros}
                                                                    id="litros"
                                                                    name="litros"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row m-1">
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="importe">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.total" defaultMessage="Total:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "importe")}
                                                                    checked={dataToExport.importe ? true : false}
                                                                    value={dataToExport.importe}
                                                                    id="importe"
                                                                    name="importe"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="precio_por_litro">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.precio_por_litro" defaultMessage="Precio por Litro:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "precioLitro")}
                                                                    checked={dataToExport.precioLitro ? true : false}
                                                                    value={dataToExport.precioLitro}
                                                                    id="precio_por_litro"
                                                                    name="precio_por_litro"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="direccion_carga">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.lugar_de_carga" defaultMessage="Lugar de Carga" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "direccionInformada")}
                                                                    checked={dataToExport.direccionInformada ? true : false}
                                                                    value={dataToExport.direccionInformada}
                                                                    id="direccion_carga"
                                                                    name="direccion_carga"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="km_litro">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.km-Litro" defaultMessage="Km/Litro:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "kmLitros")}
                                                                    checked={dataToExport.kmLitros ? true : false}
                                                                    value={dataToExport.kmLitros}
                                                                    id="km_litro"
                                                                    name="km_litro"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row m-1">
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="costo_km">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.costo-Km" defaultMessage="Costo/Km:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "costoKm")}
                                                                    checked={dataToExport.costoKm ? true : false}
                                                                    value={dataToExport.costoKm}
                                                                    id="costo_km"
                                                                    name="costo_km"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="estado">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.estado" defaultMessage="estado:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "estado")}
                                                                    checked={dataToExport.estado ? true : false}
                                                                    value={dataToExport.estado}
                                                                    id="estado"
                                                                    name="estado"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="base">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.base" defaultMessage="Base:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "base")}
                                                                    checked={dataToExport.base ? true : false}
                                                                    value={dataToExport.base}
                                                                    id="base"
                                                                    name="base"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="centroCostosNombre">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.centroCostosNombre" defaultMessage="Centro de Costos:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "centroCostosNombre")}
                                                                    checked={dataToExport.centroCostosNombre ? true : false}
                                                                    value={dataToExport.centroCostosNombre}
                                                                    id="centroCostosNombre"
                                                                    name="centroCostosNombre"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row m-1">
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="responsable1NombreApellido">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.responsable1NombreApellido" defaultMessage="Responsable 1:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "responsable1NombreApellido")}
                                                                    checked={dataToExport.responsable1NombreApellido ? true : false}
                                                                    value={dataToExport.responsable1NombreApellido}
                                                                    id="responsable1NombreApellido"
                                                                    name="responsable1NombreApellido"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="responsable2NombreApellido">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.responsable2NombreApellido" defaultMessage="Responsable 2:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "responsable2NombreApellido")}
                                                                    checked={dataToExport.responsable2NombreApellido ? true : false}
                                                                    value={dataToExport.responsable2NombreApellido}
                                                                    id="responsable2NombreApellido"
                                                                    name="responsable2NombreApellido"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="conductor">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.conductor" defaultMessage="Conductor:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "conductor")}
                                                                    checked={dataToExport.conductor ? true : false}
                                                                    value={dataToExport.conductor}
                                                                    id="conductor"
                                                                    name="conductor"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="region">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.region" defaultMessage="Región:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "region")}
                                                                    checked={dataToExport.region ? true : false}
                                                                    value={dataToExport.region}
                                                                    id="region"
                                                                    name="region"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row m-1">
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="subRegion">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.sub_region" defaultMessage="Sub-Región:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "subRegion")}
                                                                    checked={dataToExport.subRegion ? true : false}
                                                                    value={dataToExport.subRegion}
                                                                    id="subRegion"
                                                                    name="subRegion"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="combustibleTipos">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.tipo_combustible" defaultMessage="Tipo Combustible:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "combustibleTipos")}
                                                                    checked={dataToExport.combustibleTipos ? true : false}
                                                                    value={dataToExport.combustibleTipos}
                                                                    id="combustibleTipos"
                                                                    name="combustibleTipos"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="odometro">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.odometro_carga" defaultMessage="Odómetro Carga:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "odometro")}
                                                                    checked={dataToExport.odometro ? true : false}
                                                                    value={dataToExport.odometro}
                                                                    id="odometro"
                                                                    name="odometro"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="rendimiento">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.target_rendimiento" defaultMessage="Target Rendimiento:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "rendimiento")}
                                                                    checked={dataToExport.rendimiento ? true : false}
                                                                    value={dataToExport.rendimiento}
                                                                    id="rendimiento"
                                                                    name="rendimiento"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row m-1">
                                                          {columnTarjetaSaldo ? (
                                                              <div className=" col-md-3">
                                                                  <label className="col-md-6 label-control col-form-label" htmlFor="tarjetaSaldo">
                                                                      <FormattedMessage id="combustiblesGrid.tools.export.modal_content.tarjetaSaldo" defaultMessage="Saldo Tarjeta:" />
                                                                  </label>
                                                                  <Switch
                                                                      onChange={(event) => handleDataExport(event, "tarjetaSaldo")}
                                                                      checked={dataToExport.tarjetaSaldo ? true : false}
                                                                      value={dataToExport.tarjetaSaldo}
                                                                      id="tarjetaSaldo"
                                                                      name="tarjetaSaldo"
                                                                      offColor="#FF4961"
                                                                      onColor="#28D094"
                                                                      disabled={false}
                                                                      className="col-md-6 align-middle"
                                                                  />
                                                              </div>
                                                          ): dataToExport.tarjetaSaldo = false}
                                                          {columnConductorInformado && (
                                                            <div className=" col-md-3">
                                                                <label className="col-md-6 label-control col-form-label" htmlFor="tarjetaSaldo">
                                                                    <FormattedMessage id="combustiblesGrid.tools.export.modal_content.conductorInformado" defaultMessage="Conductor Informado:" />
                                                                </label>
                                                                <Switch
                                                                    onChange={(event) => handleDataExport(event, "conductorInformado")}
                                                                    checked={dataToExport.conductorInformado ? true : false}
                                                                    value={dataToExport.conductorInformado}
                                                                    id="conductorInformado"
                                                                    name="conductorInformado"
                                                                    offColor="#FF4961"
                                                                    onColor="#28D094"
                                                                    disabled={false}
                                                                    className="col-md-6 align-middle"
                                                                />
                                                            </div>
                                                          )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 alert alert-info small" role="alert">
                                            <FormattedMessage id="combustiblesGrid.tools.export.modal_content.esta_accion_puede_demorar" defaultMessage="Esta acción puede demorar unos minutos." />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer modal-grey">
                            <button type="button" className="btn btn-default btn-fleet" onClick={handleExportExcel} ><i className={loading ? 'la la-spinner spinner' : 'fa fa-download'}></i> Exportar a Excel</button>
                            <button type="button" className="btn btn-danger" onClick={handleExportCancel} ><i className="fa fa-times-circle"></i> Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade col-8" tabIndex="-1" id="import_modal" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
                <div className="modal-xl modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-fleet">
                            <h4 className="modal-title text-white" id="myModalLabel1">{/*<i className="la la-history align-middle icon-modal-title"></i>*/}
                                <FormattedMessage id="combustiblesGrid.tools.import.button.importar" defaultMessage=" Importar" />
                            </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                            <form className="form">
                                {<CombustiblesImportar callbackClose={handleCloseModal} callbackCancel={handleCancelModal} setTypeImport={setTypeImport}/>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade col-8" tabIndex="-1" id="import_report_modal" role="dialog" aria-labelledby="importReportLabel" style={{ margin: "auto" }}>
                <div className="modal-lg modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-fleet">
                            <h4 className="modal-title text-white" id="importReportLabel">Reporte de la Importación</h4>
                            <button type="button" className="close" onClick={handleReportCloseModal} aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                            {reporteImportacion ?
                                <CombustiblesImportarReporte typeImport={typeImport} data={reporteImportacion} callbackReportClose={handleReportCloseModal}></CombustiblesImportarReporte>
                                : null}
                        </div>
                        <div className="modal-footer modal-grey">
                            <button type="button" className="btn btn-default btn-fleet" onClick={handleReportCloseModal}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default injectIntl(CombustiblesGridNew);
