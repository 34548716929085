class RefactorConfig {
	static get(key) {
		return new Promise((resolve, reject) => {
			return fetch('/config/refactor-config.json', {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			})
			.then((response) => response.json())
			.then((configData) => {
				resolve(configData[key]);
			})
			.catch((error) => {
				reject(error);
			});
		});
	}
}

export default RefactorConfig;