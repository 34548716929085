import axios from 'axios'
import Config from './Config.js'
import RefactorConfig from './RefactorConfig.js'

class FeatureFlags {
  static get(flag) {
    return new Promise((resolve, reject) => {
      RefactorConfig.get("dataMigrationApiBaseUrl")
      .then((dataMigrationApiBaseUrl) => {
        axios({
          method: 'GET',
          url: `${dataMigrationApiBaseUrl}/feature-flags/${flag}?apiBaseUrl=${Config.get('apiUrlBase')}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => resolve(res.data))
        .catch((err) => {
          reject(err)
        })
      })
      .catch((err) => {
        reject(err)
      })
    })
  }
}

export default FeatureFlags
