import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Security from "../../commons/security/Security.js";
import $ from "jquery";
import "bootstrap4-duallistbox";
import "bootstrap4-duallistbox/dist/bootstrap-duallistbox.css";
import FormValidation from "../../commons/validation/FormValidation.js";
import Validator from "../../commons/validation/Validator.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import Loading from "../ui/Loading.js";
import Select from "react-select";
import "react-select/dist/react-select.css";
import moment from "moment";
import "moment/min/locales";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/min/dropzone.min.css";
import "../../assets/css/vec-dropzone.css";
import CorrectivosAbmAdjuntos from "./CorrectivosAbmAdjuntos.js";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import { ID__UM_ODOMETRO, ID__UM_HOROMETRO } from '../unidadMedidor/constants.js'

const VALUE_MOVIL = 0;
const VALUE_LLANTA = 1;

let entidades = [
  { label: "Movil", value: VALUE_MOVIL },
  { label: "Llanta", value: VALUE_LLANTA },
];

class CorrectivosAbm extends Component {
  constructor(props) {
    super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");
    let searchParams = new URLSearchParams(this.props.location.search);
    console.log(searchParams.get("valorDinamico"));
    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
        id: 0,
        ticketTipo: "CORRECTIVO",
        persona: null,
        movil: props.movil ? props.movil : null,
        llanta: null,
        servicio: null,
        autogestion: false,
        inmovilizado: false,
        abono:
          ConfigBusiness.get("mantenimientos.correctivo.abono.habilitado") ===
          "true"
            ? true
            : false,
        gerenciador: null,
        tareas: [],
        estado: "ABIERTO",
        kmGenerado: 0,
        detalle: null,
        esSiniestro: false,
        numeroSiniestro: null,
        adjuntos: [],
        valorDinamico: searchParams.get("valorDinamico"),
        ticketGarantiaId:null,
        ticketPendiente:null,
        esReemplazoDeVehiculo: false,
				fechaEntrega: null,
				placaReemplazo: null,
				otAsociada: null,
      },
      validacionCorrectivo:false,
      diasGarantia: ConfigBusiness.get("tickets.periodoGarantia.periodoDias"),
      tareasCorrectivoGarantia:[],
      entidad: null,
      llantas: [],
      llanta: null,
      moviles: [],
      gerenciadores: [],
      listaSelectServicio: [],
      listaSelectTareas: [],
      servicioSeleccionado: null,
      tareasSeleccionadas: [],
      errors: [],
      loading: false,
      tareasDisabled: true,
      tareasIsLoading: false,
      gerenciadoresDisabled: true,
      gerenciadoresIsLoading: false,
      displaySelectTareas: false,
      abonoDisabled: false,
      gerenciadoresEnabled: false,
      inmovilizadoEnabled: false,
      inmovilizadoOptions: [
        {
          value: 2,
          label: this.props.intl.formatMessage({
            id: "correctivosAbm.inmovilizadoOptions.no",
            defaultMessage: "NO",
          }),
        },
        {
          value: 1,
          label: this.props.intl.formatMessage({
            id: "correctivosAbm.inmovilizadoOptions.si",
            defaultMessage: "SI",
          }),
        },
      ],
    };

    this.handleServicioChange = this.handleServicioChange.bind(this);
    this.handleTareasChange = this.handleTareasChange.bind(this);
    this.handleDeleteTarea = this.handleDeleteTarea.bind(this);
    this.handleMovilChange = this.handleMovilChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleAutogestionChange = this.handleAutogestionChange.bind(this);
    this.handleSiniestroChange = this.handleSiniestroChange.bind(this);
    this.handleAbonoChange = this.handleAbonoChange.bind(this);
    this.handleInmovilizadoChange = this.handleInmovilizadoChange.bind(this);
    this.handleValidacionCorrectivo = this.handleValidacionCorrectivo.bind(this);
    this.handleCancelValidacionCorrectivoModal = this.handleCancelValidacionCorrectivoModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getBackendMoviles = this.getBackendMoviles.bind(this);
    this.handleReemplazoVehiculoChange = this.handleReemplazoVehiculoChange.bind(this);


    this.formValidation = new FormValidation({
      component: this,
      validators: {
        "formData.movil": (value) => Validator.notEmpty(value),
        //'formData.inmovilizado': (value) => Validator.notEmpty(value)
      },
    });
  }

  componentDidMount() {
    if (Security.hasPermission("MANTENIMIENTOS_CORRECTIVOS_CREAR")) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: "/error",
      });
    }
  }

  initForm() {
    this.setState({ loading: true });
    let component = this;
    if (this.props.option === "LLANTA") {
      let id = this.props.match.params.llantaId;
      this.ajaxHandler
        .getJson("/llantas/" + id)
        .then((data) => {
          this.setState({ entidad: entidades[VALUE_LLANTA] });
          this.setState({ llanta: data });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log("Llanta loaded");
        });
    }
    else {
      if (!ConfigBusiness.get("llantas.habilitado") || !Security.hasPermission("LLANTAS_LISTAR"))
          this.setState({ entidad: entidades[VALUE_MOVIL] });
    }
    if (this.props.option === "MOVIL") {
      this.setState({ entidad: entidades[VALUE_MOVIL] });
    }
    Promise.all([
      this.ajaxHandler.getJson("/moviles/select-with-base-filtered-by-user"),
      this.ajaxHandler.getJson("/servicios/tipo-ticket/CORRECTIVO/select"),
      null,
      ConfigBusiness.get("mantenimientos.correctivo.gerenciadores.habilitado"),
      ConfigBusiness.get("tickets.inmovilizado.habilitado"),
      (ConfigBusiness.get("llantas.habilitado") && Security.hasPermission("LLANTAS_LISTAR")) ? this.ajaxHandler.getJson("/llantas") : null,
      ConfigBusiness.get("llantas.servicioGomeriaId"),
      ConfigBusiness.get("llantas.tareasGomeria"),
    ])
      .then((data) => {
        let moviles = data[0];
        let servicios = data[1];
        let gerenciadoresEnabled = data[3] === "true";
        let inmovilizadoEnabled = data[4] === "true";
        if (inmovilizadoEnabled === "true") {
          this.formValidation = new FormValidation({
            component: this,
            validators: {
              "formData.movil": (value) => Validator.notEmpty(value),
              "formData.inmovilizado": (value) => Validator.notEmpty(value),
            },
          });
        }
        let llantas = data[5] ? data[5].llantas : [];
        component.setState(
          {
            moviles: moviles,
            llantas: llantas,
            servicios: servicios,
            gerenciadoresEnabled: gerenciadoresEnabled,
            inmovilizadoEnabled: inmovilizadoEnabled,
          },
          () => {
            window.scrollTo(0, 0);
            if (this.props.match.params.movilId) {
              this.handleMovilChange({
                id: this.props.match.params.movilId,
                label: this.props.match.params.movilDominio,
              });
            }
            $("#movil").focus();
          }
        );
        if (this.props.option === "LLANTA") {
          let servicioId = data[6];
          let tareasId = data[7].split(',');
          let tareas = [];
          this.ajaxHandler.getJson("/servicios/" + servicioId)
          .then((res) => {
            console.log(res);
            this.handleServicioChange({value: res.id, label: res.nombre}, )
          })
          .catch((er) => console.log(er))

          tareasId.forEach((tarea) => {
            this.ajaxHandler.getJson("/tareas/" + tarea)
            .then((res) => {
              console.log(res);
              tareas.push({value: res.id, label: res.nombre})

            })
            .catch(err => console.log(err))
          })

          this.setState({
            tareasSeleccionadas: tareas,
          });

          // this.handleAddTarea()
          // component.setState({
          //   servicioSeleccionado: { label: data[6].label, value: data[6].id },
          // });
        }
      })
      .catch(function (error) {
        component.ajaxHandler.handleError(error);
      })
      .finally(() => {
        component.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  addServicioNoAsignable() {
    let tareas = [];
    tareas.push({
      id: null,
      nombre: null,
      servicio: {
        id: this.state.servicioSeleccionado.value,
        nombre: this.state.servicioSeleccionado.label,
      },
    });
    this.state.tareasSeleccionadas = tareas;
  }

  handleAddTarea() {
    let tareas = [];

    this.state.tareasSeleccionadas.map((tareaSeleccionada) => {
      tareas.push({
        id:
          this.state.displaySelectTareas === "false"
            ? null
            : tareaSeleccionada.value,
        nombre:
          this.state.displaySelectTareas === "false"
            ? null
            : tareaSeleccionada.label,
        servicio: {
          id: this.state.servicioSeleccionado.value,
          nombre: this.state.servicioSeleccionado.label,
        },
      });
      return tareaSeleccionada;
    });

    this.loadTareas(tareas);
  }

  loadTareas(tareas) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    tareas.map((tarea) => {
      if (
        formDataCopy.tareas.findIndex(
          (x) => x.id === tarea.id
        ) === -1
      ) {
        formDataCopy.tareas.push(tarea);
      }
      return tareas;
    });

    this.setState({
      formData: formDataCopy,
      servicioSeleccionado: null,
      tareasSeleccionadas: [],
      listaSelectTareas: [],
      tareasDisabled: true,
    });
  }

  handleDeleteTarea(index) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.tareas.splice(index, 1);
    this.setState({
      formData: formDataCopy,
    });
  }

  handleMovilChange(object) {
    let component = this;
    if (object) {
      this.setState({ loading: true });
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.movil = object;
      this.setState(
        {
          formData: formDataCopy,
        },
        () => {
          this.ajaxHandler
            .getJson("/moviles/" + object.id)
            .then((data) => {
              let movil = data;
              let gerenciador =
                this.state.formData.autogestion &&
                !this.state.gerenciadoresEnabled
                  ? null
                  : movil.base && movil.base.gerenciador
                  ? movil.base.gerenciador
                  : null;
              let formDataCopy = JSON.parse(
                JSON.stringify(this.state.formData)
              );
              formDataCopy.kmGenerado = data.kmActual ? data.kmActual : 0;
              if (
                ConfigBusiness.get(
                  "mantenimientos.correctivo.abono.habilitado"
                ) === "true" ||
                this.state.gerenciadoresEnabled
              ) {
                this.setState({
                  formData: formDataCopy,
                  gerenciadoresIsLoading: true,
                });
                this.ajaxHandler
                  .getJson(
                    "/gerenciadores/tipo/1/subregion/" +
                      movil.base.subregion.id +
                      "/select"
                  )
                  .then((data2) => {
                    let gerenciadores = data2;
                    let formDataCopy = JSON.parse(
                      JSON.stringify(this.state.formData)
                    );
                    formDataCopy.movil = movil;
                    formDataCopy.gerenciador = gerenciador;
                    this.setState({
                      gerenciadores: gerenciadores,
                      gerenciadoresIsLoading: false,
                      gerenciadoresDisabled: false,
                      formData: formDataCopy,
                    });
                  })
                  .catch(function (error) {
                    this.ajaxHandler.handleError(error);
                  });
              } else {
                let formDataCopy = JSON.parse(
                  JSON.stringify(this.state.formData)
                );
                formDataCopy.movil = movil;
                formDataCopy.gerenciador = gerenciador;
                this.setState({
                  formData: formDataCopy,
                });
              }
              this.setState({ loading: false });
            })
            .catch(function (error) {
              component.ajaxHandler.handleError(error);
              component.setState({ loading: false });
            });
        }
      );
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.movil = null;
      formDataCopy.gerenciador = null;
      this.setState({
        formData: formDataCopy,
        gerenciadoresDisabled: true,
      });
    }
  }

  handleGerenciadorChange(object) {
    if (object) {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.gerenciador = {
        id: object.value,
        razonSocial: object.label,
      };
      this.setState({
        formData: formDataCopy,
      });
    } else {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.gerenciador = null;
      this.setState({
        formData: formDataCopy,
      });
    }
  }

  handleAutogestionChange(autogestion) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.autogestion = autogestion;
    if (autogestion) {
      formDataCopy.abono = false;
      if (!this.state.gerenciadoresEnabled) formDataCopy.gerenciador = null;
    }
    let abonoDisabled = autogestion ? true : false;
    this.setState({
      formData: formDataCopy,
      abonoDisabled: abonoDisabled,
    });
  }

  handleSiniestroChange(siniestro) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state));
    formDataCopy.formData.esSiniestro = siniestro;
    if (siniestro === false) {
      formDataCopy.formData.numeroSiniestro = null;
    }
    if (
      ConfigBusiness.get("mantenimientos.correctivo.abono.habilitado") ===
      "true"
    ) {
      if (siniestro === true) {
        //si tiene el abono habilitado y el selector de siniestro pasó a true desactivo el abono
        formDataCopy.formData.abono = false;
        //si el gerenciador no esta siempre visible se lo borra ya que es solo para el caso de que el abono sea true
        if (!this.state.gerenciadoresEnabled)
          formDataCopy.formData.gerenciador = null;
      } else {
        formDataCopy.formData.abono = true;
      }
    }

    this.setState(formDataCopy);
  }

  handleReemplazoVehiculoChange(reemplazoVehiculo) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state));
		formDataCopy.formData.esReemplazoDeVehiculo = reemplazoVehiculo;
		if (reemplazoVehiculo === false) {
			formDataCopy.formData.fechaEntrega = null;
			formDataCopy.formData.placaReemplazo = null;
		}
		this.setState(formDataCopy);
	}

	handleDatePickerFormChange(name, event) {
		let nuevaFecha = "";
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.fechaEntrega = event;
		this.setState({
		  formData: formDataCopy
		});
	  }
	
	  handleDatePickerFormRawChange(name, date) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy[name] = date;
			this.setState({
				formData: formDataCopy
			});
	  }

  handleAbonoChange(abono) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.abono = abono;
    if (abono && !this.state.gerenciadoresEnabled)
      formDataCopy.gerenciador = null;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleServicioChange(object) {
    if (object) {
      this.setState(
        {
          servicioSeleccionado: object,
          tareasIsLoading: true,
        },
        () => {
          Promise.all([
            this.ajaxHandler.getJson("/servicios/" + object.value),
            this.ajaxHandler.getJson(
              "/tareas/servicio/" + object.value + "/select"
            ),
          ])
            .then((data) => {
              let servicio = data[0];
              let listaSelectTareas = data[1];
              this.setState(
                {
                  listaSelectTareas: listaSelectTareas,
                  displaySelectTareas: true,
                  tareasDisabled: false,
                  tareasIsLoading: false,
                },
              );
            })
            .catch(function (error) {
              AjaxHandler.handleError(error);
            });
        }
      );
    } else {
      this.setState({
        servicioSeleccionado: null,
        tareasSeleccionadas: [],
        tareasDisabled: true,
      });
    }
  }

  handleInmovilizadoChange(inmovilizado) {
    console.log(inmovilizado);
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.inmovilizado = inmovilizado ? inmovilizado.value : null;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleTareasChange(value) {
    console.log(value);
    this.setState({
      tareasSeleccionadas: value,
    });
  }

  handleInputFormChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleValidacionCorrectivo(formData){
    this.setState({loading:true})
    this.setState({tareasCorrectivoGarantia:[]})
    this.ajaxHandler
      .fetch("/tickets/correctivoValidacion", {
        method: "POST",
        body: JSON.stringify({
          ...formData,
        }),
      })
      .then((response) => {
        if (response.status !== 400) {
          response.json().then((data) => {
            this.setState({loading:false})
            this.setState({validacionCorrectivo:true})
            if(data.tareasGarantia || data.pendiente){
              $('#validacion_correctivo_modal').modal({ backdrop: 'static', keyboard: false});
              let formDataCopy = this.state.formData;
              if(data.tareasGarantia){
                this.setState({tareasCorrectivoGarantia:data.tareasGarantia})
                formDataCopy.ticketGarantiaId = data.tareasGarantia[0].ticketId
              }
              if(data.pendiente) formDataCopy.ticketPendiente = data.pendiente
              
              this.setState({ formData: formDataCopy });
            }
            else{
              this.handleSubmit()
            }
          })
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })

  }

  handleCancelValidacionCorrectivoModal(){
    this.setState({validacionCorrectivo:false})
    let formDataCopy = this.state.formData;
    formDataCopy.ticketGarantiaId = null
    formDataCopy.ticketPendiente = null
    this.setState({ formData: formDataCopy });
    $("#validacion_correctivo_modal").modal('toggle');
  }

  handleSubmit(event) {
    event && event.preventDefault();
    let formDataFirstCopy = this.state.formData;
    if (this.state.llanta) {
      formDataFirstCopy.llanta = this.state.llanta;
      this.setState({ formData: formDataFirstCopy });
    }
    if (this.state.formData.tareas && this.state.formData.tareas.length > 0) {
      let servicio = this.state.formData.tareas[0].servicio;
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy.servicio = servicio;
      
      if(this.state.validacionCorrectivo === false && !this.state.llanta){
        this.handleValidacionCorrectivo(formDataCopy);
      }
      else{
        $('#validacion_correctivo_modal').modal('hide');
        this.setState(
          {
            formData: formDataCopy,
            loading: true,
          },
          () => {
            let component = this;
            this.ajaxHandler
              .fetch("/tickets", {
                method: "POST",
                body: JSON.stringify({
                  ...this.state.formData,
                }),
              })
              .then((response) => {
                if (response.status !== 400) {
                  if (formDataCopy.valorDinamico) {
                    response.json().then((data) => {
                      this.ajaxHandler
                        .fetch(
                          "/valordinamico/" +
                            formDataCopy.valorDinamico +
                            "/ticket",
                          {
                            method: "PUT",
                            body: JSON.stringify({
                              ticket_id: data.id,
                            }),
                          }
                        )
                        .then((response) => {
                          window.history.back();
                        });
                    });
                  } else {
                    component.exit();
                  }
                } else {
                  response.json().then((data) => {
                    this.setState({
                      errors: data.detalle,
                    });
                  });
                }
                window.scrollTo(0, 0);
              })
              .catch((error) => {
                this.ajaxHandler.handleError(error);
              })
              .finally(() => {
                this.setState({ loading: false });
              });
          }
        );
      }
    } else {
      let errors = this.state.errors;
      errors.push("correctivosAbm.errors.deber_ingresar_al_menos_una_tarea");
      this.setState({
        errors: errors,
      });
      window.scrollTo(0, 0);
    }
  }

  handleCancel(event) {
    window.history.back();
  }

  exit() {
    this.setState({
      redirectTo: "/tickets",
    });
  }

  handleAdjuntosChange(adjuntos) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.adjuntos = adjuntos;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleEntidadChange(e) {
    console.log(e);
    this.setState({ entidad: e });
  }

  handleLlantaChange(e) {
    console.log(e);
    this.setState({ llanta: e });
  }

  handleLlantaInputChange(e) {
    console.log(e);
    this.ajaxHandler.getJson("/llantas?serial=" + e).then((res) => {
      this.setState({ llantas: res.llantas });
    });
  }

  getBackendMoviles (searchTerm) {
    return this.ajaxHandler.getJson('/moviles/simple-search?search=' + searchTerm).then(res => {
      return {
        options: res,
        complete: true
      }
    });
  }

  render() {
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let requiredSymbol = this.state.props.action !== "VIEW" ? " *" : "";

    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.errors.length === 0}
            >
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage
                    {...(backendStrings[e]
                      ? backendStrings[e]
                      : backendStrings["errors.default"])}
                  />
                </li>
              ))}
            </div>
            <form
              className="form form-horizontal"
              ref="form"
              onSubmit={this.handleSubmit}
            >
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-info-circle"></i>
                        <FormattedMessage
                          id="correctivosAbm.datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                        <div
                          className="float-right"
                          style={{ fontSize: "14px" }}
                        >
                          <FormattedMessage
                            id="correctivosAbm.datos_generales.campos_requeridos"
                            defaultMessage="* campos requeridos"
                          />
                        </div>
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="base"
                            >
                              <FormattedMessage
                                id="correctivosAbm.datos_generales.label_entidad"
                                defaultMessage="Entidad"
                              /> *:
                            </label>
                            <div className="col-md-8">
                              {this.props.action === "VIEW" ? (
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.entidad
                                    ? this.state.entidad.label
                                    : ""}
                                </div>
                              ) : (
                                <div>
                                  <Select
                                    id="entidad"
                                    name="entidad"
                                    placeholder={this.props.intl.formatMessage({
                                      id:
                                        "correctivosAbm.datos_generales.placeholder_entidad",
                                      defaultMessage: "Entidad",
                                    })}
                                    options={entidades}
                                    valueKey="value"
                                    labelKey="label"
                                    disabled={!ConfigBusiness.get("llantas.habilitado") || !Security.hasPermission("LLANTAS_LISTAR") }
                                    value={
                                      this.state.entidad
                                        ? {
                                            value: this.state.entidad.value,
                                            label: this.state.entidad.label,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      this.handleEntidadChange(e)
                                    }
                                  />
                                  <div
                                    className="help-block text-danger field-message"
                                    hidden={
                                      validationState.formData.movil.pristine ||
                                      validationState.formData.movil.valid
                                    }
                                  >
                                    {validationState.formData.movil.message}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {this.state.llanta && this.state.llanta.movil ? (
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label
                                className="col-md-4 label-control col-form-label"
                                htmlFor="kmActual"
                              >
                                <FormattedMessage
                                  id="correctivosAbm.datos_generales.label_vehiculo_asociado"
                                  defaultMessage="Vehiculo Asociado :"
                                />
                              </label>
                              <div className="col-md-8">
                                <div className="form-control-static col-form-label form-value">
                                  {this.state.llanta.movil.dominio}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {this.state.entidad ? (
                        this.state.entidad.value === VALUE_LLANTA ? (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label
                                  className="col-md-4 label-control col-form-label"
                                  htmlFor="base"
                                >
                                  <FormattedMessage
                                    id="correctivosAbm.datos_generales.label_llanta"
                                    defaultMessage="Llanta"
                                  /> *:
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === "VIEW" ? (
                                    <div className="form-control-static col-form-label form-value">
                                      {formData.movil
                                        ? formData.movil.dominio
                                        : ""}
                                    </div>
                                  ) : (
                                    <div>
                                      <Select
                                        id="llanta"
                                        name="llanta"
                                        placeholder={this.props.intl.formatMessage(
                                          {
                                            id:
                                              "correctivosAbm.datos_generales.placeholder.select_llanta",
                                            defaultMessage: "Llanta",
                                          }
                                        )}
                                        options={this.state.llantas}
                                        valueKey="id"
                                        labelKey="serial"
                                        value={this.state.llanta}
                                        onInputChange={(e) =>
                                          this.handleLlantaInputChange(e)
                                        }
                                        onChange={(e) =>
                                          this.handleLlantaChange(e)
                                        }
                                      />
                                      <div
                                        className="help-block text-danger field-message"
                                        hidden={
                                          validationState.formData.movil
                                            .pristine ||
                                          validationState.formData.movil.valid
                                        }
                                      >
                                        {validationState.formData.movil.message}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {this.state.llanta ? (
                              <div className="col-md-6">
                                <div className="form-group row">
                                  <label
                                    className="col-md-4 label-control col-form-label"
                                    htmlFor="kmActual"
                                  >
                                    <FormattedMessage
                                      id="correctivosAbm.datos_generales.label_odometro_llanta"
                                      defaultMessage="Odometro Llanta :"
                                    />
                                  </label>
                                  <div className="col-md-8">
                                    <div className="form-control-static col-form-label form-value">
                                      {45645}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : null
                      ) : null}
                      {this.state.entidad ? (
                        this.state.entidad.value === VALUE_MOVIL ? (
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label
                                  className="col-md-4 label-control col-form-label"
                                  htmlFor="base"
                                >
                                  <FormattedMessage
                                    id="correctivosAbm.datos_generales.label_base"
                                    defaultMessage="Movil"
                                  /> *:
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === "VIEW" ? (
                                    <div className="form-control-static col-form-label form-value">
                                      {formData.movil
                                        ? formData.movil.dominio
                                        : ""}
                                    </div>
                                  ) : (
                                    <div>
                                      <Select.Async
                                          name="storageTypeId"
                                          value={formData.movil}
                                          valueKey="id"
                                          labelKey="dominio"
                                          loadOptions={this.getBackendMoviles}
                                          onChange={(e) =>
                                            this.handleMovilChange(e)
                                          }
                                          disabled={this.props.action === "VIEW"}
                                          placeholder={this.props.intl.formatMessage(
                                            {
                                              id:
                                                "correctivosAbm.datos_generales.placeholder_select_movil",
                                              defaultMessage: "Móvil",
                                            }
                                          )}
                                        />
                                      <div
                                        className="help-block text-danger field-message"
                                        hidden={
                                          validationState.formData.movil
                                            .pristine ||
                                          validationState.formData.movil.valid
                                        }
                                      >
                                        {validationState.formData.movil.message}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {formData.movil && formData.movil.unidadMedidorId ? (
                              <div className="form-group row">
                                <label
                                  className="col-md-4 label-control col-form-label"
                                  htmlFor="kmActual"
                                >
                                  { ID__UM_ODOMETRO === formData.movil.unidadMedidorId ? (
                                    <FormattedMessage
                                      id="correctivosAbm.datos_generales.km_actual"
                                      defaultMessage="Km. Actual :"/>
                                  ):''}
                                  { ID__UM_HOROMETRO ===  formData.movil.unidadMedidorId ? (
                                    <FormattedMessage
                                      id="correctivosAbm.datos_generales.horometro_actual"
                                      defaultMessage="Horometro Actual :"/>
                                  ):''}
                                </label>
                                <div className="col-md-8">
                                  <div className="form-control-static col-form-label form-value">
                                    {formData.movil && formData.movil.kmActual
                                      ? Math.floor(formData.movil.kmActual)
                                      : ""}
                                  </div>
                                </div>
                              </div>
                              ):''}
                            </div>
                          </div>
                        ) : null
                      ) : null}
                      <div className="row">
                        {/* DETALLE */}
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label
                              className="col-md-2 label-control col-form-label"
                              htmlFor="detalle"
                            >
                              <FormattedMessage
                                id="correctivosAbm.datos_generales.detallle"
                                defaultMessage="Detallle:"
                              />
                            </label>
                            <div className="col-md-10">
                              <div>
                                <textarea
                                  className="form-control"
                                  id="detalle"
                                  name="detalle"
                                  rows="3"
                                  placeholder={this.props.intl.formatMessage({
                                    id:
                                      "correctivosAbm.datos_generales.placeholder.detalle",
                                    defaultMessage: "Detalle",
                                  })}
                                  value={
                                    formData.detalle ? formData.detalle : ""
                                  }
                                  onChange={this.handleInputFormChange}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* AUTOGESTIÓN */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label text-nowrap"
                              htmlFor="autogestion"
                            >
                              <FormattedMessage
                                id="correctivosAbm.datos_generales.autogestion"
                                defaultMessage="Autogestión:"
                              />
                            </label>
                            <div className="col-md-8">
                              <Switch
                                onChange={this.handleAutogestionChange}
                                checked={formData.autogestion}
                                id="autogestion"
                                name="autogestion"
                                disabled={
                                  this.state.props.action === "VIEW"
                                    ? true
                                    : false
                                }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group row">
                                <label
                                  className="col-md-4 label-control col-form-label text-nowrap"
                                  htmlFor="siniestro"
                                >
                                  <FormattedMessage
                                    id="correctivosAbm.datos_generales.siniestro"
                                    defaultMessage="Siniestro:"
                                  />
                                </label>
                                <div className="col-md-8">
                                  <Switch
                                    onChange={this.handleSiniestroChange}
                                    checked={formData.esSiniestro}
                                    id="siniestro"
                                    name="siniestro"
                                    offColor="#FF4961"
                                    onColor="#28D094"
                                  />
                                </div>
                              </div>
                            </div>
                            {this.state.formData.esSiniestro ? (
                              <div className="col-md-8">
                                <div className="form-group row">
                                  <label
                                    className="col-md-4 label-control col-form-label text-nowrap"
                                    htmlFor="numeroSiniestro"
                                  >
                                    <FormattedMessage
                                      id="correctivosAbm.datos_generales.nro_siniestro"
                                      defaultMessage="nro_siniestro"
                                    />
                                  </label>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="numeroSiniestro"
                                      name="numeroSiniestro"
                                      placeholder="Número de Siniestro"
                                      value={
                                        formData.numeroSiniestro
                                          ? formData.numeroSiniestro
                                          : ""
                                      }
                                      onChange={this.handleInputFormChange}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          {/* Inmovilizado */}
                          {this.state.inmovilizadoEnabled ? (
                            <div className="form-group row">
                              <label
                                className="col-md-4 label-control col-form-label"
                                htmlFor="inmovilizado"
                              >
                                <FormattedMessage
                                  id="correctivosAbm.datos_generales.inmovilizado"
                                  defaultMessage="Inmovilizado"
                                />
                                {requiredSymbol}:
                              </label>
                              <div className="col-md-8">
                                {this.props.action === "VIEW" ? (
                                  <div className="form-control-static col-form-label form-value">
                                    {this.state.formData.inmovilizado
                                      ? this.state.formData.inmovilizado
                                      : ""}
                                  </div>
                                ) : (
                                  <div>
                                    <Select
                                      id="inmovilizado"
                                      name="inmovilizado"
                                      placeholder={"Seleccione"}
                                      options={this.state.inmovilizadoOptions}
                                      valueKey="value"
                                      labelKey="label"
                                      value={
                                        formData.inmovilizado
                                          ? {
                                              value: formData.inmovilizado,
                                              label:
                                                formData.inmovilizado === 2
                                                  ? "NO"
                                                  : "SI",
                                            }
                                          : null
                                      }
                                      onChange={(e) =>
                                        this.handleInmovilizadoChange(e)
                                      }
                                      //isLoading={state.estadosLoading}
                                      //disabled={state.estadosLoading}
                                    />
                                    {validationState.formData.inmovilizado ? (
                                      <div
                                        className="help-block text-danger field-message"
                                        hidden={
                                          validationState.formData.inmovilizado
                                            .pristine ||
                                          validationState.formData.inmovilizado
                                            .valid
                                        }
                                      >
                                        {
                                          validationState.formData.inmovilizado
                                            .message
                                        }
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            {/* ABONO */}
                            {ConfigBusiness.get(
                              "mantenimientos.correctivo.abono.habilitado"
                            ) === "true" ? (
                              <div className="col-md-4">
                                <div className="form-group row">
                                  <label
                                    className="col-md-4 label-control col-form-label text-nowrap"
                                    htmlFor="abono"
                                  >
                                    <FormattedMessage
                                      id="correctivosAbm.datos_generales.abono"
                                      defaultMessage="Abono:"
                                    />
                                  </label>
                                  <div className="col-md-8">
                                    <Switch
                                      onChange={this.handleAbonoChange}
                                      checked={formData.abono}
                                      id="abono"
                                      name="abono"
                                      disabled={
                                        this.state.props.action === "VIEW" ||
                                        this.state.abonoDisabled
                                          ? true
                                          : false
                                      }
                                      offColor="#FF4961"
                                      onColor="#28D094"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* GERENCIADOR */}
                            <div
                              className="col-md-8"
                              style={{
                                display:
                                  formData.abono ||
                                  this.state.gerenciadoresEnabled
                                    ? ""
                                    : "none",
                              }}
                            >
                              <div className="form-group row">
                                <label
                                  className="col-md-4 label-control col-form-label"
                                  htmlFor="gerenciador"
                                >
                                  <FormattedMessage
                                    id="correctivosAbm.datos_generales.gerenciador"
                                    defaultMessage="Gerenciador:"
                                  />
                                </label>
                                <div className="col-md-8">
                                  {this.props.action === "VIEW" ? (
                                    <div className="form-control-static col-form-label form-value">
                                      {formData.gerenciador
                                        ? formData.gerenciador
                                        : ""}
                                    </div>
                                  ) : (
                                    <div>
                                      <Select
                                        id="gerenciador"
                                        name="gerenciador"
                                        placeholder={this.props.intl.formatMessage(
                                          {
                                            id:
                                              "correctivosAbm.datos_generales.placeholder.gerenciador",
                                            defaultMessage: "Gerenciador",
                                          }
                                        )}
                                        options={this.state.gerenciadores}
                                        valueKey="value"
                                        labelKey="label"
                                        value={
                                          formData.gerenciador
                                            ? {
                                                value: formData.gerenciador.id,
                                                label:
                                                  formData.gerenciador
                                                    .razonSocial,
                                              }
                                            : null
                                        }
                                        onChange={(e) =>
                                          this.handleGerenciadorChange(e)
                                        }
                                      />
                                      <div
                                        className="help-block text-danger field-message"
                                        hidden={
                                          validationState.formData.movil
                                            .pristine ||
                                          validationState.formData.movil.valid
                                        }
                                      >
                                        {validationState.formData.movil.message}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(ConfigBusiness.get("tickets.reemplazoVehiculo.habilitado") === "true" && (this.state.entidad && this.state.entidad.value === VALUE_MOVIL)) ? (
											<div className="row">
												<div className="col-md-3">
													<div className="form-group row">
														<label
															className="col-md-8 label-control col-form-label text-nowrap" htmlFor="reemplazoVehiculo">
															<FormattedMessage
																id="correctivosAbm.datos_generales.reemplazo_vehiculo"
																defaultMessage="Reemplazo de Vehículo:"
															/>
														</label>
														<div className="col-md-4">
															<Switch
																onChange={this.handleReemplazoVehiculoChange}
																checked={formData.esReemplazoDeVehiculo}
																id="reemplazoVehiculo"
																name="reemplazoVehiculo"
																offColor="#FF4961"
																onColor="#28D094"
																/>
														</div>
													</div>
												</div>
												{this.state.formData.esReemplazoDeVehiculo ? (
													<div className="col-md-3">
														<div className="col-md-12">
															<div className="form-group row">
																<label className="col-md-7 label-control col-form-label text-nowrap" htmlFor="fechaEntrega" >
																	<FormattedMessage id="correctivosAbm.datos_generales.fecha_entrega" defaultMessage="Fecha de entrega:" />
																</label>
																<div className="col-md-5">
																	<DatePicker
																	id="fechaEntrega"
																	name="fechaEntrega"
																	className="form-control date-picker-placeholder"
																	placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_de_alta', defaultMessage: 'DD/MM/AAAA' })}
																	selected={formData.fechaEntrega === null || formData.fechaEntrega === '0000-00-00' ? null : moment(formData.fechaEntrega) }
																	onChange={(event) => this.handleDatePickerFormChange("fechaEntrega", event)}
																	onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaEntrega", event.target.value)}
																	/>
																</div>
															</div>
														</div>
													</div>
													) : null}
													{this.state.formData.esReemplazoDeVehiculo ? (
													<div className="col-md-3">
														<div className="col-md-12">
															<div className="form-group row">
																<label className="col-md-7 label-control col-form-label text-nowrap" htmlFor="placaReemplazo" >
																	<FormattedMessage id="correctivosAbm.datos_generales.placa_reemplazo" defaultMessage="Placa de reemplazo:" />
																</label>
																<div className="col-md-5">
																<input
																	type="text"
																	className="form-control"
																	id="placaReemplazo"
																	name="placaReemplazo"
																	placeholder="Placa de reemplazo"
																	value={formData.placaReemplazo ? formData.placaReemplazo : ""}
																	onChange={this.handleInputFormChange}
																></input>
																</div>
															</div>
														</div>
													</div>
													) : null}
											</div>
											) : ("")}
                      {(ConfigBusiness.get("tickets.OTAsociada.habilitado") === "true") ? (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="otAsociada" >
                              <FormattedMessage id="correctivosAbm.datos_generales.ot_asociada" defaultMessage="OT. Asociada" />:
                            </label>
                            <div className="col-md-8">
                              <input
                                type="text"
                                className="form-control"
                                id="otAsociada"
                                name="otAsociada"
                                placeholder="OT. Asociada"
                                maxLength="20"
                                value={formData.otAsociada ? formData.otAsociada : ""}
                                onChange={this.handleInputFormChange}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      ) : ("")}
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="la la-cog"></i>
                        <FormattedMessage
                          id="correctivosAbm.tareas"
                          defaultMessage=" Tareas"
                        />
                      </h4>
                      <div
                        className="row"
                        style={{
                          display: this.state.displaySelectTareas ? "" : "none",
                        }}
                      >
                        {/* SERVICIO CON TAREAS NO ASIGNABLES*/}
                        <div className="col-md-5">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="servicio"
                            >
                              <FormattedMessage
                                id="correctivosAbm.tareas.servicio"
                                defaultMessage="Servicio"
                              /> *:
                            </label>
                            <div className="col-md-8">
                              <div>
                                <Select
                                  id="servicioNA"
                                  name="servicioNA"
                                  placeholder={this.props.intl.formatMessage({
                                    id:
                                      "correctivosAbm.tareas.placeholder.servicio",
                                    defaultMessage: "Servicio",
                                  })}
                                  options={this.state.servicios}
                                  valueKey="value"
                                  labelKey="label"
                                  value={this.state.servicioSeleccionado}
                                  onChange={(e) => this.handleServicioChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-1 p-0"
                          style={{
                            display:
                              this.state.displaySelectTareas ||
                              this.state.servicioSeleccionado === null ||
                              this.state.tareasIsLoading
                                ? "none"
                                : "",
                          }}
                        >
                          <button
                            className="btn btn-icon-add text-center text-fleet p-0"
                            role="button"
                            onClick={(e) => {
                              this.addServicioNoAsignable();
                              this.handleAddTarea();
                              e.preventDefault();
                            }}
                            style={{ backgroundColor: "#FFF" }}
                          >
                            <i className="ft-plus-circle"></i>
                          </button>
                        </div>
                        <div className="col-md-6">
                          <div className="bs-callout-info callout-border-left mb-1 p-1">
                            <strong>
                              <FormattedMessage
                                id="correctivosAbm.tareas.information.servicio.recuerde_que"
                                defaultMessage="Recuerde que..."
                              />
                            </strong>
                            <p>
                              <FormattedMessage
                                id="correctivosAbm.tareas.information.servicio.primer_servicio_seleccionado_mayor_importancia"
                                defaultMessage="El primer servicio seleccionado debe ser el de mayor importancia."
                              />
                            </p>
                          </div>
                        </div>

                        {/* TAREAS */}
                        <div
                          className="col-md-5"
                          style={{
                            display: this.state.displaySelectTareas
                              ? ""
                              : "none",
                          }}
                        >
                          <div className="form-group row">
                            <label
                              className="col-md-3 label-control col-form-label"
                              htmlFor="tareasServicio"
                            >
                              <FormattedMessage
                                id="correctivosAbm.tareas.select.add.tareas"
                                defaultMessage="Tareas"
                              /> *:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === "VIEW" ? null : (
                                <div>
                                  <Select
                                    id="tareasServicioNA"
                                    name="tareasServicioNA"
                                    placeholder={this.props.intl.formatMessage({
                                      id:
                                        "correctivosAbm.tareas.select.add.placeholder",
                                      defaultMessage: "Tareas",
                                    })}
                                    options={this.state.listaSelectTareas}
                                    valueKey="value"
                                    labelKey="label"
                                    closeOnSelect={true}
                                    multi
                                    removeSelected={true}
                                    joinValues
                                    value={this.state.tareasSeleccionadas}
                                    disabled={this.state.tareasDisabled}
                                    isLoading={this.state.tareasIsLoading}
                                    onChange={this.handleTareasChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-2 p-0"
                          style={{
                            display: this.state.displaySelectTareas
                              ? ""
                              : "none",
                          }}
                        >
                          <button
                            className="btn btn-icon-add text-center text-fleet p-0"
                            role="button"
                            onClick={(e) => {
                              this.handleAddTarea();
                              e.preventDefault();
                            }}
                            style={{ backgroundColor: "#FFF" }}
                          >
                            <i className="ft-plus-circle"></i>
                          </button>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          display: this.state.displaySelectTareas ? "none" : "",
                        }}
                      >
                        {/* SERVICIO */}
                        <div className="col-md-6 pt-1">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="servicio"
                            >
                              <FormattedMessage
                                id="correctivosAbm.tareas.select.add.servicio"
                                defaultMessage="Servicio"
                              /> *:
                            </label>
                            <div className="col-md-8">
                              <div>
                                <Select
                                  id="servicio"
                                  name="servicio"
                                  placeholder={this.props.intl.formatMessage({
                                    id:
                                      "correctivosAbm.tareas.select.add.placeholder.servicio",
                                    defaultMessage: "Servicio",
                                  })}
                                  options={this.state.servicios}
                                  valueKey="value"
                                  labelKey="label"
                                  value={this.state.servicioSeleccionado}
                                  onChange={(e) => this.handleServicioChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bs-callout-info callout-border-left mb-1 p-1">
                            <strong>
                              <FormattedMessage
                                id="correctivosAbm.tareas.select.add.information.recuerde_que"
                                defaultMessage="Recuerde que..."
                              />
                            </strong>
                            <p>
                              <FormattedMessage
                                id="correctivosAbm.tareas.select.add.information.primer_servicio_seleccionado_mayor_importancia"
                                defaultMessage="El primer servicio seleccionado debe ser el de mayor importancia."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          display: formData.tareas.length > 0 ? "" : "none",
                        }}
                      >
                        {/* TAREAS SELECCIONADAS */}
                        <div className="col-md-12">
                          <table className="table">
                            <thead className="thead-fleet">
                              <tr>
                                <th scope="col">
                                  <FormattedMessage
                                    id="correctivosAbm.tareas.added_tasks.column_table.servicio"
                                    defaultMessage="Servicio"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="correctivosAbm.tareas.added_tasks.column_table.tareas"
                                    defaultMessage="Tareas"
                                  />
                                </th>
                                <th scope="col">
                                  <FormattedMessage
                                    id="correctivosAbm.tareas.added_tasks.column_table.eliminar"
                                    defaultMessage="Eliminar"
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.tareas.map((tarea, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="align-middle">
                                      {tarea.servicio.nombre}
                                    </td>
                                    <td className="align-middle">
                                      {tarea.nombre}
                                    </td>
                                    <td>
                                      <a
                                        className="btn btn-icon text-danger"
                                        role="button"
                                        onClick={(e) =>
                                          this.handleDeleteTarea(index)
                                        }
                                      >
                                        <i className="fa fa-trash fa-xs"></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <CorrectivosAbmAdjuntos
                  callbackUpdate={this.handleAdjuntosChange.bind(this)}
                ></CorrectivosAbmAdjuntos>

                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== "VIEW" && (
                          <button
                            type="submit"
                            className="btn btn-primary mr-1"
                            disabled={this.state.loading || !this.state.entidad }
                          >
                            <i className="fa fa-check-circle"></i>
                            <FormattedMessage
                              id="correctivosAbm.button.finish.guardar"
                              defaultMessage=" Guardar"
                            />
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.handleCancel.bind(this)}
                        >
                          <i className="fa fa-times-circle"></i>{" "}
                          {this.props.action === "VIEW"
                            ? this.props.intl.formatMessage({
                                id: "correctivosAbm.button.finish.volver",
                                defaultMessage: "Volver",
                              })
                            : this.props.intl.formatMessage({
                                id: "correctivosAbm.button.finish.cancelar",
                                defaultMessage: "Cancelar",
                              })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal fade col-8" tabIndex="-1" id="validacion_correctivo_modal" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="myModalLabel1">{/*<i className="la la-history align-middle icon-modal-title"></i>*/}
									<FormattedMessage id="correctivosAbm.modal.title.validacion" defaultMessage=" Ticket Validación"/>
								</h4>
								<button type="button" className="close" onClick={this.handleCancelValidacionCorrectivoModal} data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<div className="modal-body" style={{margin: "auto"}}>
                <div className="card">
                  <div className="card-content">
                    <div className="card-header">
                      {this.state.formData.ticketPendiente &&
                        <p>
                          El vehículo {this.state.formData.movil && this.state.formData.movil.dominio} &nbsp;
                          tiene un mantenimiento de tipo correctivo pendiente en el ticket <b>{this.state.formData.ticketPendiente.ticketId} - {this.state.formData.ticketPendiente.servicioNombre}</b> : &nbsp; 
                            <a href={"/tickets/"+this.state.formData.ticketPendiente.ticketId} target="_blank">Ticket</a>
                        </p>
                      }
                      {(this.state.formData.ticketPendiente && this.state.formData.ticketGarantiaId) &&
                        <p>
                          A su vez
                        </p>
                      }
                      {this.state.formData.ticketGarantiaId &&
                        <p>
                          El vehículo {this.state.formData.movil && this.state.formData.movil.dominio} &nbsp;
                          tuvo un mantenimiento de tipo correctivo hace menos de {this.state.diasGarantia} días 
                          en el ticket <b>{this.state.formData.ticketGarantiaId}</b> : &nbsp; 
                          {this.state.tareasCorrectivoGarantia.length 
                            && 
                            <a href={"/tickets/"+this.state.formData.ticketGarantiaId} target="_blank">Ticket</a>
                          }
                          &nbsp; con las siguientes tareas coincidentes:
                        </p>
                      }
                      
                    </div>
                    <div className="card-body">
                    {this.state.formData.ticketGarantiaId &&
                      <div className="d-flex justify-content-start" style={{border: 0}}>
                          <ul>
                            {this.state.tareasCorrectivoGarantia.map((tarea, i) => (
                              <li key={i}>{tarea.nombre}</li>
                            ))}
                          </ul>
                      </div>
                    }
                    <p>
                      ¿Desea continuar de todas maneras?
                    </p>
                    </div>
                  </div>
                </div>
							</div>
              <div className="modal-footer modal-grey">
                <div className="text-cd text-right">
                  <form
                    className="form form-horizontal"
                    ref="form-control"
                    onSubmit={this.handleSubmit}
                  >
                    <button type="submit" className="btn btn-primary mr-1" disabled={this.state.loading}>
                      <i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-check-circle'}></i>
                      <FormattedMessage id="correctivosAbm.button.finish.continuar" defaultMessage=" Continuar"/>
                    </button>
                    <button type="button" className="btn btn-danger" onClick={this.handleCancelValidacionCorrectivoModal}>
                      <i className="fa fa-times-circle"></i>
                      <FormattedMessage id="correctivosAbm.button.finish.cancelar" defaultMessage=" Cancelar"/>
                    </button>
                  </form>
                </div>
              </div>
						</div>
					</div>
				</div>
      </React.Fragment>
    );
  }
}

export default injectIntl(CorrectivosAbm);
