import React, { Component } from "react";
import {injectIntl} from 'react-intl';

class BootstrapSpinner extends Component {
	render() {
	    return (
			<div className="row justify-content-center d-flex">
                <div className="col-auto">
                    <i className="la la-spinner spinner" style={{ fontSize: "150px" }}></i>
                </div>
            </div>
		);
  	}
}

export default injectIntl(BootstrapSpinner);
