import {defineMessage} from 'react-intl';

export default {
    'errors.default': defineMessage({
        id: 'errors.default',
        defaultMessage: 'Ha ocurrido un error'
    }),
    'login.errors.usuario_no_existe': defineMessage({
        id: 'login.errors.usuario_no_existe',
        defaultMessage: 'El usuario no existe.'
    }),
    'login.errors.contraseña_incorrecta': defineMessage({
        id: 'login.errors.contraseña_incorrecta',
        defaultMessage: 'La contraseña es incorrecta.'
    }),
    'login.errors.activar_cuenta': defineMessage({
        id: 'login.errors.activar_cuenta',
        defaultMessage: 'Debe activar su cuenta.'
    }),
    'forgotPassword.errors.email_ingresado_no_corresponde_usuario_activo': defineMessage({
        id: 'forgotPassword.errors.email_ingresado_no_corresponde_usuario_activo',
        defaultMessage: 'El email ingresado no corresponde a un usuario activo.'
    }),
    'forgotPassword.errors.debe_activar_cuenta': defineMessage({
        id: 'forgotPassword.errors.debe_activar_cuenta',
        defaultMessage: 'Debe activar su cuenta.'
    }),
    'changePassword.errors.contrasenia_debe_ser_diferente_anteriores': defineMessage({
        id: 'changePassword.errors.contrasenia_debe_ser_diferente_anteriores',
        defaultMessage: 'La contraseña debe ser diferente a las anteriores.'
    }),
    'changePassword.errors.contrasenia_nueva_diferente_actual': defineMessage({
        id: 'changePassword.errors.contrasenia_nueva_diferente_actual',
        defaultMessage: 'La contraseña nueva debe ser diferente a la actual.'
    }),
    'changePassword.errors.contrasenia_actual_incorrecta': defineMessage({
        id: 'changePassword.errors.contrasenia_actual_incorrecta',
        defaultMessage: 'La contraseña actual es incorrecta.'
    }),
    'changePassword.errors.sesion_no_corresponde_usuario_activo': defineMessage({
        id: 'changePassword.errors.sesion_no_corresponde_usuario_activo',
        defaultMessage: 'La sesión no corresponde a un usuario activo.'
    }),
    'changePassword.errors.contrasenias_ingresadas_no_coinciden': defineMessage({
        id: 'changePassword.errors.contrasenias_ingresadas_no_coinciden',
        defaultMessage: 'Las contraseñas ingresadas no coinciden.'
    }),
    'azureCallback.errors.faltan_parametros_validar': defineMessage({
        id: 'azureCallback.errors.faltan_parametros_validar',
        defaultMessage: 'Faltan parámetros para validar su cuenta'
    }),
    'azureCallback.errors.token_no_encontrado_servidor': defineMessage({
        id: 'azureCallback.errors.token_no_encontrado_servidor',
        defaultMessage: 'Error: token no encontrado en respuesta del servidor'
    }),
    'azureCallback.errors.pedir_token_usuario': defineMessage({
        id: 'azureCallback.errors.pedir_token_usuario',
        defaultMessage: 'Error: error al pedir el token del usuario'
    }),
    'azureCallback.errors.lado_del_servidor': defineMessage({
        id: 'azureCallback.errors.lado_del_servidor',
        defaultMessage: 'Error: error del lado del servidor'
    }),
    'internalLogin.errors.acceso_denegado': defineMessage({
        id: 'internalLogin.errors.acceso_denegado',
        defaultMessage: 'Acceso denegado.'
    }),
    'recoverPassword.errors.clave_activacion_invalida': defineMessage({
        id: 'recoverPassword.errors.clave_activacion_invalida',
        defaultMessage: 'Clave de activación inválida.'
    }),
    'recoverPassword.errors.clave_expiro': defineMessage({
        id: 'recoverPassword.errors.clave_expiro',
        defaultMessage: 'La clave de activación expiró.'
    }),
    'recoverPassword.errors.contrasenia_diferente_anteriores': defineMessage({
        id: 'recoverPassword.errors.contrasenia_diferente_anteriores',
        defaultMessage: 'La contraseña debe ser diferente a las anteriores.'
    }),
    'recoverPassword.errors.contrasenias_coinciden': defineMessage({
        id: 'recoverPassword.errors.contrasenias_coinciden',
        defaultMessage: 'Las contraseñas ingresadas no coinciden.'
    }),
    'combustiblesAbm.errors.no_pudo_obtener_archivo_modelo': defineMessage({
        id: 'combustiblesAbm.errors.no_pudo_obtener_archivo_modelo',
        defaultMessage: 'No se pudo obtener el archivo modelo'
    }),
    'correctivosAbm.errors.deber_ingresar_al_menos_una_tarea': defineMessage({
        id: 'correctivosAbm.errors.deber_ingresar_al_menos_una_tarea',
        defaultMessage: 'Debe ingresar al menos una tarea.'
    }),
    'facturasAbm.errors.no_puede_dejar_datos_factura_sin_completar': defineMessage({
        id: 'facturasAbm.errors.no_puede_dejar_datos_factura_sin_completar',
        defaultMessage: 'No puede dejar datos de la factura sin completar.'
    }),
    'infraccionesService.errors.no_se_pudo_obtener_archivo_modelo': defineMessage({
        id: 'infraccionesService.errors.no_se_pudo_obtener_archivo_modelo',
        defaultMessage: 'No se pudo obtener el archivo modelo'
    }),
    'oTAbm.errors.no_puede_dejar_datos_sin_completar': defineMessage({
        id: 'oTAbm.errors.no_puede_dejar_datos_sin_completar',
        defaultMessage: 'No puede dejar datos sin completar.'
    }),
    'oTAbm.errors.debe_seleccionar_al_menos_un_ticket': defineMessage({
        id: 'oTAbm.errors.debe_seleccionar_al_menos_un_ticket',
        defaultMessage: 'Debe seleccionar al menos un ticket.'
    }),
    'personaController.errors.la_persona_tiene_tickets_pendientes_gestionar_cancelar_los_mismos_para_poder_realizar_el_cambio_de_estado': defineMessage({
        id: 'personaController.errors.la_persona_tiene_tickets_pendientes_gestionar_cancelar_los_mismos_para_poder_realizar_el_cambio_de_estado',
        defaultMessage: 'La persona tiene tickets pendientes, por favor gestionar/cancelar los mismos para poder realizar el cambio de estado de la persona'
    }),
    'personaController.errors.la_persona_tiene_controles_activos_desactivar_los_mismos_para_poder_realizar_cambio_de_estado': defineMessage({
        id: 'personaController.errors.la_persona_tiene_controles_activos_desactivar_los_mismos_para_poder_realizar_cambio_de_estado',
        defaultMessage: 'La persona tiene controles activos, favor desactivar los mismos para poder realizar el cambio de estado de la persona'
    }),
    'personaController.errors.la_persona_se_encuentra_asociada_a_un_vehiculo,_remover_dicha_relacion': defineMessage({
        id: 'personaController.errors.la_persona_se_encuentra_asociada_a_un_vehiculo,_remover_dicha_relacion',
        defaultMessage: 'La persona tiene controles activos, favor desactivar los mismos para poder realizar el cambio de estado de la persona'
    }),
    'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea': defineMessage({
        id: 'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea',
        defaultMessage: 'Debe ingresar al menos una tarea.'
    }),
    'personaRepository.errors.direccion_email_existe': defineMessage({
        id: 'personaRepository.errors.direccion_email_existe',
        defaultMessage: 'La dirección de email ingresada ya existe.'
    }),
    'personaRepository.errors.nombre_usuario_existe': defineMessage({
        id: 'personaRepository.errors.nombre_usuario_existe',
        defaultMessage: 'El nombre de Usuario ingresado ya existe.'
    }),
    'personaRepository.errors.llave_ingresada_no_valida': defineMessage({
        id: 'personaRepository.errors.llave_ingresada_no_valida',
        defaultMessage: 'La llave ingresada no es válida.'
    }),
    'almacenController.errors.no_existe_almacen_con_ese_id': defineMessage({
        id: 'almacenController.errors.no_existe_almacen_con_ese_id',
        defaultMessage: 'No existe el almacen con ese id'
    }),
    'almacenRepository.errors.almacen_ya_existe': defineMessage({
        id: 'almacenRepository.errors.almacen_ya_existe',
        defaultMessage: 'El almacen ya existe.'
    }),
    'authController.errors.acceso_denegado': defineMessage({
        id: 'authController.errors.acceso_denegado',
        defaultMessage: 'Acceso denegado.'
    }),
    'authController.errors.azure_token_grant_invalido': defineMessage({
        id: 'authController.errors.azure_token_grant_invalido',
        defaultMessage: 'Azure token_grant inválido'
    }),
    'authService.errors.no_se_encontro_una_sesion_activa': defineMessage({
        id: 'authController.errors.no_se_encontro_una_sesion_activa',
        defaultMessage: 'No se encontró una sesión activa.'
    }),
    'authService.errors.contrasenia_debe_tener_una_longitud_entre': defineMessage({
        id: 'authService.errors.contrasenia_debe_tener_una_longitud_entre',
        defaultMessage: 'La contraseña debe tener una longitud entre 8 y 16 caracteres.'
    }),
    'authService.errors.contrasenia_contener_al_menos_una_letra': defineMessage({
        id: 'authService.errors.contrasenia_contener_al_menos_una_letra',
        defaultMessage: 'La contraseña debe contener al menos una letra.'
    }),
    'authService.errors.contrasenia_contener_al_menos_un_numero': defineMessage({
        id: 'authService.errors.contrasenia_contener_al_menos_un_numero',
        defaultMessage: 'La contraseña debe contener al menos un número.'
    }),
    'authService.errors.contrasenia_no_debe_contener_espacios_en_blanco': defineMessage({
        id: 'authService.errors.contrasenia_no_debe_contener_espacios_en_blanco',
        defaultMessage: 'La contraseña no debe contener espacios en blanco.'
    }),
    'authService.errors.contrasenia_no_debe_contener': defineMessage({
        id: 'authService.errors.contrasenia_no_debe_contener',
        defaultMessage: 'La contraseña debe contener sólo letras, números y alguno de los siguientes símbolos . ! ¿ ? @ $ & # % * + - _ ='
    }),
    'authService.errors.contrasenia_no_incluir_nombre_de_usuario': defineMessage({
        id: 'authService.errors.contrasenia_no_incluir_nombre_de_usuario',
        defaultMessage: 'La contraseña no puede incluir el nombre de usuario.'
    }),
    'basesRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'basesRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'basesRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'basesRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'basesRepository.errors.ya_existe_base_con_ese_nombre': defineMessage({
        id: 'basesRepository.errors.ya_existe_base_con_ese_nombre',
        defaultMessage: 'Ya existe una Base con ese nombre.'
    }),
    'centrosBeneficiosRepository.errors.existe_centro_beneficio_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'centrosBeneficiosRepository.errors.existe_centro_beneficio_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Centro de Beneficio con ese nombre y debe ser unico.'
    }),
    'centrosBeneficiosRepository.errors.no_se_puede_borrar_centro_beneficios_esta_asociada_otro_elemento': defineMessage({
        id: 'centrosBeneficiosRepository.errors.no_se_puede_borrar_centro_beneficios_esta_asociada_otro_elemento',
        defaultMessage: 'No se puede borrar el Centro de Beneficios ya que esta asociada a otro elemento.'
    }),
    'centrosCostosRepository.errors.existe_centro_costo_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'centrosCostosRepository.errors.existe_centro_costo_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Centro de Costo con ese nombre y debe ser unico.'
    }),
    'centrosCostosRepository.errors.no_se_puede_borrar_centro_costos_esta_asociada_otro_elemento': defineMessage({
        id: 'centrosCostosRepository.errors.no_se_puede_borrar_centro_costos_esta_asociada_otro_elemento',
        defaultMessage: 'No se puede borrar el Centro de Costos ya que esta asociada a otro elemento.'
    }),
    'combustibleBanderasRepository.errors.error_al_ingresar_nueva_bandera_combustible': defineMessage({
        id: 'combustibleBanderasRepository.errors.error_al_ingresar_nueva_bandera_combustible',
        defaultMessage: 'Error al ingresar una nueva bandera de combustible'
    }),
    'combustiblesController.errors.contacte_al_administrador': defineMessage({
        id: 'combustiblesController.errors.contacte_al_administrador',
        defaultMessage: 'Contacte al administrador.'
    }),
    'combustiblesController.errors.error_al_subir_el_archivo': defineMessage({
        id: 'combustiblesController.errors.error_al_subir_el_archivo',
        defaultMessage: 'Ocurrió un error al subir el archivo'
    }),
    'combustiblesService.errors.no_pudo_realizar_importacion_fallo_creacion_log': defineMessage({
        id: 'combustiblesService.errors.no_pudo_realizar_importacion_fallo_creacion_log',
        defaultMessage: 'NO se pudo realizar la importación. Falló la creación del log'
    }),
    'combustiblesRepository.errors.el_movil_ingresado_invalido': defineMessage({
        id: 'combustiblesRepository.errors.el_movil_ingresado_invalido',
        defaultMessage: 'El móvil ingresado para la carga es inválido'
    }),
    'combustiblesRepository.errors.carga_combustible_estaduplicada': defineMessage({
        id: 'combustiblesRepository.errors.carga_combustible_estaduplicada',
        defaultMessage: 'La carga de combustible está duplicada'
    }),
    'combustiblesRepository.errors.fecha_hora_de_carga_combustible_invalida': defineMessage({
        id: 'combustiblesRepository.errors.fecha_hora_de_carga_combustible_invalida',
        defaultMessage: 'La Fecha y Hora de carga de combustible es inválida'
    }),
    'combustiblesRepository.errors.error_base_datos': defineMessage({
        id: 'combustiblesRepository.errors.error_base_datos',
        defaultMessage: 'Error en la base de datos'
    }),
    'combustiblesRepository.errors.no_se_pudieron_leer_columnas_del_archivo': defineMessage({
        id: 'combustiblesRepository.errors.no_se_pudieron_leer_columnas_del_archivo',
        defaultMessage: 'No se pudieron leer las columnas del archivo. Contacte al administrador'
    }),
    'combustiblesRepository.errors.la_carga_no_existe': defineMessage({
        id: 'combustiblesRepository.errors.la_carga_no_existe',
        defaultMessage: 'La carga que se quiere actualizar no existe'
    }),
    'combustiblesRepository.errors.carga_combustible_duplicada': defineMessage({
        id: 'combustiblesRepository.errors.carga_combustible_duplicada',
        defaultMessage: 'La carga de combustible está duplicada'
    }),
    'combustiblesRepository.errors.error_buscar_datos': defineMessage({
        id: 'combustiblesRepository.errors.error_buscar_datos',
        defaultMessage: 'Error al buscar los datos'
    }),
    'combustiblesRepository.errors.archivo_no_cumple_formato_de_EDENRED_faltan_columnas': defineMessage({
        id: 'combustiblesRepository.errors.archivo_no_cumple_formato_de_EDENRED_faltan_columnas',
        defaultMessage: 'El archivo no cumple el formato de EDENRED, faltan columnas'
    }),
    'combustiblesRepository.errors.encabezado_no_esta_en_fila_correcta': defineMessage({
        id: 'combustiblesRepository.errors.encabezado_no_esta_en_fila_correcta',
        defaultMessage: 'El encabezado no esta en la fila correcta.'
    }),
    'combustiblesRepository.errors.archivo_no_cumple_formato_YPF_faltan_columnas': defineMessage({
        id: 'combustiblesRepository.errors.archivo_no_cumple_formato_YPF_faltan_columnas',
        defaultMessage: 'El archivo no cumple el formato de YPF, faltan columnas.'
    }),
    'combustiblesRepository.errors.archivo_no_cumple_formato_COPEC_faltan_columnas': defineMessage({
        id: 'combustiblesRepository.errors.archivo_no_cumple_formato_COPEC_faltan_columnas',
        defaultMessage: 'El archivo no cumple el formato de COPEC, faltan columnas.'
    }),
    'combustiblesRepository.basicCardInfo.description.gasto_total_de_combustible': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.gasto_total_de_combustible',
        defaultMessage: 'Gasto Total de Combustible'
    }),
    'combustiblesRepository.basicCardInfo.description.total_de_litros_consumidos': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.total_de_litros_consumidos',
        defaultMessage: 'Total de Litros Consumidos'
    }),
    'combustiblesRepository.basicCardInfo.description.promedio_de_km_litro': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.promedio_de_km_litro',
        defaultMessage: 'Promedio de Km/litro'
    }),
    'combustiblesRepository.basicCardInfo.description.promedio_de_costo_litro': defineMessage({
        id: 'combustiblesRepository.basicCardInfo.description.promedio_de_costo_litro',
        defaultMessage: 'Promedio de Costo/litro'
    }),
    'combustibleTiposRepository.errors.ingresar_nueva_bandera_combustible': defineMessage({
        id: 'combustibleTiposRepository.errors.ingresar_nueva_bandera_combustible',
        defaultMessage: 'Error al ingresar una nueva bandera de combustible'
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion',
        defaultMessage: 'No hay datos del rendimiento esperado '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion_medidor': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_sin_comprobacion_medidor',
        defaultMessage: 'No se pudo calcular el rendimiento esperado'
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_carga_sin_recorrido': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_carga_sin_recorrido',
        defaultMessage: 'Rendimiento menor al esperado: realizó carga sin recorrer kilómetros'
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_menor': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_menor',
        defaultMessage: 'Rendimiento menor al esperado (tolerancia mínima): '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_superior': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_superior',
        defaultMessage: 'Rendimiento superior al esperado (tolerancia máxima): '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_valido': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_valido',
        defaultMessage: 'Rendimiento esperado (tolerancia): '
    }),
    'combustiblesGrid.column.controles.rendimiento_esperado_init': defineMessage({
        id: 'combustiblesGrid.column.controles.rendimiento_esperado_init',
        defaultMessage: ' '
    }),
    'estacionesServicioRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'estacionesServicioRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'facturasRepository.errors.la_factura_existe': defineMessage({
        id: 'facturasRepository.errors.la_factura_existe',
        defaultMessage: 'La factura ya existe.'
    }),
    'gerenciadoresRepository.errors.existe_un_gerenciador_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'gerenciadoresRepository.errors.existe_un_gerenciador_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Gerenciador con ese nombre y debe ser unico.'
    }),
    'infraccionesRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'infraccionesRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'infraccionesRepository.errors.no_se_puede_borrar_la_infraccion': defineMessage({
        id: 'infraccionesRepository.errors.no_se_puede_borrar_la_infraccion',
        defaultMessage: 'No se puede borrar la Infracción.'
    }),
    'infraccionesRepository.errors.dominio_ingresado_ya_existe': defineMessage({
        id: 'infraccionesRepository.errors.dominio_ingresado_ya_existe',
        defaultMessage: 'El dominio ingresado ya existe.'
    }),
    'insumoMarcaRepository.errors.marca_de_insumo_repuesto_ya_existe': defineMessage({
        id: 'insumoMarcaRepository.errors.marca_de_insumo_repuesto_ya_existe',
        defaultMessage: 'La marca de insumo/repuesto ya existe.'
    }),
    'insumoMarcaController.errors.no_existe_marca_con_ese_id': defineMessage({
        id: 'insumoMarcaController.errors.no_existe_marca_con_ese_id',
        defaultMessage: 'No existe la marca con ese id'
    }),
    'itemCategoriaController.errors.no_existe_categoria': defineMessage({
        id: 'itemCategoriaController.errors.no_existe_categoria',
        defaultMessage: 'No existe la categoria'
    }),
    'itemCategoriaRepository.errors.no_existe_categoria': defineMessage({
        id: 'itemCategoriaRepository.errors.no_existe_categoria',
        defaultMessage: 'La categoría ya existe.'
    }),
    'itemController.errors.no_existe_el_item_con_ese_id': defineMessage({
        id: 'itemController.errors.no_existe_el_item_con_ese_id',
        defaultMessage: 'No existe el item con ese id'
    }),
    'itemSubCategoriaController.errors.no_existe_la_subcategoria': defineMessage({
        id: 'itemSubCategoriaController.errors.no_existe_la_subcategoria',
        defaultMessage: 'No existe la subcategoria'
    }),
    'localidadController.errors.debe_ingresar_una_provincia_valida': defineMessage({
        id: 'localidadController.errors.debe_ingresar_una_provincia_valida',
        defaultMessage: 'Error, debe ingresar una provincia valida'
    }),
    'marcaService.errors.no_se_pudo_obtener_la_imagen_en_la_carpeta': defineMessage({
        id: 'marcaService.errors.no_se_pudo_obtener_la_imagen_en_la_carpeta',
        defaultMessage: 'No se pudo obtener la imagen en la carpeta.'
    }),
    'marcaRepository.errors.existe_marca_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'marcaRepository.errors.existe_marca_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe una Marca con ese nombre y debe ser única.'
    }),
    'marcaRepository.errors.no_puede_borrar_marca_ya_asociada_a_otro_elemento': defineMessage({
        id: 'marcaRepository.errors.no_puede_borrar_marca_ya_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Marca ya que esta asociada a otro elemento.'
    }),
    'modelosRepository.errors.existe_modelo_con_ese_nombre_con_ese_tipo_y_modelo_debe_ser_unico': defineMessage({
        id: 'modelosRepository.errors.existe_modelo_con_ese_nombre_con_ese_tipo_y_modelo_debe_ser_unico',
        defaultMessage: 'Ya existe un Modelo con ese nombre con ese Tipo y Modelo, debe ser único.'
    }),
    'modelosRepository.errors.no_se_puede_borrar_modelo_asociado_a_otro_elemento': defineMessage({
        id: 'modelosRepository.errors.no_se_puede_borrar_modelo_asociado_a_otro_elemento',
        defaultMessage: 'No se puede borrar el Modelo ya que esta asociado a otro elemento.'
    }),
    'modeloTiposRepository.errors.existe_un_tipo_modelo_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'modeloTiposRepository.errors.existe_un_tipo_modelo_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Tipo de Modelo con ese Nombre y debe ser único.'
    }),
    'modeloTiposRepository.errors.no_se_puede_borrar_tipo_modelo_asociada_a_otro_elemento': defineMessage({
        id: 'modeloTiposRepository.errors.no_se_puede_borrar_tipo_modelo_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar el Tipo de Modelo ya que esta asociada a otro elemento.'
    }),
    'movilController.errors.token_no_es_correcto': defineMessage({
        id: 'movilController.errors.token_no_es_correcto',
        defaultMessage: 'Error, el token ingresado no es correcto'
    }),
    'movilController.errors.campos_latitud_longitud_no_pudieron_validarse': defineMessage({
        id: 'movilController.errors.campos_latitud_longitud_no_pudieron_validarse',
        defaultMessage: 'Error, los campos latitud y longitud no pudieron validarse'
    }),
    'movilController.errors.debe_ingresar_la_fecha': defineMessage({
        id: 'movilController.errors.debe_ingresar_la_fecha',
        defaultMessage: 'Error, debe ingresar la fecha'
    }),
    'movilFuenteRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'movilFuenteRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'movilRepository.errors.error_al_ingresar_los_datos': defineMessage({
        id: 'movilRepository.errors.error_al_ingresar_los_datos',
        defaultMessage: 'Error al ingresar los datos'
    }),
    'movilRepository.errors.error_al_actualizar_tareas_del_movil': defineMessage({
        id: 'movilRepository.errors.error_al_actualizar_tareas_del_movil',
        defaultMessage: 'Error al actualizar tareas del móvil'
    }),
    'movilRepository.errors.error_cambiar_de_estado': defineMessage({
        id: 'movilRepository.errors.error_cambiar_de_estado',
        defaultMessage: 'Error Cambiar de estado'
    }),
    'movilRepository.errors.parametro_incorrecto_modificar_km': defineMessage({
        id: 'movilRepository.errors.parametro_incorrecto_modificar_km',
        defaultMessage: 'Parámetro incorrecto para modificar el km del móvil.'
    }),
    'movilRepository.errors.parametro_incorrecto_modificar_km': defineMessage({
        id: 'movilRepository.errors.parametro_incorrecto_modificar_km',
        defaultMessage: 'Parámetro incorrecto para modificar el km del móvil.'
    }),
    'movilRepository.errors.no_existe_el_movil_con_ese_id': defineMessage({
        id: 'movilRepository.errors.no_existe_el_movil_con_ese_id',
        defaultMessage: 'No existe el móvil con ese id.'
    }),
    'movilRepository.errors.dominio_ingresado_ya_existe': defineMessage({
        id: 'movilRepository.errors.dominio_ingresado_ya_existe',
        defaultMessage: 'El dominio ingresado ya existe.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_Plan': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_Plan',
        defaultMessage: 'Este movil tiene Tickets Preventivos activos, no puede cambiar el Plan.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_estado': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Preventivos_activos_no_puede_cambiar_estado',
        defaultMessage: 'Este movil tiene Tickets Preventivos activos, no puede cambiar el estado.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado',
        defaultMessage: 'Este movil tiene Tickets de Vencimientos activos, no puede cambiar el estado.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_Plan': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_Plan',
        defaultMessage: 'Este movil tiene Tickets de Vencimientos activos, no puede cambiar el Plan.'
    }),
    'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado': defineMessage({
        id: 'movilRepository.errors.este_movil_tiene_Tickets_Vencimientos_activos_no_puede_cambiar_estado',
        defaultMessage: 'Este movil tiene Tickets de Vencimientos activos, no puede cambiar el estado.'
    }),
    'movimientoStockController.errors.no_existe_el_objeto_buscado': defineMessage({
        id: 'movimientoStockController.errors.no_existe_el_objeto_buscado',
        defaultMessage: 'No existe el objeto buscado'
    }),
    'movimientoStockController.errors.no_se_puede_obtener_movimiento': defineMessage({
        id: 'movimientoStockController.errors.no_se_puede_obtener_movimiento',
        defaultMessage: 'No se puede obtener el movimiento'
    }),
    'movimientoStockController.errors.no_se_puede_eliminar_movimiento_stock': defineMessage({
        id: 'movimientoStockController.errors.no_se_puede_eliminar_movimiento_stock',
        defaultMessage: 'No se puede eliminar un movimiento de stock'
    }),
    'movimientoStockController.errors.no_se_puede_editar_movimiento_stock': defineMessage({
        id: 'movimientoStockController.errors.no_se_puede_editar_movimiento_stock',
        defaultMessage: 'No se puede editar un movimiento de stock'
    }),
    'movimientoStockRepository.errors.tipo_movimiento_stock_invalido': defineMessage({
        id: 'movimientoStockRepository.errors.tipo_movimiento_stock_invalido',
        defaultMessage: 'Tipo de movimiento de stock inválido'
    }),
    'movimientoStockRepository.errors.tipo_movimiento_incorrecto': defineMessage({
        id: 'movimientoStockRepository.errors.tipo_movimiento_incorrecto',
        defaultMessage: 'tipo movimiento incorrecto'
    }),
    'oTService.errors.no_se_pudo_obtener_archivo_modelo': defineMessage({
        id: 'oTService.errors.no_se_pudo_obtener_archivo_modelo',
        defaultMessage: 'No se pudo obtener el archivo modelo'
    }),
    'oTService.errors.fecha_vencimiento_es_obligatorio': defineMessage({
        id: 'oTService.errors.fecha_vencimiento_es_obligatorio',
        defaultMessage: 'Fecha de Vencimiento es obligatorio'
    }),
    'oTRepository.errors.tipo_archivo_no_reconocido': defineMessage({
        id: 'oTRepository.errors.tipo_archivo_no_reconocido',
        defaultMessage: 'El tipo de archivo no reconocido'
    }),
    'oTRepository.errors.archivo_no_cumple_formato_tickets_gestoria': defineMessage({
        id: 'oTRepository.errors.archivo_no_cumple_formato_tickets_gestoria',
        defaultMessage: 'El archivo no cumple el formato de Tickets de Gestoria'
    }),
    'perfilesRepository.errors.no_existe_el_permiso': defineMessage({
        id: 'perfilesRepository.errors.no_existe_el_permiso',
        defaultMessage: 'No existe el permiso.'
    }),
    'perfilesRepository.errors.existe_un_perfil_con_el_nombre_debe_ser_unico': defineMessage({
        id: 'perfilesRepository.errors.existe_un_perfil_con_el_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un perfil con el nombre y debe ser único.'
    }),
    'personaRepository.errors.direccion_email_ya_existe_debe_ser_unica': defineMessage({
        id: 'personaRepository.errors.direccion_email_ya_existe_debe_ser_unica',
        defaultMessage: 'La dirección de email ya existe debe ser única.'
    }),
    'personaRepository.errors.la_llave_ingresada_esta_asignada_a_otra_persona': defineMessage({
        id: 'personaRepository.errors.la_llave_ingresada_esta_asignada_a_otra_persona',
        defaultMessage: 'La llave ingresada está asignada a otra persona.'
    }),
    'personaRepository.errors.el_legajo_ingresado_ya_existe': defineMessage({
        id: 'personaRepository.errors.el_legajo_ingresado_ya_existe',
        defaultMessage: 'El legajo ingresado ya existe.'
    }),
    'planMantenimientoPreventivoRepository.errors.no_se_puede_borrar_region_esta_asociada_otro_elemento': defineMessage({
        id: 'planMantenimientoPreventivoRepository.errors.no_se_puede_borrar_region_esta_asociada_otro_elemento',
        defaultMessage: 'No se puede borrar la Región ya que esta asociada a otro elemento.'
    }),
    'planMantenimientoPreventivoRepository.errors.existe_plan_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'planMantenimientoPreventivoRepository.errors.existe_plan_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe un Plan con ese nombre y debe ser única.'
    }),
    'plantillaController.errors.no_existe_la_plantilla': defineMessage({
        id: 'plantillaController.errors.no_existe_la_plantilla',
        defaultMessage: 'No existe la plantilla'
    }),
    'plantillaRepository.errors.plantilla_con_ese_codigo_existe': defineMessage({
        id: 'plantillaRepository.errors.plantilla_con_ese_codigo_existe',
        defaultMessage: 'La plantilla con ese código ya existe.'
    }),
    'plantillaItemController.errors.no_existe_el_item': defineMessage({
        id: 'plantillaItemController.errors.no_existe_el_item',
        defaultMessage: 'No existe el item'
    }),
    'plantillaItemController.errors.item_no_corresponde_a_la_plantilla': defineMessage({
        id: 'plantillaItemController.errors.item_no_corresponde_a_la_plantilla',
        defaultMessage: 'El item no corresponde a la plantilla'
    }),
    'provinciaController.errors.debe_ingresar_un_pais_valido': defineMessage({
        id: 'provinciaController.errors.debe_ingresar_un_pais_valido',
        defaultMessage: 'Error, debe ingresar un pais valido'
    }),
    'provinciaRepository.errors.la_provincia_ya_existe': defineMessage({
        id: 'provinciaRepository.errors.la_provincia_ya_existe',
        defaultMessage: 'La provincia ya existe.'
    }),
    'regionRepository.errors.no_se_puede_borrar_region_esta_asociada_a_otro_elemento': defineMessage({
        id: 'regionRepository.errors.no_se_puede_borrar_region_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Región ya que esta asociada a otro elemento.'
    }),
    'regionRepository.errors.existe_region_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'regionRepository.errors.existe_region_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe una Región con ese nombre y debe ser única.'
    }),
    'servicioRepository.errors.no_se_puede_borrar_servicio_esta_asociada_a_otro_elemento': defineMessage({
        id: 'servicioRepository.errors.no_se_puede_borrar_servicio_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar el Servicio ya que está asociada a otro elemento.'
    }),
    'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Servicio con ese nombre y debe ser único.'
    }),
    'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'servicioRepository.errors.existe_servicio_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Servicio con ese nombre y debe ser único.'
    }),
    'subregionRepository.errors.no_se_puede_borrar_sub_region_esta_asociada_a_otro_elemento': defineMessage({
        id: 'subregionRepository.errors.no_se_puede_borrar_sub_region_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Sub Región ya que esta asociada a otro elemento.'
    }),
    'subregionRepository.errors.existe_sub_region_con_ese_nombre_debe_ser_unica': defineMessage({
        id: 'subregionRepository.errors.existe_sub_region_con_ese_nombre_debe_ser_unica',
        defaultMessage: 'Ya existe una Sub Región con ese nombre y debe ser única.'
    }),
    'talleresRepository.errors.existe_taller_con_el_nombre_debe_ser_unico': defineMessage({
        id: 'talleresRepository.errors.existe_taller_con_el_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Taller con el nombre y debe ser único.'
    }),
    'talleresRepository.errors.existe_un_taller_con_esa_razon_social_debe_ser_unica': defineMessage({
        id: 'talleresRepository.errors.existe_un_taller_con_esa_razon_social_debe_ser_unica',
        defaultMessage: 'Ya existe un Taller con esa Razón Social y debe ser única.'
    }),
    'tareaRepository.errors.no_se_puede_borrar_la_tarea_esta_asociada_a_otro_elemento': defineMessage({
        id: 'tareaRepository.errors.no_se_puede_borrar_la_tarea_esta_asociada_a_otro_elemento',
        defaultMessage: 'No se puede borrar la Tarea ya que está asociada a otro elemento.'
    }),
    'tareaRepository.errors.existe_tarea_con_ese_nombre_debe_ser_unico': defineMessage({
        id: 'tareaRepository.errors.existe_tarea_con_ese_nombre_debe_ser_unico',
        defaultMessage: 'Ya existe un Tarea con ese Nombre y debe ser único.'
    }),
    'unidadDeMedidaController.errors.no_existe_la_unidad_con_ese_id': defineMessage({
        id: 'unidadDeMedidaController.errors.no_existe_la_unidad_con_ese_id',
        defaultMessage: 'No existe la unidad con ese id'
    }),
    'unidadDeMedidaService.errors.no_se_puede_modificar_una_unidad_de_sistema': defineMessage({
        id: 'unidadDeMedidaService.errors.no_se_puede_modificar_una_unidad_de_sistema',
        defaultMessage: 'No se puede modificar una unidad de sistema'
    }),
    'unidadDeMedidaRepository.errors.abreviatura_de_la_unidad_medida_ya_existe': defineMessage({
        id: 'unidadDeMedidaRepository.errors.abreviatura_de_la_unidad_medida_ya_existe',
        defaultMessage: 'La abreviatura de la unidad de medida ya existe.'
    }),
    'usuarioRepository.errors.el_usuario_ingresado_ya_existe': defineMessage({
        id: 'usuarioRepository.errors.el_usuario_ingresado_ya_existe',
        defaultMessage: 'El usuario ingresado ya existe.'
    }),
    'vencimientosRepository.valida_que_haya_o_persona_o_movil': defineMessage({
        id: 'vencimientosRepository.valida_que_haya_o_persona_o_movil',
        defaultMessage: 'Valida que haya o persona o movil'
    }),
    'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea': defineMessage({
        id: 'ticketTareasCierre.errors.deber_ingresar_al_menos_una_tarea',
        defaultMessage: 'Debe ingresar al menos una tarea.'
    }),
    'infraccionesRepository.basicCardInfo.description.total': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.total',
        defaultMessage: 'Total'
    }),
    'infraccionesRepository.basicCardInfo.description.pendientes_confirmacion': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.pendientes_confirmacion',
        defaultMessage: 'Pendientes de Confirmación'
    }),
    'infraccionesRepository.basicCardInfo.description.pendientes_pago': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.pendientes_pago',
        defaultMessage: 'Pendientes de Pago'
    }),
    'infraccionesRepository.basicCardInfo.description.abonadas': defineMessage({
        id: 'infraccionesRepository.basicCardInfo.description.abonadas',
        defaultMessage: 'Abonadas'
    }),
    'movilRepository.basicCardInfo.description.sin_posicion_gps': defineMessage({
        id: 'movilRepository.basicCardInfo.description.sin_posicion_gps',
        defaultMessage: 'Sin Posición GPS'
    }),
    'movilRepository.basicCardInfo.description.en_taller': defineMessage({
        id: 'movilRepository.basicCardInfo.description.en_taller',
        defaultMessage: 'En Taller'
    }),
    'movilRepository.basicCardInfo.description.doc_movil_vencida': defineMessage({
        id: 'movilRepository.basicCardInfo.description.doc_movil_vencida',
        defaultMessage: 'Doc. Móvil Vencida'
    }),
    'movilRepository.basicCardInfo.description.doc_chofer_vencida': defineMessage({
        id: 'movilRepository.basicCardInfo.description.doc_chofer_vencida',
        defaultMessage: 'Doc. Chofer Vencida'
    }),
    'movilRepository.basicCardInfo.description.vh_con_infracciones': defineMessage({
        id: 'movilRepository.basicCardInfo.description.vh_con_infracciones',
        defaultMessage: 'Vh. con Infracciones'
    }),
    'movilRepository.basicCardInfo.description.vh_con_cargas_invalidas': defineMessage({
        id: 'movilRepository.basicCardInfo.description.vh_con_cargas_invalidas',
        defaultMessage: 'Vh. con Cargas Inválidas'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_PREVENTIVOS': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_PREVENTIVOS',
        defaultMessage: 'MANT. PREVENTIVOS'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_CORRECTIVOS': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.MANT_CORRECTIVOS',
        defaultMessage: 'MANT. CORRECTIVOS'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_MOVILES': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_MOVILES',
        defaultMessage: 'VENCIMIENTOS - MÓVILES'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_PERSONAS': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.VENCIMIENTOS_PERSONAS',
        defaultMessage: 'VENCIMIENTOS - PERSONAS'
    }),
    'solidGaugeRepository.dashboardOperativo2.cardTitle.GESTORIA': defineMessage({
        id: 'solidGaugeRepository.dashboardOperativo2.cardTitle.GESTORIA',
        defaultMessage: 'GESTORÍA'
    }),
    'llantaAsignacionController.errors.llanta_ya_esta_asignada': defineMessage({
        id: 'llantaAsignacionController.errors.llanta_ya_esta_asignada',
        defaultMessage: 'La llanta ya esta asignada a un móvil'
    }),
    'llantaAsignacionController.errors.llanta_en_recapado': defineMessage({
        id: 'llantaAsignacionController.errors.llanta_en_recapado',
        defaultMessage: 'La llanta se encuentra en recapado'
    }),
    'llantaController.errors.add_banda_es_requerida': defineMessage({
        id: 'llantaController.errors.add_banda_es_requerida',
        defaultMessage: 'La Base es requerida.'
    }),
    'llantaController.errors.add_llanta_posicion_en_movil_en_uso': defineMessage({
        id: 'llantaController.errors.add_llanta_posicion_en_movil_en_uso',
        defaultMessage: 'La posición selecionada en el móvil esta en uso.'
    }),
    'llantaController.errors.llanta_cambio_medidor_en_movil_en_uso': defineMessage({
        id: 'llantaController.errors.llanta_cambio_medidor_en_movil_en_uso',
        defaultMessage: 'No puede cambiar el medidor de una llanta asignada a un móvil.'
    }),
    'llantaAsignacionController.errors.llanta_no_pertenece_al_movil': defineMessage({
        id: 'llantaAsignacionController.errors.llanta_no_pertenece_al_movil',
        defaultMessage: 'Llanta no pertence al móvil.'
    }),
    'llantaController.errors.supero_recapados_maximo': defineMessage({
        id: 'llantaController.errors.supero_recapados_maximo',
        defaultMessage: 'No se permiten mas recapados en la llanta.'
    }),
    'llantaModeloController.errors.punto_medicion.milimetro_warning_debe_se_menor_a_milimitro_inicio': defineMessage({
        id: 'llantaModeloController.errors.punto_medicion.milimetro_warning_debe_se_menor_a_milimitro_inicio',
        defaultMessage: 'Espesor de recapado debe ser menor a mm en momento de compra.'
    }),
    'llantaModeloController.errors.punto_medicion.valores_no_pueden_ser_vacios': defineMessage({
        id: 'llantaModeloController.errors.punto_medicion.valores_no_pueden_ser_vacios',
        defaultMessage: 'El valores de un punto de medición no pueden ser vacíos.'
    }),
    'llantaModeloController.errors.punto_medicion.milimetro_danger_debe_se_menor_a_milimitro_warning': defineMessage({
        id: 'llantaModeloController.errors.punto_medicion.milimetro_danger_debe_se_menor_a_milimitro_warning',
        defaultMessage: 'Espesor de baja debe ser menor al espesor de recapado.'
    }),
    'llantaModeloController.errors.llanta_modelo_ya_existe': defineMessage({
        id: 'llantaModeloController.errors.llanta_modelo_ya_existe',
        defaultMessage: 'Ya existe un modelo de llanta con los datos ingresados.'
    }),
    'llantaModeloController.errors.existen_llantas_asociadas': defineMessage({
        id: 'llantaModeloController.errors.existen_llantas_asociadas',
        defaultMessage: 'Operacion denegada, existen llantas asociadas a este modelo.'
    }),
    'llantaAsignacionesGrid.kpis_card.llantas_entre_0mm_y_3mm': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.llantas_entre_0mm_y_3mm',
        defaultMessage: 'llantas e/ 0 y 3mm'
    }),
    'llantaAsignacionesGrid.kpis_card.llantas_entre_3mm_y_6mm': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.llantas_entre_3mm_y_6mm',
        defaultMessage: 'llantas e/ 3 y 6mm'
    }),
    'llantaAsignacionesGrid.kpis_card.inspeciones_vencidas': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.inspeciones_vencidas',
        defaultMessage: 'Inspeciones Vencidas'
    }),
    'llantaAsignacionesGrid.kpis_card.KMs_promedio_por_llanta': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.KMs_promedio_por_llanta',
        defaultMessage: 'KMs promedio por llanta'
    }),
    'llantaAsignacionesGrid.kpis_card.desgaste_cada_10000km': defineMessage({
        id: 'llantaAsignacionesGrid.kpis_card.desgaste_cada_10000km',
        defaultMessage: 'Desgaste (c/ 1000km)'
    }),
    'llantasGrid.kpis_card.llantas_entre_0mm_y_3mm': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_entre_0mm_y_3mm',
        defaultMessage: 'llantas e/ 0 y 3mm'
    }),
    'llantasGrid.kpis_card.llantas_entre_3mm_y_6mm': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_entre_3mm_y_6mm',
        defaultMessage: 'llantas e/ 3 y 6mm'
    }),
    'llantasGrid.kpis_card.inspeciones_vencidas': defineMessage({
        id: 'llantasGrid.kpis_card.inspeciones_vencidas',
        defaultMessage: 'Inspeciones Vencidas'
    }),
    'llantasGrid.kpis_card.KMs_promedio_por_llanta': defineMessage({
        id: 'llantasGrid.kpis_card.KMs_promedio_por_llanta',
        defaultMessage: 'KMs promedio por llanta'
    }),
    'llantasGrid.kpis_card.desgaste_cada_10000km': defineMessage({
        id: 'llantasGrid.kpis_card.desgaste_cada_10000km',
        defaultMessage: 'Desgaste (c/ 10000km)'
    }),
    'llantasGrid.kpis_card.llantas_sin_asignar': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_sin_asignar',
        defaultMessage: 'Llantas sin Asignar'
    }),
    'llantaInspeccionesAbm.errors.firma_requerida': defineMessage({
        id: 'llantaInspeccionesAbm.errors.firma_requerida',
        defaultMessage: 'La firma es requerida'
    }),
    'llantaInspeccionesAbm.errors.odometro_es_requerido': defineMessage({
        id: 'llantaInspeccionesAbm.errors.odometro_es_requerido',
        defaultMessage: 'El odometro es requerido'
    }),
    'llantaController.errors.forzar_recapado_requiere_movil': defineMessage({
        id: 'llantaController.errors.forzar_recapado_requiere_movil',
        defaultMessage: 'Para forzar el recapado se requiere un móvil asignado'
    }),
    'llantaController.errors.servicio_REENCAUCHE_no_encontrado': defineMessage({
        id: 'llantaController.errors.servicio_REENCAUCHE_no_encontrado',
        defaultMessage: 'Servicio de REENCAUCHE no encontrado'
    }),
    'llantaController.errors.llanta_no_existe': defineMessage({
        id: 'llantaController.errors.llanta_no_existe',
        defaultMessage: 'Llanta no existe'
    }),
    'llantaController.errors.llanta_no_existe': defineMessage({
        id: 'llantaController.errors.ingrese_reemplazo',
        defaultMessage: 'Debe asignar una Llanta de Reemplazo'
    }),
    'llantaController.errors.llanta_tiene_asignada_un_movil': defineMessage({
        id: 'llantaController.errors.llanta_tiene_asignada_un_movil',
        defaultMessage: 'Llanta esta asignada a un móvil'
    }),
    'llantaController.errors.llanta_no_tiene_ticket_de_recapado': defineMessage({
        id: 'llantaController.errors.llanta_no_tiene_ticket_de_recapado',
        defaultMessage: 'Llanta no tiene tickets de recapado asignado'
    }),
    'llantaController.errors.llanta_a_desasignar_no_tiene_asigndo_un_movil': defineMessage({
        id: 'llantaController.errors.llanta_a_desasignar_no_tiene_asigndo_un_movil',
        defaultMessage: 'Llanta a desasignar no tiene asignado un móvil'
    }),
    'llantaController.errors.llanta_a_desasignar_requiere_reemplazo': defineMessage({
        id: 'llantaController.errors.llanta_a_desasignar_requiere_reemplazo',
        defaultMessage: 'Llanta a desasignar requiere reemplazo'
    }),
    'llantasGrid.kpis_card.llantas_rodando': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_rodando',
        defaultMessage: 'Llantas Rodando'
    }),
    'dashboard.kpiMovilesDisponibles.titulo': defineMessage({
        id: 'dashboard.kpiMovilesDisponibles.titulo',
        defaultMessage: 'Móviles Operativos'
    }),
    'dashboard.kpiActivos.titulo': defineMessage({
        id: 'dashboard.kpiActivos.titulo',
        defaultMessage: 'Moviles Cargados/Registrados'
    }),
    'dashboard.kpiFueraDeServicio.titulo': defineMessage({
        id: 'dashboard.kpiFueraDeServicio.titulo',
        defaultMessage: 'Móviles Fuera de Servicio'
    }),
    'dashboard.kpiEnComodato.titulo': defineMessage({
        id: 'dashboard.kpiEnComodato.titulo',
        defaultMessage: 'Móviles En Comodato'
    }),
    'dashboard.kpiEnTaller.titulo': defineMessage({
        id: 'dashboard.kpiEnTaller.titulo',
        defaultMessage: 'Móviles En Reparación'
    }),
    'dashboard.kpiSinGps.titulo': defineMessage({
        id: 'dashboard.kpiSinGps.titulo',
        defaultMessage: 'Móviles Sin Disp. Sat.'
    }),
    'dashboard.kpiTicketsEnGestion.titulo': defineMessage({
        id: 'dashboard.kpiTicketsEnGestion.titulo',
        defaultMessage: 'Tickets en Gestión'
    }),
    'dashboard.kpiPresupuestados.titulo': defineMessage({
        id: 'dashboard.kpiPresupuestados.titulo',
        defaultMessage: 'Tickets Presupuestados'
    }),
    'dashboard.kpiListoParaRetirar.titulo': defineMessage({
        id: 'dashboard.kpiListoParaRetirar.titulo',
        defaultMessage: 'Tickets Listos para Retirar'
    }),
    'dashboard.kpiAprobados.titulo': defineMessage({
        id: 'dashboard.kpiAprobados.titulo',
        defaultMessage: 'Tickets Aprobados'
    }),
    'dashboard.kpiFueraDeServicio.titulo': defineMessage({
        id: 'dashboard.kpiFueraDeServicio.titulo',
        defaultMessage: 'Móviles Fuera de Servicio'
    }),
    'llantaController.errors.formulario_finalizar_recapado_incompleto': defineMessage({
        id: 'llantaController.errors.formulario_finalizar_recapado_incompleto',
        defaultMessage: 'Complete todos los campos.'
    }),
    'llantas.column_desgaste_cada_mil.restante_odometro.no_es_posible_el_calculo': defineMessage({
        id: 'llantas.column_desgaste_cada_mil.restante_odometro.no_es_posible_el_calculo',
        defaultMessage: 'No es posible el calculo, faltan datos'
    }),
    'llantaModeloController.errors.clase.clase_invalida_en_unidad_medidor': defineMessage({
        id: 'llantaModeloController.errors.clase.clase_invalida_en_unidad_medidor',
        defaultMessage: 'Clase no pertence a la unidad de medidor'
    }),
    'llantaModeloController.errors.unidad_medidor.unidad_medidor_invalido': defineMessage({
        id: 'llantaModeloController.errors.unidad_medidor.unidad_medidor_invalido',
        defaultMessage: 'Unidad medidor invalido'
    }),
    'llantasGrid.kpis_card.llantas_en_nivel_de_reenc_baja': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_en_nivel_de_reenc_baja',
        defaultMessage: 'En nivel de Reenc./Baja'
    }),
    'llantasGrid.kpis_card.llantas_proximas_a_reenc_baja': defineMessage({
        id: 'llantasGrid.kpis_card.llantas_proximas_a_reenc_baja',
        defaultMessage: 'Próximas a Reenc./Baja'
    }),
    'llantaController.errors.ya_existe_una_llanta': defineMessage({
        id: 'llantaController.errors.ya_existe_una_llanta',
        defaultMessage: 'Ya existe una llanta con el serial ingresado.'
    }),
    'llantaController.errors.tipo_de_llanta_incompatible_con_movil': defineMessage({
        id: 'llantaController.errors.tipo_de_llanta_incompatible_con_movil',
        defaultMessage: 'Tipo de llanta incompatible con el vehículo'
    }),
    'llantaController.errors.llanta_baja.tipo_de_llanta_reemplazo_incompatible_con_movil': defineMessage({
        id: 'llantaController.errors.llanta_baja.tipo_de_llanta_reemplazo_incompatible_con_movil',
        defaultMessage: 'Tipo de llanta reemplazo incompatible con el vehículo'
    }),
    'llantaController.errors.base_requerida_para_desasignar': defineMessage({
        id: 'llantaController.errors.base_requerida_para_desasignar',
        defaultMessage: 'Base es requerida para desasignar'
     }),
    'llantaModeloController.errors.llanta_modelo_no_existe': defineMessage({
        id: 'llantaModeloController.errors.llanta_modelo_no_existe',
        defaultMessage: 'Modelo de llanta no existe'
    }),
    'llantaModeloController.errors.llanta_modelo_tiene_llantas_asignadas_no_se_puede_cambiar_unidad_medidor': defineMessage({
        id: 'llantaModeloController.errors.llanta_modelo_tiene_llantas_asignadas_no_se_puede_cambiar_unidad_medidor',
        defaultMessage: 'Modelo tiene llantas asignadas a  vehículos, no se puede cambiar unidad medidor.'
    }),
    'llantasGrid.kpis_card.Hrs_promedio_por_llanta': defineMessage({
        id: 'llantasGrid.kpis_card.Hrs_promedio_por_llanta',
        defaultMessage: 'Hrs promedio por llanta'
    }),
    'llantasGrid.kpis_card.desgaste_cada_10000hrs': defineMessage({
        id: 'llantasGrid.kpis_card.desgaste_cada_10000hrs',
        defaultMessage: 'Desgaste (c/ 1000Hrs)'
    }),
    'llantaController.errors.add_llanta_posicion_es_requerido': defineMessage({
        id: 'llantaController.errors.add_llanta_posicion_es_requerido',
        defaultMessage: 'Selecione una posición'
    }),
    'llantasRepository.errors.encabezado_no_esta_en_fila_correcta': defineMessage({
        id: 'movilesRepository.errors.encabezado_no_esta_en_fila_correcta',
        defaultMessage: 'Encabezado no esta en fila correcta.'
    }),
    'llantasRepository.errors.archivo_no_cumple_formato_faltan_columnas': defineMessage({
        id: 'llantasRepository.errors.archivo_no_cumple_formato_faltan_columnas',
        defaultMessage: 'Archivo no cumple formato faltan columnas.'
    }),
    'ropAbm.errors.ya_existe_rop_para_equipo': defineMessage({
        id: 'rops.errors.ya_existe_rop_para_equipo',
        defaultMessage: 'Ya existe un ROP para este equipo.'
    }),
    'kpi_moviles.gps': defineMessage({
        id: 'kpi_moviles.gps',
        defaultMessage: 'GPS'
    }),
    'kpi_moviles.gps_no_reportan': defineMessage({
        id: 'kpi_moviles.gps_no_reportan',
        defaultMessage: 'GPS no reportan'
    }),
    'kpi_moviles.gps_vehiculos_sin_GPS': defineMessage({
        id: 'kpi_moviles.gps_vehiculos_sin_GPS',
        defaultMessage: 'Vehículos sin GPS'
    }),
    'error.odometro_mayor_al_actual': defineMessage({
        id: 'error.odometro_mayor_al_actual',
        defaultMessage: 'Odómetro tiene que ser mayor al actual'
    }),
    'form.error': defineMessage({
        id: 'form.error',
        defaultMessage: 'Error'
    }),
    'login.errors.invalido': defineMessage({
        id: 'login.errors.invalido',
        defaultMessage: '  La combinación de nombre de usuario y contraseña no existe en nuestro sistema'
    }),
    'llantaController.no_se_puede_dar_de_baja_la_lanta_tickets_pendientes': defineMessage({
        id: 'llantaController.no_se_puede_dar_de_baja_la_lanta_tickets_pendientes',
        defaultMessage: 'No se puede dar de baja la llanta, tiene tickets pendientes'
    }),
};
