import React, { useState, useEffect, useLayoutEffect } from "react"
import { Redirect } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
} from '@material-ui/core'
import Switch from "react-switch"
import DatePicker from "react-datepicker"
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { StyledSelect } from '../../commons/utils/TableStyles'
import * as Constants from '../atributoDinamico/constants.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

const SELECT_OPTIONS_INIT = {
  bases: [],
  tipoModelos: [],
  gerenciadores: [],
  servicios: [],
  estados: [],
  tipos: [],
  enTallerSelect: [],
  abonoSelect: [],
  realizadoSelect: [],
  regiones: [],
  cecos: [],
  cebes: [],
  nivelesAprobacion: []
}

const FILTER_STATE_INIT = {
  ticketTipo: ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? { value: 'DEFAULT', label: 'POR DEFECTO' } : '',
  id: '',
  estado: { value: 'PENDIENTES', label: 'PENDIENTES' },
  nivelAprobacionPresupuesto: null,
  fechaHoraAlta: null,
  detalle: '',
  servicio: null,
  tipoEntidadColumnGrid: '',
  gerenciador: null,
  region: null,
  base: null,
  centroCostos: null,
  centroBeneficios: null,
  responsable1: '',
  enTaller: null,
  mttTotal: '',
  mttParcial: '',
  encuestaNivelSatisfaccion: '',
  abono: null,
  fechaRealizado: null,
  fechaHoraTurno: null,
  tipoModelo: null
}

const getFilterState = () => {
  let filterStateInit = FILTER_STATE_INIT
  let file = JSON.parse(window.localStorage.getItem('TicketGridFilters'))

  if (file && file.activo && file.userFilters) {
    filterStateInit = file.userFilters
  }

  return filterStateInit
}

const getPersistedStoragedState = () => {
  let file = JSON.parse(window.localStorage.getItem('TicketGridFilters'))
  return (file !== null && file.activo === true)
}

const TicketGridFilters = (props) => {
  const intl = useIntl()
  const [selectOptions, setSelectOptions] = useState(SELECT_OPTIONS_INIT)
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [filtersState, setFiltersState] = useState(getFilterState)
  const [dataTableColumns, setDataTableColumns] = useState([])
  const [init, setInit] = useState(false)
  const [persisteFiltros, setPersisteFiltros] = useState(getPersistedStoragedState)
  const dataTables = props.dtData

  useEffect(() => {
    setFiltersLoading(true)
    //set selectOption values
    Promise.all([
      Utils.getData('/bases/select-filtered-by-user-for-tickets'),
      Utils.getData('/modelo-tipos/select'),
      Utils.getData('/gerenciadores/select-filtered-by-user'),
      Utils.getData('/tickets/estados'),
      Utils.getData('/servicios/select'),
      Utils.getData('/regiones/select'),
      Utils.getData('/centros-costos/select'),
      Utils.getData('/cebes/select'),
    ]).then((data) => {
      let estados = [{ value: 'PENDIENTES', label: intl.formatMessage({ id: 'ticketsGridFilters.select_pendientes', defaultMessage: 'PENDIENTES' }) }]
      if (data[3]) data[3].map(e => (estados.push({ value: e, label: e })))

      let tipos = [
        { value: 'DEFAULT', label: intl.formatMessage({ id: 'ticketsGridFilters.select_por_defecto', defaultMessage: 'POR DEFECTO' }) },
        { value: 'CORRECTIVO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_correctivo', defaultMessage: 'CORRECTIVO' }) },
        { value: 'PREVENTIVO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_preventivo', defaultMessage: 'PREVENTIVO' }) },
        { value: 'VENCIMIENTO', label: intl.formatMessage({ id: 'ticketsGridFilters.select_vencimiento', defaultMessage: 'VENCIMIENTO' }) }
      ]
      if (ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true') {
        tipos.push({ value: 'GESTORIA', label: intl.formatMessage({ id: 'ticketsGridFilters.select_gestoria', defaultMessage: 'GESTORIA' }) })
      }

      let selectOptions = {
        bases: data[0] ? data[0] : [],
        tipoModelos: data[1] ? data[1] : [],
        gerenciadores: data[2] ? data[2] : [],
        estados: estados,
        servicios: data[4] ? data[4] : [],
        regiones: data[5] ? data[5] : [],
        cecos: data[6] ? data[6] : [],
        cebes: data[7] ? data[7] : [],
        tipos: tipos,
        enTallerSelect: [
          { value: 'true', label: intl.formatMessage({ id: 'ticketsGridFilters.select_en_taller', defaultMessage: 'EN TALLER' }) },
          { value: 1, label: intl.formatMessage({ id: 'ticketsGridFilters.select_si_sin_verificacion', defaultMessage: 'SI - Sin Verificación' }) },
          { value: 2, label: intl.formatMessage({ id: 'ticketsGridFilters.select_si_verificado_GPS', defaultMessage: 'SI - Verificado GPS' }) },
          { value: 3, label: intl.formatMessage({ id: 'ticketsGridFilters.select_no_verificado_GPS', defaultMessage: 'No - Verificado GPS' }) },
          { value: 0, label: intl.formatMessage({ id: 'ticketsGridFilters.select_no', defaultMessage: 'NO' }) }
        ],
        abonoSelect: [
          { value: true, label: intl.formatMessage({ id: 'ticketsGridFilters.abono_select_si', defaultMessage: 'SI' }) },
          { value: false, label: intl.formatMessage({ id: 'ticketsGridFilters.abono_select_no', defaultMessage: 'NO' }) }
        ],
        realizadoSelect: [
          { value: true, label: intl.formatMessage({ id: 'ticketsGridFilters.realizado_select_si', defaultMessage: 'SI' }) },
          { value: false, label: intl.formatMessage({ id: 'ticketsGridFilters.realizado_select_no', defaultMessage: 'NO' }) }
        ],
        nivelesAprobacion: [{ value: 'N1', label: 'N1' }, { value: 'N2', label: 'N2' }, { value: 'N3', label: 'N3' }]
      }

      setSelectOptions(selectOptions)
      setFiltersLoading(false)
    })
  }, [])

  useEffect(() => {
    if (dataTables) {
      let cols = dataTables.settings().init().columns.map(col => col.data)
      setDataTableColumns(cols)
    }
  }, [dataTables])

  useEffect(() => {
    if (dataTableColumns.length) { updateToDataTables(filtersState) }

  }, [dataTableColumns])

  useEffect(() => {
    setFiltersLoading(true)
    if (init === false) {
      let query = JSON.parse(JSON.stringify(filtersState))

      query['estado'] = query['estado'] ? query['estado']['value'] : ''
      query['base'] = query['base'] ? query['base']['value'] : ''
      query['ticketTipo'] = query['ticketTipo'] ? query['ticketTipo']['value'] : ''
      query['nivelAprobacionPresupuesto'] = query['nivelAprobacionPresupuesto'] ? query['nivelAprobacionPresupuesto']['value'] : ''
      query['servicio'] = query['servicio'] ? query['servicio']['value'] : ''
      query['gerenciador'] = query['gerenciador'] ? query['gerenciador']['value'] : ''
      query['region'] = query['region'] ? query['region']['value'] : ''
      query['centroCostos'] = query['centroCostos'] ? query['centroCostos']['value'] : ''
      query['centroBeneficios'] = query['centroBeneficios'] ? query['centroBeneficios']['value'] : ''
      query['enTaller'] = query['enTaller'] ? query['enTaller']['value'] : ''
      query['fechaRealizado'] = query['fechaRealizado'] ? query['fechaRealizado']['value'] : ''
      query['abono'] = query['abono'] ? query['abono']['value'] : ''
      query['tipoModelo'] = query['tipoModelo'] ? query['tipoModelo']['value'] : ''

      //filtros export
      props.callbackQueryFilters(query)
      setInit(true)
    }

    if (persisteFiltros) {
      props.callbackDropdownActive(true)

    } else {
      setFiltersState(FILTER_STATE_INIT)
      props.callbackDropdownActive(false)
      updateToDataTables(FILTER_STATE_INIT)
    }
    setFiltersLoading(false)

    window.localStorage.setItem('TicketGridFilters', JSON.stringify({ activo: persisteFiltros, userFilters: filtersState }))
  }, [persisteFiltros])

  useEffect(() => {
    if (props.limpiarFiltros) {
      props.callbackLimpiarFiltros()
      setFiltersState(FILTER_STATE_INIT)
    }

    if (props.filtroKPI) {
      if (props.filtroKPI === 'EN_TALLER') {
        let obj = selectOptions.enTallerSelect.find(option => option.value === 'true')
        setFiltersState({ ...filtersState, enTaller: obj })
        handleChange('enTaller', obj);

      } else {
        let obj = selectOptions.tipos.find(option => option.value === props.filtroKPI)
        setFiltersState({ ...filtersState, ticketTipo: obj })
        handleChange('ticketTipo', obj);
      }
    }
  }, [props.limpiarFiltros, props.filtroKPI])

  const updateToDataTables = (filters) => {
    setFiltersLoading(true)
    if (dataTableColumns.length) {
      for (let filter in filters) {
        let col = dataTableColumns.indexOf(filter)
        let data = ''

        if (filters[filter] !== null && filters[filter].value) {
          data = filters[filter].value
        } else if (filters[filter] !== null && filters[filter] !== '') {
          data = filters[filter]
        }

        if (data) props.dtData.column(col).search(data).draw()
      }
    }
    setFiltersLoading(false)
  }

  const handleChange = (key, object) => {
    setFiltersLoading(true)
    let data
    switch (key) {
      // Selects
      case 'ticketTipo':
      case 'estado':
      case 'nivelAprobacionPresupuesto':
      case 'servicio':
      case 'gerenciador':
      case 'region':
      case 'base':
      case 'centroCostos':
      case 'centroBeneficios':
      case 'enTaller':
      case 'fechaRealizado':
      case 'abono':
      case 'tipoModelo':
        data = object ? object.value : ''
        break
      // Inputs
      default:
        data = object
        break
    }

    //filtro la tabla
    props.dtData.column(dataTableColumns.indexOf(key)).search(data).draw()

    //filtros para export
    let query = props.queryFilters
    query[key] = data
    props.callbackQueryFilters(query)

    //filtrosPersistidos
    window.localStorage.setItem('TicketGridFilters', JSON.stringify({ activo: persisteFiltros, userFilters: filtersState }))
    setFiltersLoading(false)
  }

  const handleOnEnter = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleChange(name, value)
    }
  }

  return (
    <>
      <Collapse in={props.dropdownActive}>
        <Grid container item xs={12} spacing={3} style={{ marginBottom: 20 }}>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="ticketTipo"
                disabled={filtersLoading}
                options={selectOptions.tipos}
                value={filtersState.ticketTipo}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, ticketTipo: value })}
                onKeyDown={(e) => handleOnEnter(e, 'ticketTipo', filtersState.ticketTipo)}
                onBlur={(e) => handleChange("ticketTipo", filtersState.ticketTipo)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.tipo_label", defaultMessage: "Tipo" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={1}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.id_label", defaultMessage: "Tkt" })}
                name="id"
                value={filtersState.id}
                onChange={(e) => setFiltersState({ ...filtersState, id: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'id', filtersState.id)}
                onBlur={(e) => handleChange("id", filtersState.id)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="estado"
                disabled={filtersLoading}
                options={selectOptions.estados}
                value={filtersState.estado}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, estado: value })}
                onKeyDown={(e) => handleOnEnter(e, 'estado', filtersState.estado)}
                onBlur={(e) => handleChange("estado", filtersState.estado)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.estado_label", defaultMessage: "Estado" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.creado_label", defaultMessage: "Creado" })}
                name="fechaHoraAlta"
                value={filtersState.fechaHoraAlta}
                onChange={(e) => setFiltersState({ ...filtersState, fechaHoraAlta: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'fechaHoraAlta', filtersState.fechaHoraAlta)}
                onBlur={(e) => handleChange("fechaHoraAlta", filtersState.fechaHoraAlta)}
                disabled={filtersLoading}
                className="col-12"
                type="date"
                format={"DD-MM-YYYY"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.nivelAprobacionPresupuesto && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="nivelesDeAprobacion"
                disabled={filtersLoading}
                options={selectOptions.nivelesAprobacion}
                value={filtersState.nivelAprobacionPresupuesto}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, nivelAprobacionPresupuesto: value })}
                onKeyDown={(e) => handleOnEnter(e, 'nivelAprobacionPresupuesto', filtersState.nivelAprobacionPresupuesto)}
                onBlur={(e) => handleChange("nivelAprobacionPresupuesto", filtersState.nivelAprobacionPresupuesto)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.nivelesDeAprobacion_label", defaultMessage: "Nivel Aprobacion" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.detalle_label", defaultMessage: "Detalle" })}
                name="detalle"
                value={filtersState.detalle}
                onChange={(e) => setFiltersState({ ...filtersState, detalle: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'detalle', filtersState.detalle)}
                onBlur={(e) => handleChange("detalle", filtersState.detalle)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="servicio"
                disabled={filtersLoading}
                options={selectOptions.servicios}
                value={filtersState.servicio}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, servicio: value })}
                onKeyDown={(e) => handleOnEnter(e, 'servicio', filtersState.servicio)}
                onBlur={(e) => handleChange("servicio", filtersState.servicio)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.servicio_label", defaultMessage: "Servicio" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.entidad_label", defaultMessage: "Entidad" })}
                name="tipoEntidadColumnGrid"
                value={filtersState.tipoEntidadColumnGrid}
                onChange={(e) => setFiltersState({ ...filtersState, tipoEntidadColumnGrid: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'tipoEntidadColumnGrid', filtersState.tipoEntidadColumnGrid)}
                onBlur={(e) => handleChange("tipoEntidadColumnGrid", filtersState.tipoEntidadColumnGrid)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="proveedor"
                disabled={filtersLoading}
                options={selectOptions.gerenciadores}
                value={filtersState.gerenciador}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, gerenciador: value })}
                onKeyDown={(e) => handleOnEnter(e, 'gerenciador', filtersState.gerenciador)}
                onBlur={(e) => handleChange("gerenciador", filtersState.gerenciador)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.proveedor_label", defaultMessage: "Proveedor" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.region && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="region"
                disabled={filtersLoading}
                options={selectOptions.regiones}
                value={filtersState.region}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, region: value })}
                onKeyDown={(e) => handleOnEnter(e, 'region', filtersState.region)}
                onBlur={(e) => handleChange("region", filtersState.region)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.region_label", defaultMessage: "Region" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="base"
                disabled={filtersLoading}
                options={selectOptions.bases}
                value={filtersState.base}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, base: value })}
                onKeyDown={(e) => handleOnEnter(e, 'base', filtersState.base)}
                onBlur={(e) => handleChange("base", filtersState.base)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.base_label", defaultMessage: "Base" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.centroCostos && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="centroCostos"
                disabled={filtersLoading}
                options={selectOptions.cecos}
                value={filtersState.centroCostos}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, centroCostos: value })}
                onKeyDown={(e) => handleOnEnter(e, 'centroCostos', filtersState.centroCostos)}
                onBlur={(e) => handleChange("centroCostos", filtersState.centroCostos)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.ceco_label", defaultMessage: "Centro Costos" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.centroBeneficios && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="centroBeneficios"
                disabled={filtersLoading}
                options={selectOptions.cebes}
                value={filtersState.centroBeneficios}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, centroBeneficios: value })}
                onKeyDown={(e) => handleOnEnter(e, 'centroBeneficios', filtersState.centroBeneficios)}
                onBlur={(e) => handleChange("centroBeneficios", filtersState.centroBeneficios)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.cebe_label", defaultMessage: "Centro Beneficios" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.responsable1 && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.responsable_label", defaultMessage: "Responsable" })}
                name="responsable1"
                value={filtersState.responsable1}
                onChange={(e) => setFiltersState({ ...filtersState, responsable1: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'responsable1', filtersState.responsable1)}
                onBlur={(e) => handleChange("responsable1", filtersState.responsable1)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="taller"
                disabled={filtersLoading}
                options={selectOptions.enTallerSelect}
                value={filtersState.enTaller}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, enTaller: value })}
                onKeyDown={(e) => handleOnEnter(e, 'enTaller', filtersState.enTaller)}
                onBlur={(e) => handleChange("enTaller", filtersState.enTaller)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.taller_label", defaultMessage: "Taller" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.tt_label", defaultMessage: "TT" })}
                name="mttTotal"
                value={filtersState.mttTotal}
                onChange={(e) => setFiltersState({ ...filtersState, mttTotal: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'mttTotal', filtersState.mttTotal)}
                onBlur={(e) => handleChange("mttTotal", filtersState.mttTotal)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.tp_label", defaultMessage: "TP" })}
                name="mttParcial"
                value={filtersState.mttParcial}
                onChange={(e) => setFiltersState({ ...filtersState, mttParcial: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'mttParcial', filtersState.mttParcial)}
                onBlur={(e) => handleChange("mttParcial", filtersState.mttParcial)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.encuestaSatisfaccion && { display: "none" }} component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.encuestaSatisfaccion_label", defaultMessage: "Satisfaccion" })}
                name="encuestaNivelSatisfaccion"
                value={filtersState.encuestaNivelSatisfaccion}
                onChange={(e) => setFiltersState({ ...filtersState, encuestaNivelSatisfaccion: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'encuestaNivelSatisfaccion', filtersState.encuestaNivelSatisfaccion)}
                onBlur={(e) => handleChange("encuestaNivelSatisfaccion", filtersState.encuestaNivelSatisfaccion)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="fechaRealizado"
                disabled={filtersLoading}
                options={selectOptions.realizadoSelect}
                value={filtersState.fechaRealizado}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, fechaRealizado: value })}
                onKeyDown={(e) => handleOnEnter(e, 'fechaRealizado', filtersState.fechaRealizado)}
                onBlur={(e) => handleChange("fechaRealizado", filtersState.fechaRealizado)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.realizado_label", defaultMessage: "Realizado" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.fechaHoraTurno && { display: "none" }} component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "ticketsGridFiltersFilters.render.turno_label", defaultMessage: "Turno" })}
                name="fechaHoraTurno"
                value={filtersState.fechaHoraTurno}
                onChange={(e) => setFiltersState({ ...filtersState, fechaHoraTurno: e.target.value })}
                onKeyDown={(e) => handleOnEnter(e, 'fechaHoraTurno', filtersState.fechaHoraTurno)}
                onBlur={(e) => handleChange("fechaHoraTurno", filtersState.fechaHoraTurno)}
                disabled={filtersLoading}
                className="col-12"
                type="date"
                // isClearable={true}
                format={"DD-MM-YYYY"}
                // disableFuture
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.abono && { display: "none" }} component={Box} item xs={2}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="abono"
                disabled={filtersLoading}
                options={selectOptions.abonoSelect}
                value={filtersState.abono}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, abono: value })}
                onKeyDown={(e) => handleOnEnter(e, 'abono', filtersState.abono)}
                onBlur={(e) => handleChange("abono", filtersState.abono)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.abono_label", defaultMessage: "Abono" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!props.columnsEnabled.tipoModelo && { display: "none" }} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="tipoModelo"
                disabled={filtersLoading}
                options={selectOptions.tipoModelos}
                value={filtersState.tipoModelo}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, value) => setFiltersState({ ...filtersState, tipoModelo: value })}
                onKeyDown={(e) => handleOnEnter(e, 'tipoModelo', filtersState.tipoModelo)}
                onBlur={(e) => handleChange("tipoModelo", filtersState.tipoModelo)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "ticketsGridFilters.render.tipoModelo_label", defaultMessage: "Tipo Modelo" })}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid component={Box} item xs={2} className="align-self-end">
            <form noValidate autoComplete="off">
              <div className="d-flex inline justify-content-around align-items-center">
                <FormattedMessage
                  id="ticketsGridFilters.render.filtros.persistir_filtros"
                  defaultMessage="Persistir Filtros"
                />
                <Switch
                  onChange={(value) => setPersisteFiltros(value)}
                  checked={persisteFiltros}
                  disabled={filtersLoading}
                  offColor="#FF4961"
                  onColor="#28D094"
                />
              </div>
            </form>
          </Grid>
        </Grid>
      </Collapse>
    </>
  )
}


export default TicketGridFilters
