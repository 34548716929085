import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App.js';
import 'font-awesome/css/font-awesome.min.css';
import Config from './commons/config/Config.js';
// TODO: make the default dependant on the ConfigBusiness?
fetch('/config/config.json', {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
  .then((response) => response.json())
  .then((configData) => {
    localStorage.setItem('config', JSON.stringify(configData));
    const defaultLocale = Config.get('defaultLanguage') || 'es'; // 'es' is the default locale
    let locale = localStorage.getItem('userLocale') || defaultLocale;
    localStorage.setItem('userLocale', locale);

    (async (locale) => {
      let messages = null;
      // const response = await fetch(configData.apiUrlBase+"/public/langs/"+locale, {
      //     method: 'GET',
      //     headers: {
      //         'Accept': 'application/json',
      //         'Content-Type': 'application/json'
      //     }
      // })
      fetch(`https://flow.vecfleet.io/api/lang/${locale}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          messages = JSON.parse(JSON.stringify(data));
          ReactDOM.render(
            <App locale={locale} messages={messages} />,
            document.getElementById('root'),
          );
        })
        .catch(function (error) {
          // TODO: Manejo de errores
          messages = {};
          console.log(error);
          ReactDOM.render(
            <App locale={locale} messages={messages} />,
            document.getElementById('root'),
          );
        });
      //const response = await fetch(`/lang/${locale}.json`);
      //let messages = null;
      //if (response.status >= 200 && response.status <=299) { try { messages = await response2.json(); } catch (e) { messages = {} } }
    })(locale);
  });