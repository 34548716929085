import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import FormValidation from '../../commons/validation/FormValidation.js';
import Validator from '../../commons/validation/Validator.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';
import Loading from '../ui/Loading.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/min/locales';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Security from '../../commons/security/Security.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import swal from 'sweetalert2';
import {
  ID__UM_HOROMETRO,
  ID__UM_ODOMETRO,
  TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR,
} from '../unidadMedidor/constants.js';

class TicketInformarFechaRealizado extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
        id: this.props.ticket.id,
        fechaHoraRealizado: null,
        kmRealizado: '',
      },
      errors: [],
      loading: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);

    let validators = {
      'formData.fechaHoraRealizado': (value) => {
        let valid = true;
        let message = '';
        let minDate = '';
        moment.locale('es');
        let date = moment(value);

        let diasFechaAlta = moment(this.props.ticket.fechaHoraAlta).diff(moment(), 'days');
        let diasAnterioresPermitido = -ConfigBusiness.get('tickets.fechaRealizado.diasAnterioresPermitido');

        if (diasAnterioresPermitido > diasFechaAlta) {
          minDate = moment(this.props.ticket.fechaHoraAlta).add(diasAnterioresPermitido, 'days').add(-1, 'days');
        } else {
          minDate = moment(this.props.ticket.fechaHoraAlta).add(diasFechaAlta, 'days').add(-1, 'days');
        }

        let maxDate = moment();
        if (!Validator.date(date).valid) {
          valid = false;
          message = this.props.intl.formatMessage({
            id: 'ticketInformarFechaRealizado.date_errors_ingresada_es_invalida',
            defaultMessage: 'La fecha ingresada es inválida.',
          });
        } else if (date.isAfter(maxDate)) {
          valid = false;
          message = this.props.intl.formatMessage({
            id: 'ticketInformarFechaRealizado.date_errors_fecha_no_puede_ser_mayor',
            defaultMessage: 'La fecha no puede ser mayor a hoy',
          });
        } else if (date.isBefore(minDate)) {
          valid = false;
          message = this.props.intl.formatMessage({
            id: 'ticketInformarFechaRealizado.date_errors_la_fecha_no_puede_ser_menor_a_un_mes_atras',
            defaultMessage: 'La fecha no puede ser menor a ' + minDate + ' días atrás de la fecha de alta.',
          });
        }
        return {
          valid: valid,
          message: message,
        };
      },
    };

    if (
      ConfigBusiness.get('tickets.editarKmRealizado.habilitado') === 'true' &&
      Security.hasPermission('TICKETS_EDITAR_KM_REALIZADO' && this.props.ticket.movil )
    ) {
      validators['formData.kmRealizado'] = (value) => Validator.notEmpty(value);
    }

    this.formValidation = new FormValidation({
      component: this,
      validators: validators,
    });
  }

  componentDidMount() {
    this.ajaxHandler.subscribe(this);
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
    this.setState(
      {
        loading: false,
        formData: {
          id: null,
          fechaHoraRealizado: null,
          kmRealizado: '',
        },
      },
      () => {
        this.formValidation.validate();
        this.props.callbackSave();
      },
    );
  }

  handleFormChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleDatePickerFormChange(name, event) {
    let date = event ? event.format() : '';
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = date;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = date;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleInputFormChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;
    this.setState({
      formData: formDataCopy,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (
      ConfigBusiness.get('tickets.editarKmRealizado.habilitado') === 'true' &&
      Security.hasPermission('TICKETS_EDITAR_KM_REALIZADO' && this.props.ticket.movil )
    ) {
      this.checkMovilKilometros(this.props.ticket.movil, event);
    } else {
      this.handleUpdateData(event);
    }
  }

  handleCancel() {
    this.ajaxHandler.unsubscribe();
    this.setState(
      {
        loading: false,
        formData: {
          id: null,
          fechaHoraRealizado: null,
          kmRealizado: '',
        },
      },
      () => {
        this.props.callbackClose();
      },
    );
  }

  checkMovilKilometros(movil, event) {
    let maxDif = ConfigBusiness.get('moviles.editaKm.maximo');
    let ultimoMovilKm = movil.kmActual
      ? { km: movil.kmActual, fecha: movil.kmUpdate }
      : { km: 0, fecha: null };
    let title =
      'Diferencia de ' +
      UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades'] +
      ' es mayor a ' +
      maxDif +
      ' ' +
      UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades'];
    let registrado =
      movil.kmActual == 0
        ? 'No Registra ' + UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades']
        : parseFloat(movil.kmActual) +
          UNIDAD_MEDIDOR[movil.unidadMedidorId]['unidades'] +
          ' recorridos ' +
          (movil.kmUpdate ? 'al ' + moment(movil.kmUpdate).format('MM/DD/YYYY') : '');
    if (Math.abs(this.state.formData.kmRealizado - parseFloat(movil.kmActual)) > maxDif) {
      swal({
        type: 'question',
        title: title,
        text: registrado,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#FF394F',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#079391',
      }).then((isConfirmed) => {
        if (isConfirmed.value) {
          this.handleUpdateData(event);
        } else {
          console.log('cancelado');
        }
      });
    } else {
      this.handleUpdateData(event);
    }
  }

  handleUpdateData(event) {
    let data = {
      id: this.props.ticket.id,
      fechaHoraRealizado: this.state.formData.fechaHoraRealizado,
      kmRealizado: this.state.formData.kmRealizado,
    };

    this.props.callbackSave(data);
    event.preventDefault();
  }

  render() {
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let requiredSymbol = ' *';
    
    let minDate = '';
    let diasFechaAlta = moment(this.props.ticket.fechaHoraAlta).diff(moment(), 'days');
    let diasAnterioresPermitido = -ConfigBusiness.get('tickets.fechaRealizado.diasAnterioresPermitido');
    if (diasAnterioresPermitido > diasFechaAlta) {
      minDate = moment().add(diasAnterioresPermitido, 'days');
    } else {
      if(diasFechaAlta < 0){
        minDate = moment().add(diasFechaAlta, 'days').add(-1, 'days');
      }else{
        minDate = moment();
      }
    }

    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.errors.length === 0}
            >
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage
                    {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}
                  />
                </li>
              ))}
            </div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-file-text"></i>
                        <FormattedMessage
                          id="ticketInformarFechaRealizado.render.report_done_date.header_informar_fecha_de_realizado"
                          defaultMessage=" Informar Fecha de Realizado"
                        />
                        <div className="float-right" style={{ fontSize: '14px' }}>
                          *{' '}
                          <FormattedMessage
                            id="ticketInformarFechaRealizado.render.report_done_date.campos_requeridos"
                            defaultMessage="campos requeridos"
                          />
                        </div>
                      </h4>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            {/* FECHA REALIZADO */}
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label
                                  className="col-md-6 label-control col-form-label"
                                  htmlFor="fechaHoraRealizado"
                                >
                                  <FormattedMessage
                                    id="ticketInformarFechaRealizado.render.report_done_date.label_fecha_realizado"
                                    defaultMessage="Fecha Realizado"
                                  />
                                  {requiredSymbol}:
                                </label>
                                <div className="col-md-6">
                                  <div>
                                    <DatePicker
                                      id="fechaHoraRealizado"
                                      name="fechaHoraRealizado"
                                      className="form-control date-picker-placeholder"
                                      popperPlacement="right-start"
                                      placeholderText={this.props.intl.formatMessage({
                                        id: 'ticketInformarFechaRealizado.render.report_done_date.placeholder_fecha_realizado',
                                        defaultMessage: 'DD/MM/AAAA',
                                      })}
                                      selected={
                                        formData.fechaHoraRealizado === null ||
                                        formData.fechaHoraRealizado === '0000-00-00'
                                          ? null
                                          : moment(formData.fechaHoraRealizado)
                                      }
                                      onChange={(event) =>
                                        this.handleDatePickerFormChange('fechaHoraRealizado', event)
                                      }
                                      onChangeRaw={(event) =>
                                        this.handleDatePickerFormRawChange(
                                          'fechaHoraRealizado',
                                          event.target.value,
                                        )
                                      }
                                      openToDate={moment()}
                                      maxDate={moment()}
                                      minDate={minDate}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      autoComplete="off"
                                    />
                                    <div
                                      className="help-block text-danger field-message"
                                      hidden={
                                        validationState.formData.fechaHoraRealizado.pristine ||
                                        validationState.formData.fechaHoraRealizado.valid
                                      }
                                    >
                                      {validationState.formData.fechaHoraRealizado.message}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.ticket.movil ? (
                  ConfigBusiness.get('tickets.editarKmRealizado.habilitado') === 'true' &&
                  Security.hasPermission('TICKETS_EDITAR_KM_REALIZADO') ? (
                    <div className="card">
                      <div className="card-content">
                        <div className="card-body">
                          <h4 className="form-section">
                            {this.props.ticket.movil.unidadMedidorId === ID__UM_ODOMETRO && (
                              <>
                                <i className="la la-tachometer align-middle icon-modal-title"></i>
                                <FormattedMessage
                                  id="ticket.modal_editarKmRealizado.render.report_done_date.header_informar_odometro_realizado"
                                  defaultMessage=" Informar Odometro Realizado"
                                />
                              </>
                            )}
                            {this.props.ticket.movil.unidadMedidorId === ID__UM_HOROMETRO && (
                              <>
                                <i className="la la-stopwatch align-middle icon-modal-title"></i>
                                <FormattedMessage
                                  id="ticket.modal_editarKmRealizado.render.report_done_date.header_informar_horometro_realizado"
                                  defaultMessage=" Informar Horometro Realizado"
                                />
                              </>
                            )}
                          </h4>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group row">
                                {/* KM REALIZADO */}
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label
                                      className="col-md-6 label-control col-form-label"
                                      htmlFor="KmRealizado"
                                    >
                                      <FormattedMessage
                                        id="ticket.modal_editarKmRealizado.render.report_done_date.label_km_realizado"
                                        defaultMessage="Km Realizado"
                                      />{' '}
                                      {requiredSymbol}:
                                    </label>
                                    <div className="col-md-6">
                                      <div>
                                        <input
                                          type="number"
                                          className="form-control text-right pr-1"
                                          id="kmRealizado"
                                          name="kmRealizado"
                                          value={this.state.formData.kmRealizado}
                                          onChange={(e) => this.handleInputFormChange(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                ) : null}
              </div>
              <div className="modal-footer modal-grey">
                <div className="text-cd text-right">
                  <button
                    type="submit"
                    className="btn btn-primary mr-1"
                    disabled={!validationState.form.valid}
                  >
                    <i className="fa fa-check-circle"></i>
                    <FormattedMessage
                      id="ticketInformarFechaRealizado.finish_button.guardar"
                      defaultMessage=" Guardar"
                    />
                  </button>
                  <button type="button" className="btn btn-danger" onClick={this.handleCancel}>
                    <i className="fa fa-times-circle"></i>
                    <FormattedMessage
                      id="ticketInformarFechaRealizado.finish_button.cancelar"
                      defaultMessage=" Cancelar"
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(TicketInformarFechaRealizado);
