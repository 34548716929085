import React, { Component } from 'react'
import Switch from "react-switch"
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Security from '../../commons/security/Security.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import $ from 'jquery'
import TicketHistorico from './TicketHistorico.js';
import TicketTareas from './TicketTareas.js';
import OtrosTickets from './OtrosTickets.js';
import TicketInformarFechaRealizado from './TicketInformarFechaRealizado.js';
import MovilesAbm from './../moviles/MovilesAbm.js';
import PersonasAbm from './../personas/PersonasAbm.js';
import LlantasAbm from './../llantas/LlantasAbm';
import TicketsAbm from './TicketsAbm.js';
import EamDetalle from './../rop/EamDetalle.js';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'moment/min/locales';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import swal from 'sweetalert2'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { ID__UM_ODOMETRO, ID__UM_HOROMETRO } from '../unidadMedidor/constants.js'
import Timezone from '../../commons/timezone/Timezone.js';

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TicketDatosGenerales extends Component {
	constructor(props) {
    super(props);
    this.props = props;
    this.ajaxHandler = new AjaxHandler();

    if(props.ticket) {
      this.ticket = props.ticket;
    }

    moment.locale('es');

    this.state = {
      propsMatch: null,
      modalInformacion: 0,
      modalHistorico: 0,
      modalDetalleEam: 0,
      ticketPropsMatch: null,
      modalVerOtrosTickets: 0,
			modalVerTicketAlternativo: 0,
      modalFechaRealizadoKey: 0,
      esSiniestro: false,
      numeroSiniestro: '',
      loading: false,
      icono: 'la la-check',
      iconoReemplazo: 'la la-send',
      cambioEstadoSiniestro: false,
      apagarAbono: false,
      centroCostosSelect:[],
      centroCostosLoading: false,
      centroCostos: null,
      ot: null,
      tareas: null,
      esReemplazoDeVehiculo: false,
      fechaEntrega: null,
      placaReemplazo: '',
      otAsociada: '',
      iconoOTAsociada: 'la la-send',
      loadingOT: false,
      // logo64: 'data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
    }

    this.handleSiniestroChange = this.handleSiniestroChange.bind(this);
    this.handleSiniestroFormChange = this.handleSiniestroFormChange.bind(this);
    this.handleEnviar = this.handleEnviar.bind(this);
    this.handleEnviarReemplazo = this.handleEnviarReemplazo.bind(this);
    this.handleFormChangeSelectCentroCostos = this.handleFormChangeSelectCentroCostos.bind(this);
    this.handleInmovilizadoChange = this.handleInmovilizadoChange.bind(this);
    this.handleReemplazoVehiculoChange = this.handleReemplazoVehiculoChange.bind(this);
    this.handleReemplazoVehiculoFormChange = this.handleReemplazoVehiculoFormChange.bind(this);
    this.handleOTAsociadaFormChange = this.handleOTAsociadaFormChange.bind(this);
    this.handleEnviarOTAsociada = this.handleEnviarOTAsociada.bind(this);
  }

  handleAutogestionChange (){}
  handleForzadoChange (){}
  handleAbonoChange(){}
  handleReemplazoVehiculoChange(){}

  componentWillMount() {
    this.props.onRef(this);
    this.init();
  }

  componentDidMount() {
    this.loadTareas();
    $('[data-tooltip="tooltip"]').tooltip({ trigger: "hover" });
    this.getLogo64();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
    this.ajaxHandler.unsubscribe();
  }

  loadTareas() {
    this.ajaxHandler.getJson('/tareas/ticket-has-tareas-cierre/' + this.ticket.id)
    .then(hasTareasCierre => {
      let component = this;
      let grid = ConfigBusiness.get('tickets.tareasCierre.habilitado') === 'true' ? ( hasTareasCierre ? '/gridCierre' : '/grid' ) : '/grid';
      this.setState({ loading: true });
      this.ajaxHandler.getJson('/tareas/ticket/' + this.ticket.id + grid )

      .then(data => {
        if(data) component.setState({
          tareas: data
        });
      }).finally(() => {
        this.setState({ loading: false });
      });
    });
  }

	entidadId() {
		let ticket = this.props.ticket
		if (ticket.movil)
			return ticket.movil.id
		if (ticket.persona)
			return ticket.persona.id
		if (ticket.llanta)
			return ticket.llanta.id
	}

	entidadName() {
		let ticket = this.props.ticket
		if (ticket.movil)
			return "moviles"
		if (ticket.persona)
			return "personas"
		if (ticket.llanta)
			return "llantas"
	}

	entidadABM() {
		let ticket = this.props.ticket
		if (ticket.movil && !ticket.llanta)
			return <MovilesAbm key={this.state.modalInformacion} action="VIEW" hideBack={true} match={this.state.propsMatch}></MovilesAbm>
		if (ticket.persona)
			return <PersonasAbm key={this.state.modalInformacion} action="VIEW" hideBack={true} match={this.state.propsMatch}></PersonasAbm>
		if (ticket.llanta)
			return <LlantasAbm key={this.state.modalInformacion} action="VIEW" hideBack={true} match={this.state.propsMatch}></LlantasAbm>
	}

  init() {
    this.ajaxHandler.subscribe(this);
    let esSiniestro = this.props.ticket.esSiniestro ? this.props.ticket.esSiniestro : false;
    let nroSiniestro = this.props.ticket.numeroSiniestro ? this.props.ticket.numeroSiniestro : '';
    let inmovilizado = this.props.ticket.inmovilizado ? this.props.ticket.inmovilizado : false;
    let inmovilizadoEnabled = this.props.inmovilizado;
    let esReemplazoDeVehiculo = this.props.ticket.esReemplazoDeVehiculo ? this.props.ticket.esReemplazoDeVehiculo : false;
    let fechaEntrega = this.props.ticket.fechaEntrega ? Timezone.getDateForClient(this.props.ticket.fechaEntrega, null, null) : null;
    let placaReemplazo = this.props.ticket.placaReemplazo ? this.props.ticket.placaReemplazo : '';
    let otAsociada = this.props.ticket.otAsociada ? this.props.ticket.otAsociada : '';
    let component = this;
    this.setState({
      propsMatch: {
        isExact: true,
        params: {
          id: this.entidadId()
        },
        path: "/" + this.entidadName() + "/:" + this.entidadId(),
        url: "/" + this.entidadName() + "/" + this.entidadId()
      },
      esSiniestro: esSiniestro,
      numeroSiniestro: nroSiniestro,
      loading: true,
      inmovilizadoEnabled: inmovilizadoEnabled,
      inmovilizado: inmovilizado,
      esReemplazoDeVehiculo: esReemplazoDeVehiculo,
      fechaEntrega: fechaEntrega,
      placaReemplazo: placaReemplazo,
      otAsociada: otAsociada
    });
    if(Security.hasPermission('TICKETS_EDITAR_CENTRO_COSTOS')){
      this.setState({
        centroCostosLoading: true
      });
      Promise.all([component.ajaxHandler.getJson('/centros-costos/selectobservaciones')
      ]).then((data) => {
        this.setState({
          centroCostosSelect: data[0],
          centroCostos: this.ticket.centroCostos ? {"value":this.ticket.centroCostos.id,"label":this.ticket.centroCostos.nombre+' - '+this.ticket.centroCostos.observaciones}:null,
          centroCostosLoading: false
        });
      }).catch(function(error) {
        component.ajaxHandler.handleError(error);
      });
    }

    Promise.all([
      component.ajaxHandler.getJson('/tickets/' + this.ticket.id + '/ot')
    ]).then((data) => {
      let ot = data[0] ? data[0] : null;
      this.setState({
        ot: ot
      });
    }).catch(function(error) {
			component.ajaxHandler.handleError(error);
    })
  }

	handleVerOtrosTickets(e) {
		e.preventDefault();
		let modalVerOtrosTickets = this.state.modalVerOtrosTickets + 1;

		this.setState({
			modalVerOtrosTickets: modalVerOtrosTickets,
		}, () => {
			$('#otros_tickets_modal').modal('toggle');
		});
	}

  handleCallbackOtrosTickets(id){
		let modalVerTicketAlternativo = this.state.modalVerTicketAlternativo + 1;

    this.setState({
			modalVerTicketAlternativo: modalVerTicketAlternativo,
      ticketPropsMatch: {
        isExact: true,
        params: {
          id: id
        },
        path: "/tickets/:" + id,
        url: "/tickets/" + id
      },
    }, () => {
      $('#ver_ticket_alternativo_en_modal').modal({backdrop: 'static'});
    });
  }

  handleSiniestroChange(siniestro){
    this.setState({esSiniestro: siniestro});
    if(siniestro !== this.props.ticket.esSiniestro){
      //si cambió el siniestro a ON y muestra abono, deshabilitarlo
      if(siniestro == true){
        if(ConfigBusiness.get('mantenimientos.correctivo.abono.habilitado') === 'true'){
          this.setState({apagarAbono: true});
      }}
      else{
        this.setState({apagarAbono: false});
      }
      this.setState({cambioEstadoSiniestro: true, icono: "la la-send"}, ()=>{
          $('#enviarSiniestro').tooltip({ trigger: "hover" });
      });
    }else{
      this.setState({cambioEstadoSiniestro: false, icono: "la la-check", apagarAbono:false }, ()=>{
        $('#enviarSiniestro').tooltip("hide");
      });
    }

    if(!siniestro)
      this.setState({numeroSiniestro:''});
    else
      this.setState({numeroSiniestro: this.props.ticket.numeroSiniestro});

  }

  handleSiniestroFormChange(event) {

		let target = event.target;
    let name = target.name;

    let value = target.value;

    if(value){
      $('#enviarSiniestro').tooltip({ trigger: "hover" });
    }
	  this.setState({
			[name]: value, icono: "la la-send", cambioEstadoSiniestro: true
		});
  }

  handleReemplazoVehiculoChange(reemplazoVehiculo){
    this.setState({esReemplazoDeVehiculo: reemplazoVehiculo});
    
    if(reemplazoVehiculo !== this.props.ticket.esReemplazoDeVehiculo) {
      this.setState({
        cambioEstadoReemplazo: true, iconoReemplazo: "la la-send"}, ()=>{
        $('#enviarReemplazo').tooltip({ trigger: "hover" });
      });
    }else{
      this.setState({cambioEstadoReemplazo: false, iconoReemplazo: "la la-check" }, ()=>{
        $('#enviarReemplazo').tooltip("hide");
      });
    }
    
  }
  
  handleReemplazoVehiculoFormChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    if(value){
      $('#enviarReemplazo').tooltip({ trigger: "hover" });
    }
	  this.setState({
			[name]: value, iconoReemplazo: "la la-send", cambioEstadoReemplazo: true
		});
  }

  handleOTAsociadaFormChange(event) {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value,
      iconoOTAsociada: "la la-send"
    });
  }

  handleDatePickerFormChange(name, event) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.fechaEntrega));
    formDataCopy = event;
		this.setState({
		  fechaEntrega: formDataCopy
		});
    if(event){
      $('#enviarReemplazo').tooltip({ trigger: "hover" });
    }
	  this.setState({
			iconoReemplazo: "la la-send", cambioEstadoReemplazo: true
		});
	}
	
	handleDatePickerFormRawChange(name, date) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.fechaEntrega));
			formDataCopy = date;
			this.setState({
				fechaEntrega: formDataCopy
		  });
	}

  handleInmovilizadoChange(){

  }

  handleEnviar(event) {
    this.setState({ loading: true });
    let component = this;
    let body = {esSiniestro: this.state.esSiniestro, numeroSiniestro: this.state.numeroSiniestro};
    if(this.state.apagarAbono){
      body['abono'] = false;
    }
    body = JSON.stringify(body);
		this.ajaxHandler.fetch('/tickets/siniestro/' + this.props.ticket.id, {
			method: 'PUT',
			body: body
		}).then(response => {
      if(response.status !== 400) {
        $("#enviarSiniestro").tooltip('hide');
        //se muestra el botón deshabilitado pero con OK
        this.setState({icono: 'la la-check', cambioEstadoSiniestro: false});

        //se guarda en el estado del ticekt el cambio de los parámetros
        this.props.cambioEstadoSiniestro(this.state.esSiniestro, this.state.numeroSiniestro);
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
		}).catch((error) => {
			this.ajaxHandler.handleError(error);
		}).finally(() => {
      this.setState({ loading: false});
    });
		event.preventDefault();
  }

  handleEnviarReemplazo(event) {
    this.setState({ loading: true });
    let component = this;
    let body = {esReemplazoDeVehiculo: this.state.esReemplazoDeVehiculo, fechaEntrega: this.state.fechaEntrega, placaReemplazo: this.state.placaReemplazo};
    body = JSON.stringify(body);
		this.ajaxHandler.fetch('/tickets/reemplazoVehiculo/' + this.props.ticket.id, {
			method: 'PUT',
			body: body
		}).then(response => {
      if(response.status !== 400) {
        $("#enviarReemplazo").tooltip('hide');
        //se muestra el botón deshabilitado pero con OK
        this.setState({iconoReemplazo: 'la la-check', cambioEstadoReemplazo: false});
        //se guarda en el estado del ticekt el cambio de los parámetros
        this.props.cambioEstadoReemplazo(this.state.esReemplazoDeVehiculo, this.state.fechaEntrega, this.state.placaReemplazo);
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
		}).catch((error) => {
			this.ajaxHandler.handleError(error);
		}).finally(() => {
      this.setState({ loading: false});
    });
		event.preventDefault();
  }

  handleEnviarOTAsociada(event) {
    this.setState({ loading: true });
    this.setState({ loadingOT: true });
    let component = this;
    let body = {otAsociada: this.state.otAsociada};
    body = JSON.stringify(body);
    this.ajaxHandler.fetch('/tickets/otAsociada/' + this.props.ticket.id, {
      method: 'PUT',
      body: body
    }).then(response => {
      if(response.status !== 400) {
        this.setState({iconoOTAsociada: 'la la-check'});
        //this.props.cambioEstadoReemplazo(this.state.esReemplazoDeVehiculo, this.state.fechaEntrega, this.state.placaReemplazo, this.state.otAsociada);
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
    }).catch((error) => {
      this.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false});
      this.setState({ loadingOT: false});
    });
    event.preventDefault();
  }

  handleFechaRealizadoOpen(e) {
    e.preventDefault();
    let modalFechaRealizadoKey = this.state.modalFechaRealizadoKey + 1;
    this.setState({
      modalFechaRealizadoKey: modalFechaRealizadoKey
    }, () => {
      $('#fecha_realizado_modal').modal({backdrop: 'static'});
    });
  }

  handleFechaRealizadoDelete(e) {
    swal({'title': this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_clear_done_date.header_borrar_fecha_de_realizado', defaultMessage: 'Borrar Fecha de Realizado' }),
      'text': this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_clear_done_date.confirm_borrar_fecha_de_realizado', defaultMessage: '¿Desea borrar la fecha ?' }),
      'type':"question",
      'confirmButtonText':this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_clear_done_date.confirm_borrar_fecha_de_realizado.si', defaultMessage: 'Si' }),
      'cancelButtonText': this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_clear_done_date.confirm_borrar_fecha_de_realizado.no', defaultMessage: 'No' }),
      'showCancelButton': true})
  .then((result) => {

    if (result.value) {
      this.ajaxHandler.fetch('/tickets/delete-realizado/' + this.props.ticket.id, {
        method: 'POST',

      }).then(response => {
        if(response.status === 204) {
          this.props.callbackDataUpdate();
          swal(
            this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_clear_done_date.information_eliminado', defaultMessage: 'Eliminado!' }),
            this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_clear_done_date.information_fecha_actualizo', defaultMessage: 'La fecha de realizado se actualizó' }),
            'success'
          )
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }

      }).catch((error) => {
        this.ajaxHandler.handleError(error);
      });
    }
  });
  }

  handleFechaRealizadoSave(obj) {
    $('#fecha_realizado_modal').modal('toggle');
    this.ajaxHandler.fetch('/tickets/update-realizado/' + this.props.ticket.id, {
      method: 'POST',
      body: JSON.stringify({
        ...obj
      }),
    }).then(response => {
      if(response.status === 204) {
        this.props.callbackDataUpdate();
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
      window.scrollTo(0,0);
    }).catch((error) => {
      this.ajaxHandler.handleError(error);
    });
  }

  handleFechaRealizadoClose() {
    $('#fecha_realizado_modal').modal('toggle');
  }


  handleVerInformacion(e) {
    e.preventDefault();
    let modalInformacion = this.state.modalInformacion + 1;

    this.setState({
      modalInformacion: modalInformacion,
      propsMatch: {
        isExact: true,
        params: {
          id: this.entidadId()
        },
        path: "/" + this.entidadName() + "/:" + this.entidadId(),
        url: "/" + this.entidadName() + "/" + this.entidadId()
      }
    }, () => {
      $('#informacion_modal').modal({backdrop: 'static'});
    });
  }

	handleVerHistorico(e) {
		e.preventDefault();
		let modalHistorico = this.state.modalHistorico + 1;

		this.setState({
			modalHistorico: modalHistorico
		}, () => {
			$('#historico_modal').modal({backdrop: 'static'});
		});
	}

  handleExportHistoricoCancel() {
		$('#historico_modal').modal('toggle');
	}

  handleExportExcelHistorico() {
		this.setState({ loading: true });
		this.ajaxHandler.fetch('/ticket-historico/exportar-excel', {
			method: 'POST',
			body: JSON.stringify({
        ticketId: this.props.ticket.id,
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-ticket-historico.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			this.handleExportHistoricoCancel();
			this.setState({ loading: false });
		});
	}

  handleVerDetalleEam(e) {
		e.preventDefault();
		let modalDetalleEam = this.state.modalDetalleEam + 1;

		this.setState({
			modalDetalleEam: modalDetalleEam
		}, () => {
			$('#modal_detalle_eam').modal({backdrop: 'static'});
		});
	}

  handleFormChangeSelectCentroCostos(object) {
    let centroCostos = object ? object : null;
    this.setState({
      centroCostos :centroCostos,
      centroCostosLoading: true
    });
    let body = {centroCostos: centroCostos ? centroCostos.value : null};
    body = JSON.stringify(body);
    this.ajaxHandler.fetch('/tickets/centro-costos/' + this.props.ticket.id, {
      method: 'PUT',
      body: body
    }).then(response => {
      if(response.status !== 400) {
        //se guarda en el estado del ticekt el cambio de los parámetros
        this.props.reloadActions()
        this.setState({
          centroCostosLoading: false
        });
        swal(this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_select_cost_center.information_datos_actualizados', defaultMessage: 'Datos Actualizados' }),
          this.props.intl.formatMessage({ id: 'ticketDatosGenerales.modal_select_cost_center.information_se_guardo_el_centro_de_costos', defaultMessage: 'Se guardó el centro de costos' }),
          "success");
      } else {
        response.json()
        .then(data => {
          this.setState({
            errors: data.detalle
          });
        });
      }
    }).catch((error) => {
      this.ajaxHandler.handleError(error);
    }).finally(() => {
      this.setState({ loading: false});
    });
  }

  getLogo64() {
    let component = this;
    function toDataURL(src, callback){
      var xhttp = new XMLHttpRequest();
      xhttp.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhttp.response);
      };

      xhttp.responseType = 'blob';
      xhttp.open('GET', src, true);
      xhttp.send();
    };

    toDataURL('/images/company/logo-login-company.png',
    function(img64){
      component.setState({logo64: img64});
    });
  }

  handleImprimir(e) {
    e.preventDefault();
    let ticket = this.props.ticket;
    let reparaciones = [['REPARACIONES QUE SE HICIERON EN EL TALLER ASIGNADO:']]
    let tareas = [];
    let logo64 = this.state.logo64;

    if (this.state.tareas.length > 0) {
      this.state.tareas.forEach(function(tarea) {
          var row = [];
          row.push(tarea.nombre);
          tareas.push(row);
      });
    } else {
      tareas.push(['']);
    }

    if (this.state.tareas.length > 0) {
      this.state.tareas.forEach(function(tarea) {
          var row = [];
          row.push(tarea.servicio.nombre+' - '+tarea.nombre);
          reparaciones.push(row);
      });
    } else {
      reparaciones.push(['']);
    }

    let data = {
      logo : logo64,
      ticketId: ticket.id,
      interno: ticket.movil.responsable1 ? ticket.movil.responsable1.apellido+', '+ticket.movil.responsable1.nombre : '',
      FirmaResponsable: ticket.movil.supervisor ? ticket.movil.supervisor.apellido+', '+ticket.movil.supervisor.nombre : 'Firma y Aclaraci\ón    de Responsable ',
      dominio: ticket.movil.dominio ? ticket.movil.dominio : '',
      marcaMovil: ticket.movil.marca.nombre ? ticket.movil.marca.nombre : '',
      modeloMovil: ticket.movil.modelo.nombre ? ticket.movil.modelo.nombre : '',
      anio: ticket.movil.anio ? ticket.movil.anio : '',
      tipoCombustible: ticket.movil.combustible ? ticket.movil.combustible : '',
      kilometraje: ticket.kmGenerado ? ticket.kmGenerado : '',
      servicio: ticket.servicio.nombre ? ticket.servicio.nombre : '',
      unidad: ticket.movil.dominio ? ticket.movil.dominio : '',
      ubicacion: ticket.movil.base ? ticket.movil.base.descripcion : '',
      listadoTareas: tareas,
      fechaTermTrabajo: ticket.fechaHoraRealizado ? ticket.fechaHoraRealizado.slice(0, -8) : '',
      kmSalidaTaller: (ticket.kmRealizado > 0) ? ticket.kmRealizado : '',
      listadoReparaciones: reparaciones,
      taller: ticket.taller ? ticket.taller.razonSocial : ''
    }

    let file = {
      pageSize: {
        width: 595,
        height: 842
      },
      pageMargins: [30, 30, 30, 30],

      content: [
        {
          // color: '#444',
          table: {
            widths: ['auto', '*', 'auto', '*'],
            headerRows: 1,
            body: [
              [
                {image: data.logo, fit: [100,100], style: 'tableHeader'},
                {text: 'ORDEN DE REPARACION', style: 'tableHeader'},
                {text: 'CENTRO SAN \n MARTIN', style: 'tableHeader', fontSize: 8, bold: false},
                {rowSpan: 3, text: 'O.R. Nº \n'+data.ticketId, style: 'tableHeader'}
              ],
              [
                {
                  colSpan: 3,
                  layout: 'noBorders',
                  table: {
                    widths:['40%','60%'],
                    body: [
                      ['DIRECCION: ', 'MARCA: '+data.marcaMovil]
                    ]
                  }
                },
                ''
              ],
              [
                {
                  colSpan: 3,
                  layout: 'noBorders',
                  table: {
                    widths:['40%','60%'],
                    body: [
                      ['GERENCIA: ' , 'MODELO: '+data.modeloMovil]
                    ]
                  }
                },
                '',
                '',
                ''
              ],
              [
                {
                  colSpan: 3,
                  layout: 'noBorders',
                  table: {
                    widths:['40%','60%'],
                    body: [
                      ['SERVICIO: '+data.servicio, 'AÑO: '+data.anio]
                    ]
                  }
                },
                '',
                '',
                {rowSpan:2, text:"Interno: \n"+data.interno, alignment:'center'}
              ],
              [
                {
                  colSpan: 3,
                  layout: 'noBorders',
                  table: {
                    widths:['40%','60%'],
                    body: [
                      ['UNIDAD: '+data.unidad, 'CANT.COMBUSTIBLE ('+data.tipoCombustible+'):']
                    ]
                  }
                },
                '',
                '',
                ''
              ],
              [
                {
                  colSpan: 3,
                  layout: 'noBorders',
                  table: {
                    widths:['40%','60%'],
                    body: [
                      ['UBICACION: '+data.ubicacion, 'KILOMETRAJE: '+data.kilometraje]
                    ]
                  }
                },
                '',
                '',
                {rowSpan:2, text:"Dominio: \n"+data.dominio, alignment:'center'}
              ],
              [
                {colSpan:3, text:"Se solicita la revisión y/o reparación de: ", alignment:'left', border:[true, false, false, false]},
                ''
              ],
              [
                {
                  colSpan:4, border: [true, false, true, false],
                  layout: {
                    hLineWidth: function (i, node) {
                      return (i === 0 || i === node.table.body.length) ? 0 : 1;
                    },
                    vLineWidth: function (i, node) {
                      return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                    }
                  },
                  table : {
                    widths: ['100%'],
                    dontBreakRows: true,
                    body: data.listadoTareas
                  }
                }
              ]

            ] /* body */
          }
        },
        {
          table : {
            widths: ['13%','10%','14%','10%','10%','11%','10%','22%'],
            headerRows: 8,
				    dontBreakRows: true,
				    keepWithHeaderRows: 1,
            body: [
              [
                {border: [true,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: 'ABOLLADO', fontSize: 9},
                {border: [true,true,true,true], text: ' '},
                {rowSpan: 8, border: [true,true,true,false], alignment: 'left', padding: [0,0,0,0], text: data.FirmaResponsable, fontSize: 10}
              ],
              [
                {border: [true,false,false,false], text: 'RUEDA AUXILIO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'LLAVE DE RUEDA'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: 'RASPON', fontSize: 9},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'CRIQUE'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'BALIZA'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'MATAFUEGO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'BOTIQUIN'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'CUBRE ALFOMBRA', fontSize: 7},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TARJETA COMB.'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'ESTEREO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TELEPEAJE/S'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: ' '},
                {colSpan:2, border: [false,false,false,false], text: 'PRESIÓN NEUMÁTICOS', bold: true},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'DEL.DERECHO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TRAS.DERECHO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'DEL.IZQUIERDO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TRAS.IZQUIERDO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [true,false,true,true], alignment: 'center', text: 'R.U. \ó Adjunto'}
              ],
              [
                {border: [true,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [true,true,true,true], text: 'Fecha:         /         /'}
              ]
            ]
          },
        },
        {
          layout: {
            hLineWidth: function (i, node) {
              return (i === 0 ) ? 0 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 1 : 0;
            }
          },
          table: {
            widths : ['17%','10%','17%','10%','*'],
            dontBreakRows: true,
            body: [
              [
                {text: 'Fecha term.trabajo', border: [true,false,false,true]},
                {text: data.fechaTermTrabajo, border: [false,true,false,true]},
                {text: 'Kms. salida taller:', border: [false,true,false,true]},
                {text: data.kmSalidaTaller, border: [false,true,false,true]},
                {text: 'Combustible ing.taller:Líq.: GNC:', alignment:'left',  border: [false,true,true,true]}
              ],
              [
                {
                  colSpan:5,
                  layout: {
                      hLineWidth: function (i, node) {
                        return (i === 0 || i === 1 || i === node.table.body.length) ? 0 : 1;
                      },
                      vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 0 : 0;
                      }
                  },
                  table : {
                    widths: ['*'],
                    dontBreakRows: true,
                    body: data.listadoReparaciones
                  }
                }
              ]
            ]
          }
        },
        {
          table : {
            widths: ['13%','10%','14%','10%','10%','11%','10%','22%'],
            headerRows: 8,
				    dontBreakRows: true,
				    keepWithHeaderRows: 1,
            body: [
              [
                {border: [true,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: ' '},
                {border: [false,true,false,false], text: 'ABOLLADO', fontSize: 9},
                {border: [true,true,true,true], text: ' '},
                {rowSpan: 9, border: [true,true,true,false], alignment: 'left', text: data.taller, fontSize: 10},
              ],
              [
                {border: [true,false,false,false], text: 'RUEDA AUXILIO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'LLAVE DE RUEDA'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: 'RASPON', fontSize: 9},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'CRIQUE'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'BALIZA'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'MATAFUEGO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'BOTIQUIN'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'CUBRE ALFOMBRA', fontSize: 7},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TARJETA COMB.'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'ESTEREO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TELEPEAJE/S'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: ' '},
                {colSpan:2, border: [false,false,false,false], text: 'PRESIÓN NEUMÁTICOS', bold: true},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'DEL.DERECHO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TRAS.DERECHO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '}
              ],
              [
                {border: [true,false,false,false], text: 'DEL.IZQUIERDO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: 'TRAS.IZQUIERDO'},
                {border: [true,true,true,true], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ' '},
                {border: [false,false,false,false], text: ''}
              ],
              [
                {border: [true,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [false,false,false,true], text: ' '},
                {border: [true,true,true,true], text: 'Firma y Aclaración', alignment:'center'}
              ]
            ]
          },
        },
        {
          table: {
            widths: ['*'],
            heights: 15,
            body: [
              [
                'OBSERVACIONES:'
              ],
              [
                ''
              ],
              [
                ''
              ],
              [
                ''
              ],
              [
                ''
              ]
            ]
          },
          defaultStyle: {
            fontSize: 8
          }
        }
      ], /* content */
      styles: {
        tableHeader : {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        flat: {
          margin: [0,0,0,0],
          padding: [0,0,0,0]
        }
      },
      defaultStyle : {
        alignment: 'left',
        fontSize: 8
      }
    };

    pdfMake.createPdf(file).open();

  }

	render() {
    if(this.props.ticket) {
      let ticket = this.props.ticket;
      return (
        <React.Fragment>
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <h4 className="form-section">
                  <i className="la la-info-circle"></i><FormattedMessage id="ticketDatosGenerales.render.general_data.header_datos_generales" defaultMessage=" Datos Generales"/>
                </h4>
                <div className="float-right ticket-buttons" style={{display:  this.props.action === 'VIEW' ? 'none': ''}}>
                  {Security.hasPermission('TICKETS_VER_DETALLE_EAM') && ticket.ticketTipo !== 'GESTORIA' ? (
                  <button href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_ver_detalle_eam', defaultMessage: 'Ver Detalle EAM' })} onClick={this.handleVerDetalleEam.bind(this)}><i className="la la-info-circle align-middle"></i></button>
                  ) : ''}
                  {Security.hasPermission('TICKETS_MODIFICAR_FECHA_REALIZADO') && ticket.ticketTipo !== 'GESTORIA' ? (
                  <button href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_informar_fecha_de_realizado', defaultMessage: 'Informar Fecha de Realizado' })} onClick={this.handleFechaRealizadoOpen.bind(this)}><i className="la la-calendar-check-o align-middle"></i></button>
                  ) : ''}
                  {Security.hasPermission('TICKETS_MODIFICAR_FECHA_REALIZADO') && ticket.ticketTipo !== 'GESTORIA' && ticket.fechaHoraRealizado ? (
                  <button href="" className="btn btn-float-sm btn-danger btn-round ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_eliminar_fecha_de_realizado', defaultMessage: 'Eliminar Fecha de Realizado' })} onClick={this.handleFechaRealizadoDelete.bind(this)}><i className="la la-trash align-middle"></i></button>
                  ) : ''}
                  {Security.hasPermission('TICKETS_VER_HISTORIA') ? (
                  <button href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_ver_historia', defaultMessage: 'Ver Historia' })} onClick={this.handleVerHistorico.bind(this)}><i className="la la-history align-middle"></i></button>
                  ) : ''}
                  {Security.hasPermission('TICKETS_VER_OTROS_TICKETS') ? (
                  <button href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_ver_otros_tickets', defaultMessage: 'Ver Otros Tickets' })} onClick={this.handleVerOtrosTickets.bind(this)}><i className="la la-ticket"></i></button>
                  ) : ''}
                  {Security.hasPermission('TICKETS_VER_INFORMACION') ? (
                  <button href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_ver_informacion', defaultMessage: 'Ver Información' })} onClick={this.handleVerInformacion.bind(this)}><i className="la la-info-circle"></i></button>
                  ) : ''}
                  {Security.hasPermission('TICKETS_VER_TAREAS') && ticket.ticketTipo !== 'GESTORIA' ? (
                  <button href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_ver_tareas', defaultMessage: 'Ver Tareas' })} data-toggle="modal" data-target="#tareas_modal"><i className="la la-tasks"></i></button>
                  ) : ''}
                  {ConfigBusiness.get('tickets.imprimirTicket.habilitado') === 'true' && !ticket.persona ? (
                  <button href="" className="btn btn-float-sm btn-round btn-fleet ml-1" data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_imprimir', defaultMessage: 'Imprimir' })} onClick={this.handleImprimir.bind(this)}><i className="la la-print"></i></button>
                  ) : ''}
                  </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_base" defaultMessage="Base:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{(ticket.base ? ticket.base.descripcion : '')}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_centro_costos" defaultMessage="Centro Costos:"/></label>
                      <div className="col-md-8">
                      {Security.hasPermission('TICKETS_EDITAR_CENTRO_COSTOS') ? (
                           <div>
                             <Select
                               id="centroCostos"
                               name="centroCostos"
                               placeholder={!this.state.centroCostosLoading ? this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.placeholder_centro_costos', defaultMessage: 'Seleccione' }) : ''}
                               options={this.state.centroCostosSelect}
                               valueKey='value'
                               labelKey='label'
                               value={this.state.centroCostos ? this.state.centroCostos : null}
                               onChange={(e) => this.handleFormChangeSelectCentroCostos(e)}
                               isLoading={this.state.centroCostosLoading}
                               disabled={this.props.action === 'VIEW' || this.state.centroCostosLoading}
                             />
                           {/*  <div className="help-block text-danger field-message" hidden={validationState.formData.centroCostos.pristine || validationState.formData.centroCostos.valid}>{validationState.formData.centroCostos.message}</div>*/}
                           </div>
                      ) :  (<div className="form-control-static col-form-label form-value">{ticket.centroCostos ? ticket.centroCostos.nombre : ''}</div>)
                      }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_fecha_realizado" defaultMessage="Fecha Realizado:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{ticket.fechaHoraRealizado ? moment(ticket.fechaHoraRealizado).format('L') : ''}</div>
                      </div>
                    </div>
                  </div>
                </div>

                { ticket.ticketTipo === 'GESTORIA' ? (
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_oT" defaultMessage="OT:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{this.state.ot ? this.state.ot.id : ''}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_gestor" defaultMessage="Gestor:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{this.state.ot ? (this.state.ot.gestor ? this.state.ot.gestor.razonSocial : '' ): ''}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_escribano" defaultMessage="Escribano:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{this.state.ot ? (this.state.ot.escribano ? this.state.ot.escribano.razonSocial : '' ): ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null}

                <div className="row">
                <div className="col-md-8">
                <div className="row">
                  { ticket.ticketTipo !== 'GESTORIA' ? (
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_autogestion" defaultMessage="Autogestión:"/></label>
                      <div className="col-md-6 mt-auto">
                        <Switch
                          onChange={this.handleAutogestionChange}
                          checked={ticket.autogestion ? ticket.autogestion : false }
                          id="autogestion"
                          name="autogestion"
                          disabled={true}
                          offColor="#FF4961"
                          onColor="#28D094"
                        />
                      </div>
                    </div>
                  </div>
                  ) : null}
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_forzado" defaultMessage="Forzado:"/></label>
                      <div className="col-md-6 mt-auto">
                        <Switch
                          onChange={this.handleForzadoChange}
                          checked={ticket.forzado ? ticket.forzado : false }
                          id="forzado"
                          name="forzado"
                          disabled={true}
                          offColor="#FF4961"
                          onColor="#28D094"
                        />
                      </div>
                    </div>
                  </div>
                  { ticket.ticketTipo !== 'GESTORIA' ? (
                  <div className="col-md-4" style={{display: ConfigBusiness.get('mantenimientos.correctivo.abono.habilitado') === 'true' ? '' : 'none'}}>
                    <div className="form-group row">
                      <label className="col-md-6 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_abono" defaultMessage="Abono:"/></label>
                      <div className="col-md-6">
                        <Switch
                          onChange={this.handleAbonoChange}
                          checked={ticket.abono && !this.state.apagarAbono ? true : false }
                          id="abono"
                          name="abono"
                          disabled={true}
                          offColor="#FF4961"
                          onColor="#28D094"
                        />
                      </div>
                    </div>
                  </div>
                  ) : null}
                  </div>
                  </div>

                  <div className="col-md-4">
                  <div className="row">
                  <div className="col-md-12">
                  <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_folio" defaultMessage="Folio:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{ticket.idTicketCliente ? ticket.idTicketCliente : ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                { ticket.movil ? (
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      { ticket.movil.unidadMedidorId == ID__UM_ODOMETRO ? (
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_Km_actual" defaultMessage="Km. Actual:"/></label>
                      ) : ''}
                      { ticket.movil.unidadMedidorId == ID__UM_HOROMETRO ? (
                        <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_horometro_actual" defaultMessage="Horometro Actual:"/></label>
                      ): ''}
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{Math.floor(ticket.movil.kmActual)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                    { ticket.movil.unidadMedidorId == ID__UM_ODOMETRO ? (
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_km_generado" defaultMessage="Km. Generado:"/></label>
                    ) : ''}
                    { ticket.movil.unidadMedidorId == ID__UM_HOROMETRO ? (
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_horometro_generado" defaultMessage="Horometro Generado:"/></label>
                    ): ''}
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{Math.floor(ticket.kmGenerado)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                    { ticket.movil.unidadMedidorId == ID__UM_ODOMETRO ? (
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_Km_realizado" defaultMessage="Km. Realizado:"/></label>
                    ) : ''}
                    { ticket.movil.unidadMedidorId == ID__UM_HOROMETRO ? (
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_horometro_realizado" defaultMessage="Horometro Realizado:"/></label>
                     ): ''}
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{Math.floor(ticket.kmRealizado)}</div>
                      </div>
                    </div>
                  </div>
                </div>
                ) : null
                }

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group row ml-2">
                      <label className="col-md-1 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_detalle" defaultMessage="Detalle:"/></label>
                      <div className="col-md-11">
                        <div className="form-control-static col-form-label form-value">{ticket.detalle ? ticket.detalle : ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {ticket.movilCompaniaOrigen && 
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_movil_compania_origen" defaultMessage="Compañía Origen:"/></label>
                      <div className="col-md-8">
                        <div className="form-control-static col-form-label form-value">{ticket.movilCompaniaOrigen}</div>
                      </div>
                    </div>
                  </div>
                </div>
                }

                { ticket.ticketTipo !== 'GESTORIA' ? (
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-5" style={{paddingLeft:"50px"}}>
                      <div className="form-group row">
                        <label className="col-md-6 label-control col-form-label text-nowrap" htmlFor="siniestro">
                          <FormattedMessage id="ticketDatosGenerales.render.general_data.label_siniestro" defaultMessage="Siniestro:"/>
                        </label>
                        <div className="col-md-6">
                          <Switch
                            onChange={this.handleSiniestroChange}
                            checked={this.state.esSiniestro}
                            id="siniestro"
                            name="siniestro"
                            offColor="#FF4961"
                            onColor="#28D094"
                            disabled={this.props.action === 'VIEW' || !Security.hasPermission('TICKETS_SINIESTRO_MODIFICAR')}
                          />
                        </div>
                      </div>
                    </div>

                    {this.state.esSiniestro ? (
                    <div className="col-md-4 " style={{ paddingRight: "0px"}}>
                      <div className="form-group row">
                        <label className="col-md-2 label-control col-form-label" style={{ paddingLeft: "0px", paddingRight: "0px"}} htmlFor="numeroSiniestro">
                          <FormattedMessage id="ticketDatosGenerales.render.general_data.label_nro" defaultMessage="Nro.:"/>
                        </label>

                        {this.props.action === 'VIEW' ? (
                        <div className="col-md-10 ">
                          <div className="form-control-static col-form-label form-value">{ticket.numeroSiniestro ? ticket.numeroSiniestro : ''}</div>
                        </div>
                        ) : (
                        <div className="col-md-10" style={{display:'flex', paddingLeft:"5px", paddingRight:"10px"}}>
                          <input type="text" className="form-control col-md-11" id="numeroSiniestro" name="numeroSiniestro" placeholder={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.placeholder_nro', defaultMessage: 'Nro. de Siniestro' })} value={this.state.numeroSiniestro ? this.state.numeroSiniestro : ''} onChange={this.handleSiniestroFormChange}></input>
                        </div>
                        )}
                      </div>
                    </div>
                    ): null}
                    {this.props.action !== 'VIEW' && Security.hasPermission('TICKETS_SINIESTRO_MODIFICAR') && (this.state.cambioEstadoSiniestro || this.state.esSiniestro) ?
                    <div className="col-md-2" style={{paddingLeft: "0px"}}>
                      <button id="enviarSiniestro" className='btn btn-float-sm btn-round btn-fleet ml-1' disabled={!this.state.cambioEstadoSiniestro} data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_enviar', defaultMessage: 'Enviar' })} onClick={this.handleEnviar}>
                        <i className={this.state.icono + " align-middle"}></i>
                      </button>
                    </div> : ''}
                  </div>
                </div>

                ) : null }
                 { ticket.ticketTipo !== 'GESTORIA' && this.state.inmovilizadoEnabled ? (
                  <div className="col-md-4">
                    <div className="form-group row">
                      <label className="col-md-4 label-control col-form-label"><FormattedMessage id="ticketDatosGenerales.render.general_data.label_inmovilizado" defaultMessage="Inmovilizado:"/></label>
                      <div className="col-md-4 form-control-static col-form-label form-value">{this.state.inmovilizado && this.state.inmovilizado === true ? this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.inmovilizado_si', defaultMessage: 'SI' }) : this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.inmovilizado_no', defaultMessage: 'NO' })}</div>
                    </div>
                  </div>
                  ) : null}
              </div>
              {/* reemplazo de vehiculo */}
              {(ticket.ticketTipo == 'CORRECTIVO' || ticket.ticketTipo == 'PREVENTIVO') && ConfigBusiness.get("tickets.reemplazoVehiculo.habilitado") === "true" ? (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group row">
                        <label className="col-md-8 label-control col-form-label text-nowrap" htmlFor="reemplazoVehiculo">
                          <FormattedMessage id="ticketDatosGenerales.render.general_data.reemplazo_de_vehiculo" defaultMessage="Reemplazo de Vehículo:"/>
                        </label>
                        <div className="col-md-4">
                          <Switch
                            checked={this.state.esReemplazoDeVehiculo}
                            onChange={this.handleReemplazoVehiculoChange}
                            id="reemplazoVehiculo"
                            name="reemplazoVehiculo"
                            offColor="#FF4961"
                            onColor="#28D094"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      {this.props.action !== 'VIEW' && (this.state.cambioEstadoReemplazo || this.state.esReemplazoDeVehiculo) ?
                      <div>
                        <button id="enviarReemplazo" className='btn btn-float-sm btn-round btn-fleet ml-1' disabled={!this.state.cambioEstadoReemplazo} data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_enviar_cambio_reemplazo', defaultMessage: 'Enviar Cambio de reemplazo' })} onClick={this.handleEnviarReemplazo}>
                          <i className={this.state.iconoReemplazo + " align-middle"}></i>
                        </button>
                      </div> : ''}
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        {this.state.esReemplazoDeVehiculo ? (
                        <div className="col-md-3" style={{ paddingRight: "0px"}}>
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" style={{ paddingLeft: "0px", paddingRight: "0px"}} htmlFor="fechaEntrega">
                              <FormattedMessage id="ticketDatosGenerales.render.general_data.label_fecha_entrega" defaultMessage="Fecha de entrega:"/>
                            </label>
                            <div className="col-md-6">
																	<DatePicker
																	id="fechaEntrega"
																	name="fechaEntrega"
																	className="form-control date-picker-placeholder"																	
                                  placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_de_alta', defaultMessage: 'DD/MM/AAAA' })}																	
                                  selected={this.state.fechaEntrega}																	
                                  onChange={(event) => this.handleDatePickerFormChange("fechaEntrega", event)}
																	onChangeRaw={(event) => this.handleDatePickerFormRawChange("fechaEntrega", event.target.value)}
																	/>
																</div>
                          </div>
                        </div>
                      ): null}
                      {this.state.esReemplazoDeVehiculo ? (
                        <div className="col-md-3" style={{ paddingRight: "0px"}}>
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" style={{ paddingLeft: "0px", paddingRight: "0px"}} htmlFor="placaReemplazo">
                              <FormattedMessage id="ticketDatosGenerales.render.general_data.label_placa_reemplazo" defaultMessage="Placa de Reemplazo:"/>
                            </label>
                            <div className="col-md-6 ">
                              <input type="text" className="form-control" id="placaReemplazo" name="placaReemplazo" placeholder={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.placa_reemplazo', defaultMessage: 'Placa de reemplazo' })} value={this.state.placaReemplazo ? this.state.placaReemplazo : ''} onChange={this.handleReemplazoVehiculoFormChange}></input>
                            </div>
                          </div>
                        </div>
                          ): null}
                      </div>
                    </div>
                  </div>
                </div>
                ) : null }
              {(ticket.ticketTipo == 'CORRECTIVO' || ticket.ticketTipo == 'PREVENTIVO') && ConfigBusiness.get("tickets.OTAsociada.habilitado") === "true" ? (
                <div className="col-md-4" style={{ paddingRight: "0px"}}>
                  <div className="form-group row">
                    <label className="col-md-4 label-control col-form-label" style={{ paddingLeft: "0px", paddingRight: "0px"}} htmlFor="otAsociada">
                      <FormattedMessage id="ticketDatosGenerales.render.general_data.label_ot_sociada" defaultMessage="OT. Asociada:"/>
                    </label>
                    <div className="col-md-6">
                      <input type="text" className="form-control" id="otAsociada" name="otAsociada" placeholder={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.otAsociada', defaultMessage: 'OT. Asociada' })} value={this.state.otAsociada ? this.state.otAsociada : ''} onChange={this.handleOTAsociadaFormChange} maxLength="20"></input>
                    </div>
                    <div className="col-md-2">
                      <button id="enviarReemplazo" className='btn btn-float-sm btn-round btn-fleet ml-1' data-tooltip="tooltip" data-placement="top" title="" data-original-title={this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.general_data.button_title_enviar_OT_asociada', defaultMessage: 'Enviar Cambio de OT. Asociado' })} onClick={this.handleEnviarOTAsociada}>
                        <i className={this.state.iconoOTAsociada + " align-middle"}></i>
                      </button>
                    </div>
                  </div>
                </div>
                ) 
                : null
              }
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="modal_detalle_eam" role="dialog" aria-labelledby="myModalLabel6">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel6"><i className="la la-info-circle align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.modal_detalle_eam.header_detalle_eam', defaultMessage: ' Detalle del Ticket en EAM: ' }) + this.ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  {this.state.modalDetalleEam ? <EamDetalle key={this.state.modalDetalleEam} ticketId={this.ticket.id}></EamDetalle>: ''}
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="ticketDatosGenerales.render.historico_modal.finish_button.ok" defaultMessage="Ok"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="historico_modal" role="dialog" aria-labelledby="myModalLabel1">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel1"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.historico_modal.header_historico_del_ticket', defaultMessage: ' Histórico del Ticket: ' }) + this.ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  {this.state.modalHistorico ? <TicketHistorico key={this.state.modalHistorico} ticket={ticket}></TicketHistorico>: ''}
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportExcelHistorico.bind(this)}><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-download'}></i><FormattedMessage id="ticketDatosGenerales.render.historico_modal.export_button" defaultMessage=" Exportar a Excel"/></button>
                  <button type="button" className="btn btn-default btn-fleet" onClick={this.handleExportHistoricoCancel}><FormattedMessage id="ticketDatosGenerales.render.historico_modal.finish_button.ok" defaultMessage=" Ok"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="otros_tickets_modal" role="dialog" aria-labelledby="myModalLabel2">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel2"><i className="la la-ticket align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.otros_tickets_modal.header_tickets_asociados_a', defaultMessage: 'Tickets asociados a: ' }) + this.ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <div className="container-fluid">
                    {this.state.modalVerOtrosTickets ? <OtrosTickets ticket={ticket} key={this.state.modalVerOtrosTickets} callback={this.handleCallbackOtrosTickets.bind(this)}></OtrosTickets> : ''}
                  </div>
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="ticketDatosGenerales.render.otros_tickets_modal.finish_button.ok" defaultMessage="Ok"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="informacion_modal" role="dialog" aria-labelledby="myModalLabel3">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel3"><i className="la la-info-circle align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.informacion_modal.header_informacion_asociada_al_ticket', defaultMessage: 'Información asociada al Ticket: ' }) + this.ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
										{this.state.modalInformacion ? this.entidadABM() : ''}
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="ticketDatosGenerales.render.informacion_modal.finish_button.ok" defaultMessage="Ok"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="tareas_modal" role="dialog" aria-labelledby="myModalLabel4">
            <div className="modal-lg modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel4"><i className="la la-tasks align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.tareas_modal.header_tareas_del_ticket', defaultMessage: 'Tareas del Ticket: ' }) + this.ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <div className="container-fluid">
                    {<TicketTareas ticket={this.ticket}></TicketTareas>}
                  </div>
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="ticketDatosGenerales.render.tareas_modal.finish_button.ok" defaultMessage="Ok"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="ver_ticket_alternativo_en_modal" role="dialog" aria-labelledby="myModalLabel5">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel5"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.ver_ticket_alternativo_en_modal.header_ticket', defaultMessage: 'Ticket: ' }) + this.ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <br/>
                { (this.state.ticketPropsMatch !== null && this.state.modalVerTicketAlternativo) ? <TicketsAbm key={this.state.modalVerTicketAlternativo} action="VIEW" match={this.state.ticketPropsMatch}></TicketsAbm> : ''}
                </div>
                <div className="modal-footer modal-grey">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="ticketDatosGenerales.render.ver_ticket_alternativo_en_modal.finish_button.ok" defaultMessage="Ok"/></button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="fecha_realizado_modal" role="dialog" aria-labelledby="myModalFechaRealiado">
            <div className="modal-lg modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalFechaRealiado"><i className="la la-history align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ticketDatosGenerales.render.fecha_realizado_modal.header_ticket', defaultMessage: ' Ticket: ' }) + ticket.id}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                  <TicketInformarFechaRealizado key={this.state.modalFechaRealizadoKey} ticket={ticket} callbackClose={this.handleFechaRealizadoClose.bind(this)} callbackSave={this.handleFechaRealizadoSave.bind(this)} ></TicketInformarFechaRealizado>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TicketDatosGenerales);
