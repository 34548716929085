import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect } from "react-router-dom";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import Security from "../../commons/security/Security.js";
import Loading from "../ui/Loading.js";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import $ from "jquery";
import "moment/min/locales";
import "xlsx/dist/xlsx.full.min.js";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from '../../lang/backendStrings.js';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Collapse from "@material-ui/core/Collapse";
import LlantasDetailNew from "./LlantasDetail";
import Fab from "./FAB/Fab.js";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import LimpiarFiltros from "../../assets/images/limpiar-filtros.svg";
import Select from "react-select";
import Swal from "sweetalert2";
import LlantaCambio from "./LlantaCambio";
import LlantaRecapado from "./LlantaRecapado";
import withReactContent from "sweetalert2-react-content";
import LlantaOdometroGrid from "./LlantaOdometroGrid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import Switch from "react-switch";
import moment from "moment";
import BasicCardMultipleValuesInfo from "../ui/BasicCardMultipleValuesInfo.js";
import LlantaReparar from "./LlantaRepararModal";
import Input from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import LlantaBaja from "./LlantaBaja";
import LlantaDesasignar from "./LlantaDesasignar";
import Sort from "../../assets/images/llantas/table/sort.svg";
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import LlantaReactivarModal from "./LlantaReactivarModal";
import Timezone from '../../commons/timezone/Timezone.js';
import * as Utils from '../../commons/utils/Utils';
import * as ConstantsMedidor from '../unidadMedidor/constants.js';
import LlantasImportar from "./LlantasImportar";
import swalImport from 'sweetalert'
import LlantasImportarReporte  from "./LlantasImportarReporte";

function Row(props) {
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const { llanta } = props;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showModalCambioLlanta, setShowModalCambioLlanta] = useState(false);
  const [showModalRecapado, setShowModalRecapado] = useState(false);
  const [showModalBaja, setShowModalBaja] = useState(false);
  const [ultima_medicionDiasAlerta, setultima_medicionDiasAlerta] = useState("");
  const [diferenciaEntreDosMediciones, setDiferenciaEntreDosMediciones] = useState("");
  const [isHidden, setIsHidden] = useState("hidden-personal");
  const [showModalDesasignar, setShowModalDesasignar] = useState(false);

  useLayoutEffect(() => {
    $(".llanta-desgaste").tooltip();
    $(".action-btn-llantas").tooltip();
    $(".llanta-estado-div").tooltip();
    return () => {
      $(".llanta-desgaste").tooltip("dispose");
      $(".action-btn-llantas").tooltip("dispose");
      $(".llanta-estado-div").tooltip("dispose");
    };
  }, [llanta]);

  useEffect(() => {
    Promise.all([
      ConfigBusiness.get("llantas.ultima_medicionDiasAlerta"),
      ConfigBusiness.get("llantas.diferenciaEntreDosMediciones"),
    ]).then((data) => {
      setultima_medicionDiasAlerta(data[0]);
      setDiferenciaEntreDosMediciones(data[1]);
    });
  }, []);

  useEffect(() => {
    if (props.llantaRecaparId && (parseFloat(props.llantaRecaparId) === llanta.id)) {
      setShowModalRecapado(true)
    }
  }, [props.llantaRecaparId]);

  const getData = (service) => {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  };

  const MySwal = withReactContent(Swal);

  const closeModalDesasignar = (event) => {
    setShowModalDesasignar(false);
  };

  const handleSubmitDesasignar = (event) => {
    props.dataTableUpdate(false);
    setShowModalDesasignar(false);
    MySwal.fire(
      props.intl.formatMessage({
        id: "llantasGrid.modal_desasignar.information_llanta_desasignada",
        defaultMessage: "Llanta desasignada",
      }),
      "",
      "success"
    );
  };

  const handleSubmitBaja = () => {
    setShowModalBaja(false);
    props.dataTableUpdate(false);
    MySwal.fire(
      props.intl.formatMessage({
        id: "llantasGrid.modal_baja.information_llanta_baja",
        defaultMessage: "Llanta se dio de baja",
      }),
      "",
      "success"
    );
  };

  function eventCambioLlanta(event) {
    if (llanta.movil) setShowModalCambioLlanta(true);
    else
      MySwal.fire(
        props.intl.formatMessage({
          id: "llantasGrid.modal_cambio_llanta.information_llanta_no_tiene_movil",
          defaultMessage: "La llanta no tiene un móvil asignado",
        }),
        "",
        "error"
      );
  }

  function eventReactivar(id) {
    MySwal.fire({
      title: 'Reactivacion de Llanta',
      html: <LlantaReactivarModal
        id={id}
        success={(message) => {
          MySwal.clickConfirm()
          props.dataTableUpdate(false)
          Swal.fire(message)
        }}
        failure={(message) => {
          Swal.fire(message)
        }}
        dismiss={() => MySwal.clickCancel()}
      />,
      showConfirmButton: false
    })
  }

  function handleModalCloseCambioLlanta() {
    setShowModalCambioLlanta(false);
  }

  function closeModalRecapado() {
    setShowModalRecapado(false);
  }

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleSubmitCambioLlanta = () => {
    props.dataTableUpdate(false);
    setShowModalCambioLlanta(false);
    MySwal.fire(
      props.intl.formatMessage({
        id: "llantasGrid.modal_cambio_llanta.information_cambio_exitoso",
        defaultMessage: "Cambio exitoso",
      }),
      "",
      "success"
    );
  };

  const closeModalForzarTicketRecapado = () => {
    setShowModalRecapado(false);
  };

  const handleSubmitForzarTicketRecapado = (hasTicket) => {
    setShowModalRecapado(false);
    props.dataTableUpdate(false);
    if (hasTicket) {
      MySwal.fire(
        props.intl.formatMessage({
          id: "llantaRecapado.modal_recapado.information_recapado_exitoso",
          defaultMessage: "Recapado exitoso",
        }),
        "",
        "success"
      );
      props.setRedirectTo('/llantas');
    } else {
      MySwal.fire(
        props.intl.formatMessage({
          id: "llantaRecapado.modal_recapado.information_enviado_a_taller",
          defaultMessage: "Enviado a taller",
        }),
        "",
        "success"
      );
    }
  };

  const useStylesCambioLlanta = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "15px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "0px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: "15px",
    },
  }));

  const classesCambioLlanta = useStylesCambioLlanta();

  const openCollapse = () => {
    setOpen(!open);
    if (isHidden === "hidden-personal") {
      setIsHidden("block");
    } else {
      setIsHidden("hidden-personal");
    }
  };

  const ultimaInspeccion = (llanta) => {
    if (llanta.ultima_medicion) {
      return Timezone.getDateForClient(llanta.ultima_medicion.created_at, null, 'YYYY-MM-DD HH:mm:ss');
    } else return "";
  };

  const getLlantaEstado = (llanta) => {
    if (llanta.activo === 1) {
      return "status-green";
    } else {
      return "status-red";
    }
  };

  const getLlantaEstadoSerial = (llanta) => {
    if (llanta.activo === 1) {
      return "success";
    } else {
      return "danger";
    }
  };

  const marcaLogo = () => {
    if (llanta.llanta_marca) {
      if (llanta.llanta_marca.logo) {
        return (
          <img
            key={"logo_" + llanta.id}
            className="dt-user-avatar-image"
            src={process.env.PUBLIC_URL + "/images/llantas/marcas-logo/" + llanta.llanta_marca.logo}
          />
        );
      } else {
        return (
          <div key={"logo_" + llanta.id} className="dt-user-avatar">
            {llanta.llanta_marca.nombre.substr(0, 2).toUpperCase()}
          </div>
        );
      }
    }
    const defaultName = "NN"
    return (<div key={"logo_" + llanta.id} className="dt-user-avatar">
      {defaultName.substr(0, 2).toUpperCase()}
    </div>)
  };

  const titleIconoHistorial = (llanta) => {
    if (llanta.unidad_medidor_id === ConstantsMedidor.ID__UM_ODOMETRO) {
      return props.intl.formatMessage({
        id: "llantasGrid.title_icono_historial_odometro",
        defaultMessage: "Historial Odómetro",
      })
    } else if (llanta.unidad_medidor_id === ConstantsMedidor.ID__UM_HOROMETRO){
      return props.intl.formatMessage({
      id: "llantasGrid.title_icono_historial_horometro",
        defaultMessage: "Historial Horómetro",
      })
    }
  };

  return (
    <React.Fragment>
      <tr key={llanta.id}>
        <Modal
          open={showModalCambioLlanta}
          onClose={handleModalCloseCambioLlanta}
          className={classesCambioLlanta.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModalCambioLlanta}>
            <div className={classesCambioLlanta.paper}>
              <LlantaCambio
                intl={props.intl}
                llanta={llanta}
                handleModalCloseCambioLlanta={handleModalCloseCambioLlanta}
                handleSubmitCambioLlanta={handleSubmitCambioLlanta}
              />
            </div>
          </Fade>
        </Modal>
        <Modal
          open={showModalDesasignar}
          onClose={closeModalDesasignar}
          className={classesCambioLlanta.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModalDesasignar}>
            <div className={classesCambioLlanta.paper}>
              <LlantaDesasignar
                intl={props.intl}
                llanta={llanta}
                closeModalDesasignar={closeModalDesasignar}
                handleSubmitDesasignar={handleSubmitDesasignar}
              />
            </div>
          </Fade>
        </Modal>
        <Modal
          open={showModalRecapado}
          onClose={closeModalRecapado}
          className={classesCambioLlanta.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModalRecapado}>
            <div className={classesCambioLlanta.paper}>
              <LlantaRecapado
                intl={props.intl}
                llanta={llanta}
                closeModalForzarTicketRecapado={closeModalForzarTicketRecapado}
                handleSubmitForzarTicketRecapado={handleSubmitForzarTicketRecapado}
              />
            </div>
          </Fade>
        </Modal>
        <Modal
          open={showModalBaja}
          onClose={() => setShowModalBaja(false)}
          className={classesCambioLlanta.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showModalBaja}>
            <div className={classesCambioLlanta.paper}>
              <LlantaBaja
                intl={props.intl}
                llanta={llanta}
                movil={llanta.movil ? llanta.movil : null}
                close={() => setShowModalBaja(false)}
                handleSubmit={handleSubmitBaja}
              />
            </div>
          </Fade>
        </Modal>
        <td component="th" scope="row" align="center" size="small" className="custom-lineHeight">
          <div className="d-flex items-center">
            <div
              className="personal-no-focus avatar-plus-detail d-flex items-center"
              aria-label="expand row"
              size="small"
              onClick={() => openCollapse()}
            >
              {open ? (
                <i className="fa fa-2x fa-angle-double-down personal-no-focus avatar-plus-detail"></i>
              ) : (
                <i className="fa fa-angle-double-right fa-2x personal-no-focus avatar-plus-detail"></i>
              )}
            </div>
            {marcaLogo()}
          </div>
        </td>
        <td
          className={getLlantaEstado(llanta) + " all custom-lineHeight pl-5 llanta-estado-div"}
          data-toggle="tooltip"
          data-placement="right"
          title={llanta.estado}
          style={{ maxWidth: "3px" }}
        ></td>
        <td className="all custom-lineHeight">
          <div style={{ display: "flex;" }}>
            {llanta.serial}
            <i
              id="dominio"
              className={getLlantaEstadoSerial(llanta) + " fas fa-circle datatablesnew-status-icon-fa-fas-circle"}
            ></i>
          </div>
        </td>
        <td style={{ maxWidth: "5px" }} align="center" className="custom-lineHeight">
          <Fab
            intl={props.intl}
            id={llanta.id}
            forceRecapado={() => setShowModalRecapado(true)}
            addTicket={() => props.redirectToCorrectivo(llanta)}
            eventDelete={() => setShowModalBaja(true)}
            eventDesasignar={() => setShowModalDesasignar(true)}
            eventCambioLlanta={() => eventCambioLlanta(llanta.id)}
            eventReactivar={() => eventReactivar(llanta.id)}
            llantaId={llanta.id}
            llanta={llanta}
          />
        </td>
        <td className="all custom-lineHeight text-center">{llanta.llanta_modelo ? llanta.llanta_modelo.nombre : "-"}</td>

        <td className="all custom-lineHeight text-center">{llanta.llanta_modelo ? llanta.llanta_modelo.llanta_medida.nombre : "-"}</td>
        <td className="all custom-lineHeight text-center">
          {llanta.recapadas ? (
            <div>
              <p>{"R" + llanta.recapadas}</p>
              <p>{llanta.banda}</p>
            </div>
          ) : (
            "0"
          )}
        </td>
        <td className="all custom-lineHeight text-center">
          {llanta.ultima_medicion ? (
            <div className="text-center">
              {"PSI: " + llanta.ultima_medicion.psi}
              {llanta.ultima_medicion.valor_medidos.map((valor, indexValorMedido) =>
                valor.punto_dinamico ? (
                  <div
                    key={indexValorMedido}
                    className={
                      parseFloat(valor.value) <= parseFloat(valor.punto_dinamico.mmDanger)
                        ? "text-danger"
                        : parseFloat(valor.value) <= parseFloat(valor.punto_dinamico.mmWarning)
                          ? "text-warning-personal"
                          : "text-success-personal"
                    }
                  >
                    {valor.punto_dinamico.nombre + ": " + valor.value}
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
          ) : null}
        </td>
        <td className="custom-lineHeight">
          <div>
            <div className="">{llanta.desgasteCada1000km != null ? llanta.desgasteCada1000km.toFixed(2) : ""} mm</div>
            {llanta.modelo && llanta.modelo.recapado !== 0 ? (
              <div>
                <img
                  title={
                    llanta.restantesParaCambioORecapado
                      ? llanta.restantesParaCambioORecapado.value === null
                        ? props.intl.formatMessage({
                          id: "llantasGrid.column_desgaste_cada_mil.km_restante_odometro_cero.no_es_posible_el_calculo",
                          defaultMessage: "No es posible el calculo, faltan datos",
                        })
                        : props.intl.formatMessage({
                          id: "llantasGrid.column_desgaste_cada_mil.km_restante.en_aprox",
                          defaultMessage: "En aprox. ",
                        }) +
                        " " +
                        llanta.restantesParaCambioORecapado.value.toFixed(0) +
                        " " +
                        llanta.restantesParaCambioORecapado.label
                      : ""
                  }
                  src={process.env.PUBLIC_URL + "/images/llantas/road.svg"}
                  className="llanta-desgaste"
                ></img>
                <img
                  title={
                    llanta.tiempoRestantesParaCambioORecapado
                      ? llanta.tiempoRestantesParaCambioORecapado.value !== null
                        ? props.intl.formatMessage({
                          id: "llantasGrid.column_desgaste_cada_mil.tiempo_restante.en_aprox",
                          defaultMessage: "En aprox.",
                        }) +
                        " " +
                        llanta.tiempoRestantesParaCambioORecapado.value.toFixed(0) +
                        " " +
                        props.intl.formatMessage({
                          id: "llantasGrid.column_desgaste_cada_mil.tiempo_restante.dias",
                          defaultMessage: "días",
                        })
                        : props.intl.formatMessage({
                          id: "llantasGrid.column_desgaste_cada_mil.tiempo_restante.desgaste_por_dia_no_es_posible_calculo",
                          defaultMessage: "No es posible el calculo, faltan datos",
                        })
                      : ""
                  }
                  src={process.env.PUBLIC_URL + "/images/llantas/calendar.svg"}
                  className="llanta-desgaste"
                  data-placement="top"
                  data-toggle="tooltip"
                ></img>
              </div>
            ) : (
              ""
            )}
          </div>
        </td>
        <td className="all custom-lineHeight">
          {llanta.costo ? llanta.costo.value.toFixed(2) + " " + llanta.costo.label : ""}
        </td>
        <td align="center" className="all custom-lineHeight text-center">
          {llanta.desgaste} mm
        </td>
        <td align="center" className="custom-lineHeight">
          <div>
            <div className="all text-center">
              {llanta.odometro < 0 ? 0 : llanta.odometro} {llanta.odometroLabel ? llanta.odometroLabel : ""}
            </div>
            {llanta.recapadas > 0 ? (
              <div>
                R: {llanta.odometro_recapado} {llanta.odometroLabel ? llanta.odometroLabel : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </td>
        <td align="center" className="custom-lineHeight">
          <div className="all">
            {llanta.movil ? (
              <div>
                <p>{llanta.movil.dominio}</p>
                {llanta.posicion ? (
                  <p>
                    {props.intl.formatMessage({
                      id: "llantasGrid.cplumn_posicion.label_pos",
                      defaultMessage: "Pos",
                    })}
                    : {llanta.posicion}
                  </p>
                ) : (
                  <p>Auxilio</p>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </td>
        <td align="center" className="custom-lineHeight">
          {llanta.base.descripcion}
        </td>
        <td align="center" className="custom-lineHeight">
          {ultimaInspeccion(llanta)}
        </td>
        <td align="center" className="custom-lineHeight">
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {Security.renderIfHasPermission("LLANTAS_VISUALIZAR",
              <button
                className="action-btn-llantas btn btn-sm btn-icon btn-dt-grid text-success"
                data-togle="tooltip"
                data-placement="top"
                title="Ver"
                onClick={() => props.redirectToView(llanta.id)}
              >
                <i className="fa fa-search fa-xs"></i>
              </button>)}
            {Security.renderIfHasPermission("LLANTAS_MODIFICAR",
              <button
                className="action-btn-llantas btn btn-sm btn-icon btn-dt-grid text-success"
                data-togle="tooltip"
                data-placement="top"
                title="Editar"
                onClick={() => props.redirectToEdit(llanta.id)}
              >
                <i className="fa fa-pencil fa-xs"></i>
              </button>)}
            {Security.renderIfHasPermission("LLANTAS_VISUALIZAR_ODOMETRO",
              <button
                className="action-btn-llantas action correctivo btn btn-sm btn-icon btn-dt-grid text-success"
                data-togle="tooltip"
                data-placement="top"
                title={titleIconoHistorial(llanta)}
                onClick={handleOpen}
              >
                <img width="27" height="27" src={require("../../assets/images/llantas/vec-odometro.svg")} />
              </button>)}
          </div>
        </td>
      </tr>
      <TableRow className={isHidden}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <LlantasDetailNew
              llanta={llanta}
              ultima_medicionDiasAlerta={ultima_medicionDiasAlerta}
              diferenciaEntreDosMediciones={diferenciaEntreDosMediciones}
            />
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className="w-80 d-flex justify-content-center" style={{ maxHeight: "750px" }}>
            <LlantaOdometroGrid intl={props.intl} llanta={llanta} handleClose={() => handleClose()} />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
const useStylesPersonal = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
    },
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {},
}));

const LlantasGrid = (props) => {
  const classes = useStylesPersonal();
  const intl = props.intl;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [ajaxHandler, setAjaxHandler] = useState(new AjaxHandler());
  const [dataService, setDataService] = useState("/llantas");
  const [llantas, setLlantas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [pais, setPais] = useState([])
  const [paises, setPaises] = useState([])
  const [seriales, setSeriales] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    count: 2,
    perPage: 10,
  });
  const [query, setQuery] = useState({
    page: 0,
    activo: 1,
    serial: "",
    serialSort: "",
    baseSort: "",
    marca: "",
    pais: "",
    marcaSort: "",
    modelo: "",
    modeloSort: "",
    region:"",
    subRegion:"",
    movil: "",
    movilSort: "",
    clase: "",
    estado: "",
    odometroSort: "",
    desgasteSort: "",
    filtroKpi: "",
    base: "",
    baseSort: "",
  });
  const [showInactivos, setShowInactivos] = useState(false);
  const [showPersistirFiltros, setShowPersistirFiltros] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = React.useState(false);
  const [summaryAlerts, setSummaryAlerts] = useState([]);
  const [estados, setEstados] = useState([]);
  const [estadosActivos, setEstadosActivos] = useState([]);
  const [estadosInactivos, setEstadosInactivos] = useState([]);
  const [estadosResaltados, setEstadosResaltados] = useState([]);
  const [marca, setMarca] = useState([]);
  const [modelo, setModelo] = useState([]);
  const [estado, setEstado] = useState([]);
  const [clases, setClases] = useState([]);
  const [clase, setClase] = useState("");
  const [serial, setSerial] = useState("");
  const [kpis, setKpis] = useState([]);
  const [base, setBase] = useState([]);
  const [bases, setBases] = useState([]);
  const [moviles, setMoviles] = useState([]);
  const [movilLoading, setMovilLoading] = useState(true);
  const [movil, setMovil] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [region, setRegion] = useState("");
  const [subRegiones, setSubRegiones] = useState([]);
  const [subRegion, setSubRegion] = useState("");
  const [filtersActive, setFiltersActive] = useState(null);
  const [selectFilter, setSelectFilter] = useState(null);
  const [llantaReemplazoSwitch, setLlantaReemplazoSwitch] = useState(false);
  const [showModalParaRecapar, setShowModalParaRecapar] = useState(false);
  const [filtrosTotales, setFiltrosTotales] = useState(null)

  const [filterLoading, setFilterLoading] = useState({
    marcas: false,
    modelos: false,
    paises: false,
  });
  const [dataToExport, setDataToExport] = useState({
    marca: true,
    serial: true,
    modelo: true,
    medida: true,
    pais: true,
    recapados: true,
    mediciones: true,
    subregion: true,
    region: true,
    desgasteCada1000km: true,
    costo: true,
    desgaste: true,
    odometro: true,
    asignacion: true,
    posicion: true,
    ultInspeccion: true,
    base: true
  });
  const [reporteImportacion, setReporteImportacion] = useState(null);
  const [mensajeImportacion, setMensajeImportacion] = useState("");

  useEffect(() => {
    if (Security.hasPermission("LLANTAS_LISTAR")) {
      ajaxHandler.subscribe(this);
      initGrid();
    } else {
      setRedirectTo("/error");
    }
    //componentWillUnmount
    return () => { };
  }, []);

  useEffect(() => {
    if (showPersistirFiltros) {
      let filtersStorage = window.localStorage.getItem('llantasGrid_filter') ? JSON.parse(window.localStorage.getItem('llantasGrid_filter')) : {};
      filtersStorage.activo = true
      window.localStorage.setItem('llantasGrid_filter', JSON.stringify(filtersStorage))
    }
  }, [showPersistirFiltros])

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  const initGrid = () => {
    setLoading(true);
    window.scrollTo(0, 0);

    Promise.all([
      getData("/llantamarcas"),
      getData("/llantamodelos"),
      ConfigBusiness.get("llantas.estadoBaja"),
      ConfigBusiness.get("llantas.estadosActivos"),
      ConfigBusiness.get("llantas.estadosInactivos"),
      ConfigBusiness.get("moviles.estadosResaltados"),
      Utils.getData('/llantas/kpis_card'),
      Utils.getData('/paises'),
      Utils.getData('/moviles/select'),
      Utils.getData('/llantas' + dataTableQuery()),
      ConfigBusiness.get("llantas.estadoAsignada"),
      ConfigBusiness.get("llantas.estadoEnTaller"),
      ConfigBusiness.get("llantas.estadoEnRecapado"),
      ConfigBusiness.get("llantas.estadoAlmacen"),
      Utils.getData('/bases/select-filtered-by-user/base/'),
      Utils.getData('/regiones/select'),
      Utils.getData('/subregiones/select-all'),
    ]).then((data) => {
      setMarcas(data[0]);
      setModelos(data[1]);
      let estadoBaja = data[2] ? data[2].split(",") : null;
      let estadosActivos = data[3] ? data[3].split(",") : null;
      let estadosInactivos = data[4] ? data[4].split(",") : null;
      let estadosResaltados = data[5] ? data[5].split(",") : null;
      setSummaryAlerts(data[6]);
      setEstadosActivos(estadosActivos);
      setEstadosInactivos(estadosInactivos);
      setEstadosResaltados(estadosResaltados);
      setPaises(data[7])
      setMoviles(data[8]);
      setLlantas(data[9].llantas);
      let pagination = data[9].pagination;
      setPagination(pagination);
      let seriales = [];
      for (let i = 0; i < llantas.length; i++) {
        let serial = {};
        serial.id = llantas[i].id;
        serial.value = llantas[i].serial;
        seriales.push(serial);
      }
      setSeriales(seriales);
      if (props.match.params.id) {
        getData("/llantas/" + props.match.params.id)
          .then((res) => {
            let newLlantas = [];
            newLlantas.push(res);
            setLlantas(newLlantas);
            setShowModalParaRecapar(true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // ESTADOS
      let estadoAsignada = data[10] ? data[10].split(",") : null;
      let estadoEnTaller = data[11] ? data[11].split(",") : null;
      let estadoEnRecapado = data[12] ? data[12].split(",") : null;
      let estadoAlmacen = data[13] ? data[13].split(",") : null;
      let estados = [
        ...estadoAsignada,
        ...estadoEnTaller,
        ...estadoEnRecapado,
        ...estadoAlmacen,
        ...estadoBaja,
      ];
      let menuEstado = [];
      estados.forEach((estado) => {
        menuEstado.push({ value: estado, label: estado });
      });
      setEstados(estados);
      setLoading(false);
      setBases(data[14]);
      setRegiones(data[15]);
      setSubRegiones(data[16]);
      let filtersStorage = JSON.parse(window.localStorage.getItem('llantasGrid_filter'))
      if (filtersStorage && filtersStorage.activo) {
        // APPLY FILTERS TO GRID
        setShowPersistirFiltros(filtersStorage.activo)
        setChecked(filtersStorage.activo)
        for (let filterName in filtersStorage) {
          handleMaterialChange(filterName, filtersStorage[filterName])
        }
      }
    })
    .catch((err) => {
      console.log(err)
    })
  };

  const dataTableQuery = () => {
    let myQuery = "?page=" + pagination.page;
    myQuery += "&perPage=" + pagination.perPage;
    myQuery += "&perPage=" + pagination.perPage;
    if (query.serial.value) {
      myQuery += "&serial=" + query.serial.value;
      myQuery += "&serialSort=" + query.serialSort;
    } else {
      myQuery += "&serial=" + query.serial;
      myQuery += "&serialSort=" + query.serialSort;
    }
    if (query.activo !== null) {
      myQuery += "&activo=" + query.activo;
    }
    myQuery += "&pais=" + query.pais;
    myQuery += "&marca=" + query.marca;
    myQuery += "&marcaSort=" + query.marcaSort;
    myQuery += "&modelo=" + query.modelo;
    myQuery += "&modeloSort=" + query.modeloSort;
    myQuery += "&estado=" + query.estado;
    myQuery += "&region=" + query.region;
    myQuery += "&subRegion=" + query.subRegion;
    myQuery += "&movil=" + query.movil;
    myQuery += "&movilSort=" + query.movilSort;
    myQuery += "&clase=" + query.clase;
    myQuery += "&odometroSort=" + query.odometroSort;
    myQuery += "&desgasteSort=" + query.desgasteSort;
    myQuery += "&filtroKpi=" + query.filtroKpi;
    myQuery += "&base=" + query.base;
    myQuery += "&baseSort=" + query.baseSort;
    return myQuery;
  };

  const dataTableUpdate = (setFilters) => {
    setFiltersActive(setFilters);
    Promise.all([
        Utils.getData(dataService + dataTableQuery()),
        Utils.getData(dataService + "/kpis_card" + dataTableQuery())
      ]).then((data) => {
        let llantas = data[0].llantas;
        setLlantas(llantas);
        let pagination = 1
        if (data[0].pagination)
          pagination = data[0].pagination;
        setPagination(pagination);
        setLoading(false);
        console.log(data[1]);
        setSummaryAlerts(data[1]);
    });


  };

  const handleChangeQuery = (name, event) => {
    let target = event.target;
    query[name] = target.value;
    setQuery(query);
    dataTableUpdate(true);
  };

  const handleNew = (event) => {
    setRedirectTo("/llantas/add");
  };

  const handleChangePage = (event, newPage) => {
    pagination.page = newPage;
    dataTableUpdate(false);
  };

  const handleChangeRowsPerPage = (event) => {
    pagination.perPage = parseInt(event.target.value, 10);
    pagination.page = 0;
    dataTableUpdate(false);
  };

  const getData = (service) => {
    let serviceURL = Config.get("apiUrlBase") + service;
    return fetch(serviceURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Token": localStorage.getItem("token"),
      },
    }).then((response) => {
      return ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  };

  const handleSelectChange = (name, event) => {
    pagination.page = 0;
    if (event) {
      if (event.id) {
        query[name] = event.id;
      } else {
        query[name] = event.label;
      }

      if (name === "serial") {
        query[name] = event ? event.value : "";
        let serial = {};
        serial.id = event.id;
        serial.value = event.value;
        query["serial"] = serial;
      }
    } else {
      query[name] = "";
    }
    setQuery(query);
    dataTableUpdate(true);
  };

  const MySwal = withReactContent(Swal);

  const redirectToCorrectivo = (llanta) => {
    MySwal.fire({
      title: <p>Reparar</p>,
      html: (
        <LlantaReparar
          id={llanta.id}
          intl={props.intl}
          llanta={llanta}
          movil={llanta.movil ? llanta.movil : null}
          confirmCorrectivo={() => {
            MySwal.clickConfirm();
            dataTableUpdate(false);
            showSuccessfulMessage('Reparacion Exitosa!')
          }}
          redirectCorrectivo={() => {
            MySwal.clickConfirm();
            setRedirectTo("/correctivos/add/llanta" + "/" + llanta.id);
          }}
        />
      ),
      showConfirmButton: false,
    });
  };

  const showSuccessfulMessage = (message) => {
    MySwal.fire({
      title: message,
    })
  }

  const redirectToView = (id) => {
    setRedirectTo("/llantas/" + id);
  };

  const redirectToEdit = (id) => {
    setRedirectTo("/llantas/" + id + "/edit");
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleFilterReset = () => {
    setLoading(true)
    query.page = 0;
    query.serial = "";
    query.serialSort = "";
    query.marca = "";
    query.marcaSort = "";
    query.modelo = "";
    query.modeloSort = "";
    query.region = "";
    query.subRegion = "";
    query.movil = "";
    query.movilSort = "";
    query.clase = "";
    query.estado = "";
    query.pais = ""
    query.odometroSort = "";
    query.desgasteSort = "";
    query.filtroKpi = "";
    query.base = "";
    query.baseSort = "";
    setMarca([]);
    setModelo([]);
    setSerial("");
    setEstado([]);
    setClase([]);
    setPais([]);
    setRegion(null);
    setSubRegion(null);
    setMovil([]);
    setBase([]);
    setQuery(query);
    dataTableUpdate(true);
    setFiltersActive(null);
    setSelectFilter(null);
  };

  const requestSort = (key) => {
    setLlantas([]);
    setLoading(true)
    pagination["page"] = 0;
    if (key === "marcaSort") {
      if (!query[key]) query[key] = "asc";
      else if (query[key] === "desc") query[key] = "asc";
      else query[key] = "desc";
      query["modeloSort"] = "";
      query["serialSort"] = "";
      query["movilSort"] = "";
    } else if (key === "modeloSort") {
      if (!query[key]) query[key] = "asc";
      else if (query[key] === "desc") query[key] = "asc";
      else query[key] = "desc";
      query["marcaSort"] = "";
      query["serialSort"] = "";
      query["movilSort"] = "";
    } else if (key === "serialSort") {
      if (!query[key]) query[key] = "asc";
      else if (query[key] === "desc") query[key] = "asc";
      else query[key] = "desc";
      query["marcaSort"] = "";
      query["modeloSort"] = "";
      query["movilSort"] = "";
    } else if (key === "movilSort") {
      if (!query[key]) query[key] = "asc";
      else if (query[key] === "desc") query[key] = "asc";
      else query[key] = "desc";
      query["marcaSort"] = "";
      query["modeloSort"] = "";
      query["serialSort"] = "";
    } else if (key === "odometroSort") {
      if (!query[key]) query[key] = "asc";
      else if (query[key] === "desc") query[key] = "asc";
      else query[key] = "desc";
      query["marcaSort"] = "";
      query["modeloSort"] = "";
      query["serialSort"] = "";
      query["desgasteSort"] = "";
    } else if (key === "desgasteSort") {
      if (!query[key]) query[key] = "asc";
      else if (query[key] === "desc") query[key] = "asc";
      else query[key] = "desc";
      query["marcaSort"] = "";
      query["modeloSort"] = "";
      query["serialSort"] = "";
      query["odometroSort"] = "";
    } else if (key === "baseSort") {
      if (!query[key]) query[key] = "asc";
      else if (query[key] === "desc") query[key] = "asc";
      else query[key] = "desc";
      query["marcaSort"] = "";
      query["modeloSort"] = "";
      query["serialSort"] = "";
      query["odometroSort"] = "";
      query["desgasteSort"] = "";
    }
    setQuery(query);
    setPagination(pagination);
    dataTableUpdate(false);
  };

  const actualizarSelectModelosDeMarca = (marca) => {
    setModelo([]);
    let marcasQuery = marca.map(e => e.id).join(',');
    Promise.all([
      Utils.getData('/llantamodelos/marcas?marca=' + marcasQuery)
    ]).then((data) => {
      setModelos(data[0]);
      filterLoading["modelos"] = false;
      setFilterLoading(filterLoading);
    });
  };

  const setUserFilter = (name, element, value) => {
    let filters = window.localStorage.getItem('llantasGrid_filter') ? JSON.parse(window.localStorage.getItem('llantasGrid_filter')) : {};
    filters[name] = element
    setFiltrosTotales(filters)
    window.localStorage.setItem('llantasGrid_filter', JSON.stringify(filters))
  }

  const handleMaterialChange = (name, value) => {
    setLlantas([])
    if (name === "marca") {
      query["marca"] = value ? value.map(e => e.id).join(',') : "";
      query["modelo"] = value ? "" : query["modelo"];
      filterLoading["modelos"] = true;
      setFilterLoading(filterLoading);
      setMarca(value ? value : []);
      actualizarSelectModelosDeMarca(value);
      setUserFilter(name, value ? value : null, value ? value.id : "")
    }
    if (name === "modelo") {
      setModelo(value ? value : []);
      query["modelo"] = value ? value.map(e => e.id).join(',') : "";
      setUserFilter(name, value ? value : null, value ? value.id : "")
    }
    if (name === "estado") {
      setEstado(value ? value : []);
      query["estado"] = value ? value.map(e => e).join(',') : "";
      setUserFilter(name, value ? value : null, value ? value : "")
    }
    if (name === "movil") {
      setMovil(value ? value : []);
      query["movil"] = value ? value.map(e => e.value).join(',') : "";
      setUserFilter(name, value ? value : null, value ? value.value : "")
    }
    if (name === "pais") {
      console.log(value)
      setPais(value ? value : []);
      query["pais"] = value ? value.map(e => e.id).join(',') : "";
      setUserFilter(name, value ? value : null, value ? value.id : "")
    }
    if (name === "region") {
      setRegion(value ? value : null);
      query["region"] = value ? value.value : "";
      setUserFilter(name, value ? value : null, value ? value.value : "")
    }
    if (name === "subRegion") {
      setSubRegion(value ? value : null);
      query["subRegion"] = value ? value.value : "";
      setUserFilter(name, value ? value : null, value ? value.value : "")
    }
    if (name === "clase") {
      setClase(value ? value : []);
      query["clase"] = value ? value.value : "";
      setUserFilter(name, value ? value : null, value ? value.value : "")
    }
    if (name === "serial") {
      let event = value;
      setSerial(event.target.value);
      query[name] = event.target.value;
      setUserFilter(name, { target: { value: event.target.value } }, { target: { value: event.target.value } })
    }
    if (name === "base") {
      setBase(value ? value : []);
      if(value.length){
        query["base"] = value.map(e => e.value).join(',');
        searchBaseString([]);
      }
      else{
        query["base"] = "";
        searchBaseString(value);
      }
      setUserFilter(name, value ? value : null, value ? value.value : "")
    }
    pagination.page = 0;
    setQuery(query);
    dataTableUpdate(true);
    if (value === null) {
      setFiltersActive(null);
    }
  };

  const handleMovilChange = (e, value) => {
    setMovilLoading(true);
    let newArray = [];
    getData(`/moviles/simple-search?search=${value}`)
      .then((res) => {
        res.forEach((el) => {
          let movil = {};
          movil.label = el.dominio;
          movil.value = el.id;
          newArray.push(movil);
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setMoviles(newArray);
        setMovilLoading(false);
      });
  };

  const handleAlertClick = (filter) => {
    query.filtroKpi = filter;
    dataTableUpdate(true);
  };

  const setLoadingAndUpdate = (state) => {
    setLoading(state);
    dataTableUpdate(true);
  };

  const handleSubmitExport = () => {
    setLoading(true);
    Utils.getdDataBlob(
      '/llantas/exportar-excel' + dataTableQuery(),
      'POST',
      JSON.stringify({
        colsAExportar: dataToExport,
      })
    )
    .then((response) => {
      let fileUrl = URL.createObjectURL(response);
      $("<a />", {
        href: fileUrl,
        download: "fleet-llantas.xlsx",
      })
        .appendTo("body")
        .get(0)
        .click();
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      handleExportCancel();
      setLoading(false);
    });
  };

  const handleExportExcel = () => {
    $("#export_modal").modal("show");
  };

  const handleExportCancel = () => {
    $("#export_modal").modal("toggle");
  };

  const handleDataExport = (event, name) => {
    let dataToExportCopy = JSON.parse(JSON.stringify(dataToExport));
    dataToExportCopy[name] = !dataToExportCopy[name];
    setDataToExport(dataToExportCopy);
  };

  const handleImportExcel = () => {
    $("#import_modal").modal('show');
  };

  const handleCloseModalImport = (message, filasFallaron, error) => {
    $("#import_modal").modal('toggle');
    if (message !== "" && error) {
      if (error.codigo === 4010) {
        swalImport(props.intl.formatMessage({ id: 'llantasGrid.alert.sesion_expiro', defaultMessage: 'La sesión expiró' }), props.intl.formatMessage({ id: 'movilesGrid.alert.ingrese_nuevamente', defaultMessage: 'Por favor, ingrese nuevamente.' }), "error").then(()=>{
          window.location.reload();
        });
      } else if (error.codigo) {
        swalImport(props.intl.formatMessage(
          { id: 'llantasGrid.alert.error', defaultMessage: 'Error' }),
          props.intl.formatMessage({...(backendStrings[message] ? backendStrings[message] : backendStrings['errors.default'])}),
          "error"
        );
      } else {
        swalImport(
          props.intl.formatMessage({...(backendStrings[message] ? backendStrings[message] : backendStrings['errors.default'])}),
          error,
          "error"
        );
      }
    } else {
      if(filasFallaron.filas_fallaron.length == 0){
        swalImport(message, "", "success").then((data) => {
          handleFilterReset();
          dataTableUpdate(false);
        });
      } else{
        setReporteImportacion(filasFallaron.filas_fallaron);
        setMensajeImportacion(message);
        $('#import_report_modal').modal({ backdrop: 'static' });

      }
    }
  }

  const handleCancelModalImport = (message, subtitle, error) => {
    $("#import_modal").modal('toggle');
  }

  const handleReportCloseModal = () =>{
    $("#import_report_modal").modal('toggle');
    dataTableUpdate(false);
  }

  const handleActivoSwitch = (value) => {
    setLoading(true)
    setShowInactivos(value)
    query['activo'] = value === true ? 0 : 1;
    setQuery(query)
    dataTableUpdate(false);
  }

  const handlePersistirFiltros = (value) => {
    setShowPersistirFiltros(value)
    if (!value) {
      let filtersStorage = {}
      filtersStorage.activo = false
      window.localStorage.setItem('llantasGrid_filter', JSON.stringify(filtersStorage))
      handleFilterReset()
    }
  }

  const searchBase = (e) => {
    const value = e.target.value;
    Utils.getData("/bases/simple-search?search=" + value).then((res) =>
      setBases(res.map((b) => ({ value: b.id, label: b.descripcion })))
    );
  };

  const searchBaseString = (value) => {
    Utils.getData("/bases/simple-search?search=" + value).then((res) =>
      setBases(res.map((b) => ({ value: b.id, label: b.descripcion })))
    );
  };

  const handleOnEnterSerialChange = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleMaterialChange("serial", { target: { value: serial } })
    }
  }

  return (
    <React.Fragment>
      {redirectTo && <Redirect push to={redirectTo} />}
      {loading && <Loading />}
      {summaryAlerts && (
        <div className="row">
          {summaryAlerts.map((alert, index) => {
            return (
              <div
                key={index}
                className={"col-lg-3 col-md-4 col-12"}
                style={{ paddingRight: "0.5rem", paddingLeft: "0.5rem" }}
              >
                <BasicCardMultipleValuesInfo
                  onClickAlert={handleAlertClick}
                  id={index}
                  values={alert}
                  selectFilter={selectFilter}
                  intl={props.intl}
                ></BasicCardMultipleValuesInfo>
              </div>
            );
          })}
        </div>
      )}
      <div className="row mt-2">
        <div className="col-12">
          <div className="card">
            <div className="card-content collpase show">
              <div className="card-body card-dashboard">
                <div className="container-fluid">
                  <div className="row dt-icons">
                    <div className="col-10">
                      <div className="row">
                        {Security.renderIfHasPermission("LLANTAS_CREAR",
                          <div>
                            <div
                              className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                              style={{ height: "55px" }}
                              onClick={handleNew}
                              data-toggle="tooltip"
                              data-placement="right"
                              title={props.intl.formatMessage({
                                id: "llantasGrid.render.button_add.title_nuevo",
                                defaultMessage: "Nuevo",
                              })}
                            >
                              <i className="ft-plus"></i>
                            </div>
                          </div>
                        )}
                        {/*TODO: SOLVE STYLE PROBLEMS AND TRY TO RESIZE FROM ICON TO ACCORDION*/}
                        <div
                          style={{ maxHeight: "52px" }}
                          className="btn btn-grey-blue btn-round box-shadow-2 btn-dt-main round-icon"
                          onClick={handleChange}
                          data-toggle="tooltip"
                          data-placement="right"
                          title={props.intl.formatMessage({
                            id: "llantasGrid.render.button_add.title_filtros",
                            defaultMessage: "Filtros",
                          })}
                        >
                          {checked ? <UpIcon /> : <ExpandMoreIcon />}
                        </div>
                        {filtersActive ? (
                          <div className="btn-dt-main round-icon">
                            <div
                              className="text-center"
                              style={{
                                height: "25px",
                                borderRadius: "3px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingLeft: "8px",
                                backgroundColor: "#5AAC43",
                                color: "white",
                              }}
                            >
                              <div
                                className="text-center"
                                style={{
                                  width: "100%",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                {llantas.length} de {pagination.count} resultados de busqueda
                              </div>
                              <div className="resultados-busqueda-notif">
                                <i class="las la-times la-xs cursor-pointer" onClick={handleFilterReset}></i>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-2" id="buttons">
                      <div className="dt-buttons btn-group">
                        <button
                          onClick={handleExportExcel}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={props.intl.formatMessage({ id: 'llantas.tools_export.title_exportar_a_excel', defaultMessage: 'Exportar Excel' })}
                          className="btn btn-secondary buttons-collection"
                          tabIndex="0"
                          aria-controls="dataTable"
                          type="button"
                          aria-haspopup="true"
                        >
                          <span>
                            <i className="ft-upload"></i>
                          </span>
                        </button>
                        {Security.renderIfHasPermission("LLANTAS_CREAR",
                          <button
                            onClick={handleImportExcel}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={props.intl.formatMessage({ id: 'movilesGrid.tools_import.title_importar', defaultMessage: 'Importar' })}
                            className="btn btn-secondary buttons-collection"
                            tabIndex="0"
                            aria-controls="dataTable"
                            type="button"
                            aria-haspopup="true"
                          >
                            <span>
                              <i className="ft-download"></i>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Collapse in={checked}>
                  <div className="row mb-1">
                    <div className="col-md-10">
                      <div className="row" style={{ width: "100%" }}>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="marca"
                            loading={filterLoading}
                            options={marcas}
                            value={marca}
                            defaultValue={[]}
                            getOptionLabel={(option) => option.nombre}
                            onChange={(e, v) => setMarca(v)}
                            multiple={true}
                            onBlur={(event, value) => handleMaterialChange("marca", marca)}
                            onKeyDown={(e) => e.keyCode === 13 && e.preventDefault() && handleMaterialChange("marca", marca)}
                            disabled={loading}
                            ChipProps={{onDelete: false}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_marca.label",
                                  defaultMessage: "Marca",
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_marca.placeholder",
                                  defaultMessage: "Marca",
                                })}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="modelo"
                            loading={filterLoading.modelos}
                            options={modelos.map((item) => ({ id: item.id, nombre: item.nombre + " - " + item.llanta_medida.nombre }))}
                            value={modelo}
                            defaultValue={[]}
                            multiple={true}
                            getOptionLabel={(option) => option.nombre}
                            onChange={(e, v) => setModelo(v)}
                            onBlur={(event, value) => handleMaterialChange("modelo", modelo)}
                            disabled={loading}
                            ChipProps={{onDelete: false}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_modelo.label",
                                  defaultMessage: "Modelo",
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_modelos.placeholder",
                                  defaultMessage: "Modelos",
                                })}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {filterLoading.modelos ? <CircularProgress color="inherit" size={21} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                        <Autocomplete
                            size="small"
                            name="pais"
                            loading={filterLoading.pais}
                            options={paises}
                            value={pais}
                            defaultValue={[]}
                            multiple={true}
                            getOptionLabel={(option) => option.nombre}
                            onChange={(e, v) => setPais(v)}
                            onBlur={(event, value) => handleMaterialChange("pais", pais)}
                            disabled={loading}
                            ChipProps={{onDelete: false}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_pais.label",
                                  defaultMessage: "Pais",
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_paises.placeholder",
                                  defaultMessage: "Paises",
                                })}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {filterLoading.paises ? <CircularProgress color="inherit" size={21} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/*NEW FILTER*/}
                      <div className="row mt-1" style={{ width: "100%" }}>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="estado"
                            options={estados}
                            value={estado}
                            defaultValue={[]}
                            multiple={true}
                            getOptionLabel={(option) => option}
                            onChange={(e, v) => setEstado(v)}
                            onBlur={(event, value) => handleMaterialChange("estado", estado)}
                            disabled={loading}
                            ChipProps={{onDelete: false}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_estados.label",
                                  defaultMessage: "Estado",
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_estados.placeholder",
                                  defaultMessage: "Estados",
                                })}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <form className={classes.root} noValidate autoComplete="off">
                            <Input
                              id="standard-basic"
                              label={props.intl.formatMessage({
                                id: "llantasGrid.render.filtros_serial.label",
                                defaultMessage: "Serial",
                              })}
                              name="serial"
                              value={serial}
                              onChange={(e) => setSerial(e.target.value)}
                              onKeyDown={(e) => handleOnEnterSerialChange(e)}
                              onBlur={(e) => handleMaterialChange("serial", { target: { value: serial } })}
                              disabled={loading}
                            />
                          </form>
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center">
                            <div className="mr-2">Persistir Filtros</div>
                            <Switch
                              onChange={(value) => handlePersistirFiltros(value)}
                              checked={showPersistirFiltros}
                              disabled={loading}
                              offColor="#FF4961"
                              onColor="#28D094"
                            />
                          </div>
                        </div>
                      </div>
                      {/*NEW FILTER*/}
                      <div className="row mt-1" style={{ width: "100%" }}>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="movil"
                            loading={movilLoading}
                            options={moviles}
                            value={movil}
                            defaultValue={[]}
                            multiple={true}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) => setMovil(v)}
                            onBlur={(event, value) => handleMaterialChange("movil", movil)}
                            disabled={loading}
                            ChipProps={{onDelete: false}}
                            onInputChange={(event, value) => handleMovilChange(event, value)}
                            noOptionsText={props.intl.formatMessage({
                              id: "llantasGrid.render.filtros_sin_moviles.label",
                              defaultMessage: "Sin Moviles a filtrar disponibles",
                            })}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_movil.label",
                                  defaultMessage: "Movil",
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_movil.placeholder",
                                  defaultMessage: "Movil",
                                })}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4">
                          <Autocomplete
                            size="small"
                            name="Base"
                            options={bases}
                            value={base}
                            defaultValue={[]}
                            multiple={true}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, v) => setBase(v)}
                            onBlur={(event, value) => handleMaterialChange("base", base)}
                            disabled={loading}
                            ChipProps={{onDelete: false}}
                            noOptionsText={props.intl.formatMessage({
                              id: "llantasGrid.render.filtros_sin_bases.label",
                              defaultMessage: "Sin Bases a filtrar disponibles",
                            })}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_bases.label",
                                  defaultMessage: "Bases",
                                })}
                                placeholder={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_bases.placeholder",
                                  defaultMessage: "Bases",
                                })}
                                onChange={searchBase}
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center">
                            <div className="mr-2">Ver Inactivos</div>
                            <Switch
                              onChange={(value) => handleActivoSwitch(value)}
                              checked={showInactivos}
                              disabled={loading}
                              offColor="#FF4961"
                              onColor="#28D094"
                            />
                          </div>
                        </div>
                      </div>

                      {/*REGIONES*/}
                      <div className="row mt-1" style={{ width: "100%" }}>
                        <div className="col-md-4">
                            <Autocomplete
                              size="small"
                              name="Región"
                              options={regiones}
                              value={region ? region : null}
                              getOptionLabel={(option) => option.label}
                              onChange={(event, value) => handleMaterialChange("region", value)}
                              disabled={loading}
                              noOptionsText={props.intl.formatMessage({
                                id: "llantasGrid.render.filtros_sin_regiones.label",
                                defaultMessage: "Sin Regiones a filtrar disponibles",
                              })}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label={props.intl.formatMessage({
                                    id: "llantasGrid.render.filtros_regiones.label",
                                    defaultMessage: "Región",
                                  })}
                                  placeholder={props.intl.formatMessage({
                                    id: "llantasGrid.render.filtros_regiones.placeholder",
                                    defaultMessage: "Región",
                                  })}
                                />
                              )}
                            />
                          </div>

                          {/*SUB-REGIONES*/}
                          <div className="col-md-4">
                              <Autocomplete
                                size="small"
                                name="Sub-Región"
                                options={subRegiones}
                                value={subRegion ? subRegion : null}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) => handleMaterialChange("subRegion", value)}
                                disabled={loading}
                                noOptionsText={props.intl.formatMessage({
                                  id: "llantasGrid.render.filtros_sin_sub_regiones.label",
                                  defaultMessage: "Sin Sub Regiones a filtrar disponibles",
                                })}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label={props.intl.formatMessage({
                                      id: "llantasGrid.render.filtros_subregiones.label",
                                      defaultMessage: "Sub-Región",
                                    })}
                                    placeholder={props.intl.formatMessage({
                                      id: "llantasGrid.render.filtros_subregiones.placeholder",
                                      defaultMessage: "Sub-Región",
                                    })}
                                  />
                                )}
                                />
                          </div>
                      </div>
                      {/*NEW FILTER*/}
                      <div className="row mt-1" style={{ width: "100%" }}>
                        <div className="col-md-4 text-center d-flex align-items-center justify-content-center font-weight-bold"></div>
                        <div className="col-md-8"></div>
                      </div>
                    </div>

                    <div className="col-md-2">
                    </div>
                  </div>
                </Collapse>
                <div className="table-responsive">
                  <table
                    id="dataTable"
                    className="dataTable table nowrap server-side table-hover dataTableMovil"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        {/* <th scope="col"></th> */}
                        <th scope="col" style={{ cursor: "pointer" }} onClick={() => requestSort("marcaSort")}>
                          <FormattedMessage id="llantasGrid.rennder.column_marca.label" defaultMessage="Marca" />
                          <img
                            className="ml-1"
                            src={query.marcaSort === "" ? Sort : query.marcaSort === "asc" ? SortAsc : SortDesc}
                            alt={"/"}
                            style={{ height: "16px", width: "26px" }}
                          />
                        </th>
                        <th scope="col" style={{ maxWidth: "3px" }}>
                          <div class="dropdown">
                            <i
                              id="dLabel"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className="fa fa-filter cursor-pointer"
                              style={{ marginRight: "10px" }}
                            ></i>
                            {/* <button id="dLabel" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dropdown trigger
                          </button> */}
                            <div class="dropdown-menu" aria-labelledby="dLabel">
                              {/* <p class="dropdown-item">Action</p> */}
                              {estados
                                ? estados.map((el) => (
                                  <p
                                    className="cursor-pointer dropdown-item"
                                    style={{ margin: "0", padding: "5px 5px" }}
                                    onClick={() => handleMaterialChange("estado", [el])}
                                  >
                                    {el}
                                  </p>
                                ))
                                : ""}
                            </div>
                          </div>
                        </th>
                        <th scope="col" style={{ cursor: "pointer" }} onClick={() => requestSort("serialSort")}>
                          <FormattedMessage id="llantasGrid.rennder.column_serial.label" defaultMessage="Serial" />
                          <img
                            className="ml-1"
                            src={query.serialSort === "" ? Sort : query.serialSort === "asc" ? SortAsc : SortDesc}
                            alt={"/"}
                            style={{ height: "16px", width: "26px" }}
                          />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="llantasGrid.rennder.column_accion.label" defaultMessage="Acción" />
                        </th>
                        <th scope="col" style={{ cursor: "pointer" }} onClick={() => requestSort("modeloSort")}>
                          <FormattedMessage id="llantasGrid.rennder.column_modelo.label" defaultMessage="Modelo" />
                          <img
                            className="ml-1"
                            src={query.modeloSort === "" ? Sort : query.modeloSort === "asc" ? SortAsc : SortDesc}
                            alt={"/"}
                            style={{ height: "16px", width: "26px" }}
                          />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="llantasGrid.rennder.column_medidas.label" defaultMessage="Medidas" />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="llantasGrid.rennder.column_recapados.label" defaultMessage="0/R" />
                        </th>
                        <th scope="col">
                          <FormattedMessage
                            id="llantasGrid.rennder.column_mediciones.label"
                            defaultMessage="Mediciones"
                          />
                        </th>
                        <th scope="col">
                          <FormattedMessage
                            id="llantasGrid.rennder.column_desgaste_cada_mil.label"
                            defaultMessage="Desgaste c/1000km"
                          />
                        </th>
                        <th scope="col">
                          <FormattedMessage id="llantasGrid.rennder.column_Costo.label" defaultMessage="Costo" />
                        </th>
                        <th scope="col" style={{ cursor: "pointer" }} onClick={() => requestSort("desgasteSort")}>
                          <FormattedMessage id="llantasGrid.rennder.column_desgaste.label" defaultMessage="Desgaste" />
                          <img
                            className="ml-1"
                            src={query.desgasteSort === "" ? Sort : query.desgasteSort === "asc" ? SortAsc : SortDesc}
                            alt={"/"}
                            style={{ height: "16px", width: "26px" }}
                          />
                        </th>
                        <th scope="col" style={{ cursor: "pointer" }} onClick={() => requestSort("odometroSort")}>
                          <FormattedMessage id="llantasGrid.rennder.column_medidor.label" defaultMessage="Medidor" />
                          <img
                            className="ml-1"
                            src={query.odometroSort === "" ? Sort : query.odometroSort === "asc" ? SortAsc : SortDesc}
                            alt={"/"}
                            style={{ height: "16px", width: "26px" }}
                          />
                        </th>
                        <th scope="col" style={{ cursor: "pointer" }} onClick={() => requestSort("movilSort")}>
                          <FormattedMessage
                            id="llantasGrid.rennder.column_asignacion.label"
                            defaultMessage="Asignación"
                          />
                          <img
                            className="ml-1"
                            src={query.movilSort === "" ? Sort : query.movilSort === "asc" ? SortAsc : SortDesc}
                            alt={"/"}
                            style={{ height: "16px", width: "26px" }}
                          />
                        </th>
                        <th scope="col" style={{ cursor: "pointer" }} onClick={() => requestSort("baseSort")}>
                          <FormattedMessage
                            id="llantasGrid.rennder.column_base.label"
                            defaultMessage="Base"
                          />
                          <img
                            className="ml-1"
                            src={query.baseSort === "" ? Sort : query.baseSort === "asc" ? SortAsc : SortDesc}
                            alt={"/"}
                            style={{ height: "16px", width: "26px" }}
                          />
                        </th>
                        <th scope="col">
                          <FormattedMessage
                            id="llantasGrid.rennder.column_ult_inspección.label"
                            defaultMessage="Ult. Inspección"
                          />
                        </th>
                        <th scope="col" align="center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {llantas[0] ? (
                        llantas.map((llanta) => (
                          <Row
                            intl={props.intl}
                            redirectToCorrectivo={redirectToCorrectivo}
                            redirectToView={redirectToView}
                            redirectToEdit={redirectToEdit}
                            setRedirectTo={setRedirectTo}
                            llanta={llanta}
                            loading={(state) => setLoadingAndUpdate(state)}
                            key={llanta.id}
                            dataTableUpdate={(state) => dataTableUpdate(state)}
                            llantaRecaparId={showModalParaRecapar ? props.match.params.id : null}
                          />
                        ))
                      ) : (
                        <tr>
                          <td align={"center"} colSpan={14}>
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              <FormattedMessage
                                id="llantasGrid.rennder.sin_datos_en_tabla"
                                defaultMessage="No hay datos disponibles."
                              />
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={pagination.count}
                  rowsPerPage={pagination.perPage}
                  page={pagination.page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage="Mostrar"
                  labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex="-1" id="export_modal" role="dialog" aria-labelledby="myModalLabel3">
        <div className="modal-xl modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-fleet">
              <h4 className="modal-title text-white" id="myModalLabel3">
                <i className="ft-download align-middle icon-modal-title"></i>
                <FormattedMessage
                  id="movilesGrid.render.export_modal.header_datos_generales"
                  defaultMessage=" Exportar Moviles"
                />
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body modal-grey modal-mh ovf-x-hidden">
              <div className="row">
                <div className="col-md-12">
                  <form className="form form-horizontal" onSubmit={handleSubmitExport}>
                    <div className="form-body">
                      <div className="card pull-up">
                        <div className="card-content">
                          <div className="card-body">
                            <h4 className="form-section">
                              <i className="la la-columns"></i>
                              <FormattedMessage
                                id="llantasGrid.render.export_modal.columns_to_export.header_columnas_a_exportar"
                                defaultMessage=" Columnas a exportar"
                              />
                            </h4>
                            <div className="row m-1">
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="marca">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_marca"
                                    defaultMessage="Marca:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "marca")}
                                  checked={dataToExport.marca ? true : false}
                                  value={dataToExport.marca}
                                  id="marca"
                                  name="marca"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="serial">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_serial"
                                    defaultMessage="Serial:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "serial")}
                                  checked={dataToExport.serial ? true : false}
                                  value={dataToExport.serial}
                                  id="serial"
                                  name="serial"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="modelo">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_modelo"
                                    defaultMessage="Modelo:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "modelo")}
                                  checked={dataToExport.modelo ? true : false}
                                  value={dataToExport.modelo}
                                  id="modelo"
                                  name="modelo"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="medida">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_medida"
                                    defaultMessage="Medida:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "medida")}
                                  checked={dataToExport.medida ? true : false}
                                  value={dataToExport.medida}
                                  id="medida"
                                  name="medida"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                            </div>
                            <div className="row m-1">
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="recapados">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_recapados"
                                    defaultMessage="0/R:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "recapados")}
                                  checked={dataToExport.recapados ? true : false}
                                  value={dataToExport.recapados}
                                  id="recapados"
                                  name="recapados"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="subregion">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_subregion"
                                    defaultMessage="Sub Región:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "subregion")}
                                  checked={dataToExport.subregion ? true : false}
                                  value={dataToExport.subregion}
                                  id="subregion"
                                  name="subregion"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="region">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_region"
                                    defaultMessage="Región:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "region")}
                                  checked={dataToExport.region ? true : false}
                                  value={dataToExport.region}
                                  id="region"
                                  name="region"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="pais">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_pais"
                                    defaultMessage="País:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "pais")}
                                  checked={dataToExport.pais ? true : false}
                                  value={dataToExport.pais}
                                  id="pais"
                                  name="pais"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                            </div>
                            <div className="row m-1">
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="costo">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_costo"
                                    defaultMessage="Costo:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "costo")}
                                  checked={dataToExport.costo ? true : false}
                                  value={dataToExport.costo}
                                  id="costo"
                                  name="costo"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="desgaste">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_desgaste"
                                    defaultMessage="Desgaste:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "desgaste")}
                                  checked={dataToExport.desgaste ? true : false}
                                  value={dataToExport.desgaste}
                                  id="desgaste"
                                  name="desgaste"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="odometro">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_odometro"
                                    defaultMessage="Odómetro:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "odometro")}
                                  checked={dataToExport.odometro ? true : false}
                                  value={dataToExport.odometro}
                                  id="odometro"
                                  name="odometro"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="asignacion">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_asignacion"
                                    defaultMessage="Asignación:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "asignacion")}
                                  checked={dataToExport.asignacion ? true : false}
                                  value={dataToExport.asignacion}
                                  id="asignacion"
                                  name="asignacion"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                            </div>
                            <div className="row m-1">
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="posicion">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_posicion"
                                    defaultMessage="Posición:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "posicion")}
                                  checked={dataToExport.posicion ? true : false}
                                  value={dataToExport.posicion}
                                  id="posicion"
                                  name="posicion"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="ultInspeccion">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_ultInspeccion"
                                    defaultMessage="Ult. Inspección:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "ultInspeccion")}
                                  checked={dataToExport.ultInspeccion ? true : false}
                                  value={dataToExport.ultInspeccion}
                                  id="ultInspeccion"
                                  name="ultInspeccion"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="desgasteCada1000km">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_desgasteCada1000km"
                                    defaultMessage="Desgaste c/1000km:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "desgasteCada1000km")}
                                  checked={dataToExport.desgasteCada1000km ? true : false}
                                  value={dataToExport.desgasteCada1000km}
                                  id="desgasteCada1000km"
                                  name="desgasteCada1000km"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="mediciones">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_mediciones"
                                    defaultMessage="Mediciones:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "mediciones")}
                                  checked={dataToExport.mediciones ? true : false}
                                  value={dataToExport.mediciones}
                                  id="mediciones"
                                  name="mediciones"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                            </div>
                            <div className="row m-1">
                              <div className=" col-md-3">
                                <label className="col-md-6 label-control col-form-label" htmlFor="base">
                                  <FormattedMessage
                                    id="llantasGrid.render.export_modal.columns_to_export.label_base"
                                    defaultMessage="Base:"
                                  />
                                </label>
                                <Switch
                                  onChange={(event) => handleDataExport(event, "base")}
                                  checked={dataToExport.base ? true : false}
                                  value={dataToExport.base}
                                  id="base"
                                  name="base"
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                  disabled={false}
                                  className="col-md-6 align-middle"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 alert alert-info small" role="alert">
                      <FormattedMessage
                        id="llantasGrid.render.export_modal.esta_accion_puede_demorar_unos_minutos"
                        defaultMessage="Esta acción puede demorar unos minutos."
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal-footer modal-grey">
              <button type="button" className="btn btn-default btn-fleet" onClick={handleSubmitExport}>
                <i className={loading ? "la la-spinner spinner" : "fa fa-download"}></i>
                <FormattedMessage
                  id="llantasGrid.render.export_modal.finish_button.exportar_a_excel"
                  defaultMessage=" Exportar a Excel"
                />
              </button>
              <button type="button" className="btn btn-danger" onClick={handleExportCancel}>
                <i className="fa fa-times-circle"></i>
                <FormattedMessage
                  id="llantasGrid.render.export_modal.finish_button.cancelar"
                  defaultMessage=" Cancelar"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade col-8" tabIndex="-1" id="import_modal" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
        <div className="modal-xl modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-fleet">
              <h4 className="modal-title text-white" id="myModalLabel1">
                <FormattedMessage id="llantasGrid.tools.import.button.importar" defaultMessage=" Importar"/>
              </h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body modal-grey">
              <form className="form">
                {<LlantasImportar
                  callbackClose={handleCloseModalImport}
                  callbackCancel={handleCancelModalImport}/>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade col-12" tabIndex="-1" id="import_report_modal" role="dialog" aria-labelledby="importReportLabel" style={{ margin: "auto" }}>
        <div className="modal-lg modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-fleet">
              <h4 className="modal-title text-white" id="importReportLabel">Reporte de la Importación</h4>
              <button type="button" className="close" onClick={handleReportCloseModal} aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body modal-grey modal-mh ovf-x-hidden">
              {reporteImportacion ?
              <LlantasImportarReporte data={reporteImportacion} callbackReportClose={handleReportCloseModal}></LlantasImportarReporte>
              : null}
            </div>
            <div className="modal-footer modal-grey">
              <button type="button" className="btn btn-default btn-fleet" onClick={handleReportCloseModal}>Ok</button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(LlantasGrid);
