import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import swal from 'sweetalert'
import Config from '../../commons/config/Config.js';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import moment from 'moment'
import 'moment/min/locales'
import Switch from "react-switch"
import Security from '../../commons/security/Security.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js'
import { ID__TE_PERSONA as PERSONA, ID__TE_MOVIL as MOVIL} from '../tipoEntidad/constants.js'
import swal2 from 'sweetalert2'

const defaultSelectCada = [{id:0, label:"Días"},{id:1, label:"Mes"},{id:2, label:"Año"}];

class ServiciosAbm extends Component {
	constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

		this.state = {
			redirectTo: null,
			props: this.props,
			formData: {
        id: 0,
        nombre: '',
        activo: true,
        adjuntoObligatorio: false,
        ticketTipo: "",
        preventivoCada: 0,
        preventivoAlertarPrevios: 0,
        vencimientoAplicaA: null,
        topeAprobacion: 0,
        alertaDiasPrevios: 0,
        emisionDiasPrevios: 0,
        unidadMedidorId: 1,
        vencimientoDuracion: 0,
        gestoriaVencimiento: false,
        necesitaFormulario: false,
        costoFormulario: 0,
        certificaEscribania: false,
        costoEscribania: 0,
        necesitaGestoria: false,
        costoGestoria: 0,
        estadoProvisorio: false,
        categoria: null,
        validacionAuditor: true,
        intervaloTemporalCada: 0,
        intervaloTemporalCadaTipo: 0,
        intervaloTemporalAlertar: 0,
        intervaloTemporalAlertarTipo: 0,
        intervaloTemporal: false,
        montoMaximoAprobacionN1: 0,
        montoMaximoAprobacionN2: 0,
        tareas: []
      },
      aplica_a: [
        {id:1, nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.select_aplica_a.persona', defaultMessage: 'PERSONA' })},
        {id:2, nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.select_aplica_a.movil', defaultMessage: 'MOVIL' })}
      ],
      tipos_ticket: null,
      estados: [{ id: 1, nombre: 'Activo'},{ id: 2, nombre: 'Inactivo'}],
      admite_tareas: [{ id: 1, nombre: 'SI'},{ id: 2, nombre: 'NO'}],
      intervalo_recorrido: [{ id: 0, nombre: 'SI'},{ id: 1, nombre: 'NO'}],
      errors: [],
      ticketsLoading: false,
      unidadMedidorIdLoading: false,
      intervaloTemporalCadaTipoLoading: false,
      intervaloTemporalAlertarTipoLoading: false,
      aplicaLoading: false,
      categoriaLoading:false,
      loading: false,
      categoriasAdjuntos: [],
      estadoPresupuestadoSV: ConfigBusiness.get('tickets.estadoPresupuestadoSV.habilitado') === 'true' ? true : false,
      tareas: [],

    };

    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormChange = this.handleSelectFormChange.bind(this);
    this.handleNumericChange = this.handleInputFormChange.bind(this);
    this.handleAdjuntoObligatorioChange = this.handleAdjuntoObligatorioChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);


    this.formValidation = new FormValidation({
			component: this,
			validators: {
        'formData.nombre': (value) => Validator.notEmpty(value),
        'formData.vencimientoAplicaA': (value) => Validator.conditionalNotEmpty((this.state.formData.ticketTipo && this.state.formData.ticketTipo.nombre === "VENCIMIENTO"), value),
        'formData.ticketTipo': (value) => Validator.notEmpty(value),
        'formData.costoFormulario': (value) => Validator.conditionalFloatNumber(this.state.formData.necesitaFormulario, value),
        'formData.costoEscribania': (value) => Validator.conditionalFloatNumber(this.state.formData.certificaEscribania, value),
        'formData.costoGestoria': (value) => Validator.conditionalFloatNumber(this.state.formData.necesitaGestoria, value),
        'formData.categoria': (value) => Validator.conditionalNotEmpty(this.state.formData.adjuntoObligatorio, value),
        // 'formData.realizarCadaKms': (value) => Validator.intNumber(value),
        // 'formData.realizarCadaHorometro': (value) => Validator.intNumber(value),
        // 'formData.alertarHorometro': (value) => Validator.intNumber(value),
        // 'formData.preventivoAlertarKmsPrevios': (value) => Validator.intNumber(value),
        'formData.tareas': (value) => Validator.notEmpty(value),
			}
    });

  }


  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
	}
	componentWillMount() {
    if ((Security.hasPermission('SERVICIOS_CREAR') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('SERVICIOS_MODIFICAR') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('SERVICIOS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
      this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
    }
  }
  initForm() {
		this.setState({ loading: true });
    let tipos_ticket =  null;
    if(ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true'){
      tipos_ticket = [
        {id:1 , nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.gestoria_habilitado.select_ticket_type.vencimiento', defaultMessage: 'VENCIMIENTO' })}, 
        {id:2 , nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.gestoria_habilitado.select_ticket_type.preventivo', defaultMessage: 'PREVENTIVO' })}, 
        {id:3, nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.gestoria_habilitado.select_ticket_type.correctivo', defaultMessage: 'CORRECTIVO' })}, 
        {id:4, nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.gestoria_habilitado.select_ticket_type.gestoria', defaultMessage: 'GESTORIA' })}
      ];
    } else {
      tipos_ticket = [
        {id:1 , nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.gestoria_deshabilitado.select_ticket_type.vencimiento', defaultMessage: 'VENCIMIENTO' })}, 
        {id:2 , nombre: 'PREVENTIVO'}, 
        {id:3, nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.gestoria_deshabilitado.select_ticket_type.correctivo', defaultMessage: 'CORRECTIVO' })}
      ];
    }

		this.setState({
      loading: true,
      tipos_ticket: tipos_ticket
    });
    let component = this;

		Promise.all([
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/servicios/' + this.state.props.match.params.id) : null,
      this.state.props.action !== 'ADD' ? this.ajaxHandler.getJson('/categorias/select') : null,
      this.ajaxHandler.getJson('/tareas/all-by-name'),
		]).then((data) => {
      if(data[0] && data[0].ticketTipo){
        data[0].ticketTipo = {id:1, nombre: data[0].ticketTipo}
      }
      // if(data[0] && data[0].vencimientoAplicaA){
      //   data[0].vencimientoAplicaA = {id:1, nombre: data[0].vencimientoAplicaA}
      // }
            
      if (ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.habilitado') === 'true' && data[0] === null ){
        
        let formData = this.state.formData;
        formData.montoMaximoAprobacionN1 = ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.montoMaximoAprobacionN1');
        formData.montoMaximoAprobacionN2 = ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.montoMaximoAprobacionN2');
        
        this.setState({
          formData : formData
        })
      }

      let formData = data[0];
      let categorias = data[1] ? data[1] : [];
      let tareas = data[2] ? data[2] : [];

      // Tareas del servicio
      if(formData && formData.tareas){
        let formDataTareas = formData.tareas;
        formData.tareas = formDataTareas.map(tarea => {
          return {"value": tarea.id , "label":tarea.nombre }
        });
      }
  
      if(formData && formData.categoria)
        formData.categoria = { value: data[0]['categoria']['id'], label:data[0]['categoria']['nombre'] };
      
      if(formData)
        component.setState({
  				formData: formData
        });

      component.setState({
        categoriasAdjuntos: categorias,
        tareas: tareas
      });

    }).catch(function(error) {
			console.log(error);
			component.exit();
		}).finally(() => {
			this.setState({ loading: false });
    });
	}

   handleEstadoChange(name, estado) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = estado;
    formDataCopy['intervaloTemporalCada']= 0;
    formDataCopy['intervaloTemporalCadaTipo']= 0;
    formDataCopy['intervaloTemporalAlertar']= 0;
    formDataCopy['intervaloTemporalAlertarTipo']= 0;
		this.setState({
			formData: formDataCopy
    });
  }

    handleAdjuntoObligatorioChange(estado) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

        if(estado && this.state.categoriasAdjuntos.length === 0){

            this.setState({
                loading: true
              });
              let component = this;

            Promise.all([
                component.ajaxHandler.getJson('/categorias/select'),
            ]).then((data) => {

                let categorias = data[0] ? data[0] : [];

                component.setState({
                    categoriasAdjuntos: categorias
                });

            }).catch(function(error) {
                    console.log(error);
                    component.exit();
                }).finally(() => {
                    component.setState({ loading: false });
            });
        }else if(!estado){
            //si se pasa afalse
            formDataCopy['categoria'] = null;
        }

        formDataCopy['adjuntoObligatorio'] = estado;
        this.setState({
            formData: formDataCopy
        });

    }

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
  }

  handleNumericChange(e) {
    let name = e.target.name;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = (e.target.validity.valid) ? e.target.value : this.state.formData[name];
    this.setState({
      formData: formDataCopy
    });
  }

  handleSelectFormChange(name, object) {
    const value = object === null ? null : object;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = value;
    if(name === "ticketTipo"){

      formDataCopy['vencimientoAplicaA']= null;
      formDataCopy['preventivoCada']= 0;
      formDataCopy['preventivoAlertarPrevios'] = 0;
      if(formDataCopy[name]!==null)
      {
        if (formDataCopy[name]['nombre']!=='VENCIMIENTO') {
          formDataCopy['vencimientoAplicaA']= {id: 2, nombre: this.props.intl.formatMessage({ id: 'serviciosAbm.handleSelectFormChange.select_aplica_a.movil', defaultMessage: 'MOVIL' })};
        }
      }
    } else if (name === "unidadMedidorId" || name === "intervaloTemporalCadaTipo" || name === "intervaloTemporalAlertarTipo" || name === "vencimientoAplicaA"){
      formDataCopy[name]= value.id;
    }

    this.setState({
			formData: formDataCopy
    });
  }

  handleSelectTareasChange(tarea) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.tareas = tarea;

    this.setState({
        formData : formDataCopy
    })
  }

	handleSubmit(event) {
    this.setState({ loading: true });
    let hayError = false;
    let component = this

    if(ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.habilitado') === 'true'){
      let N1 = this.state.formData.montoMaximoAprobacionN1
      let N2 = this.state.formData.montoMaximoAprobacionN2

      if(N1 == null || N1 == undefined || N1 == '' || N2 == null || N2 == undefined || N2 == '') hayError = true;      
      else if( !(N1 <= N2) ) hayError=true;
    } 
    console.log(hayError)
    if(!hayError){      
      this.ajaxHandler.fetch('/servicios' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
        method: this.props.action === 'ADD' ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...this.state.formData
        }),
      }).then(response => {
        if(response.status !== 400) {
          
          component.exit();
        } else {
          response.json()
          .then(data => {
            this.setState({
              errors: data.detalle
            });
          });
        }
        window.scrollTo(0,0);
      }).catch((error) => {
        component.ajaxHandler.handleError(error);
      }).finally(() => {
        this.setState({ loading: false });
      });                
    } else {
      swal({
        title: "Error",
        text: 'Ingrese los valores de los de aprobacion correctamente',
        icon: "error",
        buttons: {
          confirm: {
            text: component.props.intl.formatMessage({ id: 'ajaxHandler.errors_401.error_button_aceptar', defaultMessage: 'Aceptar' }),
            value: true,
            visible: true,
            className: "btn btn-primary",
            closeModal: true
          }
        }
      });
      this.setState({ loading: false });
    }
    event.preventDefault();    
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/servicios'
		});
	}

	render() {
    this.formValidation.validate();
    let formData = this.state.formData;
    let validationState = this.formValidation.state;
    let state = this.state;
		return (

			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i><FormattedMessage id="serviciosAbm.render.general_data.header_datos_generales" defaultMessage=" Datos Generales "/><div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="serviciosAbm.render.general_data.campos_requeridos" defaultMessage="campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* Nombre */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_nombre" defaultMessage="Nombre "/>*:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.nombre}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="nombre" name="nombre" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_nombre', defaultMessage: 'Servicio' })} value={formData.nombre} onChange={this.handleInputFormChange} />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.nombre.pristine || validationState.formData.nombre.valid}>{validationState.formData.nombre.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                      {/* Tipo de Ticket */}
                        <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="ticketTipo">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_tipo_de_ticket" defaultMessage="Tipo de Ticket "/>*:
                              </label>
                              <div className="col-md-9">
                                {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label">{formData.ticketTipo ? formData.ticketTipo.nombre : ''}</div>
                                ) : (
                                <div>
                                  <Select
                                    id="ticketTipo"
                                    name="ticketTipo"
                                    placeholder={!state.ticketsLoading ? this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_tipo_de_ticket', defaultMessage: 'Seleccione' }) : ''}
                                    options={this.state.tipos_ticket}
                                    valueKey='id'
                                    labelKey='nombre'
                                    value={formData.ticketTipo ? formData.ticketTipo : null}
                                    onChange={(e) => this.handleSelectFormChange('ticketTipo', e)}
                                    isLoading={state.ticketsLoading}
                                    disabled={state.ticketsLoading}
                                  />
                                  <div className="help-block text-danger field-message" hidden={validationState.formData.ticketTipo.pristine || validationState.formData.ticketTipo.valid}>{validationState.formData.ticketTipo.message}</div>
                                </div>
                                )}
                              </div>
                            </div>
                        </div>
                      </div>

                      <div className="row">
                      {/* Tareas del Servicio */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="servicioTareas">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_servicio_tareas" defaultMessage="Tareas del servicio "/>*:
                            </label>
                            <div className="col-md-9">
                              <Select
                                id="servicioTareas"
                                name="servicioTareas"
                                placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_tareas', defaultMessage: 'Seleccione' })}
                                options={this.state.tareas}
                                valueKey='value'
                                labelKey='label'
                                value={this.state.formData.tareas}
                                onChange={(e) => this.handleSelectTareasChange(e)}
                                isLoading={state.tareasLoading}
                                disabled={state.tareasLoading || this.props.action === 'VIEW'}
                                multi={true}
                                removeSelected={true}
                                closeOnSelect={true}
                              />
                              <div className="help-block text-danger field-message" hidden={validationState.formData.tareas.pristine || validationState.formData.tareas.valid}>{validationState.formData.tareas.message}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      { ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.habilitado') === 'false' ? (
                      <div className="row">
                        {/* Tope Aprobacion */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('servicios.montoAutoAprobacion.habilitado') === 'false' ? 'none' : '' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="topeAprobacion">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_monto_max_aprobacion" defaultMessage="Monto Max. Aprobación"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.topeAprobacion}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="topeAprobacion" name="topeAprobacion" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_monto_max_aprobacion', defaultMessage: 'Monto Max. de Aprobación' })} value={formData.topeAprobacion} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      ): ''}

                      {/* TICKET TIPO PREVENTIVO */}
                      { formData.ticketTipo && formData.ticketTipo.nombre === "PREVENTIVO" ?
                      <div>
                      <div className="row" >

                        {/* UNIDAD MEDIDOR */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="unidadMedidor">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_unidad_medidor" defaultMessage="Unidad medidor "/>*:
                            </label>
                            <div className="col-md-9">
                                <SelectWithIcons
                                  placeholder="Tipo"
                                  valueKey="id"
                                  disabled={this.state.props.action === 'VIEW'}
                                  labelKey="label"
                                  clearable={false}
                                  onChange={(e) => this.handleSelectFormChange('unidadMedidorId', e)}
                                  value={this.state.formData.unidadMedidorId}
                                  options={Object.values(UNIDAD_MEDIDOR)}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.ticketTipo.pristine || validationState.formData.ticketTipo.valid}>{validationState.formData.ticketTipo.message}</div>
                            </div>
                          </div>
                        </div>

                        {/* INTERVALO TEMPORAL */}
                        <div className="col-md-5 offset-md-1">
                          <div className="form-group row">
                            <div className="col-md-4">
                              <label className="label-control col-form-label" htmlFor="intervaloTemporal">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_intervalo_temporal" defaultMessage="Intervalo Temporal"/>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <Switch
                                onChange={(e) => this.handleEstadoChange('intervaloTemporal', e)}
                                checked={formData.intervaloTemporal}
                                options={this.state.intervaloTemporal}
                                id="intervaloTemporal"
                                name="intervaloTemporal"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">

                        {/* PREVENTIVO CADA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="preventivoCada">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_preventivo_cada" defaultMessage="Cada:"/>
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">{formData.preventivoCada === 0 ? '': formData.preventivoCada }</div>
                              ) : (
                                <div>
                                  <input type="text" className="form-control" pattern="[0-9]*" id="preventivoCada" name="preventivoCada" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_preventivo_cada', defaultMessage: 'Sólo números' })} value={formData.preventivoCada === 0 ? '': formData.preventivoCada}  onInput={this.handleNumericChange.bind(this)} />
                                </div>
                              )}
                            </div>
                            <label className="col-md-1 label-control col-form-label text-nowrap text-left" htmlFor="preventivoUnidad">
                              {UNIDAD_MEDIDOR[formData.unidadMedidorId]['unidades']}
                            </label>
                          </div>
                        </div>

                        {/* INTERVALO TEMPORAL CADA */}
                        <div className="col-md-5 offset-md-1">
                          <div className="form-group row">
                            <div className="col-md-3">
                              <label className="label-control col-form-label text-nowrap" htmlFor="intervaloTemporalCada">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_intervalo_temporal_cada" defaultMessage="Cada:"/>
                              </label>
                            </div>
                            <div className="col-md-5">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">{formData.intervaloTemporalCada}</div>
                              ) : (
                                <div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="intervaloTemporalCada"
                                    name="intervaloTemporalCada"
                                    placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_intervalo_temporal_cada', defaultMessage: '0' })}
                                    value={formData.intervaloTemporalCada}
                                    onChange={this.handleInputFormChange}
                                    disabled={state.intervaloTemporalCadaTipoLoading || this.props.action === 'VIEW' || formData.intervaloTemporal === false}
                                  />
                                  {/* <div
                                      className="help-block text-danger field-message"
                                          hidden={validationState.formData.realizarCadaHorometro.pristine || validationState.formData.realizarCadaHorometro.valid}>
                                            {validationState.formData.realizarCadaHorometro.message
                                          }</div> */}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <Select
                                id="intervaloTemporalCadaTipo"
                                name="intervaloTemporalCadaTipo"
                                placeholder={!state.intervaloTemporalCadaTipoLoading ? this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_intervalo_temporal_cada_tipo', defaultMessage: 'Seleccione' }) : ''}
                                options={defaultSelectCada}
                                valueKey='id'
                                labelKey='label'
                                value={formData.intervaloTemporalCadaTipo ? defaultSelectCada[formData.intervaloTemporalCadaTipo] : defaultSelectCada[0]}
                                onChange={(e) => this.handleSelectFormChange('intervaloTemporalCadaTipo', e)}
                                isLoading={state.intervaloTemporalCadaTipoLoading}
                                disabled={state.intervaloTemporalCadaTipoLoading || this.props.action === 'VIEW' || formData.intervaloTemporal === false}
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="row">

                        {/* PREVENTIVO ALERTAR PREVIOS */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="preventivoAlertarPrevios">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_preventivo_alertar_previos" defaultMessage="Alertar:"/>
                            </label>
                            <div className="col-md-8">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.preventivoAlertarPrevios === 0 ? '': formData.preventivoAlertarPrevios }</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" pattern="[0-9]*" id="preventivoAlertarPrevios" name="preventivoAlertarPrevios" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_preventivo_alertar_previos', defaultMessage: 'Sólo números' })} value={formData.preventivoAlertarPrevios === 0 ? '': formData.preventivoAlertarPrevios} onInput={this.handleNumericChange.bind(this)} />
                              </div>
                              )}
                            </div>
                            <label className="col-md-1 label-control col-form-label text-nowrap text-left" htmlFor="preventivoUnidad">
                              {UNIDAD_MEDIDOR[formData.unidadMedidorId]['unidades']}
                            </label>
                          </div>
                        </div>

                        {/* INTERVALO TEMPORAL ALERTAR */}
                        <div className="col-md-5 offset-md-1">
                          <div className="form-group row" >
                            <div className="col-md-3">
                              <label className="label-control col-form-label text-nowrap" htmlFor="intervaloTemporalAlertar">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_intervalo_temporal_alertar" defaultMessage="Alertar:"/>
                              </label>
                            </div>
                            <div className="col-md-5">
                              {this.props.action === 'VIEW'? (
                                <div className="form-control-static col-form-label form-value">{formData.intervaloTemporalAlertar}</div>
                              ) : (
                                <div>
                                  <input type="text" className="form-control" id="intervaloTemporalAlertar" name="intervaloTemporalAlertar" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_intervalo_temporal_alertar', defaultMessage: '0' })}  value={formData.intervaloTemporalAlertar} onInput={this.handleNumericChange.bind(this)} disabled={state.intervaloTemporalCadaTipoLoading || this.props.action === 'VIEW' || formData.intervaloTemporal === false}/>
                                  {/* <div
                                      className="help-block text-danger field-message"
                                      hidden={validationState.formData.alertarHorometro.pristine || validationState.formData.alertarHorometro.valid}>
                                      {validationState.formData.alertarHorometro.message
                                    }</div> */}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <Select
                                  id="intervaloTemporalAlertarTipo"
                                  name="intervaloTemporalAlertarTipo"
                                  placeholder={!state.intervaloTemporalAlertarTipoLoading ? this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_intervalo_temporal_alertar_tipo', defaultMessage: 'Seleccione' }) : ''}
                                  options={defaultSelectCada}
                                  valueKey='id'
                                  labelKey='label'
                                  value={formData.intervaloTemporalAlertarTipo ? defaultSelectCada[formData.intervaloTemporalAlertarTipo] : defaultSelectCada[0]}
                                  onChange={(e) => this.handleSelectFormChange('intervaloTemporalAlertarTipo', e)}
                                  isLoading={state.intervaloTemporalAlertarTipoLoading}
                                  disabled={state.intervaloTemporalAlertarTipoLoading || this.props.action === 'VIEW' || formData.intervaloTemporal === false}
                                />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div> : ''}

                    {/* TIPO TICKET VENCIMIENTO GESTORIA */}
                    { formData.ticketTipo && (formData.ticketTipo.nombre === "VENCIMIENTO" || formData.ticketTipo.nombre === "GESTORIA") ?
                    <div>

                      <div className="row">
                        {/* Vencimiento aplica a */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="vencimientoAplicaA">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_aplica_a" defaultMessage="Aplica a "/>*:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label">
                                  { this.state.formData.vencimientoAplicaA === PERSONA ?  (
                                      <FormattedMessage id="serviciosAbm.render.general_data.aplica_a_persona" defaultMessage="Persona"/>
                                  ) : '' }
                                  
                                  { this.state.formData.vencimientoAplicaA === MOVIL ?  (
                                    <FormattedMessage id="serviciosAbm.render.general_data.aplica_a_movil" defaultMessage="Movil"/>
                                  ) : '' }
                                </div>
                              ) : (
                              <div>
                                <Select
                                  id="vencimientoAplicaA"
                                  name="vencimientoAplicaA"
                                  placeholder={!state.aplicaLoading ? this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_aplica_a', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.aplica_a}
                                  valueKey='id'
                                  labelKey='nombre'
                                  value={formData.vencimientoAplicaA ? this.state.aplica_a.find(e => e.id == formData.vencimientoAplicaA) : null}
                                  onChange={(e) => this.handleSelectFormChange('vencimientoAplicaA', e)}
                                  isLoading={state.aplicaLoading}
                                  disabled={state.aplicaLoading}
                                />
                                <div className="help-block text-danger field-message" hidden={validationState.formData.vencimientoAplicaA.pristine || validationState.formData.vencimientoAplicaA.valid}>{validationState.formData.vencimientoAplicaA.message}</div>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* ALERTA DIAS PREVIOS */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('servicios.alertaDiasPrevios.habilitado') === 'false' ? 'none' : '' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="alertaDiasPrevios">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_primer_alerta" defaultMessage="Primer Alerta (dias antes):"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.alertaDiasPrevios === 0 ? '': formData.alertaDiasPrevios }</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" pattern="[0-9]*" id="alertaDiasPrevios" name="alertaDiasPrevios" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_primer_alerta', defaultMessage: 'Sólo números' })} value={formData.alertaDiasPrevios === 0 ? '': formData.alertaDiasPrevios} onInput={this.handleNumericChange.bind(this)} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* EMISIÓN DIAS PREVIOS */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('servicios.emisionDiasPrevios.habilitado') === 'false' ? 'none' : '' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="emisionDiasPrevios">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_emision" defaultMessage="Emisión (dias antes):"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label form-value">{formData.emisionDiasPrevios === 0 ? '': formData.emisionDiasPrevios }</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" pattern="[0-9]*" id="emisionDiasPrevios" name="emisionDiasPrevios" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_emision', defaultMessage: 'Sólo números' })} value={formData.emisionDiasPrevios === 0 ? '': formData.emisionDiasPrevios}  onInput={this.handleNumericChange.bind(this)} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* Duración del Vencimiento */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('servicios.vencimientoMesesDuracion.habilitado') === 'false' ? 'none' : '' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="vencimientoDuracion">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_duracion_en_meses" defaultMessage="Duración en Meses:"/>
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' ? (
                              <div className="form-control-static col-form-label">{formData.vencimientoDuracion}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" pattern="[0-9]*" id="vencimientoDuracion" name="vencimientoDuracion" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_duracion_en_meses', defaultMessage: 'Sólo números (meses)' })} value={formData.vencimientoDuracion === 0 ? '': formData.vencimientoDuracion}  onInput={this.handleNumericChange.bind(this)} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      :
                      <div className="row">
                      {/* Vencimiento aplica a */}
                      <div className="col-md-6">
                          <div className="form-group row" style={{display: 'none'}}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="vencimientoAplicaA">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_el_vencimiento_aplica_a" defaultMessage="El vencimiento aplica a "/>*:
                            </label>
                            <div className="col-md-9">
                            <Select
                                  id="vencimientoAplicaA"
                                  name="vencimientoAplicaA"
                                  placeholder={!state.aplicaLoading ? this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_el_vencimiento_aplica_a', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.aplica_a}
                                  valueKey='id'
                                  labelKey='nombre'
                                  value={formData.vencimientoAplicaA ? formData.vencimientoAplicaA : null}
                                  onChange={(e) => this.handleSelectFormChange('vencimientoAplicaA', e)}
                                  isLoading={state.aplicaLoading}
                                  disabled={state.aplicaLoading}
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                      }

                      { (formData.ticketTipo && formData.ticketTipo.nombre === "GESTORIA") ? (
                      <div>
                        <div className="row">
                          {/* Gestoria Vencimiento */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label" htmlFor="gestoriaVencimiento">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_tiene_vencimiento" defaultMessage="¿Tiene vencimiento?:"/>
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  onChange={(e) => this.handleEstadoChange('gestoriaVencimiento', e)}
                                  checked={formData.gestoriaVencimiento}
                                  id="gestoriaVencimiento"
                                  name="gestoriaVencimiento"
                                  disabled={this.state.props.action === 'VIEW' ? true: false }
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* Necesita Formulario */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label" htmlFor="necesitaFormulario">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_necesita_formulario" defaultMessage="¿Necesita Formulario?:"/>
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  onChange={(e) => this.handleEstadoChange('necesitaFormulario', e)}
                                  checked={formData.necesitaFormulario}
                                  id="necesitaFormulario"
                                  name="necesitaFormulario"
                                  disabled={this.state.props.action === 'VIEW' ? true: false }
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                />
                              </div>
                            </div>
                          </div>
                          {/* Costo Formulario */}
                          { formData.necesitaFormulario ?
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="costoFormulario">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_costo_formulario" defaultMessage="Costo Formulario "/>*:
                              </label>
                              <div className="col-md-7">
                                {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">{formData.costoFormulario}</div>
                                ) : (
                                <div>
                                  <input type="text" className="form-control" id="costoFormulario" name="costoFormulario" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_costo_formulario', defaultMessage: '0.00' })} value={formData.costoFormulario === 0 ? '' : formData.costoFormulario} onChange={this.handleInputFormChange} />
                                  <div className="help-block text-danger field-message" hidden={validationState.formData.costoFormulario.pristine || validationState.formData.costoFormulario.valid}>{validationState.formData.costoFormulario.message}</div>
                                </div>
                                )}
                              </div>
                            </div>
                          </div> : ''}
                        </div>
                        <div className="row">
                          {/* Certifica Escribania */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label" htmlFor="certificaEscribania">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_necesita_certif_escribanía" defaultMessage="¿Necesita Certif. Escribanía?:"/>
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  onChange={(e) => this.handleEstadoChange('certificaEscribania', e)}
                                  checked={formData.certificaEscribania}
                                  id="certificaEscribania"
                                  name="certificaEscribania"
                                  disabled={this.state.props.action === 'VIEW' ? true: false }
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                />
                              </div>
                            </div>
                          </div>
                          {/* Costo Escribania */}
                          { formData.certificaEscribania ?
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="costoEscribania">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_costo_escribania" defaultMessage="Costo Escribanía "/>*:
                              </label>
                              <div className="col-md-7">
                                {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">{formData.costoEscribania}</div>
                                ) : (
                                <div>
                                  <input type="text" className="form-control" id="costoEscribania" name="costoEscribania" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_costo_escribania', defaultMessage: '0.00' })} value={formData.costoEscribania === 0 ? '' : formData.costoEscribania} onChange={this.handleInputFormChange} />
                                  <div className="help-block text-danger field-message" hidden={validationState.formData.costoEscribania.pristine || validationState.formData.costoEscribania.valid}>{validationState.formData.costoEscribania.message}</div>
                                </div>
                                )}
                              </div>
                            </div>
                          </div> : ''}
                        </div>
                        <div className="row">
                          {/* Necesita Gestoria */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label" htmlFor="necesitaGestoria">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_necesita_gestoría" defaultMessage="¿Necesita Gestoría?:"/>
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  onChange={(e) => this.handleEstadoChange('necesitaGestoria', e)}
                                  checked={formData.necesitaGestoria}
                                  id="necesitaGestoria"
                                  name="necesitaGestoria"
                                  disabled={this.state.props.action === 'VIEW' ? true: false }
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                />
                              </div>
                            </div>
                          </div>
                          {/* Costo Gestoria */}
                          { formData.necesitaGestoria ?
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="costoGestoria">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_costo_gestoria" defaultMessage="Costo Gestoría "/>*:
                              </label>
                              <div className="col-md-7">
                                {this.props.action === 'VIEW' ? (
                                <div className="form-control-static col-form-label form-value">{formData.costoGestoria}</div>
                                ) : (
                                <div>
                                  <input type="text" className="form-control" id="costoGestoria" name="costoGestoria" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_costo_gestoria', defaultMessage: '0.00' })} value={formData.costoGestoria === 0 ? '' : formData.costoGestoria} onChange={this.handleInputFormChange} />
                                  <div className="help-block text-danger field-message" hidden={validationState.formData.costoGestoria.pristine || validationState.formData.costoGestoria.valid}>{validationState.formData.costoGestoria.message}</div>
                                </div>
                                )}
                              </div>
                            </div>
                          </div> : '' }
                        </div>
                        <div className="row">
                          {/* Estado Provisorio */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-4 label-control col-form-label" htmlFor="estadoProvisorio">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_tiene_estado_provisorio" defaultMessage="¿Tiene estado provisorio?:"/>
                              </label>
                              <div className="col-md-8">
                                <Switch
                                  onChange={(e) => this.handleEstadoChange('estadoProvisorio', e)}
                                  checked={formData.estadoProvisorio}
                                  id="estadoProvisorio"
                                  name="estadoProvisorio"
                                  disabled={this.state.props.action === 'VIEW' ? true: false }
                                  offColor="#FF4961"
                                  onColor="#28D094"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> ) : ''}

                      <div className="row">
                        {/* ESTADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_activo" defaultMessage="Activo:"/>
                            </label>
                            <div className="col-md-8">
                              <Switch
                                onChange={(e) => this.handleEstadoChange('activo', e)}
                                checked={formData.activo}
                                options={this.state.estados}
                                id="activo"
                                name="activo"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* ADJUNTOS */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-4 label-control col-form-label" htmlFor="activo">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_adjuntos_obligatorios" defaultMessage="Adjuntos Obligatorios:"/>
                            </label>
                            <div className="col-md-8">
                              <Switch
                                onChange={(e) => this.handleAdjuntoObligatorioChange(e)}
                                checked={formData.adjuntoObligatorio}
                                options={this.state.estados}
                                id="adjuntoObligatorio"
                                name="adjuntoObligatorio"
                                valueKey='id'
                                labelKey='nombre'
                                disabled={this.state.props.action === 'VIEW' ? true: false }
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                        { this.state.formData.adjuntoObligatorio ?
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="categoriaServicio">
                              <FormattedMessage id="serviciosAbm.render.general_data.label_categoria_de_adjunto_obligatorio" defaultMessage="Categoria de adjunto obligatorio "/>*:
                            </label>
                            {this.props.action === 'VIEW' ? (
                              <div className="col-md-6 form-control-static col-form-label form-value">{formData.categoria ? formData.categoria.label : ''}</div>
                              ) : (
                            <div className="col-md-9">
                            <Select
                                  id="categoriaServicio"
                                  name="categoriaServicio"
                                  placeholder={!state.categoriaLoading ? this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_categoria_de_adjunto_obligatorio', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.categoriasAdjuntos}
                                  valueKey='value'
                                  labelKey='label'
                                  value={formData.categoria ? formData.categoria : null}
                                  onChange={(e) => this.handleSelectFormChange('categoria', e)}
                                  isLoading={state.categoriaLoading}
                                  disabled={state.categoriaLoading}
                                />
                            </div>)}
                          </div>
                        </div> : null}
                        
                        
                        
                      </div>
                      
                      {(state.estadoPresupuestadoSV) ? (
                         <div className="row">
                         {/* VALIDACION AUDITOR */}
                         <div className="col-md-6">
                           <div className="form-group row">
                             <label className="col-md-4 label-control col-form-label" htmlFor="validacionAuditor">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_validacion_de_auditor" defaultMessage="Validación de Auditor:"/>
                             </label>
                             <div className="col-md-8">
                               <Switch
                                 onChange={(e) => this.handleEstadoChange('validacionAuditor', e)}
                                 checked={formData.validacionAuditor ? true : false}
                                 id="validacionAuditor"
                                 name="validacionAuditor"
                                 valueKey='id'
                                 labelKey='nombre'
                                 disabled={this.state.props.action === 'VIEW' ? true: false }
                                 offColor="#FF4961"
                                 onColor="#28D094"
                               />
                             </div>
                           </div>
                         </div>
                       </div>
                       ) : (''
                       )}
                    </div>
                  </div>
                </div>


                { (ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.habilitado') === 'true') ?
                <>
                {/* RANGOS */}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <FormattedMessage id="serviciosAbm.render.general_data.header_rangos_de_aprobacion" defaultMessage=" Rangos de Aprobación de Presupuestos "/>
                      </h4>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-6 label-control col-form-label" htmlFor="topeAprobacion">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_monto_max_aprobacion" defaultMessage="Monto Max. Aprobación Automático"/>:
                              </label>
                              <div className="col-md-3">
                                {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label">{formData.topeAprobacion}</div>
                                ) : (
                                  <div>
                                    <input type="text" className="form-control" id="topeAprobacion" name="topeAprobacion" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_monto_max_aprobacion', defaultMessage: 'Monto Max. de Aprobación' })} value={formData.topeAprobacion} onChange={this.handleInputFormChange} />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-6 label-control col-form-label" htmlFor="montoMaximoAprobacionN1">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_aprobacion_n1" defaultMessage="Monto Máximo de Aprobación Nivel 1"/> :
                              </label>
                              <div className="col-md-3">
                                {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label">{formData.montoMaximoAprobacionN1}</div>
                                ) : (
                                  <div>
                                    <input type="text" className="form-control" id="montoMaximoAprobacionN1" name="montoMaximoAprobacionN1" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_monto_maximo_aprobacion_n1', defaultMessage: 'Sin límite' })} value={formData.montoMaximoAprobacionN1} onChange={this.handleInputFormChange} />                              
                                  </div> 
                                )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-6 label-control col-form-label" htmlFor="montoMaximoAprobacionN2">
                                <FormattedMessage id="serviciosAbm.render.general_data.label_aprobacion_n2" defaultMessage="Monto Máximo de Aprobación Nivel 2"/> :
                              </label>
                              <div className="col-md-3">
                                {this.props.action === 'VIEW' ? (
                                  <div className="form-control-static col-form-label">{formData.montoMaximoAprobacionN2}</div>
                                ) : (
                                <div>
                                  <input type="text" className="form-control" id="montoMaximoAprobacionN2" name="montoMaximoAprobacionN2" placeholder={this.props.intl.formatMessage({ id: 'serviciosAbm.render.general_data.placeholder_monto_maximo_aprobacion_n2', defaultMessage: 'Sin límite' })} value={formData.montoMaximoAprobacionN2} onChange={this.handleInputFormChange} />                                
                                </div>
                                )}															
                              </div>
                            </div>
                            <div className="form-group row">
                              <span className="col-md-12 label-control col-form-label" htmlFor="montoMaximoAprobacionN3">
                                <FormattedMessage id="serviciosAbm.render.general_data.span_aprobacion_n3" defaultMessage="* Montos mayores al máximo de Aprobación Nivel 2 serán aprobados por Nivel 3"/>
                              </span>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                </>
                : ''}

                
                



                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid} >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="serviciosAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'serviciosAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'serviciosAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(ServiciosAbm);
