import React, { Component } from "react";
import {injectIntl} from 'react-intl';

class ErrorLoadingService extends Component {
	render() {
	    return (
			<h3
                style={{
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    textAlign: "center"
                }}
            >
                There was an error loading the service. Please try again in a few moments or contact the administrator.
            </h3>
		);
  	}
}

export default injectIntl(ErrorLoadingService);
