import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import $ from 'jquery'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Security from '../../commons/security/Security.js'
import moment from 'moment'
import LimpiarFiltros from '../../assets/images/limpiar-filtros.svg'

import Switch from "react-switch"
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import Loading from '../ui/Loading.js'

import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Config from '../../commons/config/Config.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import CombustiblesImportarReporte from './CombustiblesImportarReporte';
import { atributosDinamicosRequeridosFaltantes } from '../atributoDinamico/helpers.js'
import swal2 from 'sweetalert2'
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class CombustiblesLogGrid extends Component {

	constructor(props) {
		super(props);

		this.ajaxHandler = new AjaxHandler();

		this.dataService = '/combustibles/log/grid';
		this.columnsToPrint = [2, 3, 4];
		moment.locale('es');

		this.state = {
			redirectTo: null,
			reporteImportacion: null,
			loading: false,
			/*dataToExport : {
				fila,
				movil,
				motivo,
				pagina
			},
			dataToExportDetail : {
				archivo_nombre,
				archivo_tipo,
				user,
				fallas
			}*/
		}
	}

	componentDidMount(nextProps, nextState) {
		if (Security.hasPermission('COMBUSTIBLES_LOG_VISUALIZAR')) {
			this.ajaxHandler.subscribe(this);
			this.initGrid();
		} else {
			this.setState({
				redirectTo: '/error'
			});
		}
	}

	initGrid() {
		window.scrollTo(0, 0);
		let component = this;

		let filters = {};
		component.setState({

		});

		this.table = $(this.refs.grid).DataTable(
			Object.assign({
				dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',

				processing: true,
				serverSide: true,
				order: [[1, "desc"]],
				ajax: {
					type: 'GET',
					url: Config.get('apiUrlBase') + this.dataService,
					headers: {
						'Authorization-Token': localStorage.getItem("token")
					},
					dataSrc: function (response) {
						return response.data;
					},
					error: function (xhr, error, thrown) {
						component.ajaxHandler.handleError(error);
					},
					cache: false
				},
				initComplete: function (settings) {
					let stateColumns = null; //JSON.parse(localStorage.getItem('DataTables_' + component.constructor.name)).columns;

					var sets = settings;
					var index = 0;

					this.api().columns().every(function () {
						var column = this;

						if (sets.aoColumns[index].filterType) {
							if (sets.aoColumns[index].filterType === 'select') {
								var select = $('<select class="btn-dt-filter" required><option value="" selected>Buscar...</option></select>')
									.appendTo($(column.footer()).empty())
									.on('change', function () {
										var val = $.fn.dataTable.util.escapeRegex($(this).val());
										column
											.search(val ? val : '', true, false)
											.draw();
									});
								if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
									filters[sets.aoColumns[index].name].map(e => {
										select.append('<option value="' + e.value + '">' + e.label + '</option>');
										return true;
									});
								} else {
									column.data().unique().sort().each(function (d, j) {
										select.append('<option value="' + d + '">' + d + '</option>');
									});
								}
								if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
							}
							if (sets.aoColumns[index].filterType === 'input') {
								var input = $('<input type="text" class="btn-dt-filter" placeholder="Buscar..." />');
								if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
								input.appendTo($(column.footer()).empty());
								input.on('keyup change', function () {
									if (column.search() !== this.value) {
										column
											.search(this.value)
											.draw()
											.ajax.reload(null, false);
									}
								});
							}
						}
						index++;
						return '';
					});

					$('tfoot tr').appendTo('thead');
				},
				columns: [
					{
						title: 'Id',
						name: 'id',
						className: 'hidden',
						filterType: 'input',
					},
					{
						title: "Fecha y Hora",
						name: "created_at",
						className: 'all',
						filterType: 'input',
						render: function (data) {
							return Timezone.getDateForClient(data, null, 'YYYY-MM-DD HH:mm:ss');
						},
						
					},
					{
						title: '',
						name: 'estado',
						width: 3,
						padding: 0,
						orderable: false,
						className: 'p-0 all',
						render: function (data, type, full, meta) {
							return '';
						},
						createdCell: function (td, cellData, rowData, row, col) {
							$(td).attr('data-togle', 'tooltip');
							$(td).attr('data-placement', 'top');
							$(td).attr('title', rowData[6] === 0 ? 'Sin Fallas' : 'Con Fallas');
							$(td).tooltip();
							
							if (rowData[6] === 0) {
								$(td).addClass('status-green');
							} else {
								$(td).addClass('status-red');
							}
						},
						filterType: 'none'
					}, {
						title: 'Usuario',
						className: 'all',
						name: 'userLabel',
						filterType: 'input',
						orderable: false
					}, {
						title: 'Nombre de archivo',
						className: 'all',
						name: 'archivo_nombre',
						filterType: 'input'
					}, {
						title: 'Origen',
						className: 'all',
						name: 'archivo_tipo',
						filterType: 'input'
					}, {
						title: '#Fallas',
						className: 'all',
						name: 'cantidadFallas',
						filterType: 'input',
						orderable: false
					}, {
						orderable: false,
							name: 'actions',
							className: "all text-center",
							width: '120px',
							render: function (data, type, full, meta) {

								let html =`<button ${full[6] === 0 ?'disabled':''} class="action detail btn btn-sm btn-icon btn-dt-grid ${full[6] !== 0 ?'text-success':''}" title="Ver" data-togle="tooltip" data-placement="top">
									<i class="fa fa-search fa-xs"></i>
								</button>
								<button ${full[6] === 0 ?'disabled':''} class="action download btn btn-sm btn-icon btn-dt-grid ${full[6] !== 0 ?'text-success':''}" title="Descargar" data-togle="tooltip" data-trigger="hover" data-placement="top">
									<i class="fa fa-download fa-xs"></i>
								</button>`;
								return html;
							}
						/*Botón de Lupa. Al hacer click en la Lupa, abre ventana popup en donde mostramos todas las falles registradas por el log.
Botón de Descarga. Misma info que la lupa en un excel.
(Ambos botones en verde si el nro de fallas >0, en gris si no tiene falla)*/

					}],
				drawCallback: function () {
					$(this).find('.action').on('click', function () {
						let data = component.table.row($(this).parents('tr')).data();
						
						if($(this).hasClass('detail')) {
							component.getDetail(data[0]);
						}

						if($(this).hasClass('download')) {
							component.exportarDetalle(data[0]);
						}
					});
				},
				rowCallback: function (row, data) {
					if (data.marca) {
						if (data.marca.foto) {
							let photoUrl = Config.get('apiUrlBase') + '/marcas/foto/' + data.marca.foto;
							fetch(photoUrl, {
								method: 'GET',
								headers: {
									'Authorization-Token': localStorage.getItem("token")
								}
							}).then(response => {
								if (response.status === 200) {
									return response.blob();
								} else {
									$('#avatar', row).addClass('dt-user-avatar');
									$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
								}
							}).then(imgBlob => {
								$('#avatar', row).addClass('dt-user-avatar-image');
								$('#avatar', row).css('background-image', 'url(' + URL.createObjectURL(imgBlob) + ')');
							}).catch(() => {
								$('#avatar', row).addClass('dt-user-avatar');
								$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
							});
						} else {
							$('#avatar', row).addClass('dt-user-avatar');
							$('#avatar', row).html(data.marca.nombre.substr(0, 2).toUpperCase());
						}
					} else {
						$('#avatar', row).addClass('dt-user-avatar');
						$('#avatar', row).html('');
					}

					$('.input-km', row).change(function () {
						var input = this;
						var value = this.value;
						var maxDif = component.state.maxDifferenceKM;
						var kmActual = data.km;
						if (Math.abs(value - kmActual) > maxDif) {
							swal({
								title: "Diferencia de Km mayor a " + maxDif + " Km. Esta seguro que desea actualizar?",
								text: "",
								icon: "warning",
								showCancelButton: true,
								buttons: {
									confirm: {
										text: "Aceptar",
										value: true,
										visible: true,
										className: "btn btn-success",
										closeModal: false
									},
									cancel: {
										text: "Cancelar",
										value: null,
										visible: true,
										className: "btn btn-danger",
										closeModal: false,
									}
								}
							}).then(isConfirm => {
								if (isConfirm) {
									$.ajax({
										type: 'PUT',
										url: Config.get('apiUrlBase') + '/moviles/' + data.id + '/km',
										data: '{ "km": ' + value + '}',
										contentType: "application/json; charset=utf-8",
										headers: {
											'Authorization-Token': localStorage.getItem("token")
										},
										dataType: "json",
										success: function (result) {
											component.table.cell(row, 7).data(value);
											input.value = '';
											swal("Se actualizó con Éxito!", "", "success");
										}
									});
								} else {
									swal("Actualización Cancelada!", "", "error");
								}
								input.value = '';
							});
						} else {
							$.ajax({
								type: 'PUT',
								url: Config.get('apiUrlBase') + '/moviles/' + data.id + '/km',
								data: '{ "km": ' + value + '}',
								contentType: "application/json; charset=utf-8",
								headers: {
									'Authorization-Token': localStorage.getItem("token")
								},
								dataType: "json",
								success: function (result) {
									component.table.cell(row, 7).data(value);
									input.value = '';
									swal("Se actualizó con Éxito!", " ", "success");
								}
							});
						}
					});
				}
			}, datatablesConfig)
		);

		new $.fn.dataTable.Buttons(this.table, {
			buttons: [
				{
					name: 'filterReset',
					text: '<img id="filterReset" class="filter-reset" src="' + LimpiarFiltros + '"></i>',
					titleAttr: 'Restaurar filtros y ordenamiento',
					action: function (e, dt, node, config) {
						var index = 0;

						component.setState({ loading: true });

						component.table.columns().every(function () {
							var column = this;

							if (dt.settings()[0].aoColumns[index].filterType) {
								column.search('', true, false);
							}
							index++;
							return null;
						});
						$(dt.table().node()).find('thead tr th *[class$="-filter"]').each((index, element) => {
							$(element).val('');
						});

						dt.table().order([1, 'desc']);
						component.cargarDatosFiltrados().then(() => {
							component.setState({ loading: false });
						});
					}
				},
				{
					extend: 'print',
					text: '<i class="ft-printer"></i>',
					title: '',
					customize: function (win) {
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						} else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css('font-size', '10pt')
							.prepend(`<div class="container">
												<div class="row mb-2">
													<div class="col-6">
														<img style="height: 35px" src="`+ LogoCompany + `" />
													</div>
													<div class="col-6	text-right">
														<img style="height: 40px" src="`+ LogoVecFleet + `" />
													</div>
												</div>
												<div class="row mb-2">
													<div class="col-12" style="border-bottom: 1px solid #000;">
														<h2>Listado de Cargas de Combustible</h2>
													</div>
												</div>
											</div>`);

						$(win.document.body).find('table')
							.addClass('compact')
							.css('font-size', 'inherit');
					},

					exportOptions: {
						columns: component.columnsToPrint
					},

					titleAttr: 'Imprimir'
				}, {
					extend: 'colvis',
					name: 'columns',
					text: '<i class="la la-columns"></i>',
					titleAttr: 'Seleccionar columnas'
				}, {
					name: 'exportExcel',
					text: '<i class="ft-upload"></i>',
					titleAttr: 'Exportar a Excel',
					action: function(e, dt, node, config){
						swal2({
							title: 'Se están exportando los datos.',
							text: 'Esta acción puede demorar',
							buttons: {}
						});

						component.ajaxHandler.fetch('/combustibles/exportar-log',{
							method: 'GET'
						}).then(response => {
							if(response.status === 200){
								return response.blob();
							}
						}).then(fileBlob => {
							let fileUrl = URL.createObjectURL(fileBlob);
							$("<a />", {
								href: fileUrl,
								download: "fleet-combustibles-altas.xlsx"
							}).appendTo("body").get(0).click();
						}).catch(() => {
						}).finally(() => {
							swal2.close();
						});
					}
				},
				{
					text: '<i class="ft-download"></i>',
					titleAttr: 'Importar',
					action: function (e, dt, node, config) {
						$('#import_modal').modal({ backdrop: 'static' });
					}
				}]
		});

		this.table.buttons(0, null).container().appendTo('#buttons');

		$('#dataTable tfoot th').each(function () {
			var title = $(this).text();
			if (title !== '') $(this).html('<input type="text" class="btn-dt-filter" placeholder="Buscar ..." />');
		});

		this.table.columns([3, 4, 5]).every(function () {
			var that = this;
			$('input', this.footer()).on('keyup change', function () {
				if (that.search() !== this.value) {
					that
						.search(this.value)
						.draw()
						.ajax.reload(null, false);
				}
			});
			return null;
		});

		$('.buttons-collection').on("click",function(e){ e.stopPropagation(); 
			if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
				$('.dt-button-collection>.dropdown-menu').css('display','block');
		});
		
	}

	/*getData(service) {
		let serviceURL = Config.get('apiUrlBase') + service;
		return fetch(serviceURL, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization-Token': localStorage.getItem("token")
			}
		})
		.then(response => {
			return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
		});
	}*/

	/*handleExportExcel() {
		let component = this;

		this.setState({ loading: true });
		component.ajaxHandler.fetch('/combustibles/exportar-excel', {
			method: 'GET',
			body: JSON.stringify({
				colsAExportar: this.state.dataToExport
			})
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-combustibles.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			component.handleExportCancel();
			this.setState({ loading: false });
		});
	}

	handleExportCancel() {
		$('#export_modal').modal('toggle');
	}*/

	getDetail(id){
		this.setState({loading:true});

		Promise.all([
			this.ajaxHandler.getJson('/combustibles/log/detail/'+id),
		]).then((data) => {


			this.setState({
				reporteImportacion: data[0] || null
			}, ()=>{
				$('#import_report_modal').modal({ backdrop: 'static' });
				//TODO: reload cuando cierre el modal de reporte 
			});


		}).catch(function (error) {
			this.ajaxHandler.handleError(error);
		}).finally(() => {
			this.setState({
				loading: false
			});
		});

	}

	exportarDetalle(id){
		swal2({
			title: 'Se están exportando los datos.',
			text: 'Esta acción puede demorar',
			buttons: {}
		});

		this.ajaxHandler.fetch('/combustibles/exportar-excel-detail/'+id,{
			method: 'GET'
		}).then(response => {
			if(response.status === 200){
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-combustibles-fallas.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			swal2.close();
		});
	}

	cargarDatosFiltrados(){
		return new Promise((resolve, reject)=>{
			this.table.ajax.reload();
			resolve();
		});
	}

	render() {
		return (
			<React.Fragment>
				<div className="content-wrapper">
					<div className="content-header row">
						<div className="content-header-left col-md-6 col-12 mb-2">
							<h3 className="content-header-title">Log de Altas</h3>
						</div>
					</div>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
				
				<div className="row mt-2">
					<div className="col-12">
						<div className="card">
							<div className="card-content collpase show">
								<div className="card-body card-dashboard">
									<div className="container-fluid">
										<div className="row dt-icons" style={{ marginBottom: "1em" }}>
											<div className="col-6">
											</div>
											<div className="col-6" id="buttons">	</div>
										</div>
									</div>
									<div className="table-responsive">
										<table id="dataTable" className="table nowrap server-side table-hover dataTableMovil" ref="grid" width="100%">
											<tfoot>
												<tr style={{ backgroundColor: '#fff' }}>
													<th className="p-0"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className="dt-search-header"></th>
													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal fade col-8" tabIndex="-1" id="import_modal" role="dialog" aria-labelledby="myModalLabel1" style={{ margin: "auto" }}>
					<div className="modal-xl modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="myModalLabel1">{/*<i className="la la-history align-middle icon-modal-title"></i>*/}Importar</h4>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							
							{/*<div className="modal-footer modal-grey">
						<button type="button" className="btn btn-default btn-fleet" data-dismiss="modal">Ok</button>
						</div>*/}
						</div>
					</div>
				</div>
				<div className="modal fade col-8" tabIndex="-1" id="import_report_modal" role="dialog" aria-labelledby="importReportLabel" style={{ margin: "auto" }}>
					<div className="modal-lg modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="importReportLabel">Reporte de la Importación</h4>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<div className="modal-body modal-grey modal-mh ovf-x-hidden">
								{this.state.reporteImportacion ? 
								<CombustiblesImportarReporte data={this.state.reporteImportacion} callbackReportClose={this.handleReportCloseModal}></CombustiblesImportarReporte>
								: null}
							</div>
							<div className="modal-footer modal-grey">
								<button type="button" className="btn btn-default btn-fleet" data-dismiss="modal">Ok</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			</React.Fragment>
		);
	}
}

export default CombustiblesLogGrid;
