import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import * as Utils from '../../commons/utils/Utils';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  TablePagination,
  Collapse,
  TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { StyledTableCell, rowsPerPage } from '../../commons/utils/TableStyles';
import Sort from "../../assets/images/llantas/table/sort.svg";
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";


const PAGINATION_INIT = {
  page: 0,
  count: 0,
  perPage: 10,
};

const FILTER_QUERY_INIT = {
  nombre: '',
}

const SORT_QUERY_INIT = {
  nombreSort: '',
  descripcionSort: '',
};

const LlantaMarcaGrid = (props) => {
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [pagination, setPagination] = useState({ ...PAGINATION_INIT });
  const [paginationCount, setPaginationCount] = useState(0);
  const [filterQuery, setFilterQuery] = useState({ ...FILTER_QUERY_INIT });
  const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT });
  const [dropdownActive, setDropdownActive] = useState(false);
  const [marcas, setMarcas] = useState(null);

  useEffect(() => {
    setLoading(true);
    // Fetch Marcas when page changes
    fetchMarcas();
  }, [pagination]);

  useEffect(() => {
    setLoading(true);
    // Fetch Marcas when page changes
    fetchMarcas();
  }, [filterQuery, sortQuery]);

  const fetchMarcas = () => {
    Utils.getData(`/llantamarcas/grid${getQuery()}`)
      .then((res) => {
        setMarcas(res.marcas);
        setLoading(false);
        setFiltersLoading(false);
        setPaginationCount(res.pagination.count);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setFiltersLoading(false);
      });
  };

  const changePage = (e, newPage) => {
    setLoading(true);
    setPagination({ ...pagination, page: newPage });
  };

  const changeRowsPerPage = (event) => {
    setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
  }

  const filter = (name, value) => {
    setLoading(true);
    setFiltersLoading(true);
    value !== '' && setSearchActive(true);

    let query = { ...filterQuery };
    query[name] = value;
    setFilterQuery(query);
  };

  const getQuery = () => {
    // Paginado
    let query = '?page=' + pagination.page;
    query += "&perPage=" + pagination.perPage;

    // Filtros
    query += '&nombre=' + filterQuery.nombre;

    // Ordenamiento
    query += '&nombreSort=' + sortQuery.nombreSort;
    query += '&descripcionSort=' + sortQuery.descripcionSort;

    return query;
  };

  const handleSort = (key, value) => {
    let query = { ...sortQuery };

    query['nombreSort'] = '';
    query['descripcionSort'] = '';

    query[key] = (value === 'asc' || value === '') ? 'desc' : 'asc';

    setSortQuery(query);
  }

  const handleFilterReset = () => {
    setPagination(PAGINATION_INIT);
    setFilterQuery(FILTER_QUERY_INIT);
    setSortQuery(SORT_QUERY_INIT);
    setDropdownActive(false);
    setSearchActive(false);
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <div className="card">
        <div className="card-content">
          <div className="card-body">
            <div className="row" style={{ marginBottom: '-20px' }}>
              <Tooltip
                title={intl.formatMessage({
                  id: 'llantaMarcasGrid.button.label_add',
                  defaultMessage: 'Agregar',
                })}
                arrow
                placement="right"
              >
                <div
                  className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  style={{ height: 55 }}
                  onClick={() => setRedirectTo(props.match.url + '/add')}
                >
                  <i className="ft-plus"></i>
                </div>
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: 'llantaMarcasGrid.button.label_filtros',
                  defaultMessage: 'Filtros',
                })}
                arrow
                placement="right"
              >
                <div
                  className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                  style={{ height: 52 }}
                  onClick={() => setDropdownActive(!dropdownActive)}
                >
                  {dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
                </div>
              </Tooltip>
              {searchActive && (
                <div className="btn-dt-main round-icon">
                  <div
                    className="text-center"
                    style={{
                      height: '25px',
                      borderRadius: '3px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: '8px',
                      backgroundColor: '#5AAC43',
                      color: 'white',
                    }}
                  >
                    <div
                      className="text-center"
                      style={{
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      {marcas.length} de {paginationCount} resultados de busqueda
                    </div>
                    <div className="resultados-busqueda-notif">
                      <i
                        className="las la-times la-xs cursor-pointer"
                        onClick={handleFilterReset}
                      ></i>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Filtros
              dropdownActive={dropdownActive}
              filtersLoading={filtersLoading}
              filter={filter}
              searchActive={searchActive}
              handleFilterReset={handleFilterReset}
            />
            <TableContainer>
              <Table aria-label="clases table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left"></StyledTableCell>
                    <StyledTableCell align="left" onClick={(e) => handleSort('nombreSort', sortQuery.nombreSort)} style={{ cursor: "pointer" }}>
                      Nombre
                      <img className="ml-1"
                        src={sortQuery.nombreSort === "" ? Sort : sortQuery.nombreSort === "asc" ? SortAsc : SortDesc}
                        alt={"/"}
                        style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" onClick={(e) => handleSort('descripcionSort', sortQuery.descripcionSort)} style={{ cursor: "pointer" }}>
                      Descripcion
                      <img className="ml-1"
                        src={sortQuery.descripcionSort === "" ? Sort : sortQuery.descripcionSort === "asc" ? SortAsc : SortDesc}
                        alt={"/"}
                        style={{ height: "12px", width: "22px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <StyledTableCell colSpan={4} align="center">
                        Cargando
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {!loading &&
                    marcas &&
                    marcas.map((marca) => (
                      <TableRow key={marca.name} key={'llantaMarcasGridRow_' + marca.id}>
                        <StyledTableCell align="left" style={{ maxWidth: 10 }}>
                          <Logo marca={marca} />
                        </StyledTableCell>
                        <StyledTableCell align="left">{marca.nombre}</StyledTableCell>
                        <StyledTableCell align="left">
                          {marca.descripcion ? marca.descripcion : ''}
                        </StyledTableCell>
                        <StyledTableCell align="right" style={{ maxWidth: 50 }}>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0 mx-1"
                              onClick={() => setRedirectTo(props.match.url + '/' + marca.id)}
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'llantaMarcasGrid.button.label_view',
                                  defaultMessage: 'Ver',
                                })}
                                arrow
                              >
                                <i className="fa fa-search fa-xs"></i>
                              </Tooltip>
                            </button>
                            <button
                              className="btn btn-sm btn-icon btn-dt-grid text-success p-0 mx-1"
                              onClick={() =>
                                setRedirectTo(props.match.url + '/' + marca.id + '/edit')
                              }
                            >
                              <Tooltip
                                title={intl.formatMessage({
                                  id: 'llantaMarcasGrid.button.label_edit',
                                  defaultMessage: 'Editar',
                                })}
                                arrow
                              >
                                <i className="fa fa-pencil fa-xs"></i>
                              </Tooltip>
                            </button>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={paginationCount}
              rowsPerPage={pagination.perPage}
              page={pagination.page}
              onChangePage={changePage}
              onChangeRowsPerPage={changeRowsPerPage}
              labelRowsPerPage="Mostrar"
              labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

function Logo(props) {
  const [error, setError] = useState(false);
  return (
    <>
      {!error ? (
        <img
          className="dt-user-avatar-image"
          src={process.env.PUBLIC_URL + '/images/llantas/marcas-logo/' + props.marca.logo}
          onError={() => setError(true)}
        />
      ) : (
        <div className="dt-user-avatar">{props.marca.nombre.substr(0, 2).toUpperCase()}</div>
      )}
    </>
  );
}

function Filtros(props) {
  const FILTER_FIELDS_INIT = {
    nombre: '',
  }

  const intl = useIntl();
  const [filtersState, setFiltersState] = useState({ ...FILTER_FIELDS_INIT });

  useEffect(() => {
    !props.searchActive && setFiltersState(FILTER_FIELDS_INIT);
  }, [props.searchActive]);

  const handleMaterialChange = (name, value) => {
    props.filter(name, value);
  };

  const handleOnEnterSerialChange = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleMaterialChange(name, value);
    }
  };

  return (
    <>
      <Collapse in={props.dropdownActive}>
        <div className="row mb-1">
          <div className="col-md-10">
            <div className="row" style={{ width: '100%' }}>
              <div className="col-md-4">
                <form noValidate autoComplete="off">
                  <TextField
                    id="standard-basic"
                    label={intl.formatMessage({
                      id: 'llantasGrid.render.filtros_nombre.label',
                      defaultMessage: 'Nombre',
                    })}
                    name="nombre"
                    value={filtersState.nombre}
                    onChange={(e) => setFiltersState({ ...filtersState, nombre: e.target.value })}
                    onKeyDown={(e) => handleOnEnterSerialChange(e, 'nombre', filtersState.nombre)}
                    onBlur={(e) => handleMaterialChange('nombre', filtersState.nombre)}
                    disabled={props.filtersLoading}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default LlantaMarcaGrid;
