import React, { Component } from 'react'
import axios from 'axios'
import Config from '../../../commons/config/Config.js'
import InstanciaBasic from './InstanciaBasic.js'

const configAxios = {
	headers: {
		'Authorization-Token': localStorage.getItem("token")
	}
};


class InstanciaBasicConAdjunto extends InstanciaBasic {
	constructor(props) {
		super(props);
		this.props = props;
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChangeAndUpdateFather = this.handleChangeAndUpdateFather.bind(this);
		this.handleOpenAdjunto = this.handleOpenAdjunto.bind(this);
		this.castValue = this.castValue.bind(this);
		this.state = {
			adjunto: null,
			adjunto_url: null,
			adjuntoArray: [],
			adjuntoUrlArray: [],
		}

	}

	castValue(value) {
		if (typeof(value) !== 'object')
			return []
		else 
			return value
	}

	handleInputChange(event) {
		// El input debe tener el atributo name para que funcione

		let files = event.target.files
		let idArray = [];
		let count = 0;

		for (let i = 0; i < files.length; i++) {
			let file = files[i]
			let fd = new FormData();
			fd.append("file", file);
			axios.post(Config.get('apiUrlBase') + '/adjuntos', fd, configAxios).then(response => {
				// this.handleChangeAndUpdateFather(response.data.id)
				idArray.push(response.data.id);
				count++
				if (count === files.length) {
					this.handleChangeAndUpdateFather(idArray, true)
				}
			})
		}
	}

	handleChangeAndUpdateFather(adjunto_id) {
		let config = Object.assign({}, configAxios, { responseType: 'blob' });
		if (adjunto_id) {
			this.props.handleChange(this.valueKey, adjunto_id)
			let count = 0;
			let adjArr = [];
			let adjUrlArr = [];
			adjunto_id.forEach((id, i) => {
				Promise.all([
					axios.get(Config.get('apiUrlBase') + '/adjuntos/' + id, configAxios),
					axios.get(Config.get('apiUrlBase') + '/adjuntos/files/' + id, config)
				]).then((data) => {
					adjArr.push(data[0].data);
					let fileUrl = URL.createObjectURL(data[1].data);
					adjUrlArr.push(fileUrl)
					count++;
					if (count === adjunto_id.length) {
						this.setState({ adjuntoArray: adjArr, adjuntoUrlArray: adjUrlArr })
					}
				}).catch(e => console.log(e))
			})
		}
		else {
			this.setState({
				adjuntoArray: [],
				adjuntoUrlArray: []
			}, () => {
				this.props.handleChange(this.valueKey, []);
			});
		}
	}

	handleOpenAdjunto(e, url) {
		window.open(url)
		e.preventDefault()
	}

	render() {
		return false
	}
}

export default InstanciaBasicConAdjunto;
