import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import * as Utils from "../../commons/utils/Utils";
import Select from 'react-select';

const TipoGastoExtraAbm = (props) => {

  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(null);
  const formContainer = useRef("form");
  const [formData, setFormData] = useState(
	  { nombre: "", descripcion: "", activo: 0}
	);

  useEffect(() => {
    props.action === "VIEW" || props.action === "EDIT" ? loadFormData() : initBlank();
  }, []);

  const initBlank = () => {};
  const loadFormData = () => {
    Utils.getData("/tipo-gasto-extra/" + props.match.params.id).then((res) => {
		// setLoading(true);
		console.log(res)
		setFormData(res);
    });
  };

  const handleFormChange = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy[e.target.name] = e.target.value;
    setFormData(formDataCopy);
  };

  const handleActivoChange = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy['activo'] = e.value;
    setFormData(formDataCopy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Utils.sendData(
      "/tipo-gasto-extra" + (props.action === "EDIT" ? "/" + props.match.params.id : ""),
      props.action === "EDIT" ? "PUT" : "POST",
      formData
    ).then((res) => {
      setRedirectTo("/tipoGastoExtra");
    });
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <form className="form form-horizontal" ref={formContainer}>
        <div className="form-body">
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <h4 className="form-section">
                  <i className="icon-home"></i>
                  <FormattedMessage
                    id="gastoExtraAbm.render.general_data.header_datos_generales"
                    defaultMessage=" Datos Generales "
                  />
                  <div className="float-right" style={{ fontSize: "14px" }}>
                    <FormattedMessage
                      id="gastoExtraAbm.render.general_data.campos_requeridos"
                      defaultMessage="* campos requeridos"
                    />
                  </div>
                </h4>
                {/* Nombre */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="nombre">
                        <FormattedMessage id="gastoExtraAbm.render.general_data.labelnombre" defaultMessage="Nombre *:" />
                      </label>
                      <div className="col-md-9">
						{props.action === "VIEW" ? (
                          <div className="form-control-static col-form-label form-value">
                            {formData.nombre}
                          </div>
                        ) : (
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              id="nombre"
                              name="nombre"
                              placeholder={props.intl.formatMessage({
                                id: "gastoExtraAbm.render.general_data.placeholder_nombre",
                                defaultMessage: "Nombre",
                              })}
                              value={formData.nombre}
                              onChange={(e) => handleFormChange(e)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
				{/* Descripcion */}
				<div className="row">
					<div className="col-md-6">
						<div className="form-group row">
						<label className="col-md-3 label-control col-form-label" htmlFor="descripcion">
							<FormattedMessage
							id="gastoExtraAbm.render.general_data.label_descripcion"
							defaultMessage="Descripcion *:"
							/>
						</label>
						<div className="col-md-9">
						{props.action === "VIEW" ? (
                          <div className="form-control-static col-form-label form-value">
                            {formData.descripcion}
                          </div>
                        ) : (
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              id="descripcion"
                              name="descripcion"
                              placeholder={props.intl.formatMessage({
                                id: "gastoExtraAbm.render.general_data.placeholder_descripcion",
                                defaultMessage: "Descripcion",
                              })}
                              value={formData.descripcion}
                              onChange={(e) => handleFormChange(e)}
                            />
                          </div>
                        )}
						</div>
						</div>
					</div>
				</div>
				{/* Activo */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="activo">
                        <FormattedMessage
                          id="gastoExtraAbm.render.general_data.label_activo"
                          defaultMessage="Activo *:"
                        />
                      </label>
                      <div className="col-md-9">
                        {props.action === "VIEW" ? (
                          <div className="form-control-static col-form-label form-value">
                            {formData.activo}
                          </div>
                        ) : (
							<div className="col-md-9">
							<Select
								name="activo"
								value={formData.activo == 1 ? { value: 1, label: 'Si' } : { value: 0, label: 'No' }}
								valueKey="id"
								labelKey="label"
								options={[{ value: 1, label: 'Si' }, { value: 0, label: 'No' }]}
								onChange={(e) => handleActivoChange(e)}
								disabled={props.action === "VIEW"}
								/>
							</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <div className="text-cd text-right">
                  {props.action !== "VIEW" && (
                    <button className="btn btn-primary mr-1" onClick={handleSubmit}>
                      <i className="fa fa-check-circle"></i>
                      <FormattedMessage id="modelosAbm.finish_button.guardar" defaultMessage=" Guardar" />
                    </button>
                  )}
                  <button type="button" className="btn btn-danger" onClick={() => setRedirectTo("/tipoGastoExtra")}>
                    <i className="fa fa-times-circle"></i>{" "}
                    {props.action === "VIEW"
                      ? props.intl.formatMessage({
                          id: "modelosAbm.finish_button.volver",
                          defaultMessage: "Volver",
                        })
                      : props.intl.formatMessage({
                          id: "modelosAbm.finish_button.cancelar",
                          defaultMessage: "Cancelar",
                        })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default injectIntl(TipoGastoExtraAbm);
