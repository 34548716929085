import React, { Component } from "react";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Security from "../../commons/security/Security.js";
import Config from "../../commons/config/Config.js";
import ConfigBusiness from "../../commons/config/ConfigBusiness.js";
import TicketDatosCierreVencimiento from "./TicketDatosCierreVencimiento.js";
import TicketTareasCierre from "./TicketTareasCierre.js";
import moment from "moment";
import "moment/min/locales";
import $ from "jquery";
import swal from "sweetalert2";
import Dialog from "../../commons/dialog/Dialog.js";
import EnTallerRojoImage from "../../assets/images/moviles/herramienta-rojo.svg";
import EnTallerVerdeImage from "../../assets/images/moviles/herramienta-verde.svg";
import EnTallerGrisImage from "../../assets/images/moviles/herramienta-gris.svg";
import TicketGarantiaImage from "../../assets/images/moviles/ticket-garantia.svg";
import TicketPendienteImage from "../../assets/images/moviles/ticket-pendiente.png";

import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import '../../assets/css/encuesta-satisfaccion-tickets.css';
import TicketAlertaChecklist from "../../assets/images/moviles/ticket-alerta.svg";
import Timezone from "../../commons/timezone/Timezone.js";


class TicketHeader extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.ajaxHandler = new AjaxHandler();
    this.serviciosNoautogestionables = [];
    this.configAdjuntosObligatorios = null;
    this.configEncuestaSatisfaccion = null;

    moment.locale("es");

    this.state = {
      fotoMarcaUrl: null,
      comentarioCancelacion: "test",
      fechaCancelacion: null,
      modalDatosCierreVencimientoKey: 0,
      modalTareasCierreKey: 0,
      encuestaNivelSatisfaccion:null,
      encuestaComentariosSatisfaccion:'',
      formDataProximoVencimiento:null,
      errors: [],
      alertaChecklistHabilitado: ConfigBusiness.get("tickets.alertaCheckList.habilitado") == 'true' ? true : false,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.init();
  }

  componentDidUpdate() {
    $('[data-tooltip="tooltip"]').tooltip();
  }

  init() {
    this.ajaxHandler.subscribe(this);
    let ticket = this.props.ticket;
    let serviciosNoautogestionables = ConfigBusiness.get(
      "servicios.noAutogestionables"
    ).split(",");

    this.configAdjuntosObligatorios = ConfigBusiness.get(
      "tickets.cambiosEstados"
    );
    this.configEncuestaSatisfaccion = ConfigBusiness.get("tickets.solicitarEncuestaSatisfaccion");

    if (
      ticket &&
      ticket.movil &&
      ticket.movil.marca &&
      ticket.movil.marca.foto
    ) {
      let component = this;
      if(ticket.movil.marca.codigo){
      let photoUrl =
        process.env.PUBLIC_URL + "/images/marcas/" + ticket.movil.marca.codigo+".png";
        component.setState({
          fotoMarcaUrl: photoUrl,
        });
      }
      /**fetch(photoUrl, {
        method: "GET",
        headers: {
          "Authorization-Token": localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.status === 200) {
          response.blob().then((imageBlob) => {
            component.setState({
              fotoMarcaUrl: URL.createObjectURL(imageBlob),
            });
          });
        }
      });*/
    }
  }

  canDo(action) {
    return this.props.actions.indexOf(action) >= 0;
  }

  componentWillMount(){
    this.checkAprobarPresupuesto();
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
    this.ajaxHandler.unsubscribe();
  }

  checkAprobarPresupuesto(){
    const total = this.props.ticket.repuestos + this.props.ticket.manoDeObra + this.props.ticket.impuestos + this.props.ticket.otros;
    const n1 = this.props.ticket.servicio.montoMaximoAprobacionN1;
    const n2 = this.props.ticket.servicio.montoMaximoAprobacionN2;

    if (ConfigBusiness.get('tickets.trabajaConNivelesDeAprobacion.habilitado') === 'true') {
      if(Security.hasPermission("PERMISO_APRO_N1") && total <= n1) {
        return true;
      } else if (Security.hasPermission("PERMISO_APRO_N2") && total <= n2) {
        return true;
      } else if (Security.hasPermission("PERMISO_APRO_N3")) {
        return true;
      } else {
        return false;
      }
    } else {
      //No Trabaja con niveles de aprobacion
      return true;
    }
  };
  showEntityAvatar() {
    let ticket = this.props.ticket;
    if (ticket.movil && ticket.movil.marca)
      return ticket.movil.marca.nombre.substr(0, 2).toUpperCase()

    if (ticket.persona)
      return ticket.persona.nombre.substr(0, 1).toUpperCase() +
             " " +
             ticket.persona.apellido.substr(0, 1).toUpperCase()

    if (ticket.llanta)
      return ticket.llanta.llanta_marca.nombre.substr(0, 2).toUpperCase()
  }
  showEntityName() {
    let ticket = this.props.ticket;
    if (ticket.movil)
      return ticket.movil.dominio

    if (ticket.persona)
      return ticket.persona.nombre + " " + ticket.persona.apellido

    if (ticket.llanta)
      return ticket.llanta.serial
  }

  handleAprobar() {
    this.ajaxHandler
      .fetch("/tickets/aprobar/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleAprobarAuditor() {
    let error = null;
    if (
      this.configAdjuntosObligatorios && this.configAdjuntosObligatorios.APROBAR_AUDITOR &&
      this.configAdjuntosObligatorios.APROBAR_AUDITOR.obligatorio === "true"
    ) {
      let categoriaId = this.configAdjuntosObligatorios.APROBAR_AUDITOR
        .categoria;

      if (categoriaId) {
        if (
          this.props.adjuntos &&
          !this.props.adjuntos.filter(
            (c) => c.id === parseInt(categoriaId) && c.adjuntos.length
          ).length
        ) {
          error = this.props.intl.formatMessage({
            id:
              "ticketHeader.aprobar_auditor.error_categoria_adjuntos_faltantes",
            defaultMessage:
              "Para aceptar el presupuesto debe ingresar un adjunto para la categoría Informe Auditor.",
          });
        }
      } else {
        error = this.props.intl.formatMessage({
          id: "ticketHeader.aprobar_auditor.error_obtener_categoria_adjuntos",
          defaultMessage:
            "No se pudo obtener la categoría. Contacte al administrador",
        });
      }
    }

    if (error) {
      Dialog.alert(this.props.intl, {
        title: this.props.intl.formatMessage({
          id: "ticketHeader.aprobar_auditor.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      this.ajaxHandler
        .fetch("/tickets/aprobar-auditor/" + this.props.ticket.id, {
          method: "POST",
        })
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleAprobarAbono() {
    this.ajaxHandler
      .fetch("/tickets/aprobar-abono/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleRechazarAbono() {
    this.ajaxHandler
      .fetch("/tickets/rechazar-abono/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleCancelar() {
    swal({
      input: "textarea",
      inputPlaceholder: this.props.intl.formatMessage({
        id: "ticketHeader.modal_cancel.label_ingrese_un_comentario",
        defaultMessage:
          "Por favor, ingrese un comentario acerca de la cancelación",
      }),
      showCancelButton: true,
      cancelButtonText: this.props.intl.formatMessage({
        id: "perfilesGrid.modal_cancel.button_cancelar",
        defaultMessage: "Cancelar",
      }),
      confirmButtonText: this.props.intl.formatMessage({
        id: "perfilesGrid.modal_cancel.button_ok",
        defaultMessage: "Ok",
      }),
      inputValidator: function (value) {
        return !value && "Campo requerido";
      },
    }).then((result) => {
      if (result.value) {
        this.ajaxHandler
          .fetch("/tickets/cancelar/" + this.props.ticket.id, {
            method: "POST",
            body: JSON.stringify({
              comentario: result.value,
            }),
          })
          .then((response) => {
            if (response.status === 204) {
              this.props.callbackDataUpdate();
            } else {
              response.json().then((data) => {
                this.setState({
                  errors: data.detalle,
                });
              });
            }
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            this.ajaxHandler.handleError(error);
          });
      }
    });
  }

  handleCerrar(e) {
    e.preventDefault();
    $("#encuesta_satisfaccion_modal").modal("hide");
    if (this.props.ticket.llanta && this.props.ticket.id == this.props.ticket.llanta.ticket_id) {
      this.props.redirectLlanta(this.props.ticket);
    } else {
      let error = null;
      if (this.props.ticket.servicio.adjuntoObligatorio) {
        let categoriaId = this.props.ticket.servicio.categoria.id;
        if (
          this.props.adjuntos &&
          !this.props.adjuntos.filter(
            (c) => c.id === categoriaId && c.adjuntos.length
          ).length
        ) {
          error =
            this.props.intl.formatMessage({
              id:
                "ticketHeader.close_tickets.error_ingresar_al_menos_un_adjunto",
              defaultMessage:
                "Parar cerrar el ticket debe ingresar al menos un adjunto para la categoría ",
            }) +
            this.props.ticket.servicio.categoria.nombre +
            ".";
        }
      }

      if (error) {
        Dialog.alert(this.props.intl, {
          title: this.props.intl.formatMessage({
            id: "ticketHeader.close_tickets.error_adjuntos_faltantes",
            defaultMessage: "Adjuntos faltantes",
          }),
          text: error,
        });
      } else {
        if (this.props.ticket.ticketTipo === "VENCIMIENTO") {
          let modalDatosCierreVencimientoKey = this.state.modalDatosCierreVencimientoKey + 1;
          this.setState(
            {
              modalDatosCierreVencimientoKey: modalDatosCierreVencimientoKey,
            },
            () => {
              $("#datos_vencimiento_modal").modal({ backdrop: "static" });
            }
          );
        } else {
          this.ajaxHandler
            .fetch("/tickets/cerrar/" + this.props.ticket.id, {
              method: "POST",
              body: JSON.stringify({
                encuestaNivelSatisfaccion:this.state.encuestaNivelSatisfaccion,
                encuestaComentariosSatisfaccion:this.state.encuestaComentariosSatisfaccion,
              }),
            })
            .then((response) => {
              if (response.status === 204) {
                this.props.callbackDataUpdate();
              } else {
                response.json().then((data) => {
                  this.setState({
                    errors: data.detalle,
                  });
                });
              }
              window.scrollTo(0, 0);
            })
            .catch((error) => {
              this.ajaxHandler.handleError(error);
            });
        }
      }
    }
  }

  handleCerrarGestoria(e) {
    e.preventDefault();
    let error = null;
    if (this.props.ticket.servicio.adjuntoObligatorio) {
      let categoriaId = this.props.ticket.servicio.categoria.id;
      if (
        this.props.adjuntos &&
        !this.props.adjuntos.filter(
          (c) => c.id === categoriaId && c.adjuntos.length
        ).length
      ) {
        error =
          this.props.intl.formatMessage({
            id:
              "ticketHeader.cerrarGestoria.error_ingresar_al_menos_un_adjunto",
            defaultMessage:
              "Parar cerrar el ticket debe ingresar al menos un adjunto para la categoría ",
          }) +
          this.props.ticket.servicio.categoria.nombre +
          ".";
      }
    }

    if (error) {
      Dialog.alert(this.props.intl, {
        title: this.props.intl.formatMessage({
          id: "ticketHeader.cerrarGestoria.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      this.ajaxHandler
        .fetch("/tickets/cerrar-gestoria/" + this.props.ticket.id, {
          method: "POST",
        })
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleRecotizarAuditor() {
    let error = null;

    //HACK
    let categoriaId = 8;

    if (
      this.props.adjuntos &&
      !this.props.adjuntos.filter(
        (c) => c.id === categoriaId && c.adjuntos.length
      ).length
    ) {
      error = this.props.intl.formatMessage({
        id: "ticketHeader.recotizarAuditor.error_ingresar_un_adjunto",
        defaultMessage:
          "Parar enviar a Recotizar el presupuesto debe ingresar un adjunto para la categoría Informe Auditor.",
      });
    }

    if (error) {
      Dialog.alert(this.props.intl, {
        title: this.props.intl.formatMessage({
          id: "ticketHeader.recotizarAuditor.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      this.ajaxHandler
        .fetch(
          "/tickets/solicitar-recotizacion-auditor/" + this.props.ticket.id,
          {
            method: "POST",
          }
        )
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleRecotizar() {
    this.props.intl.formatMessage({
      id: "ticketHeader.recotizarAuditor.error_ingresar_un_adjunto",
      defaultMessage:
        "Parar enviar a Recotizar el presupuesto debe ingresar un adjunto para la categoría Informe Auditor.",
    });
    this.ajaxHandler
      .fetch("/tickets/solicitar-recotizacion/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleListoParaRetirar(e) {
    e.preventDefault();
    if (ConfigBusiness.get("tickets.tareasCierre.habilitado") === "true") {
      let modalTareasCierreKey = this.state.modalTareasCierreKey + 1;
      this.setState(
        {
          modalTareasCierreKey: modalTareasCierreKey,
        },
        () => {
          $("#tareas_cierre_modal").modal({ backdrop: "static" });
        }
      );
    } else {
      this.ajaxHandler
        .fetch("/tickets/listo-para-retirar/" + this.props.ticket.id, {
          method: "POST",
        })
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleEnviarAReparar() {
    this.ajaxHandler
      .fetch("/tickets/enviar-a-reparar/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleConfirmarReparacion() {
    let error = null;

    if (this.props.ticket.servicio.nombre === "GRABADO DE AUTOPARTES") {
      //HACK
      let categoriaId = 5;

      if (
        this.props.adjuntos &&
        !this.props.adjuntos.filter(
          (c) => c.id === categoriaId && c.adjuntos.length
        ).length
      ) {
        error = this.props.intl.formatMessage({
          id: "ticketHeader.confirmarReparacion.error_ingresar_un_adjunto",
          defaultMessage:
            "Parar enviar a Listo Para Retirar debe ingresar un adjunto para la categoría Grabado de Autopartes.",
        });
      }
    }

    if (error) {
      Dialog.alert(this.props.intl, {
        title: this.props.intl.formatMessage({
          id: "ticketHeader.confirmarReparacion.error_adjuntos_faltantes",
          defaultMessage: "Adjuntos faltantes",
        }),
        text: error,
      });
    } else {
      this.ajaxHandler
        .fetch("/tickets/confirmar-reparacion/" + this.props.ticket.id, {
          method: "POST",
        })
        .then((response) => {
          if (response.status === 204) {
            this.props.callbackDataUpdate();
          } else {
            response.json().then((data) => {
              this.setState({
                errors: data.detalle,
              });
            });
          }
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          this.ajaxHandler.handleError(error);
        });
    }
  }

  handleAdicional() {
    this.ajaxHandler
      .fetch("/tickets/solicitar-adicional/" + this.props.ticket.id, {
        method: "POST",
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleDatosCierreVencimientoSave(obj) {
    let formData = this.state.formDataProximoVencimiento ? this.state.formDataProximoVencimiento : obj
    $("#encuesta_satisfaccion_modal").modal("hide");
    this.ajaxHandler
      .fetch("/tickets/cerrar/" + this.props.ticket.id, {
        method: "POST",
        body: JSON.stringify({
          ...formData,
          encuestaNivelSatisfaccion:this.state.encuestaNivelSatisfaccion,
          encuestaComentariosSatisfaccion:this.state.encuestaComentariosSatisfaccion,
        }),
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleDatosCierreVencimientoClose() {
    $("#datos_vencimiento_modal").modal("toggle");
  }

  handleTareasCierreSave(obj) {
    $("#tareas_cierre_modal").modal("toggle");
    this.ajaxHandler
      .fetch("/tickets/listo-para-retirar/" + this.props.ticket.id, {
        method: "POST",
        body: JSON.stringify({
          ...obj,
        }),
      })
      .then((response) => {
        if (response.status === 204) {
          this.props.callbackDataUpdate();
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.ajaxHandler.handleError(error);
      });
  }

  handleTareasCierreClose() {
    $("#tareas_cierre_modal").modal("toggle");
  }

  canClose() {
    let ticket = this.props.ticket;
    let canClose = false;
    if (ticket.estado === "PROVISORIO" && ticket.ot.servicio.estadoProvisorio) {
      canClose = true;
    } else if (
      ticket.estado === "EN_GESTION" &&
      ticket.ot.servicio.necesitaGestoria &&
      !ticket.ot.servicio.estadoProvisorio
    ) {
      canClose = true;
    } else if (
      ticket.estado === "EN_ESCRIBANIA" &&
      ticket.ot.servicio.certificaEscribania &&
      !ticket.ot.servicio.necesitaGestoria &&
      !ticket.ot.servicio.estadoProvisorio
    ) {
      canClose = true;
    } else if (
      ticket.estado === "EN_FORMULARIO" &&
      ticket.ot.servicio.necesitaFormularios &&
      !ticket.ot.servicio.certificaEscribania &&
      !ticket.ot.servicio.necesitaGestoria &&
      !ticket.ot.servicio.estadoProvisorio
    ) {
      canClose = true;
    }
    return canClose;
  }


  handleEncuestaSatisfaccion(e){
    let ticket = this.props.ticket
    let configEncuesta = this.configEncuestaSatisfaccion
    if(ticket.ticketTipo === "CORRECTIVO" && configEncuesta.correctivo === "true"){
      $("#encuesta_satisfaccion_modal").modal("toggle");
    }
    else if(ticket.ticketTipo === "PREVENTIVO" && configEncuesta.preventivo === "true"){
      $("#encuesta_satisfaccion_modal").modal("toggle");
    }
    else{
     this.handleCerrar(e)
    }
  }

  handleEncuestaSatisfaccionVencimiento(obj){
    let ticket = this.props.ticket
    let configEncuesta = this.configEncuestaSatisfaccion

    this.setState({
      formDataProximoVencimiento:obj,
    })
    this.handleDatosCierreVencimientoClose()
    if(ticket.ticketTipo === "VENCIMIENTO" && configEncuesta.vencimiento === "true"){
        $("#encuesta_satisfaccion_modal").modal("toggle");
        // $("#datos_vencimiento_modal").modal("toggle");
    }
    else{
      this.handleDatosCierreVencimientoSave(obj)
    }
  }

  onChangeValueNivelEncuesta(event){
    console.log(event.target.value)
    this.setState({
      encuestaNivelSatisfaccion:event.target.value,
    })
  }

  onChangeValueComentarioEncuesta(event){
    this.setState({
      encuestaComentariosSatisfaccion:event.target.value,
    })
  }

  handleCancelEncuestaModal(){
    this.setState({
      encuestaNivelSatisfaccion:null,
      encuestaComentariosSatisfaccion:'',
    })
    $("#encuesta_satisfaccion_modal").modal("toggle");
  }

  render() {
    let canCancelTicket = false
    let canCloseTicket = false
    let ticket = this.props.ticket;
    let classAvatar = "dt-user-avatar mr-1 ";
    switch (ticket.ticketTipo) {
      case "PREVENTIVO":
        classAvatar += "paleta-preventivo";
        canCancelTicket = Security.hasPermission("TICKETS_CANCELAR_PREVENTIVOS")
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_PREVENTIVOS")
        break;
      case "VENCIMIENTO":
        classAvatar += "paleta-vencimiento";
        canCancelTicket = Security.hasPermission("TICKETS_CANCELAR_VENCIMIENTOS")
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_VENCIMIENTOS")
        break;
      case "GESTORIA":
        classAvatar += "paleta-gestoria";
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_GESTORIA")
        break;
      case "CORRECTIVO":
        classAvatar += "paleta-correctivo";
        canCancelTicket = Security.hasPermission("TICKETS_CANCELAR_CORRECTIVOS")
        canCloseTicket = Security.hasPermission("TICKETS_CERRAR_CORRECTIVOS")
        break;
      default:
        break;
    }
    let classTP = "";
    switch (ticket.semaforo) {
      case "VERDE":
        classTP = "bg-success";
        break;
      case "AMARILLO":
        classTP = "bg-yellow";
        break;
      case "ROJO":
        classTP = "bg-danger";
        break;
      default:
        classTP = "bg-blue-grey";
        break;
    }
    let enTallerMark = EnTallerGrisImage;
    switch (ticket.enTaller) {
      case "1":
        enTallerMark = EnTallerGrisImage;
        break;
      case "2":
        enTallerMark = EnTallerVerdeImage;
        break;
      case "3":
        enTallerMark = EnTallerRojoImage;
        break;
    }
    let classNivelEncuesta = ""
    if(ticket.encuestaNivelSatisfaccion < 7){
      classNivelEncuesta = "bg-danger"
    }
    if(ticket.encuestaNivelSatisfaccion === 7 || ticket.encuestaNivelSatisfaccion === 8){
      classNivelEncuesta = "bg-yellow"
    }
    if(ticket.encuestaNivelSatisfaccion === 9 || ticket.encuestaNivelSatisfaccion === 10){
      classNivelEncuesta = "bg-success"
    }
    return (
      <div className="card" {...(ticket.ticketGarantia || ticket.ticketPendiente || (ticket.alertaChecklist && this.state.alertaChecklistHabilitado)) && {style:{'backgroundColor':'yellow'}}}>
        <div className="card-content">
          <div className="card-body">
            <div className="row">
              <div className="col-md-10">
                <h5 className="header-card">
                  <div className="item" style={{ display: "flex" }}>
                    <div id="avatar" className={classAvatar}>
                      {ticket.ticketTipo.substr(0, 1).toUpperCase()}
                    </div>
                    {"TKT " +
                      ticket.id +
                      (ticket.idProveedor == ""
                        ? ""
                        : " (" + ticket.idProveedor + ")")}
                      {(ticket.alertaChecklist && this.state.alertaChecklistHabilitado) &&
                        <div
                          className="item"
                          title={this.props.intl.formatMessage({
                          id:
                            "ticketHeader.render.ticket_alerta_checlist",
                          defaultMessage: "Vehículo con inspecciones pendientes de resolución, ver inspecciones",
                          })}
                        >
                           <a href={"/formulario?entidad="+ticket.movil.dominio} target="_blank">
                            <img
                              style={{ width: "34px", transform: "scale(1.4, 1.4)", paddingLeft: "5px"}}
                              src={TicketAlertaChecklist}
                            />
                           </a>
                        </div>
                      }
                      {ticket.ticketGarantia &&
                        <div
                          className="item"
                          title={this.props.intl.formatMessage({
                          id:
                            "ticketHeader.render.ticket_garantia",
                          defaultMessage: "Correctivo en garantía, ver ticket:  ",
                          })  +  ticket.ticketGarantia } 
                        >
                          <a href={"/tickets/"+ticket.ticketGarantia} target="_blank">
                            <img
                              style={{ width: "34px", transform: "scale(1.4, 1.4)", paddingLeft: "5px"}}
                              src={TicketGarantiaImage}
                            />
                          </a>
                        </div>
                      }
                       {ticket.ticketPendiente &&
                        <div
                          className="item"
                          title={this.props.intl.formatMessage({
                          id:
                            "ticketHeader.render.ticket_pendiente",
                          defaultMessage: "Este vehículo cuenta con otro ticket en ejecución, ver ticket: ",
                          })  + ticket.ticketPendiente + " - " + ticket.ticketPendienteServicio.nombre } 
                        >
                          <a href={"/tickets/"+ticket.ticketPendiente} target="_blank">
                            <img
                              style={{ width: "30px", transform: "scale(1.4, 1.4)", paddingLeft: "10px"}}
                              src={TicketPendienteImage}
                            />
                          </a>
                        </div>
                      }
                  </div>
                  <div className="divider"></div>
                  <div
                    className="item text-uppercase"
                    style={{ display: "flex" }}
                  >
                    {this.state.fotoMarcaUrl ? (
                      <div
                        id="avatar"
                        className="dt-user-avatar-image mr-1"
                        style={{
                          backgroundImage:
                            "url(" + this.state.fotoMarcaUrl + ")",
                        }}
                      ></div>
                    ) : (
                      <div id="avatar" className="dt-user-avatar mr-1">
                        {this.showEntityAvatar()}
                      </div>
                    )}
                    {" "}
                    {this.showEntityName()}
                  </div>
                  <div className="divider"></div>
                  <div className="item text-uppercase">
                    {ticket.servicio.nombre}
                  </div>
                  <div className="divider"></div>
                  <div className="item text-uppercase">{ticket.estado}</div>
                  <div className="divider"></div>
                  <div className="item text-uppercase">
                    { Timezone.getDateForClient(ticket.fechaHoraAlta, null, 'L') }
                  </div>
                  {ticket.enTaller !== 0 ? <div className="divider"></div> : ""}
                  {ticket.enTaller !== 0 ? (
                    <div className="item">
                      <img
                        style={{ width: "36px", transform: "scale(1.6, 1.6)" }}
                        src={enTallerMark}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="divider"></div>
                  <div className="item text-uppercase">
                    {"TT "}
                    <h3 className="display-inline">
                      <div className="badge badge-pill bg-blue-grey">
                        {ticket.mttTotal}
                      </div>
                    </h3>
                  </div>
                  {ticket.estado !== "CERRADO" &&
                  ticket.estado !== "CANCELADO" ? (
                    <div className="divider"></div>
                  ) : (
                    ""
                  )}
                  {ticket.estado !== "CERRADO" &&
                  ticket.estado !== "CANCELADO" ? (
                    <div className="item text-uppercase">
                      {"TP "}
                      <h3 className="display-inline">
                        <div className={"badge badge-pill " + classTP}>
                          {ticket.mttParcial}
                        </div>
                      </h3>
                    </div>
                  ) : (
                    ""
                  )}
                  {(ticket.estado === "CERRADO" && ticket.encuestaNivelSatisfaccion) ? (
                    <>
                    <div className="divider"></div>
                    <div className="item text-uppercase">
                    {"ST "}
                    <h3 className="display-inline">
                      <div
                      className={"badge badge-pill " + classNivelEncuesta}
                      data-placement="top"
                      data-tooltip="tooltip"
                      title={ticket.encuestaComentarios}
                      >
                        {ticket.encuestaNivelSatisfaccion}
                      </div>
                    </h3>
                    </div>
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              </div>
              <div className="col-md-2 p-0">
                <div>
                  <div
                    className="row dt-icons"
                    style={{
                      display: this.props.action === "VIEW" ? "none" : "",
                    }}
                  >
                    <div className="col-12 text-right pr-2">
                      {this.canDo("A_RECOTIZAR") &&
                      Security.hasPermission("TICKETS_RECOTIZAR") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id: "ticketHeader.render.button_title_recotizar",
                            defaultMessage: "Recotizar",
                          })}
                          onClick={this.handleRecotizar.bind(this)}
                        >
                          <i className="ft-refresh-ccw"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("A_RECOTIZAR_AUDITOR") &&
                      Security.hasPermission("TICKETS_RECOTIZAR_AUDITOR") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_recotizar_auditor",
                            defaultMessage: "Recotizar Auditor",
                          })}
                          onClick={this.handleRecotizarAuditor.bind(this)}
                        >
                          <i className="ft-refresh-ccw"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("APROBAR_AUDITOR") &&
                      Security.hasPermission("TICKETS_APROBAR_AUDITOR") ? (
                        <div
                          className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_valida_auditor",
                            defaultMessage: "Valida Auditor",
                          })}
                          onClick={this.handleAprobarAuditor.bind(this)}
                        >
                          <i className="ft-thumbs-up"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("APROBAR") &&
                      Security.hasPermission("TICKETS_APROBAR")  &&
                      this.checkAprobarPresupuesto() ? (
                            <div
                              className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                              data-tooltip="tooltip"
                              data-placement="top"
                              title={this.props.intl.formatMessage({
                                id:
                                  "ticketHeader.render.button_title_aprobar_prespupuesto",
                                defaultMessage: "Aprobar Prespupuesto",
                              })}
                              onClick={this.handleAprobar.bind(this)}
                            >
                              <i className="ft-thumbs-up"></i>
                            </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("APROBAR_ABONO") &&
                      Security.hasPermission("TICKETS_APROBAR_ABONO") &&
                      ticket.abono ? (
                        <div
                          className="btn btn-success box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_aprobar_abono",
                            defaultMessage: "Aprobar Abono",
                          })}
                          onClick={this.handleAprobarAbono.bind(this)}
                        >
                          <i className="ft-check"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("RECHAZAR_ABONO") &&
                      Security.hasPermission("TICKETS_RECHAZAR_ABONO") &&
                      ticket.abono ? (
                        <div
                          className="btn btn-danger box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_rechazar_abono",
                            defaultMessage: "Rechazar Abono",
                          })}
                          onClick={this.handleRechazarAbono.bind(this)}
                        >
                          <i className="ft-x"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("LISTO_PARA_RETIRAR") &&
                      Security.hasPermission(
                        "TICKETS_PASAR_LISTO_PARA_RETIRAR"
                      ) ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_listo_para_retirar",
                            defaultMessage: "Listo para Retirar",
                          })}
                          onClick={this.handleListoParaRetirar.bind(this)}
                        >
                          <i className="ft-flag"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {(this.canDo("SOLICITAR_ADICIONAL") &&
                      Security.hasPermission("TICKETS_COTIZAR_ADICIONAL")) ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_recotizar_adicional",
                            defaultMessage: "Recotizar Adicional",
                          })}
                          onClick={this.handleAdicional.bind(this)}
                        >
                          <i className="ft-refresh-ccw"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("A_CONFIRMAR") &&
                      Security.hasPermission("TICKETS_CONFIRMAR_REPARACION") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_confirmar_reparacion",
                            defaultMessage: "Confirmar Reparación",
                          })}
                          onClick={this.handleConfirmarReparacion.bind(this)}
                        >
                          <i className="ft-check-square"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.canDo("A_REPARAR") &&
                      Security.hasPermission("TICKETS_ENVIAR_A_REPARAR") ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.button_title_enviar_a_reparar",
                            defaultMessage: "Enviar a reparar",
                          })}
                          onClick={this.handleEnviarAReparar.bind(this)}
                        >
                          <i className="ft-chevrons-right"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {ticket.estado === "ABIERTO" ? (
                        ticket.servicio === 37 &&
                        ticket.ticketTipo === "VENCIMIENTO" ? (
                          ""
                        ) : this.canDo("CERRAR") && canCloseTicket &&
                          ticket.fechaHoraRealizado !== null &&
                          ticket.autogestion ? (
                          <div
                            className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                            data-tooltip="tooltip"
                            data-placement="top"
                            title={this.props.intl.formatMessage({
                              id:
                                "ticketHeader.render.ticket_autogestion.button_title_cerrar",
                              defaultMessage: "Cerrar",
                            })}
                            onClick={this.handleEncuestaSatisfaccion.bind(this)}
                          >
                            <i className="ft-lock"></i>
                          </div>
                        ) : (
                          ""
                        )
                      ) : this.canDo("CERRAR") && canCloseTicket &&
                        ticket.fechaHoraRealizado !== null ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id: "ticketHeader.render.button_title_cerrar",
                            defaultMessage: "Cerrar",
                          })}
                          onClick={this.handleEncuestaSatisfaccion.bind(this)}
                        >
                          <i className="ft-lock"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {ticket.ticketTipo === "GESTORIA" &&
                      this.canDo("CERRAR_GESTORIA") && canCloseTicket &&
                      this.canClose() ? (
                        <div
                          className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.cerrar_gestoria.button_title_cerrar",
                            defaultMessage: "Cerrar",
                          })}
                          onClick={this.handleCerrarGestoria.bind(this)}
                        >
                          <i className="ft-lock"></i>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.canDo("CANCELAR") && canCancelTicket ? (
                        <div
                          className="btn btn-danger box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
                          data-tooltip="tooltip"
                          data-placement="top"
                          title={this.props.intl.formatMessage({
                            id:
                              "ticketHeader.render.ticket_cancelar.button_title_cancelar_ticket",
                            defaultMessage: "Cancelar Ticket",
                          })}
                          onClick={this.handleCancelar.bind(this)}
                        >
                          <i className="ft-thumbs-down"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex="-1"
          id="datos_vencimiento_modal"
          role="dialog"
          aria-labelledby="myModalLabel133"
        >
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel133">
                  <i className="la la-history align-middle icon-modal-title"></i>
                  {this.props.intl.formatMessage({
                    id: "ticketHeader.datos_vencimiento_modal.header_ticket",
                    defaultMessage: " Ticket: ",
                  }) + ticket.id}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <TicketDatosCierreVencimiento
                  key={this.state.modalDatosCierreVencimientoKey}
                  ticket={ticket}
                  callbackClose={this.handleDatosCierreVencimientoClose.bind(
                    this
                  )}
                  callbackSave={this.handleEncuestaSatisfaccionVencimiento.bind(
                    this
                  )}
                ></TicketDatosCierreVencimiento>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex="-1"
          id="tareas_cierre_modal"
          role="dialog"
          aria-labelledby="myModalTareasCierre"
        >
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalTareasCierre">
                  <i className="la la-history align-middle icon-modal-title"></i>
                  {this.props.intl.formatMessage({
                    id: "ticketHeader.tareas_cierre_modal.header_ticket",
                    defaultMessage: " Ticket: ",
                  }) + ticket.id}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <TicketTareasCierre
                  key={this.state.modalTareasCierreKey}
                  ticket={ticket}
                  callbackClose={this.handleTareasCierreClose.bind(this)}
                  callbackSave={this.handleTareasCierreSave.bind(this)}
                ></TicketTareasCierre>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex="-1"
          id="encuesta_satisfaccion_modal"
          role="dialog"
          aria-labelledby="myModalEncuesta"
        >
          <div className="modal-lg modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
              <h4 className="modal-title text-white" id="myModalLabel133">
                  {this.props.intl.formatMessage({
                    id: "ticketHeader.encuesta_satisfaccion_modal.header_ticket",
                    defaultMessage: " Ticket: ",
                  }) + ticket.id}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                <div className="card">
                    <div className="card-content">
                      <div className="card-body">
                          <h4 className="form-section">
                            <i className="las la-comments"></i><FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.nivel_satisfaccion" defaultMessage=" Por favor Indicar el nivel de satisfacción por el servicio recibido"/>
                          </h4>
                        <div className="row justify-content-center">
                          <div className="col-md-6">
                            <div className="row">
                              <div className="btn-group btn-group-toggle radioButtons" data-toggle="buttons" onChange={this.onChangeValueNivelEncuesta.bind(this)}>
                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={1} name="options" autoComplete="off"/> 1
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={2} name="options" autoComplete="off"/>2
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={3} name="options" autoComplete="off"/>3
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={4} name="options" autoComplete="off"/>4
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={5} name="options" autoComplete="off"/>5
                                  </label>

                                  <label className="btn radio-red active form-check-label">
                                    <input className="form-check-input" type="radio" value={6} name="options" autoComplete="off"/>6
                                  </label>

                                  <label className="btn radio-yellow active form-check-label">
                                    <input className="form-check-input" type="radio" value={7} name="options" autoComplete="off"/>7
                                  </label>

                                  <label className="btn radio-yellow active form-check-label">
                                    <input className="form-check-input" type="radio" value={8} name="options" autoComplete="off"/>8
                                  </label>

                                  <label className="btn radio-green active form-check-label">
                                    <input className="form-check-input" type="radio" value={9} name="options" autoComplete="off"/>9
                                  </label>

                                  <label className="btn radio-green active form-check-label">
                                    <input className="form-check-input" type="radio" value={10} name="options" autoComplete="off"/>10
                                  </label>
                              </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='col'>
                                  <div className='row justify-content-start'>
                                    <FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.pesimo_trabajo" defaultMessage="Pésimo Trabajo"/>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div className='row justify-content-center mr-1'>
                                    <span>{(this.state.encuestaNivelSatisfaccion) && this.state.encuestaNivelSatisfaccion}</span>
                                  </div>
                                </div>
                                <div className='col'>
                                  <div className='row justify-content-end'>
                                    <FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.excelente_trabajo" defaultMessage="Excelente Trabajo"/>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className="mt-2">
                              <label htmlFor="comentariosEncuesta">
                                <FormattedMessage id="ticketHeader.modal_encuesta_satisfaccion.comentarios" defaultMessage="Comentarios (Opcional)"/>
                              </label>
                              <textarea onChange={this.onChangeValueComentarioEncuesta.bind(this)} value={this.state.encuestaComentariosSatisfaccion} className="form-control" id="comentariosEncuesta" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="text-cd text-right">
                          <button type="submit" className="btn btn-primary mr-1" disabled={!this.state.encuestaNivelSatisfaccion} onClick={ticket.ticketTipo === "VENCIMIENTO" ? this.handleDatosCierreVencimientoSave.bind(this) :this.handleCerrar.bind(this)}>
                            <i className="fa fa-check-circle"></i><FormattedMessage id="ticketHeader.encuesta_button.cerrarTicket" defaultMessage=" Cerrar Ticker"/>
                          </button>
                          <button type="button" className="btn btn-danger" onClick={this.handleCancelEncuestaModal.bind(this)}>
                            <i className="fa fa-times-circle"></i><FormattedMessage id="ticketHeader.encuesta_button.cancelar" defaultMessage=" Cancelar"/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(TicketHeader);
