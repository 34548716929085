import React, { Component } from 'react'
import InstanciaBasicConAdjunto from './InstanciaBasicConAdjunto.js'
import styled from 'styled-components';

const ImageThumbnail = styled.img.attrs(props => ({
		src: props.src,
	}))`
	width: 120px;
	height: 120px;
	object-fit: cover;
	margin: 2px;
  `;

class InstanciaImagen extends InstanciaBasicConAdjunto {
	constructor(props) {
		super(props);
  }

	render() {
      return (
        <React.Fragment>
					<div className="form-control">
							{
								this.props.ocultarInput ? (this.state.adjuntoUrlArray.length === 0) && 'Sin imagen' : 
								<input
									disabled={this.props.disabled || this.props.previsualizacion}
									type="file"
									accept="image/*"
									multiple
									onChange={this.handleInputChange}
								/>
							}

							{this.state.adjuntoUrlArray.map((url, i) => <a href={url} target="_blank"><ImageThumbnail src={url} /></a>)}
							{this.state.adjunto && (
								<>
									<hr/>
									{this.state.adjunto_url &&  <img className="img-fluid" src={this.state.adjunto_url}/>}
									<label className="btn-sm">{this.state.adjunto.archivoNombre}</label>
									{! this.props.disabled &&

										<button
											className="action btn btn-icon text-fleet text-danger btn-sm btn-dt-grid pull-right"
											onClick={(e) => this.handleChangeAndUpdateFather(null)}
											title="Eliminar" >
												<i className="fa fa-trash fa-xs"></i>
										</button>
									}
								</>
							)}
					</div>
        </React.Fragment>
      )
  }
}

export default InstanciaImagen;
