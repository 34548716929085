import Security from '../commons/security/Security.js'

function hasPermissionToCreate() {
    return Security.hasPermission('MARCAS_CREAR');
}

function hasPermissionToUpdate() {
    return Security.hasPermission('MARCAS_MODIFICAR');
}

function hasPermissionToView() {
    return Security.hasPermission('MARCAS_VISUALIZAR');
}

function hasPermissionToList() {
    return Security.hasPermission('MARCAS_LISTAR');
}

export { hasPermissionToCreate, hasPermissionToUpdate, hasPermissionToView, hasPermissionToList };