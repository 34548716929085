import React, { useEffect } from 'react';

const CarLayout = (props) => {
  useEffect(() => {
    initCanvas();
  }, [props.llantas, props.llantaSeleccionada]);

  useEffect(() => {
    // Render updates only from Modelos Abm
    if (props.fromModelosAbm) initCanvas();
  }, [props.movil]);

  const initCanvas = () => {
    let tipologia = props.movil.tipologiaPosicion
      ? props.movil.tipologiaPosicion
      : props.movil.modelo.tipologiaPosicion;
    getArrayToRender(tipologia);
  };

  const getArrayToRender = (tipologia) => {
    let posicionesToRender = [];
    tipologia.llanta_posiciones.forEach((posicion, i) => {
      let llantaFiltered =
        props.llantas && props.llantas.find((llanta) => llanta.llanta_posicion.posicion === posicion.posicion);
      let posicionToRender = {};
      posicionToRender.x = JSON.parse(posicion.coordenadas).x;
      posicionToRender.y = JSON.parse(posicion.coordenadas).y;
      posicionToRender.posicion = llantaFiltered ? llantaFiltered.posicion : posicion.posicion;
      posicionToRender.llanta = llantaFiltered ? llantaFiltered : null;
      posicionesToRender.push({ ...posicion, ...posicionToRender });
    });
    drawCanvas(tipologia, posicionesToRender);
  };

  function drawCanvas(tipologia, posicionesToRender) {
    var canvas = document.getElementById('myCanvas_' + props.movil.id);
    var ctx = canvas.getContext('2d');
    let img = new Image();
    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      let posicionesRodando = posicionesToRender.filter(
        (posicion) => posicion.tipo_posicion.descripcion !== 'REPUESTO',
      );
      let posicionesRepuesto = posicionesToRender.filter(
        (posicion) => posicion.llanta && posicion.tipo_posicion.descripcion === 'REPUESTO',
      );
      posicionesRodando.forEach((posicion, index) => {
        roundRect(
          canvas,
          ctx,
          posicion.x,
          posicion.y,
          25,
          40,
          10,
          'green',
          posicion.label ? posicion.label : posicion.posicion,
          posicion.llanta
            ? posicion.llanta.ultima_medicion
              ? posicion.llanta.ultima_medicion.estado
              : null
            : null,
          posicion.posicion,
        );
      });

      posicionesRepuesto.forEach((llanta, index) => {
        let position = calculateSparePositionInCanvas(llanta, index, posicionesRepuesto);
        roundSpareRect(canvas, ctx, position.x, position.y, 20, 30, 8, index + 1, llanta.llanta
          ? (llanta.llanta.ultima_medicion
            ? llanta.llanta.ultima_medicion.estado
            : null)
          : null,
          llanta.posicion);
      });
    };
    img.src = process.env.PUBLIC_URL + '/images/llantas/' + tipologia.background;

    ctx.stroke();
  }

  function roundRect(
    canvas,
    context,
    x,
    y,
    w,
    h,
    radius,
    fillColor,
    posicionLabel,
    estado,
    posicionNumber,
  ) {
    // canvas.style.letterSpacing = "-0.1em";
    var r = x + w;
    var b = y + h;
    context.beginPath();
    if (props.llantaSeleccionada) {
      if (posicionNumber === props.llantaSeleccionada.posicion) {
        context.fillStyle = '#6BF365';
        context.strokeStyle = '#6BF365';
      } else {
        context.fillStyle = '#DCE2F1';
        context.strokeStyle = 'black';
        context.lineWidth = '1';
      }
    } else {
      if (estado) {
        if (estado === 1) {
          context.fillStyle = '#6BF365';
          context.strokeStyle = '#6BF365';
          context.lineWidth = '0.1';
        }
        if (estado === 2) {
          context.fillStyle = '#FC0000';
          context.strokeStyle = '#FC0000';
          context.lineWidth = '0.1';
        }
        if (estado === 3) {
          context.fillStyle = '#F9F257';
          context.strokeStyle = '#F9F257';
          context.lineWidth = '0.1';
        }
      } else {
        context.fillStyle = '#DCE2F1';
        context.strokeStyle = 'black';
        context.lineWidth = '1';
      }
    }
    // context.lineWidth = "0.1";
    context.moveTo(x + radius, y);
    context.lineTo(r - radius, y);
    context.quadraticCurveTo(r, y, r, y + radius);
    context.lineTo(r, y + h - radius);
    context.quadraticCurveTo(r, b, r - radius, b);
    context.lineTo(x + radius, b);
    context.quadraticCurveTo(x, b, x, b - radius);
    context.lineTo(x, y + radius);
    context.quadraticCurveTo(x, y, x + radius, y);
    context.stroke();
    context.fill();
    context.font = '12px Poppins';
    context.fillStyle = 'black';
    var xFill = posicionLabel.length == 3 ? 4 : 5;
    context.fillText(posicionLabel, x + xFill, y + 25);
  }

  function roundSpareRect(canvas, context, x, y, w, h, radius, numero, estado, posicionNumber) {
    canvas.style.letterSpacing = '0.1em';
    var r = x + w;
    var b = y + h;
    context.beginPath();

    if (props.llantaSeleccionada) {
      if (posicionNumber === props.llantaSeleccionada.posicion) {
        context.fillStyle = '#6BF365';
        context.strokeStyle = '#6BF365';
      } else {
        context.fillStyle = '#DCE2F1';
        context.strokeStyle = 'black';
        context.lineWidth = '1';
      }
    } else {
      if (estado) {
        if (estado === 1) {
          context.fillStyle = '#6BF365';
          context.strokeStyle = '#6BF365';
          context.lineWidth = '0.1';
        }
        if (estado === 2) {
          context.fillStyle = '#FC0000';
          context.strokeStyle = '#FC0000';
          context.lineWidth = '0.1';
        }
        if (estado === 3) {
          context.fillStyle = '#F9F257';
          context.strokeStyle = '#F9F257';
          context.lineWidth = '0.1';
        }
      } else {
        context.fillStyle = '#DCE2F1';
        context.strokeStyle = 'black';
        context.lineWidth = '1';
      }
    }
    // context.fillStyle = '#DCE2F1';
    // context.strokeStyle = 'black';
    // context.lineWidth = '1';
    context.moveTo(x + radius, y);
    context.lineTo(r - radius, y);
    context.quadraticCurveTo(r, y, r, y + radius);
    context.lineTo(r, y + h - radius);
    context.quadraticCurveTo(r, b, r - radius, b);
    context.lineTo(x + radius, b);
    context.quadraticCurveTo(x, b, x, b - radius);
    context.lineTo(x, y + radius);
    context.quadraticCurveTo(x, y, x + radius, y);
    context.stroke();
    context.fill();
    context.font = '12px Poppins';
    context.fillStyle = 'black';
    context.fillText('R' + numero, x + 2, y + 20);
  }

  const calculateSparePositionInCanvas = (llanta, index, posicionesRepuesto) => {
    let sparePositions = [
      {
        x: 39,
        y: 360,
      },
      {
        x: 64,
        y: 360,
      },
      {
        x: 89,
        y: 360,
      },
      {
        x: 115,
        y: 360,
      },
      {
        x: 141,
        y: 360,
      },
    ];
    return posicionesRepuesto.length === 1
      ? sparePositions[2]
      : sparePositions[index === 0 ? index + 1 : index + 2];
  };

  return (
    <div className="d-flex justify-content-center">
      <canvas
        id={'myCanvas_' + props.movil.id}
        width="200"
        height="400"
        style={{ border: '', borderRadius: 25, backgroundColor: '#DDE3F2' }}
      >
        Your browser does not support the canvas element.
      </canvas>
    </div>
  );
};

export default CarLayout;
