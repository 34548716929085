import React, { Component } from "react";
import {injectIntl} from 'react-intl';
import FeatureFlags from '../../commons/config/FeatureFlags.js';
import Marcas from './Marcas.js';
import { registerApplication, start, unregisterApplication } from 'single-spa';
import {
    hasPermissionToCreate,
    hasPermissionToUpdate,
    hasPermissionToView,
    hasPermissionToList,
  } from '../../microfrontendprops/brands.js';
import Security from '../../commons/security/Security.js';
import ErrorLoadingService from '../refactor/ErrorLoadingService.js';
import BootstrapSpinner from '../refactor/BootstrapSpinner.js';

class MarcasSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			brandsModuleIsActive: null,
			isLoading: false
		};
	}

	componentDidMount() {
		this.setState({ ...this.state, isLoading: true })
		FeatureFlags.get("brands").then(isActive => {
			this.setState({ ...this.state, brandsModuleIsActive: isActive })

			if (isActive) {
				registerApplication(
					'@marcas/vec',
					window['@marcas/vec'],
					(location) =>
					location.pathname.startsWith('/marcas') &&
					Boolean(document.querySelector('#root #app-content')),
					{
						permissions: {
							hasPermissionToCreate: hasPermissionToCreate(),
							hasPermissionToUpdate: hasPermissionToUpdate(),
							hasPermissionToView: hasPermissionToView(),
							hasPermissionToList: hasPermissionToList(),
							isUserInternal: Security.isInternal(),
						},
					},
				);
				start();
			}

			this.setState({ ...this.state, isLoading: false })
		})
		.catch(() => {
			this.setState({ ...this.state, brandsModuleIsActive: null })
			this.setState({ ...this.state, isLoading: false })
		});
	}

	componentWillUnmount() {
		if (this.state.brandsModuleIsActive) {
			unregisterApplication('@marcas/vec').then(() => {
				console.log('@marcas/vec is now unmounted, unloaded, and no longer registered!');
			});
		}
		this.setState({ ...this.state, brandsModuleIsActive: null })
	}

	render() {
	    return (
			<>
				{
					this.state.isLoading
						? <BootstrapSpinner />
						: this.state.brandsModuleIsActive === null
							? <ErrorLoadingService />
							: this.state.brandsModuleIsActive
								? null
								: <Marcas {...this.props} />
				}
			</>
		);
  	}
}

export default injectIntl(MarcasSelector);
