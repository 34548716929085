import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import Security from '../../../commons/security/Security.js'
import AjaxHandler from '../../../commons/ajax/AjaxHandler.js'
import WidgetSparkline from './WidgetSparkline';
import SolidGauge from './SolidGauge.js';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
import Loading from '../../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import $ from 'jquery'
import PopoverGeneral from '../../../commons/popover/popover'
import popoverTexts from '../../../commons/popover/popoverText.js'
import BasicCardInfo from '../../ui/BasicCardInfo.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import BasicCardMultipleValuesInfo from "../../ui/BasicCardMultipleValuesInfo.js";
import * as Utils from "../../../commons/utils/Utils";

class DashboardOperativo2 extends Component {

  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");
    this.state = {
      widgets: null,
      solidGauges: null,
      accordions: [],
      filtroDia: null,
      soloFactura: false,
      summaryAlerts: null,
      alertClick: null,
      redirect_params: null
    };

    this.textos = popoverTexts(this.props.intl);

    this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
    this.handleAlertClick = this.handleAlertClick.bind(this);
    this.handleKpiClick = this.handleKpiClick.bind(this);

  }

  componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
  }


  componentDidMount(nextProps, nextState) {

    $('[data-tooltip="tooltip"]').tooltip({ trigger: "hover" });

    if(Security.hasPermission('DASHBOARD_OPERATIVO2_VISUALIZAR')) {
      this.ajaxHandler.subscribe(this);

    this.initData();

      //this.initForm();

    } else {
        this.setState({
          redirectTo: '/error'
        });
    }

  }

  initData() {
    let component = this;

    this.getWidgetsData();
    this.getSolidData();
    this.getAlarmsData();

  }

  initDataByDate() {

    let component = this;
    this.setState({loading: true});
    Promise.all([
      Utils.getData('/widgets/sparkline/' + this.state.filtroDia),
      Utils.getData('/solid-gauges/' + this.state.filtroDia),

    ]).then((data) => {
      let widgets = data[0];
      let solidGauges= data[1];

      component.setState({
        widgets: widgets,
        solidGauges: solidGauges,
      });
    }).catch(function(error) {
      // TODO: Manejar errores.
		}).finally(() => {
			this.setState({ loading: false });
    });
  }

  getWidgetsData(){
    let component = this;
    Utils.getData('/widgets/sparkline/' + this.state.filtroDia).then((data) => {
      component.setState({
        widgets: data
      });
    });
  }

  getAlarmsData(){
    let component = this;
    Utils.getData('/moviles/controles/alarmas').then((data) => {
      component.setState({
        summaryAlerts: data
      });
    });
  }

  getSolidData(){
    let component = this;
    Utils.getData('/solid-gauges/' + this.state.filtroDia).then((data) => {
      component.setState({
        solidGauges: data
      });
    });
  }

  handleDatePickerChange(event) {

    let date = event ? event.format(): '';
    this.setState({filtroDia: date}, ()=>{this.initDataByDate()});

  }

  handleAlertClick(filter){
    console.log(filter);
    this.setState({
      redirectTo: '/moviles',
      redirect_params:{filter:filter}
    });
  }

  handleKpiClick(redirect,filter){
    this.setState({
      redirectTo: redirect,
      redirect_params:{filter:filter}
    });
  }

  render(){
    let selectFilter = null;
    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={{pathname:this.state.redirectTo,  state: this.state.redirect_params }}  />}
        <div className="content-wrapper" style={{ padding:"1.2rem" }}>
          <div className="content-body">

          <div className="card card-x-dia">
              <div className="card-content">
                <div className="card-body pt-0 mt-1">
                    <div className="media d-flex">
                      <div className="media-body ">
                      {this.state.summaryAlerts ? 
                        <div className="row justify-content-center">
                          {this.state.summaryAlerts.map((alert, index, array)=>{
                            return(
                              <div key={index} className={"col-xl-2 col-lg-4 col-md-6 col-12 cards-alerts-"+array.length} style={{ paddingRight: "0.5rem", paddingLeft: "0.5rem" }}>
                                <BasicCardMultipleValuesInfo
                                  id={index}
                                  onClickAlert={this.handleAlertClick}
                                  values={alert}
                                  selectFilter={selectFilter}
                                  intl={this.props.intl}
                                ></BasicCardMultipleValuesInfo>
                              </div>
                            );
                          })}
                        </div>
                      :
                      <div className="row">
                        <div className="col-12">
                          <h6 style={{fontSize: "1rem"}}><FormattedMessage id="dashboardOperativo2.label_loading.cargando_datos_Dashboard" defaultMessage="Cargando datos del Dashboard, aguarde unos instantes por favor"/></h6>
                        </div>
                        <div className="col-3 col-centered">
                        <img src={process.env.PUBLIC_URL + "/images/loading.svg"}/>
                        </div>
                      </div>}	

                        <div className="row">
                          {this.state.widgets ? Object.keys(this.state.widgets).map((index)=>{
                            return(
                              <div key={index} className={(this.state.widgets.length % 3 === 0 ? "col-xl-4" : "col-xl-3") + " col-lg-6 col-12"} style={{ padding:"0 7px" }}>
                                <WidgetSparkline 
                                  id={index} 
                                  text={this.textos[this.state.widgets[index].text]} 
                                  value={this.state.widgets[index].value} 
                                  description={this.state.widgets[index].description} 
                                  iconClass={this.state.widgets[index].iconClass} 
                                  mainColorClass={this.state.widgets[index].mainColorClass} 
                                  hasProgressBar={this.state.widgets[index].hasProgressBar} 
                                  progressBarValue={this.state.widgets[index].progressBarValue} 
                                  datos={this.state.widgets[index].datos} 
                                  entidad="dashboard" 
                                  onClickWidget={ this.handleKpiClick }
                                  link={this.state.widgets[index].link}
                                  filter={this.state.widgets[index].filters}
                                />
                              </div>
                            );
                          })
                          :<div className="col-3 col-centered">
                        <img src={process.env.PUBLIC_URL + "/images/loading.svg"}/>
                      </div>}
                        </div>


                        <div className="row">

                          {this.state.solidGauges ? this.state.solidGauges.map((solidGauge, index)=>{
                            return(
                              <div key={index} className={(this.state.solidGauges.length === 3 ? "col-xl-4" : "col-xl-3")+ " col-lg-6 col-12"} style={{ padding:"0 7px" }}>
                                <SolidGauge id={index} cardTitle={solidGauge.cardTitle} datos={solidGauge.datos} text={this.textos[solidGauge.text]}></SolidGauge>
                              </div>
                            );
                          })
                        :<div className="col-3 col-centered">
                        <img src={process.env.PUBLIC_URL + "/images/loading.svg"}/>
                      </div>}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(DashboardOperativo2);
