import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import $ from 'jquery'
import moment from 'moment'
import FormValidation from '../../commons/validation/FormValidation.js'
import Validator from '../../commons/validation/Validator.js'
import DatePicker from 'react-datepicker'
import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import swal from 'sweetalert'
import Select from 'react-select'
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import Dialog from '../../commons/dialog/Dialog.js'
import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import Timezone from '../../commons/timezone/Timezone.js';

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class FacturasAbm extends Component {
	constructor(props) {
		super(props);

		moment.locale('es');

		this.ajaxHandler = new AjaxHandler();

		this.state = {
			props: this.props,
			formData: {
				categoria: 'MANTENIMIENTO',
				gerenciador: null,
				tipoFactura: '',
				tipo: '',
				puntoDeVenta: '',
				numero: '',
				fecha: null,
				mes: {id: moment().month()+1},
				anio: moment().year(),
				quincena: {id: moment().month() < 6 ? 1 : 2},
				importe: '',
				estado: '',
				we: '',
				oe: '',
				selectedTickets: [],
				adjunto: null,
				regionEnabled: false,
				subregionEnabled: false,
				subregion: null,
				region: null
			},
			usuarioGerenciador: false,
			vGerenciadores: false,
			gerenciadoresLoading: false,
			subregionLoading: false,
			regionLoading: false,
			tiposFacturaLoading: false,
			meses: [{id: 1, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.enero', defaultMessage: 'Enero' })}, {id: 2, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.febrero', defaultMessage: 'Febrero' })}, {id: 3, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.marzo', defaultMessage: 'Marzo' })}, {id: 4, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.abril', defaultMessage: 'Abril' })}, {id: 5, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.mayo', defaultMessage: 'Mayo' })}, {id: 6, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.junio', defaultMessage: 'Junio' })}, {id: 7, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.julio', defaultMessage: 'Julio' })}, {id: 8, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.agosto', defaultMessage: 'Agosto' })}, {id: 9, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.septiembre', defaultMessage: 'Septiembre' })}, {id: 10, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.octubre', defaultMessage: 'Octubre' })}, {id: 11, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.noviembre', defaultMessage: 'Noviembre' })}, {id: 12, label: this.props.intl.formatMessage({ id: 'facturasAbm.period_data.select_month.diciembre', defaultMessage: 'Diciembre' })}],
			quincenas: [{id: 1, label: 'Primera'}, {id: 2, label: 'Segunda'}],
			tiposFactura: [{value: 'MANTENIMIENTO', label: this.props.intl.formatMessage({ id: 'facturasAbm.invoice_type.label_mantenimiento', defaultMessage: 'MANTENIMIENTO' })}, {value: 'GESTORIA', label: this.props.intl.formatMessage({ id: 'facturasAbm.invoice_type.label_gestoria', defaultMessage: 'GESTORIA' })}],
			formStep: 0,
			redirectTo: null,
			errors: [],
			multipleSelectIds: '',
			viewGestoria: ConfigBusiness.get('mantenimientos.gestoria.habilitado') === 'true' ? true : false,
			sap: ConfigBusiness.get('facturas.sap.habilitado') === 'true' ? true : false
		}

		this.handleInputFormChange = this.handleInputFormChange.bind(this);
		this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
		this.handleFormChangeSelectString = this.handleFormChangeSelectString.bind(this);
		this.handleFormChangeSelectStringFactura = this.handleFormChangeSelectStringFactura.bind(this);
		this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
		this.handleDatePickerFormRawChange = this.handleDatePickerFormRawChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.formValidation = new FormValidation({
			component: this,
			validators: {
				'formData.mes': (value) => Validator.notEmpty(value),
				'formData.anio': (value) => {
					let valid = value === null ? true : /^\d{4}$/.test(value);
					return {
						valid: valid,
						message: !valid ? this.props.intl.formatMessage({ id: 'facturasAbm.errors.form_validation.anio_invalido', defaultMessage: 'Año inválido' }) : ''
					}
				},
				'formData.quincena': (value) => Validator.notEmpty(value),
				'formData.importe': (value) => {
					let empty = value === null ? true : !/\S/.test(value);
					let valid = this.state.props.action === 'VIEW' || parseFloat(value) === parseFloat(this.state.formData.selectedTickets.map((e) => e.importe).reduce((a, b) => a + b, 0).toFixed(2));
					return {
						valid: empty || valid,
						message: !empty && !valid ? this.props.intl.formatMessage({ id: 'facturasAbm.errors.form_validation.debe_coincidir_con_suma_tickets', defaultMessage: 'Debe coincidir con la suma de los importes de los tickets seleccionados' }) : ''
					}
				}
			}
		});

		this.initFileUpload();

		this.localCurrencyFormat = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' });
	}

	componentDidMount(nextProps, nextState) {
		if ((Security.hasPermission('FACTURAS_CREAR') && this.state.props.action === 'ADD') ||
	       (Security.hasPermission('FACTURAS_MODIFICAR') && this.state.props.action === 'EDIT') ||
	       (Security.hasPermission('FACTURAS_VISUALIZAR') && this.state.props.action === 'VIEW')) {
			this.ajaxHandler.subscribe(this);
			this.initForm();
		} else {
			this.setState({
				redirectTo: '/error'
			});
		}
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		$('div.tooltip[role="tooltip"]').remove();
		if(this.table) this.table.destroy();
	}

	handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;
		this.setState({
			formData: formDataCopy
		});
	}

	handleFormChangeSelectObject(name, object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = object ? { id: object.id } : null;
		this.setState({
			formData: formDataCopy
		});
	}

  handleFormChangeSelectString(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[name] = object === null ? '' : object.value;
    this.setState({
      formData: formDataCopy
    });
  }

  handleFormChangeSelectStringFactura(name, object) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = object === null ? '' : object.value;
		let vGerenciadores = false;
		formDataCopy['gerenciador']= null;
		formDataCopy.selectedTickets = [];
		formDataCopy.categoria='MANTENIMIENTO';
		if (object !== null) {
			vGerenciadores = true;
			if (object.value=='GESTORIA') {
				formDataCopy.categoria='GESTORIA';
			}
		}

    this.setState({
			formData: formDataCopy,
			vGerenciadores : vGerenciadores
    });
  }

	handleDatePickerFormChange(name, event) {
		let date = event ? event.format() : null;
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;
		this.setState({
			formData: formDataCopy
		});
	}

	handleDatePickerFormRawChange(name, date) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date === '' ? null : date;
		this.setState({
			formData: formDataCopy
		});
	}

	handleContinue() {
		this.setState({
			formStep: this.state.formStep + 1
		});
		window.scrollTo(0, 0);
	}

	handleBack() {
		this.setState({
			formStep: this.state.formStep - 1
		});
		window.scrollTo(0, 0);
	}

	handleCancel(event) {
		this.exit();
	}

	exit() {
		this.setState({
			redirectTo: '/facturas'
    	});
	}

	handleMultipleSelectClose() {
		$('#multiple_select').modal('toggle');
	}

	handleMultipleSelect() {
		let selectedIds = this.state.multipleSelectIds.replace(/[ ]{1,}/g,',').replace(/\n{1,}/g,',').split(/,|;|\.|\n|\r\n/);
		selectedIds = selectedIds.filter(e => e.length > 0);
		selectedIds = selectedIds.filter((item, pos) => selectedIds.indexOf(item) === pos);

		let errorsNotAvailable = [];

		for(let i in selectedIds) {
			if(!this.table.column(2).data().toArray().includes(parseInt(selectedIds[i]))) {
				errorsNotAvailable.push(selectedIds[i]);
			}
		}

		if(!errorsNotAvailable.length) {
			this.table.rows().every(function(rowIdx, tableLoop, rowLoop) {
				this.data().check = false;
				$(this.cell(rowIdx, 0).node()).find('input[type="checkbox"]').prop('checked', false);
				return true;
			});

			this.table.rows().every(function(rowIdx, tableLoop, rowLoop) {
				if(selectedIds.includes(this.data().id.toString())) {
					this.data().check = true;
					$(this.cell(rowIdx, 0).node()).find('input[type="checkbox"]').prop('checked', true);
				}
				return true;
			});
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.selectedTickets = this.table.data().toArray().filter((e) => e.check);
			this.setState({
				formData: formDataCopy
			});

			this.handleMultipleSelectClose();
		} else {
			Dialog.alert(this.props.intl,{
				title: this.props.intl.formatMessage({ id: 'facturasAbm.errors.select_tickets.siguientes_numero_de_tickets_invalidos', defaultMessage: 'Los siguientes números de tickets son inválidos' }),
				text: errorsNotAvailable.join(', ')
			});
		}
	}

	handleSubmit(event) {
		let component = this;

		if(!(component.state.formData.tipo === '' &&
			component.state.formData.puntoDeVenta === '' &&
			component.state.formData.numero === '' &&
			component.state.formData.importe === '' &&
			component.state.formData.fecha === null &&
			component.state.formData.adjunto === null) &&
			!(component.state.formData.tipo !== '' &&
			component.state.formData.puntoDeVenta !== '' &&
			component.state.formData.numero !== '' &&
			component.state.formData.importe !== '' &&
			component.state.formData.fecha !== null &&
			component.state.formData.adjunto !== null)) {
			component.setState({
				errors: ['facturasAbm.errors.no_puede_dejar_datos_factura_sin_completar']
			});
			window.scrollTo(0, 0);
		} else {
			component.setState({
				loading: true
			}, () => {
				component.ajaxHandler.fetch('/facturas' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
					method: this.props.action === 'ADD' ? 'POST' : 'PUT',
					body: JSON.stringify({
						...component.state.formData
					}),
				}).then(response => {
					if (response.status !== 400) {
						component.exit();
					} else {
						response
						.json()
						.then(data => {
							component.setState({
								errors: data.detalle
							});
						});
					}
					window.scrollTo(0, 0);
				}).catch((error) => {
					component.ajaxHandler.handleError(error);
				}).finally(() => {
					component.setState({
						loading: false
					});
				});
			});
		}
		event.preventDefault();
	}

	initFileUpload() {
		let component = this;

		this.fileUploadConfig = {
			showFiletypeIcon: false,
			postUrl: Config.get('apiUrlBase') + '/facturas/adjunto'
		};

		this.fileUploadDjsConfig = {
			addRemoveLinks: false,
			thumbnailMethod: 'crop',
			dictDefaultMessage: component.state.props.action !== 'VIEW' ? component.props.intl.formatMessage({ id: 'facturasAbm.invoice_data.add_file.haga_click_o', defaultMessage: 'Haga click aquí o ' })+"<br>"+component.props.intl.formatMessage({ id: 'facturasAbm.invoice_data.add_file.arrastre_un_archivo_este_area', defaultMessage: ' arrastre un archivo a este área.' }) : '',
			uploadMultiple: true,
			parallelUploads: 1,
			headers: {
				"Authorization-Token": localStorage.getItem("token")
			},
			previewTemplate: `
		        <div class="dz-preview dz-image-preview">
		          <div class="dz-image">
		            <img data-dz-thumbnail src="/images/file.png" />
		          </div>
		          <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress="" style="width: 0%;"></span></div>
		          <div class="dz-success-mark">
		            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Check</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF" sketch:type="MSShapeGroup"></path>      </g>    </svg>
		          </div>
		          <div class="dz-error-mark">
		            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Error</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <g id="Check-+-Oval-2" sketch:type="MSLayerGroup" stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475">          <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" sketch:type="MSShapeGroup"></path>        </g>      </g>    </svg>
		          </div>
		          <div class="dz-error-message"><span data-dz-errormessage></span></div>
		          <div class="dz-view"><a href="" target="_blank">VER</a></div>
		          ${component.state.props.action !== 'VIEW' ? '<div class="dz-remove"><a href="" data-dz-remove>ELIMINAR</a></div>' : ''}
		        </div>
		    `,
			init: function() {
				let dropzone = this;

				this.on('success', (file, response) => {
					file.adjunto = response.archivo;
					let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
					formDataCopy.adjunto = response.archivo;
					component.setState({
						formData: formDataCopy
					});
				});

				this.on('removedfile', (file) => {
					let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
					formDataCopy.adjunto = null;
					component.setState({
						formData: formDataCopy
					});

					if(file.adjunto) {
						component.ajaxHandler.fetch('/facturas/adjunto/' + file.adjunto, {
							method: 'DELETE'
						})
						.then(response => {
							if (response.status !== 204) {
								Dialog.alert(component.props.intl,{
									title: component.props.intl.formatMessage({ id: 'facturasAbm.errors.delete_file.error_al_eliminar_archivo', defaultMessage: 'Error al eliminar el archivo.' })
								});
							}
						}).catch((error) => {
							component.ajaxHandler.handleError(error);
						});
					}
				});

				this.on('error', (file, errormessage, response) => {
					dropzone.removeFile(file);
					Dialog.alert(component.props.intl,{
						title: errormessage.includes('files of this type') ? component.props.intl.formatMessage({ id: 'facturasAbm.errors.add_file.formato_archivo_invalido', defaultMessage: 'Formato de archivo inválido' }) : component.props.intl.formatMessage({ id: 'facturasAbm.errors.add_file.error_cargar_archivo', defaultMessage: 'Error al cargar el archivo.' })
					});
				});

				this.on('addedfile', function(file) {
					if (this.files.length > 1) {
		              this.removeFile(this.files[0]);
		            }
					$(file.previewElement).find('.dz-view > a').on('click', (e) => {
						component.ajaxHandler.fetch('/facturas/adjunto/' + file.adjunto, {
							method: 'GET',
							headers: {
								'Authorization-Token': localStorage.getItem("token")
							}
						}).then(response => {
							if (response.status === 200) {
								return response.blob();
							}
						}).then(fileBlob => {
							let fileUrl = URL.createObjectURL(fileBlob);
							window.open(fileUrl);
						}).catch(() => {});

						e.preventDefault();
					});
				});

				if (component.state.props.action === 'VIEW') {
					dropzone.disable();
				}
			}
		};

		this.fileUploadHandlers = {
			init: dz => {
				this.adjuntosDropzone = dz;
			}
		};
	}

	initForm()
	{
		let component = this;
		if (!this.state.viewGestoria) {
			let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
			formDataCopy.categoria = 'MANTENIMIENTO';
			formDataCopy.tipoFactura = 'MANTENIMIENTO';
			this.setState({
				formData: formDataCopy,
				vGerenciadores: true
			});
		}

		if(this.state.props.action === 'ADD') {
			let persona = JSON.parse(localStorage.getItem("persona"));
			if(persona && persona.usuario && persona.usuario.gerenciador)
			{
				let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
				formDataCopy.gerenciador = {id: persona.usuario.gerenciador.id};
				if (persona.usuario.perfil.permisos.includes('USUARIO_ACTUAR_COMO_GESTOR') || persona.usuario.perfil.permisos.includes('USUARIO_ACTUAR_COMO_ESCRIBANO')) {
					formDataCopy.categoria = 'GESTORIA';
					formDataCopy.tipoFactura = 'GESTORIA';
				}
				component.setState({
					usuarioGerenciador: true,
					formData: formDataCopy,
					gerenciadores: [{value: persona.usuario.gerenciador.id, label: persona.usuario.gerenciador.razonSocial}]
				}, () => {
					if (persona.usuario.perfil.permisos.includes('USUARIO_ACTUAR_COMO_GESTOR') || persona.usuario.perfil.permisos.includes('USUARIO_ACTUAR_COMO_ESCRIBANO')) {
						this.initGridOT();
					} else {
						this.initGrid();
					}
				});
			} else {
				this.setState({
					gerenciadoresLoading: true,
					tiposFacturaLoading: true,
					regionLoading: true,
					subregionLoading: true
				});

				Promise.all([
					component.ajaxHandler.getJson('/gerenciadores/select-filtered-by-user'),
					component.ajaxHandler.getJson('/subregiones/select-all'),
					component.ajaxHandler.getJson('/regiones/select')
				]).then((data) => {
					let gerenciadores = data[0] ? data[0] : [];
					let subregion = data[1] ? data[1] : [];
					let region = data[2] ? data[2] : [];
					component.setState({
						gerenciadores: gerenciadores,
						gerenciadoresLoading: false,
						subregion: subregion,
						region: region,
						tiposFacturaLoading: false,
						regionLoading: false,
						subregionLoading: false
					});
				});
			}
		} else {
			Promise.all([
				component.ajaxHandler.getJson('/facturas/' + this.state.props.match.params.id),
				component.ajaxHandler.getJson('/tickets/factura/' + this.state.props.match.params.id),
				component.ajaxHandler.getJson('/ordenestrabajo/factura/' + this.state.props.match.params.id)
			]).then((data) => {
				let formData =  data[0] ? Object.assign(this.state.formData, data[0]) : null;
				formData.tipoFactura = {id: 'MANTENIMIENTO',label: 'MANTENIMIENTO'};
				formData.quincena = {id: formData.quincena};
				formData.mes = {id: formData.mes};
				formData.selectedTickets = data[1];
				if (formData.categoria!='MANTENIMIENTO') {
					formData.tipoFactura = {id: 'GESTORIA',label: 'GESTORIA'};
					formData.selectedTickets = data[2];
				}

				if(formData) component.setState({
					formData: formData,
					vGerenciadores: true
				});

				if (component.state.formData.adjunto) {
					let fileUrl = '/facturas/adjunto/' + component.state.formData.adjunto;
					let responseContentType;

					component.ajaxHandler.fetch(fileUrl, {
						method: 'GET',
						headers: {
							'Authorization-Token': localStorage.getItem("token")
						}
					}).then(response => {
						if (response.status === 200) {
							responseContentType = response.headers.get('Content-Type');
							return response.blob();
						}
					}).then(imgBlob => {
						let imgUrl = URL.createObjectURL(imgBlob);
						var mockFile = {
							adjunto: component.state.formData.adjunto
						};
						this.adjuntosDropzone.emit("addedfile", mockFile);
						this.adjuntosDropzone.files.push(mockFile);
						this.adjuntosDropzone.emit("thumbnail", mockFile, responseContentType.includes('image') ? imgUrl : '/images/file.png');
						this.adjuntosDropzone.emit("complete", mockFile);
					}).catch(() => {});
				}
			});
		}

		window.scrollTo(0, 0);
    }

	handleGerenciadorChange(object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.gerenciador = null;
		formDataCopy.selectedTickets = [];
		this.setState({
			formData: formDataCopy
		}, () => {
			formDataCopy.gerenciador = object ? {
				id: object.value,
				label: object.label
			} : null;
			this.setState({
				formData: formDataCopy
			}, () => {
				if (formDataCopy.tipoFactura == "MANTENIMIENTO") {
					this.initGrid();
				}
				else{
					this.initGridOT();
				}
			});
		});
	}

	handleRegionChange(object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.region = null;
		formDataCopy.selectedTickets = [];
		this.setState({
			formData: formDataCopy
		}, () => {
			formDataCopy.region = object ? {
				id: object.value,
				label: object.label
			} : null;
			this.setState({
				formData: formDataCopy
			}, () => {
				this.table.ajax.url(Config.get('apiUrlBase') + '/tickets/facturables/grid/' + this.state.formData.gerenciador.id + '/' + (this.state.formData.region ? this.state.formData.region.id : 0) + '/' + (this.state.formData.subregion ? this.state.formData.subregion.id : 0)).load();
			});
		});
	}

	handleSubRegionChange(object) {
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy.subregion = null;
		formDataCopy.selectedTickets = [];
		this.setState({
			formData: formDataCopy
		}, () => {
			formDataCopy.subregion = object ? {
				id: object.value,
				label: object.label
			} : null;
			this.setState({
				formData: formDataCopy
			}, () => {
				this.table.ajax.url(Config.get('apiUrlBase') + '/tickets/facturables/grid/' + this.state.formData.gerenciador.id + '/' + (this.state.formData.region ? this.state.formData.region.id : 0) + '/' + (this.state.formData.subregion ? this.state.formData.subregion.id : 0)).load();
			});
		});
	}

	initGridOT(){
		let component = this;
		if(this.state.formData.gerenciador) {
			if(this.table) this.table.destroy();
			let urlgridFacturacion = '/ordenestrabajo/facturables/grid/';
			let filters = [];

			Promise.all([this.ajaxHandler.getJson('/servicios/tipo-ticket/GESTORIA/select')]).then(data => {

				filters = {
					servicio: data[0] ? data[0] : []
				};
				this.table = $(this.refs.grid).DataTable(
					Object.assign({
						dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
						scrollY: "240px",
						scrollCollapse: true,
						paging: false,
						stateSave: false,
						processing: true,
						serverSide: false,
						deferRender: true,
						order: [[ 1, "desc" ]],
						language: {
							"sInfo": "_TOTAL_ tickets",
						},
						ajax: {
							type: 'GET',
							url: Config.get('apiUrlBase') + urlgridFacturacion + component.state.formData.gerenciador.id,
							headers: {
								'Authorization-Token': localStorage.getItem("token")
							},
							dataSrc: function(response) {
								return response;
							},
							error: function(xhr, error, thrown) {
								component.ajaxHandler.handleResponseErrorsIsValid(xhr);
							},
							cache: false
						},
						initComplete: function(settings) {
							let stateColumns = null;

							var sets = settings;
							var index = 0;

							this.api().columns().every(function() {
								var column = this;

								if (sets.aoColumns[index].filterType) {
									if (sets.aoColumns[index].filterType === 'select') {
										var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.search_buscar', defaultMessage: 'Buscar...' })+'</option></select>')
											.appendTo($(column.footer()).empty())
											.on('change', function() {
												var val = $.fn.dataTable.util.escapeRegex($(this).val());
												column
													.search(val ? val : '', true, false)
													.draw();
											});
										if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
											filters[sets.aoColumns[index].name].map(e => {
												select.append('<option value="' + e.value + '">' + e.label + '</option>');
												return true;
											});
										} else {
											column.data().unique().sort().each(function(d, j) {
												select.append('<option value="' + d + '">' + d + '</option>');
											});
										}
										if (stateColumns && stateColumns[index].search.search) select.val(stateColumns[index].search.search);
									}
									if (sets.aoColumns[index].filterType === 'input') {
										var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.search_placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
										if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
										input.appendTo($(column.footer()).empty());
										input.on('keyup change', function() {
											if (column.search() !== this.value) {
												column
													.search(this.value)
													.draw()
													.ajax.reload(null, false);
											}
										});
									}
								}
								index++;
								return '';
							});

							// Check
							let check = $('<input type="checkbox" id="check-all" data-togle="tooltip" data-placement="top" title="'+component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.checkbox_title.seleccionar_todo', defaultMessage: 'Seleccionaraa todo' })+'" />');
							check.appendTo($(this.api().column(0).footer()).empty());
							check.tooltip();
							let table = this;
							check.on('click', function() {
								let checked = $(this).prop('checked');
								$(this).attr('data-original-title', checked ? component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.checkbox_title_alternative.deseleccionar_todo', defaultMessage: 'Deseleccionar todo' }) : component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.checkbox_title_alternative.seleccionar_todo', defaultMessage: 'Seleccionar todo' }));
								$(this).tooltip('hide');
								table.api().rows().every(function(rowIdx, tableLoop, rowLoop) {
									this.data().check = checked;
									$(this.cell(rowIdx, 0).node()).find('input[type="checkbox"]').prop('checked', checked);
									return true;
								});
								let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
								formDataCopy.selectedTickets = component.table.data().toArray().filter((e) => e.check);

								component.setState({
									formData: formDataCopy
								});
							});

							$('div.dataTables_scrollFootInner > table.dataTable > tfoot > tr').appendTo('div.dataTables_scrollHeadInner > table.dataTable > thead');
						},
						columns: [{
							name: 'check',
							title: '',
							data: null,
							className: 'all',
							orderable: false,
							render: (data, type, full, meta) => {
								return '<input type="checkbox" id="check-' + data.id + '" ' + (data.check ? 'checked' : '') + ' />';
							},
							width: '5px',
							createdCell: (td, cellData, rowData, row, col) => {
								cellData.check = false;
								$(td).find('input[type="checkbox"]').on('click', () => {
									cellData.check = $(td).find('input[type="checkbox"]').prop('checked');

									let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
									formDataCopy.selectedTickets = component.table.data().toArray().filter((e) => e.check);
									component.setState({
										formData: formDataCopy
									});
								});
							},
						}, {
							name: 'id',
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.title_NOrden', defaultMessage: 'N. Orden' }),
							data: 'id',
							className: 'all',
							orderable: true,
							filterType: 'input'
						}, {
							title: 'Creado',
							name: 'fecha',
							className: 'all',
							data: 'fecha',
							render: function(data, type, full, meta) {
								if (type === 'sort') {
									return data;
								} else {
									return data ? Timezone.getDateForClient(data, null, 'L') : '';
								}
							},
							filterType: 'input'
						}, {
							name: 'servicio',
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.title_servicio', defaultMessage: 'Servicio' }),
							className: 'all',
							data: 'servicio',
							render: function(data, type, row) {
								if (type === 'filter') {
									return data && data.id ? data.id : '';
								} else {
									return data && data.nombre ? data.nombre : '';
								}
							},
							filterType: 'select'
						}, {
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.title_importe', defaultMessage: 'Importe' }),
							name: 'importe',
							className: 'all',
							data: 'importe',
							orderable: true,
							render: function(data, type, row) {
								return data ? component.localCurrencyFormat.format(data) : '';
							},
							filterType: 'input'
						}, {
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.title_gestion', defaultMessage: 'Gestion' }),
							name: 'concepto',
							className: 'all',
							data: 'concepto',
							filterType: 'select'
						}, {
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGridOT.columns_table.title_cantidad', defaultMessage: 'Cantidad' }),
							name: 'cantidad',
							className: 'all',
							data: 'cantidad',
							filterType: 'input'
						}],
						drawCallback: function() {
							$(this).find('.action').on('click', function() {
								let data = component.table.row($(this).parents('tr')).data();

							});
						}
					}, datatablesConfig(this.props.intl))
				);
			}).catch(function(error) {
				component.ajaxHandler.handleError(error);
			}).finally(() => {
				this.setState({
					loading: false
				});
			});
		}
	}

	initGrid() {
		let component = this;
		let region = 0;
		let subRegion = 0;

		if(this.state.formData.gerenciador) {

			region =  component.state.formData.region ? component.state.formData.region.id : 0;
			subRegion = component.state.formData.subregion ? component.state.formData.subregion.id : 0;

			if(this.table ) this.table.destroy();
			Promise.all([
				this.ajaxHandler.getJson('/servicios/select'),
				this.ajaxHandler.getJson('/regiones/select'),
				ConfigBusiness.get('facturas.region.habilitado') === 'true' ? true : false,
				ConfigBusiness.get('facturas.subregion.habilitado') === 'true' ? true : false,
				this.ajaxHandler.getJson('/subregiones/select-all'),
			]).then((data) => {
				let filters = {
					servicio: data[0] ? data[0] : [],
					region: data[1] ? data[1] : [],
					subregion: data[4] ? data[4] : []
				}

				component.setState({
					regionEnabled: data[2],
					subregionEnabled: data[3],
					region: data[1] ? data[1] : [],
					subregion: data[4] ? data[4] : []
				});

        region =  component.state.formData.region ? component.state.formData.region.id : 0;
				subRegion = component.state.formData.subregion ? component.state.formData.subregion.id : 0;

				this.table = $(this.refs.grid).DataTable(

					Object.assign({
						dom: 'r<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
						scrollY: "240px",
						scrollCollapse: true,
						paging: false,
						stateSave: false,
						processing: true,
						serverSide: false,
						deferRender: true,
						order: [[ 4, "desc" ]],
						language: {
							"sInfo": "_TOTAL_ tickets",
						},
						ajax: {
							type: 'GET',
							url: Config.get('apiUrlBase') + '/tickets/facturables/grid/' + component.state.formData.gerenciador.id + '/' + region + '/' + subRegion,
							headers: {
								'Authorization-Token': localStorage.getItem("token")
							},
							dataSrc: function(response) {
								return response;
							},
							error: function(xhr, error, thrown) {
								component.ajaxHandler.handleResponseErrorsIsValid(xhr);
							},
							cache: false
						},
						initComplete: function(settings) {
							let stateColumns = null;

							var sets = settings;
							var index = 0;

							this.api().columns().every(function() {
								var column = this;

								if (sets.aoColumns[index].filterType) {

									if (sets.aoColumns[index].filterType === 'select') {

										var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.search_buscar', defaultMessage: 'Buscar...' })+'</option></select>')
											.appendTo($(column.footer()).empty())
											.on('change', function() {
												var val = $.fn.dataTable.util.escapeRegex($(this).val());
												column
													.search(val ? val : '', true, false)
													.draw();
											});
										if (filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
											filters[sets.aoColumns[index].name].map(e => {
												select.append('<option value="' + e.value + '">' + e.label + '</option>');
												return true;
											});
										} else {
											column.data().unique().sort().each(function(d, j) {
												select.append('<option value="' + d + '">' + d + '</option>');
											});
										}
										if (stateColumns && stateColumns[index].search.search) {
											select.val(stateColumns[index].search.search);
										}
									}
									if (sets.aoColumns[index].filterType === 'input') {
										var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.search_placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
										if (stateColumns && stateColumns[index].search.search) input.val(stateColumns[index].search.search);
										input.appendTo($(column.footer()).empty());
										input.on('keyup change', function() {
											if (column.search() !== this.value) {
												column
													.search(this.value)
													.draw()
													.ajax.reload(null, false);
											}
										});
									}
								}
								index++;
								return '';
							});

							// Check
							let check = $('<input type="checkbox" id="check-all" data-togle="tooltip" data-placement="top" title="'+component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.checkbox_title.Seleccionar_todo', defaultMessage: 'Seleccionar todo' })+'" />');
							check.appendTo($(this.api().column(0).footer()).empty());
							check.tooltip();
							let table = this;
							check.on('click', function() {
								let checked = $(this).prop('checked');
								$(this).attr('data-original-title', checked ? component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.checkbox_title_alternative.deseleccionar_todo', defaultMessage: 'Deseleccionar todo' }) : component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.checkbox_title_alternative.seleccionar_todo', defaultMessage: 'Seleccionar todo' }));
								$(this).tooltip('hide');
								table.api().rows().every(function(rowIdx, tableLoop, rowLoop) {
									this.data().check = checked;
									$(this.cell(rowIdx, 0).node()).find('input[type="checkbox"]').prop('checked', checked);
									return true;
								});
								let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
								formDataCopy.selectedTickets = component.table.data().toArray().filter((e) => e.check);
								component.setState({
									formData: formDataCopy
								});
							});

							$('div.dataTables_scrollFootInner > table.dataTable > tfoot > tr').appendTo('div.dataTables_scrollHeadInner > table.dataTable > thead');
						},
						columns: [{
							name: 'check',
							title: '',
							data: null,
							className: 'all',
							orderable: true,
							render: (data, type, full, meta) => {
								return '<input type="checkbox" id="check-' + data.id + '" ' + (data.check ? 'checked' : '') + ' />';
							},
							createdCell: (td, cellData, rowData, row, col) => {
								cellData.check = false;
								$(td).find('input[type="checkbox"]').on('click', () => {
									cellData.check = $(td).find('input[type="checkbox"]').prop('checked');

									let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
									formDataCopy.selectedTickets = component.table.data().toArray().filter((e) => e.check);
									component.setState({
										formData: formDataCopy
									});
								});
							},
						}, {
							name: 'ticketTipo',
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.title_tipo', defaultMessage: 'Tipo' }),
							data: 'ticketTipo',
							className: 'all',
							orderable: true,
							render: function(data, type, full, meta) {
								if (type === 'filter') {
									return data
								} else {
									return '<div id="avatar"></div>';
								}
							},
							filterType: 'select'
						}, {
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.title_tkt', defaultMessage: 'Tkt' }),
							name: 'id',
							className: 'all',
							data: 'id',
							orderable: true,
							filterType: 'input'
						}, {
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.title_creado', defaultMessage: 'Creado' }),
							name: 'fechaHoraAlta',
							className: 'all',
							data: 'fechaHoraAlta',
							render: function(data, type, full, meta) {
								if (type === 'sort') {
									return data;
								} else {
									return data ? Timezone.getDateForClient(data, null, 'L') : '';
								}
							},
							filterType: 'input'
						}, {
							name: 'servicio',
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.title_servicio', defaultMessage: 'Servicio' }),
							className: 'all',
							data: 'servicio',
							render: function(data, type, row) {
								if (type === 'filter') {
									return data && data.id ? data.id : '';
								} else {
									return data && data.nombre ? data.nombre : '';
								}
							},
							filterType: 'select'
						}, {
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.title_entidad', defaultMessage: 'Entidad' }),
							name: 'movilPersona',
							className: 'all',
							data: 'movilPersona',
							width: '60px',
							render: function(data, type, row) {
								return data && data.descripcion ? data.descripcion : '';
							},
							filterType: 'input'
						}, {
							title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.columns_table.title_importe', defaultMessage: 'Importe' }),
							name: 'importe',
							className: 'all',
							data: 'importe',
							orderable: true,
							render: function(data, type, row) {
								return data ? component.localCurrencyFormat.format(data) : '';
							},
							filterType: 'input'
						}],
						drawCallback: function() {
							$(this).find('.action').on('click', function() {
								let data = component.table.row($(this).parents('tr')).data();

								if ($(this).hasClass('approve')) {
									swal({
										title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_authorize.confirma_aprobar', defaultMessage: 'Aprobar' }),
										text: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_authorize.confirma_aprobar_information.', defaultMessage: '¿Confirma que desea aprobar la factura?' }),
										icon: "info",
										content: {
											element: "textarea",
											attributes: {
												placeholder: "Observaciones",
												id: 'confirm-observacionesCambioEstado'
											}
										},
										buttons: {
											confirm: {
												text: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_authorize.confirma_aprobar.si', defaultMessage: 'Si' }),
												value: true,
												visible: true,
												className: "btn btn-primary",
												closeModal: false
											},
											cancel: {
												text: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_authorize.confirma_aprobar.no', defaultMessage: 'No' }),
												value: null,
												visible: true,
												className: "btn btn-danger",
												closeModal: true,
											}
										}
									}).then((isConfirm) => {
										if (isConfirm) {
											component.ajaxHandler.fetch('/facturas/' + data.id + '/aprobar', {
												method: 'POST',
												body: JSON.stringify({
													observaciones: $('#confirm-observacionesCambioEstado').val()
												}),
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json',
													'Authorization-Token': localStorage.getItem("token")
												}
											}).then((response) => {
												if(response.status === 200) {
													component.table.ajax.reload(null, false);
													swal("Factura aprobada.", "", "success");
												}
											}).catch((error) => {
												component.ajaxHandler.handleError(error);
											});
										}
									});
								}

								if ($(this).hasClass('reject')) {
									swal({
										title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_refuse.confirma_rechazar', defaultMessage: 'Aprobar' }),
										text: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_refuse.confirma_rechazar_information', defaultMessage: '¿Confirma que desea rechazar la factura?' }),
										icon: "warning",
										content: {
											element: "textarea",
											attributes: {
												placeholder: "Observaciones",
												id: 'confirm-observacionesCambioEstado'
											}
										},
										buttons: {
											confirm: {
												text: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_refuse.confirma_rechazar.si', defaultMessage: 'Si' }),
												value: true,
												visible: true,
												className: "btn btn-primary",
												closeModal: false
											},
											cancel: {
												text: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.modal_refuse.confirma_rechazar.no', defaultMessage: 'No' }),
												value: null,
												visible: true,
												className: "btn btn-danger",
												closeModal: true,
											}
										}
									}).then((isConfirm) => {
										if (isConfirm) {
											component.ajaxHandler.fetch('/facturas/' + data.id + '/rechazar', {
												method: 'POST',
												body: JSON.stringify({
													observaciones: $('#confirm-observacionesCambioEstado').val()
												}),
												headers: {
													'Accept': 'application/json',
													'Content-Type': 'application/json',
													'Authorization-Token': localStorage.getItem("token")
												}
											}).then((response) => {
												if(response.status === 200) {
													component.table.ajax.reload(null, false);
													swal("Factura rechazada.", "", "success");
												}
											}).catch((error) => {
												component.ajaxHandler.handleError(error);
											});
										}
									});
								}
							});
						},
						rowCallback: function(row, data) {
							$('#avatar', row).addClass('dt-user-avatar');
							switch(data.ticketTipo){
								case 'PREVENTIVO':
									$('#avatar', row).addClass('paleta-preventivo');
									break;
								case 'VENCIMIENTO':
									$('#avatar', row).addClass('paleta-vencimiento');
									break;
								case 'GESTORIA':
									$('#avatar', row).addClass('paleta-gestoria');
									break;
								case 'CORRECTIVO':
									$('#avatar', row).addClass('paleta-correctivo');
									break;
								default:
									break;
							}
							$('#avatar', row).html(data.ticketTipo.substr(0, 1).toUpperCase());
						}
					}, datatablesConfig(this.props.intl))
				);

				new $.fn.dataTable.Buttons(this.table, {
					buttons: [{
						name: 'multipleSelect',
						text: '<i id="multipleSelect" class="ft-check-square"></i>',
						titleAttr: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.tools_multiple_selection.title_seleccionar_multiples_tickets', defaultMessage: 'Seleccionar múltiples de tickets' }),
						action: function (e, dt, node, config) {
							component.setState({
								multipleSelectIds: ''
							});
							$('#multiple_select').modal({backdrop: 'static'});
						},
					},{
						name: 'Export',
						text: '<i id="Exportar" class="ft-upload"></i>',
						titleAttr: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.tools_export.title_exportacion_tickets', defaultMessage: 'Exportación de tickets' }),
						action: function (e, dt, node, config) {
							swal({
								title: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.tools_export.title_information_exportacion_tickets', defaultMessage: 'Se están exportando los datos.' }),
								text: component.props.intl.formatMessage({ id: 'facturasAbm.initGrid.tools_export.information_esta_accion_puede_demorar', defaultMessage: 'Esta acción puede demorar.' }),
								buttons: {}
							});
							component.ajaxHandler.fetch('/facturas/exportar-tickets-excel/'+ (component.state.formData.gerenciador ? component.state.formData.gerenciador.id : null) +'/'+(component.state.formData.region ? component.state.formData.region.id : null) +'/'+(component.state.formData.subregion ? component.state.formData.subregion.id : null), {
								method: 'GET'
							}).then(response => {
							if (response.status === 200) {
								return response.blob();
							}
							}).then(fileBlob => {
								let fileUrl = URL.createObjectURL(fileBlob);
								$("<a />", {
									href: fileUrl,
									download: "fleet-tickets-facturables_" + component.state.formData.gerenciador.label + ".xlsx"
								}).appendTo("body").get(0).click();
							}).catch(() => {
							}).finally(() => {
								swal.close();
							});
						}
					}]
				});

				this.table.buttons(0, null).container().appendTo('#buttons');

				this.table.button('multipleSelect:name')
					.nodes()
					.attr('data-toggle', 'tooltip')
					.attr('data-position', 'top');
				$('[data-toggle="tooltip"]').tooltip();
				this.table.button('Export:name')
					.nodes()
					.attr('data-toggle', 'tooltip')
					.attr('data-position', 'top');
				$('[data-toggle="tooltip"]').tooltip();
			}).catch(function(error) {
				component.ajaxHandler.handleError(error);
			}).finally(() => {
				this.setState({
					loading: false
				});
			});
		}
	}

	render() {
		this.formValidation.validate();
		let state = this.state;
		let formData = state.formData;
		let validationState = this.formValidation.state;

		let gerenciador = formData.gerenciador && state.gerenciadores && state.gerenciadores.length ? state.gerenciadores.find(e => e.value === formData.gerenciador.id) : null;
		let subregion = formData.subregion && state.subregion && state.subregion.length ? state.subregion.find(e => e.value === formData.subregion.id) : null;
		let region = formData.region && state.region && state.region.length ? state.region.find(e => e.value === formData.region.id) : null;
		let mes = formData.mes ? state.meses.find(e => e.id === formData.mes.id) : null;
		let quincena = formData.quincena ? state.quincenas.find(e => e.id === formData.quincena.id) : null;
		let tipoFactura = formData.tipoFactura !== '' ? state.tiposFactura.find(e => e.value === formData.tipoFactura) : null;
		let vGerenciadores = formData.vGerenciadores;

		return (
			<React.Fragment>
				{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				<div className="row">
					<div className="col-12">
						<div className="alert alert-danger" role="alert" hidden={state.errors.length===0}>
							{state.errors.map((e, i) => <li key={i}>{e}</li>)}
						</div>
						<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit} noValidate>
							<div className="form-body" hidden={state.formStep !== 0}>
								{!state.usuarioGerenciador ? (

								<div className="card pull-up pb-0">
									<div className="card-content">
										<div className="card-body pb-0">
											<div className="row" style={{display: state.viewGestoria ? '' : 'none'}}>
												{/* TIPO FACTURA */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="tipoFactura">
															<FormattedMessage id="facturasAbm.render_formStep.label_tipo_facturacion" defaultMessage="Tipo de Facturación:"/>
														</label>
														{this.props.action !== 'ADD' ? (
														<div className="col-md-8 form-control-static col-form-label form-value">{formData.tipoFactura ? formData.tipoFactura.label : ''}</div>
														) : (
															<div className="col-md-8">
																<div>
																	<Select
																		id="tipoFactura"
																		name="tipoFactura"
																		placeholder={!state.tiposFacturaLoading ? this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep.select_placeholder_tipo_facturacion', defaultMessage: 'Seleccione' }) : ''}
																		options={state.tiposFactura}
																		valueKey='value'
																		labelKey='label'
																		value={tipoFactura ? { value: tipoFactura.value, label: tipoFactura.label } : null}
																		onChange={(e) => this.handleFormChangeSelectStringFactura('tipoFactura',e)}
																		isLoading={state.tiposFacturaLoading}
																			disabled={state.tiposFacturaLoading}
																	/>
																</div>
															</div>
															)}
														</div>
												</div>
											</div>
											<div className="row" style={{display: state.vGerenciadores ? '' : 'none' }}>
												{/* GERENCIADOR */}
												<div className="col-md-6">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="gerenciador">
															{state.viewGestoria ? this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep.label_proveedor', defaultMessage: 'Proveedor:' }) : this.props.intl.formatMessage({ id: 'facturasAbm.render.label_gerenciador', defaultMessage: 'Gerenciador:' })}
														</label>
														{this.props.action !== 'ADD' ? (
															<div className="col-md-8 form-control-static col-form-label form-value">{formData.gerenciador ? formData.gerenciador.razonSocial : ''}</div>
														) : (
															<div className="col-md-8">
																<div>
																<Select
																	id="gerenciador"
																	name="gerenciador"
																	placeholder={!state.gerenciadoresLoading ? this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep.select_placeholder_proveedor_gerenciador', defaultMessage: 'Seleccione' }) : ''}
																	options={state.gerenciadores}
																	valueKey='value'
																	labelKey='label'
																	value={gerenciador ? { value: gerenciador.id, label: gerenciador.label } : null}
																	onChange={(e) => this.handleGerenciadorChange(e)}
																	isLoading={state.gerenciadoresLoading}
																		disabled={state.gerenciadoresLoading}
																/>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								) : ''}

								{state.props.action === 'ADD' && formData.gerenciador ? (
								<React.Fragment>
									{this.state.regionEnabled ? (
									<React.Fragment>
									<div className="card pull-up" style={{height: '80px'}}>
										<div className="card-content">
											<div className="card-body">
												<div className="row">

														{/* REGION */}
														<div className="col-md-6">
					                              	<div className="form-group row">
						                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="region">
						                                	<FormattedMessage id="facturasAbm.render_formStep.label_region" defaultMessage="Región:"/>
						                                </label>
						                                {this.props.action !== 'ADD' ? (
																							<div className="col-md-8 form-control-static col-form-label form-value">{formData.region ? formData.region.nombre : ''}</div>
														) : (
						                                		<div className="col-md-8">
						                                  	<div>
						                                    <Select
						                                      id="region"
						                                      name="region"
						                                      placeholder={!state.gerenciadoresLoading ? this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep.select_placeholder_region', defaultMessage: 'Seleccione' }) : ''}
						                                      options={state.region}
						                                      valueKey='value'
						                                      labelKey='label'
						                                      value={region ? { value: region.id, label: region.label } : null}
						                                      onChange={(e) => this.handleRegionChange(e)}
						                                      isLoading={state.regionLoading}
                                      						  disabled={state.regionLoading}
						                                    />
						                                  	</div>
						                                		</div>
						                                )}
					                              	</div>
						              </div>


                         {/* SUBREGION */}
												<div className="col-md-6">
					                              	<div className="form-group row">
						                                <label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="subregion">
						                                	<FormattedMessage id="facturasAbm.render_formStep.label_subRegion" defaultMessage="Sub Región:"/>
						                                </label>
						                                {this.props.action !== 'ADD' ? (
																							<div className="col-md-8 form-control-static col-form-label form-value">{formData.subregion ? formData.subregion.nombre : ''}</div>
														) : (
						                                		<div className="col-md-8">
						                                  	<div>
						                                    <Select
						                                      id="subregion"
						                                      name="subregion"
						                                      placeholder={!state.subregionLoading ? this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep.select_placeholder_subRegion', defaultMessage: 'Seleccione' }) : ''}
						                                      options={state.subregion}
						                                      valueKey='value'
						                                      labelKey='label'
						                                      value={subregion ? { value: subregion.id, label: subregion.label } : null}
						                                      onChange={(e) => this.handleSubRegionChange(e)}
						                                      isLoading={state.subregionLoading}
                                      						  disabled={state.subregionLoading}
						                                    />
						                                  	</div>
						                                		</div>
						                                )}
					                              	</div>
						              </div>


				              </div>
										</div>
									</div>
								</div>
							</React.Fragment>
							) : ''}





								<div className="card">
									<div className="card-content">
										<div className="card-body">
											<div className="container-fluid">
												<FormattedMessage id="facturasAbm.render_formStep.label_table_invoices" defaultMessage="Seleccione los tickets que desea agregar a la factura."/>
											</div>
											<div className="container-fluid">
												<div className="row dt-icons">
													<div className="col-6">
													</div>
													<div className="col-6" id="buttons"></div>
												</div>
											</div>
											<div className="table-responsive">
												<table id="dataTable" className="table nowrap table-hover" ref="grid" width="100%">
													<tfoot>
														<tr style={{backgroundColor: '#fff'}}>
															<th width="5px"></th>
															<th className="dt-search-header"></th>
															<th className="dt-search-header"></th>
															<th className="dt-search-header"></th>
															<th className="dt-search-header"></th>
															<th className="dt-search-header"></th>
															<th className="dt-search-header"></th>

														</tr>
													</tfoot>
												</table>
											</div>
											<hr />
											<div className="row">
												<div className="col-md-6">
													<div className="form-group row ml-2">
														<div className="form-control-static col-form-label"><strong><FormattedMessage id="facturasAbm.render_formStep.selected_invoices.label_tickets_seleccionados" defaultMessage="Tickets seleccionados: "/>{state.formData.selectedTickets.length}</strong></div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group row">
														<div className="form-control-static col-form-label"><strong><FormattedMessage id="facturasAbm.render_formStep.selected_invoices.label_importe_total" defaultMessage="Importe total: "/>{this.localCurrencyFormat.format(state.formData.selectedTickets.map((e) => e.importe).reduce((a, b) => a + b, 0))}</strong></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								</React.Fragment>
								) : ''}
								{state.props.action === 'ADD' ? (
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<div className="text-cd text-right">
												<button type="button" className="btn btn-primary mr-1" disabled={formData.selectedTickets.length === 0} onClick={this.handleContinue.bind(this)} hidden={!formData.gerenciador}>
													<i className="fa fa-chevron-circle-right"></i><FormattedMessage id="facturasAbm.render_formStep.end_button.continuar" defaultMessage=" Continuar"/>
												</button>
												<button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
													<i className="fa fa-times-circle"></i><FormattedMessage id="facturasAbm.render_formStep.end_button.cancelar" defaultMessage=" Cancelar"/>
												</button>
											</div>
										</div>
									</div>
								</div>
								) : ''}
							</div>

							<div className="form-body" hidden={state.props.action === 'ADD' && state.formStep !== 1}>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section mt-2">
												<i className="la la-ticket"></i><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.selected_invoices.label_tickets_seleccionados" defaultMessage=" Tickets seleccionados"/>
											</h4>
											{state.props.action !== 'ADD' && this.state.formData.categoria=='MANTENIMIENTO' ? (
											<div className="row">
												<div className="col-md-12" style={{maxHeight: '300px', overflowY: 'auto'}}>
													<table className="table">
														<thead className="thead-fleet">
															<tr>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table_mantenimiento.label_tipo" defaultMessage="Tipo"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table.label_ticket" defaultMessage="Ticket"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table.label_creado" defaultMessage="Creado"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table.label_servicio" defaultMessage="Servicio"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table.label_entidad" defaultMessage="Entidad"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table.label_importe" defaultMessage="Importe"/></th>

															</tr>
														</thead>
														<tbody>
														{ this.state.formData.categoria=='MANTENIMIENTO' ?
															this.state.formData.selectedTickets.map((ticket, index)=>{
															return(
															<tr key={index}>
																<td className="align-middle">{ticket.ticketTipo}</td>
																<td className="align-middle">{ticket.id}</td>
																<td className="align-middle">{ Timezone.getDateForClient(ticket.fechaHoraAlta, 'YYYY-MM-DD HH:mm:ss', 'L') }</td>
																<td className="align-middle">{ticket.servicio.nombre}</td>
																<td className="align-middle">{ticket.movilPersona.descripcion}</td>
																<td className="align-middle">{'$' + (Math.round(ticket.importe * 100) / 100).toString().replace(".", ",")}</td>
															</tr>
															);
															}) : this.state.formData.selectedTickets.map((OT, index)=>{
																return(
																<tr key={index}>
																	<td className="align-middle">{OT.id}</td>
																	<td className="align-middle">{OT.id}</td>
																	<td className="align-middle">{ Timezone.getDateForClient(OT.fecha, 'YYYY-MM-DD HH:mm:ss', 'L') }</td>
																	<td className="align-middle">{OT.servicio.nombre}</td>
																	<td className="align-middle">{OT.id}</td>
																	<td className="align-middle">{'$' + (Math.round(OT.importe * 100) / 100).toString().replace(".", ",")}</td>
																</tr>
																);
																})
																}
														</tbody>
													</table>
												</div>
											</div>
											) : ''}
											{state.props.action !== 'ADD' && this.state.formData.categoria!='MANTENIMIENTO' ? (
											<div className="row">
												<div className="col-md-12" style={{maxHeight: '300px', overflowY: 'auto'}}>
													<table className="table">
														<thead className="thead-fleet">
															<tr>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table_not_mantenimiento.label_Id" defaultMessage="Id"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table_not_mantenimiento.label_NOrdenT" defaultMessage="N. Orden T."/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table_not_mantenimiento.label_creado" defaultMessage="Creado"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table_not_mantenimiento.label_servicio" defaultMessage="Servicio"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table_not_mantenimiento.label_cantidad" defaultMessage="Cantidad"/></th>
																<th scope="col"><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.columns_table_not_mantenimiento.label_importe" defaultMessage="Importe"/></th>
															</tr>
														</thead>
														<tbody>

															{this.state.formData.selectedTickets.map((OT, index)=>{
																return(
																<tr key={index}>
																	<td className="align-middle">{OT.hijoOT}</td>
																	<td className="align-middle">{OT.id}</td>
																	<td className="align-middle">{ Timezone.getDateForClient(OT.fecha, 'YYYY-MM-DD HH:mm:ss', 'L') }</td>
																	<td className="align-middle">{OT.servicio.nombre}</td>
																	<td className="align-middle">{OT.cantidad}</td>
																	<td className="align-middle">{'$' + (Math.round(OT.importe * 100) / 100).toString().replace(".", ",")}</td>
																</tr>
																);
																})
															}
														</tbody>
													</table>
												</div>
											</div>
											) : ''}
											<div className="row">
												<div className="col-md-6">
													<div className="form-group row ml-2">
														<div className="form-control-static col-form-label"><strong><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.selected_invoices.label_tickets_seleccionados: " defaultMessage="Tickets seleccionados: "/>{state.formData.selectedTickets.length}</strong></div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group row">
														<div className="form-control-static col-form-label"><strong><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.selected_invoices.label_importe_total" defaultMessage="Importe total: "/>{this.localCurrencyFormat.format(state.formData.selectedTickets.map((e) => e.importe).reduce((a, b) => a + b, 0))}</strong></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="form-body" hidden={state.props.action === 'ADD' && state.formStep !== 1}>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section mt-2">
												<i className="ft-calendar"></i><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.period_data.label" defaultMessage=" Datos del Período "/><div className="float-right" style={{fontSize:'14px'}}><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.period_data.campos_requeridos" defaultMessage="* campos requeridos"/></div>
											</h4>

											<div className="row">
												{/* MES */}
												<div className="col-md-3">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="mes">
															<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.period_data.label_mes" defaultMessage="Mes *:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-8 form-control-static col-form-label form-value">{mes.label}</div>
														) : (
														<div className="col-md-8">
															<Select
																id="mes"
																name="mes"
																placeholder={this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep_otherThanOne.period_data.placeholder_mes', defaultMessage: 'Seleccione (Requerido)' })}
																options={state.meses}
																valueKey='value'
																labelKey='label'
																value={mes ? { value: mes.id, label: mes.label } : null}
																onChange={(e) => this.handleFormChangeSelectObject("mes", e)}
															/>
															<div className="help-block text-danger field-message" hidden={validationState.formData.mes.pristine || validationState.formData.mes.valid}>{validationState.formData.mes.message}</div>
														</div>
														)}
													</div>
												</div>
												{/* AÑO */}
												<div className="col-md-2">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="anio">
															<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.period_data.label_anio" defaultMessage="Año *:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-8 form-control-static col-form-label form-value">{formData.anio}</div>
														) : (
														<div className="col-md-8">
															<input type="number" className="form-control" id="anio" name="anio" placeholder={this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep_otherThanOne.period_data.placeholder_anio', defaultMessage: '(Requerido)' })} value={formData.anio} onChange={this.handleInputFormChange} maxLength="4" min="1000" max="9999" autoComplete="off" />
															<div className="help-block text-danger field-message" hidden={validationState.formData.anio.pristine || validationState.formData.anio.valid}>{validationState.formData.anio.message}</div>
														</div>
														)}
													</div>
												</div>

												{/* Quincena */}
												<div className="col-md-3">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="quincena">
															<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.period_data.label_quincena" defaultMessage="Quincena *:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-8 form-control-static col-form-label form-value">{quincena.label}</div>
														) : (
														<div className="col-md-8">
															<Select
																id="quincena"
																name="quincena"
																placeholder={this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep_otherThanOne.period_data.placeholder_quincena', defaultMessage: 'Seleccione (Requerido)' })}
																options={state.quincenas}
																valueKey='value'
																labelKey='label'
																value={quincena ? { value: quincena.id, label: quincena.label } : null}
																onChange={(e) => this.handleFormChangeSelectObject("quincena", e)}
															/>
															<div className="help-block text-danger field-message" hidden={validationState.formData.quincena.pristine || validationState.formData.quincena.valid}>{validationState.formData.quincena.message}</div>
														</div>
														)}
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="form-body" hidden={state.props.action === 'ADD' && state.formStep !== 1}>
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<h4 className="form-section mt-2">
												<i className="ft-file-text"></i><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label" defaultMessage=" Datos de la Factura"/>
											</h4>

											<div className="row">
												{/* TIPO */}
												<div className="col-md-2">
													<div className="form-group row">
														<label className="col-md-6 label-control col-form-label text-nowrap" htmlFor="tipo">
															<i className="ft-file-text"></i><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label_tipo" defaultMessage="Tipo:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-6 form-control-static col-form-label form-value">{formData.tipo ? formData.tipo : ''}</div>
														) : (
														<div className="col-md-6">
															<div>
																<input type="text" className="form-control" id="tipo" name="tipo" placeholder="" value={formData.tipo ? formData.tipo : ''} onChange={e => {e.target.value = e.target.value.toUpperCase(); this.handleInputFormChange(e);}} maxLength="1" />
															</div>
														</div>
														)}
													</div>
												</div>

												{/* PUNTO DE VENTA */}
												<div className="col-md-3">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="puntoDeVenta">
															<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label_pto_de_venta" defaultMessage="Pto. de Venta:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-8 form-control-static col-form-label form-value">{formData.puntoDeVenta ? formData.puntoDeVenta : ''}</div>
														) : (
														<div className="col-md-8">
															<div>
																<input type="text" className="form-control" id="puntoDeVenta" name="puntoDeVenta" placeholder="" value={formData.puntoDeVenta ? formData.puntoDeVenta : ''} onChange={this.handleInputFormChange} autoComplete="off" />
															</div>
														</div>
														)}
													</div>
												</div>

												{/* NUMERO */}
												<div className="col-md-3">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="numero">
															<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label_pto_de_numero" defaultMessage="Número:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-8 form-control-static col-form-label form-value">{formData.numero ? formData.numero : ''}</div>
														) : (
														<div className="col-md-8">
															<div>
																<input type="text" className="form-control" id="numero" name="numero" placeholder="" value={formData.numero ? formData.numero : ''} onChange={this.handleInputFormChange} autoComplete="off" />
															</div>
														</div>
														)}
													</div>
												</div>
											</div>

											<div className="row">
												{/* FECHA */}
												<div className="col-md-4">
													<div className="form-group row">
														<label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="fecha">
															<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label_fecha" defaultMessage="Fecha:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-9 form-control-static col-form-label form-value">{formData.fecha != null ? Timezone.getDateForClient(formData.fecha, null, 'L') : ''}</div>
														) : (
														<div className="col-md-9">
															<div>
																<DatePicker
																	id="fecha"
																	name="fecha"
																	className="form-control date-picker-placeholder"
																	placeholderText={this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep_otherThanOne.period_data.placeholder_fecha', defaultMessage: 'DD/MM/AAAA' })}
																	selected={formData.fecha ? moment(formData.fecha) : null}
																	onChange={(event) => this.handleDatePickerFormChange("fecha", event)}
                                      								onChangeRaw={(event) => this.handleDatePickerFormRawChange("fecha", event.target.value)}
																	maxDate={moment()}
																	openToDate={moment()}
																	showMonthDropdown
																	showYearDropdown
																	dropdownMode="select"
																	autoComplete="off"
																/>
															</div>
														</div>
														)}
													</div>
												</div>

												<div className="col-md-4">
													<div className="form-group row">
														<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="importe">
															<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label_importe" defaultMessage="Importe:"/>
														</label>
														{this.props.action === 'VIEW' ? (
														<div className="col-md-8 form-control-static col-form-label form-value">{formData.importe ? '$' + formData.importe.toString().replace(".", ",") : ''}</div>
														) : (
														<div className="col-md-8">
															<input type="text" className="form-control" id="importe" name="importe" placeholder="" value={formData.importe ? formData.importe.toString().replace(".", ",") : ''} onChange={(e) => {if(e.target && e.target.value) {e.target.value = e.target.value.toString().replace(",", ".");} this.handleInputFormChange(e)}} autoComplete="off" />
															<div className="help-block text-danger field-message" hidden={validationState.formData.importe.pristine || validationState.formData.importe.valid}>{validationState.formData.importe.message}</div>
														</div>
														)}
													</div>
												</div>
											</div>

											{(this.props.action === 'VIEW' && formData.estado==='APROBADA' && state.sap)? (

												<div className="row">
													{/* WE */}
													<div className="col-md-4">
														<div className="form-group row">
															<label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="tipo">
																<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label_WE" defaultMessage="WE:"/>
															</label>
															<div className="col-md-9 form-control-static col-form-label form-value">{formData.we ? formData.we : ''}</div>
														</div>
													</div>
													{/* OE */}
													<div className="col-md-4">
														<div className="form-group row">
															<label className="col-md-4 label-control col-form-label text-nowrap" htmlFor="tipo">
																<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.label_OE" defaultMessage="OE:"/>
															</label>
															<div className="col-md-8 form-control-static col-form-label form-value">{formData.oe ? formData.oe : ''}</div>
														</div>
													</div>
												</div>

												) : (''
											)}
											<div className="row">
												{/* ARCHIVOS ADJUNTOS */}
												<div className="col-md-8">
													<DropzoneComponent id="archivos" config={this.fileUploadConfig} eventHandlers={this.fileUploadHandlers} djsConfig={this.fileUploadDjsConfig} />
												</div>
											</div>

											{state.props.action !== 'VIEW' && (
											<div className="alert alert-info" role="alert" style={{marginTop: '8px'}}>
												<FormattedMessage id="facturasAbm.render_formStep_otherThanOne.invoice_data.information_si_no_completan_datos_guardara_como_prefactura" defaultMessage="Si no se completan estos datos se guardará como una Prefactura. Los mismos podrán completarse luego."/>
											</div>
											)}
										</div>
									</div>
								</div>

								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body">
											<div className="text-cd text-right">
												{state.props.action === 'ADD' ? (
												<button type="button" className="btn btn-primary mr-1" onClick={this.handleBack.bind(this)}>
													<i className="fa fa-chevron-circle-left"></i><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.finish_button_atras" defaultMessage=" Atrás"/>
												</button>
												) : ''}
												{this.props.action !== 'VIEW' && (
												<button type="submit" className="btn btn-primary mr-1" disabled={!validationState.form.valid}>
													<i className="fa fa-check-circle"></i><FormattedMessage id="facturasAbm.render_formStep_otherThanOne.finish_button_guardar" defaultMessage=" Guardar"/>
												</button>
												)}
												<button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
													<i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep_otherThanOne.finish_button_volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'facturasAbm.render_formStep_otherThanOne.finish_button_cancelar', defaultMessage: 'cancelar' })}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>

				<div className="modal fade" tabIndex="-1" id="multiple_select" role="dialog" aria-labelledby="myModalLabel3">
					<div className="modal-lg modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-fleet">
								<h4 className="modal-title text-white" id="myModalLabel3"><i className="ft-check-square align-middle icon-modal-title"></i><FormattedMessage id="facturasAbm.render_modal.select_tickets.label_seleccionar_multiples_tickets" defaultMessage=" Seleccionar múltiples de tickets"/></h4>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							</div>
							<div className="modal-body modal-grey modal-mh ovf-x-hidden">
								<div className="row">
									<div className="col-md-12">
										{/*<div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
											{this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
										</div>*/}
										<form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
											<div className="form-body">
												<div className="card">
													<div className="card-content">
														<div className="card-body">
															<h4 className="form-section">
																<i className="la la-ticket"></i><FormattedMessage id="facturasAbm.render_modal.select_tickets.label_tickets" defaultMessage=" Tickets"/>
															</h4>
															<div className="form-group">
																<div className="col-md-12">
																	<div className="" htmlFor="multipleSelectIds">
																		<FormattedMessage id="facturasAbm.render_modal.select_tickets.information_ingrese_números_tickets_que_desea_seleccionar" defaultMessage="Ingrese los números de los tickets que desea seleccionar (separados por coma, espacio o salto de línea):"/>
																	</div>
																</div>
																<div className="col-md-12">
																	<textarea id="multipleSelectIds" name="multipleSelectIds" style={{width: '100%', height: '150px'}} value={this.state.multipleSelectIds} onChange={e => this.setState({multipleSelectIds: e.target.value})} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="modal-footer modal-grey">
								<button type="button" className="btn btn-default btn-fleet" onClick={this.handleMultipleSelect.bind(this)}><i className={this.state.loading ? 'la la-spinner spinner' : 'fa fa-check-circle'}></i><FormattedMessage id="facturasAbm.render_modal.select_tickets.button_aceptar" defaultMessage=" Aceptar"/></button>
								<button type="button" className="btn btn-danger" onClick={this.handleMultipleSelectClose}><i className="fa fa-times-circle"></i><FormattedMessage id="facturasAbm.render_modal.select_tickets.button_cancelar" defaultMessage=" Cancelar"/></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default injectIntl(FacturasAbm);
