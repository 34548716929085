import React, { useState, useEffect} from "react";
import { useIntl, FormattedMessage } from 'react-intl'
import * as Utils from '../../commons/utils/Utils'
import {
  Collapse,
  TextField,
  CircularProgress,
  Grid,
  Box,
} from '@material-ui/core';
import Switch from "react-switch";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'

const FILTER_STATE_INIT = {
  marca: null,
  estado: [],
  tipo: null,
  pais: null,
  region: null,
  base: null,
  titular: null,
  companiaOrigen: null,
  centroCosto: null,
  unidad:'',
  chasis: '',
  dominio: '',
  modelo: '',
  cecosDireccion: '',
  cecosMercado: '',
  cecosGerencia: '',
  cecosArea: '',
  responsable1: '',
  medidor: '',
  showInactivos: false,
}

const handleInitFilters = () => {
  let filterInit = FILTER_STATE_INIT

  let storage = JSON.parse(window.localStorage.getItem('MovilesGridFilter'))
  if(storage && storage.activo && storage.filtros){
    filterInit = storage.filtros
  }
  return filterInit
}

const MovilesGridFiltros = (props) => {
  const intl = useIntl();
  const columnsEnabled = props.columnsEnabled

  const [init,setInit] = useState(false)
  const [filtersLoading, setFiltersLoading] = useState(false)
  const [filtersState, setFiltersState] = useState(handleInitFilters)

  //Filters Selects values
  const [marcas, setMarcas] = useState([])
  const [estados, setEstados] = useState([])
  const [tipos, setTipos] = useState([])
  const [paises, setPaises] = useState([])
  const [regiones, setRegiones] = useState([])
  const [subRegiones, setSubRegiones] = useState([])
  const [bases, setBases] = useState([])
  const [titulares, setTitulares] = useState([])
  const [companiasOrigen, setCompaniasOrigen] = useState([])
  const [centrosCostos, setCentrosCostos] = useState([])

  useEffect(() => {
    fetchData()
  }, []);

  useEffect(() => {
    props.filterResetInputs && setFiltersState(FILTER_STATE_INIT)
  },[props.filterResetInputs])

  useEffect(() => {
    !props.dropdownActive && props.setFilterResetInputs(false)
    !props.dropdownActive && setFiltersLoading(false)
  }, [props.dropdownActive]);

  const fetchData = () => {
    setFiltersLoading(true)
    Promise.all([
      Utils.getData('/marcas/select'),
      ConfigBusiness.get('moviles.estados'),
      Utils.getData('/modelo-tipos/select'),
      Utils.getData('/regiones/select'),
      Utils.getData('/bases/select-filtered-by-user/movil'),
      Utils.getData('/movil-titulares/select'),
      ConfigBusiness.get('moviles.companiaOrigen.valores'),
      Utils.getData('/centros-costos/select'),
      Utils.getData('/regiones/select'),
      Utils.getData('/subregiones/select-all'),
      Utils.getData('/paises/select'),
    ]).then((data) => {
      setMarcas(data[0] ? data[0] : [])
      let estados = data[1] ? data[1].split(',') : null;
      let menuEstado = [];
      estados.forEach(estado => {
        menuEstado.push({ value: estado, label: estado });
      });
      setEstados(menuEstado);
      setTipos(data[2] ? data[2] : [])

      setPaises(data[10])
      
      setRegiones(data[3] ? data[3] : [])
      setBases(data[4] ? data[4] : [])
      setTitulares(data[5] ? data[5] : [])
      let companiasOrigen = data[6] ? data[6].split(',') : null;
      let menuCompaniasOrigen = [];
      companiasOrigen.forEach(compania => {
        menuCompaniasOrigen.push({ value: compania, label: compania });
      });
      setCompaniasOrigen(menuCompaniasOrigen)
      setCentrosCostos(data[7] ? data[7] : [])
      setRegiones(data[8] ? data[8] : [])
      setSubRegiones(data[9] ? data[9] : [])

      setFiltersLoading(false)
    }).catch((err) => {
      console.log(err);
    })
  }

  const handleChange = (name, object) => {
    setFiltersLoading(true);
    let query = {}

    if(!init){
      query = JSON.parse(JSON.stringify(filtersState));
      if(props.persisteFiltros){
        props.setSearchActive(true);
        props.setDropdownActive(true)
      }
      if(filtersState.estado.length){
        query['estado'] = filtersState.estado.map((estado)=>estado.value).join(',')
      }
      query['marca'] = filtersState.marca ? filtersState.marca.value : ''
      query['tipo'] = filtersState.tipo ? filtersState.tipo.value : ''
      query['pais'] = filtersState.pais ? filtersState.pais.value : ''
      query['region'] = filtersState.region ? filtersState.region.value : ''
      query['subRegion'] = filtersState.subRegion ? filtersState.subRegion.value : ''
      query['base'] = filtersState.base ? filtersState.base.value : ''
      query['titular'] = filtersState.titular ? filtersState.titular.value : ''
      query['companiaOrigen'] = filtersState.companiaOrigen ? filtersState.companiaOrigen.value : ''
      query['centroCosto'] = filtersState.centroCosto ? filtersState.centroCosto.value : ''

      query['unidad'] = filtersState.unidad ? filtersState.unidad : ''
      query['chasis'] = filtersState.chasis ? filtersState.chasis : ''
      query['dominio'] = filtersState.dominio ? filtersState.dominio : ''
      query['modelo'] = filtersState.modelo ? filtersState.modelo : ''
      query['cecosDireccion'] = filtersState.cecosDireccion ? filtersState.cecosDireccion : ''
      query['cecosMercado'] = filtersState.cecosMercado ? filtersState.cecosMercado : ''
      query['cecosGerencia'] = filtersState.cecosGerencia ? filtersState.cecosGerencia : ''
      query['cecosArea'] = filtersState.cecosArea ? filtersState.cecosArea : ''
      query['responsable1'] = filtersState.responsable1 ? filtersState.responsable1 : ''
      query['medidor'] = filtersState.medidor ? filtersState.medidor : ''
      query['showInactivos'] = filtersState.showInactivos ? filtersState.showInactivos : ''
      
      //Controlo si viene filtro de kpi por dashboard
      if(props.kpi){
        query['filtroKpi'] = props.kpi.filter
        props.handleAlertClick(props.kpi.filter)
      }
      setInit(true)
    }
    else{
        object && object !== '' && props.setSearchActive(true);

        query = { ...props.filterQuery };

        switch (name) {
          case 'estado': // Select multiples
            query[name] = object ? object.map((estado) => estado.value).join(',') : '';
            break;
          case 'marca': // Select
          case 'tipo':
          case 'pais':
          case 'region':
          case 'subRegion':
          case 'base':
          case 'titular':
          case 'companiaOrigen':
          case 'centroCosto':
            query[name] = object ? object.value : '';
            break;
          default: // Input
            query[name] = object;
            break;
        }
    }

    window.localStorage.setItem('MovilesGridFilter',JSON.stringify({activo: props.persisteFiltros, filtros: filtersState}))
    props.setFilterQuery(query);
    setFiltersLoading(false)
  };

  const handleChangeInactivosSwich = (value) => {
    setFiltersState({...filtersState, showInactivos : value});
    handleChange('showInactivos', value);
  };

  const handleOnEnter = (e, name, value) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleChange(name, value);
    }
  };

  const handleChangeEstado = (value) => {
    setFiltersState({...filtersState, estado: value})
    handleChange('estado', value);
  }

  useEffect(() => {
    handleChange()
  },[props.persisteFiltros])

  return (
    <>
    {init &&
      <Collapse in={props.dropdownActive}>
        <Grid container item xs={12} spacing={3} style={{marginBottom:20}}>
          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="marca"
                disabled={filtersLoading}
                options={marcas}
                value={filtersState.marca}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, v) => setFiltersState({...filtersState, marca: v})}
                onKeyDown={(e) => handleOnEnter(e, 'marca', filtersState.marca)}
                onBlur={(e) => handleChange("marca", filtersState.marca)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_marca.label", defaultMessage: "Marca" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_marca.placeholder", defaultMessage: "Marca" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                multiple={true}
                size="small"
                name="estado"
                disabled={filtersLoading}
                options={estados}
                value={filtersState.estado}
                defaultValue={[]}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(e, v) => handleChangeEstado(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={intl.formatMessage({
                      id: "movilesGrid.render.filtros_estados.label",
                      defaultMessage: "Estados"
                    })}
                    placeholder={intl.formatMessage({
                      id: "movilesGrid.render.filtros_estados.placeholder",
                      defaultMessage: "Estados"
                    })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.unidadEnabled && {display:"none"}}  component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_unidad.label", defaultMessage: "Unidad" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_unidad.placeholder", defaultMessage: "Unidad" })}
                name="unidad"
                value={filtersState.unidad}
                onChange={(e) => setFiltersState({...filtersState, unidad: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'unidad', filtersState.unidad)}
                onBlur={(e) => handleChange("unidad", filtersState.unidad)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.chasisEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_chasis.label", defaultMessage: "Chasis" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_chasis.placeholder", defaultMessage: "Chasis" })}
                name="chasis"
                value={filtersState.chasis}
                onChange={(e) => setFiltersState({...filtersState, chasis: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'chasis', filtersState.chasis)}
                onBlur={(e) => handleChange("chasis", filtersState.chasis)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_dominio.label", defaultMessage: "Dominio" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_dominio.placeholder", defaultMessage: "Dominio" })}
                name="dominio"
                value={filtersState.dominio}
                onChange={(e) => setFiltersState({...filtersState, dominio: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'dominio', filtersState.dominio)}
                onBlur={(e) => handleChange("dominio", filtersState.dominio)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_modelo.label", defaultMessage: "Modelo" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_modelo.placeholder", defaultMessage: "Modelo" })}
                name="modelo"
                value={filtersState.modelo}
                onChange={(e) => setFiltersState({...filtersState, modelo: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'modelo', filtersState.modelo)}
                onBlur={(e) => handleChange("modelo", filtersState.modelo)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="tipo"
                disabled={filtersLoading}
                options={tipos}
                value={filtersState.tipo}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                // getOptionSelected={(option) => option === tipo.value}
                onChange={(event, value) => setFiltersState({...filtersState, tipo: value})}
                onKeyDown={(e) => handleOnEnter(e, 'tipo', filtersState.tipo)}
                onBlur={(e) => handleChange("tipo", filtersState.tipo)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_tipo.label", defaultMessage: "Tipo" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_tipo.placeholder", defaultMessage: "Tipo" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.paisEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="pais"
                disabled={filtersLoading}
                options={paises}
                value={filtersState.pais}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(event, value) => setFiltersState({...filtersState, pais: value})}
                onKeyDown={(e) => handleOnEnter(e, 'pais', filtersState.pais)}
                onBlur={(e) => handleChange("pais", filtersState.pais)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_pais.label", defaultMessage: "País" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_pais.placeholder", defaultMessage: "País" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.regionEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="region"
                disabled={filtersLoading}
                options={regiones}
                value={filtersState.region}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(event, value) => setFiltersState({...filtersState, region: value})}
                onKeyDown={(e) => handleOnEnter(e, 'region', filtersState.region)}
                onBlur={(e) => handleChange("region", filtersState.region)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_region.label", defaultMessage: "Región" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_region.placeholder", defaultMessage: "Región" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.subRegionEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="subRegion"
                disabled={filtersLoading}
                options={subRegiones}
                value={filtersState.subRegion}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(event, value) => setFiltersState({...filtersState, subRegion: value})}
                onKeyDown={(e) => handleOnEnter(e, 'subRegion', filtersState.subRegion)}
                onBlur={(e) => handleChange("subRegion", filtersState.subRegion)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_subRegion.label", defaultMessage: "Sub.Región" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_subRegion.placeholder", defaultMessage: "Sub.Región" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.baseEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="base"
                disabled={filtersLoading}
                options={bases}
                value={filtersState.base}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(event, value) => setFiltersState({...filtersState, base: value})}
                onKeyDown={(e) => handleOnEnter(e, 'base', filtersState.base)}
                onBlur={(e) => handleChange("base", filtersState.base)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_base.label", defaultMessage: "Base" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_base.placeholder", defaultMessage: "Base" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.titularEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="titular"
                disabled={filtersLoading}
                options={titulares}
                value={filtersState.titular}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(event, value) => setFiltersState({...filtersState, titular: value})}
                onKeyDown={(e) => handleOnEnter(e, 'titular', filtersState.titular)}
                onBlur={(e) => handleChange("titular", filtersState.titular)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_titular.label", defaultMessage: "Titular" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_titular.placeholder", defaultMessage: "Titular" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.companiaOrigenEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="companiaOrigen"
                disabled={filtersLoading}
                options={companiasOrigen}
                value={filtersState.companiaOrigen}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(event, value) => setFiltersState({...filtersState, companiaOrigen: value})}
                onKeyDown={(e) => handleOnEnter(e, 'companiaOrigen', filtersState.companiaOrigen)}
                onBlur={(e) => handleChange("companiaOrigen", filtersState.companiaOrigen)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_companiaOrigen.label", defaultMessage: "Cía. Origen" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_companiaOrigen.placeholder", defaultMessage: "Cía. Origen" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.centroCostosEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <Autocomplete
                size="small"
                name="centroCosto"
                disabled={filtersLoading}
                options={centrosCostos}
                value={filtersState.centroCosto}
                defaultValue={null}
                getOptionLabel={(option) => option ? option.label : ""}
                onChange={(event, value) => setFiltersState({...filtersState, centroCosto: value})}
                onKeyDown={(e) => handleOnEnter(e, 'centroCosto', filtersState.centroCosto)}
                onBlur={(e) => handleChange("centroCosto", filtersState.centroCosto)}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"
                    label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto.label", defaultMessage: "Centro de Costos" })}
                    placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto.placeholder", defaultMessage: "Centro de Costos" })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {filtersLoading ? (
                            <CircularProgress color="inherit" size={21} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </form>
          </Grid>

          <Grid {...!columnsEnabled.cecosDireccionEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_direccion.label", defaultMessage: "CC.Dirección" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_direccion.placeholder", defaultMessage: "CC.Dirección" })}
                name="cecosDireccion"
                value={filtersState.cecosDireccion}
                onChange={(e) => setFiltersState({...filtersState, cecosDireccion: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'cecosDireccion', filtersState.cecosDireccion)}
                onBlur={(e) => handleChange("cecosDireccion", filtersState.cecosDireccion)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.cecosMercadoEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_mercado.label", defaultMessage: "CC.Mercado" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_mercado.placeholder", defaultMessage: "CC.Mercado" })}
                name="cecosMercado"
                value={filtersState.cecosMercado}
                onChange={(e) => setFiltersState({...filtersState, cecosMercado: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'cecosMercado', filtersState.cecosMercado)}
                onBlur={(e) => handleChange("cecosMercado", filtersState.cecosMercado)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.cecosGerenciaEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_gerencia.label", defaultMessage: "CC.Gerencia" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_gerencia.placeholder", defaultMessage: "CC.Gerencia" })}
                name="cecosGerencia"
                value={filtersState.cecosGerencia}
                onChange={(e) => setFiltersState({...filtersState, cecosGerencia: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'cecosGerencia', filtersState.cecosGerencia)}
                onBlur={(e) => handleChange("cecosGerencia", filtersState.cecosGerencia)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid {...!columnsEnabled.cecosAreaEnabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_area.label", defaultMessage: "CC.Área" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_centroCosto_area.placeholder", defaultMessage: "CC.Área" })}
                name="cecosArea"
                value={filtersState.cecosArea}
                onChange={(e) => setFiltersState({...filtersState, cecosArea: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'cecosArea', filtersState.cecosArea)}
                onBlur={(e) => handleChange("cecosArea", filtersState.cecosArea)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>

          <Grid {...!columnsEnabled.responsable1Enabled && {display:"none"}} component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_responsable1.label", defaultMessage: "Responsable 1" })}
                placeholder={intl.formatMessage({ id: "movilesGrid.render.filtros_responsable1.placeholder", defaultMessage: "Responsable 1" })}
                name="responsable1"
                value={filtersState.responsable1}
                onChange={(e) => setFiltersState({...filtersState, responsable1: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'responsable1', filtersState.responsable1)}
                onBlur={(e) => handleChange("responsable1", filtersState.responsable1)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid component={Box} item xs={3}>
            <form noValidate autoComplete="off">
              <TextField
                label={intl.formatMessage({ id: "movilesGrid.render.filtros_medidor.label", defaultMessage: "Medidor" })}
                name="medidor"
                value={filtersState.medidor}
                onChange={(e) => setFiltersState({...filtersState, medidor: e.target.value})}
                onKeyDown={(e) => handleOnEnter(e, 'medidor', filtersState.medidor)}
                onBlur={(e) => handleChange("medidor", filtersState.medidor)}
                disabled={filtersLoading}
                className="col-12"
              />
            </form>
          </Grid>
          <Grid component={Box} item xs={2} className="align-self-end">
              <form noValidate autoComplete="off">
                <div className="d-flex inline justify-content-around align-items-center">
                  <FormattedMessage
                    id="formulariosGridFilters.render.filtros.ver_inactivos"
                    defaultMessage="Ver Inactivos"
                  />
                  <Switch
                    onChange={(value) => handleChangeInactivosSwich(value)}
                    checked={filtersState.showInactivos}
                    disabled={filtersLoading}
                    offColor="#FF4961"
                    onColor="#28D094"
                  />
                </div>
              </form>
            </Grid>
          <Grid component={Box} item xs={2} className="align-self-end">
            <form noValidate autoComplete="off">
              <div className="d-flex inline justify-content-around align-items-center">
                <FormattedMessage
                  id="movilesGrid.render.filtros.persistir_filtros"
                  defaultMessage="Persistir Filtros"
                />
                <Switch
                  onChange={(value) => props.setPersisteFiltros(value)}
                  checked={props.persisteFiltros}
                  disabled={filtersLoading}
                  offColor="#FF4961"
                  onColor="#28D094"
                />
              </div>
            </form>
          </Grid>
        </Grid>
      </Collapse>
  }
    </>
  )
}

export default MovilesGridFiltros
