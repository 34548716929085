import React, { Component } from 'react'
import Validator from '../../commons/validation/Validator.js'
import Loading from '../ui/Loading.js'
import moment from 'moment'
import 'moment/min/locales'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import AtributoDinamicoInstanciaViewPDF from '../atributoDinamico/AtributoDinamicoInstanciaViewPDF.js'
import 'react-select/dist/react-select.css'
import { castValoresDinamicos } from '../atributoDinamico/helpers.js'
import {FormattedMessage} from 'react-intl';

class FormularioViewPDF extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");

    this.state = {
      redirectTo: null,
      props: this.props,
      formData: {
        valores_dinamicos: [],
        medidor: 0,
        activo: 1,
        tipo_formulario: null,
        movil: null,
        chofer: null,
        persona: null,
        tareas: [],
        tareasEnResolucion: false,
      },
      valores_dinamicos: [],
      latitudForm: null,
      longitudForm: null,
      descripcion: "",
      movil_atributos_mostrar: [],
      movil: null,
      movil_atributos: [
        {
          id: 'marca',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.marca",
            defaultMessage: "Marca",
          }),
        },
        {
          id: 'modelo',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.modelo",
            defaultMessage: "Modelo",
          }),
        },
        {
          id: 'chasis_nro',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.chasis_nro",
            defaultMessage: "Chasis",
          }),
        },
        {
          id: 'motor_nro',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.motor_nro",
            defaultMessage: "Motor",
          }),
        },
        {
          id: 'color',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.color",
            defaultMessage: "Color",
          }),
        },
        {
          id: 'centro_costos',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.centro_costos",
            defaultMessage: "Centro de Costos",
          }),
        },
        {
          id: 'plan_preventivo',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.plan_preventivo",
            defaultMessage: "Plan Preventivo",
          }),
        },
        {
          id: 'combustible',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.combustible",
            defaultMessage: "Combustible",
          }),
        },
        {
          id: 'proveedor_gps',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.proveedor_gps",
            defaultMessage: "Proveedor GPS",
          }),
        },
        {
          id: 'compania_origen',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.compania_origen",
            defaultMessage: "Compañia de Origen",
          }),
        },
        {
          id: 'titular',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.titular",
            defaultMessage: "Titular",
          }),
        },
        {
          id: 'telepeaje',
          nombre: this.props.intl.formatMessage({
            id: "formularioAbm.label_movil_atributos.telepeaje",
            defaultMessage: "Peaje",
          }),
        },
      ],
      errors: [],
      loading: false,
      isChoferValid: true,
      tareas: [],
      entidad: null,
    };

    this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
  }
  initForm() {
    this.setState({ loading: true });
    let component = this;

    Promise.all([
      this.state.props.action !== "ADD"
        ? this.ajaxHandler.getJson("/formulario/" + this.state.props.formulario_id)
        : null
    ])
      .then((data) => {
        let formData = data[0];
        let valores_dinamicos = [];
        let movil_atributos_mostrar = [];
        if (formData) {
          let key_validator = formData.tipo_formulario.con_movil ? "formData.movil" : "formData.persona";
          let validator = {};
          validator["formData.tipo_formulario"] = (value) => Validator.notEmpty(value);
          validator[key_validator] = (value) => Validator.notEmpty(value);
          if(formData.tipo_formulario.movil_atributos_json && formData.tipo_formulario.con_movil){
            this.setState({ loading: true });
            Promise.all([
              this.ajaxHandler.getJson("/moviles/" + formData.movil.id)
            ])
            .then((data) => {
              console.log(data[0]);
              component.setState({
                movil: data[0],
              });
            })
            .catch(function (error) {
              console.log(error);
              component.exit();
            })
            .finally(() => {
              this.setState({ loading: false });
            });

            let movil_atributos_array = JSON.parse(formData.tipo_formulario.movil_atributos_json);
            this.state.movil_atributos.forEach(function(element) {
              if(movil_atributos_array.includes(element.id))
                movil_atributos_mostrar.push(element);
            });
          }
          valores_dinamicos = castValoresDinamicos(
            formData.tipo_formulario.atributos_dinamicos,
            formData.valores_dinamicos
          );
          component.setState({
            formData: formData,
            valores_dinamicos: valores_dinamicos,
            movil_atributos_mostrar: movil_atributos_mostrar
          });
        }
        // Security.hasPermission('USUARIO_POSEER_MOVIL')
        let userChofer = JSON.parse(localStorage.getItem("persona"));
        if (Security.hasPermission("USUARIO_POSEER_MOVIL")) {
          let formDataCopy = this.state.formData;
          formDataCopy.chofer = userChofer;
          this.setState({ formData: formDataCopy });
          this.setState({ isChoferValid: false });
        }
      })
      .catch(function (error) {
        console.log(error);
        component.exit();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  componentWillMount() {

    this.ajaxHandler.subscribe(this);
    if ((Security.hasPermission('CHECKLIST_CREAR_HISTORICO') && this.state.props.action === 'ADD') ||
			(Security.hasPermission('CHECKLIST_EDITAR_HISTORICO') && this.state.props.action === 'EDIT') ||
			(Security.hasPermission('CHECKLIST_VISUALIZAR_HISTORICO') && this.state.props.action === 'VIEW')) {
            this.initForm();
            this.getLocation();
		} else {
			this.setState({
				redirectTo: '/error'
			});
        }
  }
  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleChangeAtributoDinamico(index, key, value) {
    this.state.valores_dinamicos[index][key] = value;
    this.setState({
      valores_dinamicos: this.state.valores_dinamicos
    });
	}

  exit() {
    this.setState({
      redirectTo: "/formulario",
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((event) => this.showPosition(event));
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  showPosition(position) {
    this.setState({
      latitudForm: position.coords.latitude,
      longitudForm: position.coords.longitude,
    });
  }

  getMovilAtributo(movilAtributoId){
    if(movilAtributoId === "modelo")
      return this.state.movil.modelo ? this.state.movil.modelo.nombre : '';
    else if (movilAtributoId === 'marca')
      return this.state.movil.modelo ? (this.state.movil.modelo.marca ? this.state.movil.modelo.marca.nombre : '') : '';
    else if (movilAtributoId === 'titular')
      return this.state.movil.titular_eloquent ? this.state.movil.titular_eloquent.nombre : '';
    else if (movilAtributoId === 'plan_preventivo')
      return this.state.movil.plan_preventivo_eloquent ? this.state.movil.plan_preventivo_eloquent.descripcion : (this.state.movil.planPreventivo ? this.state.movil.planPreventivo.descripcion : '' );
    else if (movilAtributoId === 'centro_costos')
      return this.state.movil.cecos ? this.state.movil.cecos.nombre : (this.state.movil.centroCostos ? this.state.movil.centroCostos.nombre : '');
    else if (movilAtributoId === 'proveedor_gps')
      return this.state.movil.proveedorGps ? this.state.movil.proveedorGps.razon_social : '';
    else
      return this.state.movil[movilAtributoId];
  }

  render() {
    let state = this.state;
    let formData = this.state.formData;

    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        <div className="row content-body">
          <div className="col-12">
            <form className="form form-horizontal" ref="form">
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="icon-home"></i>
                        <FormattedMessage
                          id="formularioAbm.general_data.label_datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                      </h4>

                      <div className="row">
                        {/* Tipo Formulario */}
                        <div className="col-6">
                          <div className="form-group row">
                            <label className="col-3 label-control col-form-label" htmlFor="identificador">
                              <FormattedMessage
                                id="formularioAbm.general_data.label_tipo_formulario"
                                defaultMessage="Tipo de Formulario *:"
                              />
                            </label>
                            <div className="col-8">
                              <div className="form-control-static col-form-label form-value">
                                {this.state.formData.tipo_formulario ? this.state.formData.tipo_formulario.nombre : ''}
                              </div>
                            </div>
                            <label className="col-1">
                              <i
                                className={
                                  this.state.formData.tipo_formulario
                                    ? this.state.formData.tipo_formulario.icono + " fa-2x"
                                    : ""
                                }
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.formData.tipo_formulario && (
                        <div className="row">
                          {/* Descripción */}
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-3 label-control col-form-label" htmlFor="nombre">
                                <FormattedMessage
                                  id="formularioAbm.general_data.label_descripcion"
                                  defaultMessage="Descripción:"
                                />
                              </label>
                              <div className="col-9">
                                <p className="col-form-label">{formData.tipo_formulario.descripcion} </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {this.state.formData.usuario_validacion_id && (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="fa fa-thumbs-up "></i> Validación Manual
                        </h4>
                        <div className="row">
                          {/* Tipo Formulario */}
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-3 label-control col-form-label" htmlFor="identificador">
                                Usuario:
                              </label>
                              <div className="col-9">
                                <p className="col-form-label">{this.state.formData.usuario_validacion.label}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* Tipo Formulario */}
                          <div className="col-6">
                            <div className="form-group row">
                              <label className="col-3 label-control col-form-label" htmlFor="identificador">
                                Fecha:
                              </label>
                              <div className="col-9">
                                <p className="col-form-label">{this.state.formData.validated_at}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.formData.tipo_formulario && (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="fa fa-file-text-o"></i>
                          <FormattedMessage
                            id="formularioAbm.form_to_complete.label_formulario_completar"
                            defaultMessage=" Formulario a Completar "
                          />
                        </h4>
                        {this.state.formData.tipo_formulario.con_persona ? (
                          <div className="row">
                            {/* Persona */}
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="formularioAbm.form_to_complete.label_persona"
                                    defaultMessage="Persona *:"
                                  />
                                </label>
                                <div className="col-9">
                                  <div className="form-control-static col-form-label form-value">
                                    {this.state.formData.persona ? this.state.formData.persona.label : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.formData.tipo_formulario.con_movil ? (
                          <div className="row">
                            {/* Movil */}
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-3 label-control col-form-label" htmlFor="movil">
                                  <FormattedMessage
                                    id="formularioAbm.form_to_complete.label_movil"
                                    defaultMessage="Móvil *:"
                                  />
                                </label>
                                <div className="col-9">
                                  <div className="form-control-static col-form-label form-value">
                                    {this.state.formData.movil ? this.state.formData.movil.dominio : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.movil && this.state.movil_atributos_mostrar.map((element, index) => {
                              return (
                                <div key={index} className="col-6">
                                  <div className="form-group row">
                                    <label className="col-3 label-control col-form-label" htmlFor="movil">
                                      {element.nombre} :
                                    </label>
                                    <div className="col-9">
                                      <div className="form-control-static col-form-label form-value">
                                        {this.getMovilAtributo(element.id)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                );
                              })
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.formData.tipo_formulario.con_chofer ? (
                          <div className="row">
                            {/* Chofer */}
                            <div className="col-6">
                              <div className="form-group row">
                                <label className="col-3 label-control col-form-label">Actualizar Chofer *:</label>
                                <div className="col-9">
                                  <div className="form-control-static col-form-label form-value">
                                    {this.state.formData.chofer ? this.state.formData.chofer.label : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.valores_dinamicos.map(
                          (atributoDinamico, index) =>
                            atributoDinamico &&
                            atributoDinamico.activo && (
                              <div className="row" key={index}>
                                <div className="col-6">
                                  <div className="form-group row">
                                    <AtributoDinamicoInstanciaViewPDF
                                      movil={formData.movil}
                                      className=""
                                      disabled={this.props.action === "VIEW" || this.state.entidad == null}
                                      handleChange={(name, value) =>
                                        this.handleChangeAtributoDinamico(index, name, value)
                                      }
                                      atributo={atributoDinamico}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FormularioViewPDF;
