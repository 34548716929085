import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import '../../assets/css/styles.css'
import Config from '../../commons/config/Config.js';
import Loading from '../ui/Loading.js'
import swal from 'sweetalert'
import axios from 'axios'

class GeotabLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            loading: false,
            redirectTo: null,
        }

        this.loginRandom = null;
        this.firePointerLogin = this.firePointerLogin.bind(this);
    }

    componentWillMount () { }

    componentDidMount () {
        document.body.className = 'vertical-layout vertical-menu 1-column menu-expanded blank-page blank-page';
        document.body.setAttribute('data-col', '1-column');
        this.firePointerLogin()
    }

    firePointerLogin () {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            fetch(Config.get('apiUrlBase') + '/public/auth/services', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                if(response.status === 200) {
                    response.json().then(data => {
                        const query = new URLSearchParams(this.props.location.search)
                        const database = query.get('database')
                        const user = query.get('user')
                        const sessionId = query.get('sessionId')
                        const url = query.get('url')
                        if (database) {
                            axios.post(Config.get('apiUrlBase') + '/public/auth/geotab/login', { url: url, database: database, user: user, sessionId: sessionId })
                            .then(response => {
                                if(response.data && response.data.usuario && response.data.usuario.token) {
                                    localStorage.setItem('persona', JSON.stringify(response.data));
                                    localStorage.setItem('token', response.data.usuario.token);
                                    // window.location.reload();
                                    window.location.href = '/'
                                } else {
                                    this.setState({
                                        loading: false,
                                        errors: [ "Error: token no encontrado en respuesta del servidor" ]
                                    })
                                }
                            }).catch((error) => {
                                this.error()
                            })
                        } else {
                            this.error()
                        }
                    });
                } else {
                    this.error()
                }
            }).finally(() => {
                this.setState({ loading: false });
            });
        }, 5000)
    }

    error() {
        swal({
            title: "Error interno del sistema.",
            text: "Contacte al administrador.",
            icon: "error",
            buttons: {
                confirm: {
                    text: "Aceptar",
                    value: true,
                    visible: true,
                    className: "btn btn-primary",
                    closeModal: true
                }
            }
        });
    }

    render() {
        if(!this.loginRandom){
            this.loginRandom = 1 + Math.floor((4 - 1) * Math.random());
        }

        return (
            <React.Fragment>
                {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
                {this.state.loading && <Loading hideSpinner />}
                <div className="app-content content">
                    <div className="content-wrapper">
                        <div className="content-header row">
                        </div>
                        <div className="content-body">
                            <video className="video-login-bg" autoPlay muted loop type="video/mp4" poster={process.env.PUBLIC_URL + "/images/images-login/image"+ this.loginRandom +".png"}>
                                <source src={process.env.PUBLIC_URL + "/images/videos-login/video"+ this.loginRandom +".mp4"} type="video/mp4"/>
                                <img src={process.env.PUBLIC_URL + "/images/images-login/image"+ this.loginRandom +".png"} alt="Video no soportado"/>
                            </video>
                            <section className="flexbox-container">
                                <div className="col-12 d-flex align-items-center justify-content-center">
                                    <div className="col-md-4 col-10 box-shadow-2 p-0">
                                        <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                            <div id="loginLogo" className="card-header border-0 text-center">
                                                <img src={process.env.PUBLIC_URL + "/images/company/logo-login-app.png"} alt="" className="rounded-circle img-fluid center-block" />
                                            </div>
                                            <div className="card-content">
                                                <div className="card-body">
                                                    { this.state.loading ?
                                                        (<div className="row">
                                                            <div className="col-12 text-center">
                                                                <p style={{fontSize: "1rem"}}>Finalizando el proceso de autenticación<br/> Aguarde unos instantes</p>
                                                                <img height="100px" src="/images/loading.svg"/>
                                                            </div>
                                                        </div>) : (<div></div>)
                                                    }
                                                    <div className="row mt-2">
                                                        <div className="col-6"><img src={process.env.PUBLIC_URL + "/images/company/logo-login-company.png"} alt="" className="img-fluid center-block" /></div>
                                                        <div className="col-6 text-right"><img src={process.env.PUBLIC_URL + "/images/company/logo-login-powered-by.png"} alt="" className="img-fluid center-block" /></div>
                                                    </div>
                                                    <div className="row">
                                                        <h5 className="login-version">v{ process.env.REACT_APP_VERSION }</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default GeotabLogin;
