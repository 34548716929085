import React, { Component } from 'react'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import Loading from '../ui/Loading.js'
import moment from 'moment'
import 'moment/min/locales'
import '../../assets/css/timeline.css'
import {FormattedMessage, injectIntl} from 'react-intl';
import Timezone from '../../commons/timezone/Timezone.js';

class TicketHistorico extends Component {
	constructor(props) {
    super(props);
    this.props = props;

    this.ajaxHandler = new AjaxHandler();

    moment.locale('es');

    if(props.ticket) {
      this.ticket = props.ticket;
    }

    this.state = {
      loading: false,
      historia: []
    }
  }

  componentDidMount() {
		if (Security.hasPermission('TICKETS_VER_HISTORIA')) {
      this.ajaxHandler.subscribe(this);
      this.loadFormData();
		} else {
			this.setState({
				redirectTo: '/error'
			});
		}
	}

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  loadFormData() {
    let component = this;
    this.setState({ loading: true });
    this.ajaxHandler.getJson('/ticket-historico/ticket/' + this.ticket.id + '/grid')
    .then(data => {
      if(data) component.setState({
        historia: data
      });
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  getEventoDisplay (evento) {
    switch (evento) {
      case 'TICKETS_CREAR':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_creacion_del_ticket', defaultMessage: 'Creación del Ticket' }), iconClass: 'la la-dot-circle-o', iconColor: ''};
      case 'TICKETS_ASIGNAR_GERENCIADOR':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_de_gerenciador', defaultMessage: 'Asignación de Gerenciador' }), iconClass: 'la la-suitcase', iconColor: ''};
      case 'TICKETS_ASIGNAR_TALLER':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_de_taller', defaultMessage: 'Asignación de Taller' }), iconClass: 'la la-wrench', iconColor: ''};
      case 'TICKETS_CREAR_COMENTARIO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_ingreso_de_comentario', defaultMessage: 'Ingreso de Comentario' }), iconClass: 'la la-comments', iconColor: ''};
      case 'TICKETS_CREAR_PRESUPUESTO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_nuevo_presupuesto', defaultMessage: 'Nuevo Presupuesto' }), iconClass: 'la la-file-text', iconColor: ''};
      case 'TICKETS_EDITAR_PRESUPUESTO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_edicion_presupuesto', defaultMessage: 'Edición de Presupuesto' }), iconClass: 'la la-file-text', iconColor: ''};
      case 'TICKETS_ASIGNAR_TURNO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_de_turno', defaultMessage: 'Asignación de Turno' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_ESTADO_MODIFICAR':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_cambio_de_estado', defaultMessage: 'Cambio de Estado' }), iconClass: 'la la-check-circle', iconColor: ''};
      case 'TICKETS_ASIGNAR_FCH_REALIZADO':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_asignacion_fecha_de_realizado', defaultMessage: 'Asignación fecha de realizado' }), iconClass: 'la la-calendar-check-o', iconColor: ''};
      case 'TICKETS_INFORMAR_FACTURA':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_carga_de_factura', defaultMessage: 'Carga de Factura' }), iconClass: 'la la-inbox', iconColor: ''};
      case 'TICKETS_ENTRADA_TALLER':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_entrada_a_taller', defaultMessage: 'Entrada a taller' }), iconClass: 'la la-sign-in', iconColor: ''};
      case 'TICKETS_SALIDA_TALLER':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_salida_del_taller', defaultMessage: 'Salida del taller' }), iconClass: 'la la-sign-out', iconColor: ''};
      case 'TICKETS_ENTRADA_TALLER_VERIFICADA_GPS':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_entrada_del_taller_verificada_por_GPS', defaultMessage: 'Entrada del taller verificada por GPS' }), iconClass: 'la la-satellite-dish', iconColor: ''};
      case 'TICKETS_SALIDA_TALLER_VERIFICADA_GPS':
        return {txt: this.props.intl.formatMessage({ id: 'ticketHistorico.eventoDisplay.label_salida_del_taller_verificada_por_GPS', defaultMessage: 'Salida del taller verificada por GPS' }), iconClass: 'la la-satellite-dish', iconColor: ''};

      default:
        return {txt: '', iconClass: '', iconColor: '#ccc'};
    }
  }

	render() {
    if(this.ticket) {
      return (
        <React.Fragment>
          {this.state.loading && <Loading />}
          <div className="content-wrapper">
            <div className="content-body">
              <section id="timeline" className="timeline-left timeline-wrapper">
                <ul className="timeline">
                  <li className="timeline-line"></li>
                  { this.state.historia.map((item, index)=>{
                      let persona = item.persona ? item.persona.nombre + ' ' + item.persona.apellido : this.props.intl.formatMessage({ id: 'ticketHistorico.render.modal_history.text_generado_por_el_sistema', defaultMessage: 'Generado por el sistema' });
                    return(
                      <li key={index} className={item.evento === 'TICKETS_ESTADO_MODIFICAR' ? "timeline-item status-change" : "timeline-item"}>
                        <div className="timeline-badge">
                          <span className="bg-fleet bg-lighten-1" data-toggle="tooltip" data-placement="right">
                            <i className={ this.getEventoDisplay(item.evento).iconClass }></i>
                          </span>
                        </div>
                        <div className="timeline-card card border-grey border-lighten-2 card-history">
                          <div className="card-header">
                            <h4 className="card-title">{ moment(item.fechaHora).format('L') + ' - ' + this.getEventoDisplay(item.evento).txt + ' - ' + persona }</h4>
                            <p className="card-subtitle text-muted pt-1">
                              <span className="font-small-3">{ item.detalle }</span>
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </section>
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TicketHistorico);