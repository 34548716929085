import React, { useEffect, useState, useRef } from 'react';
import * as Utils from '../../commons/utils/Utils';
import backendStrings from '../../lang/backendStrings.js';
import Select from 'react-select';
import llaveIcon from '../../assets/images/llantas/svg/lug-wrench.svg';
import LlantaRepuestosSelect from './LlantaRepuestosSelect';
import LlantaBasesSelect from './LlantaBasesSelect';
import Security from '../../commons/security/Security.js';

const LlantaDesasignar = (props) => {
  const formRef = useRef('llanta_desasignar_form_ref');
  const [llanta, setLlanta] = useState(props.llanta ? props.llanta : null);
  const [llantas, setLlantas] = useState([]);
  const [formData, setFormData] = useState({
    llanta: null,
    llanta_reemplazo: null,
    base: null,
  });
  const [unidadMedidorChange, setUnidadMedidorChange] = useState(false);
  const [bases, setBases] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [basesLoading, setBasesLoading] = useState(false);
  const [llantaReemplazoSelectLoading, setLlantaReemplazoSelectLoading] = useState(false);
  const [basesSelectLoading, setBasesSelectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    props.llanta && setLlantaSelected();
  }, []);

  useEffect(() => {
    formData.llanta && getFormData();
  }, [formData]);

  const setLlantaSelected = () => {
    setBasesLoading(true);
    setBasesSelectLoading(true);
    setLlantaReemplazoSelectLoading(true);
    setFormData((prevState) => ({ ...prevState, llanta: props.llanta }));
  };

  const getFormData = () => {
    let urlQueryUnidadMedidor = llanta ? '?unidadMedidorId=' + llanta.movil.unidadMedidorId : '';

    Promise.all([null, Utils.getData('/bases/select-filtered-by-user/base/10')])
      .then((data) => {
        setLlantas(data[0]);
        setBases(data[1]);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
        setBasesLoading(false);
        setBasesSelectLoading(false);
        setLlantaReemplazoSelectLoading(false);
      });
  };

  const handleFormChangeSelectObject = (event, name) => {
    let formDataCopy = { ...formData };
    !event && name === 'llanta' && (formDataCopy['llanta_reemplazo'] = null);
    formDataCopy[name] = event;
    setFormData(formDataCopy);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitLoading(true);
    setLoading(true);
    Utils.sendData('/llantas/llantadesasignar', 'PUT', formData)
      .then((response) => {
        props.handleSubmitDesasignar();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setSubmitLoading(false);
      });
  };

  const handleLlantaRepuestoChange = (e) => {
    let unidad = e ? (e.unidad_medidor_id ? (e.unidad_medidor_id !== formData.llanta.unidad_medidor_id) : false) : false
    setUnidadMedidorChange(unidad);
    handleFormChangeSelectObject(e, 'llanta_reemplazo');
  };

  return (
    <>
      <div
        style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: '1rem', maxWidth: '350px' }}
      >
        <div className="alert alert-danger" role="alert" hidden={errors.length === 0}>
          {errors.map((e, i) => (
            <li key={i}>
              {props.intl.formatMessage({
                ...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default']),
              })}
            </li>
          ))}
        </div>
        <form className="form form-horizontal" ref={formRef}>
          <div className="text-center">
            <img src={llaveIcon} alt={'/'} style={{ height: '100px', width: '100px' }} />
          </div>
          <div className="text-center mt-2">
            <h3>
              {'¿'}
              {props.intl.formatMessage({
                id: 'llantaDesasignar.header_confirmar_desasignar_llanta',
                defaultMessage: 'Confirma que desea desasignar la llanta',
              })}{' '}
              {llanta ? llanta.serial : ''} <br></br>
              <props.intl.formatMessage
                id="llantaDesasignar.header_del_vehiculo"
                defaultMessage="del vehículo"
              />{' '}
              {llanta ? llanta.movil.dominio : ''}
              {'?'}
            </h3>
          </div>
          {props.fromAsignacion ? (
            <div className="form-group row mt-2">
              <label htmlFor="milimetrosRecapado" className="text-center col-sm-4 col-form-label">
                {props.intl.formatMessage({
                  id: 'llantaDesasignar.select_label_llanta_a_reemplazar',
                  defaultMessage: 'Seleccione una llanta',
                })}
                * :
              </label>
              <div className="col-sm-8">
                <Select
                  name="llantaTotal"
                  className="select-personal-llantas z-15"
                  maxMenuHeight={10}
                  placeholder={props.intl.formatMessage({
                    id: 'llantaDesasignar.select_label_llanta_a_reemplazar_placeholder',
                    defaultMessage: 'Cautin / SN',
                  })}
                  options={props.llantasDelAuto.map((el) => ({
                    ...el,
                    label:
                      el.serial +
                      ' - ' +
                      el.llanta_marca.nombre +
                      ' - ' +
                      el.llanta_modelo.llanta_medida.nombre,
                  }))}
                  valueKey="id"
                  labelKey="label"
                  value={formData.llanta ? formData.llanta : ''}
                  onChange={(e) => handleFormChangeSelectObject(e, 'llanta')}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {!Security.hasPermission('LLANTAS_SIN_NECESIDAD_REEMPLAZO') ? (
          <div className="form-group row mt-2">
            <label htmlFor="milimetrosRecapado" className="text-center col-sm-4 col-form-label">
              {props.intl.formatMessage({
                id: 'llantaDesasignar.select_label_llanta_reemplazo',
                defaultMessage: 'Seleccione una llanta de reemplazo',
              })}
              * :
            </label>
            <div className="col-sm-8">
              <LlantaRepuestosSelect
                llantaId={formData.llanta ? formData.llanta.id : null}
                movil={props.movil}
                intl={props.intl}
                changeLlantaRepuesto={handleLlantaRepuestoChange}
              />
            </div>
          </div>
          ) : (
            ''
          )}
          {unidadMedidorChange && (
            <p className="my-1 reemplazo-label-danger">
              La llanta seleccionada posee diferente medidor. Se reiniciaran los datos de odometro.
            </p>
          )}
          <div className="form-group row mt-2">
            <label htmlFor="milimetrosRecapado" className="text-center col-sm-4 col-form-label">
              {props.intl.formatMessage({
                id: 'llantaDesasignar.select_base',
                defaultMessage: 'Seleccione un destino',
              })}
              * :
            </label>
            <div className="col-sm-8">
              <LlantaBasesSelect
                intl={props.intl}
                changeBase={(e) => handleFormChangeSelectObject(e, 'base')}
              />
            </div>
          </div>
          <div className="text-center mt-1 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary mr-1"
              onClick={(e) => handleSubmit(e)}
              disabled={
                loading ||
                submitLoading ||
                (formData.base ? false : true) ||
                (Security.hasPermission('LLANTAS_SIN_NECESIDAD_REEMPLAZO') ? false
                  : (formData.llanta && formData.llanta.llanta_posicion.necesita_reemplazo &&
                  !formData.llanta_reemplazo))
              }
            >
              {submitLoading ? (
                <>
                  <i className="la la-spinner"></i>
                  {props.intl.formatMessage({
                    id: 'llantaAsignacionesDetails.render.button_guardando',
                    defaultMessage: ' Guardando',
                  })}
                </>
              ) : (
                <>
                  <i className="fa fa-check-circle"></i>
                  {props.intl.formatMessage({
                    id: 'llantaAsignacionesDetails.render.button_guardar',
                    defaultMessage: ' Guardar',
                  })}
                </>
              )}
            </button>
            <button
              type="button"
              className="btn btn-danger mr-1"
              onClick={(e) => props.closeModalDesasignar(e)}
            >
              <i className="fa fa-times-circle"></i>
              {props.intl.formatMessage({
                id: 'llantaAsignacionesDetails.render.button_cancelar',
                defaultMessage: ' Cancelar',
              })}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LlantaDesasignar;
