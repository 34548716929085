import $ from 'jquery'


export default {
	language: {
		sProcessing: "<i class=\"fa fa-spinner spinner\" style=\"font-size: 14px\"></i> Procesando...",
		//sLengthMenu: "Mostrar _MENU_",
		sZeroRecords: "No se encontraron datos",
		sEmptyTable: "Ningún dato disponible en esta tabla",
		sInfo: "Mostrando _START_ a _END_ de _TOTAL_",
		sInfoEmpty: "",
		sInfoFiltered: "(filtrado de un total de _MAX_)",
		sInfoPostFix: "",
		sSearch: "Buscar:",
		sUrl: "",
		sInfoThousands: ",",
		sLoadingRecords: " ",
		oPaginate: {
		sFirst: '<i class="fa fa-angle-double-left"></i>',
		sLast: '<i class="fa fa-angle-double-right"></i>',
		sNext: '<i class="fa fa-angle-right"></i>',
		sPrevious: '<i class="fa fa-angle-left"></i>'
		},
		"oAria": {
		    "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
		    "sSortDescending": ": Activar para ordenar la columna de manera descendente"
		}
	},
	pagingType: 'full_numbers',
	responsive: true,
	orderCellsTop: true,
	fixedHeader: true,
	
} 
/*
export const btnTable = () = > {
	new $.fn.dataTable.Buttons( table, {
			buttons: [
				{
          extend: 'print',
          text: '<i class="ft-printer"></i>',
					title: '',
					customize: function(win)
					{
						var css = '@page { size: landscape; }',
							head = win.document.head || win.document.getElementsByTagName('head')[0],
							style = win.document.createElement('style');

						style.type = 'text/css';
						style.media = 'print';

						if (style.styleSheet) {
							style.styleSheet.cssText = css;
						}	else {
							style.appendChild(win.document.createTextNode(css));
						}

						head.appendChild(style);

						$(win.document.body)
							.css( 'font-size', '10pt' )
							.prepend(`<div class="container">
													<div class="row mb-2">
														<div class="col-6">
															<img style="height: 35px" src="`+LogoCompany+`" />
														</div>
														<div class="col-6	text-right">
															<img style="height: 40px" src="`+LogoVecFleet+`" />
														</div>
													</div>
													<div class="row mb-2">
														<div class="col-12" style="border-bottom: 1px solid #000;">
															<h2>Listado de Regiones</h2>
														</div>
													</div>
												</div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 3,4,5,6 ]
          },
					titleAttr: 'Imprimir'
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: 'Seleccionar columnas'
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: 'Exportar',
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
              columns: ':visible'
            },
						text: 'Copiar',
						titleAttr: 'Copiar'
          },
          {
            extend: 'excelHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: 'Exportar a Excel'
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: 'Exportar en formato .csv'
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: 'Exportar a PDF'
					}
					]
				},
				{
					text: '<i class="ft-download"></i>',
					titleAttr: 'Importar'
        },
			]
		});

		table.buttons( 0, null ).container().appendTo('#buttons');
}

*/