import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import $ from 'jquery'

import DataTable from 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-autofill-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import 'datatables.net-responsive-bs4'
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css'
import 'datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import '../../assets/css/vec-datatables.css'
import datatablesConfig from '../../commons/datatables/DatatablesConfig.js'
import Security from '../../commons/security/Security.js'
import Config from '../../commons/config/Config.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import swal from 'sweetalert'
import jzip from 'xlsx/dist/jszip'
import 'xlsx/dist/xlsx.full.min.js'
import LogoCompany from '../../assets/images/logo-company.png'
import LogoVecFleet from '../../assets/images/logo-vecfleet.png'
import {FormattedMessage, injectIntl} from 'react-intl'
import backendStrings from '../../lang/backendStrings.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'

$.DataTable = DataTable;
window.JSZip = jzip;
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


class TareasGrid extends Component {
	constructor(props) {
		super(props);
        this.dataService = '/tareas/grid';
		this.apiBaseUrl = process.env.REACT_APP_VEC_FLEET_API_BASE_URL;

		this.ajaxHandler = new AjaxHandler();
		this.state = {
			redirectTo: null
		}
	}

	componentDidMount(nextProps, nextState) {
        if(Security.hasPermission('TAREAS_LISTAR')) {
            this.ajaxHandler.subscribe(this);
            let component = this;
            let filters = null;
            this.table = $(this.refs.grid).DataTable(
                Object.assign({
                    dom: '<t><"row mt-2"<"col-4" l><"col-4 text-center" i><"col-4" p>>',
                    stateSave: true,
                    stateSaveCallback: function(settings,data) {
                        localStorage.setItem( 'DataTables_TareasGrid', JSON.stringify(data) );
                    },
                    stateLoadCallback: function(settings) {
                        return JSON.parse( localStorage.getItem( 'DataTables_TareasGrid' ) );
                    },
                    serverSide: true,
                    ajax: {
                        type: 'GET',
                        url: Config.get('apiUrlBase') + this.dataService,
                        // url: component.apiBaseUrl + "/bases/grid",
                        headers: {
                            'Authorization-Token': localStorage.getItem("token")
                        },
                        dataSrc: function (response) {
                            return response.data;
                        },
                        error: function (xhr, error, thrown) {
                        },
                        cache: false
                    },
                    initComplete: function (settings) {
                        let stateColumns = JSON.parse(localStorage.getItem('DataTables_TareasGrid')).columns;

                        var sets = settings;
                        var index = 0;

                        this.api().columns().every( function () {
                            var column = this;

                            if(sets.aoColumns[index].filterType){
                                if(sets.aoColumns[index].filterType === 'select') {
                                    var select = $('<select class="btn-dt-filter" required><option value="" selected>'+component.props.intl.formatMessage({ id: 'tareasGrid.initGrid.columns_searh_filter.buscar', defaultMessage: 'Buscar...' })+'</option></select>')
                                        .appendTo( $(column.footer()).empty() )
                                        .on( 'change', function () {
                                            var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                            column
                                                .search( val ? val : '', true, false )
                                                .draw();
                                        });
                                    if(filters && sets.aoColumns[index].name && filters[sets.aoColumns[index].name]) {
                                        filters[sets.aoColumns[index].name].map(e => {
                                            select.append('<option value="'+e.value+'">'+e.label+'</option>');
                                            return true;
                                        });
                                    } else {
                                        column.data().unique().sort().each( function ( d, j ) {
                                            select.append( '<option value="'+d+'">'+d+'</option>' );
                                        });
                                    }
                                    if(stateColumns[index].search.search) select.val(stateColumns[index].search.search);
                                }
                                if(sets.aoColumns[index].filterType === 'input') {
                                    var input = $('<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'tareasGrid.initGrid.columns_searh_filter.placeholder_buscar', defaultMessage: 'Buscar...' })+'" />');
                                    if(stateColumns[index].search.search) input.val(stateColumns[index].search.search);
                                    input.appendTo($(column.footer()).empty());
                                    input.on( 'keyup change', function () {
                                        if ( column.search() !== this.value ) {
                                            column
                                            .search( this.value )
                                            .draw()
                                            .ajax.reload(null, false);
                                        }
                                    });
                                }
                            }
                            index++;
                            return '';
                        });

					$('tfoot tr').appendTo('thead');
				},
				columns: [
					{
						orderable: false,
						title: '',
						data: 'nombre',

						render: function (data, type, row) {
							return '<div class="dt-user-avatar">'+row.nombre.substr(0,1)+'</div>';
						},
						filterType: 'none'
					},{

							title: '',
							data: 'activo',
							width: 3,
							padding: 0,
							orderable: false,
							className: 'p-0',
							render: function (data, type, full, meta) {
								return '';
							},
							createdCell: function (td, cellData, rowData, row, col) {
								$('div.tooltip[role="tooltip"]').tooltip('dispose');
								$('div.tooltip[role="tooltip"]').remove();

								$(td).attr('data-togle', 'tooltip');
								$(td).attr('data-placement', 'top');
								$(td).attr('title', cellData ? 'Activo' : 'Inactivo');
								$(td).tooltip();
								if (cellData) {
									$(td).addClass('status-green');
								} else {
									$(td).addClass('status-red');
								}

							},
							filterType: 'none'
					},{
							title: component.props.intl.formatMessage({ id: 'tareasGrid.column_homework.label_tarea', defaultMessage: 'Tarea' }),
							data: 'nombre',
							filterType: 'input'
					},{
						title: component.props.intl.formatMessage({ id: 'tareasGrid.column_max_approve.label_max_aprobar', defaultMessage: 'Max. Aprobar' }),
						data: 'topeAprobacion',
						className: (ConfigBusiness.get('tareas.montoAutoAprobacion.habilitado') === 'false') ? 'd-none' : 'all',
						filterType: 'input'
					},{
							orderable: false,
							data: null,
							className: "text-center",
							width: '120px',
							render: function (data, type, full, meta) {
                                let buttons =
                                    (Security.hasPermission('TAREAS_VISUALIZAR') ? `
                                    <button class="action view btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'tareasGrid.column_actions.button_title_ver', defaultMessage: 'Ver' })+`">
                                        <i class="fa fa-search fa-xs"></i>
                                    </button>` : '') +
                                    (Security.hasPermission('TAREAS_MODIFICAR') ? `
                                    <button class="action edit btn btn-sm btn-icon btn-dt-grid text-success" title="`+component.props.intl.formatMessage({ id: 'tareasGrid.column_actions.button_title_editar', defaultMessage: 'Editar' })+`">
                                        <i class="fa fa-pencil fa-xs"></i>
                                    </button>` : '');
                                return buttons;
                            }
					}],
					drawCallback: function () {
						$(this).find('.action').on('click', function () {
							let data = component.table.row($(this).parents('tr')).data();
							let redirectTo;
							if($(this).hasClass('view')) redirectTo = component.props.match.url + '/' + data.id;
							if($(this).hasClass('edit')) redirectTo = component.props.match.url + '/' + data.id + '/edit';
							component.setState({
								redirectTo: redirectTo
							});

                                if($(this).hasClass('delete')) {
                                    swal({
                                    title: component.props.intl.formatMessage({ id: 'tareasGrid.modal_delete.confirm_eliminacion', defaultMessage: '¿Confirma la eliminación?' }),
                                    text: null,
                                    icon: "warning",
                                    buttons: {
                                        confirm: {
                                            text: component.props.intl.formatMessage({ id: 'tareasGrid.modal_delete.confirm_eliminacion.si', defaultMessage: 'Si' }),
                                            value: true,
                                            visible: true,
                                            className: "btn btn-primary",
                                            closeModal: false
                                        },
                                        cancel: {
                                            text: component.props.intl.formatMessage({ id: 'tareasGrid.modal_delete.confirm_eliminacion.no', defaultMessage: 'No' }),
                                            value: null,
                                            visible: true,
                                            className: "btn btn-danger",
                                            closeModal: true,
                                        }
                                    }
                                })
                                .then((isConfirm) => {
                                    if (isConfirm) {
                                        component.ajaxHandler.fetch('/tareas/' + data.id, {
                                            method: 'DELETE',
                                        })
                                        .then(response => {
                                            if(component.ajaxHandler.handleResponseErrorsIsValid(response)) {
                                                if(response.status !== 400) {
                                                    component.table.ajax.reload(null, false);
                                                    swal(component.props.intl.formatMessage({ id: 'tareasGrid.modal_delete.information_eliminado', defaultMessage: 'Eliminado!' }), "", "success");
                                                } else {
                                                    response.json()
                                                    .then(data => {
                                                        swal(component.props.intl.formatMessage({ id: data.detalle[0]||'tareasGrid.modal_delete.information_error', defaultMessage: 'No se puede borrar la Tarea ya que esta asociada a otro elemento.' }), "", "error");
                                                    });
                                                }
                                            }
                                        }).catch((error) => {
                                            component.ajaxHandler.handleError(error);
                                        });
                                    }
                                });
                                }
                            });
                        },
                }, datatablesConfig(this.props.intl))
            );

            new $.fn.dataTable.Buttons( this.table, {
                buttons: [
                    {
            extend: 'print',
            text: '<i class="ft-printer"></i>',
                        title: '',
                        customize: function(win)
                        {
                            var css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');

                            style.type = 'text/css';
                            style.media = 'print';

                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            }	else {
                                style.appendChild(win.document.createTextNode(css));
                            }

                            head.appendChild(style);

                            $(win.document.body)
                                .css( 'font-size', '10pt' )
                                .prepend(`<div class="container">
                                                        <div class="row mb-2">
                                                            <div class="col-6">
                                                                <img style="height: 35px" src="`+LogoCompany+`" />
                                                            </div>
                                                            <div class="col-6	text-right">
                                                                <img style="height: 40px" src="`+LogoVecFleet+`" />
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="col-12" style="border-bottom: 1px solid #000;">
                                                                <h2>`+component.props.intl.formatMessage({ id: 'tareasGrid.print_content.header_listado_de_tareas', defaultMessage: 'Listado de Tareas' })+`</h2>
                                                            </div>
                                                        </div>
                                                    </div>`);

						$(win.document.body).find( 'table' )
							.addClass( 'compact' )
							.css( 'font-size', 'inherit' );
			 		},
          exportOptions: {
              columns: [ 3,4,5,6,7 ]
          },
					titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_print.title_imprimir', defaultMessage: 'Imprimir' })
        },
        {
          extend: 'colvis',
					text: '<i class="la la-columns"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_select_columns.title_seleccionar_columnas', defaultMessage: 'Seleccionar columnas' })
        },
        {
          extend: 'collection',
					text: '<i class="ft-upload"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_export.select_title_exportar', defaultMessage: 'Exportar' }),
          buttons: [
          {
            extend: 'copyHtml5',
            exportOptions: {
                columns: [ 3,4,5,6,7 ]
            },
						text: component.props.intl.formatMessage({ id: 'tareasGrid.tools_export.select_text_copiar', defaultMessage: 'Copiar' }),
						titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_export.select_copiar', defaultMessage: 'Copiar' })
          },
          {
            extend: 'excelHtml5',
            action: function(){
                component.exportExcel()
            },
            exportOptions: { columns: ':visible'},
            titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_export.select_exportar_a_excel', defaultMessage: 'Exportar a Excel' })
          },
          {
            extend: 'csvHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_export.select_exportar_en_formato_csv', defaultMessage: 'Exportar en formato .csv' })
          },
          {
            extend: 'pdfHtml5',
            exportOptions: {
              columns: ':visible'
						},
						titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_export.select_exportar_PDF', defaultMessage: 'Exportar a PDF' })
					}
					]
				},
				{
					text: '<i class="ft-download"></i>',
					titleAttr: component.props.intl.formatMessage({ id: 'tareasGrid.tools_import.select_importar', defaultMessage: 'Importar' })
        },
			]
		});

            this.table.buttons( 0, null ).container().appendTo('#buttons');

            $('#dataTable tfoot th').each( function () {
                var title = $(this).text();
                if(title !== '') $(this).html( '<input type="text" class="btn-dt-filter" placeholder="'+component.props.intl.formatMessage({ id: 'tareasGrid.columns_searh_filter.dataTable_buscar', defaultMessage: 'Buscar ...' })+'" />' );
            });

            this.table.columns([2,3]).every( function () {
                var that = this;
                $( 'input', this.footer()).on( 'keyup change', function () {
                    if ( that.search() !== this.value ) {
                        that
                            .search( this.value )
                            .draw()
                            .ajax.reload(null, false);
                    }
                });
                return null;
            });

            $('.buttons-collection').on("click",function(e){ e.stopPropagation();
                if($('.dt-button-collection>.dropdown-menu').css('display')=='none')
                    $('.dt-button-collection>.dropdown-menu').css('display','block');
            });
        } else {
            this.setState({
                redirectTo: '/error'
            });
        }
	}

	handleNewBase(event) {
		this.setState({
			redirectTo: this.props.match.url + '/add'
		});
	}

    exportExcel() {
		let component = this;
		this.setState({loading:true});
		component.ajaxHandler.fetch('/tareas/exportar-excel', {
			method: 'POST',
		}).then(response => {
			if (response.status === 200) {
				return response.blob();
			}
		}).then(fileBlob => {
			let fileUrl = URL.createObjectURL(fileBlob);
			$("<a />", {
				href: fileUrl,
				download: "fleet-servicios.xlsx"
			}).appendTo("body").get(0).click();
		}).catch(() => {
		}).finally(() => {
			this.setState({ loading: false });
		});
	}

	componentWillUnmount() {
		this.ajaxHandler.unsubscribe();
		if(this.table) this.table.destroy();
	}

	render() {
	    return (
	    	<React.Fragment>
	    		{this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
    			<div className="row mt-2">
						<div className="col-12">
			    		<div className="card">
								<div className="card-content collpase show">
			            <div className="card-body card-dashboard">
										<div className="container-fluid">
											<div className="row dt-icons">
												<div className="col-6">
                                                { Security.hasPermission('TAREAS_CREAR') ?
													<div className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon" onClick={this.handleNewBase.bind(this)}>
														<i className="ft-plus"></i>
													</div>
                                                    :
                                                    null
                                                }
												</div>
												<div className="col-6" id="buttons"></div>
											</div>
										</div>
			    					<table id="dataTable" className="table nowrap server-side table-hover" ref="grid" width="100%">
											<tfoot>
												<tr style={{backgroundColor: '#fff'}}>

													<th className=""></th>
													<th className="p-0"></th>
													<th className="dt-search-header"><FormattedMessage id="tareasGrid.render.table_column_tarea" defaultMessage="Tarea"/></th>
													<th className="dt-search-header"><FormattedMessage id="tareasGrid.render.table_column_max_aprobar" defaultMessage="Max. Aprobar"/></th>

													<th className=""></th>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
	    	</React.Fragment>
	    );
  	}
}

export default injectIntl(TareasGrid);
