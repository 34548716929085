import React, { Component } from 'react'
import InstanciaBasic from './InstanciaBasic.js'
import * as Constants from '../constants.js'
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../../unidadMedidor/constants.js'
import moment from 'moment'
import swal from 'sweetalert'

const MAX_VALUE = 1000

class InstanciaMedidor extends InstanciaBasic {
	constructor(props) {
    super(props);
		this.castValue = this.castValue.bind(this);
		this.recalcularEstado = this.recalcularEstado.bind(this);
		this.checkInvalid = this.checkInvalid.bind(this);
  }

	recalcularEstado(value) {
		value = this.castValue(value)
		let medidorAnterior = this.castValue(this.props.movil ? this.props.movil.km_actual : 0)
		if (value && medidorAnterior !== undefined) {
			if (value < medidorAnterior - MAX_VALUE)
				return Constants.ID_ESTADO_APROBACION_INVALIDO

			if (value > medidorAnterior + MAX_VALUE)
				return Constants.ID_ESTADO_APROBACION_INVALIDO

			return Constants.ID_ESTADO_APROBACION_VALIDO
		}
		else 
			return Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION
		
	}

	checkInvalid() {
		let estado = this.recalcularEstado(this.props.atributo[this.valueKey])
		// TODO: Ver tema que this.props.movil llega sin el modelo
		let title = "La diferencia del medidor es mayor a " + MAX_VALUE +" "+ "unidades.";
		if (estado == Constants.ID_ESTADO_APROBACION_INVALIDO) {
				swal({
					title: title,
					text: "",
					icon: "warning",
					buttons: {
						confirm: {
							text: "Aceptar",
							value: true,
							visible: true,
							className: "btn btn-success",
							closeModal: true
						},
					}
				}).then(isConfirm => {
					if (isConfirm)
						this.props.handleChange("estado", Constants.ID_ESTADO_APROBACION_VALIDO)
				})
			}
	}

	castValue(value) {
		if (this.props.previsualizacion)
			return ''
		if (value) {
			if (isNaN(value))
				return 0
			else 
				return Number(value)
		} else
			return ''
	}
	masData() {

		let labelFinal = "";
		let labelUser = "";
		let labelFecha = "";
		if(this.props.movil){
			let fecha = this.props.movil.fecha_hora_km_modificacion
			let usuario = this.props.movil.usuario_km_modificacion


			if (usuario)
				labelUser = "Usuario: " + usuario.label

			if (fecha) {
				let ahora = moment()
				let dias = ahora.diff(moment(fecha), 'days')
				let horas =  ahora.diff(moment(fecha), 'hours')
				if (dias)
					labelFecha = dias + " días";
				else
					labelFecha = horas + " horas";
				labelFecha = 'hace ' + labelFecha ;
			}
			labelFinal = [labelUser, labelFecha].filter(label => label).join(' | ')
		}
		if (labelFinal.length)
			labelFinal = '(' + labelFinal + ')'
		return labelFinal
	}
	render() {
      return (
        <React.Fragment>
				  <>
						<input
							disabled={this.props.disabled || this.props.previsualizacion}
							type="number"
							className="form-control"
							value={this.props.atributo[this.valueKey]}
							onChange={this.handleInputChange}
							onBlur={this.checkInvalid}
						/>
						{ (this.props.movil && this.props.movil.km_actual) &&
							<p className='pt-1'>Última Actualización: {this.props.movil.km_actual} {UNIDAD_MEDIDOR[this.props.movil.unidadMedidorId]['unidades']} {this.masData()}</p>
						}
					</>
        </React.Fragment>
      )
  }
}

export default InstanciaMedidor;
