import React, { Component } from 'react';
import Loading from '../ui/Loading.js';
import AjaxHandler from '../../commons/ajax/AjaxHandler.js';
import Config from '../../commons/config/Config.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import recapadoIcon from '../../assets/images/llantas/recapadoicon.png';
import FormValidation from '../../commons/validation/FormValidation.js';
import Validator from '../../commons/validation/Validator.js';
import { TextField } from '@material-ui/core';
import Select from 'react-select';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ConfigBusiness from '../../commons/config/ConfigBusiness.js';
import Timezone from '../../commons/timezone/Timezone.js';
import LlantaRepuestosSelect from './LlantaRepuestosSelect.jsx';
import LlantaBasesSelect from './LlantaBasesSelect.jsx';
import Security from '../../commons/security/Security.js';
import * as Utils from '../../commons/utils/Utils';

const MySwal = withReactContent(Swal);

class LlantaRecapado extends Component {
  constructor(props) {
    super(props);
    this.ajaxHandler = new AjaxHandler();

    this.dataService = '/llantas';
    this.state = {
      props: this.props,
      llanta: this.props.llanta,
      formDataFinalizeRecapado: {
        milimetrosRecapado: '',
        costoRecapado: '',
        proveedor: '',
        modeloReencauche: '',
        fechaRealizado: null,
      },
      formDataSendRecapado: {
        llanta_reemplazo: '',
      },
      llantasSelect: [],
      gerenciadores: [],
      bases: [],
      modelosReencauche: [],
      modelosReencaucheLoading: false,
      errors: [],
      loading: false,
      unidadMedidorChange: false,
    };
    this.handleInputFormChange = this.handleInputFormChange.bind(this);
    this.handleSelectFormFinalizeChange = this.handleSelectFormFinalizeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.formFinalizeRecapadoValidation = new FormValidation({
      component: this,
      validators: {
        'formDataFinalizeRecapado.milimetrosRecapado': (value) => Validator.notEmpty(value),
        'formDataFinalizeRecapado.costoRecapado': (value) => Validator.notEmpty(value),
        'formDataFinalizeRecapado.proveedor': (value) => Validator.notEmpty(value),
        'formDataFinalizeRecapado.modeloReencauche': (value) => Validator.notEmpty(value),
        'formDataFinalizeRecapado.fechaRealizado': (value) => Validator.notEmpty(value),
      },
    });

    this.formSendRecapadoValidation = new FormValidation({
      component: this,
      validators: {
        // "formDataSendRecapado.llanta_reemplazo": (value) => Validator.notEmpty(value),
        'formDataSendRecapado.base': (value) => Validator.notEmpty(value),
      },
    });
  }

  componentWillMount() {
    this.ajaxHandler.subscribe(this);
    this.props.llanta && this.initForm();
    this.props.llantas && this.initLlantasSelect();
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.llanta && prevState.llanta !== this.state.llanta) {
      this.initForm();
    }
  }

  initForm() {
    this.setState({ loading: false });
    Promise.all([
      null,
      this.getData('/gerenciadores'),
      ConfigBusiness.get('llantas.bandas'),
      this.getData('/bases/select-filtered-by-user/base/10'),
      this.state.llanta.ticket_id ? this.getData('/tickets/' + this.state.llanta.ticket_id) : null,
    ])
      .then((data) => {
        let formDataFinalizeRecapadoCopy = JSON.parse(
          JSON.stringify(this.state.formDataFinalizeRecapado),
        );
        this.setState({ llantas: data[0] });
        let bandas = data[2] ? data[2].split(',').map((e) => ({ id: e, label: e })) : [];
        if (data[4]) formDataFinalizeRecapadoCopy.fechaRealizado = data[4].fechaHoraRealizado;
        this.setState({
          gerenciadores: data[1],
          modelosReencauche: bandas,
          bases: data[3],
          formDataFinalizeRecapado: formDataFinalizeRecapadoCopy,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  initLlantasSelect(){
    this.state.llantasSelect = this.props.llantas.filter(llanta => llanta.recapadas < llanta.modelo.recapado);
  }

  handleInputFormChange(event) {
    event.persist();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formDataFinalizeRecapado));
    formDataCopy[name] = value;
    this.setState({ formDataFinalizeRecapado: formDataCopy });
  }

  handleSelectFormFinalizeChange(event, name) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formDataFinalizeRecapado));
    formDataCopy[name] = event;
    this.setState({ formDataFinalizeRecapado: formDataCopy });
  }

  handleDatePickerFormChange(name, event) {
    let date = event ? event.format() : '';
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formDataFinalizeRecapado));
    formDataCopy[name] = date;
    this.setState({ formDataFinalizeRecapado: formDataCopy });
  }

  handleDatePickerFormRawChange(name, date) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formDataFinalizeRecapado));
    formDataCopy[name] = date;
    this.setState({ formDataFinalizeRecapado: formDataCopy });
  }

  handleFormChangeReemplazo(event, name) {
    !event && name === 'llanta' && this.setState({ unidadMedidorChange: false });
    name === 'llanta_reemplazo' && this.checkMedidorChange(event);
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formDataSendRecapado));
    formDataCopy[name] = event;
    this.setState({ formDataSendRecapado: formDataCopy });
  }

  checkMedidorChange(event) {
    this.setState({
      unidadMedidorChange: event ? (event.unidad_medidor_id ? (event.unidad_medidor_id !== this.state.llanta.unidad_medidor_id) : false ): false,
    });
  }

  getData(service) {
    let serviceURL = Config.get('apiUrlBase') + service;
    return fetch(serviceURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization-Token': localStorage.getItem('token'),
      },
    }).then((response) => {
      return this.ajaxHandler.handleResponseErrorsIsValid(response) ? response.json() : null;
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (!this.state.loading) {
      this.setState({ loading: true });
      let component = this;
      let formData = null;
      if (this.state.llanta.ticket_id) {
        formData = JSON.parse(JSON.stringify(this.state.formDataFinalizeRecapado));
        formData.fechaRealizado = Timezone.getDateForServer(formData.fechaRealizado).format(
          'YYYY-MM-DD HH:mm:ss',
        );
      } else formData = JSON.parse(JSON.stringify(this.state.formDataSendRecapado));
      let serviceURL =
        '/llantas/' +
        this.state.llanta.id +
        (this.state.llanta.ticket_id ? '/finalizarrecapada' : '/recapada');

      Utils.sendData(
        serviceURL, 
        'PUT', 
        JSON.stringify({
          ...formData,
        })
      )
      .then((response) => {
        if (response.status !== 400) {
          if (this.state.llanta.ticket_id) {
            MySwal.fire(
              this.props.intl.formatMessage({
                id: 'llantaRecapado.modal_recapado.information_recapado_exitoso',
                defaultMessage: 'Recapado exitoso',
              }),
              '',
              'success',
            );
          } else {
            MySwal.fire(
              this.props.intl.formatMessage({
                id: 'llantaRecapado.modal_recapado.information_enviado_a_recapar',
                defaultMessage: 'Enviado a recapar',
              }),
              '',
              'success',
            );
          }
          component.props.handleSubmitForzarTicketRecapado(
            this.state.llanta.ticket_id ? true : false,
          );
        } else {
          response.json().then((data) => {
            this.setState({
              errors: data.detalle,
            });
          });
        }
      })
      .catch((e) => 
        console.log(e)
      ).finally(() => {
        this.setState({ loading: false });
      });
    }
  }

  render() {
    this.formFinalizeRecapadoValidation.validate();
    this.formSendRecapadoValidation.validate();
    let state = this.state;
    let validationStateFinalizeRecapado = this.formFinalizeRecapadoValidation.state;
    let validationStateSendRecapado = this.formSendRecapadoValidation.state;

    return (
      <React.Fragment>
        <div>
          <div className="alert alert-danger" role="alert" hidden={this.state.errors.length === 0}>
            {this.state.errors.map((e, i) => (
              <li key={i}>
                {/* <FormattedMessage
                  {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}
                /> */}
              </li>
            ))}
          </div>
          <form
            className="form form-horizontal"
            ref="form"
            style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: '1rem' }}
          >
            <div className="text-center">
              <img src={recapadoIcon} alt={'/'} style={{ height: '100px', width: '100px' }} />
            </div>
            <div className="text-center mt-2 text-poppins-500">
              <h3 className="text-poppins-500">
                {this.props.intl.formatMessage({
                  id: 'LlantaRecapado.header_recapado_de_llanta',
                  defaultMessage: 'Llanta a recapar',
                })}{' '}
                : {this.state.llanta && this.state.llanta.serial}
              </h3>
            </div>
            {this.props.fromAsignacion ? (
              <div className="form-group row mt-2">
                <label htmlFor="llantaReemplazo" className="text-center col-5 col-form-label">
                  {this.props.intl.formatMessage({
                    id: 'llantaBaja.llanta_origen_label',
                    defaultMessage: 'Llanta a reemplazar',
                  })}
                  * :
                </label>
                <div className="col-7">
                  <Select
                    name="llantaOriginal"
                    className="select-personal-llantas z-15"
                    maxMenuHeight={10}
                    placeholder={this.props.intl.formatMessage({
                      id: 'llantaBaja.select_placeholder_cautin_sn',
                      defaultMessage: 'Cautin / SN',
                    })}
                    options={this.state.llantasSelect}
                    valueKey="id"
                    labelKey="serial"
                    value={this.state.llanta ? this.state.llanta : ''}
                    onChange={(e) => this.setState({ llanta: e })}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            {this.state.llanta && this.state.llanta.ticket_id ? (
              <div className="w-100 d-flex flex-column px-5 py-1">
                <label htmlFor="milimetrosRecapado" className="text-center text-poppins-500 ">
                  {this.props.intl.formatMessage({
                    id: 'LlantaRecapado.form_milimetro_recapado_label',
                    defaultMessage: 'Milimetros de Recapado',
                  })}
                  * :
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="milimetrosRecapado"
                  name="milimetrosRecapado"
                  placeholder={this.props.intl.formatMessage({
                    id: 'llantaRecapado.form_placeholder_milimetros_recapado',
                    defaultMessage: 'milimetros',
                  })}
                  value={this.state.formDataFinalizeRecapado.milimetrosRecapado}
                  onChange={this.handleInputFormChange}
                />
                <label htmlFor="costoRecapado" className="text-center mt-1 text-poppins-500 ">
                  {this.props.intl.formatMessage({
                    id: 'LlantaRecapado.form_costo_recapado_label',
                    defaultMessage: 'Costo de Recapado',
                  })}
                  * :
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="costoRecapado"
                  name="costoRecapado"
                  placeholder={this.props.intl.formatMessage({
                    id: 'llantaRecapado.form_placeholder_costo_recapado',
                    defaultMessage: 'Costo',
                  })}
                  value={this.state.formDataFinalizeRecapado.costoRecapado}
                  onChange={this.handleInputFormChange}
                />
                <label htmlFor="proveedor" className="text-center mt-1 text-poppins-500 ">
                  {this.props.intl.formatMessage({
                    id: 'LlantaRecapado.form_proveedor_label',
                    defaultMessage: 'Proveedor',
                  })}
                  * :
                </label>
                <Select
                  name="proveedor"
                  className="select-personal-llantas z-15"
                  maxMenuHeight={10}
                  placeholder={this.props.intl.formatMessage({
                    id: 'llantaRecapado.select_placeholder_proveedor',
                    defaultMessage: 'Seleccionar',
                  })}
                  options={this.state.gerenciadores}
                  valueKey="id"
                  labelKey="razonSocial"
                  value={this.state.formDataFinalizeRecapado.proveedor}
                  onChange={(e) => this.handleSelectFormFinalizeChange(e, 'proveedor')}
                />
                <label htmlFor="modeloReencauche" className="text-center mt-1 text-poppins-500 ">
                  {this.props.intl.formatMessage({
                    id: 'LlantaRecapado.form_modelo_reencache_label',
                    defaultMessage: 'Modelo de Reencauche',
                  })}
                  * :
                </label>
                <Select
                  name="modeloReencauche"
                  className="select-personal-llantas z-15"
                  maxMenuHeight={10}
                  placeholder={this.props.intl.formatMessage({
                    id: 'llantaRecapado.select_placeholder_modelo_reencache',
                    defaultMessage: 'Seleccionar',
                  })}
                  options={this.state.modelosReencauche}
                  valueKey="id"
                  labelKey="label"
                  value={this.state.formDataFinalizeRecapado.modeloReencauche}
                  onChange={(e) => this.handleSelectFormFinalizeChange(e, 'modeloReencauche')}
                />
                <label htmlFor="fechaRealizado" className="text-center mt-1 text-poppins-500 ">
                  {this.props.intl.formatMessage({
                    id: 'LlantaRecapado.form_fecha_realizado_label',
                    defaultMessage: 'Fecha de Realizado',
                  })}
                  * :
                </label>
                <div className="text-center">
                  <DatePicker
                    id="fechaRealizado"
                    name="fechaRealizado"
                    className="form-control date-picker-placeholder"
                    placeholderText={this.props.intl.formatMessage({
                      id: 'llantaRecapado.form_placeholder_fecha_de_realizado',
                      defaultMessage: 'DD/MM/AAAA',
                    })}
                    selected={
                      this.state.formDataFinalizeRecapado.fechaRealizado === null ||
                      this.state.formDataFinalizeRecapado.fechaRealizado === '0000-00-00'
                        ? null
                        : moment(this.state.formDataFinalizeRecapado.fechaRealizado)
                    }
                    onChange={(event) => this.handleDatePickerFormChange('fechaRealizado', event)}
                    onChangeRaw={(event) =>
                      this.handleDatePickerFormRawChange('fechaRealizado', event.target.value)
                    }
                    openToDate={moment()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                  />
                </div>
              </div>
            ) : this.state.llanta && this.state.llanta.movil_id ? (
              !Security.hasPermission('LLANTAS_SIN_NECESIDAD_REEMPLAZO') ? (
                <div>
                  <div className="form-group row mt-2">
                    <label htmlFor="milimetrosRecapado" className="text-center col-5 col-form-label">
                      {this.props.intl.formatMessage({
                        id: 'llantaForzarTicketRecapado.form_milimetro_recapado_label',
                        defaultMessage: 'Llanta reemplazo',
                      })}
                      * :
                    </label>
                    <div className="col-7">
                      <LlantaRepuestosSelect
                        llantaId={this.state.llanta ? this.state.llanta.id : null}
                        movil={this.props.movil}
                        intl={this.props.intl}
                        changeLlantaRepuesto={(e) =>
                          this.handleFormChangeReemplazo(e, 'llanta_reemplazo')
                        }
                      />
                    </div>
                  </div>
                  {this.state.unidadMedidorChange && (
                    <p className="my-1 reemplazo-label-danger">
                      La llanta seleccionada posee diferente medidor. Se reiniciaran los datos de
                      odometro.
                    </p>
                  )}
                  <div className="form-group row mt-2">
                    <label htmlFor="milimetrosRecapado" className="text-center col-5 col-form-label">
                      {this.props.intl.formatMessage({
                        id: 'llantaDesasignar.select_base',
                        defaultMessage: 'Seleccione un destino',
                      })}
                      * :
                    </label>
                    <div className="col-7">
                      {/* <Select
                        name="base"
                        className="select-personal-llantas z-15"
                        maxMenuHeight={10}
                        placeholder={this.props.intl.formatMessage({id:
                          "llantaDesasignar.select_placeholder_llanta_reemplazo",
                          defaultMessage: "Base"
                        })}
                        isLoading={this.state.basesLoading}
                        options={this.state.bases}
                        valueKey='value'
                        labelKey='label'
                        value={this.state.formDataSendRecapado.base ? this.state.formDataSendRecapado.base : ''}
                        onChange={(e) => this.handleFormChangeReemplazo(e,"base")}
                      /> */}
                      <LlantaBasesSelect
                        intl={this.props.intl}
                        changeBase={(e) => this.handleFormChangeReemplazo(e, 'base')}
                      />
                      <div
                        className="help-block text-danger field-message"
                        hidden={
                          validationStateSendRecapado.formDataSendRecapado.base.pristine ||
                          validationStateSendRecapado.formDataSendRecapado.base.valid
                        }
                      >
                        {validationStateSendRecapado.formDataSendRecapado.base.message}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              <div className="form-group row mt-2">
                <label htmlFor="milimetrosRecapado" className="text-center col-5 col-form-label">
                  {this.props.intl.formatMessage({
                    id: 'llantaDesasignar.select_base',
                    defaultMessage: 'Seleccione una base',
                  })}
                  * :
                </label>
                <div className="col-7">
                  {/* <Select
                    name="base"
                    className="select-personal-llantas z-15"
                    maxMenuHeight={10}
                    placeholder={this.props.intl.formatMessage({
                      id: 'llantaDesasignar.select_placeholder_base_reemplazo',
                      defaultMessage: 'Seleccione',
                    })}
                    isLoading={this.state.basesLoading}
                    options={this.state.bases}
                    valueKey="value"
                    labelKey="label"
                    value={
                      this.state.formDataSendRecapado.base
                        ? this.state.formDataSendRecapado.base
                        : ''
                    }
                    onChange={(e) => this.handleFormChangeReemplazo(e, 'base')}
                  /> */}
                  <LlantaBasesSelect
                      intl={this.props.intl}
                      changeBase={(e) => this.handleFormChangeReemplazo(e, 'base')}
                    />
                  <div
                    className="help-block text-danger field-message"
                    hidden={
                      validationStateSendRecapado.formDataSendRecapado.base.pristine ||
                      validationStateSendRecapado.formDataSendRecapado.base.valid
                    }
                  >
                    {validationStateSendRecapado.formDataSendRecapado.base.message}
                  </div>
                </div>
              </div>
            )}
            <div className="text-center d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary mr-1"
                disabled={
                  (
                    (this.state.llanta && this.state.llanta.ticket_id) ? !validationStateFinalizeRecapado.form.valid
                    : Security.hasPermission('LLANTAS_SIN_NECESIDAD_REEMPLAZO') ? !validationStateSendRecapado.form.valid
                    : (this.state.llanta && this.state.llanta.llanta_posicion && this.state.llanta.llanta_posicion.necesita_reemplazo)
                          ? (!this.state.formDataSendRecapado.llanta_reemplazo || !validationStateSendRecapado.form.valid)
                    : !validationStateSendRecapado.form.valid
                  )
                  || state.loading
                }
                onClick={this.handleSubmit}
              >
                <i className="fa fa-check-circle"></i>
                {this.props.intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.button_guardar',
                  defaultMessage: ' Guardar',
                })}
              </button>
              <button
                type="button"
                className="btn btn-danger mr-1"
                onClick={(e) => this.props.closeModalForzarTicketRecapado(e)}
              >
                <i className="fa fa-times-circle"></i>
                {this.props.intl.formatMessage({
                  id: 'llantaAsignacionesDetails.render.button_cancelar',
                  defaultMessage: ' Cancelar',
                })}
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default LlantaRecapado;
