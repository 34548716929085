import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Security from "../../commons/security/Security.js";
import "bootstrap4-duallistbox";
import "bootstrap4-duallistbox/dist/bootstrap-duallistbox.css";
import AjaxHandler from "../../commons/ajax/AjaxHandler.js";
import Loading from "../ui/Loading.js";
import "react-select/dist/react-select.css";
import moment from "moment";
import "moment/min/locales";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropzone-component/styles/filepicker.css";
import "dropzone/dist/min/dropzone.min.css";
import "../../assets/css/vec-dropzone.css";
import { FormattedMessage, injectIntl } from "react-intl";
import backendStrings from "../../lang/backendStrings.js";
import Timezone from '../../commons/timezone/Timezone.js';
import EamDetalle from './EamDetalle.js';
import $ from 'jquery'

class RopView extends Component {
  constructor(props) {
    super(props);

    this.ajaxHandler = new AjaxHandler();

    moment.locale("es");
    this.state = {
      modalEam: 0, 
      redirectTo: null,
      ticketModal: null,
      props: this.props,
      formData: {
      },
      errors: [],
      loading: false,
    };
    this.clickViewEvent = this.clickViewEvent.bind(this);
  }

  componentWillMount() {
    if (Security.hasPermission("ROPS_VISUALIZAR")) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: "/error",
      });
    }
    
  }

  initForm() {
    this.setState({ loading: true });
    let component = this;
    Promise.all([
      this.ajaxHandler.getJson('/rops/' + this.state.props.match.params.id),
      this.ajaxHandler.getJson('/rops/classifications/select')
    ])
      .then((data) => {
        let rop = data[0];
        let clasificaciones = data[1];
        let calificacion = clasificaciones.filter(function (calificacion) {
          return calificacion.value === rop.clasificacion.id;
        });

        let tickets = [];
        rop.relacionesEam.map(function (relacionEam) {
          let ticket = {
            or: relacionEam.numeroOrdenReparacion,                          
            fecha: relacionEam.ticket.fecha_hora_alta,
            fechaPrometida: relacionEam.ticket.fecha_prometida,
            razon: relacionEam.razonReparacionCotizacion ? relacionEam.razonReparacionCotizacion.descripcion : null,
            descripcion: relacionEam.ticket.detalle,
            servicio: relacionEam.ticket.servicio_detail ? relacionEam.ticket.servicio_detail.nombre : null, 
            ticket: relacionEam.ticket.id,
            estado: relacionEam.ticket.estado
          }
          tickets.push(ticket);
        }.bind(this));

        let formData = {
          numero: rop.numero,
          fechaCreacion: rop.fechaCreacion,
          fechaRecibido: rop.fechaRecibido,
          equipo: rop.movil.dominio,
          cliente: rop.movil.companiaOrigen,
          taller: rop.taller? rop.taller.razonSocial : '',
          clasificacion: calificacion[0].label,
          observaciones: rop.observacion ? rop.observacion.split(";") : [],
          operador: rop.operador,
          kms: rop.kmsAcumulados,
          combustible: rop.gasLevel,
          estado: rop.estado,
          tickets: tickets, 
          razonCierre: rop.razonCierre
        }
        component.setState(
          {
            formData: formData
          }
        );
      })
      .catch(function (error) {
        component.ajaxHandler.handleError(error);
      })
      .finally(() => {
        component.setState({ loading: false });
      });
  }

  clickViewEvent(ticketId){
    const miState = {...this.state};
    miState.redirectTo = "/tickets/"+ticketId+"/edit";
    this.setState(miState);
  }

	handleEamDetalle(e, ticketId) {
		e.preventDefault();
		let modalEam = this.state.modalEam + 1;

		this.setState({
			modalEam: modalEam, 
      ticketModal: ticketId
		}, () => {
			$('#eam_modal').modal({backdrop: 'static'});
		});
	}

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

  handleCancel(event) {
    window.history.back();
  }

  exit() {
    this.setState({
      redirectTo: "/rop",
    });
  }

  render() {
    let formData = this.state.formData;
    return (
      <React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
        {this.state.loading && <Loading />}
        <div className="row">
          <div className="col-md-12">
            <div
              className="alert alert-danger"
              role="alert"
              hidden={this.state.errors.length === 0}
            >
              {this.state.errors.map((e, i) => (
                <li key={i}>
                  <FormattedMessage
                    {...(backendStrings[e]
                      ? backendStrings[e]
                      : backendStrings["errors.default"])}
                  />
                </li>
              ))}
            </div>
            <form
              className="form form-horizontal"
              ref="form"
              onSubmit={this.handleSubmit}
            >
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-info-circle"></i>
                        <FormattedMessage
                          id="ropAbm.datos_generales"
                          defaultMessage=" Datos Generales "
                        />
                      </h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="numero"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_numero"
                                defaultMessage="Número:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.numero ? formData.numero : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="estado"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_estado"
                                defaultMessage="Estado:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.estado ? formData.estado : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="fechaCreacion"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_fecha_creacion"
                                defaultMessage="Fecha Creación:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.fechaCreacion ? Timezone.getDateForClient(formData.fechaCreacion, null, 'DD/MM/YYYY HH:mm') : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="fechaRecibido"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_fecha_recibido"
                                defaultMessage="Fecha Recibido:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.fechaRecibido ? Timezone.getDateForClient(formData.fechaRecibido, null, 'DD/MM/YYYY HH:mm') : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="equipo"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_equipo"
                                defaultMessage="Equipo:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.equipo ? formData.equipo : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="cliente"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_cliente"
                                defaultMessage="Cliente:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.cliente ? formData.cliente : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="taller"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_taller"
                                defaultMessage="Taller:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.taller ? formData.taller : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="clasificacion"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_clasificacion"
                                defaultMessage="Clasificación:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.clasificacion ? formData.clasificacion : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="kms"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_kms"
                                defaultMessage="Kms:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.kms ? formData.kms : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="combustible"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_combustible"
                                defaultMessage="Combustible:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.combustible ? formData.combustible : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="operador"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_operador"
                                defaultMessage="Operador:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.operador ? formData.operador : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label
                              className="col-md-4 label-control col-form-label"
                              htmlFor="razonCierre"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_razonCierre"
                                defaultMessage="Razón de Cierre:"
                              />
                            </label>
                            <div className="col-md-8">
                              <div className="form-control-static col-form-label form-value">
                                {formData.razonCierre ? formData.razonCierre : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label
                              className="col-md-2 label-control col-form-label"
                              htmlFor="observaciones"
                            >
                              <FormattedMessage
                                id="ropView.datos_generales.label_observaciones"
                                defaultMessage="Observaciones:"
                              />
                            </label>
                            <div className="col-md-10">
                              {
                                formData.observaciones && formData.observaciones.map((element) => (
                                  <div className="form-control-static col-form-label form-value-grow">
                                    {element}
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.loading && <Loading />}
                {this.state.formData.tickets ? (
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section">
                          <i className="la la-tasks"></i><FormattedMessage id="ropView.tickets.header_tickets" defaultMessage=" Tickets" />
                        </h4>
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table">
                              <thead className="thead-fleet">
                                <tr>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_or" defaultMessage="Número OR" /></th>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_fecha" defaultMessage="Fecha" /></th>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_fecha_prometida" defaultMessage="Fecha Prometida" /></th>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_razon" defaultMessage="Razón Reparación" /></th>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_descripcion" defaultMessage="Descripción" /></th>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_servicio" defaultMessage="Servicio" /></th>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_ticket" defaultMessage="TKT" /></th>
                                  <th scope="col"><FormattedMessage id="ropView.tickets.table_column_estado" defaultMessage="Estado" /></th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.formData.tickets.map((ticket, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="align-middle">{ticket.or}</td>
                                      <td className="align-middle">{ticket.fecha ? Timezone.getDateForClient(ticket.fecha, null, 'DD/MM/YYYY') : ""}</td>
                                      <td className="align-middle">{ticket.fechaPrometida ? Timezone.getDateForClient(ticket.fechaPrometida, null, 'DD/MM/YYYY') : ""}</td>
                                      <td className="align-middle">{ticket.razon}</td>
                                      <td className="align-middle">{ticket.descripcion}</td>
                                      <td className="align-middle">{ticket.servicio}</td>
                                      <td className="align-middle">{ticket.ticket}</td>
                                      <td className="align-middle">{ticket.estado}</td>
                                      <td>
                                        <button className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                                              onClick={()=>this.clickViewEvent(ticket.ticket)}>
									                        <i className="fa fa-pencil fa-xs"></i>
								                        </button>
                                        <button className="action view btn btn-sm btn-icon btn-dt-grid text-success"
                                              onClick={(e)=>this.handleEamDetalle(e, ticket.ticket)}>
									                        <i className="la la-info-circle"></i>
								                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (null)}
                {/*JSON.stringify(this.state)*/}
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.handleCancel.bind(this)}
                        >
                          <i className="fa fa-times-circle"></i>{" "}
                          {this.props.intl.formatMessage({
                            id: "romAbm.button.finish.volver",
                            defaultMessage: "Volver",
                          })
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="modal fade" tabIndex="-1" id="eam_modal" role="dialog" aria-labelledby="myModalLabel1">
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel1"><i className="la la-info-circle align-middle icon-modal-title"></i>{this.props.intl.formatMessage({ id: 'ropView.render.eam_modal.header_eam_detalle', defaultMessage: ' Detalle del Ticket en EAM: ' }) + this.state.ticketModal}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body modal-grey modal-mh ovf-x-hidden">
                {this.state.modalEam ? <EamDetalle key={this.state.modalEam} ticketId={this.state.ticketModal}></EamDetalle>: ''}
              </div>
              <div className="modal-footer modal-grey">
                <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="ropView.render.eam_modal.finish_button.ok" defaultMessage="Ok"/></button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(RopView);
