import React, { Component } from 'react'
import * as Constants from './constants.js'
import $ from 'jquery'
import InstanciaImagen from './instancias/InstanciaImagen.js'
import InstanciaAdjunto from './instancias/InstanciaAdjunto.js';

class AtributoDinamicoInstanciaViewPDF extends Component {
	constructor(props) {
    super(props);
    this.props = props;
  }

	componentDidMount() {
		$('.popover2').popover()
	}

	componentWillUnmount() {
		$('.popover2').popover('hide');
	}

	render() {
			let tipoValor = this.props.atributo.tipoValor ? this.props.atributo.tipoValor : 0
			let InstanciaDinamica = Constants.INSTANCIAS_DICT[tipoValor].clase
			let estadoRequiereInfo = this.props.atributo.estadosInformativos &&
															 this.props.atributo.estadosInformativos.find(estado => estado.id == this.props.atributo.estado)
			let instanciaConEstados = Constants.INSTANCIAS_DICT[tipoValor].conEstados
			let mostrarObservaciones = estadoRequiereInfo && instanciaConEstados && !this.props.previsualizacion
      return (
        <React.Fragment>
							<>
								<label className="col-3 label-control col-form-label">
									<span className={!this.props.atributo.description ? 'hidden' : ''}>
										<i
											className="cursor-pointer fa fa-info-circle popover2"
											data-toggle="popover" title="Instrucciones"
											onClick={event => event.preventDefault()}
											data-html={true}
											data-content={this.props.atributo.description}
										/>&nbsp;
									</span>

									{this.props.atributo.nombre} {this.props.atributo.required ? '*' : ''}:
								</label>
								<div className={instanciaConEstados ? "col-8" : "col-8"}>
									<InstanciaDinamica
										disabled={this.props.disabled}
										previsualizacion={this.props.previsualizacion}
										handleChange={this.props.handleChange}
										atributo={this.props.atributo}
										movil={this.props.movil}
									/>
								</div>
								<div className={instanciaConEstados ? "col-1" : "hidden"}>
									{this.props.atributo.estado == Constants.ID_ESTADO_APROBACION_VALIDO ?
										<i className="fa fa-2x fa-check success"></i> : ''
									}
									{this.props.atributo.estado == Constants.ID_ESTADO_APROBACION_INVALIDO ?
										<i className="fa fa-2x fa-times-circle-o danger"></i> : ''
									}
								</div>

								{mostrarObservaciones &&
									<>
										<label className="col-3 label-control col-form-label">
											Observaciones:
										</label>
										<div className="col-9">
											<textarea
												disabled={this.props.disabled}
												className="form-control"
												value={this.props.atributo.observaciones}
												onChange={event => this.props.handleChange("observaciones", event.target.value)}
												rows="1"
											/>
										</div>
										<label className="col-3 label-control col-form-label">
											Imagen:
										</label>
										<div className="col-9">
											<InstanciaImagen
												disabled={this.props.disabled}
												previsualizacion={this.props.previsualizacion}
												valueKey={'adjunto_id'}
												handleChange={(name, value) => this.props.handleChange(name, value)}
												atributo={this.props.atributo}
											/>
										</div>
										<label className="col-3 label-control col-form-label">
											Documento:
										</label>
										<div className="col-9">
											<InstanciaAdjunto
												disabled={this.props.disabled}
												previsualizacion={this.props.previsualizacion}
												valueKey={'adjunto_id'}
												handleChange={(name, value) => this.props.handleChange(name, value)}
												atributo={this.props.atributo}
											/>
										</div>
									</>
								}
							</>
        </React.Fragment>
      )
  }
}

export default AtributoDinamicoInstanciaViewPDF;
