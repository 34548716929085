import React, { Component, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Sort from "../../assets/images/llantas/table/sort.svg";
import SortAsc from "../../assets/images/llantas/table/sort-asc.svg";
import SortDesc from "../../assets/images/llantas/table/sort-desc.svg";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import Loading from '../ui/Loading.js'
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	TablePagination,
} from '@material-ui/core';
import { StyledTableCell, StyledTooltip, rowsPerPage } from '../../commons/utils/TableStyles'
import * as Constants from '../atributoDinamico/constants.js'
import * as Utils from '../../commons/utils/Utils';
import { FormattedMessage, useIntl } from 'react-intl';
import FormulariosGridRow from './FormulariosGridRow.jsx';
import FormulariosGridFilters from './FormulariosGridFilters.jsx';
import FormulariosGridExport from './FormulariosGridExport.jsx';
import Security from '../../commons/security/Security'

const PAGINATION_INIT = {
	page: 0,
	count: 0,
	perPage: 10,
}

const FILTER_QUERY_INIT = {
	icono: '',
	estado: '',
	id: '',
	descripcion: '',
	fecha: '',
	entidad: '',
	usuario: '',
	fallas: '',
	base: '',
	chofer: '',
	showInactivos: '',
}

const SORT_QUERY_INIT = {
	id: 'desc',
	descripcion: '',
	fecha: '',
	fallas: '',
	chofer: '',
}

const COLUMNS_ENABLED_INIT = {
	icono: true,
	estado: true,
	id: true,
	descripcion: true,
	fecha: true,
	entidad: true,
	usuario: true,
	fallas: true,
	base: true,
	chofer: true,
}

const FormularioGrid = (props) => {
	const intl = useIntl();
  const filterEntidad = (new URLSearchParams(props.location.search)).get('entidad')

	const [formularios, setFormularios] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({ ...PAGINATION_INIT });
	const [paginationCount, setPaginationCount] = useState(0);
	const [filterQuery, setFilterQuery] = useState(null);
	const [sortQuery, setSortQuery] = useState({ ...SORT_QUERY_INIT });
	const [searchActive, setSearchActive] = useState(false);
	const [columnsEnabled, setColumnsEnabled] = useState({ ...COLUMNS_ENABLED_INIT })
	const [dropdownActive, setDropdownActive] = useState(false);
	const [resetFilterInputs, setResetFilterInputs] = useState(false);
	const [redirectTo, setRedirectTo] = useState(null);
	const [modalExport,setModalExport] = useState(false)
	const estados = [
		{
			value: '',
			label: 'TODOS'
		},
		{
			value: Constants.ID_ESTADO_APROBACION_VALIDO,
			label: intl.formatMessage({ id: 'formularioGrid.column_state_filter.label_valido', defaultMessage: 'CERRADOS' })
		},
		{
			value: Constants.ID_ESTADO_APROBACION_PENDIENTE,
			label: intl.formatMessage({ id: 'formularioGrid.column_state_filter.label_pendiente', defaultMessage: 'EN PROCESO' })
		},
		{
			value: Constants.ID_ESTADO_APROBACION_INVALIDO,
			label: intl.formatMessage({ id: 'formularioGrid.column_state_filter.label_invalido', defaultMessage: 'PENDIENTES' })
		},
		{
			value: Constants.ID_ESTADO_APROBACION_SIN_COMPROBACION,
			label: intl.formatMessage({ id: 'formularioGrid.column_state_filter.label_sin_comprabacion', defaultMessage: 'SIN COMPRABACIÓN' })
		}
	]

	useEffect(() => {
		if (
			Security.hasPermission('CHECKLIST_LISTAR_HISTORICO') || 
			Security.hasPermission('CHECKLIST_VISUALIZAR_HISTORICO') 
		) {
			setLoading(true);
			filterQuery && fetchFormularios();
		} else {
			setRedirectTo('/error');
		}
	}, [pagination, sortQuery, filterQuery])

	const fetchFormularios = () => {
		Utils.getData(`/formulario/newGrid${getQuery()}`).then((data) => {
			setFormularios(data.formularios)
			console.log(data);
			setLoading(false);
			setPaginationCount(data.pagination.count);
			setLoading(false);

		}).catch((error) => {
			console.log(error);
			setLoading(false);
		})
	}

	const getQuery = () => {
		let query = "?page=" + pagination.page
		query += "&perPage=" + pagination.perPage

		// Filtrado
		query += "&icono=" + filterQuery.icono
		query += "&estado=" + filterQuery.estado
		query += "&id=" + filterQuery.id
		query += "&descripcion=" + filterQuery.descripcion
		query += "&fecha=" + filterQuery.fecha
		query += "&entidad=" + filterQuery.entidad
		query += "&usuario=" + filterQuery.usuario
		query += "&fallas=" + filterQuery.fallas
		query += "&base=" + filterQuery.base
		query += "&chofer=" + filterQuery.chofer
		query += "&showInactivos=" + filterQuery.showInactivos

		// Ordenamiento
		query += '&idSort=' + sortQuery.id
		query += '&descripcionSort=' + sortQuery.descripcion
		query += '&fechaSort=' + sortQuery.fecha
		query += '&fallasSort=' + sortQuery.fallas
		query += '&choferSort=' + sortQuery.chofer

		return query
	}
	const handleChangePage = (event, newPage) => {
		setPagination({ ...pagination, page: newPage });
	}

	const handleChangeRowsPerPage = (event) => {
		setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
	}

	const handleSort = (key) => {
		let query = { ...sortQuery }
		let orden = (sortQuery[key] == '' || sortQuery[key] === 'desc') ? 'asc' : 'desc';

		query['id'] = '';
		query['descripcion'] = '';
		query['fecha'] = '';
		query['fallas'] = '';
		query['chofer'] = '';

		query[key] = orden;
		setSortQuery(query);
	}

	const handleFilterReset = () => {
		setPagination(PAGINATION_INIT);
		setFilterQuery(FILTER_QUERY_INIT);
		setSortQuery(SORT_QUERY_INIT);
		setDropdownActive(false);
		setSearchActive(false);
		setResetFilterInputs(true);
	};

	return (
		<>
			{redirectTo && <Redirect push to={redirectTo} />}
			{loading && <Loading />}

			<FormulariosGridExport modalExport={modalExport} setModalExport={setModalExport} />

			<div className="card">
				<div className="card-content">
					<div className="card-body card-dashboard">
						<div className="row" style={{ marginBottom: '-20px' }}>
							<StyledTooltip
								title={intl.formatMessage({
									id: 'formularioGrid.button.label_add',
									defaultMessage: 'Agregar',
								})}
								arrow
								placement="right"
							>
								<div
									className="btn btn-primary box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
									style={{ height: 55 }}
									onClick={() => setRedirectTo(props.match.url + '/add')}
								>
									<i className="ft-plus"></i>
								</div>
							</StyledTooltip>
							<StyledTooltip
								title={intl.formatMessage({
									id: 'formularioGrid.button.label_filtros',
									defaultMessage: 'Filtros',
								})}
								arrow
								placement="right"
							>
								<div
									className="btn btn-grey-blue box-shadow-2 btn-round btn-lg btn-dt-main round-icon"
									style={{ height: 52 }}
									onClick={() => setDropdownActive(!dropdownActive)}
								>
									{dropdownActive ? <UpIcon /> : <ExpandMoreIcon />}
								</div>
							</StyledTooltip>
							{searchActive && (
								<div className="btn-dt-main round-icon">
									<div
										className="text-center"
										style={{
											height: '25px',
											borderRadius: '3px',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											paddingLeft: '8px',
											backgroundColor: '#5AAC43',
											color: 'white',
										}}
									>
										<div
											className="text-center"
											style={{
												width: '100%',
												fontSize: '12px',
												fontWeight: '500',
											}}
										>
											{formularios.length} de {paginationCount} resultados de busqueda
										</div>
										<div className="resultados-busqueda-notif">
											<i
												className="las la-times la-xs cursor-pointer"
												onClick={handleFilterReset}
											></i>
										</div>
									</div>
								</div>
							)}
							<div className="col">
								<div className="dt-buttons btn-group">
									<StyledTooltip
										title={intl.formatMessage({
											id: 'formularioGrid.tools_export.title_exportar',
											defaultMessage: 'Exportar',
										})}
										arrow
										placement="top"
									>
										<button onClick={() => setModalExport(true)} className="btn btn-secondary buttons-collection" type="button">
											<span>
												<i className="ft-upload"></i>
											</span>
										</button>
									</StyledTooltip>
								</div>
							</div>
						</div>

						<FormulariosGridFilters
							estados={estados}
							columnsEnabled={columnsEnabled}
							resetFilterInputs={resetFilterInputs}
							setResetFilterInputs={setResetFilterInputs}
							filterQuery={filterQuery}
							setFilterQuery={setFilterQuery}
							setDropdownActive={setDropdownActive}
							dropdownActive={dropdownActive}
							searchActive={searchActive}
							setSearchActive={setSearchActive}
              filterEntidad={filterEntidad}
						/>

						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<StyledTableCell align="left" style={{ cursor: "pointer" }}>
											<FormattedMessage id="formularioGrid.render.column_tipo.label" defaultMessage="Tipo" />
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer", maxWidth: "3px", fontSize: '18px', padding: "16px 16px 16px 0" }} >
											<div className="dropdown">
												<i
													id="dLabel"
													type="button"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													className="fa fa-filter cursor-pointer"
													style={{ marginRight: "10px" }}
												></i>
												<div className="dropdown-menu" aria-labelledby="dLabel">
													{estados
														? estados.map((el) => (
															<p
																key={el.value}
																className="cursor-pointer dropdown-item"
																style={{ margin: "0", padding: "5px 5px" }}
																onClick={() => setFilterQuery({ ...filterQuery, estado: el.value })}
															>
																{el.label}
															</p>
														))
														: ""}
												</div>
											</div>
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }} onClick={() => handleSort('id')}>
											<FormattedMessage id="formularioGrid.render.column_id.label" defaultMessage="Id" />
											<img className="ml-1"
												src={sortQuery.id === "" ? Sort : sortQuery.id === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }} onClick={() => handleSort('descripcion')}>
											<FormattedMessage id="formularioGrid.render.table_column_descripcion" defaultMessage="Descripcion" />
											<img className="ml-1"
												src={sortQuery.descripcion === "" ? Sort : sortQuery.descripcion === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }} onClick={() => handleSort('fecha')}>
											<FormattedMessage id="formularioGrid.render.table_column_fecha" defaultMessage="Fecha" />
											<img className="ml-1"
												src={sortQuery.fecha === "" ? Sort : sortQuery.fecha === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }}>
											<FormattedMessage id="formularioGrid.render.table_column_entidad" defaultMessage="Entidad" />
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }}>
											<FormattedMessage id="formularioGrid.render.table_column_usuario" defaultMessage="Usuario" />
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }} onClick={() => handleSort('fallas')}>
											<FormattedMessage id="formularioGrid.render.table_column_fallas" defaultMessage="# Fallas" />
											<img className="ml-1"
												src={sortQuery.fallas === "" ? Sort : sortQuery.fallas === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>


										<StyledTableCell align="left" style={{ cursor: "pointer" }}>
											<FormattedMessage id="formularioGrid.render.table_column_base" defaultMessage="Base" />
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }} onClick={() => handleSort('chofer')}>
											<FormattedMessage id="formularioGrid.render.table_column_chofer" />
											<img className="ml-1"
												src={sortQuery.chofer === "" ? Sort : sortQuery.chofer === "asc" ? SortAsc : SortDesc}
												alt={"/"}
												style={{ height: "12px", width: "22px" }}
											/>
										</StyledTableCell>

										<StyledTableCell align="left" style={{ cursor: "pointer" }}>
											<FormattedMessage id="formularioGrid.render.table_column_acciones" defaultMessage="Acciones" />
										</StyledTableCell>

									</TableRow>
								</TableHead>
								<TableBody>
									{loading && (
										<TableRow>
											<StyledTableCell colSpan={11} align="center">
												Cargando
											</StyledTableCell>
										</TableRow>
									)}
									{!loading &&
										formularios &&
										formularios.map((formulario) => (
											<FormulariosGridRow
												key={'formulariosGridRow_' + formulario.id}
												baseUrl={props.match.url}
												formulario={formulario}
											/>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={rowsPerPage}
							component="div"
							count={paginationCount}
							rowsPerPage={pagination.perPage}
							page={pagination.page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							labelRowsPerPage="Mostrar"
							labelDisplayedRows={({ from, to, count }) => `Mostrando ${from} a ${to} de ${count}`}
						/>

					</div>
				</div>
			</div>
		</>
	)
}

export default FormularioGrid;
