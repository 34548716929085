import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import * as Utils from '../../commons/utils/Utils';
import Select from 'react-select';
import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/min/locales';
import DatePicker from 'react-datepicker';
import Timezone from '../../commons/timezone/Timezone.js';

const GastoExtraAbm = (props) => {
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(null);
  const formContainer = useRef('form');
  const [formData, setFormData] = useState({ entidad: '', movil_id: 0, persona_id: 0, tipo_id: 0 });
  const [formDataSelect, setFormDataSelect] = useState({
    entidad: '',
    movil: 0,
    persona: 0,
    tipo: 0,
  });
  const [direccionFormData, setDireccionFormData] = useState({});
  const tipoEntidades = [
    { value: 'M', label: 'Movil' },
    { value: 'P', label: 'Persona' },
  ];

  useEffect(() => {
    moment.locale('es');
    props.action === 'VIEW' || props.action === 'EDIT' ? loadFormData() : initBlank();
  }, []);

  const initBlank = () => {
    setFormData({ entidad: 'M', movil_id: 0, persona_id: 0, tipo_id: 0, fecha: moment() });
    setFormDataSelect({
      entidad: { value: 'M', label: 'Movil' },
      movil: '',
      persona: '',
      tipo: '',
    });
  };
  const loadFormData = () => {
    Utils.getData('/gasto-extra/' + props.match.params.id).then((res) => {
      setFormData(res);
      setDireccionFormData({
        calle: res.direccion_informada,
        numero: res.numero_direccion_informada,
        localidad: res.localidad_informada,
        provincia: res.provincia_informada,
        pais: res.provincia_informada ? res.provincia_informada.pais : null,
        latitud: res.latitud_informada,
        longitud: res.longitud_informada,
      });
      setFormDataSelect({
        entidad:
          res.entidad == 'M' ? { value: 'M', label: 'Movil' } : { value: 'P', label: 'Persona' },
        movil: res.movil ? { id: res.movil_id, label: res.movil.dominio } : '',
        persona: res.persona
          ? { id: res.persona_id, label: res.persona.nombre + ' ' + res.persona.apellido }
          : '',
        tipo: res.tipo_gasto ? { id: res.tipo_id, label: res.tipo_gasto.nombre } : '',
      });
    });
  };

  const getUserMoviles = () => {
    return Utils.getData('/moviles/select-with-base-filtered-by-user').then((res) => {
      return {
        options: res,
        complete: true,
      };
    });
  };

  const getPersonas = () => {
    return Utils.getData('/personas/select-by-user').then((res) => {
      return {
        options: res,
        complete: true,
      };
    });
  };

  const getTipoGasto = () => {
    return Utils.getData('/tipo-gasto-extra/select').then((res) => {
      return {
        options: res,
        complete: true,
      };
    });
  };

  const handleFormChange = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy[e.target.name] = e.target.value;
    setFormData(formDataCopy);
  };

  const handleTipoEntidadChange = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy['entidad'] = e.value;
    setFormData(formDataCopy);
    let formDataSelectCopy = { ...formDataSelect };
    formDataSelectCopy['entidad'] = { value: e.value, label: e.label };
    setFormDataSelect(formDataSelectCopy);
  };

  const handleEntidadChange = (t, e) => {
    let formDataCopy = { ...formData };
    let formDataSelectCopy = { ...formDataSelect };
    if (t == 'M') {
      formDataCopy['movil_id'] = e.value;
      formDataCopy['persona_id'] = null;
      formDataSelectCopy['movil'] = { value: e.value, label: e.label };
      formDataSelectCopy['persona'] = '';
    } else {
      formDataCopy['movil_id'] = null;
      formDataCopy['persona_id'] = e.value;
      formDataSelectCopy['movil'] = '';
      formDataSelectCopy['persona'] = { value: e.value, label: e.label };
    }
    setFormData(formDataCopy);
    setFormDataSelect(formDataSelectCopy);
  };

  const handleTipoGastoChange = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy['tipo_id'] = e.value;
    setFormData(formDataCopy);
    let formDataSelectCopy = { ...formDataSelect };
    formDataSelectCopy['tipo'] = { id: e.id, label: e.label };
    setFormDataSelect(formDataSelectCopy);
  };

  const handleChangeDireccion = (keyDireccion, valueDireccion, direccionValid) => {
    let formDataCopy = { ...formData };
    if (keyDireccion == 'calle') keyDireccion = 'direccion';
    formDataCopy[keyDireccion] = valueDireccion;
    formDataCopy['direccionValid'] = direccionValid;
    setFormData(formDataCopy);
  };
  const handleDatePickerFormChange = (e) => {
    //let date = e ? e.format() : '';
    let date = e ? e.format() : formData.fecha ? moment(formData.fecha): moment();
    let formDataCopy = { ...formData };
    formDataCopy['fecha'] = date;
    setFormData(formDataCopy);
  };

  const handleDatePickerFormRawChange = (e) => {
    let formDataCopy = { ...formData };
    formDataCopy['fecha'] = e.value;
    setFormData(formDataCopy);
  };

  const handleSubmit = (e) => {
    console.log(formData);
    e.preventDefault();
    Utils.sendData(
      '/gasto-extra' + (props.action === 'EDIT' ? '/' + props.match.params.id : ''),
      props.action === 'EDIT' ? 'PUT' : 'POST',
      formData,
    ).then((res) => {
      setRedirectTo('/gastoExtra');
    });
  };

  return (
    <>
      {redirectTo && <Redirect push to={redirectTo} />}
      <form className="form form-horizontal" ref={formContainer}>
        <div className="form-body">
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <h4 className="form-section">
                  <i className="icon-home"></i>
                  <FormattedMessage
                    id="gastoExtraAbm.render.general_data.header_datos_generales"
                    defaultMessage=" Datos Generales "
                  />
                  <div className="float-right" style={{ fontSize: '14px' }}>
                    <FormattedMessage
                      id="gastoExtraAbm.render.general_data.campos_requeridos"
                      defaultMessage="* campos requeridos"
                    />
                  </div>
                </h4>
                {/* Tipo Entidad */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="entidad">
                        <FormattedMessage
                          id="gastoExtraAbm.render.general_data.labelentidad"
                          defaultMessage="Tipo Entidad *:"
                        />
                      </label>
                      <div className="col-md-9">
                        {props.action === 'VIEW' ? (
                          <div className="form-control-static col-form-label form-value">
                            {formData.entidad == 'M' ? 'Movil' : 'Persona'}
                          </div>
                        ) : (
                          <div className="col-md-9">
                            <Select
                              name="tipoEntidades"
                              value={formDataSelect.entidad}
                              valueKey="id"
                              labelKey="label"
                              options={tipoEntidades}
                              onChange={(e) => handleTipoEntidadChange(e)}
                              disabled={props.action === 'VIEW' || props.action === 'EDIT'}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Entidad */}
                {formData.entidad != 'P' && (
                  <div className="row">
                    {/* Movil */}
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-md-3 label-control col-form-label" htmlFor="movil">
                          <FormattedMessage
                            id="gastoExtraAbm.render.general_data.label_movil"
                            defaultMessage="Movil *:"
                          />
                        </label>
                        <div className="col-md-9">
                          {props.action === 'VIEW' ? (
                            <div className="form-control-static col-form-label form-value">
                              {formData.movil ? formData.movil.dominio : ''}
                            </div>
                          ) : (
                            <div className="col-md-9">
                              <Select.Async
                                name="movilSelect"
                                value={formDataSelect.movil}
                                valueKey="id"
                                labelKey="label"
                                loadOptions={() => getUserMoviles()}
                                onChange={(e) => handleEntidadChange('M', e)}
                                disabled={props.action === 'VIEW' || formData.entidad == 'P'}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {formData.entidad != 'M' && (
                  <div className="row">
                    {/* Persona */}
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-md-3 label-control col-form-label" htmlFor="persona">
                          <FormattedMessage
                            id="gastoExtraAbm.render.general_data.label_persona"
                            defaultMessage="Persona *:"
                          />
                        </label>
                        <div className="col-md-9">
                          {props.action === 'VIEW' ? (
                            <div className="form-control-static col-form-label form-value">
                              {formData.persona
                                ? formData.persona.nombre + ' ' + formData.persona.apellido
                                : ''}
                            </div>
                          ) : (
                            <div className="col-md-9">
                              <Select.Async
                                name="personaSelect"
                                value={formDataSelect.persona}
                                valueKey="id"
                                labelKey="label"
                                loadOptions={() => getPersonas()}
                                onChange={(e) => handleEntidadChange('P', e)}
                                disabled={props.action === 'VIEW' || formData.entidad == 'M'}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Tipo Gasto */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="tipo_id">
                        <FormattedMessage
                          id="gastoExtraAbm.render.general_data.label_tipo_id"
                          defaultMessage="Tipo Gasto *:"
                        />
                      </label>
                      <div className="col-md-9">
                        {props.action === 'VIEW' ? (
                          <div className="form-control-static col-form-label form-value">
                            {formData.tipo_id}
                          </div>
                        ) : (
                          <div className="col-md-9">
                            <Select.Async
                              name="categoriaSelect"
                              value={formDataSelect.tipo}
                              valueKey="id"
                              labelKey="label"
                              loadOptions={() => getTipoGasto()}
                              onChange={(e) => handleTipoGastoChange(e)}
                              disabled={props.action === 'VIEW'}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Importe */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="importe">
                        <FormattedMessage
                          id="gastoExtraAbm.render.general_data.label_importe"
                          defaultMessage="Importe *:"
                        />
                      </label>
                      <div className="col-md-9">
                        {props.action === 'VIEW' ? (
                          <div className="form-control-static col-form-label form-value">
                            {formData.importe}
                          </div>
                        ) : (
                          <div>
                            <input
                              type="number"
                              className="form-control"
                              id="importe"
                              name="importe"
                              placeholder={props.intl.formatMessage({
                                id: 'gastoExtraAbm.render.general_data.placeholder_importe',
                                defaultMessage: 'Importe',
                              })}
                              value={formData.importe}
                              onChange={handleFormChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fecha */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-md-3 label-control col-form-label" htmlFor="fecha">
                        <FormattedMessage
                          id="gastoExtraAbm.render.general_data.label_fecha"
                          defaultMessage="Fecha *:"
                        />
                      </label>
                      <div className="col-md-9">
                        {props.action === 'VIEW' ? (
                          <div className="form-control-static col-form-label form-value">
                            {Timezone.getDateForClient(formData.fecha, null, 'DD/MM/YYYY HH:mm')}
                          </div>
                        ) : (
                          <div>
                            <DatePicker
                              id="fecha"
                              name="fecha"
                              className="form-control date-picker-placeholder"
                              popperPlacement="right-start"
                              placeholderText={'DD/MM/AAAA'}
                              selected={
                                formData.fecha === null || formData.fecha === '0000-00-00'
                                  ? null
                                  : moment(formData.fecha)
                              }
                              onChange={(e) => handleDatePickerFormChange(e)}
                              onChangeRaw={(e) => handleDatePickerFormRawChange(e)}
                              openToDate={moment()}
                              maxDate={moment()}
                              minDate={moment().add(-1, 'months')}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body" id="container-direccion">
                <h4 className="form-section mt-2">
                  <i className="ft-map-pin"> </i>
                  <FormattedMessage
                    id="combustiblesAbm.ubicacion_carga"
                    defaultMessage=" Ubicación"
                  />
                </h4>
                <DireccionAutocompletar
                  action={props.action}
                  camposHidden={['codigoPostal']}
                  cambioDireccion={(key, value, Valid) => handleChangeDireccion(key, value, Valid)}
                  formulario={direccionFormData}
                  localidadString={true}
                  crearProvincias={true}
                  crearLocalidades={true}
                  camposObligatorios={['latitud', 'longitud']}
                />
              </div>
            </div>
          </div>
          <div className="card pull-up">
            <div className="card-content">
              <div className="card-body">
                <div className="text-cd text-right">
                  {props.action !== 'VIEW' && (
                    <button className="btn btn-primary mr-1" onClick={handleSubmit}>
                      <i className="fa fa-check-circle"></i>
                      <FormattedMessage
                        id="modelosAbm.finish_button.guardar"
                        defaultMessage=" Guardar"
                      />
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setRedirectTo('/gastoExtra')}
                  >
                    <i className="fa fa-times-circle"></i>{' '}
                    {props.action === 'VIEW'
                      ? props.intl.formatMessage({
                          id: 'modelosAbm.finish_button.volver',
                          defaultMessage: 'Volver',
                        })
                      : props.intl.formatMessage({
                          id: 'modelosAbm.finish_button.cancelar',
                          defaultMessage: 'Cancelar',
                        })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default injectIntl(GastoExtraAbm);
